(function () {
  'use strict';

  /* @ngdoc object
   * @name myLeadPod
   * @description
   *
   */
  config.$inject = ["$locationProvider", "$urlRouterProvider", "RestangularProvider", "NotificationProvider", "$compileProvider", "$analyticsProvider", "PreloadedUserProfile", "GoogleMapProvider", "configOptions", "BitlyUrlShortenerProvider", "SocialShareProvider", "cfpLoadingBarProvider", "OneSignalProvider"];
  run.$inject = ["$rootScope", "$state", "$timeout", "$window", "$document", "Restangular", "Auth", "Session", "QueueManager", "PreloadedUserProfile", "Industries", "Location", "RewardCodes", "OneSignal", "$anchorScroll"];
  angular
    .module('myLeadPod', [
      'ngAria',
      'ngAnimate',
      'ngTouch',
      'angular-loading-bar',
      'ngSanitize',
      'ui.router',
      'ui.bootstrap',
      'restangular',
      'ngRaven',
      'monospaced.elastic',
      'smart-table',
      'ui-notification',
      'credit-cards',
      'smoothScroll',
      'angulartics',
      'angulartics.google.analytics',
      'angucomplete-alt',
      'configuration',
      'components',
      'api',
      'cache',
      'account',
      'socialNetwork',
      'dashboard',
      'myPod',
      'myMemberships',
      'toolbar',
      'user',
      'lead',
      'urlShorteners',
      'socialShare',
      'mlp-animations',
      'mlp-search',
      'mlp-messaging',
      'mlpMarketplace',
      'mlp-charity',
      'mlp-notifications'
    ]);

  angular
    .module('myLeadPod')
    .config(config)
    .run(run);

  function config($locationProvider, $urlRouterProvider, RestangularProvider, NotificationProvider,
                  $compileProvider, $analyticsProvider, PreloadedUserProfile, GoogleMapProvider, configOptions,
                  BitlyUrlShortenerProvider, SocialShareProvider, cfpLoadingBarProvider, OneSignalProvider) {
    $locationProvider.html5Mode(!false);
    $compileProvider.debugInfoEnabled(false);
    cfpLoadingBarProvider.includeSpinner = false;
    RestangularProvider.setBaseUrl(configOptions.baseUrl);
    RestangularProvider.addResponseInterceptor(function (data, operation) {
      var extractedData;
      if (operation === 'getList') {
        extractedData = data.content.records;
        extractedData.meta = data.content.recordsInfo;
      }
      else {
        extractedData = angular.extend({}, data.content);
      }
      extractedData.contentEtag = data.contentEtag;
      extractedData.responseStatus = {
        code: data.statusCode,
        text: data.statusText
      };

      return extractedData;
    });
    RestangularProvider.setDefaultHeaders({Accept: 'application/json'});
    GoogleMapProvider.init(configOptions.googleMapApiKey, configOptions.googleMapLibraries);
    OneSignalProvider.init(configOptions.oneSignalAppId, configOptions.oneSignalSafariWebId);
    BitlyUrlShortenerProvider.setAccessToken(configOptions.bitlyGenericAccessToken);
    SocialShareProvider.set('facebook', 'appId', configOptions.facebookAppId);
    NotificationProvider.setOptions({
      delay: 5000,
      startTop: 70,
      startRight: 10,
      verticalSpacing: 20,
      horizontalSpacing: 20,
      maxCount: 1
    });

    $analyticsProvider.firstPageview(true);
    $analyticsProvider.withAutoBase(true);

    if (angular.isObject(PreloadedUserProfile) && !!PreloadedUserProfile.idUser) {
      $urlRouterProvider.otherwise('/');
    }
    else {
      $urlRouterProvider.otherwise('/account/signin');
    }
  }

  function run($rootScope, $state, $timeout, $window, $document, Restangular, Auth, Session, QueueManager, PreloadedUserProfile, Industries, Location, RewardCodes, OneSignal, $anchorScroll) {
    /* jshint unused:false */
    /* eslint no-unused-vars: [2, {"args": "none", "varsIgnorePattern": "Handler" }]*/
    var stateChangeStartHandler,
        loginRequiredHandler,
        loginSuccessHandler,
        logoutSuccessHandler,
        stateChangeSuccessHandler,
        stateChangeTimeout,
        stateChangeErrorHandler,
        uiView;

    // Force the address bar to close when the app is run first time
    $window.scrollTo(0, 1);

    Session.create(PreloadedUserProfile);
    $rootScope.isAuthenticated = Session.isValidProfile(PreloadedUserProfile);
    if ($rootScope.isAuthenticated) {
      OneSignal.getUserId().then(function (playerId) {
        Restangular.one('Users').customPOST(null, 'pushNotifications', {
          oneSignalPlayerId: playerId
        });
      });
    }

    /**
     * Bind state change start event
     */
    stateChangeStartHandler = $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
      if (!(angular.isDefined(fromParams.logout) && fromParams.logout === true)) {
        Auth.isAuthenticated().then(function (result) {
          // Close any modal instances that might be there.
          $rootScope.$broadcast('close-modal-instance', {});

          if (!result) {
            if (toState.authenticate) {
              event.preventDefault();
              QueueManager.reset();
              $state.go('accountSignInUp.accountSignIn', {
                nextState: {
                  name: toState.name,
                  params: toParams
                }
              });
            }
          }
          else if (toState.name === 'accountSignInUp.accountSignIn' || toState.name === 'accountSignInUp.accountSignUp') {
            event.preventDefault();
            $state.go('dashboard');
          }
          else {
            // Now try to apply the loading class but do it in the accepteble timeout so we don't show the pending
            stateChangeTimeout = $timeout(function () {
              uiView = uiView || $document.find('#app-container-data[ui-view]').parent();
              uiView.addClass('state-changing');
              $rootScope.$broadcast('event:app-state-changing');
            }, 300);
          }
        }, function () {
          if (toState.authenticate) {
            event.preventDefault();
            uiView = uiView || $document.find('#app-container-data[ui-view]').parent();
            if (uiView) {
              uiView.removeClass('state-changing');
              $rootScope.$broadcast('event:app-state-changed');
            }
            $state.transitionTo('accountSignInUp.accountSignIn', {}, {
              notify: false
            });
          }
        });
      }
    });

    /**
     * Bind state change success
     */
    stateChangeSuccessHandler = $rootScope.$on('$stateChangeSuccess', function () {
      // Cancel the state changing class
      if (stateChangeTimeout) {
        $timeout.cancel(stateChangeTimeout);
      }

      uiView = uiView || $document.find('#app-container-data[ui-view]').parent();
      uiView.removeClass('state-changing');
      $rootScope.$broadcast('event:app-state-changed');

      if (QueueManager.queue.length > 0) {
        QueueManager.start();
      }
      $anchorScroll();
    });

    /**
     * State change error handler.
     */
    stateChangeErrorHandler = $rootScope.$on('$stateChangeError', function (toState, toParams, fromState, fromParams, error) {
      uiView = uiView || $document.find('#app-container-data[ui-view]').parent();
      if (uiView) {
        uiView.removeClass('state-changing');
        $rootScope.$broadcast('event:app-state-changed');
      }
    });

    /**
     * Bind sign in page to event
     */
    loginRequiredHandler = $rootScope.$on('event:auth-loginRequired', function () {
      $rootScope.isAuthenticated = false;
      Auth.signOut(false);
      $state.go('accountSignInUp.accountSignIn');
      uiView = uiView || $document.find('#app-container-data[ui-view]').parent();
      if (uiView) {
        uiView.removeClass('state-changing');
        $rootScope.$broadcast('event:app-state-changed');
      }
    });

    /**
     * Bind login confirmation event
     */
    loginSuccessHandler = $rootScope.$on('event:auth-loginConfirmed', function () {
      $rootScope.isAuthenticated = true;
      Industries.reload();
    });

    /**
     * Bind logout event
     */
    logoutSuccessHandler = $rootScope.$on('event:auth-logoutSuccess', function () {
      $rootScope.isAuthenticated = false;
    });
  }
}());

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('my-memberships/join-pod/partials/form-user-details.tpl.html',
    '<form name="joinPodForm">\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-12">\n' +
    '      Please enter the details of the person you are asking for the leads\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="row form-section-body">\n' +
    '    <div class="col-xs-12">\n' +
    '      <div class="form-group" ng-class="{\'has-error\': (joinPodForm.$submitted || joinPodForm.firstName.$touched) && joinPodForm.firstName.$error.required}">\n' +
    '        <label class="sr-only" for="firstName">First name</label>\n' +
    '        <div ng-show="joinPodForm.$submitted || joinPodForm.firstName.$touched">\n' +
    '          <div class="input-error" ng-show="joinPodForm.firstName.$error.required">Missing first name.</div>\n' +
    '        </div>\n' +
    '        <div class="left-inner-addon">\n' +
    '          <i class="fa fa-user inner-addon"></i>\n' +
    '          <input class="form-control input-lg" type="text" name="firstName" id="firstName"\n' +
    '                  placeholder="First name" ng-required="true" ng-model="joinPod.data.firstName" />\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-12">\n' +
    '      <div class="form-group" ng-class="{\'has-error\': (joinPodForm.$submitted || joinPodForm.email.$touched) && (joinPodForm.email.$error.required || joinPodForm.email.$error.email)}">\n' +
    '        <label class="sr-only" for="email">Email</label>\n' +
    '        <div ng-show="joinPodForm.$submitted || joinPodForm.email.$touched">\n' +
    '          <div class="input-error" ng-show="joinPodForm.email.$error.required">Missing email.</div>\n' +
    '          <div class="input-error" ng-show="joinPodForm.email.$error.email">This is not a valid email.</div>\n' +
    '        </div>\n' +
    '        <div class="left-inner-addon">\n' +
    '          <i class="fa fa-envelope-o inner-addon"></i>\n' +
    '          <input class="form-control input-lg" type="email" name="email" id="email" placeholder="Email"\n' +
    '                 ng-required="true" ng-model="joinPod.data.email" />\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-12">\n' +
    '      <div class="form-group" ng-class="{\'has-error\': (joinPodForm.$submitted || joinPodForm.phoneMobile.$touched) && (joinPodForm.phoneMobile.$error.required || joinPodForm.phoneMobile.$error.email)}">\n' +
    '        <label class="sr-only" for="email">Phone</label>\n' +
    '        <div ng-show="joinPodForm.$submitted || joinPodForm.phoneMobile.$touched">\n' +
    '          <div class="input-error" ng-show="joinPodForm.phoneMobile.$error.required">Missing phone number.</div>\n' +
    '        </div>\n' +
    '        <div class="left-inner-addon">\n' +
    '          <i class="fa fa-phone inner-addon"></i>\n' +
    '          <input class="form-control input-lg" type="tel" name="phoneMobile" id="phoneMobile" placeholder="Phone Number (optional)"\n' +
    '                 ng-required="false" ng-model="joinPod.data.phoneMobile" />\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '</form>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('components/ui/tabs/tabs-manager-directive.tpl.html',
    '<div class="tabs-manager">\n' +
    '  <div class="hidden-sm hidden-md hidden-lg" ng-class="{\'tab-open\': tabsManager.isDropDownOpen}">\n' +
    '    <div ng-class="{\'tab-open\': tabsManager.isDropDownOpen}" ng-if="!tabsManager.blockXsRelocation">\n' +
    '      <action-toolbar class="visible-xs" at-target="{{tabsManager.target}}">\n' +
    '        <li ng-repeat="tab in tabsManager.tabsConfig"\n' +
    '            ng-class="{active: tabsManager.tabManager.isOpen(tab.value)}"\n' +
    '            ng-if="tab.condition ? tab.condition() : true"\n' +
    '            data-tab-name="{{tabsManager.tabId + \'-\' + tab.value}}"\n' +
    '            class="no-animate">\n' +
    '          <a class="no-animate"\n' +
    '             ng-if="tab.url"\n' +
    '             ui-sref="{{tab.url}}"\n' +
    '             ng-click="tabsManager.openTab(null, true, $event)" >\n' +
    '            {{tab.label}}\n' +
    '            <span class="no-animate" ng-if="tab.template" tab-additional-content html="tab.template"></span>\n' +
    '          </a>\n' +
    '          <a class="no-animate"\n' +
    '             ng-if="!tab.url"\n' +
    '             ng-click="tabsManager.openTab(tab.value, true, $event)"\n' +
    '             ng-class="{active: tabsManager.tabManager.isOpen(tab.value)}">\n' +
    '            {{tab.label}}\n' +
    '            <span class="no-animate" ng-if="tab.template" tab-additional-content html="tab.template"></span>\n' +
    '          </a>\n' +
    '        </li>\n' +
    '      </action-toolbar>\n' +
    '    </div>\n' +
    '    <div class="no-animate" ng-if="tabsManager.blockXsRelocation">\n' +
    '      <div class="tab-selector" ng-click="tabsManager.isDropDownOpen = !tabsManager.isDropDownOpen">\n' +
    '        <div class="tab-selected-value">\n' +
    '          {{tabsManager.getActiveLabel()}}\n' +
    '        </div>\n' +
    '        <span class="tab-selector-icon">\n' +
    '          <i class="glyphicon glyphicon-menu-hamburger"></i>\n' +
    '        </span>\n' +
    '      </div>\n' +
    '      <div class="tabs-container">\n' +
    '        <ul class="dropdown-menu slide-if" ng-if="tabsManager.isDropDownOpen">\n' +
    '          <li ng-repeat="tab in tabsManager.tabsConfig" ng-if="tab.condition ? tab.condition() : true" ng-click="tabsManager.isDropDownOpen = false">\n' +
    '            <a ng-if="tab.url" ui-sref="{{tab.url}}" ng-class="{active: tabsManager.tabManager.isOpen(tab.value)}">{{tab.label}}\n' +
    '              <span ng-if="tab.template" tab-additional-content html="tab.template"></span>\n' +
    '            </a>\n' +
    '            <a ng-if="!tab.url" ng-click="tabsManager.tabManager.openTab(tab.value)" ng-class="{active: tabsManager.tabManager.isOpen(tab.value)}">{{tab.label}}\n' +
    '              <span ng-if="tab.template" tab-additional-content html="tab.template"></span>\n' +
    '            </a>\n' +
    '          </li>\n' +
    '        </ul>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <ul class="nav nav-tabs page-nav hidden-xs">\n' +
    '    <li ng-repeat="tab in tabsManager.tabsConfig" ng-if="tab.condition ? tab.condition() : true">\n' +
    '      <a ng-if="tab.url" ui-sref="{{tab.url}}" ng-class="{active: tabsManager.tabManager.isOpen(tab.value)}">{{tab.label}}\n' +
    '        <span ng-if="tab.template" tab-additional-content html="tab.template"></span>\n' +
    '      </a>\n' +
    '      <a ng-if="!tab.url" ng-click="tabsManager.openTab(tab.value)" ng-class="{active: tabsManager.tabManager.isOpen(tab.value)}">{{tab.label}}\n' +
    '        <span ng-if="tab.template" tab-additional-content html="tab.template"></span>\n' +
    '      </a>\n' +
    '    </li>\n' +
    '  </ul>\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('components/ui/table/table-user-popover.tpl.html',
    '<div>\n' +
    '  <div class="user-details">\n' +
    '    <div ng-if="user.mlpUserDetails.showAdvanced" style="width: 100%; position: relative; margin-bottom: 5px;">\n' +
    '      <span user-avatar size="small" is-link="isLink" user="user" style="margin: 0 auto; display: block;"></span>\n' +
    '    </div>\n' +
    '    <p ng-if="user.mlpUserDetails.showAdvanced && user.mlpUserDetails.descriptionText">\n' +
    '      <i class="fa fa-briefcase"></i>\n' +
    '      <span>{{user.mlpUserDetails.descriptionText}}</span>\n' +
    '    </p>\n' +
    '    <p ng-if="user.phoneMobile"><i class="fa fa-phone"></i> <a href="tel:{{user.phoneMobile}}">{{user.phoneMobile}}</a></p>\n' +
    '    <p ng-if="user.email"><i class="fa fa-envelope-o"></i> <a href="mailto:{{user.email}}">{{user.email}}</a></p>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('components/ui/table/table-user-details-directive.tpl.html',
    '<div class="user-details-directive no-select {{addClass}}" ng-class="{\'no-link\': !isLink}">\n' +
    '  <div class="details-container">\n' +
    '    <div class="user-pages">\n' +
    '      <div class="user-page-1 table-user-detail">\n' +
    '        <a ng-if="!nameOnly" class="images-content" ui-sref="user.agreements({id: user.id})" tabindex="{{isLink ? 0 : -1}}">\n' +
    '          <div class="link">\n' +
    '            <span class="glyphicon glyphicon-link"></span>\n' +
    '          </div>\n' +
    '          <div class="selected-user">\n' +
    '            <i class="fa fa-check"></i>\n' +
    '          </div>\n' +
    '          <span user-avatar size="small" is-link="isLink" user="user"></span>\n' +
    '        </a>\n' +
    '        <a ui-sref="user.agreements({id: user.id})" class="details-content" tabindex="{{isLink ? 0 : -1}}">\n' +
    '          <span class="table-user-detail-name">\n' +
    '            {{user.firstName}} {{user.lastName}}\n' +
    '          </span>\n' +
    '          <span ng-if="user[descriptionKey] && !nameOnly" class="table-user-detail-company-name">\n' +
    '            {{user[descriptionKey]}}\n' +
    '          </span>\n' +
    '        </a>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="toggle-btn"\n' +
    '       ng-if="hasDetails && (user.phoneMobile || user.email)"\n' +
    '       popover-placement="bottom"\n' +
    '       popover-trigger="outsideClick"\n' +
    '       popover-append-to-body="true"\n' +
    '       uib-popover-html="htmlPopover">\n' +
    '    <i class="fa fa-ellipsis-h"></i>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('user/messages/messages.tpl.html',
    '<div class="col-xs-12" ng-controller="MessagesCtrl as messages">\n' +
    '  <div class="messages page-no-content">\n' +
    '\n' +
    '\n' +
    '    <div class="messages-list hidden-xs">\n' +
    '\n' +
    '      <!-- Pending overlay -->\n' +
    '      <div class="pending-overlay-white pending-overlay-column opacity-slow-if" ng-if="messages.isLoadingLeads">\n' +
    '        <span class="fa fa-circle-o-notch fa-spin-2x"></span>\n' +
    '        <div class="pending-overlay-description">Loading Leads</div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div style="height:100%; position: relative;" ng-include="\'user/messages/messages-list-partial.tpl.html\'"></div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="general-messages" id="messagesContent">\n' +
    '      <!--<messaging-form class="row" msg-recipient="messages.userProfile"></messaging-form>-->\n' +
    '\n' +
    '      <div class="stepped-wrapper" content-slider>\n' +
    '        <div class="stepped-container">\n' +
    '\n' +
    '\n' +
    '          <div class="step-content messages-panel" data-step-id="0">\n' +
    '            <div class="panel panel-default">\n' +
    '              <div class="panel-heading">\n' +
    '                <div style="display: flex; justify-content: space-between; align-items: center;">\n' +
    '                  <h4>Direct Messages</h4>\n' +
    '                  <button style="position:relative;" title="Open Leads Messages"\n' +
    '                          class="btn-sm btn-primary visible-xs"\n' +
    '                          ng-click="messages.openModal()"\n' +
    '                          ng-disabled="messages.isLoadingLeads">\n' +
    '                    <div class="pending-overlay-white pending-overlay-column opacity-slow-if" ng-if="messages.isLoadingLeads">\n' +
    '                      <span class="fa fa-circle-o-notch fa-spin-2x" style="color: black;"></span>\n' +
    '                    </div>\n' +
    '                    <i class="fa fa-list"></i>\n' +
    '                  </button>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="panel-list">\n' +
    '                <scroll-navigator scroll-target-element-id="messages.targetMsgContainer"\n' +
    '                                  scroll-offset-y="-20"\n' +
    '                                  scroll-message="Scroll to type the message (or click)"\n' +
    '                                  scroll-target-change-delay="300"\n' +
    '                                  on-click-callback="messages.scrollTo"></scroll-navigator>\n' +
    '                <messaging-form msg-id="\'direct-messages\'"\n' +
    '                                msg-recipient="messages.userProfile"\n' +
    '                                msg-load-callback="messages.onDirectMessagesLoad"></messaging-form>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '\n' +
    '          <div class="step-content messages-panel" data-step-id="1">\n' +
    '            <div class="panel panel-default">\n' +
    '              <div class="panel-heading" style="display: flex; justify-content: space-between; padding: 16px 20px;">\n' +
    '                <div class="visible-xs" style="display: flex; flex-direction: column; width: 100%; text-align: center;">\n' +
    '                  <div style="margin-bottom: 5px;">\n' +
    '                    Lead Messages\n' +
    '                  </div>\n' +
    '                  <div style="display: flex; justify-content: space-between;">\n' +
    '                    <button class="btn-sm btn-primary" ng-click="messages.switchToDirect()" title="To Direct Messages"><i class="fa fa-hashtag"></i></button>\n' +
    '                    <button class="btn-sm btn-default" ng-click="messages.openInfo = !messages.openInfo;" title="Open Lead Details">{{messages.openInfo ? \'Hide\' : \'Show\'}} details</button>\n' +
    '                    <button class="btn-sm btn-primary" ng-click="messages.openModal();" title="Open Leads Messages"><i class="fa fa-list"></i></button>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '                <div class="hidden-xs" style="display: flex; width: 100%; align-items: center; justify-content: space-between;">\n' +
    '                  <div style="display: flex; align-items: center;">\n' +
    '                    <h4>Lead Messages</h4>\n' +
    '                    <button title="Show Lead Details" class="btn-sm btn-default" style="margin-left: 10px;" ng-click="messages.openInfo = !messages.openInfo;">{{messages.openInfo ? \'Hide\' : \'Show\'}} details</button>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="panel-list" style="position: relative;">\n' +
    '                <div class="lead-info slide-if" style="z-index: 1; position: absolute; top: 0px; left: 0px; width: 100%; background: white; box-shadow: 0px 2px 5px -2px black;" ng-if="messages.openInfo">\n' +
    '\n' +
    '                  <!-- LEAD DETAILS -->\n' +
    '                  <div class="row form-group">\n' +
    '                    <div class="col-xs-12">\n' +
    '                      <div class="tab-content-w-header">\n' +
    '                        <div class="lead-detail lead-detail-split lead-detail-row">\n' +
    '                          <div class="lead-detail-left" >\n' +
    '                            <div class="lead-detail-desc">\n' +
    '                              <!--<i class="fa fa-comment-o"></i>-->\n' +
    '                              <span>Details</span>\n' +
    '                            </div>\n' +
    '                            <div class="lead-detail-val">\n' +
    '                              {{messages.currentLead.details}}\n' +
    '                            </div>\n' +
    '                          </div>\n' +
    '\n' +
    '                          <div class="lead-detail-right">\n' +
    '                            <div class="lead-detail-desc">\n' +
    '                              <!--<i class="fa fa-comment-o"></i>-->\n' +
    '                              <span>Contact</span>\n' +
    '                            </div>\n' +
    '                            <div class="lead-detail-val">\n' +
    '                              <div class="lead-detail-row">\n' +
    '                                <div class="lead-detail-desc">\n' +
    '                                  <i class="fa-lead fa fa-user"></i>\n' +
    '                                </div>\n' +
    '                                <div class="lead-detail-val">\n' +
    '                                  <div class="lead-detail-split-top">\n' +
    '                                    {{messages.currentLead.firstName}} {{messages.currentLead.lastName}}\n' +
    '                                  </div>\n' +
    '                                </div>\n' +
    '                              </div>\n' +
    '\n' +
    '                              <div class="lead-detail-row" ng-if="messages.currentLead.phone">\n' +
    '                                <div class="lead-detail-desc">\n' +
    '                                  <i class="fa-lead fa fa-phone"></i>\n' +
    '                                </div>\n' +
    '                                <div class="lead-detail-val">\n' +
    '                                  <a href="tel:{{messages.currentLead.phone}}">{{messages.currentLead.phone}}</a>\n' +
    '                                </div>\n' +
    '                              </div>\n' +
    '\n' +
    '                              <div class="lead-detail-row" ng-if="messages.currentLead.email">\n' +
    '                                <div class="lead-detail-desc">\n' +
    '                                  <i class="fa-lead fa fa-envelope-o"></i>\n' +
    '                                </div>\n' +
    '                                <div class="lead-detail-val">\n' +
    '                                  <a href="mailto:{{messages.currentLead.email}}">{{messages.currentLead.email}}</a>\n' +
    '                                </div>\n' +
    '                              </div>\n' +
    '                            </div>\n' +
    '                          </div>\n' +
    '                        </div>\n' +
    '                      </div>\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '                  <div class="row form-group">\n' +
    '                    <div class="col-xs-12 text-center">\n' +
    '                      <button class="btn-sm btn-primary" ng-click="messages.openDetails()">Open Lead</button>\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '\n' +
    '\n' +
    '                </div>\n' +
    '                <messaging-form\n' +
    '                  msg-id="\'lead-messages\'"\n' +
    '                  msg-request-params="{\'idLead\': messages.currentLead.id}"\n' +
    '                  msg-watch-params="true"\n' +
    '                  msg-recipient="messages.userProfile"\n' +
    '                  msg-load-callback="messages.onMessagesLoad"></messaging-form>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('user/messages/messages-list-partial.tpl.html',
    '<div class="panel panel-default">\n' +
    '  <div class="panel-heading">\n' +
    '    <div style="display: flex; justify-content: space-between; align-items: center;">\n' +
    '      <h4>Messages List</h4>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="lead-item lead-item-major cursor-pointer"\n' +
    '       ng-click="messages.switchToDirect();"\n' +
    '       ng-class="{\'selected\' : !messages.currentLead}">\n' +
    '    <div>\n' +
    '      # Direct Messages\n' +
    '    </div>\n' +
    '    <div class="hover-content">\n' +
    '      <div class="lead-chat">\n' +
    '        <a class="cursor-pointer"><i class="fa fa-chevron-right"></i></a>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="lead-item lead-item-major">\n' +
    '    <div>\n' +
    '      # Lead Messages\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="panel-list">\n' +
    '    <div class="pending-overlay-white pending-overlay-column opacity-slow-if" ng-if="messages.isLoadingMsg" style="opacity: 0.5;"></div>\n' +
    '\n' +
    '    <div class="lead-item" ng-if="!messages.isLoadingLeads && (!messages.leads || messages.leads.length === 0)">\n' +
    '      You have no leads (sent or received).\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="lead-item lead-item-sub cursor-pointer"\n' +
    '         ng-repeat="lead in messages.leads"\n' +
    '         ng-click="messages.navigateTo(\'messagesContent\', {offset: 150}, lead)"\n' +
    '         ng-class="{\'selected\': messages.currentLead.id == lead.id}">\n' +
    '      <div class="lead-type">\n' +
    '        <i class="fa" ng-class="{\'fa-paper-plane\': lead.leadType === \'sent\', \'fa-paper-plane-o\': lead.leadType === \'received\'}"></i>\n' +
    '      </div>\n' +
    '      <div class="lead-content">\n' +
    '        <div>{{lead.firstName}} {{lead.lastName}}</div>\n' +
    '        <div class="lead-date">{{lead.dateSent | date:\'d MMM yyyy\'}}</div>\n' +
    '      </div>\n' +
    '      <div class="hidden-xs hover-content" style="display: flex; align-items: center;">\n' +
    '        <div class="lead-chat">\n' +
    '          <a class="cursor-pointer"><i class="fa fa-chevron-right"></i></a>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('user/messages/messages-list-modal.tpl.html',
    '<div class="modal-content-container">\n' +
    '  <div class="messages">\n' +
    '    <div class="messages-list" ng-include="\'user/messages/messages-list-partial.tpl.html\'"></div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('user/marketplace/marketplace.tpl.html',
    '<div class="col-xs-12" ng-controller="UserMarketplaceCtrl as userMarketplace">\n' +
    '  <div class="page-content form-group">\n' +
    '    <marketplace-listings items="userMarketplace.items" user="userMarketplace.userProfile" has-user-avatar="false"></marketplace-listings>\n' +
    '  </div>\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-12 col-sm-12 col-md-12 text-center">\n' +
    '      <span class="sub-title-description" style="margin-left: 12px;">\n' +
    '        <a ng-click="userMarketplace.openMarketplace()" class="cursor-pointer">Open Marketplace</a> for all available subscription packages.\n' +
    '      </span>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('user/leads/sent.tpl.html',
    '<div class="col-xs-12" ng-controller="UserSentLeadsCtrl as userSentLeads" id="user-sent-leads-ctrl-container">\n' +
    '  <div class="page-no-content">\n' +
    '    <div class="row" ng-hide="userSentLeads.list.recordsExist() || !userSentLeads.list.isInitialised()">\n' +
    '      <div class="col-xs-12">\n' +
    '        <div class="page-empty-section text-center">\n' +
    '          <i class="fa fa-paper-plane fa-2x text-light-colour"></i>\n' +
    '          <div class="page-empty-section-text">\n' +
    '            You haven\'t sent any leads to {{userSentLeads.userProfile.fullName}}\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="table-responsive" ng-show="userSentLeads.list.recordsExist() && userSentLeads.list.isInitialised()">\n' +
    '      <table st-table="userSentLeads.list.getRecords()" st-pipe="userSentLeads.list.load" class="table page-content-table allow-wrap">\n' +
    '        <thead class="hidden-xs">\n' +
    '        <tr>\n' +
    '          <th class="col-sm-3">Date sent</th>\n' +
    '          <th class="col-sm-4">Name</th>\n' +
    '          <th class="col-sm-3">Status</th>\n' +
    '          <th class="col-sm-2 text-right">Details</th>\n' +
    '        </tr>\n' +
    '        </thead>\n' +
    '        <tbody ng-hide="userSentLeads.list.isLoading()">\n' +
    '        <tr ng-repeat="row in userSentLeads.list.getRecords()">\n' +
    '          <td class="col-xs-12 col-sm-3">\n' +
    '            <table class="table-cell-main-content">\n' +
    '              <tr class="row-center-items row-space-between">\n' +
    '                <td class="visible-xs" single-expand sub-content="userSentLeads.tableSubContentVisible" sub-content-idx="$index"></td>\n' +
    '                <td>\n' +
    '                  <span class="hidden-xs">{{row.dateSent | date:\'d MMM yyyy\'}}</span>\n' +
    '                  <span class="visible-xs text-muted">{{row.dateSent | date:\'d MMM yyyy\'}}<br/></span>\n' +
    '                  <span class="visible-xs">{{row.firstName}} {{row.lastName}}</span>\n' +
    '                </td>\n' +
    '                <td class="visible-xs text-right">\n' +
    '                  <span class="cursor-pointer" ng-click="userSentLeads.tableSubContentVisible[$index] = !userSentLeads.tableSubContentVisible[$index]">\n' +
    '                    <i class="fa fa-ellipsis-h"></i>\n' +
    '                  </span>\n' +
    '                </td>\n' +
    '              </tr>\n' +
    '            </table>\n' +
    '            <div class="table-cell-sub-content-container slide-if" ng-if="userSentLeads.tableSubContentVisible[$index]">\n' +
    '              <div class="table-cell-sub-content visible-xs">\n' +
    '                <span class="text-muted">Status</span><br/>\n' +
    '                <span class="text-normal">{{userSentLeads.statuses[row.status]}}</span>\n' +
    '              </div>\n' +
    '              <div class="table-cell-sub-content visible-xs">\n' +
    '                <span class="text-muted">Details</span><br/>\n' +
    '                <span class="text-normal">\n' +
    '                  <a class="cursor-pointer" ng-click="userSentLeads.openDetail($index)">View lead</a>\n' +
    '                </span>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </td>\n' +
    '          <td class="col-sm-4 hidden-xs">{{row.firstName}} {{row.lastName}}</td>\n' +
    '          <td class="col-sm-3 hidden-xs">{{userSentLeads.statuses[row.status]}}</td>\n' +
    '          <td class="col-sm-2 hidden-xs text-right">\n' +
    '            <a class="cursor-pointer" ng-click="userSentLeads.openDetail($index)">View lead</a>\n' +
    '          </td>\n' +
    '        </tr>\n' +
    '        </tbody>\n' +
    '\n' +
    '        <tbody ng-if="userSentLeads.list.isLoading()">\n' +
    '        <tr>\n' +
    '          <td colspan="4" class="col-xs-12 text-center">\n' +
    '            <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>\n' +
    '          </td>\n' +
    '        </tr>\n' +
    '        </tbody>\n' +
    '      </table>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row" ng-show="!userSentLeads.list.isInitialised()">\n' +
    '      <div class="col-xs-12 text-center">\n' +
    '        <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('user/leads/received.tpl.html',
    '<div class="col-xs-12" ng-controller="UserReceivedLeadsCtrl as userReceivedLeads" id="user-received-leads-ctrl-container">\n' +
    '  <div class="page-no-content">\n' +
    '    <div class="row" ng-hide="userReceivedLeads.list.recordsExist() || !userReceivedLeads.list.isInitialised()">\n' +
    '      <div class="col-xs-12">\n' +
    '        <div class="page-empty-section text-center">\n' +
    '          <i class="fa fa-paper-plane fa-2x text-light-colour"></i>\n' +
    '          <div class="page-empty-section-text">\n' +
    '            You have no leads from {{userReceivedLeads.userProfile.fullName}}\n' +
    '            <div style="margin-top: 15px;" ng-if="!userReceivedLeads.receiveLeads">\n' +
    '              <button class="btn btn-primary" ng-click="userReceivedLeads.openAskForLeads()">Ask for Leads</button>\n' +
    '            </div>\n' +
    '            <div style="margin-top: 15px; font-weight: normal" class="sub-title-description"\n' +
    '                 ng-if="userReceivedLeads.receiveLeads &&\n' +
    '                 (userReceivedLeads.receiveLeads.idUserSender === userReceivedLeads.myProfile.id || (userReceivedLeads.receiveLeads.revision && userReceivedLeads.receiveLeads.idUserSender !== userReceivedLeads.myProfile.id))\n' +
    '                 && !userReceivedLeads.receiveLeads.isAccepted">\n' +
    '              Waiting for user to accept the <a ui-sref="user.agreements">agreement proposal</a>.\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="table-responsive" ng-show="userReceivedLeads.list.recordsExist() && userReceivedLeads.list.isInitialised()">\n' +
    '      <table st-table="userReceivedLeads.list.getRecords()" st-pipe="userReceivedLeads.list.load" class="table page-content-table allow-wrap">\n' +
    '        <thead class="hidden-xs">\n' +
    '        <tr>\n' +
    '          <th class="col-sm-3">Date received</th>\n' +
    '          <th class="col-sm-4">Name</th>\n' +
    '          <th class="col-sm-3">Status</th>\n' +
    '          <th class="col-sm-2 text-right">Details</th>\n' +
    '        </tr>\n' +
    '        </thead>\n' +
    '        <tbody ng-hide="userReceivedLeads.list.isLoading()">\n' +
    '        <tr ng-repeat="row in userReceivedLeads.list.getRecords()">\n' +
    '          <td class="col-xs-12 col-sm-3">\n' +
    '            <table class="table-cell-main-content">\n' +
    '              <tr class="row-center-items row-space-between">\n' +
    '                <td class="visible-xs" single-expand sub-content="userReceivedLeads.tableSubContentVisible" sub-content-idx="$index"></td>\n' +
    '                <td>\n' +
    '                  <span class="hidden-xs">{{row.dateSent | date:\'d MMM yyyy\'}}</span>\n' +
    '                  <span class="visible-xs text-muted">{{row.dateSent | date:\'d MMM yyyy\'}}<br/></span>\n' +
    '                  <span class="visible-xs">{{row.firstName}} {{row.lastName}}</span>\n' +
    '                </td>\n' +
    '                <td class="visible-xs text-right">\n' +
    '                  <span class="cursor-pointer" ng-click="userReceivedLeads.tableSubContentVisible[$index] = !userReceivedLeads.tableSubContentVisible[$index]">\n' +
    '                    <i class="fa fa-ellipsis-h"></i>\n' +
    '                  </span>\n' +
    '                </td>\n' +
    '              </tr>\n' +
    '            </table>\n' +
    '            <div class="table-cell-sub-content-container slide-if" ng-if="userReceivedLeads.tableSubContentVisible[$index]">\n' +
    '              <div class="table-cell-sub-content visible-xs">\n' +
    '                <span class="text-muted">Status</span><br/>\n' +
    '                <span class="text-normal" ng-if="(row.status !== \'open\' && row.status !== \'new\') || row.isCoOwner">\n' +
    '                  {{userReceivedLeads.statuses[row.status]}}<span ng-if="row.isCoOwner" class="text-muted"> (shared)</span>\n' +
    '                </span>\n' +
    '                <div ng-if="(row.status === \'open\' || row.status === \'new\') && !row.isCoOwner" uib-dropdown class="btn-group page-filter dropdown-large" uib-dropdown dropdown-append-to-body="true" >\n' +
    '                  <button id="lead-status-{{$index}}" type="button" class="btn" uib-dropdown-toggle>\n' +
    '                    {{userReceivedLeads.statuses[row.status]}} <span class="caret"></span>\n' +
    '                  </button>\n' +
    '                  <ul class="dropdown-menu" uib-dropdown-menu role="menu" aria-labelledby="lead-status-{{$index}}">\n' +
    '                    <li role="menuitem"><a>{{row.status === \'open\' ? \'Open\' : \'New\'}}</a></li>\n' +
    '                    <li role="menuitem"><a ng-click="userReceivedLeads.closeLead($index, \'successful\')">Successful</a></li>\n' +
    '                    <li role="menuitem"><a ng-click="userReceivedLeads.closeLead($index, \'unsuccessful\')">Unsuccessful</a></li>\n' +
    '                  </ul>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="table-cell-sub-content visible-xs">\n' +
    '                <span class="text-muted">Details</span><br/>\n' +
    '                <span class="text-normal"><a class="cursor-pointer" ng-click="userReceivedLeads.openDetail($index)">View lead</a></span>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </td>\n' +
    '          <td class="col-sm-4 hidden-xs">{{row.firstName}} {{row.lastName}}</td>\n' +
    '          <td class="col-sm-3 hidden-xs prevent-wrap" style="min-width: 130px;">\n' +
    '            <span ng-if="(row.status !== \'open\' && row.status !== \'new\') || row.isCoOwner">\n' +
    '              {{userReceivedLeads.statuses[row.status]}}\n' +
    '            </span>\n' +
    '            <div ng-if="(row.status === \'open\' || row.status === \'new\') && !row.isCoOwner" class="btn-group page-filter" uib-dropdown dropdown-append-to-body="true">\n' +
    '              <button id="lead-status-{{$index}}" type="button" class="btn" uib-dropdown-toggle>\n' +
    '                {{userReceivedLeads.statuses[row.status]}} <span class="caret"></span>\n' +
    '              </button>\n' +
    '              <ul class="dropdown-menu" uib-dropdown-menu role="menu" aria-labelledby="lead-status-{{$index}}">\n' +
    '                <li role="menuitem"><a>{{row.status === \'open\' ? \'Open\' : \'New\'}}</a></li>\n' +
    '                <li role="menuitem"><a ng-click="userReceivedLeads.closeLead($index, \'successful\')">Successful</a></li>\n' +
    '                <li role="menuitem"><a ng-click="userReceivedLeads.closeLead($index, \'unsuccessful\')">Unsuccessful</a></li>\n' +
    '              </ul>\n' +
    '            </div>\n' +
    '          </td>\n' +
    '          <td class="col-sm-2 hidden-xs text-right prevent-wrap"><a class="cursor-pointer" ng-click="userReceivedLeads.openDetail($index)">View lead</a></td>\n' +
    '        </tr>\n' +
    '        </tbody>\n' +
    '\n' +
    '        <tbody ng-if="userReceivedLeads.list.isLoading()">\n' +
    '        <tr>\n' +
    '          <td colspan="4" class="col-xs-12 text-center">\n' +
    '            <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>\n' +
    '          </td>\n' +
    '        </tr>\n' +
    '        </tbody>\n' +
    '      </table>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row" ng-show="!userReceivedLeads.list.isInitialised()">\n' +
    '      <div class="col-xs-12 text-center">\n' +
    '        <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('user/connections/connections.tpl.html',
    '<div class="col-xs-12" id="connections-ctrl-container" ng-controller="ConnectionsCtrl as connections">\n' +
    '  <div class="page-content">\n' +
    '\n' +
    '    <!-- No connections content -->\n' +
    '    <div class="row" ng-hide="connections.list.recordsExist() || !connections.list.isInitialised()">\n' +
    '      <div class="col-xs-12">\n' +
    '        <div class="page-empty-section text-center">\n' +
    '          <i class="fa fa-users fa-2x text-light-colour"></i>\n' +
    '          <div class="page-empty-section-text">\n' +
    '            {{connections.userProfile.fullName}} doesn\'t have any connections.\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <!-- Search Input box -->\n' +
    '    <div class="row" ng-show="connections.list.recordsExist() && connections.list.isInitialised()">\n' +
    '      <div class="col-xs-12 col-sm-12 col-md-12">\n' +
    '        <span class="sub-title-description">Following is the list of businesses that {{connections.userProfile.firstName}} is sending leads to</span>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row form-section-body" ng-show="connections.list.recordsExist() && connections.list.isInitialised()">\n' +
    '      <div class="col-xs-12 col-sm-5 col-md-4">\n' +
    '        <div class="form-group page-filter">\n' +
    '          <label class="sr-only" for="searchName">Search</label>\n' +
    '          <div class="left-inner-addon">\n' +
    '            <i class="fa fa-search inner-addon"></i>\n' +
    '            <form name="connectionsSearchForm" ng-submit="connections.search()">\n' +
    '              <input class="form-control input-lg"\n' +
    '                     type="text"\n' +
    '                     name="searchName"\n' +
    '                     id="searchName"\n' +
    '                     placeholder="Search"\n' +
    '                     ng-model="connections.searchName"\n' +
    '                     ng-blur="connections.search()"/>\n' +
    '            </form>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="table-responsive fixed-header" ng-show="connections.list.recordsExist() && connections.list.isInitialised()">\n' +
    '      <div class="table-container">\n' +
    '        <table class="table page-content-table allow-wrap" st-table="connections.list.getRecords()" st-pagination-scroll st-pipe="connections.list.load">\n' +
    '\n' +
    '          <thead class="hidden-xs">\n' +
    '            <tr>\n' +
    '              <th class="col-sm-4 col-md-3">Name</th>\n' +
    '              <th class="col-sm-4 col-md-5">Industry</th>\n' +
    '              <th class="col-sm-2 col-md-2">Reward (up to)</th>\n' +
    '              <th class="col-sm-2 col-md-2 text-center" style="padding-right: 10px;">Join</th>\n' +
    '            </tr>\n' +
    '          </thead>\n' +
    '\n' +
    '          <tbody ng-hide="connections.list.isLoading() && !connections.list.isLoadingMoreRecords()">\n' +
    '            <tr ng-repeat="row in connections.list.getRecords()" header-check>\n' +
    '              <td class="col-xs-12 col-sm-4 col-md-3">\n' +
    '                  <table class="table-cell-main-content">\n' +
    '                    <tr class="row-center-items row-space-between">\n' +
    '                      <td class="visible-xs" single-expand sub-content="connections.tableSubContentVisible" sub-content-idx="$index"></td>\n' +
    '                    <td>\n' +
    '                      <table-user-details user="row.member"></table-user-details>\n' +
    '                    </td>\n' +
    '                    <td class="visible-xs text-right">\n' +
    '                      <span class="cursor-pointer">\n' +
    '                        <i class="fa fa-ellipsis-h"></i>\n' +
    '                      </span>\n' +
    '                    </td>\n' +
    '                    </tr>\n' +
    '                  </table>\n' +
    '\n' +
    '                <!-- This content applies only for the small devices and is driven by the "tableSubContentVisible" property -->\n' +
    '                <div class="table-cell-sub-content-container slide-if" ng-if="connections.tableSubContentVisible[$index]">\n' +
    '                  <div ng-if="row.member.industry.name" class="table-cell-sub-content visible-xs">\n' +
    '                    <span class="text-muted">Industry</span><br/>\n' +
    '                    <span class="text-normal">{{row.member.industry.name}}</span>\n' +
    '                    <span class="text-normal" ng-if="row.member.subIndustry"><br/>{{row.member.subIndustry.name}}</span>\n' +
    '                  </div>\n' +
    '                  <div ng-if="row.member.highestReward" class="table-cell-sub-content visible-xs">\n' +
    '                    <span class="text-muted">Reward (up to)</span><br/>\n' +
    '                    <span ng-if="row.member.highestReward" class="text-normal">{{row.member.highestReward | currency:\'$\':0}}</span>\n' +
    '                  </div>\n' +
    '                  <div class="table-cell-sub-content visible-xs">\n' +
    '                    <span ng-show="$index == connections.invitePendingFor"><i class="fa fa-1x fa-circle-o-notch fa-spin-2x"></i></span>\n' +
    '                    <button ng-hide="row.member.acceptedSendLeadsContractExists || $index == connections.invitePendingFor" ng-disabled="connections.invitePending || row.member.acceptedSendLeadsContractExists " ng-click="connections.quickJoin($index);" class="btn-sm btn-primary">Join</button>\n' +
    '                    <span ng-show="row.member.acceptedSendLeadsContractExists" class="text-muted">\n' +
    '                      <i class="fa fa-check" aria-hidden="true"></i>\n' +
    '                      <span> (Connected)</span>\n' +
    '                    </span>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </td>\n' +
    '              <td class="col-sm-4 col-md-5 hidden-xs">\n' +
    '                {{row.member.industry.name}}\n' +
    '                <span ng-if="row.member.subIndustry">\n' +
    '                  <br/>{{row.member.subIndustry.name}}\n' +
    '                </span>\n' +
    '              </td>\n' +
    '              <td class="col-sm-2 col-md-2 hidden-xs text-middle">\n' +
    '                <span ng-if="row.member.highestReward" class="text-normal">{{row.member.highestReward | currency:\'$\':0}}</span>\n' +
    '              </td>\n' +
    '              <td class="col-sm-2 col-md-2 hidden-xs text-center text-middle" style="overflow: hidden;">\n' +
    '                <span ng-show="$index == connections.invitePendingFor"><i class="fa fa-1x fa-circle-o-notch fa-spin-2x"></i></span>\n' +
    '                <button ng-hide="row.member.acceptedSendLeadsContractExists || $index == connections.invitePendingFor" ng-disabled="connections.invitePending || row.member.acceptedSendLeadsContractExists " ng-click="connections.quickJoin($index);" class="btn btn-primary">Join</button>\n' +
    '                <span ng-show="row.member.acceptedSendLeadsContractExists" title="This user is a member of your pod" class="text-muted"><i class="fa fa-check" aria-hidden="true"></i></span>\n' +
    '              </td>\n' +
    '            </tr>\n' +
    '            <!--<tr ng-if="connections.list.isLoadingMoreRecords()">\n' +
    '              <td colspan="5" class="col-xs-12 text-center">\n' +
    '                <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>\n' +
    '              </td>\n' +
    '            </tr>-->\n' +
    '          </tbody>\n' +
    '          <tbody ng-if="connections.list.isLoading() && !connections.list.isLoadingMoreRecords()" class="slide-if" style="display: block; border: 0px solid transparent; height: auto;">\n' +
    '            <tr>\n' +
    '              <td colspan="5" class="col-xs-12 text-center">\n' +
    '                <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>\n' +
    '              </td>\n' +
    '            </tr>\n' +
    '          </tbody>\n' +
    '        </table>\n' +
    '        <div ng-if="connections.list.isLoadingMoreRecords()" class="slide-if" style="position: absolute; top:0px; left: 0px; display: flex; width: 100%; height: 100%; align-items: center; justify-content: center;">\n' +
    '          <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <!-- Spin if the list is not yet initialized -->\n' +
    '    <div class="row" ng-show="!connections.list.isInitialised()">\n' +
    '      <div class="col-xs-12 text-center">\n' +
    '        <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('user/agreements/agreements.tpl.html',
    '<div class="col-xs-12" id="agreements-ctrl-container" ng-controller="AgreementsCtrl as agreements">\n' +
    '\n' +
    '  <div ng-if="agreements.userProfile.isBusinessAccountActive"\n' +
    '       id="send-leads-agreements-container"\n' +
    '       class="page-content page-content-with-header"\n' +
    '       ng-class="{\'agreement-acceptance-awaiting\': !agreements.sendLeads.revision && agreements.sendLeads.idUserSender !== agreements.myProfile.id && agreements.sendLeads && !agreements.sendLeads.isAccepted}">\n' +
    '    <div class="page-content-header">\n' +
    '      <div class="row row-center-items">\n' +
    '        <div class="col-xs-12 text-center">\n' +
    '          <div class="page-header-title">Send leads to {{agreements.userProfile.fullName}}</div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="revision-message-detail slide-if" ng-if="agreements.sendLeads && !agreements.sendLeads.revision && agreements.sendLeads.idUserSender !== agreements.myProfile.id && !agreements.sendLeads.isAccepted">\n' +
    '      <div class="row">\n' +
    '        <div class="col-xs-12">\n' +
    '          Accept the suggested reward from {{agreements.userProfile.fullName}} or revise the agreement.\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="revision-message-detail slide-if" ng-if="agreements.sendLeads && (agreements.sendLeads.idUserSender === agreements.myProfile.id || (agreements.sendLeads.revision && agreements.sendLeads.idUserSender !== agreements.myProfile.id)) && !agreements.sendLeads.isAccepted">\n' +
    '      <div class="row">\n' +
    '        <div class="col-xs-12">\n' +
    '          You sent a proposal to {{agreements.userProfile.fullName}} on {{agreements.sendLeads.dateSent | date:\'d MMM yyyy\'}}\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="page-content-body">\n' +
    '      <!--<div class="row" ng-if="agreements.sendLeads">\n' +
    '        <div class="col-xs-12">\n' +
    '          <div class="revision-message-detail" ng-if="!agreements.sendLeads.revision && agreements.sendLeads.idUserSender !== agreements.myProfile.id && !agreements.sendLeads.isAccepted">\n' +
    '            Accept the suggested reward from {{agreements.userProfile.fullName}} or revise the agreement.\n' +
    '          </div>\n' +
    '          <div class="revision-message-detail" ng-if="(agreements.sendLeads.idUserSender === agreements.myProfile.id || (agreements.sendLeads.revision && agreements.sendLeads.idUserSender !== agreements.myProfile.id)) && !agreements.sendLeads.isAccepted">\n' +
    '            You sent a proposal to {{agreements.userProfile.fullName}} on {{agreements.sendLeads.dateSent | date:\'d MMM yyyy\'}}\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>-->\n' +
    '      <div class="row" ng-if="agreements.sendLeads">\n' +
    '        <agreement-pricing-detail\n' +
    '          style="position: relative;display: block;"\n' +
    '          class="clearfix form-group"\n' +
    '          agreement="agreements.sendLeads"\n' +
    '          label="\'I receive\'"></agreement-pricing-detail>\n' +
    '\n' +
    '        <div class="hidden-xs col-xs-12 text-center" ng-if="agreements.sendLeads && !agreements.sendLeads.revise">\n' +
    '          <button class="btn btn-default" ng-click="agreements.switchReviseSendLeadsContent()">Revise</button>\n' +
    '          <button\n' +
    '            ng-if="!agreements.sendLeads.revision && agreements.sendLeads.idUserSender !== agreements.myProfile.id && !agreements.sendLeads.isAccepted"\n' +
    '            class="btn btn-success" ng-click="agreements.acceptSendLeads()" ng-disabled="agreements.isLoading">Accept\n' +
    '          </button>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row visible-xs revise-buttons-row" ng-if="agreements.sendLeads && !agreements.sendLeads.revise">\n' +
    '        <div class="text-center visible-xs"\n' +
    '             ng-class="{\'col-xs-6\': !agreements.sendLeads.revision && agreements.sendLeads.idUserSender !== agreements.myProfile.id && !agreements.sendLeads.isAccepted,\n' +
    '                        \'col-xs-12\': !(!agreements.sendLeads.revision && agreements.sendLeads.idUserSender !== agreements.myProfile.id && !agreements.sendLeads.isAccepted)}">\n' +
    '          <button class="btn btn-primary" style="margin: 0px 0px 10px 0px;" ng-click="agreements.sendLead()" ng-if="agreements.sendLeads.isAccepted">\n' +
    '            Send Lead\n' +
    '          </button>\n' +
    '          <button class="btn btn-default" ng-click="agreements.switchReviseSendLeadsContent()">\n' +
    '            Revise\n' +
    '          </button>\n' +
    '        </div>\n' +
    '        <div class="col-xs-6 text-center visible-xs">\n' +
    '          <button\n' +
    '            ng-if="!agreements.sendLeads.revision && agreements.sendLeads.idUserSender !== agreements.myProfile.id && !agreements.sendLeads.isAccepted"\n' +
    '            class="btn btn-success" ng-click="agreements.acceptSendLeads()" ng-disabled="agreements.isLoading">Accept\n' +
    '          </button>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row revision-row" ng-if="agreements.sendLeads.revision">\n' +
    '        <div class="col-xs-9">\n' +
    '          You sent a revised proposal to {{agreements.userProfile.fullName}} on\n' +
    '          {{agreements.sendLeads.revision.dateSent | date:\'d MMM yyyy\'}}\n' +
    '        </div>\n' +
    '        <div class="col-xs-3 text-right">\n' +
    '          <a class="cursor-pointer visible-xs"\n' +
    '             ng-click="agreements.showSendLeadsRevision = !agreements.showSendLeadsRevision">\n' +
    '            <i class="fa fa-ellipsis-h"></i>\n' +
    '          </a>\n' +
    '          <a class="cursor-pointer hidden-xs"\n' +
    '             ng-click="agreements.showSendLeadsRevision = !agreements.showSendLeadsRevision">View details</a>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row revision-row-detail slide-if" ng-if="agreements.showSendLeadsRevision">\n' +
    '        <agreement-pricing-detail agreement="agreements.sendLeads.revision" label="\'I receive\'"></agreement-pricing-detail>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row revision-row slide-if" ng-if="agreements.sendLeads.revise">\n' +
    '        <div class="col-xs-12">\n' +
    '          <div class="row">\n' +
    '            <div class="col-xs-12 form-group">\n' +
    '              Revise\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div>\n' +
    '            <reward-selector class="form-group"\n' +
    '                             has-default-program="agreements.userProfile.hasRewardsProgram && agreements.sendLeads.contractType !== \'Rewards Program\'"\n' +
    '                             has-subscription="true"\n' +
    '                             has-credit-card="false"\n' +
    '                             has-cancel-agreement="true"\n' +
    '                             has-code="false"\n' +
    '                             on-change="agreements.onRewardSelectionChangeSendLeads(formData, isFormValid)">\n' +
    '            </reward-selector>\n' +
    '\n' +
    '            <div class="row">\n' +
    '              <div class="col-xs-6">\n' +
    '                <button class="btn btn-default btn-full-width"\n' +
    '                        ng-click="agreements.cancelSendLeadsRevision()">Cancel\n' +
    '                </button>\n' +
    '              </div>\n' +
    '              <div class="col-xs-6">\n' +
    '                <button id="reviseSendLeadsBtn" class="btn btn-primary btn-full-width" ng-disabled="!agreements.isSendLeadsRewardsFormValid || agreements.isLoading" ng-click="agreements.reviseSendLeads()">Send</button>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row" ng-if="!agreements.sendLeads">\n' +
    '        <div class="col-xs-12 text-center form-group">\n' +
    '          Join {{agreements.userProfile.fullName}}\'s Rewards Program and receive up to {{agreements.userProfile.highestReward | currency: \'$\':0}}\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="col-xs-12 text-center">\n' +
    '          <span ng-if="agreements.invitePending" style="color: black">\n' +
    '            <i class="fa fa-1x fa-circle-o-notch fa-spin-2x"></i>\n' +
    '          </span>\n' +
    '          <a ng-click="agreements.quickJoin()" class="btn btn-primary" ng-disabled="agreements.invitePending">\n' +
    '            Join\n' +
    '          </a>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '  </div>\n' +
    '\n' +
    '  <div id="receive-leads-agreements-container"\n' +
    '       class="page-content page-content-with-header"\n' +
    '       ng-class="{\'agreement-acceptance-awaiting\': !agreements.receiveLeads.revision && agreements.receiveLeads.idUserSender !== agreements.myProfile.id && agreements.receiveLeads && !agreements.receiveLeads.isAccepted}">\n' +
    '\n' +
    '    <div class="page-content-header">\n' +
    '      <div class="row row-center-items">\n' +
    '        <div class="col-xs-12 text-center">\n' +
    '          <div class="page-header-title">Receive leads from {{agreements.userProfile.fullName}}</div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div ng-if="!agreements.isBusinessAccountActive()">\n' +
    '      <div class="page-content-body">\n' +
    '        <div class="row">\n' +
    '          <div class="col-xs-12 text-center form-group">\n' +
    '            You need to activate Business Account in order to be able to receive leads.\n' +
    '            <div class="sub-title-description" ng-if="agreements.trialData.isEligibleForTrial" style="font-size: 14px">\n' +
    '              (You are eligible for {{agreements.trialData.expiresIn}}-day Business Account Trial)\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="col-xs-12 text-center">\n' +
    '            <a ng-click="agreements.activateBusinessAccount()" class="btn btn-primary">\n' +
    '              Activate Business Account <span ng-if="agreements.trialData.isEligibleForTrial"> Trial</span>\n' +
    '            </a>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div ng-if="agreements.isBusinessAccountActive()">\n' +
    '      <div class="revision-message-detail slide-if" ng-if="agreements.receiveLeads && !agreements.receiveLeads.revision && agreements.receiveLeads.idUserSender !== agreements.myProfile.id && !agreements.receiveLeads.isAccepted">\n' +
    '        <div class="row">\n' +
    '          <div class="col-xs-12">\n' +
    '            Accept the suggested reward from {{agreements.userProfile.fullName}} or revise the agreement.\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="revision-message-detail slide-if" ng-if="agreements.receiveLeads && (agreements.receiveLeads.idUserSender === agreements.myProfile.id || (agreements.receiveLeads.revision && agreements.receiveLeads.idUserSender !== agreements.myProfile.id)) && !agreements.receiveLeads.isAccepted">\n' +
    '        <div class="row">\n' +
    '          <div class="col-xs-12">\n' +
    '            You sent a proposal to {{agreements.userProfile.fullName}} on {{agreements.receiveLeads.dateSent | date:\'d MMM yyyy\'}}\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="page-content-body">\n' +
    '\n' +
    '        <div class="row" ng-if="agreements.receiveLeads">\n' +
    '          <agreement-pricing-detail\n' +
    '            style="position: relative;display: block;"\n' +
    '            class="clearfix form-group"\n' +
    '            agreement="agreements.receiveLeads"\n' +
    '            label="\'I pay\'">\n' +
    '          </agreement-pricing-detail>\n' +
    '\n' +
    '          <div class="hidden-xs text-center col-xs-12" ng-if="agreements.receiveLeads && !agreements.receiveLeads.revise">\n' +
    '            <button class="btn btn-default" ng-click="agreements.switchReviseReceiveLeadsContent()">Revise\n' +
    '            </button>\n' +
    '            <button\n' +
    '              ng-if="!agreements.receiveLeads.revision && agreements.receiveLeads.idUserSender !== agreements.myProfile.id && !agreements.receiveLeads.isAccepted"\n' +
    '              class="btn btn-success" ng-click="agreements.acceptReceiveLeads()" ng-disabled="agreements.isLoading">Accept\n' +
    '            </button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row visible-xs revise-buttons-row" ng-if="agreements.receiveLeads && !agreements.receiveLeads.revise">\n' +
    '          <div class="text-center"\n' +
    '               ng-class="{\'col-xs-6\': !agreements.receiveLeads.revision && agreements.receiveLeads.idUserSender !== agreements.myProfile.id && !agreements.receiveLeads.isAccepted,\n' +
    '                        \'col-xs-12\': !(!agreements.receiveLeads.revision && agreements.receiveLeads.idUserSender !== agreements.myProfile.id && !agreements.receiveLeads.isAccepted)}">\n' +
    '            <button class="btn btn-default" ng-click="agreements.switchReviseReceiveLeadsContent()">\n' +
    '              Revise\n' +
    '            </button>\n' +
    '          </div>\n' +
    '          <div class="col-xs-6 text-center">\n' +
    '            <button\n' +
    '              ng-if="!agreements.receiveLeads.revision && agreements.receiveLeads.idUserSender !== agreements.myProfile.id && !agreements.receiveLeads.isAccepted"\n' +
    '              class="btn btn-success" ng-click="agreements.acceptReceiveLeads()" ng-disabled="agreements.isLoading">Accept\n' +
    '            </button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row revision-row" ng-if="agreements.receiveLeads.revision">\n' +
    '          <div class="col-xs-9">\n' +
    '            You sent a revised proposal to {{agreements.userProfile.fullName}} on\n' +
    '            {{agreements.receiveLeads.revision.dateSent | date:\'d MMM yyyy\'}}\n' +
    '          </div>\n' +
    '          <div class="col-xs-3 text-right">\n' +
    '            <a class="cursor-pointer visible-xs"\n' +
    '               ng-click="agreements.showReceivedLeadsRevision = !agreements.showReceivedLeadsRevision">\n' +
    '              <i class="fa fa-ellipsis-h"></i>\n' +
    '            </a>\n' +
    '            <a class="cursor-pointer hidden-xs"\n' +
    '               ng-click="agreements.showReceivedLeadsRevision = !agreements.showReceivedLeadsRevision">View details</a>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row revision-row-detail slide-if" ng-if="agreements.showReceivedLeadsRevision">\n' +
    '          <agreement-pricing-detail agreement="agreements.receiveLeads.revision" label="\'I pay\'"></agreement-pricing-detail>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row revision-row slide-if" ng-if="agreements.receiveLeads.revise">\n' +
    '          <div class="col-xs-12">\n' +
    '            <div class="row">\n' +
    '              <div class="col-xs-12 form-group">\n' +
    '                Revise\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div>\n' +
    '              <reward-selector\n' +
    '                user="agreements.myProfile" class="form-group"\n' +
    '                has-default-program="agreements.receiveLeads.contractType !== \'Rewards Program\'"\n' +
    '                has-subscription="true"\n' +
    '                has-cancel-agreement="true"\n' +
    '                on-change="agreements.onRewardSelectionChangeReceiveLeads(formData, isFormValid)">\n' +
    '              </reward-selector>\n' +
    '\n' +
    '              <div class="row">\n' +
    '                <div class="col-xs-6">\n' +
    '                  <button class="btn btn-default btn-full-width"\n' +
    '                          ng-click="agreements.cancelReceiveLeadsRevision()">Cancel\n' +
    '                  </button>\n' +
    '                </div>\n' +
    '                <div class="col-xs-6">\n' +
    '                  <button id="reviseReceiveLeadsBtn" class="btn btn-primary btn-full-width" ng-disabled="!agreements.isReceiveLeadsRewardsFormValid || agreements.isLoading"\n' +
    '                          ng-click="agreements.reviseReceiveLeads()">Send</button>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row" ng-if="!agreements.receiveLeads">\n' +
    '          <div class="col-xs-12 text-center form-group">\n' +
    '            Would you like to receive leads from {{agreements.userProfile.fullName}}?\n' +
    '          </div>\n' +
    '          <div class="col-xs-12 text-center">\n' +
    '            <a ng-click="agreements.openAskForLeads()" class="btn btn-primary">\n' +
    '              Ask for Leads\n' +
    '            </a>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '  </div>\n' +
    '\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('user/agreements/agreement-pricing-detail-directive.tpl.html',
    '<div class="col-xs-12"\n' +
    '     ng-if="agreementPricingDetail.agreement.contractType === \'Pay Per Lead\' || agreementPricingDetail.agreement.contractType === \'Rewards Program\' || agreementPricingDetail.agreement.contractType === \'Custom Rewards\'">\n' +
    '  <table class="agreement-pricing-detail">\n' +
    '    <tr>\n' +
    '      <td class="col-xs-7 col-sm-8 col-md-8 col-lg-8 phn"><span class="text-muted">Reward</span></td>\n' +
    '      <td class="col-xs-5 col-sm-3 col-md-3 col-lg-3 phn text-right"><span class="text-muted">{{agreementPricingDetail.label}}</span></td>\n' +
    '    </tr>\n' +
    '    <tr ng-repeat="option in agreementPricingDetail.agreement.pricingOptions | orderBy:\'successfulLeadPrice\'">\n' +
    '      <td class="col-xs-7 col-sm-8 col-md-8 col-lg-8 phn"><strong class="capitalize">{{option.name}}</strong></td>\n' +
    '      <td class="col-xs-5 col-sm-3 col-md-3 col-lg-3 phn text-right" valign="top"><strong>{{option.successfulLeadPrice | currency:\'$\':0}}</strong></td>\n' +
    '    </tr>\n' +
    '  </table>\n' +
    '</div>\n' +
    '\n' +
    '<div class="col-xs-7 col-sm-5 col-md-5 col-lg-5" ng-if="agreementPricingDetail.agreement.contractType === \'Subscription\'">\n' +
    '  <span class="text-muted">Reward</span>\n' +
    '  <br/>\n' +
    '  <strong>{{agreementPricingDetail.agreement.contractType}}</strong>\n' +
    '</div>\n' +
    '\n' +
    '<div class="col-xs-5 col-sm-3 col-md-3 col-lg-3 text-right"\n' +
    '     ng-if="agreementPricingDetail.agreement.contractType === \'Subscription\'">\n' +
    '  <span class="text-muted">{{agreementPricingDetail.label}}</span>\n' +
    '  <br/>\n' +
    '  <strong>{{agreementPricingDetail.agreement.subscriptionPrice | currency:\'$\':0}}</strong>\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('my-pod/sent-leads/sent-leads.tpl.html',
    '<div class="col-xs-12" ng-controller="SentLeadsCtrl as sentLeads" id="sent-leads-ctrl-container">\n' +
    '  <div class="page-content">\n' +
    '    <div class="row" ng-hide="sentLeads.list.recordsExist() || !sentLeads.list.isInitialised()">\n' +
    '      <div class="col-xs-12">\n' +
    '        <div class="page-empty-section text-center">\n' +
    '          <i class="fa fa-paper-plane fa-2x text-light-colour"></i>\n' +
    '          <div class="page-empty-section-text">\n' +
    '            You haven\'t sent any leads\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="row" ng-show="sentLeads.list.recordsExist() && sentLeads.list.isInitialised()">\n' +
    '      <div class="col-sm-5 col-md-4">\n' +
    '        <div class="form-group page-filter">\n' +
    '          <label class="sr-only" for="searchLead">Search lead</label>\n' +
    '          <div class="left-inner-addon">\n' +
    '            <i class="fa fa-search inner-addon"></i>\n' +
    '            <form name="searchLeadForm" ng-submit="sentLeads.search()">\n' +
    '              <input class="form-control input-lg" type="search" name="searchLead" id="searchLead"\n' +
    '                     placeholder="Search lead" ng-model="sentLeads.searchLead" ng-blur="sentLeads.search()" />\n' +
    '            </form>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="col-sm-4 col-md-3">\n' +
    '        <div class="form-group btn-group page-filter dropdown-large" uib-dropdown>\n' +
    '          <button id="lead-status" type="button" class="btn" uib-dropdown-toggle>\n' +
    '            {{sentLeads.selectedStatusLabel}} <span class="caret"></span>\n' +
    '          </button>\n' +
    '          <ul class="dropdown-menu" uib-dropdown-menu role="menu" aria-labelledby="lead-status">\n' +
    '            <li role="menuitem" ng-repeat="(value, label) in sentLeads.statuses">\n' +
    '              <a ng-click="sentLeads.selectStatus(value)">{{label}}</a>\n' +
    '            </li>\n' +
    '          </ul>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="table-responsive table-container" ng-show="sentLeads.list.recordsExist() && sentLeads.list.isInitialised()">\n' +
    '      <table st-table="sentLeads.list.getRecords()" st-pagination-scroll st-pipe="sentLeads.list.load" class="table page-content-table">\n' +
    '        <thead class="hidden-xs">\n' +
    '        <tr>\n' +
    '          <th class="col-sm-3">Sent to</th>\n' +
    '          <th class="col-sm-2">Date sent</th>\n' +
    '          <th class="col-sm-3">Name</th>\n' +
    '          <th class="col-sm-2">Status</th>\n' +
    '          <th class="col-sm-2 text-right">Details</th>\n' +
    '        </tr>\n' +
    '        </thead>\n' +
    '        <tbody ng-hide="sentLeads.list.isLoading() && !sentLeads.list.isLoadingMoreRecords()">\n' +
    '        <tr ng-repeat="row in sentLeads.list.getRecords()">\n' +
    '          <td class="col-xs-12 col-sm-3">\n' +
    '            <table class="table-cell-main-content">\n' +
    '              <tr class="row-center-items row-space-between">\n' +
    '                <td class="visible-xs" single-expand sub-content="sentLeads.tableSubContentVisible" sub-content-idx="$index"></td>\n' +
    '                <td>\n' +
    '                  <table-user-details user="row.recipient"></table-user-details>\n' +
    '                </td>\n' +
    '                <td class="visible-xs text-right">\n' +
    '                  <span class="cursor-pointer">\n' +
    '                    <i class="fa fa-ellipsis-h"></i>\n' +
    '                  </span>\n' +
    '                </td>\n' +
    '              </tr>\n' +
    '            </table>\n' +
    '            <div class="table-cell-sub-content-container slide-if" ng-if="sentLeads.tableSubContentVisible[$index]">\n' +
    '              <div class="table-cell-sub-content visible-xs">\n' +
    '                <span class="text-muted">Date sent</span><br/>\n' +
    '                <span class="text-normal">{{row.dateSent | date:\'d MMM yyyy\'}}</span>\n' +
    '              </div>\n' +
    '              <div class="table-cell-sub-content visible-xs">\n' +
    '                <span class="text-muted">Name</span><br/>\n' +
    '                <span class="text-normal">{{row.firstName}} {{row.lastName}}</span>\n' +
    '              </div>\n' +
    '              <div class="table-cell-sub-content visible-xs">\n' +
    '                <span class="text-muted">Status</span><br/>\n' +
    '                <span class="text-normal">{{sentLeads.statuses[row.status]}}</span>\n' +
    '              </div>\n' +
    '              <div class="table-cell-sub-content visible-xs">\n' +
    '                <span class="text-muted">Details</span><br/>\n' +
    '                <span class="text-normal"><a class="cursor-pointer" ng-click="sentLeads.openDetail($index)">View lead</a></span>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </td>\n' +
    '          <td class="col-sm-2 hidden-xs">{{row.dateSent | date:\'d MMM yyyy\'}}</td>\n' +
    '          <td class="col-sm-3 hidden-xs">{{row.firstName}} {{row.lastName}}</td>\n' +
    '          <td class="col-sm-2 hidden-xs">{{sentLeads.statuses[row.status]}}</td>\n' +
    '          <td class="col-sm-2 hidden-xs text-right">\n' +
    '            <a class="cursor-pointer" ng-click="sentLeads.openDetail($index)">View lead</a>\n' +
    '          </td>\n' +
    '        </tr>\n' +
    '        </tbody>\n' +
    '        <tbody ng-if="sentLeads.list.isLoading()">\n' +
    '        <tr>\n' +
    '          <td colspan="5" class="col-xs-12 text-center">\n' +
    '            <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>\n' +
    '          </td>\n' +
    '        </tr>\n' +
    '        </tbody>\n' +
    '      </table>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row" ng-show="!sentLeads.list.isInitialised()">\n' +
    '      <div class="col-xs-12 text-center">\n' +
    '        <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('my-pod/send-invite/send-invite-modal.tpl.html',
    '<div class="modal-content-container send-invite">\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-12">\n' +
    '      <!-- Title -->\n' +
    '      <div class="row">\n' +
    '        <div class="col-xs-12 modal-content-title">\n' +
    '          <span class="sub-title">Send Invite</span>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <!-- ENDOF: Title-->\n' +
    '\n' +
    '      <!-- Content -->\n' +
    '      <div class="row">\n' +
    '        <div class="col-xs-12">\n' +
    '\n' +
    '\n' +
    '          <form name="sendInviteForm" ng-if="!sendInvite.targetUser">\n' +
    '\n' +
    '            <div class="row">\n' +
    '              <div class="col-xs-12">\n' +
    '                Invite the vendor into your pod, so you can start sending the leads and claim the rewards.\n' +
    '              </div>\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="row form-section-body">\n' +
    '              <div class="col-xs-12 reward-input-content">\n' +
    '                <div class="reward-card-holder" ng-class="{flipped: sendInvite.onlyRewards}">\n' +
    '                  <!--<img class="front" src="./images/reward-code2-blur.jpg" />\n' +
    '                  <img class="back" src="./images/reward-code2-blur-back.jpg" />-->\n' +
    '                  <img class="front" src="./images/reward-code-card-front.png" />\n' +
    '                  <img class="back" src="./images/reward-code-card-back.png" />\n' +
    '                </div>\n' +
    '                <div style="padding-left: 10px;">\n' +
    '                  <div style="margin-bottom:15px;">\n' +
    '                    If you have the <strong>Reward Code</strong>, enter it\n' +
    '                    <span class="no-animate" ng-if="sendInvite.hasRewardCode && !sendInvite.displayContactDetails"> here</span>\n' +
    '                    <a class="no-animate"\n' +
    '                       ng-if="!sendInvite.hasRewardCode || sendInvite.displayContactDetails"\n' +
    '                       ng-click="sendInvite.openRewardCode(); ">here</a>\n' +
    '                  </div>\n' +
    '                  <div class="form-group slide-css" style="display: block;" ng-class="{\'has-error\': sendInvite.isRewardCodeInvalid, slide: sendInvite.hasRewardCode && !sendInvite.displayContactDetails}">\n' +
    '\n' +
    '                    <!--<div style="margin-bottom: 15px;">\n' +
    '                      <a style="cursor:pointer;" ng-click="sendInvite.hasRewardCode = false;">Go Back</a>\n' +
    '                    </div>-->\n' +
    '\n' +
    '                    <label class="sr-only" for="rewardCode">Reward Code</label>\n' +
    '                    <div ng-show="sendInvite.isRewardCodeInvalid">\n' +
    '                      <div class="input-error" ng-show="sendInvite.isRewardCodeInvalid">Invalid Reward Code.</div>\n' +
    '                    </div>\n' +
    '                    <div class="left-inner-addon input-group">\n' +
    '                      <i class="fa fa-hashtag inner-addon"></i>\n' +
    '                      <input class="form-control input-lg" type="text" name="rewardCode" id="rewardCode"\n' +
    '                             placeholder="Reward Code"\n' +
    '                             ng-required="false" ng-model="sendInvite.rewardCode"\n' +
    '                             ng-keydown="sendInvite.onRewardCodeEnter($event)"\n' +
    '                             ng-focus="sendInvite.onlyRewards=true; sendInvite.onlySearch=false;"\n' +
    '                             />\n' +
    '                      <span class="input-group-btn">\n' +
    '                        <button class="btn btn-primary btn-md" type="button" ng-click="sendInvite.checkRewardCode()"\n' +
    '                                ng-disabled="!sendInvite.rewardCode || sendInvite.isCheckingCode">\n' +
    '                          {{sendInvite.isCheckingCode ? \'Validating ...\' : sendInvite.isRewardCodeValid ? \'Clear\' : \'Enter\'}}\n' +
    '                        </button>\n' +
    '                      </span>\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '\n' +
    '\n' +
    '            <div class="row" ng-if="!sendInvite.isRewardCodeValid">\n' +
    '              <div class="col-xs-12 text-center form-group">\n' +
    '                <div>\n' +
    '                  <div class="btn-social-separator-text">OR</div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="">\n' +
    '              <div class="row slide-css" ng-class="{slide: !sendInvite.displayContactDetails || sendInvite.only}" style="display:block;">\n' +
    '                <div class="col-xs-12">\n' +
    '                  <div style="margin-bottom: 15px;">\n' +
    '                    Try to search for the business in our directory.\n' +
    '                    <a ng-if="sendInvite.onlyRewards"\n' +
    '                      ng-click="sendInvite.openSearch()"> Use Search</a>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '\n' +
    '              <div class="row slide-css" ng-class="{slide: !sendInvite.displayContactDetails && (!sendInvite.onlyRewards || sendInvite.onlySearch)}" style="display:block;">\n' +
    '                <div class="col-xs-12 form-group">\n' +
    '                  <search-autocomplete\n' +
    '                    control-id="send-invite-autocomplete"\n' +
    '                    placeholder="Search Business"\n' +
    '                    minlength="3"\n' +
    '                    clear-selected="false"\n' +
    '                    text-searching="false"\n' +
    '                    text-no-results="false"\n' +
    '\n' +
    '                    search-service="Search"\n' +
    '                    search-service-end-point="index"\n' +
    '                    remote-url-response-formatter="sendInvite.searchResponseFormatter"\n' +
    '\n' +
    '                    data-field="content.records"\n' +
    '                    selected-object="sendInvite.onSearchItemSelected"\n' +
    '\n' +
    '                    search-fields="search"\n' +
    '                    title-field="value"\n' +
    '                    description-field="categoryDescription"\n' +
    '                    description-field2="timeDescription"\n' +
    '                    category-field="category"\n' +
    '\n' +
    '                    input-name="{{sendInvite.searchControlName}}"\n' +
    '                    input-class="form-control input-lg"\n' +
    '                    match-class="autocomplete-highlight"\n' +
    '                    blur-on-select="true"\n' +
    '                    has-back-button="false"\n' +
    '                    update-input-on-select="true"\n' +
    '\n' +
    '                    focus-in="sendInvite.focusIn()"\n' +
    '                    is-dropdown-relative="true"\n' +
    '\n' +
    '                    override-suggestions="true"\n' +
    '                    search-categories="sendInvite.searchCategories"\n' +
    '                    field-required="false"\n' +
    '                    has-history="true" />\n' +
    '                </div>\n' +
    '              </div>\n' +
    '\n' +
    '              <div class="slide-if" ng-if="!sendInvite.onlyRewards && (sendInvite.emptyResults || sendInvite.displayContactDetails)" style="position:relative;">\n' +
    '                <div class="row" ng-if="sendInvite.displayContactDetails">\n' +
    '                  <div class="col-xs-12" style="margin-bottom: 15px;">\n' +
    '                    <a ng-click="sendInvite.clearContactData(true);">Try Search Again</a>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '                <div ng-if="sendInvite.emptyResults && !sendInvite.displayContactDetails" class="row">\n' +
    '                  <div class="col-xs-12" style="margin-bottom: 15px;">\n' +
    '                    Seems like the business you want to send a lead to is not yet registered. You can provide the details of the business manually\n' +
    '                    <a ng-click="sendInvite.displayContactDetails = true;" > here</a>.\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="slide-if" ng-if="sendInvite.displayContactDetails" style="position:relative;">\n' +
    '                <div class="pending-overlay-light" ng-if="sendInvite.isLoadingUserData">\n' +
    '                  <span class="fa fa-2x fa-circle-o-notch fa-spin-2x"></span>\n' +
    '                </div>\n' +
    '\n' +
    '                <div ng-if="!sendInvite.isLoadingUserData">\n' +
    '                  <!-- Name -->\n' +
    '                  <div ng-if="!sendInvite.isRewardCodeValid" class="row">\n' +
    '                    <div class="col-xs-12" style="margin-bottom: 15px;">\n' +
    '                      Business Contact Details:\n' +
    '                    </div>\n' +
    '\n' +
    '                    <div class="col-xs-12" ng-if="sendInvite.userInvitedProfile">\n' +
    '                      <div>\n' +
    '                        <table-user-details user="sendInvite.userInvitedProfile"\n' +
    '                                            is-link="false"\n' +
    '                                            has-details="true"\n' +
    '                                            add-class="\'full-width-user-details\'">\n' +
    '                        </table-user-details>\n' +
    '                      </div>\n' +
    '                    </div>\n' +
    '\n' +
    '                    <div ng-show="!sendInvite.userInvitedProfile">\n' +
    '                      <div class="col-xs-6">\n' +
    '                        <div class="form-group" ng-class="{\'has-error\': (sendInviteForm.$submitted || sendInviteForm.firstName.$touched) && sendInviteForm.firstName.$error.required}">\n' +
    '                          <label class="sr-only" for="firstName">First name</label>\n' +
    '                          <div ng-show="sendInviteForm.$submitted || sendInviteForm.firstName.$touched">\n' +
    '                            <div class="input-error" ng-show="sendInviteForm.firstName.$error.required">Missing first name.</div>\n' +
    '                          </div>\n' +
    '                          <div class="left-inner-addon">\n' +
    '                            <i class="fa fa-user inner-addon"></i>\n' +
    '                            <input class="form-control input-lg" type="text" name="firstName" id="firstName"\n' +
    '                                   placeholder="First name" ng-required="true" ng-model="sendInvite.data.firstName" />\n' +
    '                          </div>\n' +
    '                        </div>\n' +
    '                      </div>\n' +
    '                      <div class="col-xs-6">\n' +
    '                        <div class="" ng-class="{\'has-error\': (sendInviteForm.$submitted || sendInviteForm.phoneMobile.$touched) && (sendInviteForm.phoneMobile.$error.required || sendInviteForm.phoneMobile.$error.email)}">\n' +
    '                          <label class="sr-only" for="email">Phone</label>\n' +
    '                          <div ng-show="sendInviteForm.$submitted || sendInviteForm.phoneMobile.$touched">\n' +
    '                            <div class="input-error" ng-show="sendInviteForm.phoneMobile.$error.required">Missing phone number.</div>\n' +
    '                          </div>\n' +
    '                          <div class="left-inner-addon">\n' +
    '                            <i class="fa fa-phone inner-addon"></i>\n' +
    '                            <input class="form-control input-lg" type="tel" name="phoneMobile" id="phoneMobile" placeholder="Phone Number (optional)"\n' +
    '                                   ng-required="false" ng-model="sendInvite.data.phoneMobile" />\n' +
    '                          </div>\n' +
    '                        </div>\n' +
    '                      </div>\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '\n' +
    '                  <!-- Email -->\n' +
    '                  <div ng-show="!sendInvite.isRewardCodeValid && !sendInvite.userInvitedProfile" class="row">\n' +
    '                    <div class="col-xs-12">\n' +
    '                      <div class="" ng-class="{\'has-error\': (sendInviteForm.$submitted || sendInviteForm.email.$touched) && (sendInviteForm.email.$error.required || sendInviteForm.email.$error.email)}">\n' +
    '                        <label class="sr-only" for="email">Email</label>\n' +
    '                        <div ng-show="sendInviteForm.$submitted || sendInviteForm.email.$touched">\n' +
    '                          <div class="input-error" ng-show="sendInviteForm.email.$error.required">Missing email.</div>\n' +
    '                          <div class="input-error" ng-show="sendInviteForm.email.$error.email">This is not a valid email.</div>\n' +
    '                        </div>\n' +
    '                        <div class="left-inner-addon">\n' +
    '                          <i class="fa fa-envelope-o inner-addon"></i>\n' +
    '                          <input class="form-control input-lg" type="email" name="email" id="email" placeholder="Email"\n' +
    '                                 ng-required="true" ng-model="sendInvite.data.email" />\n' +
    '                        </div>\n' +
    '                      </div>\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </form>\n' +
    '\n' +
    '          <uib-alert ng-repeat="alert in sendInvite.alerts.getList()" type="{{alert.type}}" close="sendInvite.alerts.remove($index)">{{alert.message}}</uib-alert>\n' +
    '\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <!-- ENDOF: Content -->\n' +
    '\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="row modal-content-buttons">\n' +
    '    <div class="col-xs-12 text-right">\n' +
    '      <button type="button" class="btn btn-default"\n' +
    '              ng-disabled="sendInvite.isLoading" ng-click="sendInvite.cancel()">\n' +
    '        Cancel\n' +
    '      </button>\n' +
    '      <button type="button"\n' +
    '              class="btn btn-primary"\n' +
    '              ng-class="{loading: sendInvite.isLoading}"\n' +
    '              ng-disabled="!sendInvite.checkContactData() ||\n' +
    '                          sendInvite.isLoading" ng-click="sendInvite.send()">\n' +
    '        {{sendInvite.isLoading ? \'Sending ...\' : \'Send\'}}\n' +
    '      </button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('my-pod/members/members.tpl.html',
    '<div class="col-xs-12" ng-controller="MembersCtrl as members">\n' +
    '  <div class="page-content">\n' +
    '    <div class="row" ng-show="members.list.isInitialised() && !members.list.recordsExist()">\n' +
    '      <div class="col-xs-12">\n' +
    '        <div class="page-empty-section text-center">\n' +
    '          <i class="fa fa-users fa-2x text-light-colour"></i>\n' +
    '          <div class="page-empty-section-text">\n' +
    '            You have no people in your pod\n' +
    '          </div>\n' +
    '          <br/>\n' +
    '          Send invites to your business partners to join your pod. Click "Invite to my Pod" to start sending invites.\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row" ng-show="members.list.recordsExist() && members.list.isInitialised()">\n' +
    '      <div class="col-xs-12 col-sm-5 col-md-4">\n' +
    '        <div class="form-group page-filter">\n' +
    '          <label class="sr-only" for="searchName">Search name</label>\n' +
    '          <div class="left-inner-addon">\n' +
    '            <i class="fa fa-search inner-addon"></i>\n' +
    '            <form name="memberSearchForm" ng-submit="members.search()">\n' +
    '              <input class="form-control input-lg" type="search" name="searchName" id="searchName"\n' +
    '                     placeholder="Search" ng-model="members.searchName" ng-blur="members.search()" />\n' +
    '            </form>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="col-xs-12 col-sm-4 col-md-3">\n' +
    '        <div class="form-group page-filter dropdown-large" uib-dropdown>\n' +
    '          <button id="members-type" type="button" class="btn" uib-dropdown-toggle>\n' +
    '            {{members.selectedStatusLabel}} <span class="caret"></span>\n' +
    '          </button>\n' +
    '          <ul class="dropdown-menu" uib-dropdown-menu role="menu" aria-labelledby="members-type">\n' +
    '            <li role="menuitem" ng-repeat="(value, label) in members.statuses">\n' +
    '              <a ng-click="members.selectStatus(value)">{{label}}</a>\n' +
    '            </li>\n' +
    '          </ul>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="table-responsive table-container" ng-show="members.list.recordsExist() && members.list.isInitialised()">\n' +
    '      <table st-table="members.list.getRecords()" st-pagination-scroll st-pipe="members.list.load" class="table page-content-table allow-wrap">\n' +
    '        <thead class="hidden-xs">\n' +
    '        <tr>\n' +
    '          <th class="col-sm-3">Name</th>\n' +
    '          <th class="col-sm-5">Industry</th>\n' +
    '          <th class="col-sm-2">Reward (up to)</th>\n' +
    '          <th class="text-right col-sm-2">Reward type</th>\n' +
    '          <!--<th class="text-right col-sm-2">Earnings</th>-->\n' +
    '        </tr>\n' +
    '        </thead>\n' +
    '        <tbody ng-hide="members.list.isLoading() && !members.list.isLoadingMoreRecords()">\n' +
    '        <tr ng-repeat="row in members.list.getRecords()">\n' +
    '          <td class="col-xs-12 col-sm-3">\n' +
    '            <table class="table-cell-main-content">\n' +
    '              <tr class="row-center-items row-space-between" >\n' +
    '                <td class="visible-xs" ng-if="members.selectedStatus !== \'pending\'" single-expand sub-content="members.tableSubContentVisible" sub-content-idx="$index"></td>\n' +
    '                <td ng-if="members.selectedStatus !== \'pending\'">\n' +
    '                  <table-user-details user="row.member"></table-user-details>\n' +
    '                </td>\n' +
    '                <td ng-if="members.selectedStatus == \'pending\'">\n' +
    '                  <table-user-details user="row.member" is-link="false" description-key="\'email\'"></table-user-details>\n' +
    '                </td>\n' +
    '                <td class="visible-xs text-right">\n' +
    '                  <span class="cursor-pointer" ng-if="members.selectedStatus !== \'pending\'">\n' +
    '                    <i class="fa fa-ellipsis-h"></i>\n' +
    '                  </span>\n' +
    '                </td>\n' +
    '              </tr>\n' +
    '            </table>\n' +
    '            <div class="table-cell-sub-content-container slide-if" ng-if="members.tableSubContentVisible[$index] && members.selectedStatus !== \'pending\'">\n' +
    '              <div class="table-cell-sub-content visible-xs">\n' +
    '                <span class="text-muted">Industry</span><br/>\n' +
    '                <span class="text-normal">{{row.member.industry.name}}</span>\n' +
    '                <span class="text-normal" ng-if="row.member.subIndustry">\n' +
    '                  <br/>{{row.member.subIndustry.name}}\n' +
    '                </span>\n' +
    '              </div>\n' +
    '              <div class="table-cell-sub-content visible-xs">\n' +
    '                <span class="text-muted">Reward (up to)</span><br/>\n' +
    '                <span class="text-normal">{{row.highestContractReward | currency:\'$\':0}}</span>\n' +
    '              </div>\n' +
    '              <div class="table-cell-sub-content visible-xs">\n' +
    '                <span class="text-muted">Reward type</span><br/>\n' +
    '                <span class="text-normal">{{row.contractType}}</span>\n' +
    '              </div>\n' +
    '              <!--<div class="table-cell-sub-content visible-xs">-->\n' +
    '                <!--<span class="text-muted">Earnings</span><br/>-->\n' +
    '                <!--<span class="text-normal">{{row.earnings | currency:\'$\':0}}</span>-->\n' +
    '              <!--</div>-->\n' +
    '            </div>\n' +
    '          </td>\n' +
    '          <td class="col-sm-5 hidden-xs">\n' +
    '            {{row.member.industry.name}}\n' +
    '            <span ng-if="row.member.subIndustry">\n' +
    '              <br/>{{row.member.subIndustry.name}}\n' +
    '            </span>\n' +
    '          </td>\n' +
    '          <td class="col-sm-2 hidden-xs">{{row.highestContractReward | currency:\'$\':0}}</td>\n' +
    '          <td class="col-sm-2 hidden-xs text-right">{{row.contractType}}</td>\n' +
    '          <!--<td class="col-sm-2 hidden-xs text-right">{{row.earnings | currency:\'$\':0}}</td>-->\n' +
    '        </tr>\n' +
    '        </tbody>\n' +
    '        <tbody ng-if="members.list.isLoading()">\n' +
    '        <tr>\n' +
    '          <td colspan="5" class="col-xs-12 text-center">\n' +
    '            <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>\n' +
    '          </td>\n' +
    '        </tr>\n' +
    '        </tbody>\n' +
    '      </table>\n' +
    '    </div>\n' +
    '    <div class="row" ng-show="!members.list.isInitialised()">\n' +
    '      <div class="col-xs-12 text-center">\n' +
    '        <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('my-memberships/send-reward/send-reward.tpl.html',
    '<div class="row">\n' +
    '  <div class="col-xs-12">\n' +
    '    <h1 class="page-header">Send Reward</h1>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="row">\n' +
    '  <div class="col-xs-12">\n' +
    '    <div class="page-content page-content-no-tabs">\n' +
    '      <uib-alert ng-repeat="alert in sendReward.alerts.getList()" type="{{alert.type}}" close="sendReward.alerts.remove($index)">{{alert.message}}</uib-alert>\n' +
    '      <div ng-form="sendRewardForm" class="send-reward-form">\n' +
    '        <div class="row">\n' +
    '          <div class="col-xs-12 col-sm-6">\n' +
    '            <span class="sub-title">Add reward</span>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row form-section-body">\n' +
    '          <div class="col-xs-12 col-sm-6">\n' +
    '            <div class="form-group" ng-class="{\'has-error\': (sendRewardForm.$submitted || sendRewardForm.reward.$touched) &&\n' +
    '            (sendRewardForm.reward.$error.required || sendRewardForm.reward.$error.number || sendRewardForm.reward.$error.min)}">\n' +
    '              <label class="sr-only" for="reward">Reward value</label>\n' +
    '              <div ng-show="sendRewardForm.$submitted || sendRewardForm.reward.$touched">\n' +
    '                <div class="input-error" ng-show="sendRewardForm.reward.$error.number">Invalid reward value\n' +
    '                </div>\n' +
    '                <div class="input-error" ng-show="sendRewardForm.reward.$error.required">Missing reward value\n' +
    '                </div>\n' +
    '                <div class="input-error" ng-show="sendRewardForm.reward.$error.min">Reward value must not be negative\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="left-inner-addon input-group">\n' +
    '                <i class="fa fa-dollar inner-addon"></i>\n' +
    '                <input class="form-control input-lg" type="number" name="reward" id="reward"\n' +
    '                       placeholder="Reward value" ng-change="sendReward.onRewardChange()"\n' +
    '                       ng-blur="sendReward.calculateTotal()"\n' +
    '                       ng-required="true" ng-model="sendReward.data.reward" min="0"/>\n' +
    '                <span class="input-group-btn">\n' +
    '                  <button class="btn btn-primary btn-md" type="button" ng-click="sendReward.calculateTotal()"\n' +
    '                          ng-disabled="!sendReward.data.reward">Enter</button>\n' +
    '                </span>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row" ng-if="sendReward.showTotal">\n' +
    '          <div class="col-xs-6 col-sm-3">\n' +
    '            Reward amount\n' +
    '          </div>\n' +
    '          <div class="col-xs-6 col-sm-3 text-right">\n' +
    '            {{sendReward.data.reward | currency:"$":2}}\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="row" ng-if="sendReward.showTotal && !sendReward.calculating && !sendReward.calculationRequired">\n' +
    '          <div class="col-xs-6 col-sm-3">\n' +
    '            Processing fee\n' +
    '          </div>\n' +
    '          <div class="col-xs-6 col-sm-3 text-right">\n' +
    '            {{sendReward.processingFee | currency:"$":2}}\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="row" ng-if="sendReward.showTotal && !sendReward.calculating && !sendReward.calculationRequired">\n' +
    '          <div class="col-xs-6 col-sm-3">\n' +
    '            GST\n' +
    '          </div>\n' +
    '          <div class="col-xs-6 col-sm-3 text-right">\n' +
    '            {{sendReward.tax | currency:"$":2}}\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="row" ng-if="sendReward.showTotal && !sendReward.calculating && !sendReward.calculationRequired">\n' +
    '          <div class="col-xs-6 col-sm-3">\n' +
    '            <strong>Total charged</strong>\n' +
    '          </div>\n' +
    '          <div class="col-xs-6 col-sm-3 text-right">\n' +
    '            <strong>{{sendReward.total | currency:"$":2}}</strong>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row" ng-if="sendReward.calculating">\n' +
    '          <div class="col-xs-12 col-sm-6 text-center">\n' +
    '            <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row form-section-title">\n' +
    '          <div class="col-xs-12 col-sm-6">\n' +
    '            <span class="sub-title">Payment details</span>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row form-section-body">\n' +
    '          <div class="col-xs-12 col-sm-6">\n' +
    '            <credit-card cc-data="sendReward.data.paymentDetails" credit-card="sendReward.creditCard" />\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row form-section-title">\n' +
    '          <div class="col-xs-12 col-sm-6">\n' +
    '            <span class="sub-title">Select recipient</span>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row form-section-body" ng-show="sendReward.list.isInitialised() && sendReward.list.recordsExist()">\n' +
    '          <div class="col-xs-12">\n' +
    '\n' +
    '            <div class="row">\n' +
    '              <div class="col-xs-12 col-sm-6">\n' +
    '                <div class="form-group page-filter">\n' +
    '                  <label class="sr-only" for="searchName">Search</label>\n' +
    '                  <div class="left-inner-addon">\n' +
    '                    <i class="fa fa-search inner-addon"></i>\n' +
    '                    <input class="form-control input-lg" type="search" name="searchName" id="searchName" ng-model="sendReward.searchName"\n' +
    '                           placeholder="Search"  ng-keyup="$event.keyCode == 13 && sendReward.search($event)" />\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="col-xs-12 col-sm-6" ng-if="sendReward.industries.length">\n' +
    '\n' +
    '                <div class="form-group page-filter">\n' +
    '                  <label class="sr-only" for="classification">Classification</label>\n' +
    '                  <select class="form-control input-lg" name="classification" id="classification" placeholder="All industries"\n' +
    '                          ng-model="sendReward.idIndustry" ng-change="sendReward.filterByIndustry()">\n' +
    '                    <option value="" selected>All industries</option>\n' +
    '                    <option ng-repeat="option in sendReward.industries" value="{{option.id}}">{{option.name}}</option>\n' +
    '                  </select>\n' +
    '                </div>\n' +
    '\n' +
    '              </div>\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="row">\n' +
    '              <div class="col-xs-12 table-container">\n' +
    '                <table st-table="sendReward.list.getRecords()" st-pagination-scroll st-pipe="sendReward.list.load" class="table send-lead-users-table">\n' +
    '                  <tbody ng-hide="sendReward.list.isLoading() && !sendReward.list.isLoadingMoreRecords()">\n' +
    '                  <tr class="cursor-pointer" ng-repeat="row in sendReward.list.getRecords()" ng-click="sendReward.selectRecipient($index)">\n' +
    '                    <td class="col-xs-12 col-sm-6 col-md-5">\n' +
    '                      <table>\n' +
    '                        <tr>\n' +
    '                          <td ng-class="{\'user-selected\': sendReward.isUserSelected($index)}">\n' +
    '                            <table-user-details user="row.owner" is-link="false"></table-user-details>\n' +
    '                          </td>\n' +
    '                        </tr>\n' +
    '                      </table>\n' +
    '                    </td>\n' +
    '                    <td class="col-sm-6 col-md-5 hidden-xs">\n' +
    '                      {{row.owner.industry.name}}\n' +
    '                      <span ng-if="row.owner.subIndustry">\n' +
    '                        <br/>{{row.owner.subIndustry.name}}\n' +
    '                      </span>\n' +
    '                    </td>\n' +
    '                    <td class="col-md-2 hidden-xs hidden-sm">{{row.rewardType}}</td>\n' +
    '                  </tr>\n' +
    '                  </tbody>\n' +
    '                  <tbody ng-if="sendReward.list.isLoading()">\n' +
    '                  <tr>\n' +
    '                    <td colspan="5" class="col-xs-12 text-center">\n' +
    '                      <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>\n' +
    '                    </td>\n' +
    '                  </tr>\n' +
    '                  </tbody>\n' +
    '                </table>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="row">\n' +
    '              <div class="col-xs-12">\n' +
    '                <div class="lead-recipient-list">\n' +
    '                  <span ng-hide="sendReward.selectedRecipient">Select above to add recipient</span>\n' +
    '                  <div ng-if="sendReward.selectedRecipient" class="lead-recipient">{{sendReward.selectedRecipient.firstName}} {{sendReward.selectedRecipient.lastName}} <a class="send-lead-remove-user cursor-pointer" ng-click="sendReward.deselectRecipient()"><i class="fa fa-times"></i></a></div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row form-section-body" ng-if="sendReward.list.isInitialised() && !sendReward.list.recordsExist()">\n' +
    '          <div class="col-xs-12">\n' +
    '            <div class="page-empty-section text-center">\n' +
    '              <i class="fa fa-users fa-2x text-light-colour"></i>\n' +
    '              <div class="page-empty-section-text">\n' +
    '                You don\'t have any connections\n' +
    '              </div>\n' +
    '              <br/>\n' +
    '              Join pods and start receiving leads.\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row form-section-title">\n' +
    '          <div class="col-xs-12 col-sm-6">\n' +
    '            <span class="sub-title">Message</span>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row form-section-body">\n' +
    '          <div class="col-xs-12">\n' +
    '            <div class="form-group">\n' +
    '              <label class="sr-only" for="message">Message</label>\n' +
    '              <div class="left-inner-addon">\n' +
    '                <i class="fa fa-comment-o inner-addon"></i>\n' +
    '                <textarea name="message" id="message" placeholder="Message" ng-model="sendReward.data.message"\n' +
    '                          class="form-control input-lg" msd-elastic></textarea>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row form-section-body">\n' +
    '          <div class="col-xs-6 form-group">\n' +
    '            <button type="button" class="btn btn-default btn-lg btn-block"\n' +
    '                    ng-disabled="sendReward.isLoading" ng-click="sendReward.cancel()">\n' +
    '              Cancel\n' +
    '            </button>\n' +
    '          </div>\n' +
    '          <div class="col-xs-6 form-group">\n' +
    '            <button type="button" class="btn btn-primary btn-lg btn-block"\n' +
    '                    ng-disabled="sendRewardForm.$invalid || !sendReward.selectedRecipient || sendReward.isLoading" ng-click="sendReward.send()">\n' +
    '              {{sendReward.isLoading ? \'Loading ...\' : \'Send Reward\'}}\n' +
    '            </button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('my-memberships/import/import.tpl.html',
    '<div class="col-xs-12" ng-controller="ImportCtrl as import" id="import-ctrl-container">\n' +
    '  <div class="page-content">\n' +
    '\n' +
    '    <div class="row">\n' +
    '      <div class="col-xs-12">\n' +
    '        <span class="sub-title">Contacts Import</span>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row form-section-body">\n' +
    '      <div class="col-xs-12">\n' +
    '        Upload and invite people to join your Reward Program.\n' +
    '        <file-download class="cursor-pointer" url="import.downloadTemplateUrl"\n' +
    '                       label="\'Download the import template here.\'"></file-download>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row form-section-body">\n' +
    '      <div class="col-xs-12" ng-if="!import.isUploading && !import.importFinished">\n' +
    '        <div class="page-empty-section page-table-section text-center" ngf-drop="import.upload($files)"\n' +
    '             ngf-multiple="false"\n' +
    '             ngf-accept="import.acceptedFiles"\n' +
    '             ngf-pattern="import.acceptedFiles"\n' +
    '             ngf-drag-over-class="{pattern: import.acceptedFiles, accept: \'file-drop-accepted\', reject: \'file-drop-rejected\', delay: 100}">\n' +
    '          <i class="fa fa-upload fa-2x text-light-colour"></i>\n' +
    '          <div class="page-empty-section-text text-muted">\n' +
    '            Drop file here or use the "Select" button.\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="page-empty-section-button">\n' +
    '            <button class="btn btn-primary" ngf-select="import.upload($files)" ngf-multiple="false"\n' +
    '                    ngf-accept="import.acceptedFiles"\n' +
    '                    ngf-pattern="import.acceptedFiles">Select\n' +
    '            </button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="col-xs-12" ng-if="!import.isUploading && import.importFinished">\n' +
    '        <div class="page-empty-section page-table-section text-center">\n' +
    '          <i class="fa fa-check fa-2x text-light-colour"></i>\n' +
    '          <div class="page-empty-section-text text-muted">\n' +
    '            Success, you\'ve invited your contacts to join your Reward Program.\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="page-empty-section-button">\n' +
    '            <button class="btn btn-primary" ng-click="import.importFinished = false">Import New File</button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="col-xs-12" ng-if="import.isUploading">\n' +
    '        <uib-progressbar value="import.importProgressPercent" type="success"><b>Importing:\n' +
    '          {{import.importProgressPercent}}%</b></uib-progressbar>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row form-section-body">\n' +
    '      <uib-alert ng-repeat="alert in import.alerts.getList().slice(0,5)" type="{{alert.type}}"\n' +
    '                 close="import.alerts.remove($index)">{{alert.message}}\n' +
    '      </uib-alert>\n' +
    '      <span ng-if="import.alerts.getList().length > 5 && !import.alertsExpanded">\n' +
    '        <a class="cursor-pointer" ng-click="import.alertsExpanded=true;" title="Expand">{{\'and \' + (import.alerts.getList().length - 5) + \' more\'}}</a>\n' +
    '      </span>\n' +
    '      <div ng-if="import.alerts.getList().length > 5 && import.alertsExpanded">\n' +
    '        <uib-alert ng-repeat="alert in import.alerts.getList().slice(5)" type="{{alert.type}}"\n' +
    '                   close="import.alerts.remove($index)">{{alert.message}}\n' +
    '        </uib-alert>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('my-memberships/reward-program/welcome-modal-template.tpl.html',
    '<div class="welcome-modal">\n' +
    '  <div class="wm-header">\n' +
    '    <div class="wm-title">\n' +
    '      Welcome to MyLeadPod.\n' +
    '    </div>\n' +
    '    <div class="wm-close-btn" ng-click="welcome.close();">\n' +
    '      <button class="glyphicon glyphicon-remove"></button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="stepped-wrapper" content-slider>\n' +
    '    <div class="stepped-container">\n' +
    '      <div class="step-content wm-flex" data-step-id="0">\n' +
    '        <div style="width: 100%; position: relative;">\n' +
    '\n' +
    '          <div class="wm-sub-header">\n' +
    '            <span class="wm-bolder">Well done!</span>\n' +
    '            <br/>\n' +
    '            You are pretty much set up now to encourage others to send you leads!\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="wm-content">\n' +
    '            <div class="wm-row-block">\n' +
    '              The step you have just completed has set up your base <span class="wm-highlight">Rewards Program</span> with its <span class="wm-highlight">Reward Code</span>.\n' +
    '            </div>\n' +
    '            <div class="wm-flex mts">\n' +
    '              <div class="wm-block">\n' +
    '                <div class="wm-block-title">Rewards Program</div>\n' +
    '                <div class="wm-block-content">\n' +
    '                  You can update your Rewards Program to have multiple levels, e.g. different $ rewards for different services.\n' +
    '\n' +
    '                  You can also set up multi level Custom Rewards for special referrers or sales channels.\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="wm-block">\n' +
    '                <div class="wm-block-title">Get Traction!</div>\n' +
    '                <div class="wm-block-content">\n' +
    '                  Once you are happy with your cash reward offers, the key next step is inviting your past clients, friends and family to send you leads.  Also add codes to your website, email signature etc.\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div style="text-align: center">\n' +
    '            <a href data-step-to="1" class="step-button">\n' +
    '              <span>Learn about Rewards Program</span>\n' +
    '              <span class="glyphicon glyphicon-circle-arrow-right"></span>\n' +
    '            </a>\n' +
    '          </div>\n' +
    '\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="step-content wm-flex wm-learn-block" data-step-id="1">\n' +
    '        <div style="width: 100%; position:relative;">\n' +
    '          <div class="wm-sub-header">\n' +
    '            <span class="wm-bolder">Learn</span>\n' +
    '            <br/>\n' +
    '            Learn to work with MyLeadPod. Follow the links below or check out\n' +
    '            <a href="https://www.myleadpod.com/blog/" target="_blank">our blog</a> or\n' +
    '            <a href="https://www.youtube.com/channel/UCrlcHVUWH7BTg9oLLDuv99Q" target="_blank">video tutorials</a>.\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="wm-content wm-block-content">\n' +
    '            <div ng-click="welcome.openLink(\'https://youtu.be/sZj6hd76520\');">\n' +
    '              <div class="wm-type">\n' +
    '                <span class="glyphicon glyphicon-play-circle"></span>\n' +
    '              </div>\n' +
    '              <div>\n' +
    '                <div class="wm-learn-title">Reward Program vs Reward Code</div>\n' +
    '                <div class="wm-learn-desc">Learn the difference between your Reward Program and Custom Rewards.</div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '\n' +
    '            <div ng-click="welcome.openLink(\'https://youtu.be/guxDe3hz4DY\');">\n' +
    '              <div class="wm-type">\n' +
    '                <span class="glyphicon glyphicon-play-circle"></span>\n' +
    '              </div>\n' +
    '              <div>\n' +
    '                <div class="wm-learn-title">Bulk invite potential referrers</div>\n' +
    '                <div class="wm-learn-desc">Learn how to import the database of your clients.</div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '\n' +
    '            <div ng-click="welcome.openLink(\'https://youtu.be/Y7iGJmx_pCE\');">\n' +
    '              <div class="wm-type">\n' +
    '                <span class="glyphicon glyphicon-play-circle"></span>\n' +
    '              </div>\n' +
    '              <div>\n' +
    '                <div class="wm-learn-title">Add my codes to digital assets</div>\n' +
    '                <div class="wm-learn-desc">Learn how to share your Reward Codes</div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div style="text-align: center">\n' +
    '            <a href data-step-to="0" class="step-button">\n' +
    '              <span class="glyphicon glyphicon-circle-arrow-left"></span>\n' +
    '              <span>Back</span>\n' +
    '            </a>\n' +
    '          </div>\n' +
    '\n' +
    '        </div>\n' +
    '\n' +
    '\n' +
    '      </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '    </div>\n' +
    '    <!--<div class="wm-side-content">\n' +
    '\n' +
    '    </div>-->\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="wm-content">\n' +
    '    <div class="wm-btn-section">\n' +
    '      <div class="text-center">\n' +
    '        <button class="btn btn-primary" ng-click="welcome.close();">Update Your Rewards</button>\n' +
    '        <button class="btn btn-primary" ui-sref="dashboard">Go to Dashboard</button>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <!--<div class="wm-footer">\n' +
    '    <div class="wm-learn-block">\n' +
    '      <div class="wm-block-title">Learn</div>\n' +
    '\n' +
    '      <div class="wm-block-content">\n' +
    '        <div>\n' +
    '          <div class="wm-type">\n' +
    '            <span class="glyphicon glyphicon-play-circle"></span>\n' +
    '          </div>\n' +
    '          <div>\n' +
    '            <div class="wm-learn-title">Reward Program vs Reward Code</div>\n' +
    '            <div class="wm-learn-desc">Learn the difference between your Reward Program and Custom Rewards.</div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div>\n' +
    '          <div class="wm-type">\n' +
    '            <span class="glyphicon glyphicon-play-circle"></span>\n' +
    '          </div>\n' +
    '          <div>\n' +
    '            <div class="wm-learn-title">Bulk invite potential referrers</div>\n' +
    '            <div class="wm-learn-desc">Learn how to import the database of your clients.</div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div>\n' +
    '          <div class="wm-type">\n' +
    '            <span class="glyphicon glyphicon-play-circle"></span>\n' +
    '          </div>\n' +
    '          <div>\n' +
    '            <div class="wm-learn-title">Add my codes to digital assets</div>\n' +
    '            <div class="wm-learn-desc">Learn how to share your reward codes</div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '      </div>\n' +
    '\n' +
    '    </div>-->\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('my-memberships/reward-program/setup.tpl.html',
    '<div id="reward-program-setup-ctrl-container">\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-12">\n' +
    '      <h1 class="page-header">Rewards Program</h1>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-12">\n' +
    '      <div class="page-content page-content-no-tabs form-group">\n' +
    '        <uib-alert ng-repeat="alert in rewardProgramsSetup.alerts.getList()" type="{{alert.type}}" close="rewardProgramsSetup.alerts.remove($index)">{{alert.message}}</uib-alert>\n' +
    '        <form name="rewardProgramSetupForm" class="reward-program-form">\n' +
    '          <div class="row">\n' +
    '            <div class="col-xs-12 form-group">\n' +
    '              Add new, modify or remove rewards.\n' +
    '              <i class="no-select info-help" role="button" tabIndex="0" style="margin-left: 15px;"\n' +
    '                 popover-trigger="click, outsideClick"\n' +
    '                 popover-append-to-body="true"\n' +
    '                 popover-class="help"\n' +
    '                 uib-popover="{{rewardProgramsSetup.rewardProgramHelp}}">?</i>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '          <div class="row" ng-if="rewardProgramsSetup.data.options.length < 1">\n' +
    '            <div class="col-xs-12">\n' +
    '              <div class="page-empty-section text-center">\n' +
    '                <i class="fa fa-users fa-3x text-light-colour"></i>\n' +
    '                <div class="page-empty-section-text">\n' +
    '                  No rewards.\n' +
    '                </div>\n' +
    '                <div class="page-empty-section-button">\n' +
    '                  <button type="button" class="btn btn-default" ng-click="rewardProgramsSetup.addOption()">\n' +
    '                    <i class="fa fa-plus"></i> Add reward\n' +
    '                  </button>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '<div class="rewards-table-container">\n' +
    '        <div class="rewards-table table-responsive table-container">\n' +
    '          <table class="table">\n' +
    '            <thead class="hidden-xs">\n' +
    '            <tr>\n' +
    '              <th class="col-sm-8">Title</th>\n' +
    '              <th class="col-sm-3">Successful Lead Reward</th>\n' +
    '              <th class="col-sm-1"></th>\n' +
    '            </tr>\n' +
    '            </thead>\n' +
    '            <tbody>\n' +
    '              <tr class="animate-repeat" ng-repeat="option in rewardProgramsSetup.data.options track by option.uiId"\n' +
    '                  ng-class="{\'has-error\': (rewardProgramSetupForm.$submitted ||\n' +
    '                                           rewardProgramSetupForm.name{{option.uiId}}.$touched ||\n' +
    '                                           rewardProgramSetupForm.price{{option.uiId}}.$touched ||\n' +
    '                                           rewardProgramSetupForm.name2{{option.uiId}}.$touched ||\n' +
    '                                           rewardProgramSetupForm.price2{{option.uiId}}.$touched) &&\n' +
    '                                          (rewardProgramSetupForm.name{{option.uiId}}.$error.required ||\n' +
    '                                           rewardProgramSetupForm.name2{{option.uiId}}.$error.required ||\n' +
    '                                           rewardProgramSetupForm.price{{option.uiId}}.$error.required ||\n' +
    '                                           rewardProgramSetupForm.price2{{option.uiId}}.$error.required),\n' +
    '                             \'has-edits\': !rewardProgramSetupForm.name{{option.uiId}}.$pristine ||\n' +
    '                                       !rewardProgramSetupForm.price{{option.uiId}}.$pristine ||\n' +
    '                                       !rewardProgramSetupForm.name2{{option.uiId}}.$pristine ||\n' +
    '                                       !rewardProgramSetupForm.price2{{option.uiId}}.$pristine}">\n' +
    '\n' +
    '\n' +
    '\n' +
    '                <td class="visible-xs reward-xs-content no-select"\n' +
    '                    ng-class="{\'open\':rewardProgramsSetup.tableSubContentVisible[$index]}">\n' +
    '\n' +
    '                  <!-- xs content header -->\n' +
    '                  <div class="table-cell-main-content" ng-click="rewardProgramsSetup.toggleSubContent($index);">\n' +
    '                    <div class="reward-xs-title">\n' +
    '                      <div class="rewards-xs-warning"\n' +
    '                          ng-show="!rewardProgramsSetup.data.options[$index].name || !rewardProgramsSetup.data.options[$index].successfulLeadPrice">\n' +
    '                        Needs more details ...\n' +
    '                      </div>\n' +
    '                      <div class="reward-xs-name">{{rewardProgramsSetup.data.options[$index].name}}</div>\n' +
    '                      <div class="reward-xs-price"> $ {{rewardProgramsSetup.data.options[$index].successfulLeadPrice}}</div>\n' +
    '                    </div>\n' +
    '                    <div class="reward-action-content">\n' +
    '                      <div class="reward-action-field">\n' +
    '                        <a class="remove-icon cursor-pointer" ng-click="rewardProgramsSetup.deleteOption($index, rewardProgramSetupForm)" ng-if="rewardProgramsSetup.data.options.length > 1"><i class="fa fa-times"></i></a>\n' +
    '                      </div>\n' +
    '                      <!--<div class="reward-action-field">\n' +
    '                        <span class="cursor-pointer" >\n' +
    '                          <i class="fa fa-ellipsis-h"></i>\n' +
    '                        </span>\n' +
    '                      </div>-->\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '\n' +
    '                  <!-- xs content container -->\n' +
    '                  <div class="table-cell-sub-content-container slide-css" ng-class="{slide: rewardProgramsSetup.tableSubContentVisible[$index]}">\n' +
    '\n' +
    '                    <div class="table-cell-sub-content"\n' +
    '                         ng-class="{\'has-error\': (rewardProgramSetupForm.$submitted ||\n' +
    '                                    rewardProgramSetupForm.name2{{option.uiId}}.$touched) &&\n' +
    '                                    rewardProgramSetupForm.name2{{option.uiId}}.$error.required}">\n' +
    '\n' +
    '                      <div ng-show="rewardProgramSetupForm.$submitted || rewardProgramSetupForm.name2{{option.uiId}}.$touched">\n' +
    '                        <div class="input-error" ng-show="rewardProgramSetupForm.name2{{option.uiId}}.$error.required">Missing name</div>\n' +
    '                      </div>\n' +
    '                      <div class="left-inner-addon">\n' +
    '                        <i class="fa fa-pencil inner-addon"></i>\n' +
    '                        <input class="form-control input-lg" type="text" name="name2{{option.uiId}}" id="name2{{option.uiId}}"\n' +
    '                              placeholder="Name" ng-required="true"\n' +
    '                              ng-model="rewardProgramsSetup.data.options[$index].name" />\n' +
    '                      </div>\n' +
    '                    </div>\n' +
    '\n' +
    '\n' +
    '                    <div class="table-cell-sub-content"\n' +
    '                         ng-class="{\'has-error\': (rewardProgramSetupForm.$submitted ||\n' +
    '                                    rewardProgramSetupForm.price2{{option.uiId}}.$touched) &&\n' +
    '                                    rewardProgramSetupForm.price2{{option.uiId}}.$error.required}">\n' +
    '\n' +
    '                      <div ng-show="rewardProgramSetupForm.$submitted || rewardProgramSetupForm.price2{{option.uiId}}.$touched">\n' +
    '                        <div class="input-error" ng-show="rewardProgramSetupForm.price{{option.uiId}}.$error.number">Invalid lead reward value</div>\n' +
    '                        <div class="input-error" ng-show="rewardProgramSetupForm.price{{option.uiId}}.$error.required">Missing lead reward value</div>\n' +
    '                        <div class="input-error" ng-show="rewardProgramSetupForm.price{{option.uiId}}.$error.min">Successful lead reward value must not be negative</div>\n' +
    '                      </div>\n' +
    '                      <div class="left-inner-addon">\n' +
    '                        <i class="fa fa-dollar inner-addon"></i>\n' +
    '                        <input class="form-control input-lg" type="number" name="price2{{option.uiId}}" id="price2{{option.uiId}}"\n' +
    '                            placeholder="Successful lead reward" ng-required="true" min="0"\n' +
    '                            ng-model="rewardProgramsSetup.data.options[$index].successfulLeadPrice" />\n' +
    '                      </div>\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '                </td>\n' +
    '\n' +
    '                <td class="hidden-xs table-cell primary-cell" ng-class="{\'has-error\': (rewardProgramSetupForm.$submitted || rewardProgramSetupForm.name{{option.uiId}}.$touched) && (rewardProgramSetupForm.name{{option.uiId}}.$error.required)}">\n' +
    '                  <div ng-show="rewardProgramSetupForm.$submitted || rewardProgramSetupForm.name{{option.uiId}}.$touched">\n' +
    '                    <div class="input-error" ng-show="rewardProgramSetupForm.name{{option.uiId}}.$error.required">Missing name</div>\n' +
    '                  </div>\n' +
    '                  <div class="left-inner-addon">\n' +
    '                    <i class="fa fa-pencil inner-addon"></i>\n' +
    '                    <input class="form-control input-lg" type="text" name="name{{option.uiId}}" id="name{{option.uiId}}"\n' +
    '                          placeholder="Name" ng-required="true"\n' +
    '                          ng-model="rewardProgramsSetup.data.options[$index].name" />\n' +
    '                  </div>\n' +
    '                </td>\n' +
    '\n' +
    '\n' +
    '                <td class="hidden-xs table-cell" ng-class="{\'has-error\': (rewardProgramSetupForm.$submitted || rewardProgramSetupForm.price{{option.uiId}}.$touched) && (rewardProgramSetupForm.price{{option.uiId}}.$error.required || rewardProgramSetupForm.price{{option.uiId}}.$error.number || rewardProgramSetupForm.price{{option.uiId}}.$error.min)}">\n' +
    '                  <div ng-show="rewardProgramSetupForm.$submitted || rewardProgramSetupForm.price{{option.uiId}}.$touched">\n' +
    '                    <div class="input-error" ng-show="rewardProgramSetupForm.price{{option.uiId}}.$error.number">Invalid lead reward value</div>\n' +
    '                    <div class="input-error" ng-show="rewardProgramSetupForm.price{{option.uiId}}.$error.required">Missing lead reward value</div>\n' +
    '                    <div class="input-error" ng-show="rewardProgramSetupForm.price{{option.uiId}}.$error.min">Successful lead reward value must not be negative</div>\n' +
    '                  </div>\n' +
    '                  <div class="left-inner-addon">\n' +
    '                    <i class="fa fa-dollar inner-addon"></i>\n' +
    '                    <input class="form-control input-lg" type="number" name="price{{option.uiId}}" id="price{{option.uiId}}"\n' +
    '                         placeholder="Successful lead reward" ng-required="true" min="0"\n' +
    '                         ng-model="rewardProgramsSetup.data.options[$index].successfulLeadPrice" />\n' +
    '                  </div>\n' +
    '                </td>\n' +
    '\n' +
    '                <td class="hidden-xs table-cell">\n' +
    '                  <a class="remove-icon cursor-pointer" ng-click="rewardProgramsSetup.deleteOption($index, rewardProgramSetupForm)" ng-if="rewardProgramsSetup.data.options.length > 1"><i class="fa fa-times"></i></a>\n' +
    '                </td>\n' +
    '\n' +
    '              </tr>\n' +
    '            </tbody>\n' +
    '          </table>\n' +
    '        </div>\n' +
    '</div>\n' +
    '\n' +
    '          <div class="row">\n' +
    '            <div class="col-xs-12 text-xs-center buttons-apart" ng-if="rewardProgramsSetup.data.options.length > 0">\n' +
    '              <button type="button" class="btn btn-success" ng-click="rewardProgramsSetup.addOption()">\n' +
    '                <i class="fa fa-plus"></i> Add reward\n' +
    '              </button>\n' +
    '              <button type="button" class="btn btn-primary"\n' +
    '                      ng-disabled="rewardProgramSetupForm.$pristine || rewardProgramSetupForm.$invalid || rewardProgramsSetup.isLoading" ng-click="rewardProgramsSetup.save()">\n' +
    '                {{rewardProgramsSetup.isLoading ? \'Saving ...\' : \'Save\'}}\n' +
    '              </button>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '        </form>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row">\n' +
    '        <div class="col-xs-12 col-sm-12 col-md-12 text-center">\n' +
    '            <span class="sub-title-description" style="margin-left: 12px;">\n' +
    '              To create or view your Reward Codes, go to <a ui-sref="myMemberships.rewardPrograms" class="cursor-pointer">Reward Codes</a> page.\n' +
    '            </span>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('my-memberships/reward-program/reward-program.tpl.html',
    '<div class="col-xs-12" ng-controller="RewardProgramCtrl as rewardProgram" id="reward-program-ctrl-container">\n' +
    '  <div class="page-content form-group">\n' +
    '\n' +
    '    <div ng-show="rewardProgram.list.isInitialised()">\n' +
    '\n' +
    '      <div class="row">\n' +
    '        <div class="col-xs-12 form-group">\n' +
    '          Add new or view your Reward Codes. Use Reward Codes for promoting your rewards in digital advertising and for tracking your leads.\n' +
    '          <i class="no-select info-help" role="button" tabIndex="0" style="margin-left: 15px;"\n' +
    '             popover-trigger="click, outsideClick"\n' +
    '             popover-append-to-body="true"\n' +
    '             popover-class="help"\n' +
    '             uib-popover="{{rewardProgram.rewardCodeHelp}}">?</i>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="form-group reward-code-tools">\n' +
    '        <div class="reward-code-table-filter">\n' +
    '          <div ng-show="rewardProgram.list.isInitialised() && rewardProgram.list.recordsExist()">\n' +
    '            <div class="btn-group page-filter dropdown-large" uib-dropdown>\n' +
    '              <button id="reward-types" type="button" class="btn" uib-dropdown-toggle>\n' +
    '                {{rewardProgram.selectedStatusLabel}} <span class="caret"></span>\n' +
    '              </button>\n' +
    '              <ul class="dropdown-menu" uib-dropdown-menu role="menu" aria-labelledby="reward-types">\n' +
    '                <li role="menuitem" ng-repeat="(value, label) in rewardProgram.statuses">\n' +
    '                  <a ng-click="rewardProgram.selectStatus(value)">{{label}}</a>\n' +
    '                </li>\n' +
    '              </ul>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="reward-code-create">\n' +
    '          <button class="btn btn-primary reward-code-create-btn" ng-click="rewardProgram.openCreateCodeModal()">Create Reward Code</button>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="page-empty-section page-table-section text-center" ng-show="rewardProgram.list.isInitialised() && !rewardProgram.list.recordsExist()">\n' +
    '        <i class="fa fa-hashtag fa-2x text-light-colour"></i>\n' +
    '        <div class="page-empty-section-text">\n' +
    '          You have not created any Reward Codes\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="table-responsive table-container" ng-show="rewardProgram.list.recordsExist() && rewardProgram.list.isInitialised()">\n' +
    '        <table st-table="rewardProgram.list.getRecords()" st-pagination-scroll st-pipe="rewardProgram.list.load" class="table rewards-code-table">\n' +
    '          <thead class="hidden-xs">\n' +
    '          <tr>\n' +
    '            <th class="col-sm-3">Reward Code</th>\n' +
    '            <th class="col-sm-3">Shared with</th>\n' +
    '            <th class="col-sm-2">Clicks</th>\n' +
    '            <th class="col-sm-2">Registrations</th>\n' +
    '            <th class="col-sm-2 text-center">Details</th>\n' +
    '          </tr>\n' +
    '          </thead>\n' +
    '          <tbody ng-hide="(rewardProgram.list.isLoading() && !rewardProgram.list.isLoadingMoreRecords())">\n' +
    '            <tr ng-repeat="row in rewardProgram.list.getRecords()">\n' +
    '              <td class="col-xs-12 col-sm-3">\n' +
    '                <table class="table-cell-main-content">\n' +
    '                  <tr class="row-center-items row-space-between">\n' +
    '                    <td class="visible-xs" single-expand sub-content="rewardProgram.tableSubContentVisible" sub-content-idx="$index"></td>\n' +
    '                    <td>{{row.rewardCode}}</td>\n' +
    '                    <td class="visible-xs text-right">\n' +
    '                      <span ng-if="row.isArchived" class="description-tag">Archived</span>\n' +
    '                      <span class="cursor-pointer">\n' +
    '                        <i class="fa fa-ellipsis-h"></i>\n' +
    '                      </span>\n' +
    '                    </td>\n' +
    '                  </tr>\n' +
    '                </table>\n' +
    '\n' +
    '                <div class="table-cell-sub-content-container slide-if" ng-if="rewardProgram.tableSubContentVisible[$index]">\n' +
    '                  <div>\n' +
    '                    <div class="table-cell-sub-content visible-xs">\n' +
    '                      <span class="text-muted">Clicks</span>\n' +
    '                      <span class="text-normal">{{row.viewsCounter}}</span>\n' +
    '                    </div>\n' +
    '                    <div class="table-cell-sub-content visible-xs">\n' +
    '                      <span class="text-muted">Registrations</span>\n' +
    '                      <span class="text-normal">{{row.registrationsCounter}}</span>\n' +
    '                    </div>\n' +
    '                    <div class="table-cell-sub-content visible-xs">\n' +
    '                      <span class="text-normal">\n' +
    '                        <a ng-click="rewardProgram.setUrl(row.rewardCode);" class="btn-sm btn-primary cursor-pointer no-select"\n' +
    '                           popover-trigger="click, outsideClick"\n' +
    '                           popover-append-to-body="true"\n' +
    '                           popover-class="help"\n' +
    '                           uib-popover-template="\'social-tpl.html\'">\n' +
    '                          <i class="no-select fa fa-share-alt"></i>\n' +
    '                           Share\n' +
    '                        </a>\n' +
    '                      </span>\n' +
    '                    </div>\n' +
    '                    <div class="table-cell-sub-content visible-xs">\n' +
    '                      <span class="text-normal"><a ng-click="rewardProgram.openDetail($index)" class="btn-sm btn-primary cursor-pointer no-select">View</a></span>\n' +
    '                    </div>\n' +
    '                    <div class="table-cell-sub-content visible-xs shared-with" ng-if="row.userShare">\n' +
    '                      <span class="text-muted">Shared with</span>\n' +
    '                      <span class="text-normal">\n' +
    '                        <table ng-if="row.userShare">\n' +
    '                          <tr>\n' +
    '                            <td>\n' +
    '                              <table-user-details user="row.userShare"></table-user-details>\n' +
    '                            </td>\n' +
    '                          </tr>\n' +
    '                        </table>\n' +
    '                      </span>\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '\n' +
    '\n' +
    '              </td>\n' +
    '              <td class="hidden-xs col-sm-3">\n' +
    '                <table class="table-cell-main-content" ng-if="row.userShare">\n' +
    '                  <tr>\n' +
    '                    <td>\n' +
    '                      <table-user-details user="row.userShare"></table-user-details>\n' +
    '                    </td>\n' +
    '                  </tr>\n' +
    '                </table>\n' +
    '                <span ng-if="!row.userShare" class="text-muted">-</span>\n' +
    '              </td>\n' +
    '              <td class="hidden-xs col-sm-2">\n' +
    '                {{row.viewsCounter}}\n' +
    '              </td>\n' +
    '              <td class="hidden-xs col-sm-2">\n' +
    '                {{row.registrationsCounter}}\n' +
    '              </td>\n' +
    '              <td class="hidden-xs col-sm-2 text-right" style="white-space: nowrap;">\n' +
    '                <a ng-click="rewardProgram.setUrl(row.rewardCode);" style="margin-right: 15px; cursor: pointer;"\n' +
    '                   popover-trigger="click, outsideClick"\n' +
    '                   popover-append-to-body="true"\n' +
    '                   popover-class="help"\n' +
    '                   uib-popover-template="\'social-tpl.html\'">\n' +
    '                  <i class="no-select fa fa-share-alt"></i>\n' +
    '                   Share\n' +
    '                </a>\n' +
    '\n' +
    '                <a ng-click="rewardProgram.openDetail($index)" class="cursor-pointer">View</a>\n' +
    '              </td>\n' +
    '            </tr>\n' +
    '          </tbody>\n' +
    '          <tbody ng-if="rewardProgram.list.isLoading()">\n' +
    '          <tr>\n' +
    '            <td colspan="5" class="col-xs-12 text-center">\n' +
    '              <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>\n' +
    '            </td>\n' +
    '          </tr>\n' +
    '          </tbody>\n' +
    '        </table>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row" ng-show="!rewardProgram.list.isInitialised()">\n' +
    '      <div class="col-xs-12 text-center">\n' +
    '        <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '\n' +
    '    <!-- social share template for this page -->\n' +
    '    <script type="text/ng-template" id="social-tpl.html"><span></span>\n' +
    '      <!-- Pending overlay -->\n' +
    '      <div class="pending-overlay pending-overlay-column opacity-slow-if no-animate" ng-if="rewardProgram.isLoadingLink">\n' +
    '        <span class="fa fa-circle-o-notch fa-spin-2x"></span>\n' +
    '        <div class="pending-overlay-description">Preparing your Code Link..</div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="popover-social-links">\n' +
    '        <a class="reward-share-link" ng-click="rewardProgram.share(\'facebook\')"><i class="fa fa-facebook-square fa-2x"></i></a>\n' +
    '        <a class="reward-share-link" ng-click="rewardProgram.share(\'linkedin\')"><i class="fa fa-linkedin-square fa-2x"></i></a>\n' +
    '        <a class="reward-share-link" ng-click="rewardProgram.share(\'twitter\')"><i class="fa fa-twitter-square fa-2x"></i></a>\n' +
    '        <a class="reward-share-link" ng-click="rewardProgram.share(\'google\')"><i class="fa fa-google-plus-square fa-2x"></i></a>\n' +
    '        <a class="reward-share-link-fake" ng-if="rewardProgram.urlToShare" href="mailto:?subject=Join us at MyLeadPod&body={{rewardProgram.emailBody}}" title="Embed"><i class="fa fa-envelope fa-2x"></i></a>\n' +
    '      </div>\n' +
    '    </script>\n' +
    '\n' +
    '  </div>\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-12 col-sm-12 col-md-12 text-center">\n' +
    '        <span class="sub-title-description" style="margin-left: 12px;">\n' +
    '          To modify your Rewards Program, go to the <a ui-sref="myMembershipsRewardProgramsSetup" class="cursor-pointer">Rewards Program</a> page.\n' +
    '        </span>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('my-memberships/reward-program/reward-code-embed.tpl.html',
    '<div class="modal-content-container">\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-12">\n' +
    '      <div class="row">\n' +
    '        <div class="col-xs-12 lead-detail-header">\n' +
    '          <span class="sub-title">\n' +
    '            Embed Reward Code\n' +
    '          </span>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row form-section-body">\n' +
    '        <div class="col-xs-10">\n' +
    '          <textarea class="form-control input-lg" type="text" ng-model="rewardCodeEmbed.embedCode"></textarea>\n' +
    '        </div>\n' +
    '        <div class="col-xs-2">\n' +
    '          <a class="action-icon-link cursor-pointer" ngclipboard data-clipboard-text="{{rewardCodeEmbed.embedCode}}"\n' +
    '             uib-tooltip="Click to copy" tooltip-placement="bottom">\n' +
    '            <i class="fa fa-clipboard fa-2x"></i>\n' +
    '          </a>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row form-section-body">\n' +
    '        <div class="col-xs-12">\n' +
    '          <a ng-show="!rewardCodeEmbed.previewVisible" class="cursor-pointer" ng-click="rewardCodeEmbed.previewVisible = true;">Show Preview</a>\n' +
    '          <a ng-show="rewardCodeEmbed.previewVisible" class="cursor-pointer" ng-click="rewardCodeEmbed.previewVisible = false;">Hide Preview</a>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row">\n' +
    '        <div class="col-xs-12" ng-if="rewardCodeEmbed.previewVisible">\n' +
    '          <div class="text-center" ng-bind-html="rewardCodeEmbed.embedCode">\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="row modal-content-buttons">\n' +
    '    <div class="col-xs-12 text-right">\n' +
    '      <button class="btn btn-default" ng-click="rewardCodeEmbed.close()">\n' +
    '        Close\n' +
    '      </button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('my-memberships/reward-program/reward-code-detail.tpl.html',
    '<div class="modal-content-container">\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-12">\n' +
    '      <div class="row">\n' +
    '        <div class="col-xs-2 text-right">\n' +
    '          <span ng-if="rewardCodeDetail.rewardCode.isArchived" class="description-tag">Archived</span>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row">\n' +
    '        <div class="col-xs-12 lead-detail-row reward-code-title">\n' +
    '          <div>\n' +
    '            <i class="fa fa-hashtag" title="Reward Code Name"></i>\n' +
    '            <div>\n' +
    '              {{rewardCodeDetail.rewardCode.rewardCode}}\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div>\n' +
    '            <div>\n' +
    '              <i class="fa fa-eye" title="Number of views"></i>\n' +
    '              <div>{{rewardCodeDetail.rewardCode.viewsCounter}}</div>\n' +
    '            </div>\n' +
    '\n' +
    '            <div>\n' +
    '              <i class="fa fa-refresh" title="Number of registrations through this Reward Code"></i>\n' +
    '              <div>{{rewardCodeDetail.rewardCode.registrationsCounter}}</div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row">\n' +
    '        <div class="col-xs-12 rcd-row">\n' +
    '          <!-- Title -->\n' +
    '          <div class="rc-title">Share on Social Media</div>\n' +
    '\n' +
    '          <!-- Primary Row -->\n' +
    '          <div class="rc-value flex-container">\n' +
    '            <div class="social-links">\n' +
    '              <a class="reward-share-link" ng-click="rewardCodeDetail.share(\'facebook\')"><i class="fa fa-facebook-square fa-3x"></i></a>\n' +
    '              <a class="reward-share-link" ng-click="rewardCodeDetail.share(\'linkedin\')"><i class="fa fa-linkedin-square fa-3x"></i></a>\n' +
    '              <a class="reward-share-link" ng-click="rewardCodeDetail.share(\'twitter\')"><i class="fa fa-twitter-square fa-3x"></i></a>\n' +
    '              <a class="reward-share-link" ng-click="rewardCodeDetail.share(\'google\')"><i class="fa fa-google-plus-square fa-3x"></i></a>\n' +
    '              <a class="reward-share-link" ng-click="rewardCodeDetail.embed()" title="Embed"><i class="fa fa-code fa-3x"></i></a>\n' +
    '              <a class="reward-share-link-fake" ng-if="rewardCodeDetail.shortUrl" href="mailto:?subject=Join us at MyLeadPod&body={{rewardCodeDetail.emailBody}}" title="Embed"><i class="fa fa-envelope fa-2x"></i></a>\n' +
    '            </div>\n' +
    '            <div>\n' +
    '              <a ng-click="rewardCodeDetail.linksVisible = !rewardCodeDetail.linksVisible;" class="btn-sm btn-primary cursor-pointer">\n' +
    '                <i class="fa fa-chevron-down" ng-class="{\'rotate-180\': rewardCodeDetail.linksVisible}" style="transition: transform 0.2s ease-in-out;"></i>\n' +
    '                <span>Get Links</span>\n' +
    '              </a>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row slide-if" ng-if="rewardCodeDetail.linksVisible" >\n' +
    '        <div class="col-xs-12 rcd-row">\n' +
    '          <div style="width: 100%;">\n' +
    '            <div class="rcd-inner-row">\n' +
    '              <div class="rc-title">Link</div>\n' +
    '              <div class="rc-value flex-container">\n' +
    '                <span style="flex:1;">{{rewardCodeDetail.getUrl()}}</span>\n' +
    '                <a class="action-icon-link cursor-pointer" ngclipboard data-clipboard-text="{{rewardCodeDetail.getUrl()}}"\n' +
    '                   uib-tooltip="Click to copy" tooltip-placement="left" ngclipboard-success="rewardCodeDetail.onSuccess(e);">\n' +
    '                  <i class="fa fa-clipboard"></i>\n' +
    '                </a>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="rcd-inner-row">\n' +
    '              <div class="rc-title">Link (short)</div>\n' +
    '              <div class="rc-value flex-container">\n' +
    '                <span style="flex:1;">{{rewardCodeDetail.shortUrl}}</span>\n' +
    '                <a class="action-icon-link cursor-pointer" ngclipboard data-clipboard-text="{{rewardCodeDetail.shortUrl}}"\n' +
    '                   uib-tooltip="Click to copy" tooltip-placement="left">\n' +
    '                  <i class="fa fa-clipboard"></i>\n' +
    '                </a>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row">\n' +
    '        <div class="col-xs-12 rcd-row">\n' +
    '          <div class="rc-title">Available Rewards</div>\n' +
    '          <div class="rc-value" style="align-self: center;">\n' +
    '            <div class="rewards-program-card" ng-repeat="option in rewardCodeDetail.rewardCode.pricingOptions | orderBy:\'successfulLeadPrice\'">\n' +
    '              <!--<br ng-if="$index > 0" />-->\n' +
    '              <div class="card-desc">{{option.name}}</div>\n' +
    '              <div class="card-separator">..............................................................................................................................</div>\n' +
    '              <div class="card-value">{{option.successfulLeadPrice | currency:"$":0}}</div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row">\n' +
    '        <div class="col-xs-12 rcd-row">\n' +
    '\n' +
    '          <!-- Pending overlay-->\n' +
    '          <div class="pending-overlay-white pending-overlay-column" ng-if="rewardCodeDetail.isSavingDescription">\n' +
    '            <span class="fa fa-circle-o-notch fa-spin-2x"></span>\n' +
    '            <div class="pending-overlay-description">Saving description...</div>\n' +
    '          </div>\n' +
    '\n' +
    '          <!-- Title -->\n' +
    '          <div class="rc-title">Description</div>\n' +
    '\n' +
    '          <!-- Value -->\n' +
    '          <div class="rc-value">\n' +
    '            <!-- Primary Row -->\n' +
    '            <div style="position: relative; display: flex; align-items: center; justify-content: space-between; flex-wrap: nowrap;">\n' +
    '              <div style="align-items: center; flex-grow: 1;">\n' +
    '                <span ng-if="!rewardCodeDetail.rewardCode.description" class="text-muted">No description</span>\n' +
    '                <span ng-if="rewardCodeDetail.rewardCode.description" ng-bind-html="rewardCodeDetail.rewardCode.description | nl2br"></span>\n' +
    '              </div>\n' +
    '              <div>\n' +
    '                <a class="action-icon-link cursor-pointer no-animate"\n' +
    '                   ng-click="rewardCodeDetail.editDescriptionVisible = true;"\n' +
    '                   ng-if="!rewardCodeDetail.editDescriptionVisible"\n' +
    '                   uib-tooltip="Click to edit"\n' +
    '                   tooltip-placement="left"><i class="fa fa-pencil"></i></a>\n' +
    '\n' +
    '                <button type="button"\n' +
    '                        class="btn-sm btn-primary"\n' +
    '                        style="margin: 1px 0px;"\n' +
    '                        ng-disabled="rewardCodeDetail.isSavingDescription"\n' +
    '                        ng-if="rewardCodeDetail.rewardCode.originalDescription != rewardCodeDetail.data.description"\n' +
    '                        ng-click="rewardCodeDetail.saveDescription()">Save</button>\n' +
    '                <button type="button"\n' +
    '                        class="btn-sm btn-primary"\n' +
    '                        style="margin: 1px 0px;"\n' +
    '                        ng-if="rewardCodeDetail.editDescriptionVisible"\n' +
    '                        ng-click="rewardCodeDetail.editDescriptionVisible = false">Cancel</button>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '\n' +
    '            <!-- Edit Row -->\n' +
    '            <div class="slide-if" ng-if="rewardCodeDetail.editDescriptionVisible">\n' +
    '              <div style="position: relative; display: flex; align-items: center; justify-content: space-between;">\n' +
    '                <i class="col fa"></i>\n' +
    '                <div class="" style="flex-grow: 1; margin-top: 10px;">\n' +
    '\n' +
    '                  <div ng-if="rewardCodeDetail.editDescriptionError">\n' +
    '                    <div class="input-error">An error occurred, try again please.</div>\n' +
    '                  </div>\n' +
    '                  <label class="sr-only" for="description">Description</label>\n' +
    '                  <textarea class="form-control" name="description" id="description" placeholder="Description"\n' +
    '                            ng-model="rewardCodeDetail.data.description" msd-elastic></textarea>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <!--<div class="row" ng-if="rewardCodeDetail.shortUrl">\n' +
    '        <div class="col-xs-12 rcd-row">\n' +
    '          <div class="flex-container">\n' +
    '            <i class="col fa fa-link" title="Reward Code URL link (short)"></i>\n' +
    '            <span style="flex:1;">{{rewardCodeDetail.shortUrl}}</span>\n' +
    '            <a class="action-icon-link cursor-pointer" ngclipboard data-clipboard-text="{{rewardCodeDetail.shortUrl}}"\n' +
    '               uib-tooltip="Click to copy" tooltip-placement="bottom">\n' +
    '              <i class="fa fa-clipboard"></i>\n' +
    '            </a>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>-->\n' +
    '\n' +
    '      <div class="row">\n' +
    '        <div class="col-xs-12 rcd-row">\n' +
    '          <!-- Pending overlay-->\n' +
    '          <div class="pending-overlay-white pending-overlay-column" ng-if="rewardCodeDetail.searchingUser">\n' +
    '            <span class="fa fa-circle-o-notch fa-spin-2x"></span>\n' +
    '            <div class="pending-overlay-description">{{rewardCodeDetail.searchingUser}}</div>\n' +
    '          </div>\n' +
    '\n' +
    '          <!-- Title -->\n' +
    '          <div class="rc-title">Shared with</div>\n' +
    '\n' +
    '          <div class="rc-value">\n' +
    '            <!-- Primary Row -->\n' +
    '            <div class="flex-container">\n' +
    '              <div class="associated-users" style="align-items: center; flex-grow: 1;">\n' +
    '                <div ng-if="rewardCodeDetail.rewardCode.userShare">\n' +
    '                  <table-user-details user="rewardCodeDetail.rewardCode.userShare" />\n' +
    '                  <div>\n' +
    '                    <button class="btn-sm btn-danger" ng-click="rewardCodeDetail.removeUserShare()"><i class="fa fa-times"></i></button>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '                <span ng-if="!rewardCodeDetail.rewardCode.userShare" class="text-muted">This Reward Code is not shared with another user</span>\n' +
    '              </div>\n' +
    '\n' +
    '              <div style="flex-shrink:0;">\n' +
    '                <a ng-if="!rewardCodeDetail.editUserShareVisible"\n' +
    '                   class="action-icon-link cursor-pointer no-animate"\n' +
    '                   ng-click="rewardCodeDetail.openUserShare()"\n' +
    '                   uib-tooltip="Click to edit"\n' +
    '                   tooltip-placement="left">\n' +
    '                  <i class="fa fa-pencil"></i></a>\n' +
    '                <button type="button"\n' +
    '                        class="btn-sm btn-primary"\n' +
    '                        ng-if="rewardCodeDetail.editUserShareVisible"\n' +
    '                        ng-click="rewardCodeDetail.editUserShareVisible = false">Cancel</button>\n' +
    '\n' +
    '              </div>\n' +
    '\n' +
    '            </div>\n' +
    '            <!--- Edit Row -->\n' +
    '            <div class="slide-if" ng-if="rewardCodeDetail.editUserShareVisible">\n' +
    '              <div class="edit-container">\n' +
    '                <i class="col fa" title="Reward Code association with other users"></i>\n' +
    '                <div class="" style="flex-grow: 1; margin-top: 10px;">\n' +
    '                  <search-autocomplete\n' +
    '                    control-id="share-autocomplete"\n' +
    '                    input-name="shareUsersInputSearch"\n' +
    '\n' +
    '                    placeholder="Search Business"\n' +
    '                    minlength="3"\n' +
    '                    clear-selected="false"\n' +
    '                    text-searching="false"\n' +
    '                    text-no-results="false"\n' +
    '\n' +
    '                    search-service="Search"\n' +
    '                    search-service-end-point="index"\n' +
    '                    remote-url-response-formatter="rewardCodeDetail.searchResponseFormatter"\n' +
    '                    search-categories="rewardCodeDetail.searchCategories"\n' +
    '                    selected-object="rewardCodeDetail.onSearchSharedItemSelected"\n' +
    '\n' +
    '                    data-field="content.records"\n' +
    '                    search-fields="search"\n' +
    '                    title-field="value"\n' +
    '                    description-field="categoryDescription"\n' +
    '                    description-field2="timeDescription"\n' +
    '                    category-field="category"\n' +
    '\n' +
    '                    input-class="form-control input-lg"\n' +
    '                    match-class="autocomplete-highlight"\n' +
    '                    blur-on-select="true"\n' +
    '                    has-back-button="false"\n' +
    '                    update-input-on-select="false"\n' +
    '                    is-dropdown-relative="true"\n' +
    '                    override-suggestions="false"\n' +
    '                    field-required="false"\n' +
    '                    has-history="true" />\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row">\n' +
    '        <div class="col-xs-12 rcd-row">\n' +
    '          <!-- Pending overlay-->\n' +
    '          <div class="pending-overlay-white pending-overlay-column" ng-if="rewardCodeDetail.searchingAssociatedUser">\n' +
    '            <span class="fa fa-circle-o-notch fa-spin-2x"></span>\n' +
    '            <div class="pending-overlay-description">{{rewardCodeDetail.searchingAssociatedUser}}</div>\n' +
    '          </div>\n' +
    '\n' +
    '          <!-- Title -->\n' +
    '          <div class="rc-title">Associated with</div>\n' +
    '\n' +
    '          <!-- Primary Row -->\n' +
    '          <div class="rc-value">\n' +
    '            <div style="position: relative; display: flex; align-items: center; justify-content: space-between; flex-wrap: nowrap;">\n' +
    '              <div style="align-items: center; flex-grow: 1;">\n' +
    '                <div class="associated-users short-width" style="max-height: 150px; overflow-x: hidden; overflow-y: scroll;">\n' +
    '                  <div ng-repeat="user in rewardCodeDetail.associatedUsers" ng-if="rewardCodeDetail.associatedUsers && rewardCodeDetail.associatedUsers.length > 0">\n' +
    '                    <table-user-details user="user" is-link="false" />\n' +
    '                    <div>\n' +
    '                      <button class="btn-sm btn-danger" ng-click="rewardCodeDetail.removeAssociatedUser($index)"><i class="fa fa-times"></i></button>\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '                <span ng-if="!rewardCodeDetail.associatedUsers || rewardCodeDetail.associatedUsers.length === 0" class="text-muted">\n' +
    '                  This Reward Code is not associated with another users\n' +
    '                </span>\n' +
    '              </div>\n' +
    '\n' +
    '              <div style="flex-shrink:0;">\n' +
    '                <a ng-if="!rewardCodeDetail.editAssociatedUsersVisible"\n' +
    '                   class="action-icon-link cursor-pointer no-animate"\n' +
    '                   ng-click="rewardCodeDetail.openAssociatedUser()"\n' +
    '                   uib-tooltip="Click to edit"\n' +
    '                   tooltip-placement="left">\n' +
    '                  <i class="fa fa-pencil"></i></a>\n' +
    '                <button type="button"\n' +
    '                        class="btn-sm btn-primary btn-block"\n' +
    '                        style="margin-bottom: 3px"\n' +
    '                        ng-if="rewardCodeDetail.hasEditsAssociatedUsers()"\n' +
    '                        ng-click="rewardCodeDetail.saveAssociatedUsers()">Save</button>\n' +
    '                <button type="button"\n' +
    '                        class="btn-sm btn-primary"\n' +
    '                        ng-if="rewardCodeDetail.editAssociatedUsersVisible"\n' +
    '                        ng-click="rewardCodeDetail.onAssociatedUsersCancel()">Cancel</button>\n' +
    '\n' +
    '              </div>\n' +
    '\n' +
    '            </div>\n' +
    '            <!--- Edit Row -->\n' +
    '            <div class="slide-if" ng-if="rewardCodeDetail.editAssociatedUsersVisible">\n' +
    '              <div style="position: relative; display: flex; align-items: center; justify-content: space-between;">\n' +
    '                <i class="col fa"></i>\n' +
    '                <div class="" style="flex-grow: 1; margin-top: 10px;">\n' +
    '                  <search-autocomplete\n' +
    '                    control-id="associated-autocomplete"\n' +
    '                    input-name="associatedUsersInputSearch"\n' +
    '\n' +
    '                    placeholder="Search Business"\n' +
    '                    minlength="3"\n' +
    '                    clear-selected="false"\n' +
    '                    text-searching="false"\n' +
    '                    text-no-results="false"\n' +
    '\n' +
    '                    search-service="Search"\n' +
    '                    search-service-end-point="index"\n' +
    '                    remote-url-response-formatter="rewardCodeDetail.searchResponseFormatter"\n' +
    '                    search-categories="rewardCodeDetail.searchCategories"\n' +
    '                    selected-object="rewardCodeDetail.onAssociatedItemSelected"\n' +
    '\n' +
    '                    data-field="content.records"\n' +
    '                    search-fields="search"\n' +
    '                    title-field="value"\n' +
    '                    description-field="categoryDescription"\n' +
    '                    description-field2="timeDescription"\n' +
    '                    category-field="category"\n' +
    '\n' +
    '                    input-class="form-control input-lg"\n' +
    '                    match-class="autocomplete-highlight"\n' +
    '                    blur-on-select="true"\n' +
    '                    has-back-button="false"\n' +
    '                    update-input-on-select="false"\n' +
    '                    is-dropdown-relative="true"\n' +
    '                    override-suggestions="false"\n' +
    '                    field-required="false"\n' +
    '                    has-history="true" />\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <!--<script type="text/ng-template" id="social-tpl.html"><span></span>\n' +
    '        <div>\n' +
    '          <a class="reward-share-link" ng-click="rewardCodeDetail.share(\'facebook\')"><i class="fa fa-facebook-square fa-3x"></i></a>\n' +
    '          <a class="reward-share-link" ng-click="rewardCodeDetail.share(\'linkedin\')"><i class="fa fa-linkedin-square fa-3x"></i></a>\n' +
    '          <a class="reward-share-link" ng-click="rewardCodeDetail.share(\'twitter\')"><i class="fa fa-twitter-square fa-3x"></i></a>\n' +
    '          <a class="reward-share-link" ng-click="rewardCodeDetail.share(\'google\')"><i class="fa fa-google-plus-square fa-3x"></i></a>\n' +
    '          <a class="reward-share-link" ng-click="rewardCodeDetail.embed()" title="Embed"><i class="fa fa-code fa-3x"></i></a>\n' +
    '        </div>\n' +
    '      </script>\n' +
    '\n' +
    '      <i class="no-select info-help" role="button" tabIndex="0" style="margin-left: 15px;"\n' +
    '         popover-trigger="click, outsideClick"\n' +
    '         popover-append-to-body="true"\n' +
    '         uib-popover-template="\'social-tpl.html\'">Share</i>-->\n' +
    '\n' +
    '      <!--<div class="row" ng-if="rewardCodeDetail.shortUrl">\n' +
    '        <div class="col-xs-12 rcd-row">\n' +
    '          <a class="reward-share-link" ng-click="rewardCodeDetail.share(\'facebook\')"><i class="fa fa-facebook-square fa-3x"></i></a>\n' +
    '          <a class="reward-share-link" ng-click="rewardCodeDetail.share(\'linkedin\')"><i class="fa fa-linkedin-square fa-3x"></i></a>\n' +
    '          <a class="reward-share-link" ng-click="rewardCodeDetail.share(\'twitter\')"><i class="fa fa-twitter-square fa-3x"></i></a>\n' +
    '          <a class="reward-share-link" ng-click="rewardCodeDetail.share(\'google\')"><i class="fa fa-google-plus-square fa-3x"></i></a>\n' +
    '          <a class="reward-share-link" ng-click="rewardCodeDetail.embed()" title="Embed"><i class="fa fa-code fa-3x"></i></a>\n' +
    '        </div>\n' +
    '      </div>-->\n' +
    '\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="row modal-content-buttons">\n' +
    '    <div class="col-xs-12">\n' +
    '      <button ng-if="!rewardCodeDetail.rewardCode.isArchived" class="btn btn-default" ng-click="rewardCodeDetail.archive()" ng-disabled="rewardCodeDetail.isArchiving">\n' +
    '        {{rewardCodeDetail.isArchiving ? \'Archiving ...\' : \'Archive\'}}\n' +
    '      </button>\n' +
    '      <button class="btn btn-primary pull-right" ng-click="rewardCodeDetail.close()">\n' +
    '        Close\n' +
    '      </button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('my-memberships/reward-program/reward-code-create-modal.tpl.html',
    '<div class="modal-content-container">\n' +
    '    <div class="row">\n' +
    '      <div class="col-xs-12">\n' +
    '        <span class="sub-title ng-binding">\n' +
    '          Create Reward Code\n' +
    '        </span>\n' +
    '      </div>\n' +
    '      <div class="col-xs-12">\n' +
    '        <div class="form-section-description">\n' +
    '          <span class="sub-title-description" aria-hidden="false">Provide the details of a Reward Code and share with other member(s) of MyLeadPod</span>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="row">\n' +
    '      <uib-alert ng-repeat="alert in rewardProgram.alerts.getList()" type="{{alert.type}}" close="rewardProgram.alerts.remove($index)">{{alert.message}}</uib-alert>\n' +
    '      <div class="reward-program-form col-xs-12">\n' +
    '        <!-- Reward Code Details -->\n' +
    '        <div class="row">\n' +
    '          <div class="col-xs-12 form-section-description">\n' +
    '            Provide the Reward Code name and description\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <form name="rewardProgramForm">\n' +
    '          <div class="row form-group">\n' +
    '            <div class="col-xs-12">\n' +
    '              <div ng-class="{\'has-error\': (rewardProgramForm.$submitted || rewardProgramForm.rewardCode.$touched) &&\n' +
    '                            (rewardProgramForm.rewardCode.$error.required || rewardProgramForm.rewardCode.$error.maxlength ||\n' +
    '                            rewardProgramForm.rewardCode.$error.minlength || (rewardProgram.rewardCodeChecked && !rewardProgram.rewardCodeValid))}">\n' +
    '\n' +
    '                <label class="sr-only" for="rewardCode">Reward Code</label>\n' +
    '\n' +
    '                <div>\n' +
    '                  <div class="input-error" ng-show="rewardProgramForm.rewardCode.$error.required">Missing Reward Code</div>\n' +
    '                  <div class="input-error" ng-show="rewardProgramForm.rewardCode.$error.minlength">Reward Code must be at least 5 characters long</div>\n' +
    '                  <div class="input-error" ng-show="rewardProgramForm.rewardCode.$error.maxlength">Reward Code must not be longer than 20 characters</div>\n' +
    '                  <div class="input-error" ng-show="rewardProgram.rewardCodeChecked && !rewardProgram.rewardCodeValid">This Reward Code is not available, try another one</div>\n' +
    '                </div>\n' +
    '\n' +
    '                <div class="input-group left-inner-addon">\n' +
    '                  <i ng-show="!rewardProgram.rewardCodeChecked && !rewardProgram.rewardCodeValidating && !rewardProgram.rewardCodeValid" class="fa fa-hashtag"></i>\n' +
    '                  <i ng-show="rewardProgram.rewardCodeValidating" class="fa fa-circle-o-notch fa-spin-2x"></i>\n' +
    '                  <i ng-show="!rewardProgram.rewardCodeValidating && rewardProgram.rewardCodeChecked && rewardProgram.rewardCodeValid" class="fa fa-check code-container-valid"></i>\n' +
    '                  <i ng-show="!rewardProgram.rewardCodeValidating && rewardProgram.rewardCodeChecked && !rewardProgram.rewardCodeValid" class="fa fa-times code-container-invalid"></i>\n' +
    '\n' +
    '                  <input class="form-control input-lg" type="text" name="rewardCode" id="rewardCode"\n' +
    '                        placeholder="Reward Code"\n' +
    '                        ng-minlength="5" ng-maxlength="20"\n' +
    '                        size="20"\n' +
    '                        maxlength="20"\n' +
    '                        ng-change="rewardProgram.onRewardCodeChange()"\n' +
    '                        ng-blur="rewardProgram.validateRewardCode()"\n' +
    '                        ng-required="true" ng-model="rewardProgram.data.rewardCode"\n' +
    '                        popover-placement="\'top\'"\n' +
    '                        popover-trigger="focus"\n' +
    '                        popover-append-to-body="true"\n' +
    '                        popover-class="help"\n' +
    '                        uib-popover="{{rewardProgram.rewardCodeHelp}}" />\n' +
    '\n' +
    '                  <div class="input-group-btn">\n' +
    '                    <button class="btn btn-primary btn-md" type="button" data-short-title="Check" data-title="Check Availability" ng-disabled="!rewardProgram.data.rewardCode" ng-click="rewardProgram.validateRewardCode()"></button>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '          <div class="row">\n' +
    '          <div class="col-xs-12">\n' +
    '            <div class="control-description-subtle">\n' +
    '              Description is for your own reference only and it\'s not visible to public.\n' +
    '            </div>\n' +
    '            <div class="form-group">\n' +
    '              <label class="sr-only" for="description">Description</label>\n' +
    '              <textarea class="form-control" name="description" id="description" placeholder="Description"\n' +
    '                        ng-model="rewardProgram.data.description"></textarea>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        </form>\n' +
    '\n' +
    '        <!-- Reward Code -->\n' +
    '        <div class="sliding-details">\n' +
    '          <div class="sliding-header sliding-header-right">\n' +
    '            <span class="cursor-pointer no-outline" ng-click="rewardProgram.toggleMoreDetailsVisibility()">\n' +
    '              <i class="fa fa-chevron-down detail-arrow" ng-class="{\'fa-chevron-down\': !rewardProgram.detailsVisible, \'fa-chevron-up\': rewardProgram.detailsVisible}"></i>\n' +
    '              Rewards\n' +
    '            </span>\n' +
    '            <i class="no-select info-help" role="button" tabIndex="0" style="margin-left: 15px;"\n' +
    '               popover-trigger="click, outsideClick"\n' +
    '               popover-append-to-body="true"\n' +
    '               popover-class="help"\n' +
    '               uib-popover="{{rewardProgram.rewardsHelp}}">?</i>\n' +
    '          </div>\n' +
    '          <div class="sliding-content slide-css" ng-class="{slide: rewardProgram.detailsVisible}">\n' +
    '            <div class="row">\n' +
    '              <div class="col-xs-12 form-section-description">\n' +
    '                Select reward type for this code\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div class="row">\n' +
    '              <div class="col-xs-12">\n' +
    '                <div class="form-group reward-system-selector" ng-class="{active: rewardProgram.data.rewardType === \'rewardsProgram\'}" ng-click="rewardProgram.data.rewardType = \'rewardsProgram\'">\n' +
    '                  <input type="radio" name="rewardType" id="rewardsProgram" value="rewardsProgram" ng-model="rewardProgram.data.rewardType" ng-required="true" />\n' +
    '                  <label class="radio-label" for="rewardsProgram">\n' +
    '                    Rewards Program <span class="cursor-pointer detail-arrow"\n' +
    '                                          ng-if="rewardProgram.data.rewardType === \'rewardsProgram\'"\n' +
    '                                          ng-click="rewardProgram.toggleRewardsProgramDetailVisibility()">\n' +
    '                      <i class="fa fa-chevron-down"\n' +
    '                        ng-class="{\'fa-chevron-down\': !rewardProgram.isRewardsProgramDetailVisible, \'fa-chevron-up\': rewardProgram.isRewardsProgramDetailVisible}"></i>\n' +
    '                    </span>\n' +
    '                    <div class="control-description-subtle pan">(You can modify Rewards Program anytime)</div>\n' +
    '                  </label>\n' +
    '                  <div class="slide-css" ng-class="{slide: rewardProgram.isRewardsProgramDetailVisible}">\n' +
    '                    <div class="reward-system-detail" ng-show="rewardProgram.data.rewardType === \'rewardsProgram\'">\n' +
    '                      <span ng-if="rewardProgram.rewardsProgramSetup.length < 1">\n' +
    '                        Your Rewards Program is not set up. <a ui-sref="myMembershipsRewardProgramsSetup" target="_blank">Click here to manage</a>\n' +
    '                      </span>\n' +
    '                      <table class="rewards-program-options" ng-if="rewardProgram.rewardsProgramSetup.length > 0">\n' +
    '                        <tr ng-repeat="option in rewardProgram.rewardsProgramSetup | orderBy:\'successfulLeadPrice\'">\n' +
    '                          <td>{{option.name}}</td>\n' +
    '                          <td>{{option.successfulLeadPrice | currency:"$":0}}</td>\n' +
    '                        </tr>\n' +
    '                        <tr>\n' +
    '                          <td colspan="2">\n' +
    '                            <a ui-sref="myMembershipsRewardProgramsSetup" target="_blank">Click here to manage</a>\n' +
    '                          </td>\n' +
    '                        </tr>\n' +
    '                      </table>\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="row">\n' +
    '              <div class="col-xs-12">\n' +
    '                <div class="form-group reward-system-selector" ng-class="{active: rewardProgram.data.rewardType === \'customRewards\'}" role="button" ng-click="rewardProgram.data.rewardType = \'customRewards\'">\n' +
    '                  <input type="radio" name="rewardType" id="customRewards" value="customRewards" ng-model="rewardProgram.data.rewardType" ng-required="true" />\n' +
    '\n' +
    '                  <label class="radio-label" for="customRewards">\n' +
    '                    Customise Rewards\n' +
    '                    <div class="control-description-subtle pan">(Once Reward Code is created, you cannot modify these custom payouts)</div>\n' +
    '                  </label>\n' +
    '\n' +
    '                  <div class="reward-system-detail slide-css" ng-class="{slide: rewardProgram.data.rewardType === \'customRewards\'}">\n' +
    '\n' +
    '                    <div class="row form-group row-center-items animate-repeat" ng-repeat="option in rewardProgram.data.options track by option.uiId">\n' +
    '\n' +
    '                      <div class="col-xs-12 col-sm-7">\n' +
    '                        <div ng-class="{\'has-error\': (rewardProgramForm.$submitted || rewardProgramForm.name{{option.uiId}}.$touched) && (rewardProgramForm.name{{option.uiId}}.$error.required)}">\n' +
    '                          <label class="sr-only" for="name{{option.uiId}}">Name</label>\n' +
    '                          <div ng-show="rewardProgramForm.$submitted || rewardProgramForm.name{{option.uiId}}.$touched">\n' +
    '                            <div class="input-error" ng-show="rewardProgramForm.name{{option.uiId}}.$error.required">Missing name</div>\n' +
    '                          </div>\n' +
    '                          <div class="left-inner-addon">\n' +
    '                            <i class="fa fa-pencil inner-addon"></i>\n' +
    '                            <input class="form-control input-lg" type="text" name="name{{option.uiId}}" id="name{{option.uiId}}"\n' +
    '                                  placeholder="Name" ng-required="true"\n' +
    '                                  ng-model="rewardProgram.data.options[$index].name" />\n' +
    '                          </div>\n' +
    '                        </div>\n' +
    '                      </div>\n' +
    '\n' +
    '                      <div class="col-xs-10 col-sm-4">\n' +
    '                        <div ng-class="{\'has-error\': (rewardProgramForm.$submitted || rewardProgramForm.successfulLeadPrice{{option.uiId}}.$touched) && (rewardProgramForm.successfulLeadPrice{{option.uiId}}.$error.required || rewardProgramForm.successfulLeadPrice{{option.uiId}}.$error.number || rewardProgramForm.successfulLeadPrice{{option.uiId}}.$error.min)}">\n' +
    '                          <label class="sr-only" for="successfulLeadPrice{{option.uiId}}">Successful lead value</label>\n' +
    '                          <div ng-show="rewardProgramForm.$submitted || rewardProgramForm.successfulLeadPrice{{option.uiId}}.$touched">\n' +
    '                            <div class="input-error" ng-show="rewardProgramForm.successfulLeadPrice{{option.uiId}}.$error.number">Invalid successful lead value</div>\n' +
    '                            <div class="input-error" ng-show="rewardProgramForm.successfulLeadPrice{{option.uiId}}.$error.required">Missing successful lead value</div>\n' +
    '                            <div class="input-error" ng-show="rewardProgramForm.successfulLeadPrice{{option.uiId}}.$error.min">Successful lead value must not be negative</div>\n' +
    '                          </div>\n' +
    '                          <div class="left-inner-addon">\n' +
    '                            <i class="fa fa-dollar inner-addon"></i>\n' +
    '                            <input class="form-control input-lg" type="number" name="successfulLeadPrice{{option.uiId}}" id="successfulLeadPrice{{option.uiId}}"\n' +
    '                                  placeholder="Successful lead value" ng-required="true" min="0"\n' +
    '                                  ng-model="rewardProgram.data.options[$index].successfulLeadPrice" />\n' +
    '                          </div>\n' +
    '                        </div>\n' +
    '                      </div>\n' +
    '\n' +
    '                      <div class="col-xs-2 col-sm-1 text-center">\n' +
    '                          <a class="remove-icon cursor-pointer" role="button" ng-click="rewardProgram.deleteOption($index)" ng-if="rewardProgram.data.options.length > 1"><i class="fa fa-times fa-medium-size"></i></a>\n' +
    '                      </div>\n' +
    '                    </div>\n' +
    '                    <div class="row">\n' +
    '                      <div class="col-xs-12 text-xs-center">\n' +
    '                        <button type="button" class="btn btn-default" ng-click="rewardProgram.addOption()">\n' +
    '                          <i class="fa fa-plus"></i> Add option\n' +
    '                        </button>\n' +
    '                      </div>\n' +
    '                    </div>\n' +
    '\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <!-- Sharing -->\n' +
    '        <div class="sliding-details" style="margin-top: 10px;">\n' +
    '          <div class="sliding-header sliding-header-right">\n' +
    '            <span class="cursor-pointer no-outline" ng-click="rewardProgram.toggleSharingVisibility()">\n' +
    '              <i class="fa fa-chevron-down detail-arrow" ng-class="{\'fa-chevron-down\': !rewardProgram.sharingVisible, \'fa-chevron-up\': rewardProgram.sharingVisible}"></i>\n' +
    '              Sharing incoming leads\n' +
    '            </span>\n' +
    '            <i class="no-select info-help" role="button" tabIndex="0" style="margin-left: 15px;"\n' +
    '               popover-trigger="click, outsideClick"\n' +
    '               popover-append-to-body="true"\n' +
    '               popover-class="help"\n' +
    '               uib-popover="{{rewardProgram.shareHelp}}">?</i>\n' +
    '          </div>\n' +
    '          <div class="sliding-content slide-css" ng-class="{slide: rewardProgram.sharingVisible}">\n' +
    '            <div class="pending-overlay-light" ng-if="rewardProgram.searchingUser">\n' +
    '              <span class="fa fa-circle-o-notch fa-spin-2x"></span>\n' +
    '            </div>\n' +
    '            <div class="row slide-if" ng-if="rewardProgram.sharedUsers.length == 0">\n' +
    '              <div class="col-xs-12 form-section-description">\n' +
    '                Type in the email of an existing user with business subscription (max.1) (optional)\n' +
    '              </div>\n' +
    '              <div class="col-xs-12">\n' +
    '                <div class="form-group">\n' +
    '                  <search-autocomplete\n' +
    '                    control-id="share-autocomplete"\n' +
    '                    input-name="shareUsersInputSearch"\n' +
    '\n' +
    '                    placeholder="Search Business"\n' +
    '                    minlength="3"\n' +
    '                    clear-selected="false"\n' +
    '                    text-searching="false"\n' +
    '                    text-no-results="false"\n' +
    '\n' +
    '                    search-service="Search"\n' +
    '                    search-service-end-point="index"\n' +
    '                    remote-url-response-formatter="rewardProgram.searchResponseFormatter"\n' +
    '                    search-categories="rewardProgram.searchCategories"\n' +
    '                    selected-object="rewardProgram.onSearchSharedItemSelected"\n' +
    '\n' +
    '                    data-field="content.records"\n' +
    '                    search-fields="search"\n' +
    '                    title-field="value"\n' +
    '                    description-field="categoryDescription"\n' +
    '                    description-field2="timeDescription"\n' +
    '                    category-field="category"\n' +
    '\n' +
    '                    input-class="form-control input-lg"\n' +
    '                    match-class="autocomplete-highlight"\n' +
    '                    blur-on-select="true"\n' +
    '                    has-back-button="false"\n' +
    '                    update-input-on-select="false"\n' +
    '                    is-dropdown-relative="true"\n' +
    '                    override-suggestions="false"\n' +
    '                    field-required="false"\n' +
    '                    has-history="true" />\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div class="row slide-if" ng-if="rewardProgram.sharedUsers.length > 0">\n' +
    '              <div class="col-xs-12 form-group">\n' +
    '                <div class="no-associated-users">\n' +
    '                  This Reward Code will be shared with:\n' +
    '                </div>\n' +
    '                <div class="associated-users">\n' +
    '                  <div class="no-animate no-associated-users" ng-if="!rewardProgram.sharedUsers || rewardProgram.sharedUsers.length === 0">\n' +
    '                    There are no associated users to this Reward Code.\n' +
    '                  </div>\n' +
    '                  <div ng-repeat="user in rewardProgram.sharedUsers">\n' +
    '                    <table-user-details user="user" is-link="false" />\n' +
    '                    <div>\n' +
    '                      <button class="btn-sm btn-danger" ng-click="rewardProgram.removeUserShare()"><i class="fa fa-times"></i></button>\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <!-- Associated Users -->\n' +
    '        <div class="sliding-details" style="margin-top: 10px;">\n' +
    '          <div class="sliding-header sliding-header-right">\n' +
    '            <span class="cursor-pointer no-outline" ng-click="rewardProgram.toggleAssociatedUsersVisibility()">\n' +
    '              <i class="fa fa-chevron-down detail-arrow" ng-class="{\'fa-chevron-down\': !rewardProgram.associatedUsersVisible, \'fa-chevron-up\': rewardProgram.associatedUsersVisible}"></i>\n' +
    '              Associated Users\n' +
    '            </span>\n' +
    '            <i class="no-select info-help" role="button" tabIndex="0" style="margin-left: 15px;"\n' +
    '               popover-trigger="click, outsideClick"\n' +
    '               popover-append-to-body="true"\n' +
    '               popover-class="help"\n' +
    '               uib-popover="{{rewardProgram.associatedUsersHelp}}">?</i>\n' +
    '          </div>\n' +
    '          <div class="sliding-content slide-css" ng-class="{slide: rewardProgram.associatedUsersVisible}">\n' +
    '            <div class="row">\n' +
    '              <div class="col-xs-12 form-section-description">\n' +
    '                Search for the business users you\'d like to associate this Reward Code with.\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div class="row">\n' +
    '              <div class="col-xs-12 form-group">\n' +
    '                <search-autocomplete\n' +
    '                  control-id="associated-autocomplete"\n' +
    '                  placeholder="Search Business"\n' +
    '                  minlength="3"\n' +
    '                  clear-selected="true"\n' +
    '                  text-searching="false"\n' +
    '                  text-no-results="false"\n' +
    '\n' +
    '                  search-service="Search"\n' +
    '                  search-service-end-point="index"\n' +
    '                  remote-url-response-formatter="rewardProgram.searchResponseFormatter"\n' +
    '\n' +
    '                  data-field="content.records"\n' +
    '                  selected-object="rewardProgram.onSearchItemSelected"\n' +
    '\n' +
    '                  search-fields="search"\n' +
    '                  title-field="value"\n' +
    '                  description-field="categoryDescription"\n' +
    '                  description-field2="timeDescription"\n' +
    '                  category-field="category"\n' +
    '\n' +
    '                  input-name="associateUsersInputSearch"\n' +
    '                  input-class="form-control input-lg"\n' +
    '                  match-class="autocomplete-highlight"\n' +
    '                  blur-on-select="true"\n' +
    '                  has-back-button="false"\n' +
    '                  update-input-on-select="true"\n' +
    '\n' +
    '                  is-dropdown-relative="true"\n' +
    '\n' +
    '                  override-suggestions="true"\n' +
    '                  search-categories="rewardProgram.searchCategories"\n' +
    '                  field-required="false"\n' +
    '                  has-history="true" />\n' +
    '                </div>\n' +
    '            </div>\n' +
    '            <div class="row">\n' +
    '              <div class="col-xs-12 form-group">\n' +
    '                <div>\n' +
    '                  Associated Users\n' +
    '                  <span ng-if="rewardProgram.searchingUser" style="display: inline-block; margin-left: 15px;" class="fa fa-circle-o-notch fa-spin-2x"></span>\n' +
    '                </div>\n' +
    '                <div class="associated-users">\n' +
    '                  <div class="no-animate no-associated-users" ng-if="!rewardProgram.associatedUsers || rewardProgram.associatedUsers.length === 0">\n' +
    '                    There are no associated users to this Reward Code.\n' +
    '                  </div>\n' +
    '                  <div ng-repeat="user in rewardProgram.associatedUsers">\n' +
    '                    <table-user-details user="user" is-link="false" />\n' +
    '                    <div class="" data-delete-btn="{{$index}}">\n' +
    '                      <!--<button class="btn-sm btn-primary"\n' +
    '                              ng-click="rewardProgram.associatedUsers.splice($index, 1)"\n' +
    '                              ng-blur="rewardProgram.hideButton($index, this, $event)">See Profile</button>-->\n' +
    '                      <button class="btn-sm btn-danger"\n' +
    '                              ng-click="rewardProgram.associatedUsers.splice($index, 1)"\n' +
    '                              ng-blur="rewardProgram.hideButton($index, this, $event)"><i class="fa fa-times"></i></button>\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="row modal-content-buttons">\n' +
    '      <div class="col-xs-12 text-right">\n' +
    '        <button type="button" class="btn btn-default"\n' +
    '                ng-disabled="rewardProgram.isLoading" ng-click="rewardProgram.closeCreateCodeModal()">\n' +
    '          Cancel\n' +
    '        </button>\n' +
    '        <button type="button" class="btn btn-primary"\n' +
    '                ng-disabled="rewardProgramForm.$invalid || rewardProgram.isLoading || rewardProgram.rewardCodeValidating || !rewardProgram.rewardCodeChecked ||\n' +
    '                              (rewardProgram.rewardCodeChecked && !rewardProgram.rewardCodeValid) ||\n' +
    '                              (rewardProgram.data.rewardType === \'rewardsProgram\' && rewardProgram.rewardsProgramSetup.length < 1)"\n' +
    '                ng-click="rewardProgram.create(rewardProgramForm)">\n' +
    '          {{rewardProgram.isLoading ? \'Loading ...\' : \'Create\'}}\n' +
    '        </button>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('my-memberships/received-leads/received-leads.tpl.html',
    '<div class="col-xs-12" ng-controller="ReceivedLeadsCtrl as receivedLeads" id="received-leads-ctrl-container">\n' +
    '  <div class="page-content">\n' +
    '    <div class="row" ng-hide="receivedLeads.list.recordsExist() || !receivedLeads.list.isInitialised()">\n' +
    '      <div class="col-xs-12">\n' +
    '        <div class="page-empty-section text-center">\n' +
    '          <i class="fa fa-paper-plane fa-2x text-light-colour"></i>\n' +
    '          <div class="page-empty-section-text">\n' +
    '            You haven\'t received any leads\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row" ng-show="receivedLeads.list.recordsExist() && receivedLeads.list.isInitialised()">\n' +
    '      <div class="col-sm-5 col-md-4">\n' +
    '        <div class="form-group page-filter">\n' +
    '          <label class="sr-only" for="searchLead">Search lead</label>\n' +
    '          <div class="left-inner-addon">\n' +
    '            <i class="fa fa-search inner-addon"></i>\n' +
    '            <form name="searchLeadForm" ng-submit="receivedLeads.search()">\n' +
    '              <input class="form-control input-lg" type="search" name="searchLead" id="searchLead"\n' +
    '                     placeholder="Search lead" ng-model="receivedLeads.searchLead" ng-blur="receivedLeads.search();"/>\n' +
    '            </form>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="col-sm-4 col-md-3">\n' +
    '        <div class="form-group btn-group page-filter dropdown-large" uib-dropdown>\n' +
    '          <button id="lead-status" type="button" class="btn" uib-dropdown-toggle>\n' +
    '            {{receivedLeads.selectedStatusLabel}} <span class="caret"></span>\n' +
    '          </button>\n' +
    '          <ul class="dropdown-menu" uib-dropdown-menu role="menu" aria-labelledby="lead-status">\n' +
    '            <li role="menuitem" ng-repeat="(value, label) in receivedLeads.statuses"><a ng-click="receivedLeads.selectStatus(value)">{{label}}</a></li>\n' +
    '          </ul>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="table-responsive table-container wrap-allowed" ng-show="receivedLeads.list.recordsExist() && receivedLeads.list.isInitialised()">\n' +
    '      <table st-table="receivedLeads.list.getRecords()" st-pagination-scroll st-pipe="receivedLeads.list.load" class="table page-content-table allow-wrap">\n' +
    '        <thead class="hidden-xs">\n' +
    '        <tr>\n' +
    '          <th class="col-sm-3">Received from</th>\n' +
    '          <th class="col-sm-2">Date received</th>\n' +
    '          <th class="col-sm-3">Name</th>\n' +
    '          <th class="col-sm-2">Status</th>\n' +
    '          <th class="col-sm-2 text-right">Details</th>\n' +
    '        </tr>\n' +
    '        </thead>\n' +
    '        <tbody ng-hide="receivedLeads.list.isLoading() && !receivedLeads.list.isLoadingMoreRecords()">\n' +
    '        <tr ng-repeat="row in receivedLeads.list.getRecords()">\n' +
    '          <td class="col-xs-12 col-sm-3">\n' +
    '            <table class="table-cell-main-content">\n' +
    '              <tr class="row-center-items row-space-between">\n' +
    '                <td class="visible-xs" single-expand sub-content="receivedLeads.tableSubContentVisible" sub-content-idx="$index"></td>\n' +
    '                <td ng-click="$event.stopPropagation()">\n' +
    '                  <table-user-details user="row.sender"></table-user-details>\n' +
    '                </td>\n' +
    '                <td class="visible-xs text-right" style="white-space: nowrap !important;">\n' +
    '                  <span class="lead-status-tag description-tag" ng-class="row.status"></span>\n' +
    '                  <span class="cursor-pointer">\n' +
    '                    <i class="fa fa-ellipsis-h"></i>\n' +
    '                  </span>\n' +
    '                </td>\n' +
    '              </tr>\n' +
    '            </table>\n' +
    '            <div class="table-cell-sub-content-container slide-if" ng-if="receivedLeads.tableSubContentVisible[$index]">\n' +
    '              <div class="table-cell-sub-content visible-xs">\n' +
    '                <span class="text-muted">Date received</span><br/>\n' +
    '                <span class="text-normal">{{row.dateSent | date:\'d MMM yyyy\'}}</span>\n' +
    '              </div>\n' +
    '              <div class="table-cell-sub-content visible-xs">\n' +
    '                <span class="text-muted">Name</span><br/>\n' +
    '                <span class="text-normal">{{row.firstName}} {{row.lastName}}</span>\n' +
    '              </div>\n' +
    '              <div class="table-cell-sub-content visible-xs">\n' +
    '                <span class="text-muted">Status</span><br/>\n' +
    '                <span class="text-normal" ng-if="(row.status !== \'open\' && row.status !== \'new\') || row.isCoOwner">\n' +
    '                  {{receivedLeads.statuses[row.status]}}\n' +
    '                </span>\n' +
    '                <div ng-if="(row.status === \'open\' || row.status === \'new\') && !row.isCoOwner" class="btn-group page-filter dropdown-large" uib-dropdown>\n' +
    '                  <button id="lead-status-{{$index}}" type="button" class="btn" uib-dropdown-toggle>\n' +
    '                    {{receivedLeads.statuses[row.status]}} <span class="caret"></span>\n' +
    '                  </button>\n' +
    '                  <ul class="dropdown-menu" uib-dropdown-menu role="menu" aria-labelledby="lead-status-{{$index}}">\n' +
    '                    <li role="menuitem"><a>{{row.status === \'open\' ? \'Open\' : \'New\'}}</a></li>\n' +
    '                    <li role="menuitem"><a ng-click="receivedLeads.closeLead($index, \'successful\')">Successful</a></li>\n' +
    '                    <li role="menuitem"><a ng-click="receivedLeads.closeLead($index, \'unsuccessful\')">Unsuccessful</a></li>\n' +
    '                  </ul>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="table-cell-sub-content visible-xs">\n' +
    '                <span class="text-muted">Details</span><br/>\n' +
    '                <span class="text-normal"><a class="cursor-pointer" ng-click="receivedLeads.openDetail($index)">View lead</a></span>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </td>\n' +
    '          <td class="col-sm-2 hidden-xs">{{row.dateSent | date:\'d MMM yyyy\'}}</td>\n' +
    '          <td class="col-sm-3 hidden-xs">{{row.firstName}} {{row.lastName}}</td>\n' +
    '          <td class="col-sm-2 hidden-xs prevent-wrap" style="min-width:130px;">\n' +
    '            <span ng-if="(row.status !== \'open\' && row.status !== \'new\') || row.isCoOwner">\n' +
    '              {{receivedLeads.statuses[row.status]}}<span ng-if="row.isCoOwner" class="text-muted"> (shared)</span>\n' +
    '            </span>\n' +
    '            <div ng-if="(row.status === \'open\' || row.status === \'new\') && !row.isCoOwner" class="btn-group page-filter" uib-dropdown dropdown-append-to-body="true">\n' +
    '              <button id="lead-status-{{$index}}" type="button" class="btn" uib-dropdown-toggle>\n' +
    '                {{receivedLeads.statuses[row.status]}} <span class="caret"></span>\n' +
    '              </button>\n' +
    '              <ul class="dropdown-menu" uib-dropdown-menu role="menu" aria-labelledby="lead-status-{{$index}}">\n' +
    '                <li role="menuitem"><a>{{row.status === \'open\' ? \'Open\' : \'New\'}}</a></li>\n' +
    '                <li role="menuitem"><a ng-click="receivedLeads.closeLead($index, \'successful\')">Successful</a></li>\n' +
    '                <li role="menuitem"><a ng-click="receivedLeads.closeLead($index, \'unsuccessful\')">Unsuccessful</a></li>\n' +
    '              </ul>\n' +
    '            </div>\n' +
    '          </td>\n' +
    '          <td class="col-sm-2 hidden-xs text-right prevent-wrap"><a class="cursor-pointer" ng-click="receivedLeads.openDetail($index)">View lead</a></td>\n' +
    '        </tr>\n' +
    '        </tbody>\n' +
    '\n' +
    '        <tbody ng-if="receivedLeads.list.isLoading()">\n' +
    '        <tr>\n' +
    '          <td colspan="5" class="col-xs-12 text-center">\n' +
    '            <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>\n' +
    '          </td>\n' +
    '        </tr>\n' +
    '        </tbody>\n' +
    '      </table>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row" ng-show="!receivedLeads.list.isInitialised()">\n' +
    '      <div class="col-xs-12 text-center">\n' +
    '        <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('my-memberships/memberships/memberships.tpl.html',
    '<div class="col-xs-12" ng-controller="MembershipsCtrl as memberships">\n' +
    '  <div class="page-content">\n' +
    '\n' +
    '    <div class="row" ng-hide="memberships.list.recordsExist() || !memberships.list.isInitialised()">\n' +
    '      <div class="col-xs-12">\n' +
    '        <div class="page-empty-section text-center">\n' +
    '          <i class="fa fa-users fa-2x text-light-colour"></i>\n' +
    '          <div class="page-empty-section-text">\n' +
    '            You don\'t have any connections\n' +
    '          </div>\n' +
    '          <br/>\n' +
    '          Join pods and start receiving leads. Click "Ask for Leads" to add your first connection.\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row" ng-show="memberships.list.recordsExist() && memberships.list.isInitialised()">\n' +
    '      <div class="col-xs-12 col-sm-5 col-md-4">\n' +
    '        <div class="form-group page-filter">\n' +
    '          <label class="sr-only" for="searchName">Search</label>\n' +
    '          <div class="left-inner-addon">\n' +
    '            <i class="fa fa-search inner-addon"></i>\n' +
    '            <form name="membershipSearchForm" ng-submit="memberships.search()">\n' +
    '              <input class="form-control input-lg" type="text" name="searchName" id="searchName"\n' +
    '                     placeholder="Search" ng-model="memberships.searchName" ng-blur="memberships.search();" />\n' +
    '            </form>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="table-responsive table-container" ng-show="memberships.list.recordsExist() && memberships.list.isInitialised()">\n' +
    '      <table st-table="memberships.list.getRecords()" st-pagination-scroll st-pipe="memberships.list.load" class="table page-content-table allow-wrap">\n' +
    '        <thead class="hidden-xs">\n' +
    '        <tr>\n' +
    '          <th class="col-sm-3">Pod owner</th>\n' +
    '          <th class="col-sm-5">Industry</th>\n' +
    '          <th class="col-sm-2">Join date</th>\n' +
    '          <th class="text-right col-sm-2">Reward type</th>\n' +
    '          <!--<th class="text-right col-sm-2">Leads received</th>-->\n' +
    '        </tr>\n' +
    '        </thead>\n' +
    '        <tbody ng-hide="memberships.list.isLoading() && !memberships.list.isLoadingMoreRecords()">\n' +
    '        <tr ng-repeat="row in memberships.list.getRecords()">\n' +
    '          <td class="col-xs-12 col-sm-3">\n' +
    '            <table class="table-cell-main-content">\n' +
    '              <tr class="row-center-items row-space-between">\n' +
    '                <td class="visible-xs" single-expand sub-content="memberships.tableSubContentVisible" sub-content-idx="$index"></td>\n' +
    '                <td>\n' +
    '                  <table-user-details user="row.owner"></table-user-details>\n' +
    '                </td>\n' +
    '                <td class="visible-xs text-right">\n' +
    '                  <span class="cursor-pointer">\n' +
    '                    <i class="fa fa-ellipsis-h"></i>\n' +
    '                  </span>\n' +
    '                </td>\n' +
    '              </tr>\n' +
    '            </table>\n' +
    '            <div class="visible-xs">\n' +
    '              <div class="table-cell-sub-content-container slide-if" ng-if="memberships.tableSubContentVisible[$index]">\n' +
    '                <div class="table-cell-sub-content">\n' +
    '                  <span class="text-muted">Industry</span><br/>\n' +
    '                  <span class="text-normal">{{row.owner.industry.name}}</span>\n' +
    '                  <span class="text-normal" ng-if="row.owner.subIndustry"><br/>{{row.owner.subIndustry.name}}</span>\n' +
    '                </div>\n' +
    '                <div class="table-cell-sub-content">\n' +
    '                  <span class="text-muted">Join date</span><br/>\n' +
    '                  <span class="text-normal">{{row.startDate | date:\'d MMM yyyy\'}}</span>\n' +
    '                </div>\n' +
    '                <div class="table-cell-sub-content">\n' +
    '                  <span class="text-muted">Reward type</span><br/>\n' +
    '                  <span class="text-normal">{{row.contractType}}</span>\n' +
    '                </div>\n' +
    '                <!--<div class="table-cell-sub-content visible-xs">-->\n' +
    '                  <!--<span class="text-muted">Leads received</span><br/>-->\n' +
    '                  <!--<span class="text-normal">{{row.leadsReceived}}</span>-->\n' +
    '                <!--</div>-->\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </td>\n' +
    '          <td class="col-sm-5 hidden-xs">\n' +
    '            {{row.owner.industry.name}}\n' +
    '            <span ng-if="row.owner.subIndustry">\n' +
    '              <br/>{{row.owner.subIndustry.name}}\n' +
    '            </span>\n' +
    '          </td>\n' +
    '          <td class="col-sm-2 hidden-xs">{{row.startDate | date:\'d MMM yyyy\'}}</td>\n' +
    '          <td class="col-sm-2 hidden-xs text-right">{{row.contractType}}</td>\n' +
    '          <!--<td class="col-sm-2 hidden-xs text-right">{{row.leadsReceived}}</td>-->\n' +
    '        </tr>\n' +
    '        </tbody>\n' +
    '        <tbody ng-if="memberships.list.isLoading()">\n' +
    '        <tr>\n' +
    '          <td colspan="5" class="col-xs-12 text-center">\n' +
    '            <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>\n' +
    '          </td>\n' +
    '        </tr>\n' +
    '        </tbody>\n' +
    '      </table>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row" ng-show="!memberships.list.isInitialised()">\n' +
    '      <div class="col-xs-12 text-center">\n' +
    '        <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('my-memberships/join-pod/join-pod-modal.tpl.html',
    '<div class="modal-content-container">\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-12" ng-form="form">\n' +
    '      <!-- Title -->\n' +
    '      <div class="row">\n' +
    '        <div class="col-xs-12 modal-content-title">\n' +
    '          <span class="sub-title">Ask for Leads</span>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <!-- ENDOF: Title-->\n' +
    '\n' +
    '      <!-- Content -->\n' +
    '      <div class="row">\n' +
    '        <div class="col-xs-12">\n' +
    '\n' +
    '          <div ng-if="!joinPod.targetUser" ng-include="\'my-memberships/join-pod/partials/form-user-details.tpl.html\'"></div>\n' +
    '\n' +
    '          <uib-alert ng-repeat="alert in joinPod.alerts.getList()" type="{{alert.type}}" close="joinPod.alerts.remove($index)">{{alert.message}}</uib-alert>\n' +
    '          <div class="row form-section-body">\n' +
    '            <div class="col-xs-12">\n' +
    '              How would you like to pay for the received leads\n' +
    '              <span ng-if="joinPod.targetUser">from {{joinPod.targetUser.fullName}}</span>?\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="row form-section-body">\n' +
    '            <div class="col-xs-12">\n' +
    '              <reward-selector user="joinPod.user" has-monthly-subscription="true" on-change="joinPod.onRewardSelectionChange(formData, isFormValid)"></reward-selector>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <!-- ENDOF: Content -->\n' +
    '\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="row modal-content-buttons">\n' +
    '    <div class="col-xs-12 text-right">\n' +
    '      <button type="button" class="btn btn-default" ng-disabled="joinPod.isLoading" ng-click="joinPod.cancel()">\n' +
    '        Cancel\n' +
    '      </button>\n' +
    '      <button type="button" class="btn btn-primary"\n' +
    '              ng-disabled="form.$invalid || !joinPod.isRewardsFormValid || joinPod.isLoading" ng-click="joinPod.join()">\n' +
    '        {{joinPod.isLoading ? \'Loading ...\' : \'Send\'}}\n' +
    '      </button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('dashboard/stats/stats.tpl.html',
    '<div class="row" ng-controller="StatsCtrl as stats">\n' +
    '	<div class="col-lg-3 col-xs-6">\n' +
    '		<stat-block\n' +
    '			data-stat="{{stats.membersCount}}"\n' +
    '			data-subtext="{{stats.membersCount === 1 ? \'person\' : \'people\'}} receiving my leads"\n' +
    '			data-button-link="/my-pod"\n' +
    '			data-button-text="View All">\n' +
    '		</stat-block>\n' +
    '	</div>\n' +
    '	<div ng-if="stats.isBusinessAccountActive()" class="col-lg-3 col-xs-6">\n' +
    '		<stat-block\n' +
    '			data-stat="{{stats.membershipsCount}}"\n' +
    '			data-subtext="{{stats.membershipsCount === 1 ? \'person\' : \'people\'}} sending me leads"\n' +
    '			data-button-link="/my-memberships"\n' +
    '			data-button-text="View All">\n' +
    '		</stat-block>\n' +
    '	</div>\n' +
    '  <div ng-if="!stats.isBusinessAccountActive()" class="col-lg-3 col-xs-6">\n' +
    '    <stat-block\n' +
    '      data-stat="{{stats.sentLeadsCount}}"\n' +
    '      data-subtext="sent leads"\n' +
    '      data-button-link="/my-pod/sent-leads"\n' +
    '      data-button-text="View All">\n' +
    '    </stat-block>\n' +
    '  </div>\n' +
    '	<div class="col-lg-3 col-xs-6">\n' +
    '		<stat-block\n' +
    '			data-stat="{{stats.earnings}}"\n' +
    '			data-subtext="last month earnings"\n' +
    '			data-button-link="/account/earnings"\n' +
    '			data-button-text="View Details"\n' +
    '      ng-if="stats.earningsValue > 0 || stats.earningsValue == 0 && stats.donationsValue == 0">\n' +
    '		</stat-block>\n' +
    '    <stat-block\n' +
    '      data-stat="{{stats.donations}}"\n' +
    '      data-subtext="Donations"\n' +
    '      data-button-text="Donate"\n' +
    '      ng-click="stats.openDonate()"\n' +
    '      ng-if="stats.earningsValue == 0 && stats.donationsValue > 0">>\n' +
    '    </stat-block>\n' +
    '	</div>\n' +
    '	<div class="col-lg-3 col-xs-6">\n' +
    '		<stat-block\n' +
    '			data-stat="{{stats.balance}}"\n' +
    '			data-subtext="current balance"\n' +
    '			data-button-link="/account/earnings/withdraw"\n' +
    '			data-button-text="Withdraw">\n' +
    '		</stat-block>\n' +
    '	</div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('components/ui/prompt-dialog.tpl.html',
    '<div class="modal-content-container">\n' +
    '  <div class="row" ng-if="!promptDialog.isConfirmed">\n' +
    '    <!-- Title -->\n' +
    '    <!--<div class="col-xs-12 modal-content-title">-->\n' +
    '      <!--<span class="sub-title">Preferences</span>-->\n' +
    '    <!--</div>-->\n' +
    '    <!-- ENDOF: Title-->\n' +
    '\n' +
    '    <!-- Content -->\n' +
    '    <div class="col-xs-12">\n' +
    '      <div ng-bind-html="promptDialog.content"></div>\n' +
    '    </div>\n' +
    '    <!-- ENDOF: Content -->\n' +
    '\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="row modal-content-buttons" ng-if="!promptDialog.isConfirmed">\n' +
    '    <div class="text-right">\n' +
    '      <button type="button" class="btn btn-default" ng-disabled="promptDialog.isLoading" ng-click="promptDialog.cancel()">\n' +
    '        {{promptDialog.cancelButtonLabel}}\n' +
    '      </button>\n' +
    '      <button type="button" class="btn btn-primary"\n' +
    '              ng-disabled="promptDialog.isLoading" ng-click="promptDialog.confirm()">\n' +
    '        {{promptDialog.isLoading ? \'Loading ...\' : promptDialog.confirmButtonLabel}}\n' +
    '      </button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="row" ng-if="promptDialog.isConfirmed">\n' +
    '    {{promptDialog.onConfirmMessage}}\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('components/ui/credit-card-directive.tpl.html',
    '<div class="credit-card">\n' +
    '  <form name="ccForm" id="ccForm">\n' +
    '    <!-- Existing Card Info -->\n' +
    '    <div class="registered-card-info row slide-if" ng-if="!cc.showCCForm(ccForm)">\n' +
    '      <div class="col-xs-12" style="display: flex;">\n' +
    '        <div class="credit-card-details">\n' +
    '          <i class="fa fa-credit-card"></i>\n' +
    '          <span class="credit-card-number">{{cc.scope.creditCard.number}}</span>\n' +
    '          <span class="credit-card-expiry">\n' +
    '                                    ({{cc.scope.creditCard.expiryMonth}}/{{cc.scope.creditCard.expiryYear}})\n' +
    '                                  </span>\n' +
    '        </div>\n' +
    '        <a ng-click="cc.newCard=true; cc.scope.ccData.method=\'new\';" style="cursor: pointer;">Change</a>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <!-- New Card Form -->\n' +
    '    <div class="new-card-form slide-if" ng-if="cc.showCCForm(ccForm)">\n' +
    '      <div style="display: flex; justify-content: space-between; position: relative; width: 100%; flex-direction: column;">\n' +
    '        <div class="" style="position: relative; width: 100%;">\n' +
    '\n' +
    '          <!-- CC Prompt -->\n' +
    '          <div class="card-prompt" style="display: flex; justify-content: space-between;">\n' +
    '            <div>\n' +
    '              Provide credit card details:\n' +
    '            </div>\n' +
    '            <a ng-if="cc.scope.creditCard && cc.scope.creditCard.number"\n' +
    '               class="cursor-pointer"\n' +
    '               style="flex-shrink:0;"\n' +
    '               ng-click="cc.closeCCForm(ccForm)">Use Registered Card</a>\n' +
    '          </div>\n' +
    '\n' +
    '          <!-- CC Details-->\n' +
    '          <div class="cc-container">\n' +
    '            <div class="">\n' +
    '              <div class="row">\n' +
    '                <div class="col-xs-7" style="margin-bottom: 15px;">\n' +
    '                  <i class="fa fa-cc-visa fa-2x"></i> <i class="fa fa-cc-mastercard fa-2x"></i> <i class="fa fa-cc-amex fa-2x"></i>\n' +
    '                </div>\n' +
    '                <div class="col-xs-5" style="margin-bottom: 15px;">\n' +
    '                  <!-- Begin eWAY Linking Code -->\n' +
    '                  <div id="eWAYBlock">\n' +
    '                    <div style="text-align:right;">\n' +
    '                      <a href="https://www.eway.com.au/secure-site-seal?i=13&s=7&pid=880d42e7-6908-4374-8cfe-19357ae619da&theme=0" title="eWAY Payment Gateway" target="_blank" rel="nofollow">\n' +
    '                        <img alt="eWAY Payment Gateway" src="https://www.eway.com.au/developer/payment-code/verified-seal.ashx?img=13&size=7&pid=880d42e7-6908-4374-8cfe-19357ae619da&theme=0" />\n' +
    '                      </a>\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '                  <!-- End eWAY Linking Code -->\n' +
    '                </div>\n' +
    '              </div>\n' +
    '\n' +
    '              <div class="row">\n' +
    '                <div class="col-xs-12">\n' +
    '                  <div class="form-group" ng-class="{\'has-error\': (ccForm.$submitted || ccForm.holderName.$touched) && ccForm.holderName.$error.required}">\n' +
    '                    <label class="sr-only" for="holderName">Name on card</label>\n' +
    '                    <div ng-show="ccForm.$submitted || ccForm.holderName.$touched">\n' +
    '                      <div class="input-error" ng-show="ccForm.holderName.$error.required">Missing name on card.</div>\n' +
    '                    </div>\n' +
    '                    <div class="left-inner-addon">\n' +
    '                      <i class="fa fa-user inner-addon"></i>\n' +
    '                      <input class="form-control input-lg" type="text" name="holderName" id="holderName"\n' +
    '                             placeholder="Name on card" ng-required="true" ng-model="cc.scope.ccData.holderName" />\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '\n' +
    '              <div class="row">\n' +
    '                <div class="col-xs-12">\n' +
    '                  <div class="form-group" ng-class="{\'has-error\': (ccForm.$submitted || ccForm.number.$touched) && (ccForm.number.$error.required || ccForm.number.$error.ccNumber)}">\n' +
    '                    <label class="sr-only" for="number">Card number</label>\n' +
    '                    <div ng-show="ccForm.$submitted || ccForm.number.$touched">\n' +
    '                      <div class="input-error" ng-show="ccForm.number.$error.required">Missing card number.</div>\n' +
    '                      <div class="input-error" ng-show="!ccForm.number.$error.required && ccForm.number.$error.ccNumber">Invalid card number.</div>\n' +
    '                    </div>\n' +
    '                    <div class="left-inner-addon">\n' +
    '                      <i class="fa fa-credit-card inner-addon"></i>\n' +
    '                      <input class="form-control input-lg" type="text" name="number" id="number"\n' +
    '                             placeholder="Card number" ng-required="true" ng-model="cc.scope.ccData.number"\n' +
    '                             cc-number cc-type="cc.cards" />\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '\n' +
    '              <div class="row" cc-exp>\n' +
    '                <div class="col-xs-12">\n' +
    '                  <div ng-show="ccForm.$submitted || (ccForm.expiryMonth.$touched && ccForm.expiryYear.$touched)">\n' +
    '                    <div class="input-error" ng-show="ccForm.$error.ccExp">Invalid expiry date.</div>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '                <div class="col-xs-6 col-sm-6">\n' +
    '                  <div class="" ng-class="{\'has-error\': (ccForm.$submitted || ccForm.expiryMonth.$touched) && ccForm.expiryMonth.$error.required}">\n' +
    '                    <label class="sr-only" for="expiryMonth">Expiry month</label>\n' +
    '                    <div ng-show="ccForm.$submitted || ccForm.expiryMonth.$touched">\n' +
    '                      <div class="input-error" ng-show="ccForm.expiryMonth.$error.required">Missing Expiry month.</div>\n' +
    '                    </div>\n' +
    '                    <div class="left-inner-addon">\n' +
    '                      <i class="fa fa-calendar inner-addon"></i>\n' +
    '                      <select class="form-control input-lg" name="expiryMonth" id="expiryMonth" placeholder="Expiry month" ng-required="true"\n' +
    '                              ng-model="cc.scope.ccData.expiryMonth" cc-exp-month>\n' +
    '                        <option value="" disabled selected>Expiry month</option>\n' +
    '                        <option ng-repeat="month in cc.months" value="{{month}}">{{month}}</option>\n' +
    '                      </select>\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '\n' +
    '                </div>\n' +
    '\n' +
    '                <div class="col-xs-6 col-sm-6">\n' +
    '                  <div class="" ng-class="{\'has-error\': (ccForm.$submitted || ccForm.expiryYear.$touched) && ccForm.expiryYear.$error.required}">\n' +
    '                    <label class="sr-only" for="expiryYear">Expiry year</label>\n' +
    '                    <div ng-show="ccForm.$submitted || ccForm.expiryYear.$touched">\n' +
    '                      <div class="input-error" ng-show="ccForm.expiryYear.$error.required">Missing Expiry year.</div>\n' +
    '                    </div>\n' +
    '                    <div class="left-inner-addon">\n' +
    '                      <i class="fa fa-calendar inner-addon"></i>\n' +
    '                      <select class="form-control input-lg" name="expiryYear" id="expiryYear" placeholder="Expiry year" ng-required="true"\n' +
    '                              ng-model="cc.scope.ccData.expiryYear" cc-exp-year full-year>\n' +
    '                        <option value="" disabled selected>Expiry year</option>\n' +
    '                        <option ng-repeat="year in cc.years" value="{{year}}">{{year}}</option>\n' +
    '                      </select>\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '        </div>\n' +
    '        <!--<div class="col-xs-12" ng-if="cc.scope.creditCard && cc.scope.creditCard.number">\n' +
    '          <a style="cursor: pointer;"\n' +
    '             ng-click="cc.closeCCForm(ccForm)">Use Registered Card</a>\n' +
    '        </div>-->\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </form>\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('components/ui/apps-store-links-directive.tpl.html',
    '<div class="app-store-links" ng-if="isAvailable && !isAuthenticated">\n' +
    '    <span clas="apps-title">Download MyLeadPod app from store</span>\n' +
    '    <div class="apps-container">\n' +
    '        <a href="https://itunes.apple.com/au/app/myleadpod/id1138902261?mt=8"\n' +
    '            style="background:url(//linkmaker.itunes.apple.com/assets/shared/badges/en-us/appstore-lrg.svg) no-repeat; background-size:contain;">\n' +
    '        </a>\n' +
    '        <a href="https://play.google.com/store/apps/details?id=com.myleadpod.android.app&hl=en&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"\n' +
    '            style="background:url(./images/google-play-badge.png) no-repeat; background-size:contain;">\n' +
    '        </a>\n' +
    '    </div>\n' +
    '</div>');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('components/ui/action-toolbar-directive.tpl.html',
    '<div class="action-toolbar">\n' +
    '  <div>\n' +
    '    <div class="secondary-container">\n' +
    '\n' +
    '    </div>\n' +
    '    <div class="primary-container">\n' +
    '\n' +
    '    </div>\n' +
    '    <div id="at-transcluded" class="transcluded-content no-select">\n' +
    '      <div class="container-scroll-shadow" scroll-check with-delay="300" target-element-id="transcluded-{{id}}">\n' +
    '        <div class="scroll-left-indicator"><span style="transform: rotate(180deg);">&#9658;</span></div>\n' +
    '        <div class="scroll-right-indicator"><span>&#9658;</span></div>\n' +
    '      </div>\n' +
    '      <div id="transcluded-{{id}}" class="transcluded-content no-select" ng-transclude>\n' +
    '        <!-- tanscluded contnet -->\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('dashboard/pod/pod.tpl.html',
    '\n' +
    '<div class="col-xs-12" ng-controller="PodCtrl as pod">\n' +
    '  <h2 class="hidden-xs" style="margin-top: 45px;">My Pod</h2>\n' +
    '\n' +
    '  <div class="page-content" ng-show="!pod.list.records.length">\n' +
    '    <div class="row">\n' +
    '      <div class="col-xs-12">\n' +
    '        <div class="page-empty-section text-center">\n' +
    '          <i class="fa fa-users fa-2x text-light-colour"></i>\n' +
    '          <div class="page-empty-section-text">\n' +
    '            You have no people in your pod\n' +
    '          </div>\n' +
    '          <br/>\n' +
    '          Send invites to your business partners to join your pod. Click "Invite to my Pod" to start sending invites.\n' +
    '          <div class="page-empty-section-button">\n' +
    '            <a class="btn btn-primary" ui-sref="myPodSendInvite">Invite to my Pod</a>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="row" ng-show="pod.list.records.length"  style="margin-top:30px;">\n' +
    '    <div class="col-md-4">\n' +
    '      <pod-members-list list="pod.list"></pod-members-list>\n' +
    '    </div>\n' +
    '    <div class="col-md-8 hidden-xs">\n' +
    '      <pod-earnings-graph list="pod.dailyStats"></pod-earnings-graph>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '</div>\n' +
    '\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('dashboard/pod/pod-members-list-directive.tpl.html',
    '<div class="panel panel-default">\n' +
    '  <div class="panel-heading">\n' +
    '    <h4>People in my Pod</h4>\n' +
    '  </div>\n' +
    '  <div class="panel-body">\n' +
    '		<div class="panel-list">\n' +
    '		  <table class="table">\n' +
    '		    <tbody>\n' +
    '			    <tr ng-repeat="row in podMembersList.list.records">\n' +
    '			      <td class="col-xs-12">\n' +
    '			        <table class="table-cell-main-content">\n' +
    '			          <tr>\n' +
    '			            <td>\n' +
    '                    <table-user-details user="row.member" />\n' +
    '                  </td>\n' +
    '		            </tr>\n' +
    '		          </table>\n' +
    '			      </td>\n' +
    '			    </tr>\n' +
    '		    </tbody>\n' +
    '	    </table>\n' +
    '		</div>\n' +
    '		<p>\n' +
    '			<a ui-sref="myPod.members" class="btn btn-default btn-block" ng-if="podMembersList.list.recordsInfo.totalRecords > 4">All people</a>\n' +
    '		</p>\n' +
    '	</div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('dashboard/pod/pod-earnings-graph-directive.tpl.html',
    '<div class="panel panel-default">\n' +
    '	<div class="panel-heading">\n' +
    '		<h4>Earnings</h4>\n' +
    '	</div>\n' +
    '	<div class="panel-body">\n' +
    '		<chartist\n' +
    '			class="ct-chart"\n' +
    '			chartist-chart-type="Line"\n' +
    '			chartist-data="podEarningsGraph.chartData"\n' +
    '			chartist-chart-options="podEarningsGraph.chartOptions"\n' +
    '			chartist-events="podEarningsGraph.chartEvents"\n' +
    '			chartist-responsive-options="podEarningsGraph.responsiveOptions">\n' +
    '		</chartist>\n' +
    '	</div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('dashboard/memberships/received-leads-list-directive.tpl.html',
    '<div class="panel panel-default" ng-show="receivedLeadsList.list.records.length">\n' +
    '	<div class="panel-heading">\n' +
    '	    <h4>Newest received leads</h4>\n' +
    '  </div>\n' +
    '  <div class="panel-body">\n' +
    '		<div class="panel-list">\n' +
    '		  <table class="table">\n' +
    '		    <tbody>\n' +
    '			    <tr ng-repeat="row in receivedLeadsList.list.records">\n' +
    '			      <td class="col-xs-12 col-sm-9">\n' +
    '			        <table class="table-cell-main-content">\n' +
    '								<tr>\n' +
    '									<td>\n' +
    '										<div class="single-expand" ng-click="receivedLeadsList.openDetail($index)"></div>\n' +
    '										<table-user-details user="row.sender" is-link="false"></table-user-details>\n' +
    '									</td>\n' +
    '								</tr>\n' +
    '            	</table>\n' +
    '			      </td>\n' +
    '			      <td class="col-sm-3 hidden-xs">\n' +
    '			      	<a class="cursor-pointer" ng-click="receivedLeadsList.openDetail($index)">View lead</a>\n' +
    '			      </td>\n' +
    '			    </tr>\n' +
    '		    </tbody>\n' +
    '		  </table>\n' +
    '	  </div>\n' +
    '	  <p>\n' +
    '			<a ui-sref="myMemberships.receivedLeads" class="btn btn-default btn-block" ng-if="receivedLeadsList.list.recordsInfo.totalRecords > 4">All received leads</a>\n' +
    '		</p>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('dashboard/memberships/received-leads-graph-directive.tpl.html',
    '<div class="panel panel-default">\n' +
    '	<div class="panel-heading">\n' +
    '		<h4>Received leads</h4>\n' +
    '	</div>\n' +
    '	<div class="panel-body">\n' +
    '	<chartist \n' +
    '			id="received-leads-graph"\n' +
    '			chartist-chart-type="Line" \n' +
    '			chartist-data="receivedLeadsGraph.chartData" \n' +
    '			chartist-chart-options="receivedLeadsGraph.chartOptions"\n' +
    '			chartist-events="receivedLeadsGraph.chartEvents"\n' +
    '			chartist-responsive-options="receivedLeadsGraph.responsiveOptions">\n' +
    '		</chartist>\n' +
    '	</div>\n' +
    '</div>');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('dashboard/memberships/memberships.tpl.html',
    '<div class="col-xs-12" ng-controller="DashboardMembershipsCtrl as memberships">\n' +
    '	<h2 class="hidden-xs">My Business</h2>\n' +
    '\n' +
    '	<div class="page-content" ng-show="!memberships.list.records.length">\n' +
    '		<div class="row">\n' +
    '      <div class="col-xs-12">\n' +
    '        <div class="page-empty-section text-center">\n' +
    '          <i class="fa fa-users fa-2x text-light-colour"></i>\n' +
    '          <div class="page-empty-section-text">\n' +
    '            You have not received any leads\n' +
    '          </div>\n' +
    '          <br/>\n' +
    '          Join pods and start receiving leads. Click "Ask for Leads" to grow your network.\n' +
    '          <div class="page-empty-section-button">\n' +
    '            <a class="btn btn-primary" ui-sref="myMembershipsJoinPod">Ask for Leads</a>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '	<div class="row" ng-show="memberships.list.records.length">\n' +
    '		<div class="col-md-5 col-md-push-7">\n' +
    '			<received-leads-list list="memberships.list"></received-leads-list>\n' +
    '		</div>\n' +
    '		<div class="col-md-7 col-md-pull-5 hidden-xs">\n' +
    '			<received-leads-graph list="memberships.dailyStats"></received-leads-graph>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '\n' +
    '</div>\n' +
    '\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('account/profile/profile.tpl.html',
    '<div class="col-xs-12" ng-controller="ProfileCtrl as profile" id="profile-ctrl-container"\n' +
    '     ng-class="{\'image-edit-mode\': profile.imageEditMode, \'image-upload-mode\': profile.imageUploadMode, \'pointer-events-none\': profile.isDragging}">\n' +
    '\n' +
    '  <div class="row user-profile-container hidden-xs">\n' +
    '    <div class="col-xs-12">\n' +
    '\n' +
    '      <div style="position: relative;">\n' +
    '        <div class="page-content user-profile-lg" ng-style="profile.getProfileStyle()">\n' +
    '          <div class="profile-bg-edit">\n' +
    '            <button class="btn-sm btn-primary" ng-click="profile.imageEditMode = !profile.imageEditMode">\n' +
    '              Change Background\n' +
    '            </button>\n' +
    '            <!--<button class="btn-sm btn-primary"\n' +
    '                    ng-disabled="profile.isLoading"\n' +
    '                    ngf-change="profile.uploadBackgroundPhoto()"\n' +
    '                    ngf-select ng-model="profile.backgroundFile" accept="image/*">\n' +
    '              {{profile.isLoading ? \'Loading ...\' : \'Change Background\'}}\n' +
    '            </button>-->\n' +
    '          </div>\n' +
    '          <a class="crediting-link" ng-if="profile.bkgData.keepPattern && !profile.bkgData.backgroundFile" href="https://www.toptal.com/designers/subtlepatterns/gplay/" target="_blank"><i class="fa fa-copyright"></i>Design by Hoekstra</a>\n' +
    '          <div class="user-profile-container-lg">\n' +
    '            <div class="user-avatar-part">\n' +
    '              <!-- User Avatar -->\n' +
    '              <div user-avatar size="large" is-link="false" user="profile.userProfile"></div>\n' +
    '              <!-- Edit Profile Image Button -->\n' +
    '              <div uib-dropdown class="profile-img-edit" ng-if="profile.hasPhoto()">\n' +
    '                <button uib-dropdown-toggle class="btn-sm btn-primary" ng-disabled="profile.isLoading">\n' +
    '                  Edit Photo\n' +
    '                </button>\n' +
    '                <ul uib-dropdown-menu role="menu" class="dropdown-menu">\n' +
    '                  <li>\n' +
    '                    <a ng-if="profile.hasPhoto()"\n' +
    '                       ng-disabled="profile.isLoading" ng-click="profile.removePhoto()">\n' +
    '                      {{profile.isLoading ? \'Loading ...\' : \'Remove Photo\'}}\n' +
    '                    </a>\n' +
    '                  </li>\n' +
    '                  <li>\n' +
    '                    <a ng-if="profile.hasPhoto()"\n' +
    '                       ng-disabled="profile.isLoading" ngf-change="profile.uploadPhoto()"\n' +
    '                       ngf-select ng-model="profile.photoFile" accept="image/*">\n' +
    '                      {{profile.isLoading ? \'Loading ...\' : \'Change Photo\'}}\n' +
    '                    </a>\n' +
    '                  </li>\n' +
    '                </ul>\n' +
    '              </div>\n' +
    '              <div class="profile-img-edit" ng-if="!profile.hasPhoto()">\n' +
    '                <button class="btn-sm btn-primary"\n' +
    '                        ng-disabled="profile.isLoading"\n' +
    '                        ngf-change="profile.uploadPhoto()"\n' +
    '                        ngf-select ng-model="profile.photoFile" accept="image/*">\n' +
    '                  {{profile.isLoading ? \'Loading ...\' : \'Upload Photo\'}}\n' +
    '                </button>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div class="user-info-part">\n' +
    '              <div>\n' +
    '                <div class="user-name-and-company mbs" ng-class="{\'mbm\': profile.userProfile.businessName}" ng-style="profile.getHeadlineStyle()">\n' +
    '                  <!-- headline -->\n' +
    '                  <span class="sub-title user-name-container">{{profile.userProfile.fullName}}</span>\n' +
    '                  <span class="sub-title sub-title-regular sub-title-separator hidden-xs" ng-if="profile.userProfile.businessName">|</span>\n' +
    '                  <span class="sub-title sub-title-regular user-company-container">{{profile.userProfile.businessName}}</span>\n' +
    '                  <!-- Industry Info -->\n' +
    '                  <div class="sub-sub-title" ng-if="profile.userProfile.industry">{{profile.userProfile.industry.name}}{{profile.userProfile.subIndustry ? \' - \' + profile.userProfile.subIndustry.name : \'\'}}</div>\n' +
    '                </div>\n' +
    '                <!-- User Details -->\n' +
    '                <div class="user-details-container">\n' +
    '                  <div ng-if="profile.getAddress()"><i class="fa fa-map-marker"></i><a href="http://www.google.com/maps?q={{profile.addressLink}}" target="_blank">{{profile.getAddress()}}</a></div>\n' +
    '                  <div ng-if="profile.userProfile.phoneMobile"><i class="fa fa-phone"></i> <a href="tel:{{profile.userProfile.phoneMobile}}">{{profile.userProfile.phoneMobile}}</a></div>\n' +
    '                  <div ng-if="profile.userProfile.email"><i class="fa fa-envelope-o"></i> <a href="mailto:{{profile.userProfile.email}}">{{profile.userProfile.email}}</a></div>\n' +
    '                  <div ng-if="profile.userProfile.website"><i class="fa fa-desktop"></i> <a href="{{profile.formatWebsiteUrl()}}" target="_blank">{{profile.userProfile.website}}</a></div>\n' +
    '                </div>\n' +
    '                <!-- Commands -->\n' +
    '                <div class="user-details-buttons-container no-select no-animate">\n' +
    '                  <div class="sample-warning"><span>Only for illustration</span></div>\n' +
    '                  <a class="btn btn-primary">Send Message</a>\n' +
    '                  <a class="btn btn-primary">Send Reward</a>\n' +
    '                  <a class="btn btn-primary" ng-if="profile.userProfile.isBusinessAccountActive">Send Lead</a>\n' +
    '                  <a class="btn btn-primary" ng-if="profile.userProfile.isBusinessAccountActive">Register Lead</a>\n' +
    '                  <a class="btn btn-success" ng-if="profile.userProfile.isCharityAccountActive">Donate</a>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <!-- Commands -->\n' +
    '          <div class="user-details-buttons-container no-select no-animate">\n' +
    '            <a class="btn btn-primary">Send Message</a>\n' +
    '            <a class="btn btn-primary">Send Reward</a>\n' +
    '            <a class="btn btn-primary" ng-if="profile.userProfile.isBusinessAccountActive">Send Lead</a>\n' +
    '            <a class="btn btn-primary" ng-if="profile.userProfile.isBusinessAccountActive">Register Lead</a>\n' +
    '            <a class="btn btn-success" ng-if="profile.userProfile.isCharityAccountActive">Donate</a>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="image-blocker-container"></div>\n' +
    '      </div>\n' +
    '\n' +
    '    </div>\n' +
    '\n' +
    '\n' +
    '    <div class="col-xs-12 form-group image-control-panel slide-if" ng-if="profile.imageEditMode">\n' +
    '      <div class="page-content">\n' +
    '\n' +
    '        <div class="pending-overlay-white pending-overlay-column opacity-slow-if" ng-if="profile.isSavingBackgound">\n' +
    '          <span class="fa fa-circle-o-notch fa-spin-2x"></span>\n' +
    '          <div class="pending-overlay-description">Updating your profile...</div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="cancel-section">\n' +
    '          <button class="btn-sm btn-primary" ng-click="profile.close();">\n' +
    '            Cancel\n' +
    '          </button>\n' +
    '          <button class="btn-sm btn-primary" ng-click="profile.clearData();" ng-if="profile.hasEdits() && !profile.resetRequested">\n' +
    '            Clear\n' +
    '          </button>\n' +
    '          <button class="btn-sm btn-primary" ng-click="profile.saveBackgroundEdits()" ng-if="profile.hasEdits() && !profile.resetRequested">\n' +
    '            Save\n' +
    '          </button>\n' +
    '          <div ng-if="profile.hasEdits()" class="edit-instructions" style="position:absolute;font-size: 10px;margin-top: 5px;">\'Cancel\' will clear all your edits.</div>\n' +
    '        </div>\n' +
    '        <div class="options-section">\n' +
    '          <!-- Step container -->\n' +
    '\n' +
    '          <div class="top-section">\n' +
    '            <div ng-form="fileUploadForm">\n' +
    '                  <span class="error-instructions slide-if"\n' +
    '                        ng-if="fileUploadForm.file.$error.minWidth"\n' +
    '                        style="font-size:14px;display:block; margin-bottom:5px; color:salmon;font-weight: bold;">The image is too small (min. width required is 500px)\n' +
    '                  </span>\n' +
    '              <button class="btn-sm btn-primary"\n' +
    '                      ng-if="profile.backgroundStyleBkp"\n' +
    '                      ng-click="profile.resetToDefault()">\n' +
    '                Reset to Default\n' +
    '              </button>\n' +
    '              <button class="btn-sm btn-primary" ng-click="profile.changingColor = true;profile.resetRequested = false;">\n' +
    '                Change Colour\n' +
    '              </button>\n' +
    '              <button name="file"\n' +
    '                      ng-if="!profile.bkgData.backgroundFile"\n' +
    '                      class="btn-sm btn-primary"\n' +
    '                      ngf-select\n' +
    '                      ngf-min-width="500"\n' +
    '                      live-view="{block: false}"\n' +
    '                      ngf-multiple="false"\n' +
    '                      ngf-change="profile.uploadBackgroundPhoto()"\n' +
    '                      ng-model="profile.backgroundFile"\n' +
    '                      accept="image/*">\n' +
    '                Upload File\n' +
    '              </button>\n' +
    '              <button class="btn-sm btn-primary" ng-if="profile.bkgData.backgroundFile" ng-click="profile.removeBkgPhoto()">\n' +
    '                Remove Photo\n' +
    '              </button>\n' +
    '              <span class="edit-instructions" style="font-size:12px;display:block; margin-top:5px;">Uploaded images must be at least 500px wide. The dimensions of the area for the cover picture are 1140x215px.</span>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="slide-if" ng-if="profile.bkgData.backgroundFile || profile.changingColor" style="position: relative; width: 100%;">\n' +
    '            <div class="bottom-section">\n' +
    '              <div style="max-width: 250px;white-space: normal;font-size: 12px;" ng-if="profile.bkgData.backgroundFile">\n' +
    '                Move around the crop area in the content below. change the background colour to add some interesting effects.\n' +
    '              </div>\n' +
    '              <div>\n' +
    '                <span class="edit-instructions">Headline colour</span>\n' +
    '                <button colorpicker type="button"\n' +
    '                        ng-style="{\'background-color\': profile.bkgData.headlineColor || \'#3b374a\'}"\n' +
    '                        style="text-shadow: 1px 1px 3px black; display: block; margin: 0 auto; background-color: #3b374a;"\n' +
    '                        class="btn-sm btn-success"\n' +
    '                        colorpicker-position="bottom"\n' +
    '                        ng-model="profile.bkgData.headlineColor"><i class="fa fa-pencil"></i></button>\n' +
    '              </div>\n' +
    '              <div ng-if="!profile.bkgData.backgroundFile">\n' +
    '                <span class="edit-instructions">Keep pattern</span>\n' +
    '                <div class="compound-buttons">\n' +
    '                  <div role="button" tabindex="1"\n' +
    '                       ng-click="profile.bkgData.keepPattern = true; profile.edits.patternEdited = true;"\n' +
    '                       ng-class="{\'active\': profile.bkgData.keepPattern}">Yes\n' +
    '                  </div>\n' +
    '                  <div role="button" tabindex="1"\n' +
    '                       ng-click="profile.bkgData.keepPattern = false; profile.edits.patternEdited = true;"\n' +
    '                       ng-class="{\'active\': !profile.bkgData.keepPattern}">No\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div>\n' +
    '                <span class="edit-instructions">Pick background color from palette</span>\n' +
    '                <div style="display: flex; align-items: center;">\n' +
    '                  <button colorpicker type="button"\n' +
    '                          ng-style="{\'background-color\': profile.bkgData.selectedColor || \'#fffffa\'}"\n' +
    '                          style="text-shadow: 1px 1px 3px black; display: inline-block; margin-right: 5px;"\n' +
    '                          class="btn-sm btn-success"\n' +
    '                          colorpicker-position="bottom"\n' +
    '                          ng-model="profile.bkgData.selectedColor"><i class="fa fa-pencil"></i></button>\n' +
    '                  <ngjs-color-picker style="display: inline-block;" options="profile.customOptions" custom-colors="profile.customColors" selected-color="profile.bkgData.selectedColor"></ngjs-color-picker>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div>\n' +
    '                <button ng-click="profile.clearColourSettings()"\n' +
    '                        class="btn-sm btn-primary"\n' +
    '                        style="color: black; margin-left: 5px; color: white;"\n' +
    '                        alt="Clear both colour settings" title="Clear both colour settings">Reset all colours</button>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="slide-if" ng-if="profile.resetRequested" style="position: relative; width: 100%;">\n' +
    '            <div class="bottom-section" style="align-items: center;justify-content: center; height: 60px;">\n' +
    '              <span class="edit-instructions">This will remove all the current settings. Do you wish to proceed?</span>\n' +
    '              <button class="btn-sm btn-primary" ng-click="profile.resetBackgroundData()" style="margin-left: 10px;">\n' +
    '                Reset\n' +
    '              </button>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="col-xs-12" ng-if="profile.backgroundFile" style="z-index: 2;">\n' +
    '      <div class="image-cropper-container" style="position:relative; height: 300px; background-color:white; border-radius: 5px;">\n' +
    '        <ui-cropper image="profile.backgroundFile  | ngfDataUrl"\n' +
    '                    change-on-fly="true"\n' +
    '                    result-image="profile.bkgData.backgroundFile"\n' +
    '                    area-min-size="{w:30, h:30}"\n' +
    '                    init-max-area="true"\n' +
    '                    area-type="rectangle"\n' +
    '                    aspect-ratio="1140/215"\n' +
    '                    result-image-size="{w: 1140,h: 215}"></ui-cropper>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '  </div>\n' +
    '\n' +
    '\n' +
    '  <div class="page-content info-content">\n' +
    '    <div class="row visible-xs">\n' +
    '      <div class="col-xs-4 col-sm-3 col-md-2 col-lg-2">\n' +
    '        <div user-avatar size="large" is-link="false" user="profile.userProfile"></div>\n' +
    '      </div>\n' +
    '      <div class="col-xs-8 col-sm-9 col-md-10 col-lg-10">\n' +
    '        <span class="sub-title">Upload your photo</span>\n' +
    '        <p class="upload-photo-sub-title">Photo should be at least 300 x 300px</p>\n' +
    '      </div>\n' +
    '      <div class="col-xs-12 col-sm-9 col-md-10 col-lg-10">\n' +
    '        <button class="btn btn-default upload-photo-btn" ng-if="profile.hasPhoto()"\n' +
    '                ng-disabled="profile.isLoading" ng-click="profile.removePhoto()">\n' +
    '          {{profile.isLoading ? \'Loading ...\' : \'Remove Photo\'}}\n' +
    '        </button>\n' +
    '        <button class="btn btn-primary upload-photo-btn" ng-if="profile.hasPhoto()"\n' +
    '                ng-disabled="profile.isLoading" ngf-change="profile.uploadPhoto()"\n' +
    '                ngf-select ng-model="profile.photoFile" accept="image/*">\n' +
    '          {{profile.isLoading ? \'Loading ...\' : \'Change Photo\'}}\n' +
    '        </button>\n' +
    '        <button class="btn btn-primary upload-photo-btn" ng-if="!profile.hasPhoto()"\n' +
    '                ng-disabled="profile.isLoading" ngf-change="profile.uploadPhoto()"\n' +
    '                ngf-select ng-model="profile.photoFile" accept="image/*">\n' +
    '          {{profile.isLoading ? \'Loading ...\' : \'Upload Photo\'}}\n' +
    '        </button>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <form name="userProfileForm" class="user-profile-form" ng-submit="userProfileForm.$valid && !profile.isLoading && profile.save()">\n' +
    '      <div class="row form-section-title">\n' +
    '        <div class="col-xs-12">\n' +
    '          <span class="sub-title">Basic information</span>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row form-section-body">\n' +
    '        <div class="col-xs-12 col-sm-6">\n' +
    '          <div class="form-group" ng-class="{\'has-error\': (userProfileForm.$submitted || userProfileForm.firstName.$touched) && userProfileForm.firstName.$error.required}">\n' +
    '            <label class="sr-only" for="firstName">First name</label>\n' +
    '            <div ng-show="userProfileForm.$submitted || userProfileForm.firstName.$touched">\n' +
    '              <div class="input-error" ng-show="userProfileForm.firstName.$error.required">Tell us your first name.</div>\n' +
    '            </div>\n' +
    '            <div class="left-inner-addon">\n' +
    '              <i class="fa fa-user inner-addon"></i>\n' +
    '              <input class="form-control input-lg" type="text" name="firstName" id="firstName"\n' +
    '                     placeholder="First name" ng-required="true" ng-model="profile.data.firstName" />\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="col-xs-12 col-sm-6">\n' +
    '          <div class="form-group" ng-class="{\'has-error\': (userProfileForm.$submitted || userProfileForm.lastName.$touched) && userProfileForm.lastName.$error.required}">\n' +
    '            <label class="sr-only" for="lastName">Last name</label>\n' +
    '            <div ng-show="userProfileForm.$submitted || userProfileForm.lastName.$touched">\n' +
    '              <div class="input-error" ng-show="userProfileForm.lastName.$error.required">Tell us your last name.</div>\n' +
    '            </div>\n' +
    '            <div class="left-inner-addon">\n' +
    '              <i class="fa fa-user inner-addon"></i>\n' +
    '              <input class="form-control input-lg" type="text" name="lastName" id="lastName"\n' +
    '                     placeholder="Last name" ng-required="true" ng-model="profile.data.lastName" />\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="row">\n' +
    '        <div class="col-xs-12 col-sm-6">\n' +
    '          <div class="form-group" ng-class="{\'has-error\': (userProfileForm.$submitted || userProfileForm.email.$touched) && (userProfileForm.email.$error.required || userProfileForm.email.$error.email)}">\n' +
    '            <label class="sr-only" for="email">Email</label>\n' +
    '            <div ng-show="userProfileForm.$submitted || userProfileForm.email.$touched">\n' +
    '              <div class="input-error" ng-show="userProfileForm.email.$error.required">Tell us your email.</div>\n' +
    '              <div class="input-error" ng-show="userProfileForm.email.$error.email">This is not a valid email.</div>\n' +
    '            </div>\n' +
    '            <div class="left-inner-addon">\n' +
    '              <i class="fa fa-envelope-o inner-addon"></i>\n' +
    '              <input class="form-control input-lg" type="email" name="email" id="email" placeholder="Email"\n' +
    '                     ng-required="true" ng-model="profile.data.email" />\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="col-xs-12 col-sm-6">\n' +
    '          <div class="form-group" ng-class="{\'has-error\': (userProfileForm.$submitted || userProfileForm.phoneMobile.$touched) && userProfileForm.phoneMobile.$error.required}">\n' +
    '            <label class="sr-only" for="phoneMobile">Mobile</label>\n' +
    '            <div ng-show="userProfileForm.$submitted || userProfileForm.phoneMobile.$touched">\n' +
    '              <div class="input-error" ng-show="userProfileForm.phoneMobile.$error.required">Tell us your mobile.</div>\n' +
    '            </div>\n' +
    '            <div class="left-inner-addon">\n' +
    '              <i class="fa fa-mobile inner-addon"></i>\n' +
    '              <input class="form-control input-lg" type="text" name="phoneMobile" id="phoneMobile"\n' +
    '                     placeholder="Mobile" ng-required="true" ng-model="profile.data.phoneMobile" />\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row form-section-title">\n' +
    '        <div class="col-xs-12">\n' +
    '          <span class="sub-title">Change password</span>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row form-section-body">\n' +
    '        <div class="col-xs-12 col-sm-6">\n' +
    '          <div class="form-group">\n' +
    '            <label class="sr-only" for="password">New Password</label>\n' +
    '            <div class="left-inner-addon">\n' +
    '              <i class="fa fa-lock inner-addon"></i>\n' +
    '              <input class="form-control input-lg" type="password" name="password" id="password"\n' +
    '                     placeholder="New password" ng-required="false" ng-model="profile.data.password" />\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="col-xs-12 col-sm-6">\n' +
    '          <div class="form-group" ng-class="{\'has-error\': (userProfileForm.$submitted || userProfileForm.confirmPassword.$touched) && userProfileForm.confirmPassword.$error.match}">\n' +
    '            <label class="sr-only" for="confirmPassword">Confirm new password</label>\n' +
    '            <div ng-show="userProfileForm.$submitted || userProfileForm.confirmPassword.$touched">\n' +
    '              <div class="input-error" ng-show="userProfileForm.confirmPassword.$error.match">Passwords do not match.</div>\n' +
    '            </div>\n' +
    '            <div class="left-inner-addon">\n' +
    '              <i class="fa fa-lock inner-addon"></i>\n' +
    '              <input class="form-control input-lg" type="password" name="confirmPassword" id="confirmPassword"\n' +
    '                     placeholder="Confirm new password" ng-required="false" ng-model="profile.data.confirmPassword"\n' +
    '                     match="profile.data.password" />\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row form-section-title">\n' +
    '        <div class="col-xs-12">\n' +
    '          <span class="sub-title">Business information</span>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row form-section-body">\n' +
    '        <div class="col-xs-12 col-sm-6">\n' +
    '          <div class="form-group">\n' +
    '            <label class="sr-only" for="businessName">Company / Organisation</label>\n' +
    '            <div class="left-inner-addon">\n' +
    '              <i class="fa fa-briefcase inner-addon"></i>\n' +
    '              <input class="form-control input-lg" type="text" name="businessName" id="businessName"\n' +
    '                     placeholder="Company / Organisation" ng-required="false" ng-model="profile.data.businessName" />\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="col-xs-12 col-sm-6">\n' +
    '          <div class="form-group" ng-class="{\'has-error\': (userProfileForm.$submitted || userProfileForm.location.$touched) && userProfileForm.location.$error.required}">\n' +
    '            <label class="sr-only" for="location">Location</label>\n' +
    '            <div ng-show="userProfileForm.$submitted || userProfileForm.location.$touched">\n' +
    '              <div class="input-error" ng-show="userProfileForm.location.$error.required">Tell us your location.</div>\n' +
    '            </div>\n' +
    '            <div class="left-inner-addon">\n' +
    '              <i class="fa fa-map-marker inner-addon"></i>\n' +
    '              <input class="form-control input-lg" type="text" name="location" id="location"\n' +
    '                     placeholder="Location" ng-required="false" ng-model="profile.data.location"\n' +
    '                     location-search input-id="location" place-changed-callback="profile.placeChangedCallback" />\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row">\n' +
    '        <div class="col-xs-12 col-sm-6">\n' +
    '          <div class="form-group">\n' +
    '            <label class="sr-only" for="industry">Classification</label>\n' +
    '            <div class="left-inner-addon">\n' +
    '              <i class="fa fa-folder-o inner-addon"></i>\n' +
    '              <angucomplete-alt id="industry"\n' +
    '                                placeholder="Classification"\n' +
    '                                pause="100"\n' +
    '                                selected-object="profile.onClassificationSelect"\n' +
    '                                local-data="profile.industries"\n' +
    '                                search-fields="name"\n' +
    '                                title-field="name"\n' +
    '                                minlength="2"\n' +
    '                                match-class="autocomplete-highlight"\n' +
    '                                input-class="form-control input-lg"\n' +
    '                                input-name="industry"\n' +
    '                                field-required="false"\n' +
    '                                initial-value="profile.selectedClassification" />\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="col-xs-12 col-sm-6">\n' +
    '          <div class="form-group">\n' +
    '            <label class="sr-only" for="website">Website</label>\n' +
    '            <div class="left-inner-addon">\n' +
    '              <i class="fa fa-desktop inner-addon"></i>\n' +
    '              <input class="form-control input-lg" type="text" name="website" id="website"\n' +
    '                     placeholder="Website" ng-required="false" ng-model="profile.data.website" />\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="row">\n' +
    '        <div class="col-xs-12">\n' +
    '          <div class="form-group">\n' +
    '            <label class="sr-only" for="info">Describe your business and services</label>\n' +
    '            <textarea class="form-control" name="info" id="info" placeholder="Describe your business and services"\n' +
    '                      ng-model="profile.data.description" msd-elastic></textarea>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row form-section-title">\n' +
    '        <div class="col-xs-12">\n' +
    '          <span class="sub-title">Billing details</span>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row form-section-body">\n' +
    '        <div class="col-xs-12 col-sm-6">\n' +
    '          <div class="form-group">\n' +
    '            <label class="sr-only" for="idCountryPostal">Country</label>\n' +
    '            <div class="left-inner-addon">\n' +
    '              <i class="fa fa-map-marker"></i>\n' +
    '              <select class="form-control input-lg" name="idCountryPostal" id="idCountryPostal" placeholder="Country"\n' +
    '                      ng-required="false" ng-model="profile.data.addressPostal.idCountryPostal" ng-change="profile.onCountryChange()">\n' +
    '                <option value="" disabled selected>Country</option>\n' +
    '                <option ng-repeat="country in profile.getCountries()" value="{{country.id}}">{{country.name}}</option>\n' +
    '              </select>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="col-xs-12 col-sm-6" ng-if="profile.data.addressPostal.idCountryPostal && profile.getStates().length">\n' +
    '          <div class="form-group">\n' +
    '            <label class="sr-only" for="idStatePostal">State</label>\n' +
    '            <div class="left-inner-addon">\n' +
    '              <i class="fa fa-map-marker"></i>\n' +
    '              <select class="form-control input-lg" name="idStatePostal" id="idStatePostal" placeholder="State"\n' +
    '                      ng-model="profile.data.addressPostal.idStatePostal" ng-required="false" ng-disabled="!profile.data.addressPostal.idCountryPostal">\n' +
    '                <option value="" disabled selected>State</option>\n' +
    '                <option ng-repeat="state in profile.getStates()" value="{{state.id}}">{{state.name}}</option>\n' +
    '              </select>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row">\n' +
    '        <div class="col-xs-12 col-sm-6">\n' +
    '          <div class="form-group">\n' +
    '            <label class="sr-only" for="addressPostal">Street address</label>\n' +
    '            <div class="left-inner-addon">\n' +
    '              <i class="fa fa-map-marker inner-addon"></i>\n' +
    '              <input class="form-control input-lg" type="text" name="addressPostal" id="addressPostal"\n' +
    '                     placeholder="Street address" ng-required="false" ng-model="profile.data.addressPostal.addressPostal" />\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="col-xs-12 col-sm-6">\n' +
    '          <div class="form-group">\n' +
    '            <label class="sr-only" for="suburbPostal">Suburb</label>\n' +
    '            <div class="left-inner-addon">\n' +
    '              <i class="fa fa-map-marker inner-addon"></i>\n' +
    '              <input class="form-control input-lg" type="text" name="suburbPostal" id="suburbPostal"\n' +
    '                     placeholder="Suburb" ng-required="false" ng-model="profile.data.addressPostal.suburbPostal" />\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="col-xs-12 col-sm-6">\n' +
    '          <div class="form-group">\n' +
    '            <label class="sr-only" for="postCodePostal">Post code</label>\n' +
    '            <div class="left-inner-addon">\n' +
    '              <i class="fa fa-map-marker inner-addon"></i>\n' +
    '              <input class="form-control input-lg" type="text" name="postCodePostal" id="postCodePostal"\n' +
    '                     placeholder="Post code" ng-required="false" ng-model="profile.data.addressPostal.postCodePostal" />\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row form-section-title">\n' +
    '        <div class="col-xs-12">\n' +
    '          <span class="sub-title">Deactivate Account</span>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row form-section-body">\n' +
    '        <div class="col-xs-12 col-sm-6 form-group">\n' +
    '          Follow the link to <a ng-click="profile.deactivateAccount()" style="cursor: pointer;">deactive your account</a>.\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row form-section-body">\n' +
    '        <div class="col-xs-12 form-group">\n' +
    '          <button type="submit" class="btn btn-primary btn-lg btn-block"\n' +
    '                  ng-disabled="profile.isLoading">\n' +
    '            {{profile.isLoading ? \'Loading ...\' : \'Update Profile\'}}\n' +
    '          </button>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </form>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('account/profile/photo-upload.tpl.html',
    '<div class="modal-content-container">\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-12 photo-upload-crop-container">\n' +
    '      <div ngf-drop ng-model="photoUpload.photoFile" ngf-pattern="image/*" class="cropArea">\n' +
    '        <ui-cropper image="photoUpload.photoFile  | ngfDataUrl" result-image="photoUpload.croppedDataUrl"></ui-cropper>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="row modal-content-buttons">\n' +
    '    <div class="col-xs-12 text-right">\n' +
    '      <button class="btn btn-default" ng-disabled="photoUpload.isLoading" ng-click="photoUpload.cancel()">\n' +
    '        Cancel\n' +
    '      </button>\n' +
    '      <button class="btn btn-primary" ng-disabled="photoUpload.isLoading" ng-click="photoUpload.ok()">\n' +
    '        {{photoUpload.isLoading ? \'Loading ...\' : \'Add Photo\'}}\n' +
    '      </button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('account/profile/deactivate-modal.tpl.html',
    '<div class="modal-content-container deactivate-account-modal">\n' +
    '  <div class="row">\n' +
    '    <!-- Title -->\n' +
    '    <div class="col-xs-12 modal-content-title">\n' +
    '      <span class="sub-title" >Account Deactivation</span>\n' +
    '    </div>\n' +
    '    <!-- ENDOF: Title-->\n' +
    '\n' +
    '    <!-- Content -->\n' +
    '\n' +
    '    <div class="row">\n' +
    '      <div class="col-xs-12">\n' +
    '        <div class="steps">\n' +
    '          <div class="step" data-step-id="0">\n' +
    '            Account to Remove\n' +
    '          </div>\n' +
    '          <div class="step-separator" data-after-step-id="1">\n' +
    '            <span class="fa fa-chevron-right"></span>\n' +
    '          </div>\n' +
    '          <div class="step" data-step-id="1">\n' +
    '            Feedback\n' +
    '          </div>\n' +
    '          <div class="step-separator" data-after-step-id="2">\n' +
    '            <span class="fa fa-chevron-right"></span>\n' +
    '          </div>\n' +
    '          <div class="step" data-step-id="2">\n' +
    '            Deactivate\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row">\n' +
    '      <div class="col-xs-12">\n' +
    '        <div class="stepped-wrapper" content-slider>\n' +
    '          <div class="stepped-container">\n' +
    '\n' +
    '            <div class="step-content" data-step-id="0">\n' +
    '              <div class="split-content">\n' +
    '                <div class="profile-content-container" ng-if="ctrl.isBusinessAccount">\n' +
    '\n' +
    '                  <div class="profile-content-title">\n' +
    '                    Deactivate Business Account\n' +
    '                  </div>\n' +
    '\n' +
    '                  <div class="profile-content">\n' +
    '                    By clicking Continue, you will start the process of deactivating your business account and you will no longer need to pay subscription fees.<br>\n' +
    '                    Other MyLeadPod members will no longer be able to locate your business on MyLeadPod app and will not be able to send you leads.<br>\n' +
    '                    <!--<span class="slide-if" ng-if="!ctrl.data.wholeAccount">\n' +
    '                      Your account will be downgraded to the free account.\n' +
    '                    </span>-->\n' +
    '                  </div>\n' +
    '\n' +
    '                  <!--<div style="text-align: center; margin-top: 10px; background-color: white;">\n' +
    '                    <input type="checkbox" id="removeFreeAccount" ng-model="ctrl.data.wholeAccount" style="margin: 10px;" />\n' +
    '                    <label for="removeFreeAccount">Close my free account</label>\n' +
    '                  </div>-->\n' +
    '\n' +
    '                  <div class="profile-content-buttons">\n' +
    '                    <button class="btn btn-primary" data-step-to="1">Continue</button>\n' +
    '                  </div>\n' +
    '\n' +
    '                </div>\n' +
    '                <div class="profile-content-container" ng-if="!ctrl.isBusinessAccount">\n' +
    '                  <div class="profile-content-title">\n' +
    '                    Deactivate my account.\n' +
    '                  </div>\n' +
    '                  <div class="profile-content">\n' +
    '                    By clicking Continue, you will start the process of deactivating your MyLeadPod account and will no longer be able to send the leads and to be rewarded.<br>\n' +
    '                    You will also be unsubscribed from all MyLeadPod marketing communication.\n' +
    '                  </div>\n' +
    '                  <div class="profile-content-buttons">\n' +
    '                    <button class="btn btn-primary" data-step-to="1">Continue</button>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div class="step-content" data-step-id="1">\n' +
    '              <div class="profile-content-container">\n' +
    '                  <div class="feedback-prompt profile-content">\n' +
    '                    <span>We are sorry that you are leaving us. We\'d love to hear how we could improve MyLeadPod with your valuable feedback below.</span>\n' +
    '                    <span>Thank you.</span>\n' +
    '                  </div>\n' +
    '                  <form name="feedbackForm" style="margin-top: 15px;">\n' +
    '                    <div class="form-group">\n' +
    '                      <label class="sr-only" for="details">Feedback</label>\n' +
    '                      <div class="left-inner-addon">\n' +
    '                        <i class="fa fa-comment-o inner-addon"></i>\n' +
    '                        <textarea class="form-control input-lg" name="details" id="details" placeholder="Feedback"\n' +
    '                                  ng-required="true" ng-model="ctrl.data.feedback"></textarea>\n' +
    '                      </div>\n' +
    '                    </div>\n' +
    '                  </form>\n' +
    '                </div>\n' +
    '                <div class="step-buttons" data-active-step-id="1" style="width:100%;">\n' +
    '                  <button class="btn btn-primary" data-step-to="0">\n' +
    '                    Back\n' +
    '                  </button>\n' +
    '                  <button class="btn btn-primary no-animate" data-step-to="2" ng-click="ctrl.cancelHidden = true;">\n' +
    '                    Next\n' +
    '                  </button>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '\n' +
    '\n' +
    '            <div class="step-content" data-step-id="2">\n' +
    '\n' +
    '                <div class="profile-content-container" style="text-align:center; width: auto;">\n' +
    '                  <div class="profile-content">\n' +
    '                    <span ng-if="ctrl.data.wholeAccount === true">Confirm the deactivation of your MyLeadPod account.</span>\n' +
    '                    <span ng-if="ctrl.data.wholeAccount === false">Confirm the deactivation of your MyLeadPod business account.</span>\n' +
    '                  </div>\n' +
    '                  <div class="profile-content-buttons">\n' +
    '                    <button class="btn btn-danger" ng-disabled="ctrl.isDeactivating" ng-click="ctrl.deactivate();">{{ ctrl.isDeactivating ? \'Deactivating...\' : \'Deactivate\'}}</button>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '                <div class="step-buttons" data-active-step-id="2" style="width:100%;">\n' +
    '                  <button class="btn btn-primary" data-step-to="1" ng-click="ctrl.cancelHidden = false" ng-disabled="ctrl.isDeactivating">\n' +
    '                    Back\n' +
    '                  </button>\n' +
    '                  <button\n' +
    '                    class="btn btn-default" ng-disabled="ctrl.isDeactivating"\n' +
    '                    ng-click="ctrl.close()">\n' +
    '                    Cancel\n' +
    '                  </button>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <!-- ENDOF: Content -->\n' +
    '  </div>\n' +
    '  <div class="row modal-content-buttons slide-if" ng-if="!ctrl.cancelHidden">\n' +
    '    <div class="col-xs-12 text-right">\n' +
    '      <button\n' +
    '              class="btn btn-default" ng-disabled="ctrl.isDeactivating"\n' +
    '              ng-click="ctrl.close()">\n' +
    '        Cancel\n' +
    '      </button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('account/billing/credit-card.tpl.html',
    '<div class="modal-content-container">\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-12">\n' +
    '      <div class="row">\n' +
    '        <div class="col-xs-12">\n' +
    '          <span class="sub-title">\n' +
    '            {{creditCard.isAdd() ? \'Add Credit Card\' : \'Change Credit Card\'}}\n' +
    '          </span>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="row">\n' +
    '        <div class="col-xs-7 accepted-cards-container">\n' +
    '          <i class="fa fa-cc-visa fa-2x"></i> <i class="fa fa-cc-mastercard fa-2x"></i> <i class="fa fa-cc-amex fa-2x"></i>\n' +
    '        </div>\n' +
    '        <div class="col-xs-5 accepted-cards-container">\n' +
    '          <!-- Begin eWAY Linking Code -->\n' +
    '          <div id="eWAYBlock">\n' +
    '            <div style="text-align:right;">\n' +
    '              <a href="https://www.eway.com.au/secure-site-seal?i=13&s=7&pid=880d42e7-6908-4374-8cfe-19357ae619da&theme=0" title="eWAY Payment Gateway" target="_blank" rel="nofollow">\n' +
    '                <img alt="eWAY Payment Gateway" src="https://www.eway.com.au/developer/payment-code/verified-seal.ashx?img=13&size=7&pid=880d42e7-6908-4374-8cfe-19357ae619da&theme=0" />\n' +
    '              </a>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <!-- End eWAY Linking Code -->\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <form name="creditCardForm">\n' +
    '        <div class="row">\n' +
    '          <div class="col-xs-12">\n' +
    '            <div class="form-group" ng-class="{\'has-error\': (creditCardForm.$submitted || creditCardForm.holderName.$touched) && creditCardForm.holderName.$error.required}">\n' +
    '              <label class="sr-only" for="holderName">Name on card</label>\n' +
    '              <div ng-show="creditCardForm.$submitted || creditCardForm.holderName.$touched">\n' +
    '                <div class="input-error" ng-show="creditCardForm.holderName.$error.required">Missing name on card.</div>\n' +
    '              </div>\n' +
    '              <div class="left-inner-addon">\n' +
    '                <i class="fa fa-user inner-addon"></i>\n' +
    '                <input class="form-control input-lg" type="text" name="holderName" id="holderName"\n' +
    '                       placeholder="Name on card" ng-required="true" ng-model="creditCard.data.holderName" />\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row">\n' +
    '          <div class="col-xs-12">\n' +
    '            <div class="form-group" ng-class="{\'has-error\': (creditCardForm.$submitted || creditCardForm.number.$touched) && (creditCardForm.number.$error.required || creditCardForm.number.$error.ccNumber)}">\n' +
    '              <label class="sr-only" for="number">Card number</label>\n' +
    '              <div ng-show="creditCardForm.$submitted || creditCardForm.number.$touched">\n' +
    '                <div class="input-error" ng-show="creditCardForm.number.$error.required">Missing card number.</div>\n' +
    '                <div class="input-error" ng-show="!creditCardForm.number.$error.required && creditCardForm.number.$error.ccNumber">Invalid card number.</div>\n' +
    '              </div>\n' +
    '              <div class="left-inner-addon">\n' +
    '                <i class="fa fa-credit-card inner-addon"></i>\n' +
    '                <input class="form-control input-lg" type="text" name="number" id="number"\n' +
    '                       placeholder="Card number" ng-required="true" ng-model="creditCard.data.number"\n' +
    '                       cc-number cc-type="creditCard.cards" />\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row" cc-exp>\n' +
    '          <div class="col-xs-12">\n' +
    '            <div ng-show="creditCardForm.$submitted || (creditCardForm.expiryMonth.$touched && creditCardForm.expiryYear.$touched)">\n' +
    '              <div class="input-error" ng-show="creditCardForm.$error.ccExp">Invalid expiry date.</div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="col-xs-12 col-sm-6">\n' +
    '            <div class="form-group" ng-class="{\'has-error\': (creditCardForm.$submitted || creditCardForm.expiryMonth.$touched) && creditCardForm.expiryMonth.$error.required}">\n' +
    '              <label class="sr-only" for="expiryMonth">Expiry month</label>\n' +
    '              <div ng-show="creditCardForm.$submitted || creditCardForm.expiryMonth.$touched">\n' +
    '                <div class="input-error" ng-show="creditCardForm.expiryMonth.$error.required">Missing Expiry month.</div>\n' +
    '              </div>\n' +
    '              <div class="left-inner-addon">\n' +
    '                <i class="fa fa-calendar inner-addon"></i>\n' +
    '                <select class="form-control input-lg" name="expiryMonth" id="expiryMonth" placeholder="Expiry month" ng-required="true"\n' +
    '                        ng-model="creditCard.data.expiryMonth" cc-exp-month>\n' +
    '                  <option value="" disabled selected>Expiry month</option>\n' +
    '                  <option ng-repeat="month in creditCard.months" value="{{month}}">{{month}}</option>\n' +
    '                </select>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '\n' +
    '          </div>\n' +
    '          <div class="col-xs-12 col-sm-6">\n' +
    '            <div class="form-group" ng-class="{\'has-error\': (creditCardForm.$submitted || creditCardForm.expiryYear.$touched) && creditCardForm.expiryYear.$error.required}">\n' +
    '              <label class="sr-only" for="expiryYear">Expiry year</label>\n' +
    '              <div ng-show="creditCardForm.$submitted || creditCardForm.expiryYear.$touched">\n' +
    '                <div class="input-error" ng-show="creditCardForm.expiryYear.$error.required">Missing Expiry year.</div>\n' +
    '              </div>\n' +
    '              <div class="left-inner-addon">\n' +
    '                <i class="fa fa-calendar inner-addon"></i>\n' +
    '                <select class="form-control input-lg" name="expiryYear" id="expiryYear" placeholder="Expiry year" ng-required="true"\n' +
    '                        ng-model="creditCard.data.expiryYear" cc-exp-year full-year>\n' +
    '                  <option value="" disabled selected>Expiry year</option>\n' +
    '                  <option ng-repeat="year in creditCard.years" value="{{year}}">{{year}}</option>\n' +
    '                </select>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="row modal-content-buttons">\n' +
    '    <div class="col-xs-12 text-right">\n' +
    '      <button class="btn btn-default" ng-disabled="creditCard.isLoading" ng-click="creditCard.cancel()">\n' +
    '        Cancel\n' +
    '      </button>\n' +
    '      <button class="btn btn-primary" ng-disabled="creditCard.isLoading || creditCardForm.$invalid" ng-click="creditCard.ok()">\n' +
    '        {{creditCard.isLoading ? \'Loading ...\' : (creditCard.isAdd() ? \'Add Card\' : \'Change Card\') }}\n' +
    '      </button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('account/billing/billing.tpl.html',
    '<div class="col-xs-12" ng-controller="BillingCtrl as billing" id="billing-ctrl-container">\n' +
    '  <div class="page-content">\n' +
    '\n' +
    '    <div class="row" ng-show="!billing.invoices.isInitialised()">\n' +
    '      <div class="col-xs-12 text-center">\n' +
    '        <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row">\n' +
    '      <div class="col-xs-12">\n' +
    '        <!-- Pending overlay -->\n' +
    '        <div class="pending-overlay-white opacity-slow-if" ng-if="billing.invoices.isInitialised() && billing.initialisingCard">\n' +
    '          <span class="fa fa-circle-o-notch fa-spin-2x"></span>\n' +
    '        </div>\n' +
    '        <div class="slide-if" ng-if="billing.invoices.isInitialised() && !billing.initialisingCard">\n' +
    '          <span class="sub-title">Credit card details</span>\n' +
    '          <div class="page-empty-section page-table-section text-center slide-if" ng-if="!billing.initialisingCard && !billing.creditCard">\n' +
    '            <i class="fa fa-credit-card fa-2x text-light-colour"></i>\n' +
    '            <div class="page-empty-section-text">\n' +
    '              Looks like you don\'t have a credit card on file\n' +
    '            </div>\n' +
    '            <div class="page-empty-section-button">\n' +
    '              <button class="btn btn-primary" ng-click="billing.manageCreditCard(\'add\')">Add a Credit Card</button>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="credit-card-detail-container slide-if" ng-if="!billing.initialisingCard && billing.creditCard">\n' +
    '            <div class="row">\n' +
    '              <div class="col-xs-12">\n' +
    '                <table>\n' +
    '                  <tr>\n' +
    '                    <td class="credit-card-logo">\n' +
    '                      <i class="fa fa-credit-card"></i>\n' +
    '                    </td>\n' +
    '                    <td class="credit-card-detail">\n' +
    '                      <p class="credit-card-holder">{{billing.creditCard.holderName}}</p>\n' +
    '                      <p>{{billing.creditCard.number}}</p>\n' +
    '                      <p>Expires {{billing.creditCard.expiryMonth}} / {{billing.creditCard.expiryYear}}</p>\n' +
    '                    </td>\n' +
    '                  </tr>\n' +
    '                </table>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div class="row">\n' +
    '              <div class="col-xs-12">\n' +
    '                <button class="btn btn-default credit-card-btn" ng-disabled="billing.cardLoading"\n' +
    '                        ng-click="billing.removeCreditCard()">\n' +
    '                  {{billing.cardLoading ? \'Loading ...\' : \'Remove Card\'}}\n' +
    '                </button>\n' +
    '                <button class="btn btn-primary credit-card-btn" ng-disabled="billing.cardLoading"\n' +
    '                        ng-click="billing.manageCreditCard(\'change\')">\n' +
    '                  {{billing.cardLoading ? \'Loading ...\' : \'Change Card\'}}\n' +
    '                </button>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row page-section form-section-title slide-if" ng-if="billing.invoices.isInitialised()">\n' +
    '      <div class="col-xs-12">\n' +
    '        <span class="sub-title">Invoice history</span>\n' +
    '        <div class="page-empty-section page-table-section text-center" ng-hide="billing.invoices.recordsExist()">\n' +
    '          <i class="fa fa-file-text-o fa-2x text-light-colour"></i>\n' +
    '          <div class="page-empty-section-text">\n' +
    '            There are no invoices to show\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="page-table-section table-container" ng-show="billing.invoices.recordsExist()">\n' +
    '          <table st-table="billing.invoices.getRecords()" st-pagination-scroll st-pipe="billing.invoices.load" class="table">\n' +
    '            <thead class="hidden-xs">\n' +
    '            <tr>\n' +
    '              <th>Invoice date</th>\n' +
    '              <th>Amount</th>\n' +
    '              <th class="text-right">Invoice</th>\n' +
    '            </tr>\n' +
    '            </thead>\n' +
    '            <tbody ng-hide="billing.invoices.isLoading() && !billing.invoices.isLoadingMoreRecords()">\n' +
    '            <tr ng-repeat="invoice in billing.invoices.getRecords()">\n' +
    '              <td class="hidden-xs col-sm-4">{{invoice.dateInvoice | date:\'d MMM yyyy\'}}</td>\n' +
    '              <td class="col-xs-6 col-sm-4">\n' +
    '                <span class="text-muted visible-xs">{{invoice.dateInvoice | date:\'d MMM yyyy\'}}<br/></span>\n' +
    '                {{invoice.totalInclTax | currency:"$":2}}\n' +
    '              </td>\n' +
    '              <td class="col-xs-6 col-sm-4 text-right invoice-no">\n' +
    '                <file-download url="invoice.downloadUrl" label="invoice.invoiceNumber"></file-download>\n' +
    '              </td>\n' +
    '            </tr>\n' +
    '            </tbody>\n' +
    '            <tbody ng-if="billing.invoices.isLoading()">\n' +
    '            <tr>\n' +
    '              <td colspan="3" class="col-xs-12 text-center">\n' +
    '                <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>\n' +
    '              </td>\n' +
    '            </tr>\n' +
    '            </tbody>\n' +
    '          </table>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('account/preferences/preferences-modal.tpl.html',
    '<div class="modal-content-container preferences" id="preferences-container">\n' +
    '  <div class="row">\n' +
    '    <!-- Title -->\n' +
    '    <div class="col-xs-12 modal-content-title">\n' +
    '      <span class="sub-title">Preferences</span>\n' +
    '    </div>\n' +
    '    <!-- ENDOF: Title-->\n' +
    '\n' +
    '    <!-- alerts -->\n' +
    '    <uib-alert ng-repeat="alert in preferences.alerts.getList()" type="{{alert.type}}" close="preferences.alerts.remove($index)">{{alert.message}}</uib-alert>\n' +
    '\n' +
    '    <!-- Content -->\n' +
    '    <div class="col-xs-12 preferences-section">\n' +
    '      <div class="preferences-title">\n' +
    '        Mailing Lists\n' +
    '      </div>\n' +
    '\n' +
    '      <div ng-repeat="(listName, list) in preferences.data.mailingLists" class="preferences-content">\n' +
    '\n' +
    '          <div class="preferences-content-title">\n' +
    '            <div>\n' +
    '              {{list.label}}\n' +
    '            </div>\n' +
    '            <div class="preferences-content-subtitle">\n' +
    '              <div ng-if="list.description">{{list.description}}</div>\n' +
    '              You are currently\n' +
    '              <span ng-if="list.isSubscribed">subscribed to the mailing list</span>\n' +
    '              <span ng-if="!list.isSubscribed">not subscribed to the mailing list</span>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="preferences-tools">\n' +
    '            <div class="compound-buttons">\n' +
    '              <div role="button" tabindex="1"\n' +
    '                   ng-click="preferences.changeSettings(\'mailingLists.\'+listName+\'.isSubscribed\', true)"\n' +
    '                   ng-class="{\'active\': list.isSubscribed}">ON\n' +
    '              </div>\n' +
    '              <div role="button" tabindex="1"\n' +
    '                   ng-click="preferences.changeSettings(\'mailingLists.\' + listName+\'.isSubscribed\', false)"\n' +
    '                   ng-class="{\'active\': !list.isSubscribed}">OFF\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '      </div>\n' +
    '    </div>\n' +
    '      <!-- ENDOF: Content -->\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="row modal-content-buttons">\n' +
    '    <div class="text-right">\n' +
    '      <button type="button" class="btn btn-default" ng-disabled="preferences.isSaving" ng-click="preferences.cancel()">\n' +
    '        Cancel\n' +
    '      </button>\n' +
    '      <button type="button" class="btn btn-primary"\n' +
    '              ng-disabled="!preferences.hasChanges || preferences.isSaving" ng-click="preferences.saveChanges()">\n' +
    '        {{preferences.isSaving ? \'Saving ...\' : \'Save\'}}\n' +
    '      </button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('account/preferences/mailing-list-unsubscribe.tpl.html',
    '<div class="unsubscribe-content">\n' +
    '  <div class="unsubscribe-logo">\n' +
    '    <img src="/images/logo.svg" />\n' +
    '  </div>\n' +
    '  <span ng-if="preferences.isSaving" class="no-animate fa fa-medium-size fa-circle-o-notch fa-spin-2x"></span>\n' +
    '  <div ng-if="!preferences.isSaving && !preferences.isError" class="no-animate unsubscribe-success">\n' +
    '    You have been unsubscribed from the weekly mailing list. You can subscribe back anytime using the preferences.\n' +
    '    <div style="margin-top: 20px;">\n' +
    '      <a ui-sref="dashboard" class="btn btn-primary">MyLeadPod</a>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div ng-if="preferences.isError" class="no-animate unsubscribe-error">\n' +
    '    The update in subscription was not successful. Please try again or contact us.\n' +
    '    <div>\n' +
    '      <div style="display: inline-block; margin: 5px 10px;">\n' +
    '        <button class="btn btn-primary" ng-click="preferences.openFeedback()">Contact Us</button>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('account/password/reset.tpl.html',
    '<div class="row" ng-if="!resetCtrl.showMessage">\n' +
    '  <div class="col-md-12 text-center">\n' +
    '    <h1 class="page-header sign-up-page-header">Set New Password</h1>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="row sign-up-page-container" ng-if="!resetCtrl.showMessage">\n' +
    '  <div class="sign-in-page-content text-center activate-form-content">\n' +
    '    <uib-alert ng-repeat="alert in resetCtrl.alerts.getList()" type="{{alert.type}}" close="resetCtrl.alerts.remove($index)">{{alert.message}}</uib-alert>\n' +
    '    <form name="passwordResetForm">\n' +
    '      <div class="form-group" ng-class="{\'has-error\': (passwordResetForm.$submitted || passwordResetForm.password.$touched) && passwordResetForm.password.$error.required}">\n' +
    '        <label class="sr-only" for="password">New Password</label>\n' +
    '        <div ng-show="passwordResetForm.$submitted || passwordResetForm.password.$touched">\n' +
    '          <div class="input-error" ng-show="passwordResetForm.password.$error.required">Missing password.</div>\n' +
    '        </div>\n' +
    '        <div class="left-inner-addon">\n' +
    '          <i class="fa fa-lock inner-addon"></i>\n' +
    '          <input class="form-control input-lg" type="password" name="password" id="password"\n' +
    '                 placeholder="New password" ng-required="true" ng-model="resetCtrl.data.password" />\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="form-group" ng-class="{\'has-error\': (passwordResetForm.$submitted || passwordResetForm.confirmPassword.$touched) && (passwordResetForm.confirmPassword.$error.required || passwordResetForm.confirmPassword.$error.match)}">\n' +
    '        <label class="sr-only" for="confirmPassword">Confirm new password</label>\n' +
    '        <div ng-show="passwordResetForm.$submitted || passwordResetForm.confirmPassword.$touched">\n' +
    '          <div class="input-error" ng-show="passwordResetForm.confirmPassword.$error.required">Missing password.</div>\n' +
    '          <div class="input-error" ng-show="passwordResetForm.confirmPassword.$error.match">Passwords do not match.</div>\n' +
    '        </div>\n' +
    '        <div class="left-inner-addon">\n' +
    '          <i class="fa fa-lock inner-addon"></i>\n' +
    '          <input class="form-control input-lg" type="password" name="confirmPassword" id="confirmPassword"\n' +
    '                 placeholder="Confirm new password" ng-required="true" ng-model="resetCtrl.data.confirmPassword"\n' +
    '                 match="resetCtrl.data.password" />\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="form-group sign-up-page-btn-create">\n' +
    '        <button type="button" class="btn btn-primary btn-lg btn-block"\n' +
    '                ng-disabled="passwordResetForm.$invalid || resetCtrl.isLoading" ng-click="resetCtrl.reset()">\n' +
    '          {{resetCtrl.isLoading ? \'Loading ...\' : \'Set Password\'}}\n' +
    '        </button>\n' +
    '      </div>\n' +
    '    </form>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="row success-message-container" ng-if="resetCtrl.showMessage">\n' +
    '  <div class="col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 text-center">\n' +
    '    <div class="panel panel-default">\n' +
    '      <div class="panel-body">\n' +
    '        <h2>Password Reset</h2>\n' +
    '        <div class="panel-body-subtitle">\n' +
    '          Your password has been successfully updated!\n' +
    '        </div>\n' +
    '        <div class="sign-up-page-btn-create">\n' +
    '          <a ui-sref="accountSignInUp.accountSignIn" type="button" class="btn btn-primary btn-lg btn-block">Login</a>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('account/password/recover.tpl.html',
    '<div class="row" ng-if="!recoverCtrl.showMessage">\n' +
    '  <div class="col-md-12 text-center">\n' +
    '    <h1 class="page-header sign-up-page-header">Enter your email and we\'ll get you back on track</h1>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="row sign-up-page-container" ng-if="!recoverCtrl.showMessage">\n' +
    '  <div class="sign-up-page-content text-center">\n' +
    '    <uib-alert ng-repeat="alert in recoverCtrl.alerts.getList()" type="{{alert.type}}" close="recoverCtrl.alerts.remove($index)">{{alert.message}}</uib-alert>\n' +
    '    <form name="passwordRecoverForm">\n' +
    '      <div class="form-group" ng-class="{\'has-error\': (passwordRecoverForm.$submitted || passwordRecoverForm.email.$touched) && (passwordRecoverForm.email.$error.required || passwordRecoverForm.email.$error.email)}">\n' +
    '        <label class="sr-only" for="email">Email</label>\n' +
    '        <div ng-show="passwordRecoverForm.$submitted || passwordRecoverForm.email.$touched">\n' +
    '          <div class="input-error" ng-show="passwordRecoverForm.email.$error.required">Tell us your email.</div>\n' +
    '          <div class="input-error" ng-show="passwordRecoverForm.email.$error.email">This is not a valid email.</div>\n' +
    '        </div>\n' +
    '        <div class="left-inner-addon">\n' +
    '          <i class="fa fa-envelope-o inner-addon"></i>\n' +
    '          <input class="form-control input-lg" type="email" name="email" id="email"\n' +
    '                 placeholder="Email" ng-required="true" ng-model="recoverCtrl.data.email" />\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="form-group">\n' +
    '        <div vc-recaptcha key="recoverCtrl.reCaptchaSiteKey"></div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="form-group sign-up-page-btn-create">\n' +
    '        <button type="button" class="btn btn-primary btn-lg btn-block"\n' +
    '                ng-disabled="passwordRecoverForm.$invalid || recoverCtrl.isLoading" ng-click="recoverCtrl.recover()">\n' +
    '          {{recoverCtrl.isLoading ? \'Loading ...\' : \'Reset Password\'}}\n' +
    '        </button>\n' +
    '      </div>\n' +
    '    </form>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="row success-message-container" ng-if="recoverCtrl.showMessage">\n' +
    '  <div class="col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 text-center">\n' +
    '    <div class="panel panel-default">\n' +
    '      <div class="panel-body">\n' +
    '        <h2>Password Reset</h2>\n' +
    '        <p>We have sent {{recoverCtrl.data.email}} an email with reset instructions</p>\n' +
    '        <p>\n' +
    '          If the email does not arrive soon, check your spam folder. The email was sent from confirm@myleadpod.com\n' +
    '        </p>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="row">\n' +
    '  <div class="col-md-12 text-center sign-in-page-footer">\n' +
    '    <a ui-sref="accountSignInUp.accountSignIn">Back to login</a>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('account/feedback/feedback-modal.tpl.html',
    '<div class="modal-content-container">\n' +
    '  <div class="row">\n' +
    '      <!-- Title -->\n' +
    '      <div class="col-xs-12 modal-content-title">\n' +
    '        <span class="sub-title" ng-if="!feedback.isHelpRequired">Feedback</span>\n' +
    '        <span class="sub-title" ng-if="feedback.isHelpRequired">Need Help?</span>\n' +
    '      </div>\n' +
    '      <!-- ENDOF: Title-->\n' +
    '\n' +
    '      <!-- alerts -->\n' +
    '      <uib-alert ng-repeat="alert in preferences.alerts.getList()" type="{{alert.type}}" close="feedback.alerts.remove($index)">{{alert.message}}</uib-alert>\n' +
    '\n' +
    '      <!-- Content -->\n' +
    '      <div class="col-xs-12 feedback">\n' +
    '        <div class="feedback-prompt">\n' +
    '          <span ng-if="!feedback.isHelpRequired">We would like to hear from you! Please let us know how we can improve MyLeadPod</span>\n' +
    '          <span ng-if="feedback.isHelpRequired">Please provide your name, email and the issue description</span>\n' +
    '        </div>\n' +
    '        <form name="feedbackForm">\n' +
    '          <div class="row" ng-if="feedback.isHelpRequired">\n' +
    '            <div class="col-xs-12 col-sm-6">\n' +
    '              <div class="form-group" ng-class="{\'has-error\': (feedbackForm.$submitted || feedbackForm.name.$touched) && feedbackForm.name.$invalid}">\n' +
    '                <label class="sr-only" for="name">Name</label>\n' +
    '                <div ng-show="feedbackForm.$submitted || feedbackForm.name.$touched">\n' +
    '                  <div class="input-error" ng-show="!feedbackForm.name.value">Your name is required</div>\n' +
    '                </div>\n' +
    '                <div class="left-inner-addon">\n' +
    '                  <i class="fa fa-user inner-addon"></i>\n' +
    '                  <input type="text" class="form-control input-lg" name="name" id="name" placeholder="Your Name" ng-model="feedback.data.name" ng-required="true" />\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div class="col-xs-12 col-sm-6">\n' +
    '              <div class="form-group" ng-class="{\'has-error\': (feedbackForm.$submitted || feedbackForm.email.$touched) && feedbackForm.email.$invalid}">\n' +
    '                <label class="sr-only" for="email">Email</label>\n' +
    '                <div ng-show="feedbackForm.$submitted || feedbackForm.email.$touched">\n' +
    '                  <div class="input-error" ng-show="!feedbackForm.email.$error.email">Email address is required</div>\n' +
    '                  <div class="input-error" ng-show="feedbackForm.email.$error.email">Invalid email address</div>\n' +
    '                </div>\n' +
    '                <div class="left-inner-addon">\n' +
    '                  <i class="fa fa-envelope-o inner-addon"></i>\n' +
    '                  <input class="form-control input-lg" type="email" name="email" id="email" ng-required="true" placeholder="Email"\n' +
    '                         ng-model="feedback.data.email" />\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="form-group" ng-class="{\'has-error\': (feedbackForm.$submitted || feedbackForm.details.$touched) && feedbackForm.details.$error.required}">\n' +
    '            <label class="sr-only" for="details">Feedback</label>\n' +
    '            <div ng-show="feedbackForm.$submitted || feedbackForm.details.$touched">\n' +
    '              <div class="input-error" ng-show="feedbackForm.details.$error.required" ng-if="!feedback.isHelpRequired">Missing feedback</div>\n' +
    '              <div class="input-error" ng-show="feedbackForm.details.$error.required" ng-if="feedback.isHelpRequired">Missing issue description</div>\n' +
    '            </div>\n' +
    '            <div class="left-inner-addon">\n' +
    '              <i class="fa fa-comment-o inner-addon"></i>\n' +
    '              <textarea class="form-control input-lg" name="details" id="details" placeholder="Feedback"\n' +
    '                        ng-required="true" ng-model="feedback.data.feedback" msd-elastic></textarea>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '        </form>\n' +
    '      </div>\n' +
    '      <!-- ENDOF: Content -->\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="modal-content-buttons">\n' +
    '    <div class="text-right">\n' +
    '      <button type="button" class="btn btn-default" ng-click="feedback.cancel()">\n' +
    '        Cancel\n' +
    '      </button>\n' +
    '      <button type="button" class="btn btn-primary"\n' +
    '              ng-disabled="!feedbackForm.$valid || feedback.isSending" ng-click="feedback.send()">\n' +
    '        {{feedback.isSending ? \'Sending ...\' : \'Send\'}}\n' +
    '      </button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('account/earnings/withdraw.tpl.html',
    '<div class="row">\n' +
    '  <div class="col-xs-12">\n' +
    '    <h1 class="page-header">Withdraw Earnings</h1>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="row">\n' +
    '  <div class="col-xs-12">\n' +
    '    <div class="page-content page-content-no-tabs">\n' +
    '\n' +
    '      <div class="row">\n' +
    '        <div class="col-xs-12">\n' +
    '          <div class="form-notification">\n' +
    '            <div class="form-notification-desc">\n' +
    '              At MyLeadPod we encourage people to support local communities, sports clubs and charities and we offer the option of donating to a charity.\n' +
    '            </div>\n' +
    '            <div class="form-notification-prompt hidden-xs hidden-sm">\n' +
    '              Would you like to donate now?\n' +
    '            </div>\n' +
    '            <div class="form-notification-content">\n' +
    '              <button class="btn btn-primary" ng-click="withdraw.donate();">Donate</button>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <uib-alert ng-repeat="alert in withdraw.alerts.getList()" type="{{alert.type}}" close="withdraw.alerts.remove($index)">{{alert.message}}</uib-alert>\n' +
    '      <form name="withdrawEarningsForm" class="withdraw-earnings-form row">\n' +
    '\n' +
    '        <div class="col-xs-12 col-sm-6">\n' +
    '          <!-- Withdraw Amount -->\n' +
    '          <div class="row">\n' +
    '            <div class="col-xs-12 form-section-title">\n' +
    '              <span class="sub-title">Withdraw value</span>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="row">\n' +
    '            <div class="col-xs-12 form-section-description">\n' +
    '              The minimum withdrawal limit is {{withdraw.minLimit | currency:"$":2}}\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="row">\n' +
    '            <div class="col-xs-12">\n' +
    '              <div class="form-group validating-control" ng-class="{\'has-error\': (withdrawEarningsForm.$submitted || withdrawEarningsForm.amount.$touched) &&\n' +
    '            (withdrawEarningsForm.amount.$error.required || withdrawEarningsForm.amount.$error.number || withdrawEarningsForm.amount.$error.min || withdrawEarningsForm.amount.$error.max)}">\n' +
    '                <label class="sr-only" for="amount">Enter amount</label>\n' +
    '                <div ng-show="withdrawEarningsForm.$submitted || withdrawEarningsForm.amount.$touched">\n' +
    '                  <div class="input-error" ng-show="withdrawEarningsForm.amount.$error.number">Invalid amount\n' +
    '                  </div>\n' +
    '                  <div class="input-error" ng-show="withdrawEarningsForm.amount.$error.required">Missing amount\n' +
    '                  </div>\n' +
    '                  <div class="input-error" ng-show="withdrawEarningsForm.amount.$error.max">You can not withdraw more than your balance\n' +
    '                  </div>\n' +
    '                  <div class="input-error" ng-show="withdrawEarningsForm.amount.$error.min">You have to withdraw more than minimum withdraw limit\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '                <div class="left-inner-addon input-group">\n' +
    '                  <i class="fa fa-dollar inner-addon"></i>\n' +
    '                  <input class="form-control input-lg" type="number" name="amount" id="amount"\n' +
    '                         placeholder="Enter amount" ng-change="withdraw.onAmountChange()"\n' +
    '                         ng-blur="withdraw.calculate()"\n' +
    '                         ng-required="true" ng-model="withdraw.data.amount" min="{{withdraw.minLimit}}" max="{{withdraw.balance}}"/>\n' +
    '                  <span class="input-group-btn">\n' +
    '                  <button class="btn btn-primary btn-md" type="button" ng-click="withdraw.calculate()"\n' +
    '                          ng-disabled="!withdraw.data.amount">Enter</button>\n' +
    '                </span>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <!-- Balance sheet -->\n' +
    '          <div class="controls-container form-group pam" style="position: relative; font-size: 14px;">\n' +
    '\n' +
    '            <!-- Pending overlay -->\n' +
    '            <div class="pending-overlay-white pending-overlay-column" ng-if="withdraw.calculating">\n' +
    '              <span class="fa fa-circle-o-notch fa-spin-2x"></span>\n' +
    '              <div class="pending-overlay-description">Recalculating</div>\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="row">\n' +
    '              <div class="col-xs-6 ">\n' +
    '                Current balance\n' +
    '              </div>\n' +
    '              <div class="col-xs-6 text-right">\n' +
    '                {{withdraw.balance | currency:"$":2}}\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <!--<div class="row" ng-if="withdraw.showTotal && !withdraw.calculating && !withdraw.calculationRequired && withdraw.processingFee">-->\n' +
    '            <div class="row">\n' +
    '              <div class="col-xs-6 ">\n' +
    '                Processing fee\n' +
    '              </div>\n' +
    '              <div class="col-xs-6  text-right">\n' +
    '                {{withdraw.processingFee ? (withdraw.processingFee | currency:"$":2) : "..."}}\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div class="row">\n' +
    '              <div class="col-xs-6 3">\n' +
    '                Withdrawal amount\n' +
    '              </div>\n' +
    '              <div class="col-xs-6 text-right">\n' +
    '                {{withdraw.receivedAmount ? (withdraw.receivedAmount| currency:"$":2) : "..."}}\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div class="row" ng-if="withdraw.showTotal && !withdraw.calculating && !withdraw.calculationRequired">\n' +
    '              <div class="col-xs-6 ">\n' +
    '                <strong>Remaining balance</strong>\n' +
    '              </div>\n' +
    '              <div class="col-xs-6  text-right">\n' +
    '                <strong>{{withdraw.remainingBalance ? (withdraw.remainingBalance | currency:"$":2) : "..."}}</strong>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '\n' +
    '          </div>\n' +
    '\n' +
    '\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="col-xs-12 col-sm-6">\n' +
    '\n' +
    '          <!-- Payment Details -->\n' +
    '          <div class="row">\n' +
    '            <div class="col-xs-12 form-section-title">\n' +
    '              <span class="sub-title">Payment details</span>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="row">\n' +
    '            <div class="col-xs-12 form-section-description">\n' +
    '              Provide recipient\'s bank account details\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="col-xs-12">\n' +
    '              <div class="form-group validating-control"\n' +
    '                   ng-class="{\'has-error\': (withdrawEarningsForm.$submitted || withdrawEarningsForm.accountHolder.$touched) && withdrawEarningsForm.accountHolder.$error.required}">\n' +
    '                <label class="sr-only" for="accountHolder">Account holder</label>\n' +
    '                <div ng-show="withdrawEarningsForm.$submitted || withdrawEarningsForm.accountHolder.$touched">\n' +
    '                  <div class="input-error" ng-show="withdrawEarningsForm.accountHolder.$error.required">Account holder.\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '                <div class="left-inner-addon">\n' +
    '                  <i class="fa fa-user inner-addon"></i>\n' +
    '                  <input class="form-control input-lg" type="text" name="accountHolder" id="accountHolder"\n' +
    '                         placeholder="Account holder" ng-required="true" ng-model="withdraw.data.accountHolder"/>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="row">\n' +
    '            <div class="col-xs-12">\n' +
    '              <div class="form-group"\n' +
    '                   ng-class="{\'has-error\': (withdrawEarningsForm.$submitted || withdrawEarningsForm.accountBsb.$touched) && withdrawEarningsForm.accountBsb.$error.required}">\n' +
    '                <label class="sr-only" for="accountBsb">BSB</label>\n' +
    '                <div ng-show="withdrawEarningsForm.$submitted || withdrawEarningsForm.accountBsb.$touched">\n' +
    '                  <div class="input-error" ng-show="withdrawEarningsForm.accountBsb.$error.required">Account BSB.</div>\n' +
    '                </div>\n' +
    '                <div class="left-inner-addon">\n' +
    '                  <i class="fa fa-bank inner-addon"></i>\n' +
    '                  <input class="form-control input-lg" type="text" name="accountBsb" id="accountBsb"\n' +
    '                         placeholder="BSB" ng-required="true" ng-model="withdraw.data.accountBsb"/>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="row">\n' +
    '            <div class="col-xs-12">\n' +
    '              <div class="form-group"\n' +
    '                   ng-class="{\'has-error\': (withdrawEarningsForm.$submitted || withdrawEarningsForm.accountNumber.$touched) && withdrawEarningsForm.accountNumber.$error.required}">\n' +
    '                <label class="sr-only" for="accountNumber">Account</label>\n' +
    '                <div ng-show="withdrawEarningsForm.$submitted || withdrawEarningsForm.accountNumber.$touched">\n' +
    '                  <div class="input-error" ng-show="withdrawEarningsForm.accountNumber.$error.required">Account</div>\n' +
    '                </div>\n' +
    '                <div class="left-inner-addon">\n' +
    '                  <i class="fa fa-bank inner-addon"></i>\n' +
    '                  <input class="form-control input-lg" type="text" name="accountNumber" id="accountNumber"\n' +
    '                         placeholder="Account" ng-required="true" ng-model="withdraw.data.accountNumber"/>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="row form-section-body">\n' +
    '            <div class="col-xs-6">\n' +
    '              <button type="button" class="btn btn-default btn-lg btn-block"\n' +
    '                      ng-disabled="withdraw.isLoading" ng-click="withdraw.cancel()">\n' +
    '                Cancel\n' +
    '              </button>\n' +
    '            </div>\n' +
    '            <div class="col-xs-6">\n' +
    '              <button type="button" class="btn btn-primary btn-lg btn-block"\n' +
    '                      ng-disabled="withdrawEarningsForm.$invalid || withdraw.isLoading" ng-click="withdraw.withdraw()">\n' +
    '                {{withdraw.isLoading ? \'Loading ...\' : \'Withdraw\'}}\n' +
    '              </button>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '        </div>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('account/earnings/earnings.tpl.html',
    '<div class="col-xs-12" ng-controller="EarningsCtrl as earnings" id="earnings-ctrl-container">\n' +
    '  <div class="page-content">\n' +
    '    <div class="earnings-cells">\n' +
    '      <div class="col-xs-6 col-sm-6 col-md-3 earnings-cell">\n' +
    '        <div class="earnings-cell-content">\n' +
    '          <div class="earnings-cell-value earnings-cell-value-link" ng-click="earnings.viewLastMonthEarnings()">\n' +
    '            <span ng-show="earnings.isLoadingSummaries"><i class="fa fa-circle-o-notch fa-spin-2x"></i></span>\n' +
    '            <span ng-hide="earnings.isLoadingSummaries">{{earnings.summaries.lastMonthEarnings | currency:\'$\':0}}</span>\n' +
    '          </div>\n' +
    '          <div class="earnings-cell-info">\n' +
    '            last month\n' +
    '          </div>\n' +
    '          <div class="earnings-cell-action hidden-xs">\n' +
    '            <button class="btn btn-default" ng-click="earnings.viewLastMonthEarnings()">View All</button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="col-xs-6 col-sm-6 col-md-3 earnings-cell">\n' +
    '        <div class="earnings-cell-content">\n' +
    '          <div class="earnings-cell-value earnings-cell-value-link" ng-click="earnings.viewAllEarnings()">\n' +
    '            <span ng-show="earnings.isLoadingSummaries"><i class="fa fa-circle-o-notch fa-spin-2x"></i></span>\n' +
    '            <span ng-hide="earnings.isLoadingSummaries">{{earnings.summaries.totalEarnings | currency:\'$\':0}}</span>\n' +
    '          </div>\n' +
    '          <div class="earnings-cell-info">\n' +
    '            total earnings\n' +
    '          </div>\n' +
    '          <div class="earnings-cell-action hidden-xs">\n' +
    '            <button class="btn btn-default" ng-click="earnings.viewAllEarnings()">View All</button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="col-xs-6 col-sm-6 col-md-3 earnings-cell">\n' +
    '        <div class="earnings-cell-content">\n' +
    '          <div class="earnings-cell-value earnings-cell-value-link" ng-click="earnings.tabManager.openTab(\'withdrawals\')">\n' +
    '            <span ng-show="earnings.isLoadingSummaries"><i class="fa fa-circle-o-notch fa-spin-2x"></i></span>\n' +
    '            <span ng-hide="earnings.isLoadingSummaries">{{earnings.summaries.withdrawals | currency:\'$\':0}}</span>\n' +
    '          </div>\n' +
    '          <div class="earnings-cell-info">\n' +
    '            withdrawn\n' +
    '          </div>\n' +
    '          <div class="earnings-cell-action hidden-xs">\n' +
    '            <button class="btn btn-default" ng-click="earnings.tabManager.openTab(\'withdrawals\')">View Details</button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="col-xs-6 col-sm-6 col-md-3 earnings-cell">\n' +
    '        <div class="earnings-cell-content">\n' +
    '          <div class="earnings-cell-value">\n' +
    '            <span ng-show="earnings.isLoadingSummaries"><i class="fa fa-circle-o-notch fa-spin-2x"></i></span>\n' +
    '            <span ng-hide="earnings.isLoadingSummaries">{{earnings.summaries.actualEarnings | currency:\'$\':0}}</span>\n' +
    '          </div>\n' +
    '          <div class="earnings-cell-info">\n' +
    '            current balance\n' +
    '          </div>\n' +
    '          <div class="earnings-cell-action hidden-xs">\n' +
    '            <button class="btn btn-default" ng-click="earnings.withdraw()">Withdraw</button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row earnings-withdraw-button-row">\n' +
    '      <div class="col-xs-12 visible-xs">\n' +
    '        <button class="btn btn-default" ng-click="earnings.withdraw()">Withdraw</button>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row earnings-tabs">\n' +
    '      <div class="col-xs-12">\n' +
    '        <tabs-manager tab-id="earnings" target="secondary" tabs-config="earnings.tabsConfig" default-tab="earnings" tab-manager="earnings.tabManager"></tabs-manager>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row" ng-show="earnings.tabManager.isOpen(\'earnings\')">\n' +
    '      <div class="col-xs-12">\n' +
    '\n' +
    '        <div class="row" ng-hide="earnings.list.recordsExist() || !earnings.list.isInitialised()">\n' +
    '          <div class="col-xs-12">\n' +
    '            <div class="page-empty-section text-center">\n' +
    '              <i class="fa fa-money fa-2x text-light-colour"></i>\n' +
    '              <div class="page-empty-section-text">\n' +
    '                There\'s nothing to show, your wallet is empty!\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row" ng-show="earnings.list.recordsExist() && earnings.list.isInitialised()">\n' +
    '          <div class="col-xs-12 col-sm-5 col-md-4 col-lg-3">\n' +
    '            <div class="form-group btn-group page-filter dropdown-large" uib-dropdown>\n' +
    '              <button id="earnings-range" type="button" class="btn" uib-dropdown-toggle>\n' +
    '                {{earnings.selectedRangeLabel}} <span class="caret"></span>\n' +
    '              </button>\n' +
    '              <ul class="dropdown-menu" uib-dropdown-menu role="menu" aria-labelledby="earnings-range">\n' +
    '                <li role="menuitem" ng-repeat="(value, label) in earnings.ranges">\n' +
    '                  <a ng-click="earnings.selectRange(value)">{{label}}</a>\n' +
    '                </li>\n' +
    '              </ul>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="table-container">\n' +
    '        <table st-table="earnings.list.getRecords()" st-pagination-scroll st-pipe="earnings.list.load" class="table" ng-show="earnings.list.recordsExist() && earnings.list.isInitialised()">\n' +
    '          <thead class="hidden-xs">\n' +
    '          <tr>\n' +
    '            <th class="col-sm-5">Received from</th>\n' +
    '            <th class="col-sm-3">Date</th>\n' +
    '            <th class="col-sm-2 text-right">Amount</th>\n' +
    '            <th class="col-sm-2 text-right">Details</th>\n' +
    '          </tr>\n' +
    '          </thead>\n' +
    '          <tbody ng-hide="earnings.list.isLoading() && !earnings.list.isLoadingMoreRecords()">\n' +
    '          <tr ng-repeat="row in earnings.list.getRecords()">\n' +
    '            <td class="col-xs-12 col-sm-5">\n' +
    '              <table class="table-cell-main-content">\n' +
    '                <tr>\n' +
    '                  <td>\n' +
    '                    <table-user-details user="row.user"></table-user-details>\n' +
    '                  </td>\n' +
    '                  <td class="visible-xs text-right">\n' +
    '                  <span class="cursor-pointer" ng-click="earnings.tableEarningsSubContentVisible[$index] = !earnings.tableEarningsSubContentVisible[$index]">\n' +
    '                    <i class="fa fa-ellipsis-h"></i>\n' +
    '                  </span>\n' +
    '                  </td>\n' +
    '                </tr>\n' +
    '              </table>\n' +
    '              <div class="table-cell-sub-content-container" ng-show="earnings.tableEarningsSubContentVisible[$index]">\n' +
    '                <div class="table-cell-sub-content visible-xs">\n' +
    '                  <span class="text-muted">Date</span><br/>\n' +
    '                  <span class="text-normal">{{row.date | date:\'d MMM yyyy\'}}</span>\n' +
    '                </div>\n' +
    '                <div class="table-cell-sub-content visible-xs">\n' +
    '                  <span class="text-muted">Amount</span><br/>\n' +
    '                  <span class="text-normal">{{row.amount | currency:\'$\':0}}</span>\n' +
    '                </div>\n' +
    '                <div class="table-cell-sub-content visible-xs" ng-if="row.idLead">\n' +
    '                  <span class="text-muted">Details</span><br/>\n' +
    '                  <span class="text-normal"><a class="cursor-pointer" ng-click="earnings.openLead($index)">View lead</a></span>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </td>\n' +
    '            <td class="col-sm-3 hidden-xs">{{row.date | date:\'d MMM yyyy\'}}</td>\n' +
    '            <td class="col-sm-2 hidden-xs text-right">{{row.amount | currency:\'$\':0}}</td>\n' +
    '            <td class="col-sm-2 hidden-xs text-right"><a class="cursor-pointer" ng-if="row.idLead" ng-click="earnings.openLead($index)">View lead</a></td>\n' +
    '          </tr>\n' +
    '          </tbody>\n' +
    '          <tbody ng-if="earnings.list.isLoading()">\n' +
    '          <tr>\n' +
    '            <td colspan="5" class="col-xs-12 text-center">\n' +
    '              <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>\n' +
    '            </td>\n' +
    '          </tr>\n' +
    '          </tbody>\n' +
    '        </table>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row" ng-show="!earnings.list.isInitialised()">\n' +
    '          <div class="col-xs-12 text-center">\n' +
    '            <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row" ng-show="earnings.tabManager.isOpen(\'withdrawals\')">\n' +
    '      <div class="col-xs-12 table-container table-container-withdrawals">\n' +
    '\n' +
    '        <div class="row" ng-hide="earnings.withdrawalsList.recordsExist() || !earnings.withdrawalsList.isInitialised()">\n' +
    '          <div class="col-xs-12">\n' +
    '            <div class="page-empty-section text-center">\n' +
    '              <i class="fa fa-money fa-2x text-light-colour"></i>\n' +
    '              <div class="page-empty-section-text">\n' +
    '                You haven\'t made any withdrawals\n' +
    '              </div>\n' +
    '              <div class="page-empty-section-text">\n' +
    '                <button class="btn btn-primary" ng-click="earnings.withdraw()">Withdraw</button>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <table st-table="earnings.withdrawalsList.getRecords()" st-pagination-scroll st-pipe="earnings.withdrawalsList.load" class="table" ng-show="earnings.withdrawalsList.recordsExist() && earnings.withdrawalsList.isInitialised()">\n' +
    '          <thead class="hidden-xs">\n' +
    '          <tr>\n' +
    '            <th class="col-sm-6">Date</th>\n' +
    '            <th class="col-sm-6 text-right">Amount</th>\n' +
    '            <!--<th class="col-sm-4 text-right">Details</th>-->\n' +
    '          </tr>\n' +
    '          </thead>\n' +
    '          <tbody ng-hide="earnings.withdrawalsList.isLoading() && !earnings.withdrawalsList.isLoadingMoreRecords()">\n' +
    '          <tr ng-repeat="row in earnings.withdrawalsList.getRecords()">\n' +
    '            <td class="hidden-xs col-sm-6">{{row.date | date:\'d MMM yyyy\'}}</td>\n' +
    '            <td class="col-xs-12 col-sm-6 text-sm-right">\n' +
    '              <span class="text-muted visible-xs">{{row.date | date:\'d MMM yyyy\'}}<br/></span>\n' +
    '              {{row.amount | currency:\'$\':0}}\n' +
    '            </td>\n' +
    '            <!--<td class="col-sm-4 hidden-xs text-right">Detail</td>-->\n' +
    '          </tr>\n' +
    '          </tbody>\n' +
    '          <tbody ng-if="earnings.withdrawalsList.isLoading()">\n' +
    '          <tr>\n' +
    '            <td colspan="5" class="col-xs-12 text-center">\n' +
    '              <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>\n' +
    '            </td>\n' +
    '          </tr>\n' +
    '          </tbody>\n' +
    '        </table>\n' +
    '\n' +
    '        <div class="row" ng-show="!earnings.withdrawalsList.isInitialised()">\n' +
    '          <div class="col-xs-12 text-center">\n' +
    '            <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('user/user.tpl.html',
    '<div class="row user-profile-container">\n' +
    '\n' +
    '  <div class="visible-xs mobile-content" style="position: relative; z-index: 2; ">\n' +
    '    <div class="" style="position:relative;z-index: 2;">\n' +
    '      <div class="user-profile-details">\n' +
    '        <div user-avatar size="small" is-link="false" user="user.userProfile"></div>\n' +
    '        <div class="user-profile-user-title">\n' +
    '          <div>\n' +
    '            <span class="sub-title user-name-container">{{user.userProfile.fullName}}</span>\n' +
    '            <span class="sub-title sub-title-regular user-company-container">{{user.userProfile.businessName}}</span>\n' +
    '          </div>\n' +
    '          <div>\n' +
    '            <span class="sub-sub-title">{{user.userProfile.industry.name}}{{user.userProfile.subIndustry ? \' - \' + user.userProfile.subIndustry.name : \'\'}}</span>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="user-details-container">\n' +
    '      <div class="slide-if" ng-if="user.detailsVisible">\n' +
    '        <div ng-if="user.getAddress()"><i class="fa fa-map-marker"></i><a href="http://www.google.com/maps?q={{user.addressLink}}" target="_blank">{{user.getAddress()}}</a></div>\n' +
    '        <div ng-if="user.userProfile.phoneMobile"><i class="fa fa-phone"></i> <a href="tel:{{user.userProfile.phoneMobile}}">{{user.userProfile.phoneMobile}}</a></div>\n' +
    '        <div ng-if="user.userProfile.email"><i class="fa fa-envelope-o"></i> <a href="mailto:{{user.userProfile.email}}">{{user.userProfile.email}}</a></div>\n' +
    '        <div ng-if="user.userProfile.website"><i class="fa fa-desktop"></i> <a href="{{user.formatWebsiteUrl()}}" target="_blank">{{user.userProfile.website}}</a></div>\n' +
    '\n' +
    '        <div class="user-details-buttons-container">\n' +
    '          <a ui-sref="user.messages({user: user.userProfile})" class="btn-sm btn-primary" ng-if="user.tabManager.activeTab !== \'messages\' && (user.userProfile.acceptedReceiveLeadsContractExists || user.userProfile.acceptedSendLeadsContractExists)">Send Message</a>\n' +
    '          <a ui-sref="myMembershipsSendReward({user: user.userProfile})" class="btn-sm btn-primary" ng-if="user.isBusinessAccountActive() && user.agreementsDetails.receiveLeads && user.agreementsDetails.receiveLeads.isAccepted">Send Reward</a>\n' +
    '          <a ng-click="user.registerLead({user: user.userProfile})" class="btn-sm btn-primary" ng-if="user.isBusinessAccountActive()">Register Lead</a>\n' +
    '          <a ng-click="user.sendLead({user: user.userProfile})" class="btn-sm btn-primary" ng-if="user.agreementsDetails.sendLeads && user.agreementsDetails.sendLeads.isAccepted">Send Lead</a>\n' +
    '          <a ng-click="user.donate({user: user.userProfile})" class="btn-sm btn-success" ng-if="user.userProfile.isCharityAccountActive">Donate</a>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <span class="user-profile-more cursor-pointer" ng-click="user.toggleDetails()"><i class="fa" ng-class="{\'fa-chevron-down\': !user.detailsVisible, \'fa-chevron-up\': user.detailsVisible}"></i></span>\n' +
    '    </div>\n' +
    '\n' +
    '  </div>\n' +
    '  <div class="hidden-xs">\n' +
    '    <div class="col-xs-12">\n' +
    '      <div class="page-content user-profile-lg" ng-style="user.getCoverStyle()">\n' +
    '        <a class="crediting-link" ng-if="!user.isCustomBackground()" href="https://www.toptal.com/designers/subtlepatterns/gplay/" target="_blank"><i class="fa fa-copyright"></i>Design by Hoekstra</a>\n' +
    '        <div class="user-profile-container-lg">\n' +
    '          <div class="user-avatar-part">\n' +
    '            <!-- User Avatar -->\n' +
    '            <div user-avatar size="large" is-link="false" user="user.userProfile"></div>\n' +
    '          </div>\n' +
    '          <div class="user-info-part">\n' +
    '            <div>\n' +
    '              <div class="user-name-and-company mbs" ng-class="{\'mbm\': user.userProfile.businessName}" ng-style="user.getHeadlineStyle()">\n' +
    '                <!-- User Info -->\n' +
    '                <span class="sub-title user-name-container">{{user.userProfile.fullName}}</span>\n' +
    '                <span class="sub-title sub-title-regular sub-title-separator hidden-xs" ng-if="user.userProfile.businessName">|</span>\n' +
    '                <span class="sub-title sub-title-regular user-company-container">{{user.userProfile.businessName}}</span>\n' +
    '\n' +
    '                <!-- Industry Info -->\n' +
    '                <div class="sub-sub-title" ng-if="user.userProfile.industry">{{user.userProfile.industry.name}}{{user.userProfile.subIndustry ? \' - \' + user.userProfile.subIndustry.name : \'\'}}</div>\n' +
    '              </div>\n' +
    '              <!-- User Details -->\n' +
    '              <div class="user-details-container">\n' +
    '                <div ng-if="user.getAddress()"><i class="fa fa-map-marker"></i><a href="http://www.google.com/maps?q={{user.addressLink}}" target="_blank">{{user.getAddress()}}</a></div>\n' +
    '                <div ng-if="user.userProfile.phoneMobile"><i class="fa fa-phone"></i> <a href="tel:{{user.userProfile.phoneMobile}}">{{user.userProfile.phoneMobile}}</a></div>\n' +
    '                <div ng-if="user.userProfile.email"><i class="fa fa-envelope-o"></i> <a href="mailto:{{user.userProfile.email}}">{{user.userProfile.email}}</a></div>\n' +
    '                <div ng-if="user.userProfile.website"><i class="fa fa-desktop"></i> <a href="{{user.formatWebsiteUrl()}}" target="_blank">{{user.userProfile.website}}</a></div>\n' +
    '              </div>\n' +
    '              <!-- Commands -->\n' +
    '              <div class="user-details-buttons-container">\n' +
    '                <a ui-sref="user.messages({user: user.userProfile})" class="btn btn-primary" ng-if="user.userProfile.acceptedReceiveLeadsContractExists || user.userProfile.acceptedSendLeadsContractExists">Send Message</a>\n' +
    '                <a ui-sref="myMembershipsSendReward({user: user.userProfile})" class="btn btn-primary" ng-if="user.isBusinessAccountActive() && user.agreementsDetails.receiveLeads && user.agreementsDetails.receiveLeads.isAccepted">Send Reward</a>\n' +
    '                <a ng-click="user.sendLead({user: user.userProfile})" class="btn btn-primary" ng-if="user.userProfile.isBusinessAccountActive && user.agreementsDetails.sendLeads && user.agreementsDetails.sendLeads.isAccepted">Send Lead</a>\n' +
    '                <a ng-click="user.registerLead({user: user.userProfile})" class="btn btn-primary" ng-if="user.isBusinessAccountActive()">Register Lead</a>\n' +
    '                <a ng-click="user.donate({user: user.userProfile})" class="btn btn-success" ng-if="user.userProfile.isCharityAccountActive">Donate</a>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <!-- Commands -->\n' +
    '        <div class="user-details-buttons-container">\n' +
    '          <a ui-sref="user.messages({user: user.userProfile})" class="btn btn-primary" ng-if="user.userProfile.acceptedReceiveLeadsContractExists || user.userProfile.acceptedSendLeadsContractExists">Send Message</a>\n' +
    '          <a ui-sref="myMembershipsSendReward({user: user.userProfile})" class="btn btn-primary" ng-if="user.isBusinessAccountActive() && user.agreementsDetails.receiveLeads && user.agreementsDetails.receiveLeads.isAccepted">Send Reward</a>\n' +
    '          <a ng-click="user.sendLead({user: user.userProfile})" class="btn btn-primary" ng-if="user.userProfile.isBusinessAccountActive && user.agreementsDetails.sendLeads && user.agreementsDetails.sendLeads.isAccepted">Send Lead</a>\n' +
    '          <a ng-click="user.registerLead({user: user.userProfile})" class="btn btn-primary" ng-if="user.isBusinessAccountActive()">Register Lead</a>\n' +
    '          <a ng-click="user.donate({user: user.userProfile})" class="btn btn-success" ng-if="user.userProfile.isCharityAccountActive">Donate</a>\n' +
    '        </div>\n' +
    '\n' +
    '        <!-- Tab -->\n' +
    '        <tabs-manager style="background: white;" tab-id="user" tabs-config="user.tabsConfig" default-tab="agreements" tab-manager="user.tabManager"></tabs-manager>\n' +
    '\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="row visible-xs">\n' +
    '  <div class="col-xs-12">\n' +
    '    <tabs-manager tab-id="user" tabs-config="user.tabsConfig" default-tab="agreements" tab-manager="user.tabManager"></tabs-manager>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '<div ng-click="user.detailsVisible = false;" style="cursor: default;">\n' +
    '  <div class="row" ng-show="user.tabManager.isOpen(\'about\')" ng-include="\'user/about.tpl.html\'">\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="row"  ng-show="user.tabManager.isOpen(\'sentLeads\')" ng-include="\'user/leads/sent.tpl.html\'">\n' +
    '  </div>\n' +
    '\n' +
    '  <div ng-if="user.isBusinessAccountActive()" class="row"  ng-show="user.tabManager.isOpen(\'receivedLeads\')" ng-include="\'user/leads/received.tpl.html\'">\n' +
    '  </div>\n' +
    '  <div class="row"  ng-show="user.tabManager.isOpen(\'agreements\')" ng-include="\'user/agreements/agreements.tpl.html\'">\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="row"  ng-show="user.tabManager.isOpen(\'messages\')" ng-include="\'user/messages/messages.tpl.html\'">\n' +
    '  </div>\n' +
    '\n' +
    '  <div ng-if="user.userProfile.isBusinessAccountActive" class="row"  ng-show="user.tabManager.isOpen(\'connections\')" ng-include="\'user/connections/connections.tpl.html\'">\n' +
    '  </div>\n' +
    '\n' +
    '  <div ng-if="user.userProfile.isBusinessAccountActive" class="row"  ng-show="user.tabManager.isOpen(\'marketplace\')" ng-include="\'user/marketplace/marketplace.tpl.html\'">\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '<action-toolbar class="visible-xs" at-target="secondary">\n' +
    '  <a class="no-animate fa fa-dollar" label="Send Reward" ui-sref="myMembershipsSendReward({user: user.userProfile})" ng-if="user.isBusinessAccountActive() && user.agreementsDetails.receiveLeads && user.agreementsDetails.receiveLeads.isAccepted"><span class="tool-label">Send Reward</span></a>\n' +
    '  <a class="no-animate fa fa-paper-plane" label="Send Lead" ng-click="user.sendLead({user: user.userProfile})" ng-if="user.userProfile.isBusinessAccountActive && user.agreementsDetails.sendLeads && user.agreementsDetails.sendLeads.isAccepted"><span class="tool-label">Send Lead</span></a>\n' +
    '  <a class="no-animate fa fa-arrow-down" label="Register Lead" ng-click="user.registerLead({user: user.userProfile})" ng-if="user.isBusinessAccountActive()"><span class="tool-label">Register Lead</span></a>\n' +
    '  <a class="no-animate fa fa-user-plus" label="Ask for Leads" ui-sref="myMembershipsJoinPod({user: user.userProfile})" ng-if="user.isBusinessAccountActive() && !user.agreementsDetails.receiveLeads"><span class="tool-label">Ask for Leads</span></a>\n' +
    '  <a class="no-animate fa fa-heart" label="Donate" ng-click="user.donate()" ng-if="user.userProfile.isCharityAccountActive"><span class="tool-label">Donate</span></a>\n' +
    '</action-toolbar>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('user/user-avatar-directive.tpl.html',
    '<span class="avatar-container" ng-class="{\'avatar-container-small\':userAvatar.isSmall(), \'avatar-container-large\':!userAvatar.isSmall(), \'fixed-size\': userAvatar.isFixedSize()}">\n' +
    '  <a ng-if="userAvatar.isLink" ui-sref="user.agreements({id: userAvatar.idUser})"\n' +
    '     ng-bind="userAvatar.initials" class="avatar"\n' +
    '     ng-class="{\'avatar-picture\':userAvatar.isImage(), \'avatar-initials\':!userAvatar.isImage(),\n' +
    '     \'avatar-small\':userAvatar.isSmall(), \'avatar-large\':!userAvatar.isSmall()\n' +
    '     }"\n' +
    '     ng-style="userAvatar.getStyle()"></a>\n' +
    '  <span ng-if="!userAvatar.isLink" ng-bind="userAvatar.initials" class="avatar"\n' +
    '        ng-class="{\'avatar-picture\':userAvatar.isImage(), \'avatar-initials\':!userAvatar.isImage(),\n' +
    '        \'avatar-small\':userAvatar.isSmall(), \'avatar-large\':!userAvatar.isSmall()\n' +
    '        }"\n' +
    '        ng-style="userAvatar.getStyle()"></span>\n' +
    '</span>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('user/about.tpl.html',
    '<div class="col-xs-12" ng-controller="AboutCtrl as about" ng-show="about.userProfile.description">\n' +
    '  <div class="page-content" ng-bind-html="about.userProfile.description | nl2br">\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('toolbar/toolbar.tpl.html',
    '<div ng-controller="ToolbarCtrl as toolbar">\n' +
    '  <nav id="main-navbar" class="navbar"\n' +
    '       ng-class="{\'navbar-default\': toolbar.isAuthenticated(), \'navbar-visitor\': !toolbar.isAuthenticated(), \'is-mobile\': toolbar.isMobile}">\n' +
    '\n' +
    '    <div class="container fill-height" ng-if="!toolbar.isAuthenticated()">\n' +
    '      <div class="row">\n' +
    '        <div class="col-xs-4">\n' +
    '          <a href="https://www.myleadpod.com" class="has-image">\n' +
    '            <img class="nav-no-login-logo" src="images/logo-original-centered.svg" alt="MyLeadPod">\n' +
    '          </a>\n' +
    '        </div>\n' +
    '        <div class="col-xs-8 text-right">\n' +
    '          <a ng-click="toolbar.openFeedback(true)" class="navbar-link nav-no-login-link" style="margin-right: 20px; cursor: pointer;">Need Help?</a>\n' +
    '          <a ui-sref="accountSignInUp.accountSignUp" class="navbar-link nav-no-login-link no-animate" ng-if="toolbar.isLogin()">Sign up</a>\n' +
    '          <a ui-sref="accountSignInUp.accountSignIn" class="navbar-link nav-no-login-link no-animate" ng-if="toolbar.showLoginLink()">Login</a>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="container" ng-if="toolbar.isAuthenticated()" style="pointer-events: none;">\n' +
    '\n' +
    '      <div class="navbar-header">\n' +
    '\n' +
    '        <a class="navbar-toggle collapsed" ng-click="toolbar.toggleMenu()">\n' +
    '          <span class="sr-only">Toggle navigation</span>\n' +
    '          <i class="glyphicon glyphicon-menu-hamburger"></i>\n' +
    '        </a>\n' +
    '\n' +
    '       <div id="searchbar-alt" class="searchbar-container"></div>\n' +
    '\n' +
    '\n' +
    '        <a ng-if="toolbar.isDesktop" class="navbar-brand navbar-brand-desktop" ui-sref="dashboard">\n' +
    '          <img src="images/logo-normal-fullalpha.svg"/>\n' +
    '        </a>\n' +
    '\n' +
    '        <a ng-if="toolbar.isMobile" class="navbar-brand navbar-brand-mobile" ui-sref="dashboard">\n' +
    '          <img src="images/logo-mobile-opaque.svg"/>\n' +
    '        </a>\n' +
    '\n' +
    '        <!--<div id="notification-bell-alt" ng-class="{\'has-notifications\': toolbar.hasNotifications}"></div>-->\n' +
    '      </div>\n' +
    '\n' +
    '      <!-- Navbar-collapse -->\n' +
    '      <div id="navbar" class="navbar-collapse collapse"\n' +
    '           ng-class="{open: !toolbar.isCollapsed}">\n' +
    '\n' +
    '        <div ng-if="!toolbar.isCollapsed" style="position: absolute; width: 100vw; height: 100%; top: 0px; left: 0px; background: transparent; z-index: -1;" ng-click="toolbar.isCollapsed = true;"></div>\n' +
    '\n' +
    '        <ul class="nav navbar-nav main-menu">\n' +
    '          <li ng-if="toolbar.isMobile" style="text-align: center">\n' +
    '            <a ui-sref="account.profile" ng-click="toolbar.closeMenu()" data-toggle="dropdown" role="button" style="height: auto;position:relative; text-align:center;">\n' +
    '              <div user-avatar user="toolbar.getUserProfile()" size="large" is-link="false"></div>\n' +
    '              <div>{{toolbar.userProfile.fullName}}</div>\n' +
    '            </a>\n' +
    '          </li>\n' +
    '          <li ui-sref-active="active"><a class="icon-only-lg icon-only-md" ui-sref="dashboard" ng-click="toolbar.closeMenu()"><i class="fa fa-home"></i><span>Dashboard</span></a></li>\n' +
    '          <li ui-sref-active="active"><a ui-sref="myPod.members" ng-click="toolbar.closeMenu()"><i class="fa fa-paper-plane"></i>My Pod</a></li>\n' +
    '          <li ui-sref-active="active" ng-show="toolbar.isBusinessAccountActive()"><a ui-sref="myMemberships.memberships" ng-click="toolbar.closeMenu()"><i class="fa fa-briefcase"></i>My Business</a></li>\n' +
    '          <li ng-if="toolbar.isMobile" class="separator"><span></span></li>\n' +
    '          <li ui-sref-active="active"><a ui-sref="marketplace" ng-click="toolbar.closeMenu()"><i class="fa fa-shopping-bag"></i>Marketplace</a></li>\n' +
    '\n' +
    '          <li ng-if="toolbar.isMobile" class="separator"><span></span></li>\n' +
    '          <li ng-if="toolbar.isMobile"><a ui-sref="account.profile" ng-click="toolbar.closeMenu()"><i class="fa fa-info"></i>Account Details</a></li>\n' +
    '          <li ng-if="toolbar.isMobile && toolbar.isBusinessAccountActive()""><a ui-sref="account.billing" ng-click="toolbar.closeMenu()"><i class="fa fa-credit-card"></i>Billing Information</a></li>\n' +
    '          <li ng-if="toolbar.isMobile"><a ui-sref="account.earnings" ng-click="toolbar.closeMenu()"><i class="fa fa-money"></i>Earnings</a></li>\n' +
    '          <li ng-if="toolbar.isMobile && !toolbar.isBusinessAccountActive()">\n' +
    '            <a ui-sref="myMemberships" ng-click="toolbar.closeMenu()">\n' +
    '              <i class="fa fa-briefcase"></i>\n' +
    '            Business Account\n' +
    '            <span ng-if="toolbar.trialData.isEligibleForTrial" class="evaluation-notification">Evaluate</span>\n' +
    '            </a>\n' +
    '          </li>\n' +
    '          <li ng-if="toolbar.isMobile && toolbar.isBusinessAccountActive()"><a ui-sref="myMembershipsRewardProgramsSetup" ng-click="toolbar.closeMenu()"><i class="fa fa-book"></i>Rewards Program</a></li>\n' +
    '          <li ng-if="toolbar.isMobile" class="separator"><span></span></li>\n' +
    '          <li ng-if="toolbar.isMobile">\n' +
    '            <a class="cursor-pointer" ng-click="toolbar.openPreferences();"><i class="fa fa-sliders"></i>Preferences</a>\n' +
    '          </li>\n' +
    '          <li ng-if="toolbar.isMobile">\n' +
    '            <a class="cursor-pointer" ng-click="toolbar.openFeedback();"><i class="fa fa-commenting"></i>Send Feedback</a>\n' +
    '          </li>\n' +
    '          <li ng-if="toolbar.isMobile">\n' +
    '            <a class="cursor-pointer" target="_blank" href="http://www.myleadpod.com/faq"><i class="fa fa-question"></i>FAQs</a>\n' +
    '          </li>\n' +
    '          <li ng-if="toolbar.isMobile" class="separator"><span></span></li>\n' +
    '          <li ng-if="toolbar.isMobile">\n' +
    '            <a class="cursor-pointer" ng-click="toolbar.signOut()"><i class="fa fa-power-off"></i>Logout</a>\n' +
    '          </li>\n' +
    '        </ul>\n' +
    '\n' +
    '        <div class="searchbar-container">\n' +
    '          <search-bar node-relocate alt-element-id="searchbar-alt" alt-element-breakpoint="1024"></search-bar>\n' +
    '        </div>\n' +
    '\n' +
    '\n' +
    '        <!--<ul class="nav navbar-nav notifications-nav">-->\n' +
    '          <!--<li class="dropdown" node-relocate alt-element-id="notification-bell-alt" alt-element-breakpoint="1024"-->\n' +
    '              <!--uib-dropdown-->\n' +
    '              <!--auto-close="outsideClick"-->\n' +
    '              <!--on-toggle="toolbar.toggleNotificationCenter(open)">-->\n' +
    '            <!--<a href="#" class="cursor-pointer dropdown-toggle notifications-bell"-->\n' +
    '               <!--ng-class="{\'has-notifications\': toolbar.hasNotifications}"-->\n' +
    '               <!--data-toggle="dropdown"-->\n' +
    '               <!--role="button"-->\n' +
    '               <!--aria-haspopup="true"-->\n' +
    '               <!--aria-expanded="false" uib-dropdown-toggle>-->\n' +
    '              <!--<span class="fa fa-bell" data-n-count="{{toolbar.notificationsCount}}"></span>-->\n' +
    '            <!--</a>-->\n' +
    '            <!--<ul uib-dropdown-menu role="menu" class="dropdown-menu slide-css" ng-class="{\'slide\': toolbar.isNCopen}">-->\n' +
    '              <!--<notification-center></notification-center>-->\n' +
    '            <!--</ul>-->\n' +
    '          <!--</li>-->\n' +
    '        <!--</ul>-->\n' +
    '\n' +
    '        <ul ng-if="toolbar.isDesktop" class="nav navbar-nav navbar-right">\n' +
    '          <li class="dropdown" uib-dropdown on-toggle="toolbar.toggleProfile(open)">\n' +
    '            <a href="#" class="navbar-user-container dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"\n' +
    '               aria-expanded="false" uib-dropdown-toggle>\n' +
    '              <div class="no-select" style="position: relative; margin-top: 3px;">\n' +
    '                <span user-avatar size="small" user="toolbar.getUserProfile()" is-link="false"></span>\n' +
    '                <span class="navbar-user-container-name">{{toolbar.userProfile.fullName}}</span>\n' +
    '              </div>\n' +
    '              <span class="caret"></span>\n' +
    '            </a>\n' +
    '            <ul uib-dropdown-menu role="menu" class="dropdown-menu slide-css" ng-class="{\'slide\': toolbar.isProfileOpen}">\n' +
    '              <li><a ui-sref="account.profile" ng-click="toolbar.closeMenu()">Account Details</a></li>\n' +
    '              <li><a ng-show="toolbar.isBusinessAccountActive()" ui-sref="account.billing" ng-click="toolbar.closeMenu()">Billing Information</a></li>\n' +
    '              <li><a ui-sref="account.earnings" ng-click="toolbar.closeMenu()">Earnings</a></li>\n' +
    '              <li>\n' +
    '                <a ng-show="!toolbar.isBusinessAccountActive()" ui-sref="myMemberships.memberships" ng-click="toolbar.closeMenu()">Business Account\n' +
    '                  <span ng-if="toolbar.trialData.isEligibleForTrial" class="evaluation-notification">Evaluate</span>\n' +
    '                </a>\n' +
    '              </li>\n' +
    '              <li><a ng-show="toolbar.isBusinessAccountActive()" ui-sref="myMembershipsRewardProgramsSetup" ng-click="toolbar.closeMenu()">Rewards Program</a></li>\n' +
    '              <li role="separator" class="divider"></li>\n' +
    '              <li><a class="cursor-pointer" ng-click="toolbar.openPreferences()">Preferences</a></li>\n' +
    '              <li><a class="cursor-pointer" ng-click="toolbar.openFeedback()">Send Feedback</a></li>\n' +
    '              <li><a class="cursor-pointer" target="_blank" href="http://www.myleadpod.com/faq">FAQs</a></li>\n' +
    '              <li role="separator" class="divider"></li>\n' +
    '              <li><a class="cursor-pointer" ng-click="toolbar.signOut()">Logout</a></li>\n' +
    '            </ul>\n' +
    '          </li>\n' +
    '        </ul>\n' +
    '      </div>\n' +
    '      <!-- End navbar-collapse -->\n' +
    '\n' +
    '    </div>\n' +
    '  </nav>\n' +
    '\n' +
    '  <!-- Display the "register business suggestion" in case he is not a business. The directive handles the check with the local storage -->\n' +
    '    <business-suggestion ng-if="toolbar.isAuthenticated() && (!toolbar.isBusinessAccountActive() || (toolbar.isBusinessAccountActive() && toolbar.trialData.isInTrial && toolbar.trialData.trialWarning))"></business-suggestion>\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('toolbar/business-suggestion-directive.tpl.html',
    '<div class="business-suggestion" ng-hide="suggestion.isClosed">\n' +
    '  <div class="container">\n' +
    '\n' +
    '    <div ng-if="!suggestion.trialData.isEligibleForTrial && !suggestion.trialData.isInTrial" class="suggestion-confirm">\n' +
    '      <span> Do you want to receive leads for your own business?</span>\n' +
    '      <button class="btn-sm btn-primary" ng-click="suggestion.registerBusiness()" ng-disabled="suggestion.isActivating">\n' +
    '        {{suggestion.isActivating ? \'Activating ...\' : \'Register my business\'}}\n' +
    '      </button>\n' +
    '    </div>\n' +
    '\n' +
    '    <div ng-if="suggestion.trialData.isEligibleForTrial" class="suggestion-confirm">\n' +
    '      <span>You are eligible for a trial business subscription, subscribe now and start receiving leads!</span>\n' +
    '      <button class="btn-sm btn-primary" ng-click="suggestion.registerBusiness()" ng-disabled="suggestion.isActivating">\n' +
    '        {{suggestion.isActivating ? \'Activating ...\' : \'Register for trial business account\'}}\n' +
    '      </button>\n' +
    '    </div>\n' +
    '\n' +
    '    <div ng-if="suggestion.trialData.isInTrial && suggestion.trialData.trialWarning" class="suggestion-confirm">\n' +
    '      <span>Your trial business account subscription {{suggestion.trialData.trialExpiresIn}}</span>\n' +
    '      <button class="btn-sm btn-primary" ng-click="suggestion.registerBusiness()" ng-disabled="suggestion.isActivating">\n' +
    '        {{suggestion.isActivating ? \'Activating ...\' : \'Buy subscription\'}}\n' +
    '      </button>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="suggestion-cancel" ng-if="!suggestion.trialData.isInTrial || !suggestion.trialData.trialWarning">\n' +
    '      <button class="btn-sm btn-primary" style="background:transparent; color: inherit;" ng-click="suggestion.closeSuggestion()">x</button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('social-share/social-share-directive.tpl.html',
    '<div class="social-share-container">\n' +
    '  <span>\n' +
    '    <a class="social-share-button-share" data-options="align:left" data-dropdown="dropdown-share{{socialShare.id}}">\n' +
    '      <i class="fa fa-share-alt"></i> Share\n' +
    '    </a>\n' +
    '  </span>\n' +
    '\n' +
    '  <ul id="dropdown-share{{socialShare.id}}" class="f-dropdown drop-left" data-dropdown-content>\n' +
    '    <li><a class="social-share-button" ng-click="socialShare.share(\'facebook\')"><i class="fa fa-facebook-square"></i> Facebook</a></li>\n' +
    '    <li><a class="social-share-button" ng-click="socialShare.share(\'linkedin\')"><i class="fa fa-linkedin"></i> LinkedIn</a></li>\n' +
    '    <li><a class="social-share-button" ng-click="socialShare.share(\'twitter\')"><i class="fa fa-twitter"></i> Twitter</a></li>\n' +
    '    <li><a class="social-share-button" ng-click="socialShare.share(\'google\')"><i class="fa fa-google-plus"></i> Google+</a></li>\n' +
    '  </ul>\n' +
    '\n' +
    '  <script type="text/javascript">\n' +
    '    $(document).foundation({\n' +
    '      dropdown: {\n' +
    '        active_class: \'open\'\n' +
    '      }\n' +
    '    });\n' +
    '  </script>\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('search/search-bar-directive.tpl.html',
    '<div class="no-select">\n' +
    '    <label class="sr-only" for="searchbar">Search</label>\n' +
    '\n' +
    '    <search-autocomplete\n' +
    '        control-id="{{searchbar.searchbarId}}"\n' +
    '        placeholder="Search"\n' +
    '        minlength="3"\n' +
    '        clear-selected="false"\n' +
    '\n' +
    '        search-service="{{searchbar.searchService}}"\n' +
    '        search-service-end-point="{{searchbar.searchServiceEndPoint}}"\n' +
    '        remote-url-response-formatter="searchbar.searchResponseFormatter"\n' +
    '\n' +
    '        data-field="content.records"\n' +
    '        selected-object="searchbar.onSearchItemSelected"\n' +
    '\n' +
    '        search-fields="search"\n' +
    '        title-field="value"\n' +
    '        description-field="categoryDescription"\n' +
    '        description-field2="timeDescription"\n' +
    '        category-field="category"\n' +
    '\n' +
    '        input-name="searchbarControl"\n' +
    '        input-class="form-control input-lg"\n' +
    '        match-class="autocomplete-highlight"\n' +
    '        blur-on-select="true"\n' +
    '        has-back-button="true"\n' +
    '        update-input-on-select="true"\n' +
    '\n' +
    '        search-categories="searchbar.searchCategories"\n' +
    '        focus-in="searchbar.focusIn()"\n' +
    '        focus-out="searchbar.focusOut()"\n' +
    '\n' +
    '        has-history="true"\n' +
    '        field-required="false" />\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('search/search-bar-autocomplete-result-template.tpl.html',
    '<div ng-transclude="result">\n' +
    '  VAJCO! + {{result.value}}\n' +
    '</div>');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('search/search-autocomplete-directive.tpl.html',
    '<div id="{{controlId}}"\n' +
    '    class="search-autocomplete-holder"\n' +
    '    ng-click="checkFocus($event)"\n' +
    '    ng-class="{\'search-focused\': searchFocused == true, \'filter-active\': filterActive, \'search-autocomplete-dropdown-visible\': showDropdown}">\n' +
    '\n' +
    '    <div class="input-holder">\n' +
    '        <div class="visible-xs back-button" ng-click="backBtnClicked()" ng-if="hasBackButton">\n' +
    '            <i class="fa fa-arrow-left"></i>\n' +
    '        </div>\n' +
    '        <input id="{{controlId}}_value"\n' +
    '            name="{{inputName}}"\n' +
    '            tabindex="{{fieldTabindex}}"\n' +
    '            type="{{inputType}}"\n' +
    '            placeholder="{{placeholder}}"\n' +
    '            maxlength="{{maxlength}}"\n' +
    '            class="{{inputClass}}"\n' +
    '\n' +
    '            ng-focus="onFocusHandler()"\n' +
    '            ng-blur="hideResults($event, true)"\n' +
    '            ng-class="{\'search-autocomplete-input-not-empty\': notEmpty}"\n' +
    '            ng-model="searchStr"\n' +
    '            ng-disabled="disableInput"\n' +
    '            ng-change="inputChangeHandler(searchStr)"\n' +
    '\n' +
    '            autocapitalize="off"\n' +
    '            autocorrect="off"\n' +
    '            autocomplete="off" />\n' +
    '\n' +
    '        <div ng-class="{\'search-autocomplete-searching\': searching}" class="search-autocomplete-icons">\n' +
    '            <div class="search-filter" tabindex="1" role="button" ng-click="openFilter()" ng-if="searchCategories && searchCategories.length > 1 && hasCategoryFilter">\n' +
    '                <i class="fa fa-chevron-down" />\n' +
    '            </div>\n' +
    '            <div class="icons">\n' +
    '                <i class="pending fa fa-circle-o-notch fa-spin-2x"></i>\n' +
    '                <i class="search fa fa-search opacity-if" ng-if="checkSearch()"></i>\n' +
    '                <i class="fa fa-times opacity-if" ng-click="clearInput()" ng-if="checkInput()"/>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '\n' +
    '    </div>\n' +
    '\n' +
    '    <div id="{{controlId}}_filter_dropdown" class="search-autocomplete-dropdown search-filter-dropdown no-select slide-if" ng-if="showFilterDropdown">\n' +
    '        <span class="search-filter-description">Narrow your search by choosing the search categories:</span>\n' +
    '        <nav>\n' +
    '            <ul>\n' +
    '                <li ng-repeat="category in searchCategories">\n' +
    '                    <div ng-class="{\'filter-selected\': isSelected(\'{{category.value}}\')}" ng-click="changeCategory(category.value, null, false)"><i class="fa fa-check"></i>{{category.name}}</div>\n' +
    '                </li>\n' +
    '            </ul>\n' +
    '        </nav>\n' +
    '    </div>\n' +
    '\n' +
    '    <div id="{{controlId}}_history_dropdown"\n' +
    '         class="search-autocomplete-dropdown no-select slide-if"\n' +
    '         ng-if="hasHistory && showHistoryDropdown && historyItems.length > 0"\n' +
    '         style="cursor: inherit;">\n' +
    '\n' +
    '      <!-- search history -->\n' +
    '      <div class="results-container">\n' +
    '        <span class="search-filter-description">Your last selected items:</span>\n' +
    '        <!-- result item -->\n' +
    '        <div class="results-container" ng-class="{searching: searching}">\n' +
    '          <div class="search-autocomplete-row no-animate"\n' +
    '               ng-repeat="result in historyItems"\n' +
    '               ng-click="selectHistoryItem(result)"\n' +
    '               ng-mouseenter="hoverRow($index, true)"\n' +
    '               ng-mouseleave="removeHover(true)"\n' +
    '               ng-class="{\'search-autocomplete-selected-row\': $index == currentHistoryIndex}">\n' +
    '\n' +
    '            <div ng-if="!resultTemplateUrl" class="result-content">\n' +
    '              <div ng-if="imageField" class="search-autocomplete-image-holder">\n' +
    '                <img ng-if="result.image && result.image != \'\'" ng-src="{{result.image}}" class="search-autocomplete-image"/>\n' +
    '                <div ng-if="!result.image && result.image != \'\'" class="search-autocomplete-image-default"></div>\n' +
    '              </div>\n' +
    '              <div ng-if="matchClass && result.description && result.description != \'\'" class="search-autocomplete-description" ng-bind-html="result.description"></div>\n' +
    '              <div ng-if="!matchClass && result.description && result.description != \'\'" class="search-autocomplete-description">{{result.description}}</div>\n' +
    '              <div class="search-autocomplete-title" ng-if="matchClass" ng-bind-html="result.title"></div>\n' +
    '              <div class="search-autocomplete-title" ng-if="!matchClass">{{ result.title }}</div>\n' +
    '              <div ng-if="result.description2 && result.description2 != \'\'" class="search-autocomplete-description2">{{result.description2}}</div>\n' +
    '            </div>\n' +
    '            <div ng-if="resultTemplateUrl" ng-include src="resultTemplateUrl">\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div id="{{controlId}}_dropdown" class="search-autocomplete-dropdown slide-css" ng-class="{\'slide-fast\': showDropdown}">\n' +
    '    <!--<div id="{{controlId}}_dropdown" class="search-autocomplete-dropdown">-->\n' +
    '\n' +
    '        <!-- category filter -->\n' +
    '        <div class="result-category-filter no-select" ng-if="searchCategories && searchCategories.length > 1 && hasCategoryFilter">\n' +
    '            <div>Filter:</div>\n' +
    '            <div class="category-filters">\n' +
    '                <div ng-repeat="category in searchCategories"\n' +
    '                    ng-class="{\'filter-selected\': isSelected(\'{{category.value}}\'), \'has-results\': category.hasResults}"\n' +
    '                    ng-click="changeCategory(category.value, $event, true)"\n' +
    '                    data-category-value="category.value">\n' +
    '                    <div class="category-badge"></div>{{category.name}}\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <!-- no results found -->\n' +
    '        <div class="search-autocomplete-searching" ng-show="!searching && (!results || results.length == 0)" ng-bind="textNoResults"></div>\n' +
    '\n' +
    '        <!-- result item -->\n' +
    '        <div class="results-container" ng-class="{searching: searching}">\n' +
    '            <div class="search-autocomplete-row no-animate"\n' +
    '                ng-repeat="result in results"\n' +
    '                ng-click="selectResult(result)"\n' +
    '                ng-mouseenter="hoverRow($index)"\n' +
    '                ng-mouseleave="removeHover()"\n' +
    '                ng-class="{\'search-autocomplete-selected-row\': $index == currentIndex}">\n' +
    '\n' +
    '                <div ng-if="!resultTemplateUrl" class="result-content">\n' +
    '                    <div ng-if="imageField" class="search-autocomplete-image-holder">\n' +
    '                        <img ng-if="result.image && result.image != \'\'" ng-src="{{result.image}}" class="search-autocomplete-image"/>\n' +
    '                        <div ng-if="!result.image && result.image != \'\'" class="search-autocomplete-image-default"></div>\n' +
    '                    </div>\n' +
    '                    <div ng-if="matchClass && result.description && result.description != \'\'" class="search-autocomplete-description" ng-bind-html="result.description"></div>\n' +
    '                    <div ng-if="!matchClass && result.description && result.description != \'\'" class="search-autocomplete-description">{{result.description}}</div>\n' +
    '                    <div class="search-autocomplete-title" ng-if="matchClass" ng-bind-html="result.title"></div>\n' +
    '                    <div class="search-autocomplete-title" ng-if="!matchClass">{{ result.title }}</div>\n' +
    '                    <div ng-if="result.description2 && result.description2 != \'\'" class="search-autocomplete-description2">{{result.description2}}</div>\n' +
    '                </div>\n' +
    '                <div ng-if="resultTemplateUrl" ng-include src="resultTemplateUrl">\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('notifications/notification-center-directive.tpl.html',
    '<!--<div class="nc no-select">\n' +
    '\n' +
    '  <div ng-if="(notifications.length > 0 || filterActive) && filterCategories && filterCategories.length > 1">\n' +
    '    <div class="result-category-filter no-select">\n' +
    '      <div>Filter:</div>\n' +
    '      <div class="category-filters">\n' +
    '        <div ng-repeat="category in filterCategories"\n' +
    '             ng-class="{\'filter-selected\': isSelected(\'{{category.value}}\'), \'has-results\': category.hasResults}"\n' +
    '             ng-click="changeCategory(category.value, $event, true)"\n' +
    '             data-category-value="category.value">\n' +
    '          <div class="category-badge"></div>{{category.name}}\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <a ng-click="clearNotifications">Mark as read</a>\n' +
    '    </div>\n' +
    '\n' +
    '    <div style="position: relative; width: 100%; overflow: scroll; max-height: 51vh;">\n' +
    '      <div ng-repeat="n in notifications" class="nc-notification no-animate" ng-click="openNotification(n)">\n' +
    '        <div class="nc-icon">\n' +
    '          <span class="fa {{n.iconClass}}"></span>\n' +
    '        </div>\n' +
    '        <div class="nc-container">\n' +
    '          <div class="nc-header">\n' +
    '            <div>\n' +
    '              {{n.title}}\n' +
    '            </div>\n' +
    '            <div class="nc-time">\n' +
    '              {{n.time | date:\'d MMM yyyy HH:mm\'}}\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="nc-body">\n' +
    '            <div class="nc-user">\n' +
    '              <span class="nc-user-name">{{n.userName}}</span>\n' +
    '              <span class="nc-user-business" ng-if="n.userBusiness">| {{n.userBusiness}}</span>\n' +
    '            </div>\n' +
    '            <div class="nc-value">\n' +
    '              {{n.value}}\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div ng-if="notifications.length === 0 && !filterActive">\n' +
    '    You have no new notifications.\n' +
    '  </div>\n' +
    '\n' +
    '  <div ng-if="notifications.length === 0 && filterActive">\n' +
    '    You have no notifications in this category.\n' +
    '  </div>\n' +
    '\n' +
    '  <button class="btn-sm btn-primary" ng-click="clearNotification()">Clear</button>\n' +
    '  <button class="btn-sm btn-primary" ng-click="raiseNotification()">Raise</button>\n' +
    '</div>-->\n' +
    '\n' +
    '<!--<div class="nc2 no-select">\n' +
    '  <div ng-if="(notifications.length > 0 || filterActive) && filterCategories && filterCategories.length > 1">\n' +
    '    <div class="result-category-filter no-select">\n' +
    '      <div>Filter:</div>\n' +
    '      <div class="category-filters">\n' +
    '        <div ng-repeat="category in filterCategories"\n' +
    '             ng-class="{\'filter-selected\': isSelected(\'{{category.value}}\'), \'has-results\': category.hasResults}"\n' +
    '             ng-click="changeCategory(category.value, $event, true)"\n' +
    '             data-category-value="category.value">\n' +
    '          <div class="category-badge"></div>{{category.name}}\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <a ng-click="clearNotifications">Mark as read</a>\n' +
    '    </div>\n' +
    '\n' +
    '    <div style="position: relative; width: 100%; overflow: scroll; max-height: 51vh;">\n' +
    '      <div ng-repeat="n in notifications" class="nc-notification no-animate" ng-click="openNotification(n)">\n' +
    '\n' +
    '        <div class="nc-header">\n' +
    '          <div class="nc-icon">\n' +
    '            <span class="fa {{n.iconClass}}"></span>\n' +
    '          </div>\n' +
    '          <div class="nc-title">\n' +
    '            {{n.title}}\n' +
    '          </div>\n' +
    '          <div class="nc-time">\n' +
    '            {{n.time | date:\'d MMM yyyy HH:mm\'}}\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="nc-container">\n' +
    '          <div class="nc-body">\n' +
    '            <div class="nc-user">\n' +
    '              <span class="nc-user-name">{{n.userName}}</span>\n' +
    '              <span class="nc-user-business" ng-if="n.userBusiness">| {{n.userBusiness}}</span>\n' +
    '            </div>\n' +
    '            <div class="nc-value">\n' +
    '              {{n.value}}\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div ng-if="notifications.length === 0 && !filterActive">\n' +
    '    You have no new notifications.\n' +
    '  </div>\n' +
    '\n' +
    '  <div ng-if="notifications.length === 0 && filterActive">\n' +
    '    You have no notifications in this category.\n' +
    '  </div>\n' +
    '\n' +
    '  <button class="btn-sm btn-primary" ng-click="clearNotification()">Clear</button>\n' +
    '  <button class="btn-sm btn-primary" ng-click="raiseNotification()">Raise</button>\n' +
    '</div>-->\n' +
    '\n' +
    '<!--<div class="nc3 no-select">\n' +
    '  <div ng-if="(notifications.length > 0 || filterActive) && filterCategories && filterCategories.length > 1">\n' +
    '    <div class="result-category-filter no-select">\n' +
    '      <div>Filter:</div>\n' +
    '      <div class="category-filters">\n' +
    '        <div ng-repeat="category in filterCategories"\n' +
    '             ng-class="{\'filter-selected\': isSelected(\'{{category.value}}\'), \'has-results\': category.hasResults}"\n' +
    '             ng-click="changeCategory(category.value, $event, true)"\n' +
    '             data-category-value="category.value">\n' +
    '          <div class="category-badge"></div>{{category.name}}\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <a ng-click="clearNotifications">Mark as read</a>\n' +
    '    </div>\n' +
    '\n' +
    '    <div style="position: relative; width: 100%; overflow: scroll; max-height: 51vh;">\n' +
    '      <div ng-repeat="n in notifications" class="nc-notification no-animate" ng-click="openNotification(n)">\n' +
    '\n' +
    '        <div class="nc-header">\n' +
    '          <div class="nc-icon">\n' +
    '            <span class="fa {{n.iconClass}}"></span>\n' +
    '          </div>\n' +
    '          <div class="nc-title">\n' +
    '            <div class="nc-user">\n' +
    '              <span class="nc-user-name">{{n.userName}}</span>\n' +
    '              <span class="nc-user-business" ng-if="n.userBusiness"><span class="fa fa-briefcase"></span></span>\n' +
    '              <span class="nc-description"> {{n.description}}</span>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="nc-time">\n' +
    '            {{n.time | date:\'d MMM yyyy HH:mm\'}}\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="nc-container">\n' +
    '          <div class="nc-body">\n' +
    '            <div class="nc-value no-animate" ng-if="n.value">\n' +
    '              {{n.value}}\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div ng-if="notifications.length === 0 && !filterActive">\n' +
    '    You have no new notifications.\n' +
    '  </div>\n' +
    '\n' +
    '  <div ng-if="notifications.length === 0 && filterActive">\n' +
    '    You have no notifications in this category.\n' +
    '  </div>\n' +
    '\n' +
    '  <button class="btn-sm btn-primary" ng-click="clearNotification()">Clear</button>\n' +
    '  <button class="btn-sm btn-primary" ng-click="raiseNotification()">Raise</button>\n' +
    '</div>-->\n' +
    '\n' +
    '<div class="nc4 no-select" ng-class="{\'help-visible\': nc.displayHelp}">\n' +
    '  <div ng-if="(nc.notifications.length > 0 || nc.filterActive) && nc.filterCategories && nc.filterCategories.length > 1">\n' +
    '    <div class="result-category-filter no-select">\n' +
    '      <div>Filter:</div>\n' +
    '      <div class="category-filters">\n' +
    '        <div ng-repeat="category in nc.filterCategories"\n' +
    '             ng-class="{\'filter-selected\': nc.isSelected(\'{{category.value}}\'), \'has-results\': category.hasResults}"\n' +
    '             ng-click="nc.changeCategory(category.value, $event, true)"\n' +
    '             data-category-value="category.value">\n' +
    '          <div class="category-badge"></div>{{category.name}}\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <a class="cursor-pointer" ng-click="nc.markRead();">Mark as read</a>\n' +
    '    </div>\n' +
    '\n' +
    '    <div style="position: relative; width: 100%; overflow-y: auto; overflow-x: hidden; max-height: 51vh;">\n' +
    '      <div ng-repeat="n in nc.notifications" class="nc-notification no-animate">\n' +
    '        <div class="nc-notification-item"\n' +
    '             notification-swipe-item\n' +
    '             data-notification-id="{{n.id}}"\n' +
    '             on-swipe-done="nc.clearNotificationById(id)"\n' +
    '             on-swiping="nc.onSwiping(deltaX)"\n' +
    '             on-swipe-cancel=""\n' +
    '             on-click="nc.openNotificationById(id)">\n' +
    '          <div class="nc-icon">\n' +
    '            <span class="fa {{n.iconClass}}"></span>\n' +
    '          </div>\n' +
    '          <div class="nc-container">\n' +
    '            <div class="nc-body">\n' +
    '              <div class="nc-user">\n' +
    '                <span class="nc-user-name">{{n.userName}}</span>\n' +
    '                <!--<span class="nc-user-business" ng-if="n.userBusiness"><span class="fa fa-briefcase"></span></span>-->\n' +
    '                <span class="nc-description"> {{n.description}}</span>\n' +
    '              </div>\n' +
    '              <div style="display: flex; align-items: center;">\n' +
    '                <div class="nc-value no-animate">\n' +
    '                  {{n.value}}\n' +
    '                </div>\n' +
    '                <div class="nc-time">\n' +
    '                  <!--{{n.time | date:\'d MMM yyyy HH:mm\'}}-->\n' +
    '                  {{n.timeFormatted}}\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="nc-delete" ng-if="nc.isSwipeRight">\n' +
    '          <span class="fa fa-trash"></span>\n' +
    '        </div>\n' +
    '        <div class="nc-delete" style="right:0px;" ng-if="!nc.isSwipeRight">\n' +
    '          <span class="fa fa-trash"></span>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div ng-if="nc.notifications.length === 0 && !nc.filterActive">\n' +
    '    You have no new notifications.\n' +
    '  </div>\n' +
    '\n' +
    '  <div ng-if="nc.notifications.length === 0 && nc.filterActive">\n' +
    '    You have no notifications in this category.\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="nc-help slide-if" ng-if="nc.displayHelp">\n' +
    '    <div>\n' +
    '      This is your notification center. Click the notification to display the details or swipe left or right to dismiss it!\n' +
    '    </div>\n' +
    '    <button class="btn-sm btn-primary" style="margin-top: 10px; margin-bottom: 5px;" ng-click="nc.dismissHelp()">Got it.</button>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('my-pod/my-pod.tpl.html',
    '<div class="row">\n' +
    '  <div class="col-xs-12 col-sm-6">\n' +
    '    <h1 class="page-header">My Pod</h1>\n' +
    '  </div>\n' +
    '  <div class="col-sm-6 hidden-xs">\n' +
    '    <div class="page-header-buttons">\n' +
    '      <a ng-click="myPod.sendInvite();" class="btn btn-primary btn-shadow btn-lg-lg">Invite to my Pod</a>\n' +
    '      <a ui-sref="leadSend" class="btn btn-success btn-shadow btn-lg-lg">Send Lead</a>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '\n' +
    '<div class="row">\n' +
    '  <div class="col-xs-12">\n' +
    '    <tabs-manager tab-id="myPod" tabs-config="myPod.tabsConfig" default-tab="members" tab-manager="myPod.tabManager"></tabs-manager>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="row" ng-show="myPod.tabManager.isOpen(\'members\')" ng-include="\'my-pod/members/members.tpl.html\'">\n' +
    '</div>\n' +
    '\n' +
    '<div class="row" ng-show="myPod.tabManager.isOpen(\'sentLeads\')" ng-include="\'my-pod/sent-leads/sent-leads.tpl.html\'">\n' +
    '</div>\n' +
    '\n' +
    '<action-toolbar class="visible-xs" at-target="secondary">\n' +
    '  <a class="no-animate fa fa-envelope" label="Invite to my Pod" ng-click="myPod.sendInvite();"><span class="tool-label">Invite to my Pod</span></a>\n' +
    '  <a class="no-animate fa fa-paper-plane" label="Send Lead" ui-sref="leadSend"><span class="tool-label">Send Lead</span></a>\n' +
    '</action-toolbar>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('my-memberships/reward-selector-directive.tpl.html',
    '<div>\n' +
    '  <form id="rewardsForm" name="rewardsForm" ng-class="{\'is-invalid\': rewards.checkValidity()}">\n' +
    '    <div class="row" ng-if="rewards.hasDefaultProgram">\n' +
    '      <div class="col-xs-12 col-sm-12">\n' +
    '        <div class="form-group reward-system-selector"\n' +
    '              ng-class="{active: rewards.data.contractType === rewards.selectors.default.value}"\n' +
    '              ng-click="rewards.onContractTypeChange(rewards.selectors.default.value, rewardsForm, $event)">\n' +
    '\n' +
    '          <input type="radio" name="contractType" id="{{rewards.selectors.default.id}}" value="{{rewards.selectors.default.value}}"\n' +
    '                  ng-model="rewards.data.contractType" ng-required="true" />\n' +
    '          <label class="radio-label" for="{{rewards.selectors.default.id}}">\n' +
    '            {{rewards.selectors.default.desc}}\n' +
    '            <span class="cursor-pointer detail-arrow"\n' +
    '                  ng-if="rewards.data.contractType === rewards.selectors.default.value && !rewards.hasLockedDefaultProgram"\n' +
    '                  ng-click="rewards.toggleRewardsProgramDetailVisibility()">\n' +
    '              <i class="fa fa-chevron-down" ng-class="{\'fa-chevron-down\': !rewards.isRewardsProgramDetailVisible, \'fa-chevron-up\': rewards.isRewardsProgramDetailVisible}"></i>\n' +
    '            </span>\n' +
    '          </label>\n' +
    '\n' +
    '          <div class="reward-system-detail slide-if" ng-if="rewards.data.contractType === rewards.selectors.default.value && rewards.isRewardsProgramDetailVisible">\n' +
    '                <span ng-if="rewards.rewardsProgramSetup.length < 1">\n' +
    '                  Your Rewards Program is not set up. Set up your Rewards Program <a ui-sref="myMembershipsRewardProgramsSetup" target="_blank">here</a> to be able to ask for the leads with this option.\n' +
    '                </span>\n' +
    '            <table class="rewards-program-options" ng-if="rewards.rewardsProgramSetup.length > 0">\n' +
    '              <tr ng-repeat="option in rewards.rewardsProgramSetup | orderBy:\'successfulLeadPrice\'">\n' +
    '                <td>{{option.name}}</td>\n' +
    '                <td>{{option.successfulLeadPrice | currency:"$":0}}</td>\n' +
    '              </tr>\n' +
    '              <tr>\n' +
    '                <td colspan="2">\n' +
    '                  <a ui-sref="myMembershipsRewardProgramsSetup" target="_blank">Click here to manage</a>\n' +
    '                </td>\n' +
    '              </tr>\n' +
    '            </table>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row" ng-if="rewards.hasCode">\n' +
    '      <div class="col-xs-12 col-sm-12">\n' +
    '        <div class="form-group reward-system-selector" ng-class="{active: rewards.data.contractType === rewards.selectors.code.value}" ng-click="rewards.onContractTypeChange(rewards.selectors.code.value, rewardsForm, $event)">\n' +
    '          <input type="radio" name="contractType" id="{{rewards.selectors.code.id}}" value="{{rewards.selectors.code.value}}" ng-model="rewards.data.contractType" ng-required="true" />\n' +
    '          <label class="radio-label" for="{{rewards.selectors.code.id}}">\n' +
    '            {{rewards.selectors.code.desc}}\n' +
    '          </label>\n' +
    '\n' +
    '          <div class="reward-system-detail slide-if" ng-if="rewards.data.contractType === rewards.selectors.code.value">\n' +
    '            <div class="form-group" ng-class="{\'has-error\': (rewardsForm.$submitted || rewardsForm.rewardCode.$touched) && rewardsForm.rewardCode.$error.required}">\n' +
    '\n' +
    '              <label class="sr-only" for="rewardCode">Select Reward Code</label>\n' +
    '              <div>\n' +
    '                <div ng-show="rewardsForm.$submitted || rewardsForm.rewardCode.$touched">\n' +
    '                  <div class="input-error" ng-show="rewardsForm.rewardCode.$error.required">Select Reward Code</div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="left-inner-addon">\n' +
    '                <i class="fa fa-hashtag inner-addon"></i>\n' +
    '                <select class="form-control input-lg" name="rewardCode" id="rewardCode" placeholder="Reward Code"\n' +
    '                        ng-required="true" ng-model="rewards.data.rewardCode" ng-change="rewards.onValueChanged()">\n' +
    '                    <option value="" disabled selected>Select Reward Code</option>\n' +
    '                    <option ng-repeat="(value, label) in rewards.rewardCodes" value="{{value}}">{{label}}</option>\n' +
    '                </select>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row" ng-if="rewards.hasPayPerLead">\n' +
    '      <div class="col-xs-12 col-sm-12">\n' +
    '        <div class="form-group reward-system-selector" ng-class="{active: rewards.data.contractType === rewards.selectors.payPerLead.value}" ng-click="rewards.onContractTypeChange(rewards.selectors.payPerLead.value, rewardsForm, $event)">\n' +
    '          <input type="radio" name="contractType" id="{{rewards.selectors.payPerLead.id}}" value="{{rewards.selectors.payPerLead.value}}" ng-model="rewards.data.contractType" ng-required="true" />\n' +
    '          <label class="radio-label" for="{{rewards.selectors.payPerLead.id}}">\n' +
    '            {{rewards.selectors.payPerLead.desc}}\n' +
    '          </label>\n' +
    '          <div class="reward-system-detail slide-if" ng-if="rewards.data.contractType === rewards.selectors.payPerLead.value">\n' +
    '            <div class="form-group" ng-class="{\'has-error\': (rewardsForm.$submitted || rewardsForm.successfulLeadPrice.$touched) && (rewardsForm.successfulLeadPrice.$error.required || rewardsForm.successfulLeadPrice.$error.number || rewardsForm.successfulLeadPrice.$error.min)}">\n' +
    '\n' +
    '              <label class="sr-only" for="successfulLeadPrice">Successful lead value</label>\n' +
    '              <div>\n' +
    '                <div ng-show="rewardsForm.$submitted || rewardsForm.successfulLeadPrice.$touched">\n' +
    '                  <div class="input-error" ng-show="rewardsForm.successfulLeadPrice.$error.number">Invalid successful lead value</div>\n' +
    '                  <div class="input-error" ng-show="rewardsForm.successfulLeadPrice.$error.required">Missing successful lead value</div>\n' +
    '                  <div class="input-error" ng-show="rewardsForm.successfulLeadPrice.$error.min">Successful lead value must not be negative</div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="left-inner-addon">\n' +
    '                <i class="fa fa-dollar inner-addon"></i>\n' +
    '                <input class="form-control input-lg" type="number" name="successfulLeadPrice" id="successfulLeadPrice" placeholder="Successful lead value"\n' +
    '                        ng-required="true" ng-model="rewards.data.successfulLeadPrice" min="0" ng-change="rewards.onValueChanged()" />\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row" ng-if="rewards.hasMonthlySubscription">\n' +
    '      <div class="col-xs-12 col-sm-12">\n' +
    '        <div class="form-group reward-system-selector" ng-class="{active: rewards.data.contractType === rewards.selectors.subscription.value}" ng-click="rewards.onContractTypeChange(rewards.selectors.subscription.value, rewardsForm, $event)">\n' +
    '          <input type="radio" name="contractType" id="{{rewards.selectors.subscription.id}}" value="{{rewards.selectors.subscription.value}}" ng-model="rewards.data.contractType" ng-required="true" />\n' +
    '          <label class="radio-label" for="{{rewards.selectors.subscription.id}}">\n' +
    '            {{rewards.selectors.subscription.desc}}\n' +
    '          </label>\n' +
    '          <div class="reward-system-detail slide-if" ng-if="rewards.data.contractType === rewards.selectors.subscription.value">\n' +
    '            <div ng-class="{\'has-error\': (rewardsForm.$submitted || rewardsForm.subscriptionPrice.$touched) && (rewardsForm.subscriptionPrice.$error.required || rewardsForm.subscriptionPrice.$error.number || rewardsForm.subscriptionPrice.$error.min)}">\n' +
    '\n' +
    '              <label class="sr-only" for="subscriptionPrice">Subscription value</label>\n' +
    '              <div>\n' +
    '                <div ng-show="rewardsForm.$submitted || rewardsForm.subscriptionPrice.$touched">\n' +
    '                  <div class="input-error" ng-show="rewardsForm.subscriptionPrice.$error.number">Invalid subscription value</div>\n' +
    '                  <div class="input-error" ng-show="rewardsForm.subscriptionPrice.$error.required">Missing subscription value</div>\n' +
    '                  <div class="input-error" ng-show="rewardsForm.subscriptionPrice.$error.min">Subscription value must not be negative</div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="left-inner-addon">\n' +
    '                <i class="fa fa-dollar inner-addon"></i>\n' +
    '                <input class="form-control input-lg" type="number" name="subscriptionPrice" id="subscriptionPrice" placeholder="Subscription value"\n' +
    '                        ng-required="true" ng-model="rewards.data.subscriptionPrice" min="0" ng-change="rewards.onValueChanged()" />\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div class="row" ng-if="rewards.showCCForm">\n' +
    '              <div class="col-xs-12">\n' +
    '                <credit-card cc-data="rewards.data.creditCard" cc-show-form="rewards.showCCForm"></credit-card>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row" ng-if="rewards.hasCancelAgreement">\n' +
    '      <div class="col-xs-12 col-sm-12">\n' +
    '        <div class="form-group reward-system-selector" ng-class="{\'active warning\': rewards.data.contractType === rewards.selectors.cancel.value}" ng-click="rewards.onContractTypeChange(rewards.selectors.cancel.value, rewardsForm, $event)">\n' +
    '            <input type="radio" name="contractType" id="{{rewards.selectors.cancel.id}}" value="{{rewards.selectors.cancel.value}}" ng-model="rewards.data.contractType" ng-required="true" />\n' +
    '            <label class="radio-label" for="{{rewards.selectors.cancel.id}}">\n' +
    '              {{rewards.selectors.cancel.desc}}\n' +
    '            </label>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </form>\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('my-memberships/my-memberships.tpl.html',
    '<div id="my-memberships-ctrl-container">\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-12 text-center trial-top-notification" ng-if="myMemberships.trialData.isInTrial">\n' +
    '      <div>You are currently evaluating the business account. This trial subscription will expire on <span style="white-space:nowrap;">{{myMemberships.trialData.trialExpiryDate | date:\'d MMM yyyy\'}}</span></div>\n' +
    '      <div ng-if="!myMemberships.trialData.trialWarning">\n' +
    '        <div>To purchase the full subscription now, click the button below</div>\n' +
    '        <button class="btn-primary btn-sm" ng-click="myMemberships.activateBusinessAccount()">Buy Subscription</button>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-12 col-sm-4">\n' +
    '      <h1 class="page-header">My Business</h1>\n' +
    '    </div>\n' +
    '    <div class="col-sm-8 hidden-xs">\n' +
    '      <div class="page-header-buttons">\n' +
    '        <div ng-if="!myMemberships.isBusinessAccountActive()">\n' +
    '          <span class="trial-notification no-select" ng-if="myMemberships.trialData.isEligibleForTrial && !myMemberships.trialData.isInTrial">You are eligible for the trial business subscription</span>\n' +
    '          <button class="btn btn-success btn-shadow btn-lg-lg"\n' +
    '                  ng-click="myMemberships.activateBusinessAccount()"\n' +
    '                  ng-disabled="myMemberships.isActivating">{{myMemberships.isActivating ? \'Activating ...\' : \'Activate\'}}\n' +
    '        </button>\n' +
    '        </div>\n' +
    '        <a ng-if="myMemberships.isBusinessAccountActive()" ng-click="myMemberships.openAskForLeads();"\n' +
    '           class="btn btn-primary btn-shadow btn-lg-lg">Ask for Leads</a>\n' +
    '        <a ng-if="myMemberships.isBusinessAccountActive()" ui-sref="leadRegister"\n' +
    '           class="btn btn-success btn-shadow btn-lg-lg">Register Lead</a>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="row" ng-if="!myMemberships.isBusinessAccountActive()">\n' +
    '    <div class="col-xs-12">\n' +
    '      <div class="page-empty-section text-center">\n' +
    '        <i class="fa fa-users fa-3x text-light-colour"></i>\n' +
    '        <div class="page-empty-section-text">\n' +
    '          Need leads for your business?\n' +
    '        </div>\n' +
    '        <br/>\n' +
    '        Invite people to recommend your business. You can join their pod and grow your connections. Click "Activate" to\n' +
    '        start receiving leads.\n' +
    '        <div class="page-empty-section-button visible-xs">\n' +
    '          <div ng-if="!myMemberships.isBusinessAccountActive()">\n' +
    '            <div class="trial-notification">You are eligible for the trial business subscription</div>\n' +
    '            <button class="btn btn-success btn-shadow btn-lg-lg"\n' +
    '                    ng-click="myMemberships.activateBusinessAccount()"\n' +
    '                    ng-disabled="myMemberships.isActivating">{{myMemberships.isActivating ? \'Activating ...\' : \'Activate\'}}\n' +
    '            </button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="row" ng-if="myMemberships.isBusinessAccountActive()">\n' +
    '    <div class="col-xs-12">\n' +
    '      <tabs-manager tab-id="myMemberships" tabs-config="myMemberships.tabsConfig" default-tab="memberships" tab-manager="myMemberships.tabManager"></tabs-manager>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div ng-if="myMemberships.isBusinessAccountActive()" class="row"\n' +
    '       ng-show="myMemberships.tabManager.isOpen(\'memberships\')"\n' +
    '       ng-include="\'my-memberships/memberships/memberships.tpl.html\'">\n' +
    '  </div>\n' +
    '\n' +
    '  <div ng-if="myMemberships.isBusinessAccountActive()" class="row"\n' +
    '       ng-show="myMemberships.tabManager.isOpen(\'receivedLeads\')"\n' +
    '       ng-include="\'my-memberships/received-leads/received-leads.tpl.html\'">\n' +
    '  </div>\n' +
    '\n' +
    '  <div ng-if="myMemberships.isBusinessAccountActive()" class="row"\n' +
    '       ng-show="myMemberships.tabManager.isOpen(\'rewardPrograms\')"\n' +
    '       ng-include="\'my-memberships/reward-program/reward-program.tpl.html\'">\n' +
    '  </div>\n' +
    '\n' +
    '  <div ng-if="myMemberships.isBusinessAccountActive() && myMemberships.isImportAllowed()" class="row"\n' +
    '       ng-show="myMemberships.tabManager.isOpen(\'import\')"\n' +
    '       ng-include="\'my-memberships/import/import.tpl.html\'">\n' +
    '  </div>\n' +
    '\n' +
    '  <action-toolbar class="visible-xs" at-target="secondary">\n' +
    '    <a ng-if="!myMemberships.isBusinessAccountActive()" class="no-animate fa fa-play" label="Activate"\n' +
    '       ng-click="myMembership.activateBusinessAccount()"\n' +
    '       ng-disabled="myMemberships.isActivating"><span class="tool-label">Activate</span></a>\n' +
    '    <a ng-if="myMemberships.isBusinessAccountActive()" class="no-animate fa fa-user-plus" label="Ask for Leads"\n' +
    '       ng-click="myMemberships.openAskForLeads();"><span class="tool-label">Ask for Leads</span></a>\n' +
    '    <a ng-if="myMemberships.isBusinessAccountActive()" class="no-animate fa fa-paper-plane" label="Register Lead"\n' +
    '       ui-sref="leadRegister"><span class="tool-label">Register Lead</span></a>\n' +
    '  </action-toolbar>\n' +
    '\n' +
    '\n' +
    '\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('lead/send-lead.tpl.html',
    '<div class="row" ng-if="!sendLead.selectedUser">\n' +
    '  <div class="col-xs-12">\n' +
    '    <h1 class="page-header">{{sendLead.isRegister ? \'Register Lead\' : \'Send Lead\'}}</h1>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="row">\n' +
    '  <div class="col-xs-12">\n' +
    '    <div class="page-content page-content-no-tabs">\n' +
    '\n' +
    '      <!-- Name, Surname, Email, Phone, Description -->\n' +
    '      <form name="sendLeadForm" class="send-lead-form">\n' +
    '        <ng-include src="\'lead/send-lead-form-partial.tpl.html\'" />\n' +
    '      </form>\n' +
    '\n' +
    '      <div ng-if="!sendLead.selectedUser">\n' +
    '        <div class="row form-section-title">\n' +
    '          <div class="col-xs-12">\n' +
    '            <span class="sub-title">{{sendLead.isRegister ? \'Select sender\' : \'Select recipient\'}}</span>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row form-section-description">\n' +
    '          <div class="col-xs-12">\n' +
    '            <span ng-if="!sendLead.isRegister" class="sub-title-description">Select businesses\n' +
    '              (<a style="cursor: pointer;" ng-click="sendLead.showInviteNew()">or invite new</a>)\n' +
    '              that will receive your lead:\n' +
    '            </span>\n' +
    '            <span ng-if="sendLead.isRegister" class="sub-title-description">Select user\n' +
    '              (<a style="cursor: pointer;" ng-click="sendLead.showInviteNew()">or invite new</a>)\n' +
    '              who sent you the lead above:\n' +
    '            </span>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row" ng-if="sendLead.validateRecipient && sendLead.isInvalidRecipient()">\n' +
    '          <div class="col-xs-12">\n' +
    '            <div class="input-error">Either select an existing person or invite a new one</div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <!-- Choose existing member -->\n' +
    '        <div class="row">\n' +
    '          <div class="col-xs-12">\n' +
    '            <div class="row slide-if" ng-if="!sendLead.showInviteForm">\n' +
    '              <!-- Select the recipients from the search input -->\n' +
    '              <div class="col-xs-12 col-md-6 form-group" >\n' +
    '                <search-autocomplete\n' +
    '                  control-id="{{sendLead.controlName}}-autocomplete"\n' +
    '                  placeholder="Search Recipient"\n' +
    '                  minlength="3"\n' +
    '                  text-searching="false"\n' +
    '                  text-no-results="false"\n' +
    '                  search-service="Search"\n' +
    '                  search-service-end-point="index"\n' +
    '                  remote-url-response-formatter="sendLead.searchResponseFormatter"\n' +
    '                  search-categories="sendLead.userSearchCategories"\n' +
    '                  selected-object="sendLead.onRecipientItemSelected"\n' +
    '                  focus-in="sendLead.activeSearch=\'recipients\'; sendLead.emptyResults = false;"\n' +
    '                  data-field="content.records"\n' +
    '                  search-fields="search"\n' +
    '                  title-field="value"\n' +
    '                  description-field="categoryDescription"\n' +
    '                  description-field2="timeDescription"\n' +
    '                  category-field="category"\n' +
    '                  input-name="associateUsersInputSearch"\n' +
    '                  input-class="form-control input-lg"\n' +
    '                  match-class="autocomplete-highlight"\n' +
    '                  blur-on-select="true"\n' +
    '                  has-back-button="false"\n' +
    '                  update-input-on-select="true"\n' +
    '                  is-dropdown-relative="false"\n' +
    '                  override-suggestions="true"\n' +
    '                  field-required="false"\n' +
    '                  has-category-filter="false"\n' +
    '                  clear-selected="true"\n' +
    '                  has-history="true"\n' +
    '                />\n' +
    '              </div>\n' +
    '              <!-- Show the prompt to enter the non-existing business details if results not found -->\n' +
    '              <div class="col-xs-12 form-group" ng-if="sendLead.activeSearch==\'recipients\' && sendLead.emptyResults">\n' +
    '                Seems like the business you want to send a lead to is not yet a member of MyLeadPod. You can provide the details of the business manually\n' +
    '                <a ng-click="sendLead.showInviteForm = true;" style="cursor: pointer;">here</a>.\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <!-- Choose existing member : end -->\n' +
    '\n' +
    '        <!-- Invite new member -->\n' +
    '        <div class="row form-group slide-if" ng-if="sendLead.showInviteForm">\n' +
    '          <div class="col-xs-12 col-sm-12">\n' +
    '            <div class="form-section-description">\n' +
    '              <span class="sub-title-description ng-scope">Provide the details of the person\n' +
    '                <span ng-if="!sendLead.isRegister">(s)</span> you\'d like to invite, or\n' +
    '                <a ng-click="sendLead.showSearchForm()" style="cursor: pointer;">try Search again</a>:\n' +
    '              </span>\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="row">\n' +
    '\n' +
    '              <div class="col-xs-12 col-sm-6">\n' +
    '                <!-- user name details -->\n' +
    '                <form name="newUserForm">\n' +
    '\n' +
    '                  <div class="form-section-description-function">\n' +
    '                    <span>Contact Details:</span>\n' +
    '                  </div>\n' +
    '\n' +
    '                  <!-- Name -->\n' +
    '                  <div class="row">\n' +
    '                    <div class="col-xs-12">\n' +
    '                      <div class="form-group" ng-class="{\'has-error\': (newUserForm.$submitted || newUserForm.inviteFirstName.$touched) && newUserForm.inviteFirstName.$error.required}">\n' +
    '                        <label class="sr-only" for="inviteFirstName">First name</label>\n' +
    '                        <div ng-show="newUserForm.$submitted || newUserForm.inviteFirstName.$touched">\n' +
    '                          <div class="input-error" ng-show="newUserForm.inviteFirstName.$error.required">First name is missing</div>\n' +
    '                        </div>\n' +
    '                        <div class="left-inner-addon">\n' +
    '                          <i class="fa fa-user inner-addon"></i>\n' +
    '                          <input class="form-control input-lg" type="text" name="inviteFirstName" id="inviteFirstName"\n' +
    '                                placeholder="First name" ng-model="sendLead.data.invitation.firstName" ng-required="true" />\n' +
    '                        </div>\n' +
    '                      </div>\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '\n' +
    '                  <!-- Email -->\n' +
    '                  <div class="row">\n' +
    '                    <div class="col-xs-12">\n' +
    '                      <div class="form-group" ng-class="{\'has-error\': (newUserForm.$submitted || newUserForm.inviteEmail.$touched) && (newUserForm.inviteEmail.$error.required || newUserForm.inviteEmail.$error.email)}">\n' +
    '                        <label class="sr-only" for="inviteEmail">Email</label>\n' +
    '                        <div ng-show="newUserForm.$submitted || newUserForm.inviteEmail.$touched">\n' +
    '                          <div class="input-error" ng-show="newUserForm.inviteEmail.$error.required">Email is missing</div>\n' +
    '                          <div class="input-error" ng-show="newUserForm.inviteEmail.$error.email">This is not a valid email</div>\n' +
    '                        </div>\n' +
    '                        <div class="left-inner-addon">\n' +
    '                          <i class="fa fa-envelope-o inner-addon"></i>\n' +
    '                          <input class="form-control input-lg" type="email" name="inviteEmail" id="inviteEmail" placeholder="Email"\n' +
    '                                ng-model="sendLead.data.invitation.email" ng-required="true" />\n' +
    '                        </div>\n' +
    '                      </div>\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '\n' +
    '                  <!-- Phone -->\n' +
    '                  <div class="row">\n' +
    '                    <div class="col-xs-12">\n' +
    '                      <div class="form-group" ng-class="{\'has-error\': (newUserForm.$submitted || newUserForm.phoneMobile.$touched) && (newUserForm.phoneMobile.$error.required || newUserForm.phoneMobile.$error.email)}">\n' +
    '                        <label class="sr-only" for="phoneMobile">Phone</label>\n' +
    '                        <div ng-show="newUserForm.$submitted || newUserForm.phoneMobile.$touched">\n' +
    '                          <div class="input-error" ng-show="newUserForm.phoneMobile.$error.required">Missing phone number.</div>\n' +
    '                        </div>\n' +
    '                        <div class="left-inner-addon">\n' +
    '                          <i class="fa fa-phone inner-addon"></i>\n' +
    '                          <input class="form-control input-lg" type="tel" name="phoneMobile" id="phoneMobile" placeholder="Phone Number (optional)"\n' +
    '                                 ng-required="false" ng-model="sendLead.data.invitation.phoneMobile" />\n' +
    '                        </div>\n' +
    '                      </div>\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '                </form>\n' +
    '                <!-- end of user name details -->\n' +
    '              </div>\n' +
    '\n' +
    '              <div class="col-xs-12 col-sm-6">\n' +
    '                <!-- Rewards program selection -->\n' +
    '                <div class="row">\n' +
    '                  <!-- reward option for register lead -->\n' +
    '                  <div class="col-xs-12" ng-if="sendLead.isRegister">\n' +
    '\n' +
    '                    <div class="form-section-description-function">\n' +
    '                      <span class="cursor-pointer no-outline" ng-click="sendLead.toggleMoreDetailsVisibility()">\n' +
    '                        Change reward agreement (more details)\n' +
    '                        <i class="detail-arrow fa fa-chevron-down" ng-class="{\'fa-chevron-down\': !sendLead.detailsVisible, \'fa-chevron-up\': sendLead.detailsVisible}"></i>\n' +
    '                      </span>\n' +
    '                    </div>\n' +
    '                    <div class="slide-css" ng-class="{slide: sendLead.detailsVisible}">\n' +
    '                      <reward-selector user="sendLead.user" has-monthly-subscription="false" on-change="sendLead.onRewardSelectionChange(formData, isFormValid)"></reward-selector>\n' +
    '                    </div>\n' +
    '\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '                <!-- Rewards program selection : end -->\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <button class="btn btn-primary" ng-if="!sendLead.isRegister"\n' +
    '                    ng-disabled="newUserForm.$invalid || (!sendLead.isRewardsFormValid || !sendLead.isValidRecipient())"\n' +
    '                    ng-click="sendLead.addNewRecipient(newUserForm)">Add to Recipients</button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <!-- Invite New Member : end -->\n' +
    '\n' +
    '        <!-- Recipients list -->\n' +
    '        <div class="row">\n' +
    '          <div class="col-xs-12">\n' +
    '            <div class="pending-overlay-light" ng-if="sendLead.searchingRecipient">\n' +
    '              <span class="fa fa-2x fa-circle-o-notch fa-spin-2x"></span>\n' +
    '            </div>\n' +
    '            <div class="lead-recipient-list">\n' +
    '              <span ng-hide="sendLead.selectedUsers.length > 0">Recipients</span>\n' +
    '              <div ng-repeat="user in sendLead.selectedUsers" class="lead-recipient">\n' +
    '                {{user.firstName}} {{user.lastName}} <a class="send-lead-remove-user cursor-pointer" ng-click="sendLead.removeUser($index)"><i class="fa fa-times"></i></a>\n' +
    '              </div>\n' +
    '              <div ng-repeat="user in sendLead.invitations" class="lead-recipient">\n' +
    '                {{user.firstName}} <span><i class="fa fa-user"></i> new</span> <a class="send-lead-remove-user cursor-pointer" ng-click="sendLead.removeNewRecipient($index)"><i class="fa fa-times"></i></a>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row form-section-body">\n' +
    '        <div class="col-xs-6 form-group">\n' +
    '          <button type="button" class="btn btn-default btn-lg btn-block"\n' +
    '                  ng-disabled="sendLead.isLoading" ng-click="sendLead.cancel()">\n' +
    '            Cancel\n' +
    '          </button>\n' +
    '        </div>\n' +
    '        <div class="col-xs-6 form-group">\n' +
    '          <button ng-if="!sendLead.isRegister" type="button" class="btn btn-primary btn-lg btn-block"\n' +
    '                  ng-disabled="sendLead.isLoading || sendLeadForm.$invalid || (sendLead.invitations.length === 0 && sendLead.selectedUsers.length === 0) || !sendLead.isValidName() || !sendLead.isValidContact()" ng-click="sendLead.send()">\n' +
    '\n' +
    '            {{sendLead.isLoading ? \'Loading ...\' : \'Send Lead\'}}\n' +
    '          </button>\n' +
    '          <button ng-if="sendLead.isRegister" type="button" class="btn btn-primary btn-lg btn-block"\n' +
    '            ng-disabled="sendLeadForm.$invalid || sendLead.isLoading || !sendLead.isValidRecipient() || !sendLead.isValidName() || !sendLead.isValidContact()" ng-click="sendLead.register()">\n' +
    '            {{sendLead.isLoading ? \'Registering ...\' : \'Register Lead\'}}\n' +
    '          </button>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('lead/send-lead-modal.tpl.html',
    '<div class="modal-content-container">\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-12">\n' +
    '        <div class="row">\n' +
    '          <form name="sendLeadForm" class="send-lead-form">\n' +
    '            <ng-include src="\'lead/send-lead-form-partial.tpl.html\'" />\n' +
    '          </form>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="row modal-content-buttons">\n' +
    '    <div class="col-xs-12 text-right">\n' +
    '      <button type="button" class="btn btn-default"\n' +
    '              ng-disabled="sendLead.isLoading" ng-click="sendLead.cancel()">\n' +
    '        Cancel\n' +
    '      </button>\n' +
    '      <button ng-if="!sendLead.isRegister" type="button" class="btn btn-primary"\n' +
    '              ng-disabled="sendLeadForm.$invalid || sendLead.isLoading || !sendLead.isValidRecipient() || !sendLead.isValidName() || !sendLead.isValidContact()" ng-click="sendLead.send()">\n' +
    '        {{sendLead.isLoading ? \'Sending ...\' : \'Send Lead\'}}\n' +
    '      </button>\n' +
    '      <button ng-if="sendLead.isRegister" type="button" class="btn btn-primary"\n' +
    '              ng-disabled="sendLeadForm.$invalid || sendLead.isLoading || !sendLead.isValidRecipient() || !sendLead.isValidName() || !sendLead.isValidContact()" ng-click="sendLead.register()">\n' +
    '        {{sendLead.isLoading ? \'Registering ...\' : \'Register Lead\'}}\n' +
    '      </button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('lead/send-lead-form-partial.tpl.html',
    '<div class="row">\n' +
    '  <div class="col-xs-12">\n' +
    '    <span class="sub-title">Lead details</span>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="row form-section-description">\n' +
    '  <div ng-if="!sendLead.isRegister" class="col-xs-12">\n' +
    '    <span ng-show="sendLead.selectedUser || (sendLead.selectedUsers && sendLead.selectedUsers.length == 1)" class="sub-title-description">Provide the details of the person that this business should get in touch with. </span>\n' +
    '    <span ng-show="!sendLead.selectedUser && (sendLead.selectedUsers && sendLead.selectedUsers.length > 1)" class="sub-title-description">Provide the details of the person that these businesses should get in touch with. </span>\n' +
    '    <span ng-show="!sendLead.selectedUser && (!sendLead.selectedUsers || (sendLead.selectedUsers && sendLead.selectedUsers.length == 0))" class="sub-title-description">Provide the details of the person that the businesses should get in touch with. </span>\n' +
    '    <span>Try to search for the person\'s contact details to pre-fill the form automatically, or use the form to manually enter the required information.</span>\n' +
    '  </div>\n' +
    '  <div ng-if="sendLead.isRegister" class="col-xs-12">\n' +
    '    <span class="sub-title-description">Provide the details of the customer this lead was related to:</span>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '\n' +
    '<div class="row">\n' +
    '  <div class="col-xs-12 col-sm-12 col-md-6 form-group form-section-description">\n' +
    '    <search-autocomplete\n' +
    '      control-id="end-user-autocomplete"\n' +
    '      placeholder="Search"\n' +
    '      minlength="3"\n' +
    '      clear-selected="false"\n' +
    '      text-searching="false"\n' +
    '      text-no-results="false"\n' +
    '\n' +
    '      search-service="Search"\n' +
    '      search-service-end-point="index"\n' +
    '      remote-url-response-formatter="sendLead.searchResponseFormatter"\n' +
    '      search-categories="sendLead.searchCategories"\n' +
    '      selected-object="sendLead.onSearchItemSelected"\n' +
    '      focus-in="sendLead.activeSearch=\'clients\'; sendLead.emptyResults = false;"\n' +
    '\n' +
    '      data-field="content.records"\n' +
    '\n' +
    '      search-fields="search"\n' +
    '      title-field="value"\n' +
    '      description-field="categoryDescription"\n' +
    '      description-field2="timeDescription"\n' +
    '      category-field="category"\n' +
    '\n' +
    '      input-name="associateUsersInputSearch"\n' +
    '      input-class="form-control input-lg"\n' +
    '      match-class="autocomplete-highlight"\n' +
    '      blur-on-select="true"\n' +
    '      has-back-button="false"\n' +
    '      update-input-on-select="true"\n' +
    '\n' +
    '      is-dropdown-relative="false"\n' +
    '\n' +
    '      override-suggestions="true"\n' +
    '      field-required="false"\n' +
    '      has-category-filter="false"\n' +
    '      has-history="true"\n' +
    '    />\n' +
    '    <div class="input-error slide-if" ng-if="sendLead.activeSearch==\'clients\' && sendLead.emptyResults">No results were returned, please enter the person\'s details manually</div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="row form-section-body">\n' +
    '\n' +
    '  <div class="pending-overlay-light" ng-if="sendLead.searchingUser">\n' +
    '    <span class="fa fa-2x fa-circle-o-notch fa-spin-2x"></span>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="col-xs-12 col-sm-6">\n' +
    '    <div class="form-group" ng-class="{\'has-error\': (sendLeadForm.$submitted || (sendLeadForm.firstName.$touched && sendLeadForm.lastName.$touched)) && !sendLead.isValidName()}">\n' +
    '      <label class="sr-only" for="firstName">First name</label>\n' +
    '      <div ng-show="sendLeadForm.$submitted || (sendLeadForm.firstName.$touched && sendLeadForm.lastName.$touched)">\n' +
    '        <div class="input-error" ng-show="!sendLead.isValidName()">At least one of first name or last name is required</div>\n' +
    '      </div>\n' +
    '      <div class="left-inner-addon">\n' +
    '        <i class="fa fa-user inner-addon"></i>\n' +
    '        <input class="form-control input-lg" type="text" name="firstName" id="firstName"\n' +
    '               placeholder="First name" ng-model="sendLead.data.firstName" />\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="col-xs-12 col-sm-6">\n' +
    '    <div class="form-group" ng-class="{\'has-error\': (sendLeadForm.$submitted || (sendLeadForm.firstName.$touched && sendLeadForm.lastName.$touched)) && !sendLead.isValidName()}">\n' +
    '      <label class="sr-only" for="lastName">Last name</label>\n' +
    '      <div class="left-inner-addon">\n' +
    '        <i class="fa fa-user inner-addon"></i>\n' +
    '        <input class="form-control input-lg" type="text" name="lastName" id="lastName"\n' +
    '               placeholder="Last name" ng-model="sendLead.data.lastName" />\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="col-xs-12 col-sm-6">\n' +
    '    <div class="form-group" ng-class="{\'has-error\': (sendLeadForm.$submitted || (sendLeadForm.email.$touched && sendLeadForm.phone.$touched)) && (!sendLead.isValidContact() || sendLeadForm.email.$error.email)}">\n' +
    '      <label class="sr-only" for="email">Email</label>\n' +
    '      <div ng-show="sendLeadForm.$submitted || (sendLeadForm.email.$touched && sendLeadForm.phone.$touched)">\n' +
    '        <div class="input-error" ng-show="!sendLead.isValidContact() && !sendLeadForm.email.$error.email">At least one of phone or valid email address is required</div>\n' +
    '        <div class="input-error" ng-show="sendLeadForm.email.$error.email">Invalid email address</div>\n' +
    '      </div>\n' +
    '      <div class="left-inner-addon">\n' +
    '        <i class="fa fa-envelope-o inner-addon"></i>\n' +
    '        <input class="form-control input-lg" type="email" name="email" id="email" placeholder="Email"\n' +
    '               ng-model="sendLead.data.email" />\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="col-xs-12 col-sm-6">\n' +
    '    <div class="form-group" ng-class="{\'has-error\': (sendLeadForm.$submitted || (sendLeadForm.email.$touched && sendLeadForm.phone.$touched)) && !sendLead.isValidContact()}">\n' +
    '      <label class="sr-only" for="phone">Phone</label>\n' +
    '      <div class="left-inner-addon">\n' +
    '        <i class="fa fa-phone inner-addon"></i>\n' +
    '        <input class="form-control input-lg" type="text" name="phone" id="phone"\n' +
    '               placeholder="Phone" ng-model="sendLead.data.phone" />\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="col-xs-12">\n' +
    '    <div class="" ng-class="{\'has-error\': (sendLeadForm.$submitted || sendLeadForm.details.$touched) && sendLeadForm.details.$error.required}">\n' +
    '      <label class="sr-only" for="details">Note</label>\n' +
    '      <div ng-show="sendLeadForm.$submitted || sendLeadForm.details.$touched">\n' +
    '        <div class="input-error" ng-show="sendLeadForm.details.$error.required">Missing note</div>\n' +
    '      </div>\n' +
    '      <div class="left-inner-addon">\n' +
    '        <i class="fa fa-comment-o inner-addon"></i>\n' +
    '        <textarea class="form-control input-lg lead-note-input" name="details" id="details" placeholder="Note"\n' +
    '                  ng-required="true" ng-model="sendLead.data.details" msd-elastic></textarea>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('lead/lead-view.tpl.html',
    '<div>\n' +
    '  <!-- lEad detail view -->\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('lead/lead-detail.tpl.html',
    '<div class="modal-content-container" id="lead-detail-container">\n' +
    '  <div class="row lead-info">\n' +
    '    <div class="col-xs-12">\n' +
    '\n' +
    '      <div class="row modal-content-title">\n' +
    '        <div class="col-xs-6 lead-detail-header">\n' +
    '          <span class="sub-title">\n' +
    '            {{leadDetail.leadType == \'sent\' ? \'Sent Lead\' : \'Received Lead\'}}\n' +
    '          </span>\n' +
    '        </div>\n' +
    '        <div class="col-xs-6 col-sm-6 text-right">\n' +
    '          <div class="lead-detail-date">\n' +
    '            <i class="fa fa-calendar-o"></i>{{leadDetail.lead.dateSent | date:\'d MMM yyyy\'}}\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row">\n' +
    '        <div class="col-xs-12">\n' +
    '          <tabs-manager tab-id="leadDetail"\n' +
    '                        block-xs-relocation="true"\n' +
    '                        on-change="leadDetail.onTabChange(activeTab)"\n' +
    '                        tabs-config="leadDetail.tabsConfig"\n' +
    '                        default-tab="{{leadDetail.tabName}}"\n' +
    '                        tab-manager="leadDetail.tabManager"></tabs-manager>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <!-- User details -->\n' +
    '      <div class="row">\n' +
    '        <div class="col-xs-12 tab-content-header">\n' +
    '          <div class="lead-detail-row lead-detail-user-row"\n' +
    '               ng-class="{\'w-background\': leadDetail.tabManager.isOpen(\'messages\')}">\n' +
    '            <div class="lead-detail-user">\n' +
    '              <div class="lead-detail-user-desc no-animate" ng-if="leadDetail.tabManager.isOpen(\'details\')">\n' +
    '                <!--<i class="fa fa-user-circle-o"></i>-->\n' +
    '                <span>{{leadDetail.leadType === \'sent\' ? \'To\' : \'From\'}}</span>\n' +
    '              </div>\n' +
    '              <div style="width:100%; position: relative;">\n' +
    '                <!-- ng-class="{\'table-user-detail-opaque\': leadDetail.tabManager.isOpen(\'messages\')}"-->\n' +
    '                <!--class="{{leadDetail.getUserDetailClass()}}"-->\n' +
    '                <table-user-details\n' +
    '                  user="leadDetail.getUser()"\n' +
    '                  name-only="true"\n' +
    '                  add-class="leadDetail.getUserDetailClass()"\n' +
    '                  is-link="true"\n' +
    '                  has-details="true">\n' +
    '                </table-user-details>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div class="lead-detail-reward no-animate" ng-if="leadDetail.tabManager.isOpen(\'details\') && !leadDetail.lead.isCoOwner && leadDetail.leadType === \'received\' && leadDetail.lead.rewardCode">\n' +
    '              <div class="lead-detail-user-desc">\n' +
    '                <span>Reward</span>\n' +
    '              </div>\n' +
    '              <div class="lead-detail-val">\n' +
    '                <div>\n' +
    '                  #{{leadDetail.lead.rewardCode.rewardCode}}\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <!-- Lead Details -->\n' +
    '      <div class="row no-animate ng-hide" ng-show="leadDetail.tabManager.isOpen(\'details\')">\n' +
    '        <div class="col-xs-12">\n' +
    '          <div class="tab-content-w-header">\n' +
    '            <div class="lead-detail lead-detail-split lead-detail-row">\n' +
    '              <div class="lead-detail-left" >\n' +
    '                <div class="lead-detail-desc">\n' +
    '                  <!--<i class="fa fa-comment-o"></i>-->\n' +
    '                  <span>Details</span>\n' +
    '                </div>\n' +
    '                <div class="lead-detail-val">\n' +
    '                  {{leadDetail.lead.details}}\n' +
    '                </div>\n' +
    '              </div>\n' +
    '\n' +
    '              <div class="lead-detail-right">\n' +
    '                <div class="lead-detail-desc">\n' +
    '                  <!--<i class="fa fa-comment-o"></i>-->\n' +
    '                  <span>Contact</span>\n' +
    '                </div>\n' +
    '                <div class="lead-detail-val">\n' +
    '                  <div class="lead-detail-row">\n' +
    '                    <div class="lead-detail-desc">\n' +
    '                      <i class="fa-lead fa fa-user"></i>\n' +
    '                    </div>\n' +
    '                    <div class="lead-detail-val">\n' +
    '                      <div class="lead-detail-split-top">\n' +
    '                        {{leadDetail.lead.firstName}} {{leadDetail.lead.lastName}}\n' +
    '                      </div>\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '\n' +
    '                  <div class="lead-detail-row" ng-if="leadDetail.lead.phone">\n' +
    '                    <div class="lead-detail-desc">\n' +
    '                      <i class="fa-lead fa fa-phone"></i>\n' +
    '                    </div>\n' +
    '                    <div class="lead-detail-val">\n' +
    '                      <a href="tel:{{leadDetail.lead.phone}}">{{leadDetail.lead.phone}}</a>\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '\n' +
    '                  <div class="lead-detail-row" ng-if="leadDetail.lead.email">\n' +
    '                    <div class="lead-detail-desc">\n' +
    '                      <i class="fa-lead fa fa-envelope-o"></i>\n' +
    '                    </div>\n' +
    '                    <div class="lead-detail-val">\n' +
    '                      <a href="mailto:{{leadDetail.lead.email}}">{{leadDetail.lead.email}}</a>\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="lead-detail lead-detail-row reward-code-user"\n' +
    '                 ng-if="!leadDetail.lead.isCoOwner && leadDetail.leadType === \'received\'">\n' +
    '              <div class="lead-detail-desc">\n' +
    '                <span>Sharing</span>\n' +
    '              </div>\n' +
    '              <div class="lead-detail-val" style="width:100%;">\n' +
    '                <span ng-if="!leadDetail.lead.coOwner.id" class="">This lead is not shared with another user</span>\n' +
    '                <span ng-if="leadDetail.lead.coOwner.id" class="">\n' +
    '                  This lead is shared with {{leadDetail.lead.coOwner.firstName}} {{leadDetail.lead.coOwner.lastName}}\n' +
    '                </span>\n' +
    '                <span class="no-animate">\n' +
    '                  <a ng-if="leadDetail.lead.coOwner.id"\n' +
    '                     ng-click="leadDetail.userShareSectionVisible = !leadDetail.userShareSectionVisible"\n' +
    '                     class="no-animate"\n' +
    '                     style="width: 50px; display: inline-block; text-align: center;cursor: pointer;">\n' +
    '                    <span ng-if="!leadDetail.userShareSectionVisible"><i class="fa fa-chevron-down" /></span>\n' +
    '                    <span ng-if="leadDetail.userShareSectionVisible"><i class="fa fa-chevron-up" /></span>\n' +
    '                  </a>\n' +
    '                  <a class="action-icon-link cursor-pointer"\n' +
    '                     ng-if="!leadDetail.lead.coOwner.id"\n' +
    '                     ng-click="leadDetail.editUserShareVisible=true; leadDetail.userShareSectionVisible=true;"\n' +
    '                     uib-tooltip="Click to edit"\n' +
    '                     tooltip-placement="bottom">\n' +
    '                    <i class="fa fa-user-plus"></i>\n' +
    '                  </a>\n' +
    '                </span>\n' +
    '\n' +
    '                <!-- here is the collapsible content as we don\'t want to pollute the view immediately -->\n' +
    '                <div class="slide-if" ng-if="leadDetail.userShareSectionVisible">\n' +
    '                  <div class="shared-user" ng-if="leadDetail.lead.coOwner.id">\n' +
    '                    <div class="shared-user-info" >\n' +
    '                      <table-user-details user="leadDetail.lead.coOwner"></table-user-details>\n' +
    '                    </div>\n' +
    '\n' +
    '                    <div class="shared-user-controls">\n' +
    '                      <button class="btn-primary btn-sm"\n' +
    '                              ng-click="leadDetail.editUserShareVisible = true;"\n' +
    '                              uib-tooltip="Click to edit"\n' +
    '                              tooltip-placement="top">Edit</button>\n' +
    '                      <button class="btn-sm btn-danger no-animate"\n' +
    '                              ng-click="leadDetail.editUserShareVisible = false; leadDetail.removeSharing();"\n' +
    '                              uib-tooltip="Click to cancel sharing"\n' +
    '                              tooltip-placement="top">\n' +
    '                        <span>Remove</span>\n' +
    '                        <span ng-if="leadDetail.isRemovingShare" class="pending-overlay">\n' +
    '                          <i class="fa fa-spin-2x fa-circle-o-notch" />\n' +
    '                        </span>\n' +
    '                      </button>\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '                  <div class="row" ng-if="leadDetail.editUserShareVisible" style="margin-top: 15px;">\n' +
    '                    <div class="col-xs-12 form-group">\n' +
    '                      <search-autocomplete\n' +
    '                        control-id="share-with-autocomplete"\n' +
    '                        placeholder="Search Business"\n' +
    '                        minlength="3"\n' +
    '                        clear-selected="false"\n' +
    '                        text-searching="false"\n' +
    '                        text-no-results="false"\n' +
    '\n' +
    '                        search-service="Search"\n' +
    '                        search-service-end-point="index"\n' +
    '                        remote-url-response-formatter="leadDetail.searchResponseFormatter"\n' +
    '\n' +
    '                        selected-object="leadDetail.onSearchItemSelected"\n' +
    '\n' +
    '                        search-fields="search"\n' +
    '                        title-field="value"\n' +
    '                        description-field="categoryDescription"\n' +
    '                        description-field2="timeDescription"\n' +
    '                        category-field="category"\n' +
    '\n' +
    '                        input-name="\'share-with-control\'"\n' +
    '                        input-class="form-control input-lg"\n' +
    '                        match-class="autocomplete-highlight"\n' +
    '                        blur-on-select="true"\n' +
    '                        has-back-button="false"\n' +
    '                        update-input-on-select="true"\n' +
    '\n' +
    '                        override-suggestions="true"\n' +
    '                        search-categories="leadDetail.searchCategories"\n' +
    '                        field-required="false"\n' +
    '                        has-history="true" />\n' +
    '                    </div>\n' +
    '                    <div class="col-xs-12 form-group">\n' +
    '                      <div style="position: relative;">\n' +
    '                        <div class="pending-overlay-light" ng-if="leadDetail.isLoadingUserData">\n' +
    '                          <span class="fa fa-circle-o-notch fa-spin-2x"></span>\n' +
    '                        </div>\n' +
    '                        You will share this lead with:\n' +
    '                        <div class="share-with-result">\n' +
    '                          <div>\n' +
    '                            <div ng-if="leadDetail.shareWithProfile" style="flex-grow: 1;">\n' +
    '                              <table-user-details user="leadDetail.shareWithProfile"\n' +
    '                                                  is-link="false"\n' +
    '                                                  has-details="true"\n' +
    '                                                  add-class="\'full-width-user-details\'"/>\n' +
    '                            </div>\n' +
    '                          </div>\n' +
    '                          <button type="button" class="btn btn-primary"\n' +
    '                                  ng-disabled="leadDetail.isSavingUserShare || !leadDetail.userShareEmail || !leadDetail.shareWithProfile"\n' +
    '                                  ng-click="leadDetail.saveUserShare()">{{leadDetail.isSavingUserShare ? \'Saving ...\' : \'Save\'}}</button>\n' +
    '                        </div>\n' +
    '                      </div>\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '\n' +
    '          <div class="action-bar" ng-if="(leadDetail.lead.status === \'open\' || leadDetail.lead.status === \'new\') && leadDetail.tabManager.isOpen(\'details\')">\n' +
    '\n' +
    '            <div class="action-buttons"\n' +
    '                 ng-class="{\'single\': leadDetail.leadType !== \'received\' && !leadDetail.showAcknowledgeButton() && !leadDetail.showResendButton(), \'multi\': leadDetail.leadType !== \'received\'}">\n' +
    '              <button class="btn btn-primary no-animate" ng-click="leadDetail.acknowledge()" ng-if="leadDetail.showAcknowledgeButton()" ng-disabled="leadDetail.isAcknowledging">\n' +
    '                {{leadDetail.isAcknowledging ? \'Loading ...\' : \'Acknowledge\'}}\n' +
    '              </button>\n' +
    '              <button class="btn btn-primary no-animate" ng-click="leadDetail.resend()" ng-if="leadDetail.showResendButton()" ng-disabled="leadDetail.isSending">\n' +
    '                {{leadDetail.isSending ? \'Sending ...\' : \'Resend\'}}\n' +
    '              </button>\n' +
    '              <button class="btn btn-primary no-animate" ng-click="leadDetail.forward()">\n' +
    '                Forward\n' +
    '              </button>\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="btn-group page-filter close-lead-dropdown" uib-dropdown\n' +
    '                 ng-if="leadDetail.leadType === \'received\' && !leadDetail.lead.isCoOwner"\n' +
    '                 style>\n' +
    '              <button id="close-lead-button" type="button" class="btn" uib-dropdown-toggle>\n' +
    '                Close lead <span class="caret"></span>\n' +
    '              </button>\n' +
    '              <ul class="dropdown-menu" uib-dropdown-menu role="menu" aria-labelledby="close-lead-button">\n' +
    '                <li role="menuitem"><a ng-click="leadDetail.closeLead(\'successful\')">Successful</a></li>\n' +
    '                <li role="menuitem"><a ng-click="leadDetail.closeLead(\'unsuccessful\')">Unsuccessful</a></li>\n' +
    '              </ul>\n' +
    '            </div>\n' +
    '\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="row">\n' +
    '            <div class="col-xs-12">\n' +
    '              <uib-alert ng-repeat="alert in leadDetail.alerts.getList()" type="{{alert.type}}" close="leadDetail.alerts.remove($index)">{{alert.message}}</uib-alert>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <!-- Lead Messages -->\n' +
    '      <div class="row messaging-container no-animate ng-hide" ng-show="leadDetail.tabManager.isOpen(\'messages\')">\n' +
    '        <div class="col-xs-12">\n' +
    '          <div class="tab-content-w-header">\n' +
    '            <messaging-form class="row" msg-request-params="{\'idLead\': leadDetail.lead.id}" msg-recipient="leadDetail.leadType == \'sent\' ? leadDetail.lead.recipient : leadDetail.lead.sender"></messaging-form>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="row modal-content-buttons">\n' +
    '    <div class="col-xs-12 text-right">\n' +
    '      <button class="btn btn-default" ng-click="leadDetail.close()">\n' +
    '        Close\n' +
    '      </button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('lead/close-lead.tpl.html',
    '<div class="modal-content-container close-lead">\n' +
    '    <div class="row">\n' +
    '      <div ng-form="closeLeadForm" style="position:relative;">\n' +
    '\n' +
    '        <!-- Successful Lead -->\n' +
    '        <div class="col-xs-12" ng-if="closeLead.isSuccessful()">\n' +
    '          <div class="row">\n' +
    '            <div class="col-xs-12 lead-detail-header modal-content-title">\n' +
    '              <span class="sub-title">\n' +
    '                Successful lead reward payment\n' +
    '              </span>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="row">\n' +
    '            <div class="col-xs-12">\n' +
    '              <uib-alert ng-repeat="alert in closeLead.alerts.getList()" type="{{alert.type}}" close="closeLead.alerts.remove($index)">{{alert.message}}</uib-alert>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="row">\n' +
    '            <div class="col-xs-12">\n' +
    '              <div class="steps">\n' +
    '                <div class="step" data-step-id="0">\n' +
    '                  Select Reward\n' +
    '                </div>\n' +
    '                <div class="step-separator" data-after-step-id="1">\n' +
    '                  <span class="fa fa-chevron-right"></span>\n' +
    '                </div>\n' +
    '\n' +
    '                <div class="step" data-step-id="1">\n' +
    '                  Additional Reward\n' +
    '                </div>\n' +
    '\n' +
    '                <div class="step-separator no-animate"\n' +
    '                     data-after-step-id="2"\n' +
    '                     ng-show="closeLead.contract.pricingOptions.length >= 1 || closeLead.data.bonusReward">\n' +
    '                  <span class="fa fa-chevron-right"></span>\n' +
    '                </div>\n' +
    '                <div class="step no-animate"\n' +
    '                     data-step-id="2"\n' +
    '                     ng-show="closeLead.contract.pricingOptions.length >= 1 || closeLead.data.bonusReward">\n' +
    '                  Review and Payment\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="row">\n' +
    '            <div class="col-xs-12">\n' +
    '              <div class="stepped-wrapper" content-slider>\n' +
    '                <div class="stepped-container">\n' +
    '                  <div class="step-content" data-step-id="0">\n' +
    '\n' +
    '                    <!-- Reward Program type of Rewards -->\n' +
    '                    <div ng-if="closeLead.contract.pricingOptions.length >= 1">\n' +
    '                      <div class="row">\n' +
    '                        <div class="col-xs-12 form-group">\n' +
    '                          Select a reward for this lead:\n' +
    '                        </div>\n' +
    '                      </div>\n' +
    '                      <div class="row">\n' +
    '                        <div class="col-xs-12">\n' +
    '                          <div class="reward-system-selector form-group"\n' +
    '                               ng-repeat="option in closeLead.contract.pricingOptions"\n' +
    '                               ng-class="{active: closeLead.selectedPricingOption === option}"\n' +
    '                               ng-click="closeLead.onPricingOptionChange(closeLeadForm, $index)">\n' +
    '\n' +
    '                              <input type="radio"\n' +
    '                                     name="idPricingOption"\n' +
    '                                     id="idPricingOption_{{$index}}"\n' +
    '                                     value="{{option.id}}"\n' +
    '                                     ng-model="closeLead.data.idPricingOption"\n' +
    '                                     ng-required="true" />\n' +
    '\n' +
    '                              <label class="radio-label" for="idPricingOption_{{$index}}">\n' +
    '                                {{option.name}} - {{option.successfulLeadPrice | currency:\'$\':0}}\n' +
    '                              </label>\n' +
    '                          </div>\n' +
    '                        </div>\n' +
    '                      </div>\n' +
    '                      <div class="step-buttons" data-active-step-id="1">\n' +
    '                        <button class="btn btn-primary" data-step-to="1" ng-disabled="closeLead.isLoading || closeLead.calculating || !closeLead.data.idPricingOption">\n' +
    '                          Next\n' +
    '                        </button>\n' +
    '                      </div>\n' +
    '                    </div>\n' +
    '\n' +
    '                    <!-- Subscription-based Rewards -->\n' +
    '                    <div ng-if="closeLead.contract.contractType.toLowerCase() === \'subscription\'">\n' +
    '                      <div class="row">\n' +
    '                        <div class="col-xs-12 control-description">\n' +
    '                          The reward contract between you and <strong>{{closeLead.lead.sender.fullName}}</strong> is based on SUBSCRIPTION model.\n' +
    '                        </div>\n' +
    '                        <div class="col-xs-12 control-description">\n' +
    '                          This user is rewarded periodically an amount of:\n' +
    '                        </div>\n' +
    '                      </div>\n' +
    '                      <div class="row">\n' +
    '                        <div class="col-xs-12">\n' +
    '                          <div class="reward-system-selector form-group active">\n' +
    '                            <div class="col-xs-12">\n' +
    '                              <input type="radio" id="subscriptionValue" name="subscriptionValue" checked />\n' +
    '                              <label class="radio-label" for="subscriptionValue">\n' +
    '                                {{closeLead.contract.subscriptionPrice | currency:\'$\':0}}\n' +
    '                              </label>\n' +
    '                            </div>\n' +
    '                          </div>\n' +
    '                        </div>\n' +
    '                      </div>\n' +
    '                      <div class="step-buttons" data-active-step-id="1">\n' +
    '                        <button class="btn btn-primary" data-step-to="1">\n' +
    '                          Next\n' +
    '                        </button>\n' +
    '                      </div>\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '\n' +
    '                  <div class="step-content" data-step-id="1">\n' +
    '                    <div>\n' +
    '                      <div class="row form-group">\n' +
    '                        <div class="col-xs-12 control-description">\n' +
    '                          If you\'d like to pay the extra reward, enter the amount below:\n' +
    '                        </div>\n' +
    '                        <div class="col-xs-12" ng-class="{\'has-error\': (closeLeadForm.$submitted || closeLeadForm.bonusReward.$touched) &&\n' +
    '                        (closeLeadForm.bonusReward.$error.number || closeLeadForm.bonusReward.$error.min)}">\n' +
    '                          <label class="sr-only" for="bonusReward">Additional reward</label>\n' +
    '                          <div ng-show="closeLeadForm.$submitted || closeLeadForm.bonusReward.$touched">\n' +
    '                            <div class="input-error" ng-show="closeLeadForm.bonusReward.$error.number">Invalid optional reward\n' +
    '                            </div>\n' +
    '                            <div class="input-error" ng-show="closeLeadForm.bonusReward.$error.min">Optional reward must not be negative\n' +
    '                            </div>\n' +
    '                          </div>\n' +
    '                          <div class="left-inner-addon input-group" style="width: 100%">\n' +
    '                            <i class="fa fa-dollar inner-addon"></i>\n' +
    '                            <input class="form-control input-lg" type="number" name="bonusReward" id="bonusReward"\n' +
    '                                   placeholder="Additional reward (optional)"\n' +
    '                                   ng-required="false" ng-model="closeLead.data.bonusReward" min="0"\n' +
    '                                   ng-change="closeLead.onBonusRewardChange(closeLeadForm)"\n' +
    '                            />\n' +
    '                          </div>\n' +
    '                        </div>\n' +
    '                      </div>\n' +
    '                      <div class="row form-group">\n' +
    '                        <div class="col-xs-12 control-description">\n' +
    '                          Provide any additional feedback / comment / appraisal to {{closeLead.lead.sender.fullName}}:\n' +
    '                        </div>\n' +
    '                        <div class="col-xs-12" ng-class="{\'has-error\': (closeLeadForm.$submitted || closeLeadForm.feedback.$touched) && closeLeadForm.feedback.$error.required}">\n' +
    '                          <label class="sr-only" for="feedback">Please provide feedback for this lead</label>\n' +
    '                          <div ng-show="closeLeadForm.$submitted || closeLeadForm.feedback.$touched">\n' +
    '                            <div class="input-error" ng-show="closeLeadForm.feedback.$error.required">Missing feedback</div>\n' +
    '                          </div>\n' +
    '                          <div class="left-inner-addon">\n' +
    '                            <i class="fa fa-comment-o inner-addon"></i>\n' +
    '                            <textarea class="form-control input-lg lead-note-input" name="feedback" id="feedback" placeholder="Please provide feedback for this lead"\n' +
    '                                      ng-required="true" ng-model="closeLead.data.feedback"></textarea>\n' +
    '                          </div>\n' +
    '                        </div>\n' +
    '                      </div>\n' +
    '                    </div>\n' +
    '\n' +
    '                    <!-- buttons if the rewards are based on reward options -->\n' +
    '                    <div class="step-buttons"\n' +
    '                         data-active-step-id="2"\n' +
    '                         ng-if="closeLead.contract.pricingOptions.length >= 1">\n' +
    '                      <button class="btn btn-primary" data-step-to="0">\n' +
    '                        Back\n' +
    '                      </button>\n' +
    '                      <button class="btn btn-primary no-animate"\n' +
    '                              data-step-to="2"\n' +
    '                              ng-disabled="closeLead.isLoading || closeLead.calculating"\n' +
    '                              ng-click="closeLead.calculateTotal($event)">\n' +
    '                        Next\n' +
    '                      </button>\n' +
    '                    </div>\n' +
    '\n' +
    '                    <!-- buttons if the rewards are based on subscription model -->\n' +
    '                    <div class="step-buttons no-animate"\n' +
    '                         data-active-step-id="2"\n' +
    '                         ng-if="closeLead.contract.contractType.toLowerCase() === \'subscription\'">\n' +
    '                      <button class="btn btn-primary" data-step-to="0">\n' +
    '                        Back\n' +
    '                      </button>\n' +
    '                      <button class="btn btn-primary no-animate"\n' +
    '                              data-step-to="2"\n' +
    '                              ng-disabled="closeLead.isLoading || closeLead.calculating"\n' +
    '                              ng-click="closeLead.calculateTotal($event)"\n' +
    '                              ng-show="closeLead.data.bonusReward">\n' +
    '                        Next\n' +
    '                      </button>\n' +
    '                      <button ng-show="!closeLead.data.bonusReward"\n' +
    '                              class="btn btn-primary no-animate"\n' +
    '                              ng-disabled="closeLeadForm.$invalid || closeLead.isLoading"\n' +
    '                              ng-click="closeLead.close()">\n' +
    '                        {{closeLead.isLoading ? \'Loading ...\' : \'Close and Pay\'}}\n' +
    '                      </button>\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '\n' +
    '                  <div class="step-content" data-step-id="2">\n' +
    '                    <div>\n' +
    '                      <div class="form-group" style="min-height: 150px; position: relative;">\n' +
    '                        <div class="pending-overlay-light" ng-if="closeLead.calculating">\n' +
    '                          <span class="fa fa-2x fa-circle-o-notch fa-spin-2x"></span>\n' +
    '                        </div>\n' +
    '                        <div class="close-lead-invoice">\n' +
    '                          <div class="row">\n' +
    '                            <div class="col-xs-12 form-group">\n' +
    '                              Payment Review:\n' +
    '                            </div>\n' +
    '                          </div>\n' +
    '                          <div class="row" ng-if="closeLead.showPriceBreakdown() && closeLead.chargeForSuccessfulLead()">\n' +
    '                            <div class="col-xs-6">\n' +
    '                              Successful lead reward\n' +
    '                            </div>\n' +
    '                            <div class="col-xs-6 text-right">\n' +
    '                              {{closeLead.getSuccessfulLeadPrice() | currency:\'$\':2}}\n' +
    '                            </div>\n' +
    '                          </div>\n' +
    '                          <div class="row" ng-if="closeLead.showPriceBreakdown() && !!closeLead.data.bonusReward">\n' +
    '                            <div class="col-xs-6">\n' +
    '                              Bonus reward\n' +
    '                            </div>\n' +
    '                            <div class="col-xs-6 text-right">\n' +
    '                              {{closeLead.data.bonusReward | currency:"$":2}}\n' +
    '                            </div>\n' +
    '                          </div>\n' +
    '                          <div class="row" ng-if="closeLead.showPriceBreakdown() && !closeLead.calculating && !closeLead.calculationRequired">\n' +
    '                            <div class="col-xs-6">\n' +
    '                              Processing fee\n' +
    '                            </div>\n' +
    '                            <div class="col-xs-6 text-right">\n' +
    '                              {{closeLead.processingFee | currency:"$":2}}\n' +
    '                            </div>\n' +
    '                          </div>\n' +
    '                          <div class="row" ng-if="closeLead.showPriceBreakdown() && !closeLead.calculating && !closeLead.calculationRequired">\n' +
    '                            <div class="col-xs-6">\n' +
    '                              GST\n' +
    '                            </div>\n' +
    '                            <div class="col-xs-6 text-right">\n' +
    '                              {{closeLead.tax | currency:"$":2}}\n' +
    '                            </div>\n' +
    '                          </div>\n' +
    '                          <div class="row" style="border-top: 1px lightgray dotted; padding-top: 5px; margin-top: 3px;"\n' +
    '                               ng-if="closeLead.showPriceBreakdown() && !closeLead.calculating && !closeLead.calculationRequired">\n' +
    '                            <div class="col-xs-6">\n' +
    '                              <strong>Total charged</strong>\n' +
    '                            </div>\n' +
    '                            <div class="col-xs-6 text-right">\n' +
    '                              <strong>{{closeLead.total | currency:"$":2}}</strong>\n' +
    '                            </div>\n' +
    '                          </div>\n' +
    '                        </div>\n' +
    '                      </div>\n' +
    '\n' +
    '                      <div>\n' +
    '                        <div class="row">\n' +
    '                          <div class="col-xs-12 form-group">\n' +
    '                            Payment Details:\n' +
    '                          </div>\n' +
    '                        </div>\n' +
    '                        <credit-card user="closeLead.user" credit-card="closeLead.creditCard" cc-data="closeLead.data.creditCard" cc-show-form="closeLead.showCCForm()"></credit-card>\n' +
    '                      </div>\n' +
    '\n' +
    '                    </div>\n' +
    '\n' +
    '                    <div class="step-buttons">\n' +
    '                      <button class="btn btn-primary" data-step-to="1">\n' +
    '                        Back\n' +
    '                      </button>\n' +
    '                      <button class="btn btn-primary" ng-disabled="closeLeadForm.$invalid || closeLead.isLoading" ng-click="closeLead.close()">\n' +
    '                        {{closeLead.isLoading ? \'Loading ...\' : \'Close and Pay\'}}\n' +
    '                      </button>\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '        </div>\n' +
    '\n' +
    '        <!-- Unsuccessful Lead -->\n' +
    '        <div class="col-xs-12" ng-if="!closeLead.isSuccessful()">\n' +
    '          <div class="row">\n' +
    '            <div class="col-xs-12 lead-detail-header modal-content-title">\n' +
    '              <span class="sub-title">\n' +
    '                Unsuccessful Lead\n' +
    '              </span>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="form-group">\n' +
    '            Are you sure you want to close this lead as <strong>\'UNSUCCESSFUL\'</strong>?\n' +
    '          </div>\n' +
    '          <div class="row form-group">\n' +
    '            <div class="col-xs-12 control-description">\n' +
    '              Please provide feedback for this lead\n' +
    '            </div>\n' +
    '            <div class="col-xs-12" ng-class="{\'has-error\': (closeLeadForm.$submitted || closeLeadForm.feedback.$touched) && closeLeadForm.feedback.$error.required}">\n' +
    '              <label class="sr-only" for="feedback">Please provide feedback for this lead</label>\n' +
    '              <div ng-show="closeLeadForm.$submitted || closeLeadForm.feedback.$touched">\n' +
    '                <div class="input-error" ng-show="closeLeadForm.feedback.$error.required">Missing feedback</div>\n' +
    '              </div>\n' +
    '              <div class="left-inner-addon">\n' +
    '                <i class="fa fa-comment-o inner-addon"></i>\n' +
    '                <textarea class="form-control input-lg lead-note-input" name="feedback" id="feedback" placeholder="Please provide feedback for this lead"\n' +
    '                          ng-required="true" ng-model="closeLead.data.feedback"></textarea>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="row modal-content-buttons">\n' +
    '      <div class="col-xs-12 text-right">\n' +
    '        <button class="btn btn-default" ng-disabled="closeLead.isLoading" ng-click="closeLead.cancel()">\n' +
    '          Cancel\n' +
    '        </button>\n' +
    '        <button ng-if="!closeLead.isSuccessful()"\n' +
    '                class="btn btn-primary"\n' +
    '                ng-class="{loading: closeLead.isLoading}"\n' +
    '                ng-disabled="closeLead.isLoading || closeLeadForm.$invalid"\n' +
    '                ng-click="closeLead.close()">\n' +
    '          Close Lead\n' +
    '        </button>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('mlp-marketplace/marketplace.tpl.html',
    '<div class="preferences">\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-12 col-sm-4">\n' +
    '      <h1 class="page-header">Marketplace</h1>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-12">\n' +
    '      <div class="page-content page-content-no-tabs">\n' +
    '\n' +
    '        <div class="row">\n' +
    '          <div class="col-xs-12 form-group">\n' +
    '            Sign up to deals and services advertised by MyLeadPod business subscribers. (To advertise, please\n' +
    '            <a href="mailto:cs@myleadpod.com?subject=Marketplace advertising interest">contact us</a>\n' +
    '            to set you up for MyLeadPod Marketplace.)\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row form-group">\n' +
    '          <div class="col-xs-12 text-center">\n' +
    '            <div class="preferences-content hidden-xs">\n' +
    '              <div class="compound-buttons" style="margin:0 auto;">\n' +
    '                <div role="button" tabindex="1"\n' +
    '                     ng-click="marketplace.isGrid = false;"\n' +
    '                     ng-class="{\'active\': !marketplace.isGrid}"><i class="fa fa-bars"></i>\n' +
    '                </div>\n' +
    '                <div role="button" tabindex="1"\n' +
    '                     ng-click="marketplace.isGrid = true;"\n' +
    '                     ng-class="{\'active\': marketplace.isGrid}"><i class="fa fa-th"></i>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div ng-if="marketplace.mlpItems && marketplace.mlpItems.length > 0">\n' +
    '          <div class="row marketplace-section">\n' +
    '            <div class="col-xs-12 mp-section-title text-center cursor-pointer" ng-click="marketplace.viewMLP = !marketplace.viewMLP;">\n' +
    '              <i class="fa fa-chevron-down" ng-class="{\'rotate-180\': marketplace.viewMLP}" style="transition: transform 0.2s ease-in-out;"></i>\n' +
    '              MyLeadPod Deals and Services\n' +
    '            </div>\n' +
    '            <div class="col-xs-12 preferences-section mp-section slide-if" ng-class="{\'grid\': marketplace.isGrid}" ng-if="marketplace.viewMLP">\n' +
    '              <marketplace-listings items="marketplace.mlpItems" has-user-avatar="true"></marketplace-listings>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '\n' +
    '        <div class="row marketplace-section">\n' +
    '          <div class="col-xs-12 mp-section-title text-center">\n' +
    '            Marketplace\n' +
    '          </div>\n' +
    '          <div class="col-xs-12 preferences-section mp-section" ng-class="{\'grid\': marketplace.isGrid}">\n' +
    '            <marketplace-listings items="marketplace.items" has-user-avatar="true"></marketplace-listings>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('mlp-marketplace/marketplace-listings-directive.tpl.html',
    '<div class="marketplace no-select" ng-class="{\'no-border\': marketplace.items && marketplace.items.length == 1}">\n' +
    '  <!-- alerts -->\n' +
    '  <uib-alert ng-repeat="alert in marketplace.alerts.getList()" type="{{alert.type}}" close="marketplace.alerts.remove($index)">{{alert.message}}</uib-alert>\n' +
    '\n' +
    '  <!-- items -->\n' +
    '  <div class="preferences-content"\n' +
    '       ng-if="marketplace.items.length > 0"\n' +
    '       ng-repeat="item in marketplace.items">\n' +
    '    <div class="preferences-content-title"  ng-click="marketplace.openInfo($index)">\n' +
    '      <div ng-if="marketplace.hasUserAvatar && item.owner" class="preferences-avatar hidden-sm hidden-xs">\n' +
    '        <user-avatar user="item.owner" size="small" fixed-size="false" is-link="false"></user-avatar>\n' +
    '      </div>\n' +
    '      <div ng-if="marketplace.hasUserAvatar && !item.owner" class="preferences-avatar hidden-sm hidden-xs">\n' +
    '        <div class="avatar-container"><img src="../images/user-placeholder.jpg" class="avatar avatar-small"></div>\n' +
    '      </div>\n' +
    '      <div class="marketplace-content cursor-pointer">\n' +
    '        <div>\n' +
    '          <a class="cursor-pointer marketplace-title">\n' +
    '            <div ng-if="marketplace.hasUserAvatar && item.owner" class="preferences-avatar hidden-lg hidden-md">\n' +
    '              <user-avatar user="item.owner" size="small" fixed-size="false" is-link="false"></user-avatar>\n' +
    '            </div>\n' +
    '            <div ng-if="marketplace.hasUserAvatar && !item.owner" class="preferences-avatar hidden-lg hidden-md">\n' +
    '              <div class="avatar-container"><img src="../images/user-placeholder.jpg" class="avatar avatar-small"></div>\n' +
    '            </div>\n' +
    '            <span>{{item.label}}</span>\n' +
    '          </a>\n' +
    '          <div class="marketplace-beans">\n' +
    '            <div class="marketplace-price">\n' +
    '              <span>Price</span>\n' +
    '              <span class="marketplace-value">{{item.isFree ? \'FREE\' : (item.priceMonthly | currency:\'$\':0)}}</span>\n' +
    '            </div>\n' +
    '            <div ng-if="item.owner" class="marketplace-company">\n' +
    '              <span>Company</span>\n' +
    '              <span class="marketplace-value">{{item.owner.fullName + ((item.owner.businessName && item.owner.fullName !== item.owner.businessName) ? \' | \' + item.owner.businessName : \'\' )}}</span>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="preferences-content-subtitle hidden-xs hidden-sm">\n' +
    '          <span ng-if="item.description">{{item.description}}</span>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="preferences-tools">\n' +
    '      <div class="subscription-button" role="button" tabindex="1"\n' +
    '           ng-click="marketplace.switchSubscription($index)"\n' +
    '           ng-class="{\'active\': item.isSubscribed, \'disabled\': marketplace.isLoading[$index]}">\n' +
    '\n' +
    '        <div ng-if="!item.isSubscribed">\n' +
    '          <!--<i class="fa fa-plus"></i>-->\n' +
    '          <span>{{item.isFree ? \'FREE\' : (item.priceMonthly | currency:\'$\':0)}}</span>\n' +
    '        </div>\n' +
    '        <div ng-if="item.isSubscribed">\n' +
    '          <!--<i class="fa fa-minus"></i>-->\n' +
    '          <span>Unsubscribe</span>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="preferences-content-subtitle hidden-md hidden-lg" style="width: 100%;" ng-click="marketplace.openInfo($index)">\n' +
    '      <span ng-if="item.description">{{item.description}}</span>\n' +
    '    </div>\n' +
    '\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('mlp-marketplace/marketplace-info-modal.tpl.html',
    '<div class="modal-content-container marketplace">\n' +
    '  <div class="row">\n' +
    '    <!-- Title -->\n' +
    '    <div class="col-xs-12 modal-content-title modal-content-title-w-tools" style="flex-wrap: wrap;">\n' +
    '      <div style="display: flex;flex: 1; justify-content: space-between;">\n' +
    '        <span class="sub-title">{{marketplaceInfo.item.label}} - {{marketplaceInfo.item.isFree ? \'FREE\' : ((marketplaceInfo.item.priceMonthly | currency:\'$\':0))}}</span>\n' +
    '        <div class="preferences-tools">\n' +
    '          <div class="subscription-button" role="button" tabindex="1"\n' +
    '               ng-click="marketplaceInfo.switchSubscription($index)"\n' +
    '               ng-class="{\'active\': marketplaceInfo.item.isSubscribed, \'disabled\': marketplaceInfo.isUpdating}">\n' +
    '\n' +
    '            <div ng-if="!marketplaceInfo.item.isSubscribed">\n' +
    '              <span>{{marketplaceInfo.item.isFree ? \'FREE\' : (marketplaceInfo.item.priceMonthly | currency:\'$\':0)}}</span>\n' +
    '            </div>\n' +
    '            <div ng-if="marketplaceInfo.item.isSubscribed">\n' +
    '              <span>Unsubscribe</span>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="preferences-content-subtitle" style="text-align:right;">\n' +
    '            <span ng-if="marketplaceInfo.item.isSubscribed">Subscribed </span>\n' +
    '            <span ng-if="!marketplaceInfo.item.isSubscribed">Not subscribed</span>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div style="flex: 1 0 100%;">\n' +
    '        <div class="marketplace-beans marketplace-beans-structured">\n' +
    '          <div class="marketplace-owner" ng-if="marketplaceInfo.item.owner">\n' +
    '            <span>Owner</span>\n' +
    '            <span>\n' +
    '              <table-user-details style="display: inline-flex; width: auto;" user="marketplaceInfo.item.owner" name-only="true" add-class="" is-link="true" has-details="true"></table-user-details>\n' +
    '            </span>\n' +
    '          </div>\n' +
    '          <div class="marketplace-price">\n' +
    '            <span>Price</span>\n' +
    '            <span class="marketplace-value">{{marketplaceInfo.item.isFree ? \'FREE\' : (marketplaceInfo.item.priceMonthly | currency:\'$\':0)}}</span>\n' +
    '          </div>\n' +
    '          <!--<div class="marketplace-company" ng-if="marketplaceInfo.item.owner.businessName">\n' +
    '            <span>Company</span>\n' +
    '            <span class="marketplace-value">{{marketplaceInfo.item.owner.fullName + ((marketplaceInfo.item.owner.businessName && marketplaceInfo.item.owner.fullName !== marketplaceInfo.item.owner.businessName) ? \' | \' + marketplaceInfo.item.owner.businessName : \'\' )}}</span>\n' +
    '          </div>-->\n' +
    '          <div class="marketplace-description">\n' +
    '            <span>Description</span>\n' +
    '            <span class="marketplace-value">{{marketplaceInfo.item.description}}</span>\n' +
    '          </div>\n' +
    '\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <!-- ENDOF: Title-->\n' +
    '\n' +
    '    <!-- Content -->\n' +
    '\n' +
    '    <div class="col-xs-12" ng-if="!marketplaceInfo.isLoading" ng-bind-html="marketplaceInfo.item.info || marketplaceInfo.item.description"></div>\n' +
    '    <div class="col-xs-12 text-center" ng-if="marketplaceInfo.isLoading"><i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i></div>\n' +
    '    <!-- ENDOF: Content -->\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="row modal-content-buttons">\n' +
    '    <div class="text-right">\n' +
    '      <button type="button" class="btn btn-default" ng-click="marketplaceInfo.close()">\n' +
    '        Close\n' +
    '      </button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('mlp-marketplace/marketplace-activation-modal.tpl.html',
    '<div class="modal-content-container">\n' +
    '  <div class="row">\n' +
    '    <!-- Title -->\n' +
    '    <div class="col-xs-12 modal-content-title">\n' +
    '      <span class="sub-title">{{marketplaceActivation.item.label}}</span>\n' +
    '    </div>\n' +
    '    <!-- ENDOF: Title-->\n' +
    '\n' +
    '    <!-- Content -->\n' +
    '    <div class="col-xs-12" ng-bind-html="marketplaceActivation.item.description"></div>\n' +
    '\n' +
    '    <div class="col-xs-12">\n' +
    '      <form name="marketplaceActivationForm">\n' +
    '        <div ng-if="marketplaceActivation.isPaymentRequired" class="close-lead-invoice form-group">\n' +
    '          <div class="row">\n' +
    '            <div class="col-xs-12">\n' +
    '              <h4>Payment Summary</h4>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="row">\n' +
    '            <div class="col-xs-6">\n' +
    '              Subscription fee\n' +
    '            </div>\n' +
    '            <div class="col-xs-6 text-right">\n' +
    '              {{marketplaceActivation.paymentSummary.price | currency:\'$\':2}}\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '\n' +
    '          <div class="row">\n' +
    '            <div class="col-xs-6">\n' +
    '              GST\n' +
    '            </div>\n' +
    '            <div class="col-xs-6 text-right">\n' +
    '              {{marketplaceActivation.paymentSummary.tax | currency:"$":2}}\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="row" style="border-top: 1px lightgray dotted; padding-top: 5px; margin-top: 3px;">\n' +
    '            <div class="col-xs-6">\n' +
    '              <strong>Total</strong>\n' +
    '            </div>\n' +
    '            <div class="col-xs-6 text-right">\n' +
    '              <strong>{{marketplaceActivation.paymentSummary.total | currency:"$":2}}</strong>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <!--<div class="col-xs-12"\n' +
    '               style="color: lightgray;margin: 15px 0px;font-size: 12px;color: #676767;white-space: normal;">\n' +
    '            * You will be periodically charged this amount every month\n' +
    '          </div>-->\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row" ng-if="marketplaceActivation.isPaymentRequired">\n' +
    '          <div class="col-xs-12">\n' +
    '            <h4>Payment Options</h4>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row" ng-if="marketplaceActivation.isPaymentRequired">\n' +
    '          <div class="col-xs-12" ng-hide="marketplaceActivation.isCCLoading">\n' +
    '            <credit-card credit-card="marketplaceActivation.creditCard"\n' +
    '                         cc-data="marketplaceActivation.data.creditCard" />\n' +
    '          </div>\n' +
    '          <div class="col-xs-12 text-center" ng-if="marketplaceActivation.isCCLoading">\n' +
    '            <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '\n' +
    '    <!-- ENDOF: Content -->\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="row modal-content-buttons">\n' +
    '    <div class="col-xs-12 text-right">\n' +
    '      <button class="btn btn-default" ng-disabled="marketplaceActivation.isLoading"\n' +
    '              ng-click="marketplaceActivation.cancel()">\n' +
    '        Cancel\n' +
    '      </button>\n' +
    '      <button class="btn btn-primary"\n' +
    '              ng-disabled="marketplaceActivation.isLoading || marketplaceActivation.isCCLoading || marketplaceActivationForm.$invalid"\n' +
    '              ng-click="marketplaceActivation.activate()">\n' +
    '        {{marketplaceActivation.isLoading ? \'Activating...\' : (marketplaceActivation.isPaymentRequired ? \'Pay & Activate\' : \'Activate\')}}\n' +
    '      </button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('mlp-charity/donate-modal.tpl.html',
    '<div class="modal-content-container charity">\n' +
    '  <div class="row">\n' +
    '    <!-- Title -->\n' +
    '    <div class="col-xs-12 modal-content-title">\n' +
    '      <span class="sub-title">Donation</span>\n' +
    '    </div>\n' +
    '    <!-- ENDOF: Title-->\n' +
    '\n' +
    '    <div class="divided-form-xs" style="clear:both;">\n' +
    '\n' +
    '      <div class="pending-overlay-white pending-overlay-column" ng-if="donation.isLoading" style="position:absolute;">\n' +
    '        <span class="fa fa-circle-o-notch fa-spin-2x"></span>\n' +
    '        <div class="pending-overlay-description">Making Donation..</div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="charity-input-form">\n' +
    '        <form name="donationForm" class="no-select">\n' +
    '\n' +
    '          <!-- Pending overlay -->\n' +
    '          <div class="pending-overlay-white pending-overlay-column opacity-slow-if" ng-if="donation.isCCLoading">\n' +
    '            <span class="fa fa-circle-o-notch fa-spin-2x"></span>\n' +
    '            <div class="pending-overlay-description">Loading your Credit Card</div>\n' +
    '          </div>\n' +
    '\n' +
    '            <!-- Charity Select -->\n' +
    '          <div class="control-description-subtle">\n' +
    '            Charity receiving your donation\n' +
    '          </div>\n' +
    '          <div class="form-group">\n' +
    '            <div class="receiving-charity-container" ng-if="donation.charityProfile">\n' +
    '              <div>{{ donation.charityProfile.businessName ? donation.charityProfile.businessName : donation.charityProfile.firstName }}</div>\n' +
    '              <a href ng-click="donation.isSearchVisible = true;" class="cursor-pointer no-animate" role="button" tabindex="0" ng-if="donation.charityProfile && !donation.isSearchVisible">Change</a>\n' +
    '              <a href ng-click="donation.isSearchVisible = false;" class="cursor-pointer no-animate" role="button" tabindex="0" ng-if="donation.charityProfile && donation.isSearchVisible">Done</a>\n' +
    '            </div>\n' +
    '            <div class="slide-css controls-container" ng-class="{ slide: donation.isSearchVisible || !donation.charityProfile}">\n' +
    '              <div class="last-charities" ng-show="donation.lastCharities && donation.lastCharities.length > 0">\n' +
    '                <div class="control-description-subtle">Last donations</div>\n' +
    '                <div style="position: relative; overflow: hidden;">\n' +
    '                  <div class="container-scroll-shadow" scroll-check target-element-id="lastcharities"></div>\n' +
    '                  <div class="last-charities-container" id="lastcharities" drag-scroll="true" axis="x">\n' +
    '                    <div ng-repeat="charity in donation.lastCharities" ng-init="$last && donation.checkScroll()" ng-click="donation.selectCharity(charity);">\n' +
    '                      <table-user-details user="charity" is-link="false" ng-click="donation.selectCharity(charity)" tabindex="-1" />\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div>\n' +
    '                <div class="control-description-subtle">Search within MyLeadPod</div>\n' +
    '                <search-autocomplete\n' +
    '                  control-id="donation-autocomplete"\n' +
    '                  placeholder="Search Charities "\n' +
    '                  minlength="3"\n' +
    '                  clear-selected="false"\n' +
    '                  text-searching="false"\n' +
    '                  text-no-results="false"\n' +
    '\n' +
    '                  search-service="Search"\n' +
    '                  search-service-end-point="index"\n' +
    '                  remote-url-response-formatter="donation.searchResponseFormatter"\n' +
    '                  search-categories="donation.searchCategories"\n' +
    '                  selected-object="donation.onSearchItemSelected"\n' +
    '                  focus-in="donation.emptyResults = false;"\n' +
    '\n' +
    '                  data-field="content.records"\n' +
    '\n' +
    '                  search-fields="search"\n' +
    '                  title-field="value"\n' +
    '                  description-field="categoryDescription"\n' +
    '                  description-field2="timeDescription"\n' +
    '                  category-field="category"\n' +
    '\n' +
    '                  input-name="donationUsersInputSearch"\n' +
    '                  input-class="form-control input-lg"\n' +
    '                  match-class="autocomplete-highlight"\n' +
    '                  blur-on-select="true"\n' +
    '                  has-back-button="false"\n' +
    '                  update-input-on-select="true"\n' +
    '\n' +
    '                  is-dropdown-relative="false"\n' +
    '\n' +
    '                  override-suggestions="true"\n' +
    '                  field-required="false"\n' +
    '                  has-category-filter="false"\n' +
    '                  has-history="true"\n' +
    '                />\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '            <!-- Payment Select -->\n' +
    '          <div class="form-group">\n' +
    '            <div class="control-description-subtle">\n' +
    '              Payment options\n' +
    '            </div>\n' +
    '            <credit-card cc-data="donation.data.paymentDetails" credit-card="donation.creditCard" ng-if="!donation.isCCLoading"/>\n' +
    '          </div>\n' +
    '\n' +
    '          <!-- Donation Select -->\n' +
    '          <div class="form-group">\n' +
    '            <div class="control-description-subtle">\n' +
    '              Please provide the donation amount\n' +
    '            </div>\n' +
    '            <div class="validating-control" ng-class="{\'has-error\': (donationForm.$submitted || donationForm.amount.$touched) &&\n' +
    '                (donationForm.amount.$error.required || donationForm.amount.$error.number || donationForm.amount.$error.min)}">\n' +
    '              <label class="sr-only" for="amount">Reward value</label>\n' +
    '              <div ng-show="donationForm.$submitted || donationForm.amount.$touched">\n' +
    '                <div class="input-error" ng-show="donationForm.amount.$error.number">Invalid value</div>\n' +
    '                <div class="input-error" ng-show="donationForm.amount.$error.required">Missing value</div>\n' +
    '                <div class="input-error" ng-show="donationForm.amount.$error.min">Donation value must not be negative</div>\n' +
    '              </div>\n' +
    '              <div class="left-inner-addon input-group" style="width: 100%;">\n' +
    '                <i class="fa fa-dollar inner-addon"></i>\n' +
    '                <input class="form-control input-lg"\n' +
    '                       type="number"\n' +
    '                       name="amount"\n' +
    '                       id="amount"\n' +
    '                       placeholder="Donation Amount"\n' +
    '                       ng-required="true"\n' +
    '                       ng-model="donation.data.amount"\n' +
    '                       ng-change="donation.calculate()"\n' +
    '                       ng-keydown="donation.onKeyPress($event)"\n' +
    '                       min="0" />\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div class="value-btns">\n' +
    '              <button class="btn-sm btn-default" ng-click="donation.calculateWith(10)">$10</button>\n' +
    '              <button class="btn-sm btn-default" ng-click="donation.calculateWith(20)">$20</button>\n' +
    '              <button class="btn-sm btn-default" ng-click="donation.calculateWith(50)">$50</button>\n' +
    '              <button class="btn-sm btn-default" ng-click="donation.calculateWith(100)">$100</button>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <!-- Balance sheet -->\n' +
    '          <div class="slide-if" ng-if="donation.data.amount > 0">\n' +
    '            <div class="control-description-subtle">\n' +
    '              Payment Summary\n' +
    '            </div>\n' +
    '            <div class="controls-container pam" style="position: relative; font-size: 14px;">\n' +
    '\n' +
    '              <!-- Pending overlay -->\n' +
    '              <div class="pending-overlay-white pending-overlay-column opacity-slow-if" ng-if="donation.isCalculating">\n' +
    '                <span class="fa fa-circle-o-notch fa-spin-2x"></span>\n' +
    '                <div class="pending-overlay-description">Recalculating</div>\n' +
    '              </div>\n' +
    '\n' +
    '              <div class="row">\n' +
    '                <div class="col-xs-6 ">\n' +
    '                  Donation\n' +
    '                </div>\n' +
    '                <div class="col-xs-6 text-right">\n' +
    '                  {{donation.data.amount | currency:"$":2}}\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <!--<div class="row" ng-if="withdraw.showTotal && !withdraw.calculating && !withdraw.calculationRequired && withdraw.processingFee">-->\n' +
    '              <div class="row" ng-if="donation.summary.fee">\n' +
    '                <div class="col-xs-6 ">\n' +
    '                  Processing fee\n' +
    '                </div>\n' +
    '                <div class="col-xs-6  text-right">\n' +
    '                  {{donation.summary.fee >= 0 ? (donation.summary.fee | currency:"$":2) : "..."}}\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="row">\n' +
    '                <div class="col-xs-6 3">\n' +
    '                  Tax\n' +
    '                </div>\n' +
    '                <div class="col-xs-6 text-right">\n' +
    '                  {{donation.summary.tax >= 0 ? (donation.summary.tax| currency:"$":2) : "..."}}\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="row">\n' +
    '                <div class="col-xs-6 ">\n' +
    '                  <strong>Payment Total</strong>\n' +
    '                </div>\n' +
    '                <div class="col-xs-6  text-right">\n' +
    '                  <strong>{{donation.summary.total >= 0 ? (donation.summary.total | currency:"$":2) : "..."}}</strong>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </form>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="chosen-charity-info">\n' +
    '        <div style="text-align:center;">\n' +
    '\n' +
    '          <div class="pending-overlay-white pending-overlay-column" ng-if="donation.isLoadingCharity">\n' +
    '            <span class="fa fa-circle-o-notch fa-spin-2x"></span>\n' +
    '            <div class="pending-overlay-description">Loading Charity Info</div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div ng-if="donation.charityProfile">\n' +
    '            <user-avatar size="large" is-link="false" user="donation.charityProfile"></user-avatar>\n' +
    '            <div class="charity-name">\n' +
    '              {{ donation.charityProfile.businessName ? donation.charityProfile.businessName : donation.charityProfile.firstName }}\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div ng-if="!donation.charityProfile">\n' +
    '            <div class="charity-name">\n' +
    '              No Charity selected\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="charity-donation">\n' +
    '            <!--<span>Your Donation</span>\n' +
    '            <span>{{donation.data.amount ? (donation.data.amount | currency:\'$\':0) : \'$?\'}}</span>-->\n' +
    '            <button ng-if="!donation.isRegister" type="button" class="btn btn-primary"\n' +
    '                    ng-disabled="donationForm.$invalid || donation.isLoading || donation.hasError || donation.isCalculating || !donation.charityProfile" ng-click="donation.donate()">\n' +
    '              {{donation.isLoading ? \'Sending ...\' : \'Donate\' + (donation.data.amount ? \' \' + (donation.data.amount | currency:\'$\':0) : \'\')}}</button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="row modal-content-buttons">\n' +
    '    <div class="col-xs-12 text-right">\n' +
    '      <button type="button" class="btn btn-default"\n' +
    '              ng-disabled="donation.isLoading" ng-click="donation.cancel()">\n' +
    '        Cancel\n' +
    '      </button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('messaging/messaging-form-directive.tpl.html',
    '<div class="messaging-directive" ng-class="{\'loading\': msg.loading || msg.saving}">\n' +
    '  <div class="additional-msg-loader-container" ng-class="{\'open\': msg.loading && !msg.saving && msg.appendMessages}">\n' +
    '    <div class="additional-msg-loader">\n' +
    '      <i class="fa fa-circle-o-notch fa-spin-2x"></i>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <!--<div class="messages-container loading no-animate" ng-if="(msg.loading || msg.saving) && !msg.appendMessages">\n' +
    '    <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>\n' +
    '  </div>-->\n' +
    '  <div class="pending-overlay-white pending-overlay-column opacity-slow-if" ng-if="msg.loading && !msg.appendMessages">\n' +
    '    <span class="fa fa-circle-o-notch fa-spin-2x"></span>\n' +
    '    <div class="pending-overlay-description">Loading Messages</div>\n' +
    '  </div>\n' +
    '  <div class="pending-overlay-white pending-overlay-column opacity-slow-if" ng-if="msg.saving && !msg.appendMessages">\n' +
    '    <span class="fa fa-circle-o-notch fa-spin-2x"></span>\n' +
    '    <div class="pending-overlay-description">Sending...</div>\n' +
    '  </div>\n' +
    '  <div class="messages-container" ng-if="msg.messages.length === 0 && msg.loading"></div>\n' +
    '  <div class="messages-container" ng-if="msg.messages.length === 0 && !msg.hasError && !msg.loading" ng-class="{\'empty\': msg.messages.length === 0}">\n' +
    '    <!-- No messages -->\n' +
    '    <div class="no-messages">\n' +
    '      No Messages.\n' +
    '    </div>\n' +
    '    <div class="no-messages-prompt">\n' +
    '      Send the first message!\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="messages-container has-data" ng-show="msg.messages.length > 0 && !msg.error">\n' +
    '    <!-- list of messages -->\n' +
    '    <div ng-repeat="m in msg.messages" class="message-row"\n' +
    '         ng-class="{\'incoming\': m.usersender.idUser === msg.recipient.idUser}">\n' +
    '\n' +
    '         <!--ng-init="msg.itemRender(m, $index)">-->\n' +
    '      <!--<div ng-if="msg.grouping" ng-class="{\'group-opening\': m.opensGroup, \'group-closing\': m.closesGroup, \'no-group\': m.noGroup}">\n' +
    '        <div class="group-line"></div>\n' +
    '        <div class="group-container" ng-if="!m.noGroup">\n' +
    '          <div class="group-content">\n' +
    '            <button class="btn-sm btn-primary">info</button>\n' +
    '            <div class="group-item">\n' +
    '              lead: {{m.groupId}}\n' +
    '            </div>\n' +
    '            <button class="btn-sm btn-primary">></button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>-->\n' +
    '      <div class="message-container">\n' +
    '        <div class="recipient-image" ng-if="m.usersender.idUser === msg.recipient.idUser">\n' +
    '          <user-avatar size="small" fixed-size="false" user="msg.recipient"></user-avatar>\n' +
    '        </div>\n' +
    '        <div class="message-content" ng-bind-html="m.message"></div>\n' +
    '        <div class="message-time" ng-if="!msg.savingMessages[m.dateCreated]">\n' +
    '          {{m.created}}\n' +
    '        </div>\n' +
    '        <div class="message-pending opacity-slow-if" ng-if="msg.savingMessages[m.dateCreated]">\n' +
    '          <span class="fa fa-circle-o-notch fa-spin-2x"></span>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <!--<div ng-if="msg.grouping" ng-class="{\'group-closing\': m.closesGroup}"></div>-->\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="messages-container error" ng-if="msg.messages.length === 0 && msg.hasError">\n' +
    '    <!-- No messages -->\n' +
    '    <div class="no-messages">\n' +
    '      Unable to load messages.\n' +
    '    </div>\n' +
    '    <div class="no-messages-prompt">\n' +
    '      <button class="btn" ng-click="msg.reload()">Reload</button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="message-input" ng-if="!msg.hasError">\n' +
    '    <!-- input form for entering the messages -->\n' +
    '    <form name="messagingForm">\n' +
    '      <label class="sr-only" for="textInput">Type your message</label>\n' +
    '      <textarea id="{{msg.id}}-text-input" ng-disabled="vm.hasError" name="textInput" type="text" class="form-control msg-inputbox" placeholder="Write message here.." ng-model="msg.messageStr"></textarea>\n' +
    '      <button name="submitBtn" ng-disabled="vm.hasError" type="button" class="btn btn-primary btn-send" ng-click="msg.send(messagingForm.textInput)">Send</button>\n' +
    '    </form>\n' +
    '  </div>\n' +
    '  <div class="message-notification">\n' +
    '    {{msg.notification}}\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('dashboard/dashboard.tpl.html',
    '<div class="row">\n' +
    '  <div class="col-xs-12 col-sm-6">\n' +
    '    <h1 class="page-header">Dashboard</h1>\n' +
    '  </div>\n' +
    '  <div class="col-sm-6 hidden-xs">\n' +
    '    <div class="page-header-buttons">\n' +
    '      <a ui-sref="myPodSendInvite" class="btn btn-primary btn-shadow btn-lg-lg">Invite to my Pod</a>\n' +
    '      <a ui-sref="leadSend" class="btn btn-success btn-shadow btn-lg-lg">Send Lead</a>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '<action-toolbar class="visible-xs" at-target="secondary">\n' +
    '  <a class="no-animate fa fa-envelope" label="Invite to my Pod" ui-sref="myPodSendInvite"><span class="tool-label">Invite to my Pod</span></a>\n' +
    '  <a class="no-animate fa fa-paper-plane" label="Send Lead" ui-sref="leadSend"><span class="tool-label">Send Lead</span></a>\n' +
    '</action-toolbar>\n' +
    '\n' +
    '<div class="dashboard-highlight" ng-include="\'dashboard/stats/stats.tpl.html\'"></div>\n' +
    '\n' +
    '<div ng-if="dashboard.isBusinessAccountActive()" class="dashboard-memberships page-section row" ng-include="\'dashboard/memberships/memberships.tpl.html\'"></div>\n' +
    '\n' +
    '<div class="dashboard-pod page-section row" ng-include="\'dashboard/pod/pod.tpl.html\'"></div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('components/stat-block-directive.tpl.html',
    '<div class="highlight">\n' +
    '	<div class="highlight_title">{{statBlock.stat}}</div>\n' +
    '	<div class="highlight_subtitle">{{statBlock.subtext}}</div>\n' +
    '	<div class="highlight_footer">\n' +
    '		<a ng-href="{{statBlock.buttonLink}}" class="btn btn-default btn-lg btn-block" ng-disabled="statBlock.stat === 0">{{statBlock.buttonText}}</a>\n' +
    '	</div>\n' +
    '</div>');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('account/sign-up.tpl.html',
    '<div class="row" ng-class="{\'has-invitation\': signUp.invitationSender, \'reward-notes-content\': signUp.invitationSender, \'signup-trial\': signUp.trialEligible}" ng-if="!signUp.showMessage">\n' +
    '  <div class="col-md-12 text-center">\n' +
    '    <div class="row" ng-if="signUp.invitationSender && signUp.invitationSender.imageToken">\n' +
    '      <div class="col-xs-12">\n' +
    '        <div class="sign-up-page-header">\n' +
    '          <user-avatar user="signUp.invitationSender" size="large" fixed-size="false" is-link="false"></user-avatar>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <!-- Please keep this for the future reference in case we will change the description under profile avatar back in\n' +
    '      <div class="col-xs-12 text-xs-center text-sm-center col-sm-12 col-md-12 col-lg-12">\n' +
    '        <span style="font-size: 14px; font-weight: bold;">{{signUp.invitationSender.fullName}}</span>\n' +
    '        <span style="font-size: 14px; font-weight: normal; color: gray; word-wrap: word-wrap;">  ({{signUp.invitationSender.businessName}})</span>\n' +
    '      </div>-->\n' +
    '    </div>\n' +
    '\n' +
    '    <h1 class="page-header" ng-if="!signUp.invitationSender">\n' +
    '      Join in less than a minute. No credit card required.\n' +
    '    </h1>\n' +
    '\n' +
    '\n' +
    '    <div class="row" ng-if="signUp.invitationSender && !signUp.trialEligible">\n' +
    '      <div class="col-xs-12" ng-class="{\'page-header\': !signUp.invitationSender.imageToken}" style="text-shadow: none;">\n' +
    '        <h1 class="" ng-if="signUp.invitationType === \'Reward Code\'" ng-switch on="signUp.rewardCode">\n' +
    '          <span ng-switch-when="WEB-FREE">\n' +
    '            Join {{signUp.invitationSender.fullName}} and start earning cash for sending leads.\n' +
    '          </span>\n' +
    '          <span ng-switch-default>\n' +
    '            Join {{signUp.invitationSender.fullName}}{{signUp.invitationSender.businessName ? \', \' + signUp.invitationSender.businessName : \'\'}} Rewards Program.\n' +
    '          </span>\n' +
    '        </h1>\n' +
    '        <h1 class="" ng-if="signUp.invitationType === \'Join Pod\'">\n' +
    '          Join {{signUp.invitationSender.fullName}}{{signUp.invitationSender.businessName ? \', \' + signUp.invitationSender.businessName : \'\'}} Rewards Program.\n' +
    '        </h1>\n' +
    '        <h1 class="" ng-if="signUp.invitationType === \'Invitation\'">\n' +
    '          {{signUp.invitationSender.fullName}}{{signUp.invitationSender.businessName ? \', \' + signUp.invitationSender.businessName : \'\'}} would like to send you business leads.\n' +
    '        </h1>\n' +
    '        <div ng-if="signUp.invitationType !== \'Invitation\'" ng-switch on="signUp.rewardCode">\n' +
    '          <div class="reward-code-both" ng-switch-when="WEB-FREE">\n' +
    '            <div class="signup-prompt-earninginfo" ng-if="signUp.invitationSender.highestReward">\n' +
    '              You can now earn up to <span>${{signUp.invitationSender.highestReward}}</span> by sending MyLeadPod a successful lead!\n' +
    '              <br>\n' +
    '              Search and join different businesses and their Rewards Program on MyLeadPod to start earning cash for sending leads.\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div ng-switch-default>\n' +
    '            <div class="signup-prompt-earninginfo" ng-if="signUp.invitationSender.highestReward">You can now earn up to <span>${{signUp.invitationSender.highestReward}}</span> by sending just one lead!</div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div ng-if="signUp.invitationType === \'Invitation\'">\n' +
    '          <div class="signup-prompt-earninginfo">Expand your business by registering with MyLeadPod, fast-growing business referral network system.</div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row" ng-if="signUp.invitationSender && signUp.trialEligible && signUp.invitationType === \'Reward Code\'">\n' +
    '      <div class="col-xs-12" ng-class="{\'page-header\': !signUp.invitationSender.imageToken}" style="text-shadow: none;">\n' +
    '        <div ng-switch on="signUp.rewardCode">\n' +
    '          <h2 style="font-weight: 100" ng-switch-when="WEB-TRIAL">\n' +
    '            Sign up for your free trial.\n' +
    '          </h2>\n' +
    '          <h2 style="font-weight: 100" ng-switch-default>\n' +
    '            Join {{signUp.invitationSender.fullName}}{{signUp.invitationSender.businessName ? \', \' + signUp.invitationSender.businessName : \'\'}} on MyLeadPod. Sign up and..\n' +
    '          </h2>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="col-xs-12" ng-switch on="signUp.rewardCode">\n' +
    '        <div class="reward-code-both" ng-switch-when="WEB-TRIAL">\n' +
    '          <div style="text-shadow: none; margin-bottom: 0px;">\n' +
    '            <div class="welcome-title">\n' +
    '              Activate your {{signUp.trialDuration}} free business subscription.\n' +
    '            </div>\n' +
    '            <div class="signup-prompt-earninginfo">\n' +
    '              You are only a few steps away from creating your own cash Reward Program for your business.\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="reward-code-both" ng-switch-default>\n' +
    '          <div>\n' +
    '            <div class="welcome-title">\n' +
    '              .. send your first lead.\n' +
    '            </div>\n' +
    '            <div class="signup-prompt-earninginfo" ng-if="signUp.invitationSender.highestReward">You can now earn up to <span>${{signUp.invitationSender.highestReward}}</span> by sending just one lead!</div>\n' +
    '          </div>\n' +
    '          <div class="" style="text-shadow: none; margin-bottom: 0px;">\n' +
    '            <!--<div ng-if="!signUp.invitationSender || !signUp.invitationSender.imageToken">\n' +
    '              <img src="../images/logo.png">\n' +
    '            </div>-->\n' +
    '            <div class="welcome-title">\n' +
    '              .. receive {{signUp.trialDuration}} free business trial.\n' +
    '            </div>\n' +
    '            <div class="signup-prompt-earninginfo">\n' +
    '              You are only a few steps away from creating your own cash Reward Program and starting receiving leads for your business.\n' +
    '            </div>\n' +
    '            <!--<div class="signup-prompt-earninginfo">*Only for new or eligible businesses</div>-->\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="row" ng-if="!signUp.invitationSender && !signUp.showMessage">\n' +
    '  <div class="col-xs-12 reward-input-content slide-if-flex" ng-if="!signUp.rewardCardOwner">\n' +
    '    <div class="reward-card-holder" ng-class="{flipped: signUp.flipCard}">\n' +
    '      <img class="front" src="./images/reward-code-card-front.png" />\n' +
    '      <img class="back" src="./images/reward-code-card-back.png" />\n' +
    '    </div>\n' +
    '    <div class="reward-holder">\n' +
    '      <div style="margin-bottom:15px;">\n' +
    '        Do you have a <strong>Reward Code</strong>?\n' +
    '      </div>\n' +
    '      <div ng-class="{\'has-error\': signUp.isRewardCodeInvalid, slide: signUp.hasRewardCode && !signUp.displayContactDetails}">\n' +
    '\n' +
    '        <label class="sr-only" for="rewardCode">Reward Code</label>\n' +
    '        <div ng-show="signUp.isRewardCodeInvalid">\n' +
    '          <div class="input-error" ng-show="signUp.isRewardCodeInvalid">Invalid Reward Code.</div>\n' +
    '        </div>\n' +
    '        <div class="left-inner-addon input-group">\n' +
    '          <i ng-show="!signUp.rewardCodeChecked && !signUp.isCheckingCode && !signUp.isRewardCodeValid" class="fa fa-hashtag inner-addon"></i>\n' +
    '          <i ng-show="signUp.isCheckingCode" class="fa fa-circle-o-notch fa-spin-2x inner-addon"></i>\n' +
    '          <i ng-show="!signUp.isCheckingCode && signUp.rewardCodeChecked && signUp.isRewardCodeValid" class="fa fa-check code-container-valid"></i>\n' +
    '          <i ng-show="!signUp.isCheckingCode && signUp.rewardCodeChecked && !signUp.isRewardCodeValid" class="fa fa-times code-container-invalid"></i>\n' +
    '          <input class="form-control input-lg" type="text" name="rewardCode" id="rewardCode"\n' +
    '                 placeholder="Reward Code"\n' +
    '                 ng-required="false"\n' +
    '                 ng-model="signUp.rewardCode"\n' +
    '                 ng-keydown="signUp.onRewardCodeEnter($event)"\n' +
    '                 ng-focus="signUp.flipCard=true;"\n' +
    '                 ng-blur="signUp.checkRewardCode();signUp.flipCard=false;"/>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="col-xs-12 reward-notes-content"\n' +
    '       ng-class="{\'reward-card-note\' : !signUp.rewardCodeChecked}"\n' +
    '       ng-if="!signUp.rewardCodeChecked || (signUp.rewardCodeChecked && (signUp.rewardCardOwner || !signUp.isRewardCodeValid))">\n' +
    '\n' +
    '    <!-- User Info -->\n' +
    '    <div class="reward-card-found slide-if" ng-if="signUp.rewardCodeChecked && !signUp.isCheckingCode && signUp.isRewardCodeValid && signUp.rewardCardOwner">\n' +
    '      <div ng-if="signUp.rewardCardOwner && signUp.rewardCardOwner.imageToken">\n' +
    '        <user-avatar user="signUp.rewardCardOwner" size="large" fixed-size="false" is-link="false"></user-avatar>\n' +
    '      </div>\n' +
    '      <div>\n' +
    '        <div class="reward-card-user-info">\n' +
    '          <h1>Join {{signUp.rewardCardOwner.fullName}}{{signUp.rewardCardOwner.businessName ? \', \' + signUp.rewardCardOwner.businessName : \'\'}} Rewards Program.</h1>\n' +
    '        </div>\n' +
    '        <div class="signup-prompt-earninginfo" ng-if="signUp.rewardCardOwner.highestReward">You can now earn up to <span>${{signUp.rewardCardOwner.highestReward}}</span> by sending just one lead!</div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <!-- Card not found -->\n' +
    '    <div class="reward-card-notfound slide-if" ng-if="signUp.rewardCodeChecked && !signUp.isCheckingCode && !signUp.isRewardCodeValid">\n' +
    '      <div>Sorry, Reward Code was not found or is not longer available.</div>\n' +
    '      <div>You can try again, or join and search for the business in the app to send the lead(s)!</div>\n' +
    '    </div>\n' +
    '\n' +
    '    <!-- Default message -->\n' +
    '    <div class="reward-card-warning slide-if" ng-if="!signUp.rewardCodeChecked">\n' +
    '      Reward Code is optional and not required for joining.\n' +
    '      <span>Reward Card may differ.</span>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="col-xs-12 reward-input-content reward-card-note slide-if" ng-if="signUp.rewardCardOwner">\n' +
    '    <a ng-click="signUp.rewardCardOwner = null;">Try another Reward Code</a>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="row sign-up-page-container" ng-if="!signUp.showMessage">\n' +
    '  <div class="sign-in-page-content">\n' +
    '    <div class="sign-up-page-action-title hidden-xs">\n' +
    '      Join the quick way\n' +
    '    </div>\n' +
    '    <div class="sign-up-page-action-container">\n' +
    '      <div class="btn-social-container">\n' +
    '        <button class="btn btn-social btn-facebook" ng-click="signUp.socialSignIn(\'facebook\')" ng-disabled="signUp.isLoading">\n' +
    '          <i class="btn-social-icon fa fa-facebook"></i>\n' +
    '          <span class="btn-social-text">Join with Facebook</span>\n' +
    '        </button>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="btn-social-container">\n' +
    '        <button class="btn btn-social btn-linkedin" ng-click="signUp.socialSignIn(\'linkedin\')" ng-disabled="signUp.isLoading">\n' +
    '          <i class="btn-social-icon fa fa-linkedin"></i>\n' +
    '          <span class="btn-social-text">Join with Linkedin</span>\n' +
    '        </button>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="btn-social-container">\n' +
    '        <button class="btn btn-social btn-google" ng-click="signUp.socialSignIn(\'google\')" ng-disabled="signUp.isLoading">\n' +
    '          <i class="btn-social-icon fa fa-google-plus"></i>\n' +
    '          <span class="btn-social-text">Join with Google+</span>\n' +
    '        </button>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="sign-in-page-content separator">\n' +
    '    <div class="btn-social-separator-container">\n' +
    '      <div class="btn-social-separator btn-social-separator-sign-in"></div>\n' +
    '      <div class="btn-social-separator-text">OR</div>\n' +
    '      <div class="btn-social-separator btn-social-separator-sign-in"></div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="sign-in-page-content">\n' +
    '    <div class="sign-up-page-action-title hidden-xs">\n' +
    '      Join with your email\n' +
    '    </div>\n' +
    '    <div class="sign-up-page-action-container sign-up-in-form-container">\n' +
    '      <uib-alert ng-repeat="alert in signUp.alerts.getList()" type="{{alert.type}}" close="signUp.alerts.remove($index)"><span ng-bind-html="alert.message"></span></uib-alert>\n' +
    '      <form name="passwordSignUpForm" ng-submit="passwordSignUpForm.$valid && !signUp.isLoading && signUp.passwordSignUp()">\n' +
    '        <div class="form-group" ng-class="{\'has-error\': (passwordSignUpForm.$submitted || passwordSignUpForm.username.$touched) && (passwordSignUpForm.username.$error.required || passwordSignUpForm.username.$error.email)}">\n' +
    '          <label class="sr-only" for="email">Email</label>\n' +
    '          <div ng-show="passwordSignUpForm.$submitted || passwordSignUpForm.username.$touched">\n' +
    '            <div class="input-error" ng-show="passwordSignUpForm.username.$error.required">Tell us your email.</div>\n' +
    '            <div class="input-error" ng-show="passwordSignUpForm.username.$error.email">This is not a valid email.</div>\n' +
    '          </div>\n' +
    '          <div class="left-inner-addon">\n' +
    '            <i class="fa fa-envelope-o inner-addon"></i>\n' +
    '            <input class="form-control input-lg" type="email" name="username" id="email" placeholder="Email"\n' +
    '                   ng-required="true" ng-model="signUp.data.email" />\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="form-group" ng-class="{\'has-error\': (passwordSignUpForm.$submitted || passwordSignUpForm.password.$touched) && passwordSignUpForm.password.$error.required}">\n' +
    '          <label class="sr-only" for="password">Create Your Password</label>\n' +
    '          <div ng-show="passwordSignUpForm.$submitted || passwordSignUpForm.password.$touched">\n' +
    '            <div class="input-error" ng-show="passwordSignUpForm.password.$error.required">Please type in your new password.</div>\n' +
    '          </div>\n' +
    '          <div class="left-inner-addon">\n' +
    '            <i class="fa fa-lock inner-addon"></i>\n' +
    '            <input class="form-control input-lg" type="password" name="password" id="password" placeholder="Create Your Password"\n' +
    '                   ng-required="true" ng-model="signUp.data.password"/>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="form-group sign-up-page-btn-create">\n' +
    '          <button type="submit" class="btn btn-primary btn-lg btn-block"\n' +
    '                  ng-disabled="signUp.isLoading">\n' +
    '            {{signUp.isLoading ? \'Loading ...\' : \'Create Account\'}}\n' +
    '          </button>\n' +
    '        </div>\n' +
    '\n' +
    '      </form>\n' +
    '      <div class="sign-up-terms text-left">\n' +
    '        By clicking \'Create Account\' button, you agree to MyLeadPod\'s <a target="_blank" href="http://blog.myleadpod.com/terms-and-conditions/">Terms & Conditions</a> and <a target="_blank" href="http://blog.myleadpod.com/privacy-policy/">Privacy Policy</a>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="row success-message-container" ng-if="signUp.showMessage">\n' +
    '  <div class="col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 text-center">\n' +
    '    <div class="panel panel-default">\n' +
    '      <div class="panel-body">\n' +
    '        <h2>Thanks for joining!</h2>\n' +
    '        <p style="margin-bottom: 15px;">\n' +
    '          Please check your email and click Verify Email in the message we just sent to\n' +
    '          <br>\n' +
    '          <span>{{signUp.data.email}}</span>\n' +
    '          <br>\n' +
    '          Once your email has been verified you\'ll get access to your account.\n' +
    '        </p>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('account/sign-in.tpl.html',
    '<div class="row">\n' +
    '  <div class="col-md-12 text-center">\n' +
    '    <h1 class="page-header sign-up-page-header">Login</h1>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="row sign-in-page-container">\n' +
    '\n' +
    '  <div class="sign-in-page-content">\n' +
    '    <div class="btn-social-container">\n' +
    '      <button class="btn btn-social btn-facebook" ng-click="signIn.socialSignIn(\'facebook\')" ng-disabled="signIn.isLoading">\n' +
    '        <i class="btn-social-icon fa fa-facebook"></i>\n' +
    '        <span class="btn-social-text">Login with Facebook</span>\n' +
    '      </button>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="btn-social-container">\n' +
    '      <button class="btn btn-social btn-linkedin" ng-click="signIn.socialSignIn(\'linkedin\')" ng-disabled="signIn.isLoading">\n' +
    '        <i class="btn-social-icon fa fa-linkedin"></i>\n' +
    '        <span class="btn-social-text">Login with Linkedin</span>\n' +
    '      </button>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="btn-social-container">\n' +
    '      <button class="btn btn-social btn-google" ng-click="signIn.socialSignIn(\'google\')" ng-disabled="signIn.isLoading">\n' +
    '        <i class="btn-social-icon fa fa-google-plus"></i>\n' +
    '        <span class="btn-social-text">Login with Google+</span>\n' +
    '      </button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="sign-in-page-content separator">\n' +
    '    <div class="btn-social-separator-container">\n' +
    '      <div class="btn-social-separator btn-social-separator-sign-in"></div>\n' +
    '      <div class="btn-social-separator-text">OR</div>\n' +
    '      <div class="btn-social-separator btn-social-separator-sign-in"></div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="sign-in-page-content">\n' +
    '    <div class="sign-up-in-form-container">\n' +
    '      <uib-alert ng-repeat="alert in signIn.alerts.getList()" type="{{alert.type}}" close="signIn.alerts.remove($index)">{{alert.message}}</uib-alert>\n' +
    '      <form name="passwordSignInForm" ng-submit="passwordSignInForm.$valid && !signIn.isLoading && signIn.passwordSignIn()">\n' +
    '        <div class="form-group" ng-class="{\'has-error\': (passwordSignInForm.$submitted || passwordSignInForm.username.$touched) && (passwordSignInForm.username.$error.required || passwordSignInForm.username.$error.email)}">\n' +
    '          <label class="sr-only" for="email">Email</label>\n' +
    '          <div ng-show="passwordSignInForm.$submitted || passwordSignInForm.username.$touched">\n' +
    '            <div class="input-error" ng-show="passwordSignInForm.username.$error.required">Tell us your email.</div>\n' +
    '            <div class="input-error" ng-show="passwordSignInForm.username.$error.email">This is not a valid email.</div>\n' +
    '          </div>\n' +
    '          <div class="left-inner-addon">\n' +
    '            <i class="fa fa-envelope-o inner-addon"></i>\n' +
    '            <input class="form-control input-lg" type="email" name="username" id="email" placeholder="Email"\n' +
    '                   ng-required="true" ng-model="signIn.data.username" />\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="form-group" ng-class="{\'has-error\': (passwordSignInForm.$submitted || passwordSignInForm.password.$touched) && passwordSignInForm.password.$error.required}">\n' +
    '          <label class="sr-only" for="password">Password</label>\n' +
    '          <div ng-show="passwordSignInForm.$submitted || passwordSignInForm.password.$touched">\n' +
    '            <div class="input-error" ng-show="passwordSignInForm.password.$error.required">Missing password.</div>\n' +
    '          </div>\n' +
    '          <div class="left-inner-addon forgot-link-addon">\n' +
    '            <i class="fa fa-lock inner-addon"></i>\n' +
    '            <input class="form-control input-lg" type="password" name="password" id="password" placeholder="Password"\n' +
    '                   ng-required="true" ng-model="signIn.data.password"/>\n' +
    '            <a class="forgot-password-link" ui-sref="accountPassword.recover">Forgot?</a>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="sign-up-page-btn-create">\n' +
    '          <button type="submit" class="btn btn-primary btn-lg btn-block"\n' +
    '                  ng-disabled=\'signIn.isLoading\'>\n' +
    '            {{signIn.isLoading ? \'Loading ...\' : \'Login\'}}\n' +
    '          </button>\n' +
    '          <div class="sign-up-terms">\n' +
    '            Don\'t have an account? <a ui-sref="accountSignInUp.accountSignUp">Sign up</a>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('account/business-account.tpl.html',
    '<div class="modal-content-container business-account">\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-12">\n' +
    '      <div class="row">\n' +
    '        <div class="col-xs-12  modal-content-title">\n' +
    '          <span class="sub-title">Activate Business Account</span>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <uib-alert ng-repeat="alert in businessAccount.alerts.getList()" type="{{alert.type}}" close="businessAccount.alerts.remove($index)">{{alert.message}}</uib-alert>\n' +
    '\n' +
    '      <div class="row">\n' +
    '        <div class="col-xs-12">\n' +
    '          <div class="steps">\n' +
    '\n' +
    '            <div class="step" data-step-id="0">\n' +
    '              Company Details\n' +
    '            </div>\n' +
    '            <div class="step-separator no-animate"\n' +
    '                 data-after-step-id="0">\n' +
    '              <span class="fa fa-chevron-right"></span>\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="step" data-step-id="1">\n' +
    '              Subscription Type\n' +
    '            </div>\n' +
    '            <div class="step-separator" data-after-step-id="1">\n' +
    '              <span class="fa fa-chevron-right"></span>\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="step" data-step-id="2" ng-if="!businessAccount.hasRewardCode">\n' +
    '              Reward Code\n' +
    '            </div>\n' +
    '            <div class="step-separator no-animate" data-after-step-id="2" ng-if="!businessAccount.hasRewardCode">\n' +
    '              <span class="fa fa-chevron-right"></span>\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="step no-animate"\n' +
    '                 data-step-id="3">\n' +
    '              Payment Details\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '\n' +
    '      <form name="businessAccountForm">\n' +
    '\n' +
    '      <!-- Step container -->\n' +
    '      <div class="stepped-wrapper" content-slider>\n' +
    '        <div class="stepped-container">\n' +
    '\n' +
    '          <!-- Business Details -->\n' +
    '          <div class="step-content" data-step-id="0">\n' +
    '            <div class="row">\n' +
    '              <div class="col-xs-12">\n' +
    '                <h4>Company Details</h4>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div class="row form-section-body">\n' +
    '              <div class="col-xs-12 col-sm-12">\n' +
    '                <div class="form-group"\n' +
    '                     ng-class="{\'has-error\': (businessAccountForm.$submitted || businessAccountForm.businessName.$touched) && businessAccountForm.businessName.$error.required}">\n' +
    '                  <label class="sr-only" for="businessName">Company / Organisation</label>\n' +
    '                  <div ng-show="businessAccountForm.$submitted || businessAccountForm.businessName.$touched">\n' +
    '                    <div class="input-error" ng-show="businessAccountForm.businessName.$error.required">Company /\n' +
    '                      Organisation is a required field.\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '                  <div class="left-inner-addon">\n' +
    '                    <i class="fa fa-briefcase inner-addon"></i>\n' +
    '                    <input class="form-control input-lg" type="text" name="businessName" id="businessName"\n' +
    '                           placeholder="Company / Organisation Name"\n' +
    '                           ng-required="true"\n' +
    '                           ng-model="businessAccount.data.businessName" />\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '\n' +
    '              <div class="col-xs-12 col-sm-12">\n' +
    '                <div class="form-group" ng-class="{\'has-error\': (businessAccountForm.$submitted || businessAccountForm.industry.$touched) && businessAccountForm.industry.$error[\'autocomplete-required\']}">\n' +
    '                  <label class="sr-only" for="industry">Classification</label>\n' +
    '                  <div ng-show="businessAccountForm.$submitted || businessAccountForm.industry.$touched">\n' +
    '                    <div class="input-error" ng-show="businessAccountForm.industry.$error[\'autocomplete-required\']">Classification is a required field.\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '                  <div class="left-inner-addon">\n' +
    '                    <i class="fa fa-folder-o inner-addon"></i>\n' +
    '                    <angucomplete-alt id="industry"\n' +
    '                                      placeholder="Classification"\n' +
    '                                      pause="100"\n' +
    '                                      selected-object="businessAccount.onClassificationSelect"\n' +
    '                                      local-data="businessAccount.industries"\n' +
    '                                      search-fields="name"\n' +
    '                                      title-field="name"\n' +
    '                                      minlength="2"\n' +
    '                                      match-class="autocomplete-highlight"\n' +
    '                                      input-class="form-control input-lg"\n' +
    '                                      input-name="industry"\n' +
    '                                      field-required="true"\n' +
    '                                      initial-value="businessAccount.selectedClassification"\n' +
    '                    />\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '\n' +
    '\n' +
    '            <!-- Geolocation details -->\n' +
    '            <div class="row">\n' +
    '              <div class="col-xs-12">\n' +
    '                <label class="sr-only" for="location">Location</label>\n' +
    '                <div class="left-inner-addon location-input">\n' +
    '                  <i class="fa fa-map-marker inner-addon"></i>\n' +
    '                  <input class="form-control input-lg"\n' +
    '                         type="text"\n' +
    '                         name="location"\n' +
    '                         id="location"\n' +
    '                         placeholder="Address (type in or use Geolocation)"\n' +
    '                         ng-model="businessAccount.data.addressPostal.address"\n' +
    '                         ng-required="false"\n' +
    '                         location-search\n' +
    '                         input-id="location"\n' +
    '                         full-address="true"\n' +
    '                         place-changed-callback="businessAccount.placeChangedCallback" />\n' +
    '                  <a id="geolocateBtn" style="cursor: pointer;position: absolute;top: 3px;right: 3px;"\n' +
    '                     ng-disabled="businessAccount.isGeolocationDisabled"\n' +
    '                     ng-click="businessAccount.getUserLocationAddress()"\n' +
    '                     class="btn btn-primary">\n' +
    '                    <span class="fa fa-location-arrow no-animate" ng-if="!businessAccount.isGeolocating"></span>\n' +
    '                    <span class="fa fa-circle-o-notch fa-spin-2x no-animate" ng-if="businessAccount.isGeolocating"></span>\n' +
    '                  </a>\n' +
    '                  <div class="geolocation-disabled-msg slide-if" ng-if="businessAccount.isGeolocationDisabled">\n' +
    '                    There was an issue while trying to find your location. <br />\n' +
    '                    Make sure the Location is allowed on your device and try again, or type in your address.\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '\n' +
    '            <!-- Address static image\n' +
    '            <div class="row">\n' +
    '              <div class="col-xs-12 ">\n' +
    '                <div class="location-screenshot">\n' +
    '                  <div class="pending-overlay" ng-if="businessAccount.isRenderingLocation">\n' +
    '                    <span class="fa fa-circle-o-notch fa-spin-2x no-animate"></span>\n' +
    '                  </div>\n' +
    '                  <img src="" />\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div> -->\n' +
    '\n' +
    '            <!-- Address Details -->\n' +
    '            <div class="row location-details slide-if" ng-if="businessAccount.data.addressPostal.addressPostal ||\n' +
    '                                                             businessAccount.data.addressPostal.suburbPostal ||\n' +
    '                                                             businessAccount.data.addressPostal.postCodePostal ||\n' +
    '                                                             businessAccount.data.addressPostal.idStatePostal ||\n' +
    '                                                             businessAccount.data.addressPostal.idCountryPostal">\n' +
    '              <div class="col-xs-12">\n' +
    '                <div class="address-container">\n' +
    '                  <div class="address-description">\n' +
    '                    Your current address is set to:\n' +
    '                  </div>\n' +
    '                  <div class="address-value">\n' +
    '                    {{businessAccount.data.addressPostal | address:\'au\'}}\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="row location-details missing-fields slide-if" ng-if="businessAccount.isAddressIncomplete()">\n' +
    '              <div class="col-xs-12">\n' +
    '                <div class="address-container missing-fields">\n' +
    '                  <div class="address-description">\n' +
    '                    <span ng-if="!businessAccount.data.addressPostal.addressPostal &&\n' +
    '                                 !businessAccount.data.addressPostal.suburbPostal &&\n' +
    '                                 !businessAccount.data.addressPostal.postCodePostal &&\n' +
    '                                 !businessAccount.data.addressPostal.idStatePostal &&\n' +
    '                                 !businessAccount.data.addressPostal.idCountryPostal">\n' +
    '                      We don\'t have any of your address information on file yet, please type in the address or use the Geolocation button and provide us with the following information\n' +
    '                    </span>\n' +
    '                    <span ng-if="businessAccount.data.addressPostal.addressPostal ||\n' +
    '                                 businessAccount.data.addressPostal.suburbPostal ||\n' +
    '                                 businessAccount.data.addressPostal.postCodePostal ||\n' +
    '                                 businessAccount.data.addressPostal.idStatePostal ||\n' +
    '                                 businessAccount.data.addressPostal.idCountryPostal">\n' +
    '                      Even though you have provided some address information, we still need you to provide us with the additional info\n' +
    '                    </span>\n' +
    '                  </div>\n' +
    '                  <div class="address-value">\n' +
    '                    <div class="missing-val slide-if" ng-if="!businessAccount.data.addressPostal.numberPostal">\n' +
    '                      Street Number\n' +
    '                    </div>\n' +
    '                    <div class="missing-val slide-if" ng-if="!businessAccount.data.addressPostal.addressPostal">\n' +
    '                      Street Name\n' +
    '                    </div>\n' +
    '                    <div class="missing-val slide-if" ng-if="!businessAccount.data.addressPostal.suburbPostal">\n' +
    '                      Suburb\n' +
    '                    </div>\n' +
    '                    <div class="missing-val slide-if" ng-if="!businessAccount.data.addressPostal.postCodePostal">\n' +
    '                      Postcode\n' +
    '                    </div>\n' +
    '                    <div class="missing-val slide-if" ng-if="!businessAccount.data.addressPostal.idStatePostal">\n' +
    '                      State\n' +
    '                    </div>\n' +
    '                    <div class="missing-val slide-if" ng-if="!businessAccount.data.addressPostal.idCountryPostal">\n' +
    '                      Country\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '\n' +
    '\n' +
    '            <div class="step-buttons"\n' +
    '                 data-active-step-id="2">\n' +
    '\n' +
    '              <button class="btn btn-primary no-animate"\n' +
    '                      data-step-to="1"\n' +
    '                      ng-click="businessAccount.onDataChange(\'name\');"\n' +
    '                      ng-disabled="!businessAccount.data.businessName ||\n' +
    '                                   !businessAccount.data.idClassification ||\n' +
    '                                  !businessAccount.data.addressPostal.addressPostal ||\n' +
    '                                  !businessAccount.data.addressPostal.suburbPostal ||\n' +
    '                                  !businessAccount.data.addressPostal.postCodePostal ||\n' +
    '                                  !businessAccount.data.addressPostal.idStatePostal ||\n' +
    '                                  !businessAccount.data.addressPostal.idCountryPostal">\n' +
    '                Next\n' +
    '              </button>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '\n' +
    '          <!-- Subscription -->\n' +
    '          <div class="step-content" data-step-id="1">\n' +
    '            <div>\n' +
    '              <div class="row">\n' +
    '                <div class="col-xs-12">\n' +
    '                  <h4>Subscription</h4>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '\n' +
    '              <div class="row" style="white-space: normal;">\n' +
    '                <div class="col-xs-12">\n' +
    '                  <div class="form-group">\n' +
    '                    <span ng-if="businessAccount.pricingDetails && businessAccount.pricingDetails.length == 1">\n' +
    '                      Currently there is only one plan to choose from. Click \'Next\' to continue.\n' +
    '                    </span>\n' +
    '                    <span ng-if="businessAccount.pricingDetails && businessAccount.pricingDetails.length > 1">\n' +
    '                      Select (click on) the subscription type:\n' +
    '                    </span>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '\n' +
    '                <div class="col-xs-12 no-select subscription-selection form-group">\n' +
    '                  <label class="radio-inline" ng-repeat="p in businessAccount.pricingDetails" for="{{p.id}}" ng-class="{\'active\': businessAccount.data.businessMarketingPayment == p.id}">\n' +
    '                    <input type="radio" name="businessMarketingPayment"\n' +
    '                           id="{{p.id}}"\n' +
    '                           value="{{p.id}}"\n' +
    '                           ng-required="true"\n' +
    '                           ng-model="businessAccount.data.businessMarketingPayment"\n' +
    '                           ng-change="businessAccount.updateSummary()"/>\n' +
    '                    <div class="subscr-type">{{p.label}}</div>\n' +
    '                    <div class="subscr-desc" style="color:transparent;">(no description)</div>\n' +
    '                    <div class="subscr-value">{{p.priceInclGst | currency:\'$\':0}}</div>\n' +
    '                    <div class="subscr-desc" style="margin-top: -5px;">(GST incls.)</div>\n' +
    '                    <div class="subscr-check fa fa-check"></div>\n' +
    '                  </label>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="step-buttons" data-active-step-id="1">\n' +
    '              <button class="btn btn-primary" data-step-to="0">\n' +
    '                Back\n' +
    '              </button>\n' +
    '              <button class="btn btn-primary" data-step-to="{{businessAccount.hasRewardCode? \'3\' : \'2\'}}"\n' +
    '                      ng-disabled="!businessAccount.data.businessMarketingPayment"\n' +
    '                      ng-click="businessAccount.onDataChange(\'name\');businessAccount.updateSummary();">\n' +
    '                Next\n' +
    '              </button>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '\n' +
    '          <!-- Subscription - Reward Code / Lead Reward -->\n' +
    '          <div class="step-content" data-step-id="2">\n' +
    '            <div ng-if="!businessAccount.hasRewardCode">\n' +
    '              <div class="row form-group reward-selection">\n' +
    '                <div>\n' +
    '                    <div class="control-description">\n' +
    '                      Set up your own Reward Code:\n' +
    '                    </div>\n' +
    '                    <div class="form-group" ng-class="{\'has-error\': (businessAccountForm.$submitted || businessAccountForm.rewardCode.$touched) &&\n' +
    '                (businessAccountForm.rewardCode.$error.required || businessAccountForm.rewardCode.$error.maxlength || businessAccountForm.rewardCode.$error.minlength || (businessAccount.rewardCodeChecked && !businessAccount.rewardCodeValid))}">\n' +
    '                      <label class="sr-only" for="rewardCode">Reward Code</label>\n' +
    '                      <div ng-show="businessAccountForm.$submitted || businessAccountForm.rewardCode.$touched">\n' +
    '                        <div class="input-error" ng-show="businessAccountForm.rewardCode.$error.required">Missing Reward Code</div>\n' +
    '                        <div class="input-error" ng-show="businessAccountForm.rewardCode.$error.minlength">Reward Code must be at least 5 characters long</div>\n' +
    '                        <div class="input-error" ng-show="businessAccountForm.rewardCode.$error.maxlength">Reward Code must not be longer than 20 characters</div>\n' +
    '                        <div class="input-error" ng-show="businessAccount.rewardCodeChecked && !businessAccount.rewardCodeValid">This Reward Code is not available, try another one</div>\n' +
    '                      </div>\n' +
    '                      <div class="left-inner-addon">\n' +
    '                        <i ng-show="!businessAccount.rewardCodeChecked && !businessAccount.rewardCodeValidating && !businessAccount.rewardCodeValid" class="fa fa-hashtag"></i>\n' +
    '                        <i ng-show="businessAccount.rewardCodeValidating" class="fa fa-circle-o-notch fa-spin-2x"></i>\n' +
    '                        <i ng-show="!businessAccount.rewardCodeValidating && businessAccount.rewardCodeChecked && businessAccount.rewardCodeValid" class="fa fa-check code-container-valid"></i>\n' +
    '                        <i ng-show="!businessAccount.rewardCodeValidating && businessAccount.rewardCodeChecked && !businessAccount.rewardCodeValid" class="fa fa-times code-container-invalid"></i>\n' +
    '                        <input class="form-control input-lg" type="text" name="rewardCode" id="rewardCode"\n' +
    '                               placeholder="Your own Reward Code"\n' +
    '                               ng-minlength="5" ng-maxlength="20"\n' +
    '                               size="20"\n' +
    '                               maxlength="20"\n' +
    '                               ng-change="businessAccount.onRewardCodeChange(); businessAccount.onDataChange(\'code\');"\n' +
    '                               ng-blur="businessAccount.validateRewardCode()"\n' +
    '                               ng-required="true"\n' +
    '                               ng-model="businessAccount.data.rewardCode"\n' +
    '                               popover-placement="\'top\'"\n' +
    '                               popover-trigger="focus"\n' +
    '                               popover-append-to-body="true"\n' +
    '                               popover-class="help"\n' +
    '                               uib-popover="{{businessAccount.rewardCodeHelp}}" />\n' +
    '                      </div>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '\n' +
    '                <div>\n' +
    '                  <div class="control-description">\n' +
    '                    Set the Reward Code value (for successful lead):\n' +
    '                  </div>\n' +
    '                  <div class="form-group" ng-class="{\'has-error\': (businessAccountForm.$submitted || businessAccountForm.successfulLeadPrice.$touched) && (businessAccountForm.successfulLeadPrice.$error.required || businessAccountForm.successfulLeadPrice.$error.number || businessAccountForm.successfulLeadPrice.$error.min)}">\n' +
    '                    <label class="sr-only" for="successfulLeadPrice">Successful Lead Reward</label>\n' +
    '                    <div ng-show="businessAccountForm.$submitted || businessAccountForm.successfulLeadPrice.$touched">\n' +
    '                      <div class="input-error" ng-show="businessAccountForm.successfulLeadPrice.$error.number">Invalid successful lead value</div>\n' +
    '                      <div class="input-error" ng-show="businessAccountForm.successfulLeadPrice.$error.required">Missing successful lead value</div>\n' +
    '                      <div class="input-error" ng-show="businessAccountForm.successfulLeadPrice.$error.min">Successful lead value must not be negative</div>\n' +
    '                    </div>\n' +
    '                    <div class="left-inner-addon">\n' +
    '                      <i class="fa fa-dollar inner-addon"></i>\n' +
    '                      <input class="form-control input-lg"\n' +
    '                             type="number"\n' +
    '                             name="successfulLeadPrice"\n' +
    '                             id="successfulLeadPrice"\n' +
    '                             placeholder="Successful lead reward"\n' +
    '                             ng-required="true"\n' +
    '                             min="0"\n' +
    '                             ng-change="businessAccount.onDataChange(\'value\');"\n' +
    '                             ng-model="businessAccount.data.successfulLeadPrice" />\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '\n' +
    '              <div ng-show="businessAccount.showRewardCard">\n' +
    '                <div class="reward-selection" style="flex-wrap: nowrap;">\n' +
    '                  <div class="reward-card-image">\n' +
    '                    <object type="image/svg+xml" id="RewardCard-back" data="../images/reward-card-back.svg" tabindex="-1">\n' +
    '                    </object>\n' +
    '                  </div>\n' +
    '                  <div class="reward-card-image">\n' +
    '                    <object type="image/svg+xml" id="RewardCard-front" data="../images/reward-card-front.svg" tabindex="-1">\n' +
    '                    </object>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '                <div class="reward-card-warning">\n' +
    '                  * Reward cards are purchased separately. <br/>\n' +
    '                  For more information please contact the <a href="mailto:sales@myleadpod.com">sales support</a>.\n' +
    '                </div>\n' +
    '              </div>\n' +
    '\n' +
    '              <div class="step-buttons"\n' +
    '                   data-active-step-id="1">\n' +
    '                <button class="btn btn-primary" data-step-to="1">\n' +
    '                  Back\n' +
    '                </button>\n' +
    '                <button class="btn btn-primary no-animate"\n' +
    '                        data-step-to="3"\n' +
    '                        ng-click="businessAccount.updateSummary();"\n' +
    '                        ng-disabled="businessAccount.rewardCodeValidating || !businessAccount.rewardCodeChecked || !businessAccount.data.rewardCode || !businessAccount.isLeadPriceValid()">\n' +
    '                  Next\n' +
    '                </button>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '\n' +
    '          <!-- Payment details -->\n' +
    '          <div class="step-content" data-step-id="3">\n' +
    '\n' +
    '            <div class="close-lead-invoice form-group">\n' +
    '              <div class="row">\n' +
    '                <div class="col-xs-12">\n' +
    '                  <h4>Payment Summary</h4>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="row">\n' +
    '                <div class="col-xs-6">\n' +
    '                  Subscription fee\n' +
    '                </div>\n' +
    '                <div class="col-xs-6 text-right">\n' +
    '                  {{businessAccount.paymentSummary.price | currency:\'$\':2}}\n' +
    '                </div>\n' +
    '              </div>\n' +
    '\n' +
    '\n' +
    '              <div class="row">\n' +
    '                <div class="col-xs-6">\n' +
    '                  GST\n' +
    '                </div>\n' +
    '                <div class="col-xs-6 text-right">\n' +
    '                  {{businessAccount.paymentSummary.tax | currency:"$":2}}\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="row" style="border-top: 1px lightgray dotted; padding-top: 5px; margin-top: 3px;">\n' +
    '                <div class="col-xs-6">\n' +
    '                  <strong>Total*</strong>\n' +
    '                </div>\n' +
    '                <div class="col-xs-6 text-right">\n' +
    '                  <strong>{{businessAccount.paymentSummary.total | currency:"$":2}}</strong>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="col-xs-12" style="color: lightgray;margin: 15px 0px;font-size: 12px;color: #676767;white-space: normal;" >\n' +
    '                * You will be periodically charged this amount based on the chosen subscription type\n' +
    '              </div>\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="row">\n' +
    '              <div class="col-xs-12">\n' +
    '                <h4>Payment Options</h4>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="row">\n' +
    '              <div class="col-xs-12">\n' +
    '                <credit-card credit-card="businessAccount.creditCard" cc-data="businessAccount.data.creditCard" />\n' +
    '              </div>\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="step-buttons"\n' +
    '                 data-active-step-id="2">\n' +
    '              <button class="btn btn-primary" data-step-to="{{businessAccount.hasRewardCode? \'1\' : \'2\'}}">\n' +
    '                Back\n' +
    '              </button>\n' +
    '              <button class="btn btn-primary"\n' +
    '                      ng-class="{loading: businessAccount.isLoading}"\n' +
    '                      ng-disabled="businessAccount.isLoading || businessAccountForm.$invalid || (!businessAccount.hasRewardCode && businessAccount.rewardCodeChecked && !businessAccount.rewardCodeValid)"\n' +
    '                      ng-click="businessAccount.save()">\n' +
    '                {{businessAccount.isLoading ? \'Loading ...\' : \'Pay & Activate\' }}\n' +
    '              </button>\n' +
    '            </div>\n' +
    '\n' +
    '          </div>\n' +
    '\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="row modal-content-buttons">\n' +
    '    <div class="col-xs-12 text-right">\n' +
    '      <button class="btn btn-default" ng-disabled="businessAccount.isLoading" ng-click="businessAccount.cancel()">\n' +
    '        Cancel\n' +
    '      </button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('account/business-account-trial.tpl.html',
    '<div class="modal-content-container business-account">\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-12">\n' +
    '      <div class="row">\n' +
    '        <div class="col-xs-12  modal-content-title">\n' +
    '          <span class="sub-title">Activate Business Account (Trial)</span>\n' +
    '          <div class="m-close-btn" ng-click="businessAccount.cancel();">\n' +
    '            <button class="glyphicon glyphicon-remove"></button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <uib-alert ng-repeat="alert in businessAccount.alerts.getList()" type="{{alert.type}}" close="businessAccount.alerts.remove($index)">{{alert.message}}</uib-alert>\n' +
    '\n' +
    '      <div class="row">\n' +
    '        <div class="col-xs-12">\n' +
    '          <div class="steps">\n' +
    '            <div class="step" data-step-id="0">\n' +
    '              Company Details\n' +
    '            </div>\n' +
    '            <div class="step-separator no-animate"\n' +
    '                 data-after-step-id="0">\n' +
    '              <span class="fa fa-chevron-right"></span>\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="step" data-step-id="1">\n' +
    '              Reward Code\n' +
    '            </div>\n' +
    '            <div class="step-separator" data-after-step-id="1">\n' +
    '              <span class="fa fa-chevron-right"></span>\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="step" data-step-id="2">\n' +
    '              Confirmation\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '\n' +
    '      <form name="businessAccountForm">\n' +
    '\n' +
    '        <!-- Step container -->\n' +
    '        <div class="stepped-wrapper" content-slider>\n' +
    '          <div class="stepped-container">\n' +
    '\n' +
    '            <!-- Business Details -->\n' +
    '            <div class="step-content" data-step-id="0">\n' +
    '              <div class="row">\n' +
    '                <div class="col-xs-12">\n' +
    '                  <h4>Company Details</h4>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="row form-section-body">\n' +
    '                <div class="col-xs-12 col-sm-12">\n' +
    '                  <div class="form-group"\n' +
    '                       ng-class="{\'has-error\': (businessAccountForm.$submitted || businessAccountForm.businessName.$touched) && businessAccountForm.businessName.$error.required}">\n' +
    '                    <label class="sr-only" for="businessName">Company / Organisation</label>\n' +
    '                    <div ng-show="businessAccountForm.$submitted || businessAccountForm.businessName.$touched">\n' +
    '                      <div class="input-error" ng-show="businessAccountForm.businessName.$error.required">Company /\n' +
    '                        Organisation is a required field.\n' +
    '                      </div>\n' +
    '                    </div>\n' +
    '                    <div class="left-inner-addon">\n' +
    '                      <i class="fa fa-briefcase inner-addon"></i>\n' +
    '                      <input class="form-control input-lg" type="text" name="businessName" id="businessName"\n' +
    '                             placeholder="Company / Organisation Name"\n' +
    '                             ng-required="true"\n' +
    '                             ng-model="businessAccount.data.businessName" />\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '\n' +
    '                <div class="col-xs-12 col-sm-12">\n' +
    '                  <div class="form-group" ng-class="{\'has-error\': (businessAccountForm.$submitted || businessAccountForm.industry.$touched) && businessAccountForm.industry.$error[\'autocomplete-required\']}">\n' +
    '                    <label class="sr-only" for="industry">Classification</label>\n' +
    '                    <div ng-show="businessAccountForm.$submitted || businessAccountForm.industry.$touched">\n' +
    '                      <div class="input-error" ng-show="businessAccountForm.industry.$error[\'autocomplete-required\']">Classification is a required field.\n' +
    '                      </div>\n' +
    '                    </div>\n' +
    '                    <div class="left-inner-addon">\n' +
    '                      <i class="fa fa-folder-o inner-addon"></i>\n' +
    '                      <angucomplete-alt id="industry"\n' +
    '                                        placeholder="Classification"\n' +
    '                                        pause="100"\n' +
    '                                        selected-object="businessAccount.onClassificationSelect"\n' +
    '                                        local-data="businessAccount.industries"\n' +
    '                                        search-fields="name"\n' +
    '                                        title-field="name"\n' +
    '                                        minlength="2"\n' +
    '                                        match-class="autocomplete-highlight"\n' +
    '                                        input-class="form-control input-lg"\n' +
    '                                        input-name="industry"\n' +
    '                                        field-required="true"\n' +
    '                                        initial-value="businessAccount.selectedClassification"\n' +
    '                      />\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '\n' +
    '              <!-- Address Details -->\n' +
    '\n' +
    '              <!-- Geolocation details -->\n' +
    '              <div class="row">\n' +
    '                <div class="col-xs-12" ng-class="{\'has-error\': (businessAccountForm.$submitted || businessAccountForm.location.$touched) && businessAccount.isAddressIncomplete()}">\n' +
    '                  <label class="sr-only" for="location">Location</label>\n' +
    '                  <div ng-show="businessAccountForm.$submitted || businessAccountForm.businessName.$touched">\n' +
    '                    <div class="input-error">Address is Incomplete.\n' +
    '                      <span style="color:gray;"> (type in or use the geolocation)</span>\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '                  <div class="left-inner-addon location-input">\n' +
    '                    <i class="fa fa-map-marker inner-addon"></i>\n' +
    '                    <input class="form-control input-lg"\n' +
    '                           type="text"\n' +
    '                           name="location"\n' +
    '                           id="location"\n' +
    '                           placeholder="Address (type in or use Geolocation)"\n' +
    '                           ng-model="businessAccount.data.addressPostal.address"\n' +
    '                           ng-required="false"\n' +
    '                           location-search\n' +
    '                           input-id="location"\n' +
    '                           full-address="true"\n' +
    '                           place-changed-callback="businessAccount.placeChangedCallback" />\n' +
    '                    <a id="geolocateBtn" style="cursor: pointer;position: absolute;top: 3px;right: 3px;"\n' +
    '                       ng-disabled="businessAccount.isGeolocationDisabled"\n' +
    '                       ng-click="businessAccount.getUserLocationAddress()"\n' +
    '                       class="btn btn-primary">\n' +
    '                      <span class="fa fa-location-arrow no-animate" ng-if="!businessAccount.isGeolocating"></span>\n' +
    '                      <span class="fa fa-circle-o-notch fa-spin-2x no-animate" ng-if="businessAccount.isGeolocating"></span>\n' +
    '                    </a>\n' +
    '                    <div class="geolocation-disabled-msg slide-if" ng-if="businessAccount.isGeolocationDisabled">\n' +
    '                      There was an issue while trying to find your location. <br />\n' +
    '                      Make sure the Location is allowed on your device and try again, or type in your address.\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '\n' +
    '              <!-- Address static image\n' +
    '              <div class="row">\n' +
    '                <div class="col-xs-12 ">\n' +
    '                  <div class="location-screenshot">\n' +
    '                    <div class="pending-overlay" ng-if="businessAccount.isRenderingLocation">\n' +
    '                      <span class="fa fa-circle-o-notch fa-spin-2x no-animate"></span>\n' +
    '                    </div>\n' +
    '                    <img src="" />\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div> -->\n' +
    '\n' +
    '              <!-- Address Details -->\n' +
    '              <div class="row location-details slide-if" ng-if="businessAccount.data.addressPostal.addressPostal ||\n' +
    '                                                             businessAccount.data.addressPostal.suburbPostal ||\n' +
    '                                                             businessAccount.data.addressPostal.postCodePostal ||\n' +
    '                                                             businessAccount.data.addressPostal.idStatePostal ||\n' +
    '                                                             businessAccount.data.addressPostal.idCountryPostal">\n' +
    '                <div class="col-xs-12">\n' +
    '                  <div class="address-container">\n' +
    '                    <div class="address-description">\n' +
    '                      Your current address is set to:\n' +
    '                    </div>\n' +
    '                    <div class="address-value">\n' +
    '                      {{businessAccount.data.addressPostal | address:\'au\'}}\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '\n' +
    '              <div class="row location-details missing-fields slide-if" ng-if="businessAccount.isAddressIncomplete()">\n' +
    '                <div class="col-xs-12">\n' +
    '                  <div class="address-container missing-fields">\n' +
    '                    <div class="address-description">\n' +
    '                    <span ng-if="!businessAccount.data.addressPostal.addressPostal &&\n' +
    '                                 !businessAccount.data.addressPostal.suburbPostal &&\n' +
    '                                 !businessAccount.data.addressPostal.postCodePostal &&\n' +
    '                                 !businessAccount.data.addressPostal.idStatePostal &&\n' +
    '                                 !businessAccount.data.addressPostal.idCountryPostal">\n' +
    '                      We don\'t have any of your address information on file yet, please type in the address or use the Geolocation button and provide us with the following information\n' +
    '                    </span>\n' +
    '                      <span ng-if="businessAccount.data.addressPostal.addressPostal ||\n' +
    '                                 businessAccount.data.addressPostal.suburbPostal ||\n' +
    '                                 businessAccount.data.addressPostal.postCodePostal ||\n' +
    '                                 businessAccount.data.addressPostal.idStatePostal ||\n' +
    '                                 businessAccount.data.addressPostal.idCountryPostal">\n' +
    '                      Even though you have provided some address information, we still need you to provide us with the additional info\n' +
    '                    </span>\n' +
    '                    </div>\n' +
    '                    <div class="address-value">\n' +
    '                      <div class="missing-val slide-if" ng-if="!businessAccount.data.addressPostal.numberPostal">\n' +
    '                        Street Number\n' +
    '                      </div>\n' +
    '                      <div class="missing-val slide-if" ng-if="!businessAccount.data.addressPostal.addressPostal">\n' +
    '                        Street Name\n' +
    '                      </div>\n' +
    '                      <div class="missing-val slide-if" ng-if="!businessAccount.data.addressPostal.suburbPostal">\n' +
    '                        Suburb\n' +
    '                      </div>\n' +
    '                      <div class="missing-val slide-if" ng-if="!businessAccount.data.addressPostal.postCodePostal">\n' +
    '                        Postcode\n' +
    '                      </div>\n' +
    '                      <div class="missing-val slide-if" ng-if="!businessAccount.data.addressPostal.idStatePostal">\n' +
    '                        State\n' +
    '                      </div>\n' +
    '                      <div class="missing-val slide-if" ng-if="!businessAccount.data.addressPostal.idCountryPostal">\n' +
    '                        Country\n' +
    '                      </div>\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '\n' +
    '              <div class="step-buttons"\n' +
    '                   data-active-step-id="2">\n' +
    '\n' +
    '                <button class="btn btn-primary no-animate"\n' +
    '                        data-step-to="1"\n' +
    '                        ng-click="businessAccount.onDataChange(\'name\');"\n' +
    '                        ng-disabled="!businessAccount.data.businessName ||\n' +
    '                                   !businessAccount.data.idClassification ||\n' +
    '                                  !businessAccount.data.addressPostal.addressPostal ||\n' +
    '                                  !businessAccount.data.addressPostal.suburbPostal ||\n' +
    '                                  !businessAccount.data.addressPostal.postCodePostal ||\n' +
    '                                  !businessAccount.data.addressPostal.idStatePostal ||\n' +
    '                                  !businessAccount.data.addressPostal.idCountryPostal">\n' +
    '                  Next\n' +
    '                </button>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '\n' +
    '\n' +
    '            <!-- Subscription - Reward Code / Lead Reward -->\n' +
    '            <div class="step-content" data-step-id="1">\n' +
    '              <div class="row form-group reward-selection">\n' +
    '                <div>\n' +
    '                  <div class="control-description">\n' +
    '                    Set up your own Reward Code:\n' +
    '                    <i class="no-select info-help" role="button" tabIndex="0" style="margin-left: 15px;"\n' +
    '                       popover-trigger="click, outsideClick"\n' +
    '                       popover-append-to-body="true"\n' +
    '                       popover-class="help"\n' +
    '                       uib-popover="{{businessAccount.rewardCodeHelp1}}">?</i>\n' +
    '                  </div>\n' +
    '                  <div class="form-group" ng-class="{\'has-error\': (businessAccountForm.$submitted || businessAccountForm.rewardCode.$touched) &&\n' +
    '              (businessAccountForm.rewardCode.$error.required || businessAccountForm.rewardCode.$error.maxlength || businessAccountForm.rewardCode.$error.minlength || (businessAccount.rewardCodeChecked && !businessAccount.rewardCodeValid))}">\n' +
    '                    <label class="sr-only" for="rewardCode">Reward Code</label>\n' +
    '                    <div ng-show="businessAccountForm.$submitted || businessAccountForm.rewardCode.$touched">\n' +
    '                      <div class="input-error" ng-show="businessAccountForm.rewardCode.$error.required">Missing Reward Code</div>\n' +
    '                      <div class="input-error" ng-show="businessAccountForm.rewardCode.$error.minlength">Reward Code must be at least 5 characters long</div>\n' +
    '                      <div class="input-error" ng-show="businessAccountForm.rewardCode.$error.maxlength">Reward Code must not be longer than 20 characters</div>\n' +
    '                      <div class="input-error" ng-show="businessAccount.rewardCodeChecked && !businessAccount.rewardCodeValid">This Reward Code is not available, try another one</div>\n' +
    '                    </div>\n' +
    '                    <div class="left-inner-addon">\n' +
    '                      <i ng-show="!businessAccount.rewardCodeChecked && !businessAccount.rewardCodeValidating && !businessAccount.rewardCodeValid" class="fa fa-hashtag"></i>\n' +
    '                      <i ng-show="businessAccount.rewardCodeValidating" class="fa fa-circle-o-notch fa-spin-2x"></i>\n' +
    '                      <i ng-show="!businessAccount.rewardCodeValidating && businessAccount.rewardCodeChecked && businessAccount.rewardCodeValid" class="fa fa-check code-container-valid"></i>\n' +
    '                      <i ng-show="!businessAccount.rewardCodeValidating && businessAccount.rewardCodeChecked && !businessAccount.rewardCodeValid" class="fa fa-times code-container-invalid"></i>\n' +
    '                      <input class="form-control input-lg" type="text" name="rewardCode" id="rewardCode"\n' +
    '                             placeholder="Your own Reward Code"\n' +
    '                             ng-minlength="5" ng-maxlength="20"\n' +
    '                             size="20"\n' +
    '                             maxlength="20"\n' +
    '                             ng-change="businessAccount.onRewardCodeChange(); businessAccount.onDataChange(\'code\');"\n' +
    '                             ng-blur="businessAccount.validateRewardCode()"\n' +
    '                             ng-required="true"\n' +
    '                             ng-model="businessAccount.data.rewardCode"\n' +
    '                             popover-placement="\'top\'"\n' +
    '                             popover-trigger="focus"\n' +
    '                             popover-append-to-body="true"\n' +
    '                             popover-class="help"\n' +
    '                             uib-popover="{{businessAccount.rewardCodeHelp2}}" />\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '\n' +
    '                <div>\n' +
    '                  <div class="control-description">\n' +
    '                    Set the Reward Code value (for successful lead):\n' +
    '                  </div>\n' +
    '                  <div class="form-group" ng-class="{\'has-error\': (businessAccountForm.$submitted || businessAccountForm.successfulLeadPrice.$touched) && (businessAccountForm.successfulLeadPrice.$error.required || businessAccountForm.successfulLeadPrice.$error.number || businessAccountForm.successfulLeadPrice.$error.min)}">\n' +
    '                    <label class="sr-only" for="successfulLeadPrice">Successful Lead Reward</label>\n' +
    '                    <div ng-show="businessAccountForm.$submitted || businessAccountForm.successfulLeadPrice.$touched">\n' +
    '                      <div class="input-error" ng-show="businessAccountForm.successfulLeadPrice.$error.number">Invalid successful lead value</div>\n' +
    '                      <div class="input-error" ng-show="businessAccountForm.successfulLeadPrice.$error.required">Missing successful lead value</div>\n' +
    '                      <div class="input-error" ng-show="businessAccountForm.successfulLeadPrice.$error.min">Successful lead value must not be negative</div>\n' +
    '                    </div>\n' +
    '                    <div class="left-inner-addon">\n' +
    '                      <i class="fa fa-dollar inner-addon"></i>\n' +
    '                      <input class="form-control input-lg"\n' +
    '                             type="number"\n' +
    '                             name="successfulLeadPrice"\n' +
    '                             id="successfulLeadPrice"\n' +
    '                             placeholder="Successful lead reward"\n' +
    '                             ng-required="true"\n' +
    '                             min="0"\n' +
    '                             ng-change="businessAccount.onDataChange(\'value\');"\n' +
    '                             ng-model="businessAccount.data.successfulLeadPrice" />\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '\n' +
    '              <div ng-show="businessAccount.showRewardCard">\n' +
    '                <div class="reward-selection" style="flex-wrap: nowrap;">\n' +
    '                  <div class="reward-card-image">\n' +
    '                    <object type="image/svg+xml" id="RewardCard-back" data="../images/reward-card-back.svg" tabindex="-1">\n' +
    '                    </object>\n' +
    '                  </div>\n' +
    '                  <div class="reward-card-image">\n' +
    '                    <object type="image/svg+xml" id="RewardCard-front" data="../images/reward-card-front.svg" tabindex="-1">\n' +
    '                    </object>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '                <div class="reward-card-warning">\n' +
    '                  * Reward cards are purchased separately. <br/>\n' +
    '                  For more information please contact the <a href="mailto:sales@myleadpod.com">sales support</a>.\n' +
    '                </div>\n' +
    '              </div>\n' +
    '\n' +
    '              <div class="step-buttons"\n' +
    '                   data-active-step-id="1">\n' +
    '                <button class="btn btn-primary" data-step-to="0">\n' +
    '                  Back\n' +
    '                </button>\n' +
    '                <button class="btn btn-primary no-animate"\n' +
    '                        data-step-to="2"\n' +
    '                        ng-disabled="businessAccount.rewardCodeValidating || !businessAccount.rewardCodeChecked || !businessAccount.data.rewardCode || !businessAccount.isLeadPriceValid()">\n' +
    '                  Next\n' +
    '                </button>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '\n' +
    '\n' +
    '            <!-- Payment details -->\n' +
    '            <div class="step-content" data-step-id="2">\n' +
    '\n' +
    '              <div class="row">\n' +
    '                <div class="col-xs-12 text-center" style="white-space: normal;">\n' +
    '                  <h4>You are now ready to start your trial business account subscription with</h4>\n' +
    '                  <img src="../images/logo.svg" style="width: 100px;margin: 10px 0px;" />\n' +
    '                  <h5>This evaluation subscription will expire on {{businessAccount.trialData.trialExpiryDate | date:\'d MMM yyyy\'}}</h5>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '\n' +
    '              <div class="step-buttons"\n' +
    '                   data-active-step-id="2">\n' +
    '                <button class="btn btn-primary" data-step-to="1">\n' +
    '                  Back\n' +
    '                </button>\n' +
    '                <button class="btn btn-primary"\n' +
    '                        ng-class="{loading: businessAccount.isLoading}"\n' +
    '                        ng-disabled="businessAccount.isLoading || businessAccountForm.$invalid || (businessAccount.rewardCodeChecked && !businessAccount.rewardCodeValid)"\n' +
    '                        ng-click="businessAccount.save()">\n' +
    '                  {{businessAccount.isLoading ? \'Loading ...\' : \'Start Trial!\' }}\n' +
    '                </button>\n' +
    '              </div>\n' +
    '\n' +
    '            </div>\n' +
    '\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <!--<div class="row modal-content-buttons">\n' +
    '    <div class="col-xs-12 text-right">\n' +
    '      <button class="btn btn-default" ng-disabled="businessAccount.isLoading" ng-click="businessAccount.cancel()">\n' +
    '        Cancel\n' +
    '      </button>\n' +
    '    </div>\n' +
    '  </div>-->\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('account/activate.tpl.html',
    '<div class="row">\n' +
    '  <div class="col-md-12 text-center">\n' +
    '    <h1 class="page-header sign-up-page-header">Activate your account</h1>\n' +
    '    <div class="sign-up-page-sub-header">\n' +
    '      Please fill in the form to activate your account\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="row sign-up-page-container">\n' +
    '  <div class="sign-in-page-content text-center activate-form-content">\n' +
    '    <uib-alert ng-repeat="alert in activate.alerts.getList()" type="{{alert.type}}" close="activate.alerts.remove($index)">{{alert.message}}</uib-alert>\n' +
    '    <form name="activateAccountForm" ng-submit="activate.activate()">\n' +
    '\n' +
    '      <div ng-if="activate.passwordVisible" class="form-group">\n' +
    '        <label class="sr-only" for="password">Create Your Password</label>\n' +
    '        <div class="left-inner-addon">\n' +
    '          <i class="fa fa-lock inner-addon"></i>\n' +
    '          <input class="form-control input-lg" type="password" name="password" id="password"\n' +
    '                 placeholder="Create Your Password" ng-required="true" ng-model="activate.data.password" />\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div ng-if="activate.emailVisible" class="form-group" ng-class="{\'has-error\': (activateAccountForm.$submitted || activateAccountForm.email.$touched) && (activateAccountForm.email.$error.required || activateAccountForm.email.$error.email)}">\n' +
    '        <label class="sr-only" for="email">Email</label>\n' +
    '        <div ng-show="activateAccountForm.$submitted || activateAccountForm.email.$touched">\n' +
    '          <div class="input-error" ng-show="activateAccountForm.email.$error.required">Tell us your email.</div>\n' +
    '          <div class="input-error" ng-show="activateAccountForm.email.$error.email">This is not a valid email.</div>\n' +
    '        </div>\n' +
    '        <div class="left-inner-addon">\n' +
    '          <i class="fa fa-envelope-o inner-addon"></i>\n' +
    '          <input class="form-control input-lg" type="email" name="email" id="email"\n' +
    '                 placeholder="Email" ng-required="true" ng-model="activate.data.email" />\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="form-group" ng-class="{\'has-error\': (activateAccountForm.$submitted || activateAccountForm.firstName.$touched) && activateAccountForm.firstName.$error.required}">\n' +
    '        <label class="sr-only" for="firstName">First name</label>\n' +
    '        <div ng-show="activateAccountForm.$submitted || activateAccountForm.firstName.$touched">\n' +
    '          <div class="input-error" ng-show="activateAccountForm.firstName.$error.required">Tell us your first name.</div>\n' +
    '        </div>\n' +
    '        <div class="left-inner-addon">\n' +
    '          <i class="fa fa-user inner-addon"></i>\n' +
    '          <input class="form-control input-lg" type="text" name="firstName" id="firstName"\n' +
    '                 placeholder="First name" ng-required="true" ng-model="activate.data.firstName" />\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="form-group" ng-class="{\'has-error\': (activateAccountForm.$submitted || activateAccountForm.lastName.$touched) && activateAccountForm.lastName.$error.required}">\n' +
    '        <label class="sr-only" for="lastName">Last name</label>\n' +
    '        <div ng-show="activateAccountForm.$submitted || activateAccountForm.lastName.$touched">\n' +
    '          <div class="input-error" ng-show="activateAccountForm.lastName.$error.required">Tell us your last name.</div>\n' +
    '        </div>\n' +
    '        <div class="left-inner-addon">\n' +
    '          <i class="fa fa-user inner-addon"></i>\n' +
    '          <input class="form-control input-lg" type="text" name="lastName" id="lastName"\n' +
    '                 placeholder="Last name" ng-required="true" ng-model="activate.data.lastName" />\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="form-group">\n' +
    '        <label class="sr-only" for="location">Location</label>\n' +
    '        <div class="left-inner-addon">\n' +
    '          <i class="fa fa-map-marker inner-addon"></i>\n' +
    '          <input class="form-control input-lg" type="text" name="location" id="location"\n' +
    '                 placeholder="Location" ng-model="activate.data.location"\n' +
    '                 location-search input-id="location" place-changed-callback="activate.placeChangedCallback" />\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="form-group" ng-class="{\'has-error\': (activateAccountForm.$submitted || activateAccountForm.phoneMobile.$touched) && activateAccountForm.phoneMobile.$error.required}">\n' +
    '        <label class="sr-only" for="phoneMobile">Mobile</label>\n' +
    '        <div ng-show="activateAccountForm.$submitted || activateAccountForm.phoneMobile.$touched">\n' +
    '          <div class="input-error" ng-show="activateAccountForm.phoneMobile.$error.required">Tell us your mobile phone number.</div>\n' +
    '        </div>\n' +
    '        <div class="left-inner-addon">\n' +
    '          <i class="fa fa-mobile inner-addon"></i>\n' +
    '          <input class="form-control input-lg" type="text" name="phoneMobile" id="phoneMobile"\n' +
    '                 placeholder="Mobile" ng-required="true" ng-model="activate.data.phoneMobile" />\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="form-group" ng-if="!activate.rewardCodeLabel" ng-class="{\'text-left\': !activate.rewardCodeLabel}">\n' +
    '        <label for="rewardCode">If you have a Reward Code, type it below or leave the field blank\n' +
    '          <i class="no-select info-help" role="button" tabIndex="0" style="margin-left: 15px;"\n' +
    '             popover-trigger="click, outsideClick"\n' +
    '             popover-append-to-body="true"\n' +
    '             popover-class="help"\n' +
    '             uib-popover="{{activate.rewardCodeHelp}}">?</i>\n' +
    '        </label>\n' +
    '        <div class="left-inner-addon">\n' +
    '          <i class="fa fa-hashtag inner-addon"></i>\n' +
    '          <input class="form-control input-lg" type="text" name="rewardCode" id="rewardCode"\n' +
    '                 placeholder="Reward Code (optional)" ng-model="activate.data.rewardCode" ng-readonly="activate.rewardCodeReadOnly" />\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <!--<div class="form-group" ng-if="activate.rewardCodeLabel && activate.rewardCodeReadOnly">\n' +
    '        <label for="rewardCode">Reward Code</label>\n' +
    '        <div class="reward-code-label">\n' +
    '          <i class="fa fa-hashtag inner-addon"></i>\n' +
    '          <span>{{activate.data.rewardCode}}</span>\n' +
    '        </div>\n' +
    '      </div>-->\n' +
    '\n' +
    '      <div class="form-group checkbox text-left ">\n' +
    '        <label>\n' +
    '          <input type="checkbox" name="newsletter" ng-model="activate.data.newsletter">\n' +
    '          Send me emails with tips and hints to help me turn into a pro\n' +
    '        </label>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="form-group sign-up-page-btn-create">\n' +
    '        <button type="submit" class="btn btn-primary btn-lg btn-block"\n' +
    '                ng-disabled="activate.isLoading">\n' +
    '          {{activate.isLoading ? \'Loading ...\' : \'Activate Account\'}}\n' +
    '        </button>\n' +
    '      </div>\n' +
    '    </form>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('account/activate-update-password.tpl.html',
    '<div class="row">\n' +
    '  <div class="col-md-12 text-center">\n' +
    '    <h1 class="page-header sign-up-page-header">Set Password</h1>\n' +
    '    <div class="sign-up-page-sub-header">\n' +
    '      Welcome to MyLeadPod!\n' +
    '      <br>\n' +
    '      In order to continue to use this account, we need you to setup the password...\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="row sign-up-page-container">\n' +
    '  <div class="sign-in-page-content text-center activate-form-content">\n' +
    '    <form name="activateAccountForm" ng-submit="activate.activate()">\n' +
    '\n' +
    '      <div class="form-group">\n' +
    '        <label class="sr-only" for="password">New Password</label>\n' +
    '        <div class="left-inner-addon">\n' +
    '          <i class="fa fa-lock inner-addon"></i>\n' +
    '          <input class="form-control input-lg" type="password" name="password" id="password"\n' +
    '                 placeholder="Password" ng-required="true" ng-model="activate.data.password" />\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '\n' +
    '      <div class="form-group" ng-class="{\'has-error\': (activateAccountForm.$submitted || activateAccountForm.confirmPassword.$touched) && activateAccountForm.confirmPassword.$error.match}">\n' +
    '        <label class="sr-only" for="confirmPassword">Confirm new password</label>\n' +
    '        <div ng-show="activateAccountForm.$submitted || activateAccountForm.confirmPassword.$touched">\n' +
    '          <div class="input-error" ng-show="activateAccountForm.confirmPassword.$error.match">Passwords do not match.</div>\n' +
    '        </div>\n' +
    '        <div class="left-inner-addon">\n' +
    '          <i class="fa fa-lock inner-addon"></i>\n' +
    '          <input class="form-control input-lg" type="password" name="confirmPassword" id="confirmPassword"\n' +
    '                 placeholder="Confirm password" ng-required="true" ng-model="activate.data.confirmPassword"\n' +
    '                 match="activate.data.password" />\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="form-group sign-up-page-btn-create">\n' +
    '        <button type="submit" class="btn btn-primary btn-lg btn-block"\n' +
    '                ng-disabled="activate.isLoading || activateAccountForm.$invalid">\n' +
    '          {{activate.isLoading ? \'Loading ...\' : \'Update Password\'}}\n' +
    '        </button>\n' +
    '      </div>\n' +
    '\n' +
    '    </form>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('account/account.tpl.html',
    '<div class="row">\n' +
    '  <div class="col-xs-12">\n' +
    '    <h1 class="page-header">My Account</h1>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="row">\n' +
    '  <div class="col-md-12">\n' +
    '    <tabs-manager tab-id="account" tabs-config="account.tabsConfig" default-tab="profile" tab-manager="account.tabManager"></tabs-manager>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="row" ng-if="account.tabManager.isOpen(\'profile\')" ng-include="\'account/profile/profile.tpl.html\'">\n' +
    '</div>\n' +
    '\n' +
    '<div class="row" ng-if="account.tabManager.isOpen(\'earnings\')" ng-include="\'account/earnings/earnings.tpl.html\'">\n' +
    '</div>\n' +
    '\n' +
    '<div class="row" ng-if="account.tabManager.isOpen(\'billing\')" ng-include="\'account/billing/billing.tpl.html\'">\n' +
    '</div>\n' +
    '');
}]);

angular.module('myLeadPod').run(['$templateCache', function($templateCache) {
  $templateCache.put('account/account-header.tpl.html',
    '');
}]);

(function () {
  'use strict';

  /* @ngdoc object
   * @name components.ui.tabs
   * @description
   *
   */
  angular
    .module('components.ui.tabs', []);
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name components.ui.tabs.directive:tabsManager
   * @restrict EA
   * @element
   */
  angular
    .module('components.ui.tabs')
    .directive('tabsManager', tabsManager);

  function tabsManager() {
    return {
      restrict: 'EA',
      scope: {
        // Tab id
        tabId: '@',
        // Configuration object with all the tabs that this directive will have
        tabsConfig: '=',
        // Default tab value
        defaultTab: '@',
        // Tab Manager instance. This can be user by the implementing scope to get the instance of this directive in parent controller.
        tabManager: '=',
        // On change callback
        change: '&onChange',
        // Target in action toolbar (primary:default or secondary) that this instance will be relocated to in xs devices
        target: '@',
        // Whether to block the relocation into the action toolbar for xs devices
        blockXsRelocation: '='
      },
      templateUrl: 'components/ui/tabs/tabs-manager-directive.tpl.html',
      replace: true,
      controllerAs: 'tabsManager',
      controller: ['$scope', '$document', '$compile', 'RouterHelper', 'Tabs', function ($scope, $document, $compile, RouterHelper, Tabs) {
        var vm = this,
            lastLabel;

        vm.tabId = angular.isDefined($scope.tabId) ? $scope.tabId : (new Date()).getTime();
        vm.tabsConfig = angular.isDefined($scope.tabsConfig) ? $scope.tabsConfig : [];
        vm.blockXsRelocation = angular.isDefined($scope.blockXsRelocation) ? $scope.blockXsRelocation : false;
        vm.tabs = _.pluck(vm.tabsConfig, 'value');
        vm.isScrolling = false;

        if (!vm.tabs || vm.tabs.length === 0 || !vm.tabId) {
          throw new Error('[tabs-manager-directive]: List of names must not be empty');
        }
        vm.defaultTab = angular.isDefined($scope.defaultTab) ? $scope.defaultTab : vm.tabsConfig[0].value;
        vm.target = $scope.target ? $scope.target : 'primary';

        // Create the tab manager
        vm.tabManager = Tabs.create(vm.tabId, vm.tabs, vm.defaultTab, $scope);

        // Re-assign the value to parent controller so we can show/hide the templated content.
        $scope.tabManager = vm.tabManager;

        // Default value for the dropdown visibility
        vm.isDropDownOpen = false;

        // Set the currently selected tab (default tab value) and then watch the tabs-factory ActiveTab value
        vm.currentTabLabel = _.find(vm.tabsConfig, {value: vm.defaultTab}).label;

        vm.openTab = function (tabName, checkScroll, $event) {
          var el;

          if (checkScroll && $event) {
            // This is the scenario where openTab is invoked through click on the action toolbar's tab. (xs devices)
            el = $event.currentTarget;
            vm.scrollToTab(el);
          }
          else if (checkScroll) {
            // This is the scenario where openTab is invoked through RouterHelper
            // Check for the element. if the element exists, it means we are in the xs size and we need to scroll to the tab
            el = angular.element('[data-tab-name="' + vm.tabId + '-' + tabName + '"] a');
            if (el && el[0] && !vm.isScrolling) {
              vm.scrollToTab(el[0]);
            }
          }

          if (tabName) {
            // Finally set the tab active in any scenrio
            vm.tabManager.openTab(tabName);
          }
        };

        vm.scrollToTab = function (element) {
          var parent,
              vw,
              elWidth,
              elLeft,
              scrollLeft,
              diff;

          if (!element || vm.isScrolling) {
            return;
          }
          parent = element.parentElement;
          if (parent) {
            // Means we are in xs view (very likely). Get the parent Element and its parent and comparet to scroll vals
            vw = $document[0].documentElement.clientWidth;
            elWidth = parent.clientWidth;
            elLeft = angular.element(parent).position().left;
            scrollLeft = parent.parentElement.scrollLeft;
            diff = elLeft + elWidth / 2 - vw / 2;
            vm.isScrolling = true;
            angular.element(parent.parentElement).animate({scrollLeft: scrollLeft + diff}, {
              easing: 'linear',
              duration: 200,
              complete: function () {
                vm.isScrolling = false;
              }
            });
          }
        };

        vm.getActiveLabel = function () {
          var label;

          if (!vm.tabManager.activeTab) {
            return null;
          }

          label = _.find(vm.tabsConfig, {value: vm.tabManager.activeTab}).label;
          if (lastLabel !== label) {
            $scope.change({activeTab: vm.tabManager.activeTab});
            lastLabel = label;
          }
          return label;
        };

        // Subscribe
        angular.forEach(vm.tabs, function (tabName) {
          // Maybe the event has been already triggered
          if (RouterHelper.lastEvent && RouterHelper.lastEvent.id === 'event:' + vm.tabId + '-open-tab-' + tabName) {
            vm.openTab(tabName, true);
          }

          $scope.$on('event:' + vm.tabId + '-open-tab-' + tabName, function () {
            vm.openTab(tabName, true);
          });
        });
      }]
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name components.ui.tabs.directive:tabAdditionalContent
   * @restrict EA
   * @element
   */
  tabAdditionalContent.$inject = ["$compile"];
  angular
    .module('components.ui.tabs')
    .directive('tabAdditionalContent', tabAdditionalContent);

  function tabAdditionalContent($compile) {
    return {
      restrict: 'EA',
      template: '<span></span>',
      replace: true,
      scope: {
        rawHtml: '=html'
      },
      link: function (scope, elem) {
        scope.$watch('rawHtml', function (value) {
          var newElem;
          if (!value || !angular.isString(value)) {
            return;
          }
          // we want to use the scope OUTSIDE of this directive
          // (which itself is an isolate scope).
          newElem = $compile(value)(scope.$parent);
          elem.contents().remove();
          elem.append(newElem);
        });
      }
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name components.ui.table
   * @description
   *
   */
  angular
    .module('components.ui.table', []);
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name components.ui.table.directive:tableUserDetails
   * @restrict E
   * @element
   */
  tableUserDetails.$inject = ["$timeout", "$templateRequest", "$compile", "$sce"];
  angular
    .module('components.ui.table')
    .directive('tableUserDetails', tableUserDetails);

  function tableUserDetails($timeout, $templateRequest, $compile, $sce) {
    return {
      restrict: 'E',
      scope: {
        user: '=',
        isLink: '=?',
        descriptionKey: '=?',
        stopPropagation: '=?',
        hasDetails: '=?',
        nameOnly: '=?',
        addClass: '='
      },
      replace: true,
      transclude: false,
      templateUrl: 'components/ui/table/table-user-details-directive.tpl.html',
      compile: function (element, attrs) {
        return {
          pre: function preLink(scope) {
            if (!attrs.isLink) {
              scope.isLink = true;
            }
            if (!attrs.hasDetails) {
              scope.hasDetails = false;
            }
            if (!attrs.nameOnly) {
              scope.nameOnly = false;
            }
            if (!attrs.descriptionKey) {
              scope.descriptionKey = 'businessName';
            }
            if (!attrs.stopPropagation) {
              scope.stopPropagation = true;
            }

            if (scope.user) {
              scope.user.mlpUserDetails = {
                showAdvanced: scope.nameOnly,
                descriptionText: scope.user[scope.descriptionKey]
              };
            }
            // Pre-assign the detailsVisible property to false
            scope.detailsVisible = false;
          },
          post: function postLink(scope, iElement) {
            var templateUrl;
            if (iElement.parent()) {
              iElement.parent().addClass('table-user-detail-holder');
            }
            if (scope.stopPropagation) {
              iElement.bind('click', function (evt) {
                evt.stopPropagation();
              });
            }
            templateUrl = $sce.getTrustedResourceUrl('components/ui/table/table-user-popover.tpl.html');
            if (scope.hasDetails) {
              $templateRequest(templateUrl).then(function (template) {
                var el = angular.element(template)[0],
                    linked = $compile(el)(scope);
                $timeout(function () {
                  scope.$apply();
                  scope.htmlPopover = $sce.trustAsHtml(linked.html());
                }, 100, false);
              }, function () {
                // An error has occurred here
              });
            }
          }
        };
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name components.ui.table.directive:singleExpand
   * @restrict A
   * @element
   */
  singleExpand.$inject = ["ComponentsUtil"];
  angular
    .module('components.ui.table')
    .directive('singleExpand', singleExpand);

  function singleExpand(ComponentsUtil) {
    return {
      restrict: 'A',
      scope: {
        subContent: '=',
        subContentIdx: '='
      },
      link: function (scope, element) {
        element.addClass('single-expand');
        element.bind('click', function () {
          scope.subContent = ComponentsUtil.toggleSubContent(scope.subContent, scope.subContentIdx);
          scope.$apply();
        });
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name components.ui.table.directive:headerCheck
   * @restrict A
   * @element
   * @description After the last ng-repeat is rendered, the directive checks for the scrollbar. if present, it adds the padding to the header.
   */
  headerCheck.$inject = ["$timeout"];
  angular
    .module('components.ui.table')
    .directive('headerCheck', headerCheck);

  function headerCheck($timeout) {
    return {
      restrict: 'A',
      link: function (scope, element) {
        var tbody,
            thead,
            scrollBarWidth;

        if (scope.$last === true) {
          $timeout(function () {
            // Grab the element (which should be the row), its parent (thead), check for the scroll.
            // If scroll exists, do the padding (if thead is visible)
            if (!tbody) {
              tbody = element.parent();
              thead = tbody.parent().find('thead');
            }

            if (tbody[0].scrollHeight > tbody.height()) {
              if (!scrollBarWidth) {
                scrollBarWidth = tbody.width() - tbody[0].scrollWidth;
                thead.css({'padding-right': scrollBarWidth + 'px'});
              }
            }
            else {
              thead.css({'padding-right': null});
            }
          });
        }
      }
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name user.messages
   * @description
   *
   */
  angular
    .module('user.messages', [
      'ui.router',
      'mlp-messaging'
    ]);
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider", "configOptions"];
  angular
    .module('user.messages')
    .config(config);

  function config($stateProvider, configOptions) {
    $stateProvider
      .state('user.messages', angular.extend({}, configOptions.defaultStateParams, {
        url: '/messages',
        templateUrl: 'user/messages/messages.tpl.html',
        controller: 'MessagesCtrl',
        controllerAs: 'msg',
        onEnter: ['$rootScope', '$timeout', '$window', '$document', 'RouterHelper', 'notificationConst', 'userProfile', function ($rootScope, $timeout, $window, $document, RouterHelper, notificationConst, userProfile) {
          RouterHelper.activateTab('user', 'messages');
          // $rootScope.$broadcast('event:slider-do-layout', {delay: 100, stepTo: 0});
          $rootScope.$broadcast('event:slider-do-layout', {delay: 100});

          // Broadcast to all scroll-navigators to do the check. Not ideal but I don't know how to target only those on this relevant page.
          $rootScope.$broadcast('event:check-scroll-navigator', 'all', 1000);

          // Broadcast to the NotificationCentre to check any notifications related to messages for this user.
          $rootScope.$broadcast('event:notifications-check', {
            userId: userProfile.idUser,
            category: notificationConst.categoryMessages,
            type: notificationConst.typeDirectMessage
          });
        }],
        onExit: ['$rootScope', function ($rootScope) {
          $rootScope.$broadcast('event:check-scroll-navigator', 'all', 200);
        }]
      }));
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name user.leads.controller:UserReceivedLeadsCtrl
   *
   * @description
   *
   */
  MessagesCtrl.$inject = ["$rootScope", "$scope", "$document", "$window", "$q", "$timeout", "$uibModal", "Restangular", "Leads", "smoothScroll"];
  angular
    .module('user.messages')
    .controller('MessagesCtrl', MessagesCtrl);

  function MessagesCtrl($rootScope, $scope, $document, $window, $q, $timeout, $uibModal, Restangular, Leads, smoothScroll) {
    var vm = this,
        promises = [];

    vm.isLoadingLeads = false;
    vm.isLoadingMsg = false;
    vm.userProfile = $scope.user.userProfile;
    vm.openInfo = false;
    vm.currentLead = null;
    vm.modalInstance = null;
    vm.targetMsgContainer = 'direct-messages-text-input';

    vm.loadLeads = function () {
      // Get the leads
      vm.isLoadingLeads = true;

      promises.push(Restangular.one('Leads').getList('index', {
        type: 'sent',
        idUser: vm.userProfile.id,
        status: 'open',
        limit: -1
      }));

      promises.push(Restangular.one('Leads').getList('index', {
        type: 'received',
        idUser: vm.userProfile.id,
        status: 'open',
        limit: -1
      }));

      $q.all(promises).then(function (resultsArray) {
        vm.sentLeads = resultsArray[0].plain();
        _.each(vm.sentLeads, function (lead) {
          lead.leadType = 'sent';
        });

        vm.receivedLeads = resultsArray[1].plain();
        _.each(vm.receivedLeads, function (lead) {
          lead.leadType = 'received';
        });

        vm.leads = _.union(vm.sentLeads, vm.receivedLeads);
        vm.leads = _.sortBy(vm.leads, 'dateSent').reverse();

        vm.isLoadingLeads = false;
      });
    };

    vm.openDetails = function (lead, type) {
      if (!lead && !type) {
        lead = vm.currentLead;
      }
      Leads.openDetail(lead, lead.leadType, null);
    };

    vm.navigateTo = function (hash, options, lead) {
      if (vm.isLoadingMsg || vm.currentLead === lead) {
        // Come back later. Otherwise the messages directive will load the latest incoming request which might be not right.
        return;
      }
      vm.isLoadingMsg = true;
      vm.currentLead = lead;
      vm.openInfo = false;
      if (angular.isDefined(vm.stepTo)) {
        vm.stepTo({data: 1});
      }
      if (vm.modalInstance) {
        vm.modalInstance.close();
        vm.modalInstance = null;
      }
      vm.targetMsgContainer = 'lead-messages-text-input';
    };

    vm.switchToDirect = function () {
      vm.currentLead = null;
      vm.openInfo = false;
      if (angular.isDefined(vm.stepTo)) {
        vm.stepTo({data: 0});
      }
      if (vm.modalInstance) {
        vm.modalInstance.close();
        vm.modalInstance = null;
      }
      vm.targetMsgContainer = 'direct-messages-text-input';
    };

    vm.openModal = function () {
      vm.modalInstance = $uibModal.open({
        animation: true,
        appendTo: angular.element($document[0].body),
        templateUrl: 'user/messages/messages-list-modal.tpl.html',
        scope: $scope,
        size: 'lg',
        windowClass: 'from-center'
      });
    };

    vm.scrollTo = function (elementId) {
      var el = $document[0].getElementById(elementId);
      if (el) {
        smoothScroll(el, {
          callbackAfter: function () {
            if (el && el.focus) {
              el.focus();
            }
          }
        });
      }
    };

    vm.onMessagesLoad = function () {
      promises = [];
      vm.isLoadingMsg = false;
    };

    vm.onDirectMessagesLoad = function () {
      if (!vm.checkedScroll) {
        $rootScope.$broadcast('event:check-scroll-navigator', 'all');
        vm.checkedScroll = true;
      }
    };

    // Load leads
    vm.loadLeads();

    // Subscribe on events
    vm.watchHandle1 = $rootScope.$on('event:lead-sent', function () {
      vm.loadLeads();
    });
    vm.watchHandle2 = $rootScope.$on('event:lead-closed', function () {
      vm.loadLeads();
    });
    $scope.$on('$destroy', function () {
      vm.watchHandle1();
      vm.watchHandle2();
    });

    // Unfortunately we have to use the timeout here
    $timeout(function () {
      vm.stepTo = $scope.stepTo;
    }, 100);
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name user.marketplace
   * @description
   *
   */
  angular
    .module('user.marketplace', [
      'ui.router',
      'mlpMarketplace'
    ]);
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider", "configOptions"];
  angular
    .module('user.marketplace')
    .config(config);

  function config($stateProvider, configOptions) {
    $stateProvider
      .state('user.marketplace', angular.extend({}, configOptions.defaultStateParams, {
        url: '/marketplace',
        templateUrl: 'user/user.tpl.html',
        controller: 'UserCtrl',
        controllerAs: 'user',
        onEnter: ['RouterHelper', function (RouterHelper) {
          RouterHelper.activateTab('user', 'marketplace');
        }]
      }));
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name user.marketplace.controller:UserMarketplaceCtrl
   *
   * @description
   *
   */
  UserMarketplaceCtrl.$inject = ["$scope", "Marketplace"];
  angular
    .module('user.marketplace')
    .controller('UserMarketplaceCtrl', UserMarketplaceCtrl);

  function UserMarketplaceCtrl($scope, Marketplace) {
    var vm = this;

    vm.userProfile = $scope.user.userProfile;
    vm.items = $scope.user.userProfile.marketplace;

    vm.openMarketplace = function () {
      Marketplace.open();
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name user.leads
   * @description
   *
   */
  angular
    .module('user.leads', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name user.leads.controller:UserSentLeadsCtrl
   *
   * @description
   *
   */
  UserSentLeadsCtrl.$inject = ["$scope", "DataTable", "Leads"];
  angular
    .module('user.leads')
    .controller('UserSentLeadsCtrl', UserSentLeadsCtrl);

  function UserSentLeadsCtrl($scope, DataTable, Leads) {
    var vm = this;
    vm.userProfile = $scope.user.userProfile;
    vm.statuses = {
      all: 'All leads',
      open: 'Delivered',
      new: 'Notified',
      successful: 'Successful',
      unsuccessful: 'Unsuccessful'
    };
    vm.list = DataTable.create('Leads', 'index', {}, {
      basicParams: {
        type: 'sent',
        idUser: vm.userProfile.id
      }
    });

    vm.openDetail = function ($index) {
      Leads.openDetail(vm.list.getRecords()[$index], 'sent', 'user-sent-leads-ctrl-container');
    };

    $scope.$on('sentLeads-success', function () {
      if (vm.list) {
        vm.list.reload();
      }
    });
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name user.leads.controller:UserReceivedLeadsCtrl
   *
   * @description
   *
   */
  UserReceivedLeadsCtrl.$inject = ["$scope", "$state", "Session", "DataTable", "MyMemberships", "Leads"];
  angular
    .module('user.leads')
    .controller('UserReceivedLeadsCtrl', UserReceivedLeadsCtrl);

  function UserReceivedLeadsCtrl($scope, $state, Session, DataTable, MyMemberships, Leads) {
    var vm = this;
    vm.userProfile = $scope.user.userProfile;
    vm.myProfile = null;
    vm.receiveLeads = $scope.user.agreementsDetails.receiveLeads;

    // Watch the property
    $scope.$watch('user.agreementsDetails.receiveLeads', function (receiveLeads) {
      vm.receiveLeads = receiveLeads;
    });

    Session.getUserProfile().then(function (profile) {
      vm.myProfile = profile;
    });

    vm.statuses = {
      all: 'All leads',
      open: 'Open',
      new: 'New',
      successful: 'Successful',
      unsuccessful: 'Unsuccessful'
    };
    vm.list = DataTable.create('Leads', 'index', {}, {
      basicParams: {
        type: 'received',
        idUser: vm.userProfile.id
      }
    });

    vm.openDetail = function ($index) {
      var detailInstance = Leads.openDetail(vm.list.getRecords()[$index], 'received', 'user-received-leads-ctrl-container');
      if (angular.isDefined(detailInstance)) {
        detailInstance.then(function (result) {
          if (angular.isObject(result) && angular.isObject(result.lead)) {
            vm.list.getRecords()[$index] = result.lead;
          }
        });
      }
    };

    vm.closeLead = function ($index, status) {
      Leads.closeLead(vm.list.getRecords()[$index], status, 'user-received-leads-ctrl-container')
        .then(function () {
          vm.list.getRecords()[$index].status = status;
        });
    };

    vm.openAskForLeads = function () {
      var modalInstance = MyMemberships.openAskForLeads(vm.myProfile, vm.userProfile);
      modalInstance.then(function (receiveLeads) {
        $scope.user.agreementsDetails.receiveLeads = receiveLeads;
        vm.receiveLeads = receiveLeads;
      });
    };

    $scope.$on('event:receive-leads-contract-accepted', function (event, data) {
      angular.forEach(vm.list.getRecords(), function (record) {
        if (record.sender.idUser === data.idUser) {
          record.acceptedReceiveLeadsContractExists = true;
        }
      });
    });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider", "configOptions"];
  angular
    .module('user.leads')
    .config(config);

  function config($stateProvider, configOptions) {
    $stateProvider
      .state('user.sentLeads', angular.extend({}, configOptions.defaultStateParams, {
        url: '/sent-leads',
        templateUrl: 'user/user.tpl.html',
        controller: 'UserCtrl',
        controllerAs: 'user',
        onEnter: ['RouterHelper', function (RouterHelper) {
          RouterHelper.activateTab('user', 'sentLeads');
        }]
      }))
      .state('user.receivedLeads', angular.extend({}, configOptions.defaultStateParams, {
        url: '/received-leads',
        templateUrl: 'user/user.tpl.html',
        controller: 'UserCtrl',
        controllerAs: 'user',
        onEnter: ['RouterHelper', function (RouterHelper) {
          RouterHelper.activateTab('user', 'receivedLeads');
        }]
      }));
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name user.connections
   * @description
   *
   */
  angular
    .module('user.connections', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider", "configOptions"];
  angular
    .module('user.connections')
    .config(config);

  function config($stateProvider, configOptions) {
    $stateProvider
      .state('user.connections', angular.extend({}, configOptions.defaultStateParams, {
        url: '/connections',
        templateUrl: 'user/user.tpl.html',
        controller: 'UserCtrl',
        controllerAs: 'user',
        onEnter: ['RouterHelper', function (RouterHelper) {
          RouterHelper.activateTab('user', 'connections');
        }]
      }));
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name user.connections.controller:ConnectionsCtrl
   *
   * @description
   *
   */
  ConnectionsCtrl.$inject = ["$scope", "Notification", "MyPod"];
  angular
    .module('user.connections')
    .controller('ConnectionsCtrl', ConnectionsCtrl);

  function ConnectionsCtrl($scope, Notification, MyPod) {
    var vm = this;

    vm.userProfile = $scope.user.userProfile;
    vm.searchName = '';
    vm.invitePendingFor = -1;
    vm.invitePending = false;
    vm.lastSearched = null;

    // use the data table initialised in the parent scope
    vm.list = $scope.user.connectionsList;

    // Search/Filter function for the search/filter form
    vm.search = function () {
      if (vm.searchName === vm.lastSearched) {
        return;
      }
      vm.lastSearched = vm.searchName;
      vm.list.reload({
        search: vm.searchName
      });
    };

    // Will directly join the other user's pod (using the default rewards program)
    vm.quickJoin = function ($index) {
      var user = vm.list.getRecords()[$index].member;

      // Let's start pending...
      vm.invitePendingFor = $index;
      vm.invitePending = true;

      MyPod.quickJoin(user.idUser).then(function () {
        user.acceptedSendLeadsContractExists = true;
        vm.invitePendingFor = -1;
        vm.invitePending = false;
        Notification({message: 'Success'}, 'confirmation');
      }, function (err) {
        vm.invitePendingFor = -1;
        vm.invitePending = false;
        Notification({message: 'The registration was unsuccessful.'}, 'error');
        throw new Error(err);
      });
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name user.agreements
   * @description
   *
   */
  angular
    .module('user.agreements', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider", "configOptions"];
  angular
    .module('user.agreements')
    .config(config);

  function config($stateProvider, configOptions) {
    $stateProvider
      .state('user.agreements', angular.extend({}, configOptions.defaultStateParams, {
        url: '/agreements',
        templateUrl: 'user/user.tpl.html',
        controller: 'UserCtrl',
        controllerAs: 'user',
        onEnter: ['RouterHelper', function (RouterHelper) {
          RouterHelper.activateTab('user', 'agreements');
        }]
      }));
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name user.agreements.controller:AgreementsCtrl
   *
   * @description
   *
   */
  AgreementsCtrl.$inject = ["$rootScope", "$scope", "$timeout", "$state", "Restangular", "$uibModal", "Notification", "MyMemberships", "MyPod", "Leads", "$document", "BusinessAccount"];
  angular
    .module('user.agreements')
    .controller('AgreementsCtrl', AgreementsCtrl);

  function AgreementsCtrl($rootScope, $scope, $timeout, $state, Restangular, $uibModal,
                          Notification, MyMemberships, MyPod, Leads, $document, BusinessAccount) {
    var vm = this,
        body;

    vm.userProfile = $scope.user.userProfile;
    vm.myProfile = $scope.user.myProfile;
    vm.receiveLeads = $scope.user.agreementsDetails.receiveLeads;
    vm.sendLeads = $scope.user.agreementsDetails.sendLeads;
    vm.isLoading = false;
    vm.trialData = null;

    // Watch the sendLeads property of the scope's parent 'user' property (in user controller) as the change in the user controller sendLeads is not propagated to the child controller.
    $scope.$watch('user.agreementsDetails.sendLeads', function (newSendLeads) {
      vm.sendLeads = newSendLeads;
    });

    // Watch the property
    $scope.$watch('user.agreementsDetails.receiveLeads', function (receiveLeads) {
      vm.receiveLeads = receiveLeads;
    });

    vm.data = {
      receiveLeads: {},
      sendLeads: {}
    };

    function getScrollingElement() {
      var d = $document[0];
      return d.documentElement.scrollHeight > d.body.scrollHeight && d.compatMode.indexOf('CSS1') === 0 ? d.documentElement : d.body;
    }

    function reviseAgreement(data) {
      data.idUser = vm.userProfile.id;
      vm.isLoading = true;
      Restangular.one('Contracts').customPUT(data, 'revise')
        .then(function (agreements) {
          vm.receiveLeads = agreements.receiveLeads;
          vm.sendLeads = agreements.sendLeads;
          $scope.user.agreementsDetails.receiveLeads = agreements.receiveLeads;
          $scope.user.agreementsDetails.sendLeads = agreements.sendLeads;
          $rootScope.$broadcast('event:user-agreements-updated');
          vm.isLoading = false;
          vm.data = {
            receiveLeads: {},
            sendLeads: {}
          };
          Notification({message: 'Success'}, 'confirmation');
        }, function () {
          vm.isLoading = false;
        });
    }

    function accept(contractPurpose) {
      var data = {
        idUser: vm.userProfile.id,
        contractPurpose: contractPurpose
      };
      vm.isLoading = true;
      Restangular.one('Contracts').customPUT(data, 'accept')
        .then(function (agreements) {
          vm.receiveLeads = agreements.receiveLeads;
          vm.sendLeads = agreements.sendLeads;
          $scope.user.agreementsDetails.receiveLeads = agreements.receiveLeads;
          $scope.user.agreementsDetails.sendLeads = agreements.sendLeads;
          $rootScope.$broadcast('event:user-agreements-updated');
          vm.isLoading = false;
          vm.data = {
            receiveLeads: {},
            sendLeads: {}
          };

          Notification({message: 'Success'}, 'confirmation');

          if (contractPurpose === 'receiveLeads') {
            $rootScope.$broadcast('event:receive-leads-contract-accepted', {
              idUser: vm.userProfile.id
            });
          }

          if (angular.isDefined(agreements.idLead) && angular.isNumber(agreements.idLead)) {
            $state.go('leadDetail', {id: agreements.idLead});
          }
        }, function () {
          vm.isLoading = false;
        });
    }

    function acceptAgreement(contractPurpose) {
      var modalInstance;
      if (contractPurpose === 'receiveLeads' && vm.receiveLeads.contractType === 'Subscription' && !vm.myProfile.isCCSet) {
        modalInstance = $uibModal.open({
          animation: false,
          templateUrl: 'account/billing/credit-card.tpl.html',
          controller: 'CreditCardCtrl',
          controllerAs: 'creditCard',
          size: 'lg',
          windowClass: 'modal-credit-card',
          resolve: {
            operation: function () {
              return 'add';
            }
          }
        });

        modalInstance.result.then(function (result) {
          if (angular.isObject(result) && angular.isObject(result.creditCard) && angular.isDefined(result.creditCard.number)) {
            accept(contractPurpose);
          }
        });
      }
      else {
        accept(contractPurpose);
      }
    }

    function scrollTo(btnId) {
      var btn;
      body = body || $document[0].scrollingElement || getScrollingElement();

      if (!btnId) {
        return;
      }

      btn = angular.element('#' + btnId);
      if (btn && btn.offset().top > $document[0].documentElement.clientHeight + body.scrollTop) {
        angular.element(body).animate({
          scrollTop: body.scrollTop + btn.offset().top + btn.outerHeight() + 10 - $document[0].documentElement.clientHeight
        }, 200);
      }
    }

    vm.reviseReceiveLeads = function () {
      var data = angular.extend({}, vm.data.receiveLeads, {
        contractPurpose: 'receiveLeads'
      });
      reviseAgreement(data);
    };

    vm.reviseSendLeads = function () {
      var data = angular.extend({}, vm.data.sendLeads, {
        contractPurpose: 'sendLeads'
      });
      reviseAgreement(data);
    };

    vm.acceptReceiveLeads = function () {
      acceptAgreement('receiveLeads');
    };

    vm.acceptSendLeads = function () {
      acceptAgreement('sendLeads');
    };

    vm.isBusinessAccountActive = function () {
      return angular.isDefined(vm.myProfile.isBusinessAccountActive) && vm.myProfile.isBusinessAccountActive === true;
    };

    vm.cancelReceiveLeadsRevision = function () {
      vm.receiveLeads.revise = false;
      vm.data.receiveLeads = {};
    };

    vm.cancelSendLeadsRevision = function () {
      vm.sendLeads.revise = false;
      vm.data.sendLeads = {};
    };

    vm.switchReviseSendLeadsContent = function () {
      vm.sendLeads.revise = !vm.sendLeads.revise;
      if (vm.sendLeads.revise) {
        $timeout(function () {
          scrollTo('reviseSendLeadsBtn');
        }, 200);
      }
    };

    vm.switchReviseReceiveLeadsContent = function () {
      vm.receiveLeads.revise = !vm.receiveLeads.revise;
      if (vm.receiveLeads.revise) {
        $timeout(function () {
          scrollTo('reviseReceiveLeadsBtn');
        }, 200);
      }
    };

    vm.openAskForLeads = function () {
      var modalInstance = MyMemberships.openAskForLeads(vm.myProfile, vm.userProfile);
      modalInstance.then(function (receiveLeads) {
        $scope.user.agreementsDetails.receiveLeads = receiveLeads;
        vm.receiveLeads = receiveLeads;
      });
    };

    vm.openSendInvite = function () {
      var modalInstance = MyPod.openSendInvite(vm.myProfile, vm.userProfile);
      modalInstance.then(function (sendLeads) {
        vm.sendLeads = sendLeads;
      });
    };

    vm.onRewardSelectionChangeReceiveLeads = function (data, isFormValid) {
      _.extend(vm.data.receiveLeads, data);
      vm.isReceiveLeadsRewardsFormValid = isFormValid;
    };

    vm.onRewardSelectionChangeSendLeads = function (data, isFormValid) {
      _.extend(vm.data.sendLeads, data);
      vm.isSendLeadsRewardsFormValid = isFormValid;
    };

    // Will directly join the other user's pod (using the default rewards program)
    vm.quickJoin = function () {
      // Let's start pending...
      vm.invitePending = true;

      MyPod.quickJoin(vm.userProfile.idUser).then(function (response) {
        vm.sendLeads = response.sendLeads;
        $scope.user.agreementsDetails.sendLeads = response.sendLeads;
        $rootScope.$broadcast('event:user-agreements-updated');
        vm.invitePending = false;
        Notification({message: 'Success'}, 'confirmation');
      }, function (err) {
        vm.invitePending = false;
        Notification({message: 'Joining the Rewards Program was unsuccessful.' + (err.data && err.data.errorMessage ? '<br/>' + err.data.errorMessage : '')}, 'error');
        throw new Error(err);
      });
    };

    vm.activateBusinessAccount = function () {
      BusinessAccount.initiateActivation($document[0].body, vm.trialData.isEligibleForTrial);
    };

    // Send Lead
    vm.sendLead = function () {
      Leads.openSendLead(vm.userProfile, false);
    };

    //
    if (!vm.isBusinessAccountActive()) {
      vm.trialData = BusinessAccount.getTrialData(vm.myProfile);
    }
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name user.agreements.directive:agreementPricingDetail
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="user.agreements">
       <file name="index.html">
        <agreement-pricing-detail></agreement-pricing-detail>
       </file>
     </example>
   *
   */
  angular
    .module('user.agreements')
    .directive('agreementPricingDetail', agreementPricingDetail);

  function agreementPricingDetail() {
    return {
      restrict: 'EA',
      scope: {
        agreement: '=',
        label: '='
      },
      templateUrl: 'user/agreements/agreement-pricing-detail-directive.tpl.html',
      replace: false,
      controllerAs: 'agreementPricingDetail',
      controller: ["$scope", function ($scope) {
        var vm = this;
        vm.agreement = $scope.agreement;
        vm.label = $scope.label;
      }]
    };
  }
}());

(function () {
  'use strict';
  /* @ngdoc object
   * @name socialShare.providers
   * @description
   *
   */
  angular
    .module('socialShare.providers', [
      'angularLoad'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name socialShare.provider:TwitterSocialShare
   *
   * @description
   *
   */
  angular
    .module('socialShare.providers')
    .provider('TwitterSocialShare', TwitterSocialShare);

  function TwitterSocialShare() {
    var config = {
      popupHeight: 500,
      popupWidth: 500
    };

    function setConfig(newConfig) {
      if (angular.isObject(newConfig)) {
        angular.forEach(newConfig, function (value, paramName) {
          if (angular.isDefined(config[paramName])) {
            config[paramName] = value;
          }
          else {
            throw new Error('Invalid configuration option');
          }
        });
      }
    }

    return {
      $get: ['$q', '$window', function ($q, $window) {
        var $twitter = $q.defer();

        $twitter.init = function (newConfig) {
          setConfig(newConfig);
          $twitter.resolve(true);
        };

        $twitter.share = function (data) {
          var url = '//twitter.com/share?url=' + encodeURIComponent(data.url),
              text = '';

          text += data.name;

          if (data.description) {
            text += ' - ' + data.description;
          }

          url += '&text=' + encodeURIComponent(text);

          if (data.source) {
            url += '&via=' + encodeURI(data.source);
          }

          if (data.hashTags) {
            url += '&hashtags=' + encodeURI(data.hashTags);
          }

          $window.open(url
            , 'sharer', 'toolbar=0,status=0,width=' + config.popupWidth + ',height=' + config.popupHeight);
        };

        return $twitter;
      }]
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name socialShare.provider:LinkedinSocialShare
   *
   * @description
   *
   */
  angular
    .module('socialShare.providers')
    .provider('LinkedinSocialShare', LinkedinSocialShare);

  function LinkedinSocialShare() {
    var config = {
      popupHeight: 500,
      popupWidth: 500
    };

    function setConfig(newConfig) {
      if (angular.isObject(newConfig)) {
        angular.forEach(newConfig, function (value, paramName) {
          if (angular.isDefined(config[paramName])) {
            config[paramName] = value;
          }
          else {
            throw new Error('Invalid configuration option');
          }
        });
      }
    }

    return {
      $get: ['$q', '$window', function ($q, $window) {
        var $linkedin = $q.defer();

        $linkedin.init = function (newConfig) {
          setConfig(newConfig);
          $linkedin.resolve(true);
        };

        $linkedin.share = function (data) {
          var url = '//www.linkedin.com/shareArticle?mini=true&url=' + encodeURIComponent(data.url);

          if (data.name) {
            url += '&title=' + encodeURI(data.name);
          }

          if (data.description) {
            url += '&summary=' + encodeURI(data.description);
          }

          if (data.source) {
            url += '&source=' + encodeURI(data.source);
          }
          $window.open(url
            , 'sharer', 'toolbar=0,status=0,width=' + config.popupWidth + ',height=' + config.popupHeight);
        };

        return $linkedin;
      }]
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name socialShare.provider:GooglePlusSocialShare
   *
   * @description
   *
   */
  angular
    .module('socialShare.providers')
    .provider('GooglePlusSocialShare', GooglePlusSocialShare);

  function GooglePlusSocialShare() {
    var config = {
      popupHeight: 500,
      popupWidth: 500
    };

    function setConfig(newConfig) {
      if (angular.isObject(newConfig)) {
        angular.forEach(newConfig, function (value, paramName) {
          if (angular.isDefined(config[paramName])) {
            config[paramName] = value;
          }
          else {
            throw new Error('Invalid configuration option');
          }
        });
      }
    }

    return {
      $get: ['$q', '$window', function ($q, $window) {
        var $googlePlus = $q.defer();

        $googlePlus.init = function (newConfig) {
          setConfig(newConfig);
          $googlePlus.resolve(true);
        };

        $googlePlus.share = function (data) {
          $window.open(
            '//plus.google.com/share?' +
            'url=' + encodeURIComponent(data.url)
            , 'sharer', 'toolbar=0,status=0,width=' + config.popupWidth + ',height=' + config.popupHeight);
        };

        return $googlePlus;
      }]
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name socialShare.provider:Facebook
   *
   * @description
   *
   */
  angular
    .module('socialShare.providers')
    .provider('FacebookSocialShare', FacebookSocialShare);

  function FacebookSocialShare() {
    var config = {
      appId: null,
      version: 'v2.3',
      xfbml: true,
      popupHeight: 500,
      popupWidth: 500
    };

    function setConfig(newConfig) {
      if (angular.isObject(newConfig)) {
        angular.forEach(newConfig, function (value, paramName) {
          if (angular.isDefined(config[paramName])) {
            config[paramName] = value;
          }
          else {
            throw new Error('Invalid configuration option');
          }
        });
      }
    }

    return {
      $get: ['$q', '$window', '$rootScope', 'angularLoad', function ($q, $window, $rootScope, angularLoad) {
        /* jshint unused:false */
        /* eslint no-unused-vars: [2, {"args": "none", "varsIgnorePattern": "Handler" }]*/
        var $facebook = $q.defer(),
            fbInitHandler;

        $facebook.init = function (newConfig) {
          setConfig(newConfig);

          if (angular.isUndefined(config.appId) || config.appId === null) {
            throw new Error('Missing APP ID');
          }

          if (!$window.FB) {
            angularLoad.loadScript('//connect.facebook.net/en_US/sdk.js')
              .then(function () {
                $window.FB.init({
                  appId: config.appId,
                  version: config.version,
                  xfbml: config.xfbml
                });
                $rootScope.$broadcast('fb.init');
              });
          }
        };

        fbInitHandler = $rootScope.$on('fb.init', function () {
          $facebook.resolve(true);
        });

        $facebook.share = function (data) {
          var params = {
            method: 'feed',
            link: data.url
          };
          if (data.title) {
            params.caption = data.title;
          }

          if (data.name) {
            params.name = data.name;
          }

          if (data.description) {
            params.description = data.description;
          }

          if (data.picture) {
            params.picture = data.picture;
          }

          $window.FB.ui(params);
        };

        return $facebook;
      }]
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name myPod.sentLeads
   * @description
   *
   */
  angular
    .module('myPod.sentLeads', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider", "configOptions"];
  angular
    .module('myPod.sentLeads')
    .config(config);

  function config($stateProvider, configOptions) {
    $stateProvider
      .state('myPod.sentLeads', angular.extend({}, configOptions.defaultStateParams, {
        url: '/sent-leads',
        templateUrl: 'my-pod/my-pod.tpl.html',
        controller: 'MyPodCtrl',
        controllerAs: 'myPod',
        onEnter: ['RouterHelper', function (RouterHelper) {
          RouterHelper.activateTab('myPod', 'sentLeads');
        }]
      }));
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name myPod.sentLeads.controller:SentLeadsCtrl
   *
   * @description
   *
   */
  SentLeadsCtrl.$inject = ["DataTable", "Leads"];
  angular
    .module('myPod.sentLeads')
    .controller('SentLeadsCtrl', SentLeadsCtrl);

  function SentLeadsCtrl(DataTable, Leads) {
    var vm = this,
        filter;
    vm.statuses = {
      all: 'All leads',
      open: 'Delivered',
      new: 'Notified',
      successful: 'Successful',
      unsuccessful: 'Unsuccessful'
    };
    vm.searchLead = '';
    vm.selectedStatus = 'all';
    vm.selectedStatusLabel = 'All leads';
    vm.lastSearched = null;

    vm.list = DataTable.create('Leads', 'index', {}, {
      basicParams: {
        type: 'sent'
      }
    });

    filter = function () {
      vm.list.reload({
        status: vm.selectedStatus,
        search: vm.searchLead
      });
    };

    vm.selectStatus = function (leadStatus) {
      vm.selectedStatus = leadStatus;
      vm.selectedStatusLabel = vm.statuses[leadStatus];
      filter();
    };

    vm.search = function () {
      if (vm.selectedStatus + vm.searchLead === vm.lastSearched) {
        return;
      }
      filter();
      vm.lastSearched = vm.selectedStatus + vm.searchLead;
    };

    vm.openDetail = function ($index) {
      Leads.openDetail(vm.list.getRecords()[$index], 'sent', 'sent-leads-ctrl-container');
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name myPod.sendInvite
   * @description
   *
   */
  angular
    .module('myPod.sendInvite', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('myPod.sendInvite')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('myPodSendInvite', {
        url: '/my-pod/send-invite',
        template: '<ui-view />',
        controller: 'SendInviteCtrl',
        controllerAs: 'sendInvite',
        authenticate: true,
        params: {
          user: null
        }
      });
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name myPod.sendInvite.controller:SendInviteModalCtrl
   *
   * @description
   *
   */
  SendInviteModalCtrl.$inject = ["$state", "$timeout", "Restangular", "Notification", "FormAlerts", "$uibModalInstance", "userProfile"];
  angular
    .module('myPod.sendInvite')
    .controller('SendInviteModalCtrl', SendInviteModalCtrl);

  function SendInviteModalCtrl($state, $timeout, Restangular, Notification, FormAlerts, $uibModalInstance, userProfile) {
    var vm = this;
    vm.isLoading = false;
    vm.isLoadingUserData = false;
    vm.searchControlName = 'sendInviteSearchControl';
    vm.data = {
      firstName: null,
      email: null,
      phoneMobile: null
    };
    vm.alerts = new FormAlerts();
    vm.displayContactDetails = false;
    vm.hasRewardCode = true;
    vm.isRewardCodeValid = false;
    vm.isRewardCodeInvalid = false;
    vm.targetUser = userProfile;
    vm.emptyResults = false;
    vm.searchCategories = [{
      name: 'Directory',
      value: 'businessUsers',
      stateTarget: 'user',
      iconClass: 'fa-briefcase'
    }];

    if (angular.isDefined(userProfile) && angular.isObject(userProfile)) {
      vm.data.firstName = userProfile.firstName;
      vm.data.email = userProfile.email;
      vm.data.phoneMobile = userProfile.phoneMobile;
    }

    vm.cancel = function () {
      $uibModalInstance.dismiss();
    };

    vm.send = function () {
      vm.isLoading = true;
      vm.alerts.reset();

      Restangular.one('Members').post('index', vm.data)
        .then(function (response) {
          vm.isLoading = false;
          $uibModalInstance.close(response.sendLeads);
          Notification({message: 'Success'}, 'confirmation');
        }, function () {
          vm.isLoading = false;
          vm.alerts.add('danger', 'Unable to send');
          $uibModalInstance.dismiss();
        });
    };

    vm.checkRewardCode = function () {
      if (vm.isRewardCodeValid) {
        vm.rewardCode = null;
        vm.isRewardCodeInvalid = false;
        vm.isRewardCodeValid = false;
      }
      else {
        vm.rewardCode = vm.rewardCode.toUpperCase().replace(/\s+/g, '-');
        vm.isCheckingCode = true;
        Restangular.one('RewardCodes').one('validate').get({rewardCode: vm.rewardCode})
          .then(function (result) {
            vm.isRewardCodeInvalid = !result.valid;
            vm.isRewardCodeValid = result.valid;
            Restangular.one('RewardCodes').post('activate', {
              rewardCode: vm.rewardCode
            })
              .then(function (activationResult) {
                if (angular.isObject(activationResult) && angular.isDefined(activationResult.idUser) && activationResult.idUser > 0) {
                  $uibModalInstance.close({isRedirecting: true});
                  Notification({message: 'Success'}, 'confirmation');
                  $state.go('user.agreements', {id: activationResult.idUser});
                }
                else {
                  vm.isLoading = false;
                  vm.isRewardCodeInvalid = true;
                  vm.isRewardCodeValid = false;
                  vm.isCheckingCode = false;
                }
              }, function () {
                vm.isLoading = false;
                vm.isRewardCodeInvalid = true;
                vm.isRewardCodeValid = false;
                vm.isCheckingCode = false;
              });
          }, function () {
            vm.isRewardCodeInvalid = true;
            vm.isRewardCodeValid = false;
            vm.isCheckingCode = false;
          });
      }
    };

    vm.onRewardCodeEnter = function ($event) {
      if ($event.key === 'Enter') {
        $event.stopImmediatePropagation();
        vm.checkRewardCode();
      }
    };

    vm.searchResponseFormatter = function (response) {
      var r,
          records;

      vm.userInvitedProfile = null;

      if (angular.isUndefined(response) || !response) {
        return null;
      }
      vm.emptyResults = false;
      r = response.records;
      if (r.length === 0) {
        vm.emptyResults = true;
      }

      records = _.map(r, function (item) {
        var category = _.find(vm.searchCategories, {value: item.datasource}),
            returnObject = {},
            categoryDescriptionClass,
            value = item.fullname;

        categoryDescriptionClass = category.iconClass || 'fa-user';
        if (item.companyname && item.companyname !== item.fullname) {
          value += ' | ' + item.companyname;
        }

        returnObject = {
          value: value,
          id: item.id,
          category: item.datasource,
          categoryDescription: '<i class="fa ' + categoryDescriptionClass + '"></i>',
          timeDescription: item.datecreatedformatted ? item.datecreatedformatted : '',
          leadtype: item.leadtype
        };

        return returnObject;
      });

      return records;
    };

    vm.onSearchItemSelected = function (item) {
      // Callback on when the user selected the search item.
      // Pull the stateTarget from category
      if (!item) {
        return;
      }
      vm.displayContactDetails = true;
      vm.isLoadingUserData = true;
      Restangular.one('Users').one('profile').get({id: item.originalObject.id})
        .then(function (profile) {
          profile = profile.plain();
          vm.data.phoneMobile = profile.phoneMobile;
          vm.data.firstName = profile.firstName;
          vm.data.email = profile.email;
          vm.userInvitedProfile = profile;
          vm.isLoadingUserData = false;
        });
    };

    vm.clearContactData = function (focus) {
      vm.displayContactDetails = false;
      vm.data.email = null;
      vm.data.phoneMobile = null;
      vm.data.firstName = null;
      if (focus) {
        $timeout(function () {
          angular.element('[name=\'' + vm.searchControlName + '\']').focus();
        }, 200);
      }
    };

    vm.checkContactData = function () {
      if (vm.displayContactDetails === true) {
        return vm.data.email && vm.data.firstName;
      }

      return true;
    };

    vm.openSearch = function () {
      vm.onlySearch = true;
      vm.onlyRewards = false;

      $timeout(function () {
        angular.element('[name=\'' + vm.searchControlName + '\']').focus();
      }, 200);
    };

    vm.focusIn = function () {
      vm.hasRewardCode = false;
      vm.onlyRewards = false;
      vm.onlySearch = true;
    };

    vm.openRewardCode = function () {
      vm.hasRewardCode = true;
      vm.clearContactData(false);
      $timeout(function () {
        angular.element('#rewardCode').focus();
      }, 200);
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name myPod.sendInvite.controller:SendInviteCtrl
   *
   * @description
   *
   */
  SendInviteCtrl.$inject = ["$state", "$stateParams", "MyPod"];
  angular
    .module('myPod.sendInvite')
    .controller('SendInviteCtrl', SendInviteCtrl);

  function SendInviteCtrl($state, $stateParams, MyPod) {
    MyPod.openSendInvite().then(function (result) {
      if (result && !result.isRedirecting) {
        $state.go('myPod');
      }
    },
    function () {
      // When this is an error or just simply closed, redirect to my-pod.
      $state.go('myPod');
    });
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name myPod.members
   * @description
   *
   */
  angular
    .module('myPod.members', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider", "configOptions"];
  angular
    .module('myPod.members')
    .config(config);

  function config($stateProvider, configOptions) {
    $stateProvider
      .state('myPod.members', angular.extend({}, configOptions.defaultStateParams, {
        url: '/members',
        templateUrl: 'my-pod/my-pod.tpl.html',
        controller: 'MyPodCtrl',
        controllerAs: 'myPod',
        onEnter: ['RouterHelper', function (RouterHelper) {
          RouterHelper.activateTab('myPod', 'members');
        }]
      }));
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name myPod.members.controller:MembersCtrl
   *
   * @description
   *
   */
  MembersCtrl.$inject = ["DataTable"];
  angular
    .module('myPod.members')
    .controller('MembersCtrl', MembersCtrl);

  function MembersCtrl(DataTable) {
    var vm = this,
        filter;
    vm.statuses = {
      active: 'Active',
      past: 'Past',
      pending: 'Pending invites'
    };
    vm.searchName = '';
    vm.selectedStatus = 'active';
    vm.selectedStatusLabel = 'Active';
    vm.lastSearched = null;

    vm.list = DataTable.create('Members', 'index', {
      status: 'active'
    }, {
      basicParams: {}
    });

    filter = function () {
      vm.list.reload({
        status: vm.selectedStatus,
        search: vm.searchName
      });
    };

    vm.selectStatus = function (status) {
      vm.selectedStatus = status;
      vm.selectedStatusLabel = vm.statuses[status];
      filter();
    };

    vm.search = function () {
      if (vm.lastSearched === vm.selectedStatus + vm.searchName) {
        return;
      }
      filter();
      vm.lastSearched = vm.selectedStatus + vm.searchName;
    };

    vm.list.isReady.then(function () {
      if (vm.list.recordsExist() && vm.list.getRecords() < 1) {
        vm.selectStatus('past');
      }
      else if (!vm.list.recordsExist()) {
        vm.selectStatus('pending');
      }
    });
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name myMemberships.sendReward
   * @description
   *
   */
  angular
    .module('myMemberships.sendReward', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('myMemberships.sendReward')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('myMembershipsSendReward', {
        url: '/my-memberships/send-reward',
        templateUrl: 'my-memberships/send-reward/send-reward.tpl.html',
        controller: 'SendRewardCtrl',
        controllerAs: 'sendReward',
        authenticate: true,
        resolve: {
          creditCard: ['Restangular', function (Restangular) {
            return Restangular.one('Billing').one('creditCard').get()
              .then(function (creditCard) {
                if (!creditCard.number || !creditCard.expiryMonth || !creditCard.expiryYear) {
                  return null;
                }
                return creditCard.plain();
              }, function () {
                return null;
              });
          }],
          membershipsSubIndustries: ['Restangular', function (Restangular) {
            return Restangular.one('Industries').getList('getMembershipsSubIndustries')
              .then(function (result) {
                var subIndustries = [];
                angular.forEach(result, function (value) {
                  subIndustries.push(value);
                });
                return subIndustries;
              });
          }]
        },
        params: {
          user: null
        }
      });
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name myMemberships.sendReward.controller:SendRewardCtrl
   *
   * @description
   *
   */
  SendRewardCtrl.$inject = ["$state", "$stateParams", "Restangular", "Notification", "DataTable", "FormAlerts", "creditCard", "Industries", "membershipsSubIndustries"];
  angular
    .module('myMemberships.sendReward')
    .controller('SendRewardCtrl', SendRewardCtrl);

  function SendRewardCtrl($state, $stateParams, Restangular, Notification, DataTable, FormAlerts, creditCard,
                          Industries, membershipsSubIndustries) {
    var vm = this,
        filter;

    vm.isLoading = false;
    vm.alerts = new FormAlerts();
    vm.processingFee = 0;
    vm.total = 0;
    vm.tax = 0;
    vm.showTotal = false;
    vm.calculating = false;
    vm.calculationRequired = false;
    vm.list = DataTable.create('Memberships', 'index');
    vm.selectedRecipient = null;
    vm.searchName = '';
    vm.idIndustry = '';
    vm.creditCard = creditCard;

    if (angular.isDefined($stateParams.user) && angular.isObject($stateParams.user)) {
      vm.selectedRecipient = $stateParams.user;
    }

    vm.data = {
      paymentDetails: {
        method: angular.isObject(creditCard) && angular.isDefined(creditCard.number) ? null : 'new'
      },
      idUserRecipient: 0,
      message: 'Thank you for your leads!'
    };
    Industries.isReady.then(function () {
      vm.industries = Industries.getConsolidatedFilteredList(membershipsSubIndustries);
    });

    filter = function () {
      var idIndustry = parseInt(vm.idIndustry, 10);
      if (idIndustry <= 0 || isNaN(idIndustry)) {
        idIndustry = 0;
      }

      vm.list.reload({
        search: vm.searchName,
        idIndustry: idIndustry
      });
    };

    vm.search = function ($event) {
      $event.stopImmediatePropagation();
      filter();
    };

    vm.filterByIndustry = function () {
      filter();
    };

    vm.cancel = function () {
      $state.go('myMemberships.memberships');
    };

    vm.selectRecipient = function ($index) {
      vm.selectedRecipient = vm.list.getRecords()[$index].owner;
    };

    vm.deselectRecipient = function () {
      vm.selectedRecipient = null;
    };

    vm.isUserSelected = function ($index) {
      var user = vm.list.getRecords()[$index].owner;
      return angular.isObject(vm.selectedRecipient) && vm.selectedRecipient.id === user.id;
    };

    vm.send = function () {
      vm.isLoading = true;
      vm.alerts.reset();
      Restangular.one('Earnings').post('sendReward', angular.extend({}, vm.data, {
        idUserRecipient: vm.selectedRecipient.id
      }))
        .then(function () {
          $state.go('myMemberships.memberships');
          Notification({message: 'Success'}, 'confirmation');
        }, function (responseData) {
          vm.isLoading = false;
          if (responseData.data.errorCode === 701 || responseData.data.errorCode === 702 || responseData.data.errorCode === 703) {
            vm.alerts.add('danger', 'Unable to send: ' + responseData.data.errorMessage);
          }
          else {
            vm.alerts.add('danger', 'Unable to send, try again later please');
          }
        });
    };

    vm.onRewardChange = function () {
      vm.showTotal = false;
      vm.calculationRequired = true;
    };

    vm.calculateTotal = function () {
      if (vm.data.reward > 0) {
        vm.calculating = true;
        Restangular.one('Earnings').one('calculateRewardTotal').get({
          reward: vm.data.reward
        })
          .then(function (result) {
            vm.processingFee = result.fees;
            vm.total = result.total;
            vm.tax = result.tax;
            vm.calculating = false;
            vm.calculationRequired = false;
            vm.showTotal = true;
          });
      }
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name myMemberships.rewardProgram
   * @description
   *
   */
  angular
    .module('myMemberships.rewardProgram', [
      'ui.router',
      'ngclipboard'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name myMemberships.rewardProgram.controller:WelcomeModalCtrl
   *
   * @description
   *
   */
  WelcomeModalCtrl.$inject = ["$window", "$scope", "$uibModalInstance"];
  angular
    .module('myMemberships.rewardProgram')
    .controller('WelcomeModalCtrl', WelcomeModalCtrl);

  function WelcomeModalCtrl($window, $scope, $uibModalInstance) {
    var vm = this;

    // Listen to the broadcast of 'close-modal-instance';
    $scope.$on('close-modal-instance', function () {
      $uibModalInstance.dismiss();
    });

    vm.close = function () {
      $uibModalInstance.close();
    };

    vm.openLink = function (linkUrl) {
      $window.open(linkUrl, '_blank');
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name myMemberships.rewardProgram.controller:RewardProgramsSetupCtrl
   *
   * @description
   *
   */
  RewardProgramsSetupCtrl.$inject = ["$scope", "$timeout", "$filter", "Notification", "$state", "FormAlerts", "defaultOptions", "RewardCodes"];
  angular
    .module('myMemberships.rewardProgram')
    .controller('RewardProgramsSetupCtrl', RewardProgramsSetupCtrl);

  function RewardProgramsSetupCtrl($scope, $timeout, $filter, Notification, $state, FormAlerts, defaultOptions, RewardCodes) {
    var vm = this;

    vm.alerts = new FormAlerts();
    vm.isLoading = false;
    vm.rewardProgramHelp = 'This is where you can configure different cash rewards for different products and/or services you can link to your Reward Codes. Examples: Book Keeping $100, Tax Return $200, Financial Planning $300';

    vm.data = {
      options: []
    };

    function getIdForOption() {
      var d = new Date();
      return d.getTime();
    }

    function initData() {
      vm.data.options.push({
        name: null,
        successfulLeadPrice: null,
        uiId: getIdForOption()
      });
    }

    if ($state.params.openInvite) {
      RewardCodes.openWelcome();
    }

    // Subscribe to the update of the user profile.
    $scope.$on('event:user-updateLocalProfile', function () {
      // We cannot use state.reload as that would possibly open a WelcomeScreen again.
      $state.transitionTo('myMembershipsRewardProgramsSetup', {
        openInvite: false
      });
    });

    // Render reward program option
    vm.renderRewardProgramOptions = function (options) {
      if (options && options.length > 0) {
        angular.forEach(options, function (option) {
          var uiId = getIdForOption() * Math.random();
          vm.data.options.push(angular.extend({}, option, {
            uiId: uiId.toString().replace('.', '')
          }));
        });
        vm.data.options = $filter('orderBy')(vm.data.options, 'successfulLeadPrice');
      }
      else {
        initData();
      }
    };
    vm.renderRewardProgramOptions(defaultOptions);

    vm.addOption = function () {
      var optionId,
          idx,
          input;

      optionId = getIdForOption();

      vm.data.options.push({
        name: null,
        successfulLeadPrice: null,
        uiId: getIdForOption()
      });

      idx = vm.data.options.length - 1;

      $timeout(function () {
        // There might be two different inputs available. First try the desktop one. If visible, just focus it.
        input = angular.element('#name' + optionId + ':visible');
        if (input.length === 0) {
          // This must be the input hidden inside the panel (xs,sm devices)
          input = angular.element('#name2' + optionId);
          vm.tableSubContentVisible = {};
          vm.tableSubContentVisible[idx] = true;

          if (input.length === 1) {
            input.closest('.table-cell-sub-content-container').css('display', 'block');
            input[0].focus();
          }
        }
        else {
          input[0].focus();
        }
      }, 200);
    };

    vm.deleteOption = function ($index, form) {
      // This is to fix the shifting index in the xs,sm devices that keeps opening incorrect panels
      vm.tableSubContentVisible = {};
      // Remove the option from the data
      vm.data.options.splice($index, 1);

      if (form) {
        form.$setDirty();
      }
    };

    vm.save = function () {
      vm.isLoading = true;
      vm.alerts.reset();
      RewardCodes.saveDefaultRewardOptions(vm.data.options)
        .then(function () {
          RewardCodes.updateRewardProgramsAndCodes(true, function () {
            vm.isLoading = false;
            Notification({message: 'Success'}, 'confirmation');
          });
        }, function (errorCode) {
          if (errorCode === 609) {
            vm.alerts.add('danger', 'Unable to save: Rewards program is still being updated, please try again later.');
          }
          else {
            vm.alerts.add('danger', 'Unable to save');
          }
          vm.isLoading = false;
        });
    };

    vm.toggleSubContent = function (idx) {
      if (!vm.tableSubContentVisible) {
        vm.tableSubContentVisible = {};
        vm.tableSubContentVisible[idx] = true;
      }
      else if (_.contains(_.keys(vm.tableSubContentVisible), idx.toString())) {
        // Check if the incoming idx is already added, Just toggle it.
        vm.tableSubContentVisible[idx] = !vm.tableSubContentVisible[idx];
      }
      else {
        // Delete everything and set as new
        vm.tableSubContentVisible = {};
        vm.tableSubContentVisible[idx] = true;
      }
    };

    vm.openWelcomeScreen = function () {
      RewardCodes.openWelcome();
    };
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider", "configOptions"];
  angular
    .module('myMemberships.rewardProgram')
    .config(config);

  function config($stateProvider, configOptions) {
    $stateProvider
      .state('myMemberships.rewardPrograms', angular.extend({}, configOptions.defaultStateParams, {
        url: '/reward-programs',
        templateUrl: 'my-memberships/my-memberships.tpl.html',
        controller: 'MyMembershipsCtrl',
        controllerAs: 'myMemberships',
        onEnter: ['RouterHelper', function (RouterHelper) {
          RouterHelper.activateTab('myMemberships', 'rewardPrograms');
        }]
      }))
      .state('myMembershipsRewardProgramsSetup', angular.extend({}, configOptions.defaultStateParams, {
        url: '/my-memberships/reward-programs/setup',
        templateUrl: 'my-memberships/reward-program/setup.tpl.html',
        controller: 'RewardProgramsSetupCtrl',
        controllerAs: 'rewardProgramsSetup',
        params: {
          openInvite: {
            value: false,
            dynamic: true
          }
        },
        resolve: {
          defaultOptions: ['Session', function (Session) {
            return Session.getUserProfile().then(function (user) {
              return user.defaultRewardProgram;
            });
          }]
        }
      }))
      .state('myMembershipsRewardProgramCode', {
        url: '/reward-program/join/{code}',
        resolve: {
          rewardCode: ['$state', '$stateParams', 'Restangular', 'Notification', 'Auth', function ($state, $stateParams, Restangular, Notification, Auth) {
            Restangular.one('RewardCodes').one('viewsCounter').get({rewardCode: $stateParams.code});
            Auth.isAuthenticated()
              .then(function (isAuthenticated) {
                if (isAuthenticated) {
                  Restangular.one('RewardCodes').post('activate', {
                    rewardCode: $stateParams.code
                  })
                    .then(function (result) {
                      if (angular.isObject(result) && angular.isDefined(result.idUser) && result.idUser > 0) {
                        Notification({message: 'You are now joined with this business!'}, 'success');
                        $state.go('user.agreements', {id: result.idUser});
                      }
                      else {
                        Notification({message: 'The Reward Code does not exist or is no longer valid.'}, 'warning');
                        $state.go('dashboard');
                      }
                    }, function () {
                      $state.go('dashboard');
                    });
                }
                else {
                  $state.go('accountSignInUp.accountSignUp', {rewardCode: $stateParams.code});
                }
              }, function () {
                $state.go('accountSignInUp.accountSignUp', {rewardCode: $stateParams.code});
              });
          }]
        }
      });
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name myMemberships.rewardProgram.controller:RewardProgramCtrl
   *
   * @description
   *
   */
  RewardProgramCtrl.$inject = ["$interpolate", "DataTable", "RewardCodes", "configOptions", "SocialShare", "BitlyUrlShortener"];
  angular
    .module('myMemberships.rewardProgram')
    .controller('RewardProgramCtrl', RewardProgramCtrl);

  function RewardProgramCtrl($interpolate, DataTable, RewardCodes, configOptions, SocialShare, BitlyUrlShortener) {
    var vm = this,
        filter;

    vm.selectedStatus = 'active';
    vm.selectedStatusLabel = 'Active';
    vm.rewardCodeHelp = 'In this screen you can set up new Reward Codes and apply the Reward Program payouts, or alternatively select \'Customise Rewards\' to set up a totally new cash reward offering';
    vm.urlToShare = null;
    vm.emailBody = null;

    vm.statuses = {
      active: 'Active',
      archived: 'Archived',
      all: 'All'
    };

    filter = function () {
      vm.list.reload({
        status: vm.selectedStatus,
        limit: 15
      });
    };

    vm.selectStatus = function (status, forceReload) {
      if (status !== vm.selectedStatus || forceReload === true) {
        vm.selectedStatus = status;
        vm.selectedStatusLabel = vm.statuses[status];
        filter();
      }
    };

    vm.list = DataTable.create('RewardCodes', 'index', {
      status: vm.selectedStatus,
      limit: 15
    }, {
      basicParams: {}
    });

    vm.openDetail = function ($index) {
      RewardCodes.openDetail(vm.list.getRecords()[$index])
        .then(function (result) {
          if (angular.isObject(result) && angular.isDefined(result.updatedRecord) && angular.isObject(result.updatedRecord)) {
            if (vm.selectedStatus === 'active' && result.updatedRecord.isArchived) {
              vm.list.getRecords().splice($index, 1);
            }
            else {
              angular.extend(vm.list.getRecords()[$index], result.updatedRecord);
            }
          }
        });
    };

    vm.openCreateCodeModal = function () {
      var result = RewardCodes.openRewardCreateModal();

      // The following will run only if the modal instance is closed (not dismissed).
      result.then(function (response) {
        var newSelectedStatus = vm.selectedStatus;
        if (angular.isDefined(response) && response === 'success') {
          if (vm.selectedStatus === 'archived') {
            newSelectedStatus = 'active';
          }
          vm.selectStatus(newSelectedStatus, true);
        }
      });
    };

    vm.list.isReady.then(function () {
      if (vm.list.recordsExist() && vm.list.getRecords() < 1) {
        vm.selectStatus('archived');
      }
    });

    vm.setUrl = function (rewardCode) {
      vm.isLoadingLink = true;
      BitlyUrlShortener.shorten(RewardCodes.getRewardCodeUrl(rewardCode))
        .then(function (result) {
          var exp = $interpolate(configOptions.defaultEmailMessage);
          vm.urlToShare = result;
          vm.emailBody = encodeURIComponent(exp({link: vm.urlToShare}));
          vm.isLoadingLink = false;
        });
    };

    vm.share = function (socialService) {
      SocialShare.share(socialService, {
        url: vm.urlToShare,
        name: configOptions.defaultShareName,
        description: configOptions.defaultShareMessage,
        source: 'MyLeadPod',
        picture: configOptions.appUrl + '/images/touch-icon-192x192.png'
      });
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name myMemberships.rewardProgram.service:RewardCodes
   *
   * @description
   *
   */
  RewardCodes.$inject = ["$document", "$q", "$state", "$uibModal", "Session", "Restangular", "configOptions", "$httpParamSerializer"];
  angular
    .module('myMemberships.rewardProgram')
    .service('RewardCodes', RewardCodes);

  function RewardCodes($document, $q, $state, $uibModal, Session, Restangular, configOptions, $httpParamSerializer) {
    var self = this;

    self.openDetail = function (rewardCode, parentElementId) {
      var modalInstance,
          params = {
            animation: false,
            templateUrl: 'my-memberships/reward-program/reward-code-detail.tpl.html',
            controller: 'RewardCodeDetailCtrl',
            controllerAs: 'rewardCodeDetail',
            size: 'md',
            resolve: {
              rewardCode: function () {
                return rewardCode;
              }
            }
          };
      if (angular.isDefined(parentElementId) && !!parentElementId) {
        params.appendTo = angular.element($document[0].getElementById(parentElementId));
      }
      modalInstance = $uibModal.open(params);

      return modalInstance.result;
    };

    self.openWelcome = function () {
      var params = {
        animation: true,
        templateUrl: 'my-memberships/reward-program/welcome-modal-template.tpl.html',
        controller: 'WelcomeModalCtrl',
        controllerAs: 'welcome',
        size: 'lg',
        backdrop: 'static',
        keyboard: false
      };
      $uibModal.open(params);
    };

    self.openEmbed = function (rewardCode, parentElementId) {
      var modalInstance,
          params = {
            animation: false,
            templateUrl: 'my-memberships/reward-program/reward-code-embed.tpl.html',
            controller: 'RewardCodeEmbedCtrl',
            controllerAs: 'rewardCodeEmbed',
            size: 'lg',
            resolve: {
              rewardCode: function () {
                return rewardCode;
              }
            }
          };
      if (angular.isDefined(parentElementId) && !!parentElementId) {
        params.appendTo = angular.element($document[0].getElementById(parentElementId));
      }
      modalInstance = $uibModal.open(params);

      return modalInstance.result;
    };

    self.create = function (rewardCode, rewardType, options, description, userShareEmail, associatedUserIds) {
      var deferred = $q.defer();
      Restangular.one('RewardCodes').post('index', {
        rewardCode: rewardCode,
        rewardType: rewardType,
        options: options,
        description: description,
        userShareEmail: userShareEmail,
        associatedUsers: associatedUserIds
      })
        .then(function (result) {
          deferred.resolve(result.plain());
        }, function (error) {
          deferred.reject(error.data.errorCode);
        });

      return deferred.promise;
    };

    self.update = function (idRewardCode, data) {
      var deferred = $q.defer();
      Restangular.one('RewardCodes').customPUT(data, 'index?id=' + idRewardCode)
        .then(function (result) {
          deferred.resolve(result.plain());
        }, function (error) {
          deferred.reject(error.data.errorCode);
        });

      return deferred.promise;
    };

    self.getRewardCodeUrl = function (rewardCode) {
      return $state.href('myMembershipsRewardProgramCode', {code: rewardCode}, {absolute: true});
    };

    self.isRewardCodeUnique = function (rewardCode) {
      return Restangular.one('RewardCodes').one('unique').get({rewardCode: rewardCode})
        .then(function (result) {
          return result.unique;
        }, function () {
          return false;
        });
    };

    self.isUserShareEmailValid = function (userShareEmail) {
      return Restangular.one('RewardCodes').one('checkUserShareEmail').get({email: userShareEmail})
        .then(function (result) {
          return result.isValid;
        }, function () {
          return false;
        });
    };

    self.getDefaultRewardOptions = function () {
      return Restangular.one('RewardCodes').getList('pricingOptionsTemplate')
        .then(function (result) {
          var options = [];
          angular.forEach(result, function (value) {
            options.push(value.plain());
          });
          return options;
        }, function () {
          return [];
        });
    };

    self.getRewardCodes = function () {
      return Restangular.one('RewardCodes').getList('index?' + $httpParamSerializer({
        limit: -1,
        orderby: 'rewardCode'
      })).then(function (result) {
        var options = [];
        angular.forEach(result, function (value) {
          options.push(value.plain());
        });
        return options;
      });
    };

    self.saveDefaultRewardOptions = function (options) {
      var deferred = $q.defer();
      Restangular.one('RewardCodes').customPUT({
        options: options
      }, 'pricingOptionsTemplate')
        .then(function (result) {
          deferred.resolve(result.plain());
        }, function (error) {
          deferred.reject(error.data.errorCode);
        });

      return deferred.promise;
    };

    self.getRegistrationFormCode = function (rewardCode) {
      return '<iframe frameborder="0" width="440" height="440" src="' + configOptions.appUrl + '/widgets/register/?rewardCode=' + rewardCode + '"></iframe>';
    };

    self.openRewardCreateModal = function () {
      var modalInstance = $uibModal.open({
        animation: false,
        appendTo: angular.element($document[0].body),
        templateUrl: 'my-memberships/reward-program/reward-code-create-modal.tpl.html',
        controller: 'RewardCodeCreateCtrl',
        controllerAs: 'rewardProgram',
        size: 'md'
      });

      return modalInstance.result;
    };

    self.archive = function (idRewardCode) {
      var deferred = $q.defer();
      Restangular.one('RewardCodes').customPUT({}, 'archive?id=' + idRewardCode)
        .then(function (result) {
          deferred.resolve(result.plain());
        }, function (error) {
          deferred.reject(error.data.errorCode);
        });

      return deferred.promise;
    };

    self.updateRewardProgramsAndCodes = function (broadcast, callback) {
      var programDeferred = $q.defer(),
          codesDeferred = $q.defer();

      // Get the default reward program
      self.getDefaultRewardOptions().then(function (response) {
        programDeferred.resolve(response);
      });

      // Get the reward codes.
      self.getRewardCodes().then(function (response) {
        codesDeferred.resolve(response);
      });

      $q.all({program: programDeferred.promise, codes: codesDeferred.promise})
        .then(function (result) {
          Session.updateLocalUserProfile({
            defaultRewardProgram: result.program,
            rewardCodes: result.codes
          }, broadcast);
          if (angular.isDefined(callback) && angular.isFunction(callback)) {
            callback.apply();
          }
        });
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name myMemberships.rewardProgram.controller:RewardCodeEmbedCtrl
   *
   * @description
   *
   */
  RewardCodeEmbedCtrl.$inject = ["$sce", "$uibModalInstance", "RewardCodes", "rewardCode"];
  angular
    .module('myMemberships.rewardProgram')
    .controller('RewardCodeEmbedCtrl', RewardCodeEmbedCtrl);

  function RewardCodeEmbedCtrl($sce, $uibModalInstance, RewardCodes, rewardCode) {
    var vm = this;
    vm.embedCode = $sce.trustAsHtml(RewardCodes.getRegistrationFormCode(rewardCode.rewardCode));
    vm.previewVisible = false;

    vm.close = function () {
      $uibModalInstance.close();
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name myMemberships.rewardProgram.controller:RewardCodeDetailCtrl
   *
   * @description
   *
   */
  RewardCodeDetailCtrl.$inject = ["$timeout", "$interpolate", "$uibModalInstance", "SocialShare", "BitlyUrlShortener", "Restangular", "Notification", "configOptions", "RewardCodes", "rewardCode"];
  angular
    .module('myMemberships.rewardProgram')
    .controller('RewardCodeDetailCtrl', RewardCodeDetailCtrl);

  function RewardCodeDetailCtrl($timeout, $interpolate, $uibModalInstance, SocialShare, BitlyUrlShortener, Restangular, Notification, configOptions, RewardCodes, rewardCode) {
    var vm = this;
    vm.rewardCode = _.clone(rewardCode);
    // vm.rewardCode.originalUserShare = rewardCode.userShare;
    vm.rewardCode.originalDescription = rewardCode.description;
    vm.data = {
      description: vm.rewardCode.description,
      userShareEmail: ''
    };

    // Associated Users
    vm.associatedUsers = vm.rewardCode.associatedUsers || [];
    vm.associatedUsersClone = _.clone(rewardCode.associatedUsers || []);

    vm.userShareEmailHelp = 'Type in the email address of another MyLeadPod user you want to share the Reward Code with.';
    vm.isCopyToClipboardSuccessful = false;
    vm.editDescriptionVisible = false;
    vm.editUserShareVisible = false;
    vm.editAssociatedUsersVisible = false;
    vm.isSavingDescription = false;
    vm.isSavingUserShare = false;
    vm.userShareEmailChecked = false;
    vm.userShareEmailValid = false;
    vm.userShareEmailValidating = false;
    vm.isArchiving = false;
    vm.shortUrl = null;
    vm.emailBody = null;

    vm.searchCategories = [{
      name: 'Directory',
      value: 'businessUsers',
      stateTarget: 'user',
      iconClass: 'fa-briefcase'
    }];

    if (angular.isDefined(rewardCode.userShare) && angular.isObject(rewardCode.userShare)) {
      vm.data.userShareEmail = rewardCode.userShare.email;
      vm.userShareEmailValid = true;
      vm.userShareEmailChecked = true;
    }

    vm.close = function () {
      $uibModalInstance.close({
        updatedRecord: vm.rewardCode
      });
    };

    vm.archive = function () {
      vm.isArchiving = true;
      RewardCodes.archive(rewardCode.id)
        .then(function (result) {
          vm.rewardCode = result;
          rewardCode = result;
          vm.isArchiving = false;
        }, function () {
          vm.isArchiving = false;
        });
    };

    vm.getUrl = function () {
      return RewardCodes.getRewardCodeUrl(rewardCode.rewardCode);
    };

    vm.share = function (socialService) {
      SocialShare.share(socialService, {
        url: vm.shortUrl,
        name: configOptions.defaultShareName,
        description: configOptions.defaultShareMessage,
        source: 'MyLeadPod',
        picture: configOptions.appUrl + '/images/touch-icon-192x192.png'
      });
    };

    vm.saveDescription = function () {
      vm.isSavingDescription = true;
      vm.editDescriptionError = false;
      RewardCodes.update(rewardCode.id, {
        description: vm.data.description
      })
        .then(function (result) {
          vm.rewardCode = result;
          rewardCode = result;
          vm.isSavingDescription = false;
          vm.editDescriptionVisible = false;
          vm.rewardCode.originalDescription = rewardCode.description;
        }, function () {
          vm.isSavingDescription = false;
          vm.editDescriptionError = 'An error occurred';
        });
    };

    vm.openUserShare = function () {
      vm.editUserShareVisible = true;
      console.log(angular.element('#share-autocomplete_value'));
      $timeout(function () {
        angular.element('#share-autocomplete_value')[0].focus();
      }, 200);
    };

    vm.saveUserShare = function (userMsg) {
      vm.isSavingUserShare = true;
      vm.editUserShareError = false;

      vm.searchingUser = userMsg || 'Saving the changes...';
      return RewardCodes.update(rewardCode.id, {
        userShareEmail: vm.data.userShareEmail
      })
        .then(function (result) {
          vm.rewardCode = result;
          rewardCode = result;
          vm.editUserShareVisible = false;
          vm.isSavingUserShare = false;
          vm.rewardCode.originalDescription = rewardCode.description;

          // MGA - #37545
          vm.searchingUser = false;
          vm.editUserShareVisible = false;
        }, function () {
          vm.isSavingUserShare = false;
          vm.editUserShareError = 'An error occurred';

          // MGA - #37545
          vm.searchingUser = false;
          vm.editUserShareVisible = false;
        });
    };

    vm.removeUserShare = function () {
      vm.data.userShareEmail = '';
      vm.saveUserShare('Removing sharing with the user..').then(function () {
        vm.rewardCode.userShare = null;
      }, function () {
        // Do what?
        vm.data.userShareEmail = vm.rewardCode.userShare.email;
      });
    };

    vm.isUserShareEmailValid = function () {
      if (vm.data.userShareEmail.length === 0) {
        return true;
      }

      return vm.userShareEmailValid;
    };

    vm.onSearchSharedItemSelected = function (item) {
      if (!item) {
        return;
      }
      vm.searchingUser = 'Retrieving the user profile';
      Restangular.one('Users').one('profile').get({id: item.originalObject.id})
        .then(function (profile) {
          if (!profile) {
            return;
          }
          profile = profile.plain();
          vm.data.userShareEmail = profile.email;
          vm.saveUserShare().then(function () {
            vm.rewardCode.userShare = profile;
          });
        }, function () {
          vm.searchingUser = false;
        });
    };

    vm.searchResponseFormatter = function (response) {
      var r,
          records;

      if (angular.isUndefined(response) || !response) {
        return null;
      }
      vm.emptyResults = false;
      r = response.records;
      if (r.length === 0) {
        vm.emptyResults = true;
      }

      records = _.map(r, function (item) {
        var category = _.find(vm.searchCategories, {value: item.datasource}),
            returnObject = {},
            categoryDescriptionClass,
            value = item.fullname;

        categoryDescriptionClass = category.iconClass || 'fa-user';
        if (item.companyname && item.companyname !== item.fullname) {
          value += ' | ' + item.companyname;
        }

        returnObject = {
          value: value,
          id: item.id,
          category: item.datasource,
          categoryDescription: '<i class="fa ' + categoryDescriptionClass + '"></i>',
          timeDescription: item.datecreatedformatted ? item.datecreatedformatted : '',
          leadtype: item.leadtype
        };

        return returnObject;
      });

      return records;
    };

    /**
     * Copy success callback
     */
    vm.onSuccess = function () {
      Notification({message: 'Copied into clipboard!'}, 'success');
    };

    vm.hasEditsAssociatedUsers = function () {
      var diff1 = _.difference(vm.associatedUsersClone, vm.associatedUsers),
          diff2 = _.difference(vm.associatedUsers, vm.associatedUsersClone);
      return diff1.length > 0 || diff2.length > 0 ? true : false;
    };

    vm.openAssociatedUser = function () {
      vm.editAssociatedUsersVisible = true;
      $timeout(function () {
        angular.element('#associated-autocomplete_value')[0].focus();
      }, 200);
    };

    vm.addAssociatedUser = function (userProfile) {
      if (_.where(vm.associatedUsers, {idUser: userProfile.idUser}).length === 0) {
        vm.associatedUsers.push(userProfile);
      }
    };

    vm.onAssociatedItemSelected = function (item) {
      if (!item) {
        return;
      }
      vm.searchingAssociatedUser = 'Retrieving the user profile';
      Restangular.one('Users').one('profile').get({id: item.originalObject.id})
        .then(function (profile) {
          if (!profile) {
            return;
          }
          profile = profile.plain();
          vm.addAssociatedUser(profile);
          vm.searchingAssociatedUser = false;
        }, function () {
          vm.searchingAssociatedUser = false;
        });
    };

    vm.onAssociatedUsersCancel = function () {
      vm.editAssociatedUsersVisible = false;
      vm.associatedUsers = _.clone(vm.associatedUsersClone);
    };

    vm.saveAssociatedUsers = function () {
      vm.isSavingAssociatedUsers = true;
      vm.editAssociatedUsersError = false;

      vm.searchingAssociatedUser = 'Saving the changes...';
      return RewardCodes.update(rewardCode.id, {
        associatedUsers: _.map(vm.associatedUsers, function (user) {
          return user.id;
        })
      })
        .then(function (result) {
          vm.rewardCode = result;
          rewardCode = result;
          vm.associatedUsers = vm.rewardCode.associatedUsers || [];
          vm.associatedUsersClone = _.clone(rewardCode.associatedUsers || []);
          vm.editAssociatedUsersVisible = false;
          vm.isSavingAssociatedUsers = false;
          vm.rewardCode.originalDescription = rewardCode.description;

          // MGA - #37545
          vm.searchingAssociatedUser = false;
          vm.editAssociatedUsersVisible = false;
        }, function () {
          vm.editAssociatedUsersError = false;
          vm.editUserShareError = 'An error occurred';

          // MGA - #37545
          vm.searchingAssociatedUser = false;
          vm.editAssociatedUsersVisible = false;
        });
    };

    vm.removeAssociatedUser = function ($index) {
      vm.associatedUsers.splice($index, 1);
      vm.editAssociatedUsersVisible = true;
    };

    vm.embed = function () {
      RewardCodes.openEmbed(rewardCode);
    };

    BitlyUrlShortener.shorten(vm.getUrl())
      .then(function (result) {
        var exp = $interpolate(configOptions.defaultEmailMessage);
        vm.shortUrl = result;
        vm.emailBody = encodeURIComponent(exp({link: vm.shortUrl}));
      });
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name myMemberships.rewardCodeCreate.controller:RewardCodeCreateCtrl
   *
   * @description
   *
   */
  RewardCodeCreateCtrl.$inject = ["$scope", "$document", "$timeout", "$filter", "Notification", "FormAlerts", "Restangular", "Session", "RewardCodes", "$uibModalInstance"];
  angular
    .module('myMemberships.rewardProgram')
    .controller('RewardCodeCreateCtrl', RewardCodeCreateCtrl);

  function RewardCodeCreateCtrl($scope, $document, $timeout, $filter, Notification, FormAlerts, Restangular, Session, RewardCodes, $uibModalInstance) {
    var vm = this;
    vm.alerts = new FormAlerts();
    vm.isLoading = false;
    vm.rewardCodeChecked = false;
    vm.userShareEmailChecked = false;
    vm.rewardCodeValid = false;
    vm.rewardCodeValidating = false;
    vm.rewardsProgramSetup = [];
    vm.isRewardsProgramDetailVisible = false;
    vm.detailsVisible = false;
    vm.sharingVisible = false;
    vm.associatedUsersVisible = false;
    vm.associatedUsers = [];
    vm.sharedUsers = [];
    vm.data = {
      options: [],
      rewardType: 'rewardsProgram',
      userShareEmail: null
    };
    vm.searchCategories = [{
      name: 'Directory',
      value: 'businessUsers',
      stateTarget: 'user',
      iconClass: 'fa-briefcase'
    }];

    // Subscribe to the local update
    $scope.$on('event:user-updateLocalProfile', function () {
      if (vm.user.defaultRewardProgram) {
        vm.renderRewardProgramOptions(vm.user.defaultRewardProgram);
      }
    });

    function getIdForOption() {
      var d = new Date();
      return d.getTime();
    }

    function initData() {
      vm.data.options.push({
        name: null,
        successfulLeadPrice: null,
        uiId: getIdForOption()
      });
    }

    // Render reward program option
    vm.renderRewardProgramOptions = function (options) {
      if (options && options.length > 0) {
        angular.forEach(options, function (option) {
          var uiId = getIdForOption() * Math.random();
          vm.rewardsProgramSetup.push(option);
          vm.data.options.push(angular.extend({}, option, {
            uiId: uiId.toString().replace('.', '')
          }));
        });
        vm.data.options = $filter('orderBy')(vm.data.options, 'successfulLeadPrice');
      }
      else {
        initData();
        vm.isRewardsProgramDetailVisible = true;
      }
    };
    Session.getUserProfile().then(function (user) {
      vm.user = user;
      if (user.defaultRewardProgram) {
        vm.renderRewardProgramOptions(user.defaultRewardProgram);
      }
    });

    vm.rewardCodeHelp = 'You can create your own Reward Codes.\n\nExamples: MYCOMPANYNAME123, CODE123, SUMMERPROMOTION, ...\n\nPlease choose between 5 to 20 characters.';
    vm.userShareEmailHelp = 'Type in the email address of another MyLeadPod user you want to share the Reward Code with.';
    vm.rewardsHelp = 'Change the Reward Program for this Reward Code. Users joining you via this Reward Code will be connected to the assigned Reward Program.';
    vm.shareHelp = 'Any leads that you will receive via this Reward Code will be shared with the designated user.';
    vm.associatedUsersHelp = 'Associated users is the group of businesses that the user registering through this Reward Code will be automatically connected with.';

    vm.addOption = function () {
      var optionId = getIdForOption();
      vm.data.options.push({
        name: null,
        successfulLeadPrice: null,
        uiId: getIdForOption()
      });

      $timeout(function () {
        angular.element($document[0].getElementById('name' + optionId))[0].focus();
      }, 0);
    };

    vm.deleteOption = function ($index) {
      vm.data.options.splice($index, 1);
    };

    vm.onRewardCodeChange = function () {
      vm.rewardCodeChecked = false;
      vm.rewardCodeValidating = false;
      vm.rewardCodeValid = false;
    };

    vm.validateRewardCode = function () {
      var code;
      vm.rewardCodeChecked = false;
      if (vm.data.rewardCode && vm.data.rewardCode.length >= 5 && vm.data.rewardCode.length <= 20) {
        code = vm.data.rewardCode.toUpperCase().replace(/\s+/g, '-');
        vm.data.rewardCode = code.replace(/[-]+/g, '-');
        vm.rewardCodeValidating = true;
        RewardCodes.isRewardCodeUnique(vm.data.rewardCode)
          .then(function (isUnique) {
            vm.rewardCodeChecked = true;
            vm.rewardCodeValidating = false;
            vm.rewardCodeValid = isUnique;
            // Please don't remove. This could be the workaround if the $digest takes too long to apply changes to watchers. _.defer(function(){$scope.$apply();});
          });
      }
    };

    vm.create = function () {
      var associatedUsersIds = _.map(vm.associatedUsers, function (user) {
        return user.id;
      });
      vm.isLoading = true;
      vm.alerts.reset();
      RewardCodes.create(vm.data.rewardCode.toUpperCase(), vm.data.rewardType, vm.data.options, vm.data.description, vm.data.userShareEmail, associatedUsersIds)
        .then(function () {
          // Force the refresh of the reward program and code for the local user
          RewardCodes.updateRewardProgramsAndCodes(true);
          Notification({message: 'Success'}, 'confirmation');
          $uibModalInstance.close('success');
        }, function (errorCode) {
          if (errorCode === 601) {
            vm.alerts.add('danger', 'This Reward Code is already used. Please choose another one.');
          }
          else {
            vm.alerts.add('danger', 'Unable to create');
          }
          vm.isLoading = false;
        });
    };

    vm.toggleRewardsProgramDetailVisibility = function () {
      vm.isRewardsProgramDetailVisible = !vm.isRewardsProgramDetailVisible;
    };

    vm.toggleMoreDetailsVisibility = function () {
      vm.detailsVisible = !vm.detailsVisible;
      vm.associatedUsersVisible = false;
      vm.sharingVisible = false;
      if (!vm.detailsVisible) {
        vm.isRewardsProgramDetailVisible = false;
      }
    };

    vm.toggleSharingVisibility = function () {
      vm.sharingVisible = !vm.sharingVisible;
      vm.associatedUsersVisible = false;
      vm.detailsVisible = false;
      $timeout(function () {
        angular.element('#share-autocomplete_value')[0].focus();
      }, 200);
    };

    vm.toggleAssociatedUsersVisibility = function () {
      vm.associatedUsersVisible = !vm.associatedUsersVisible;
      vm.detailsVisible = false;
      vm.sharingVisible = false;
      $timeout(function () {
        angular.element('#associate-autocomplete_value')[0].focus();
      }, 200);
    };

    vm.closeCreateCodeModal = function () {
      $uibModalInstance.dismiss();
    };

    vm.onSearchSharedItemSelected = function (item) {
      if (!item) {
        return;
      }
      vm.searchingUser = true;
      Restangular.one('Users').one('profile').get({id: item.originalObject.id})
        .then(function (profile) {
          vm.searchingUser = false;
          profile = profile.plain();
          vm.addShareUser(profile);
        });
    };

    vm.onSearchItemSelected = function (item) {
      if (!item) {
        return;
      }
      vm.searchingUser = true;
      Restangular.one('Users').one('profile').get({id: item.originalObject.id})
        .then(function (profile) {
          vm.searchingUser = false;
          if (!profile) {
            return;
          }
          profile = profile.plain();
          vm.addUser(profile);
        });
    };

    vm.addShareUser = function (userProfile) {
      if (vm.sharedUsers.length === 0 && _.where(vm.sharedUsers, {idUser: userProfile.idUser}).length === 0) {
        vm.sharedUsers.push(userProfile);
        vm.data.userShareEmail = userProfile.email;
      }
    };

    vm.removeUserShare = function () {
      vm.sharedUsers = [];
      vm.data.userShareEmail = null;
    };

    vm.searchResponseFormatter = function (response) {
      var r,
          records;

      if (angular.isUndefined(response) || !response) {
        return null;
      }
      vm.emptyResults = false;
      r = response.records;
      if (r.length === 0) {
        vm.emptyResults = true;
      }

      records = _.map(r, function (item) {
        var category = _.find(vm.searchCategories, {value: item.datasource}),
            returnObject = {},
            categoryDescriptionClass,
            value = item.fullname;

        categoryDescriptionClass = category.iconClass || 'fa-user';
        if (item.companyname && item.companyname !== item.fullname) {
          value += ' | ' + item.companyname;
        }

        returnObject = {
          value: value,
          id: item.id,
          category: item.datasource,
          categoryDescription: '<i class="fa ' + categoryDescriptionClass + '"></i>',
          timeDescription: item.datecreatedformatted ? item.datecreatedformatted : '',
          leadtype: item.leadtype
        };

        return returnObject;
      });

      return records;
    };

    vm.addUser = function (userProfile) {
      if (_.where(vm.associatedUsers, {idUser: userProfile.idUser}).length === 0) {
        vm.associatedUsers.push(userProfile);
      }
    };

    vm.showButton = function (index) {
      var r = $document[0].querySelector('[data-delete-btn=\'' + index + '\']');
      if (r) {
        angular.element(r).addClass('btn-flip');
        r.querySelector('button').focus();
      }
    };

    vm.hideButton = function (index, btn, evt) {
      try {
        angular.element(evt.currentTarget.parentElement).removeClass('btn-flip');
      }
      catch (ex) {
        console.error(ex);
      }
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name myMemberships.receivedLeads
   * @description
   *
   */
  angular
    .module('myMemberships.receivedLeads', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider", "configOptions"];
  angular
    .module('myMemberships.receivedLeads')
    .config(config);

  function config($stateProvider, configOptions) {
    $stateProvider
      .state('myMemberships.receivedLeads', angular.extend({}, configOptions.defaultStateParams, {
        url: '/received-leads',
        templateUrl: 'my-memberships/my-memberships.tpl.html',
        controller: 'MyMembershipsCtrl',
        controllerAs: 'myMemberships',
        params: {
          leadToOpen: null
        },
        onEnter: ['RouterHelper', function (RouterHelper) {
          RouterHelper.activateTab('myMemberships', 'receivedLeads');
        }]
      }));
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name myMemberships.receivedLeads.controller:ReceivedLeadsCtrl
   *
   * @description
   *
   */
  ReceivedLeadsCtrl.$inject = ["$stateParams", "DataTable", "Leads"];
  angular
    .module('myMemberships.receivedLeads')
    .controller('ReceivedLeadsCtrl', ReceivedLeadsCtrl);

  function ReceivedLeadsCtrl($stateParams, DataTable, Leads) {
    var vm = this,
        filter;
    vm.statuses = {
      all: 'All leads',
      open: 'Open',
      new: 'New',
      successful: 'Successful',
      unsuccessful: 'Unsuccessful'
    };
    vm.searchLead = '';
    vm.selectedStatus = 'all';
    vm.selectedStatusLabel = 'All leads';
    vm.lastSearched = null;

    vm.list = DataTable.create('Leads', 'index', {}, {
      basicParams: {
        type: 'received'
      }
    });

    filter = function () {
      vm.list.reload({
        status: vm.selectedStatus,
        search: vm.searchLead
      });
    };

    vm.selectStatus = function (leadStatus) {
      vm.selectedStatus = leadStatus;
      vm.selectedStatusLabel = vm.statuses[leadStatus];
      filter();
    };

    vm.search = function () {
      if (vm.selectedStatus + vm.searchLead === vm.lastSearched) {
        return;
      }
      filter();
      vm.lastSearched = vm.selectedStatus + vm.searchLead;
    };

    vm.openDetail = function ($index) {
      var detailInstance = Leads.openDetail(vm.list.getRecords()[$index], 'received', 'received-leads-ctrl-container');
      if (angular.isDefined(detailInstance)) {
        detailInstance.then(function (result) {
          if (angular.isObject(result) && angular.isObject(result.lead)) {
            vm.list.getRecords()[$index] = result.lead;
          }
        });
      }
    };

    vm.closeLead = function ($index, status) {
      var result = Leads.closeLead(vm.list.getRecords()[$index], status, 'received-leads-ctrl-container');
      if (result) {
        result.then(function () {
          vm.list.getRecords()[$index].status = status;
        });
      }
    };

    if (angular.isObject($stateParams.leadToOpen) && angular.isNumber($stateParams.leadToOpen.id)) {
      Leads.openDetail($stateParams.leadToOpen, 'received', 'received-leads-ctrl-container');
    }
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name myMemberships.memberships
   * @description
   *
   */
  angular
    .module('myMemberships.memberships', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider", "configOptions"];
  angular
    .module('myMemberships.memberships')
    .config(config);

  function config($stateProvider, configOptions) {
    $stateProvider
      .state('myMemberships.memberships', angular.extend({}, configOptions.defaultStateParams, {
        url: '/memberships',
        templateUrl: 'my-memberships/my-memberships.tpl.html',
        controller: 'MyMembershipsCtrl',
        controllerAs: 'myMemberships',
        onEnter: ['RouterHelper', function (RouterHelper) {
          RouterHelper.activateTab('myMemberships', 'memberships');
        }]
      }));
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name myMemberships.memberships.controller:MembershipsCtrl
   *
   * @description
   *
   */
  MembershipsCtrl.$inject = ["DataTable"];
  angular
    .module('myMemberships.memberships')
    .controller('MembershipsCtrl', MembershipsCtrl);

  function MembershipsCtrl(DataTable) {
    var vm = this;
    vm.searchName = '';
    vm.tableSubContentVisible = null;
    vm.lastSearched = null;
    vm.list = DataTable.create('Memberships', 'index');
    vm.search = function () {
      if (vm.lastSearched === vm.searchName) {
        return;
      }
      vm.list.reload({
        search: vm.searchName
      });
      vm.lastSearched = vm.searchName;
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name myMemberships.joinPod
   * @description
   *
   */
  angular
    .module('myMemberships.joinPod', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('myMemberships.joinPod')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('myMembershipsJoinPod', {
        url: '/my-memberships/join-pod',
        template: '<ui-view />',
        controller: 'JoinPodCtrl',
        controllerAs: 'joinPod',
        authenticate: true,
        params: {
          user: null,
          nextState: null
        },
        resolve: {
          userProfile: ['Session', function (Session) {
            return Session.getUserProfile();
          }]
        }
      });
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name myMemberships.joinPod.controller:JoinPodModalCtrl
   *
   * @description
   *
   */
  JoinPodModalCtrl.$inject = ["Restangular", "Notification", "FormAlerts", "$uibModalInstance", "myProfile", "userProfile"];
  angular
    .module('myMemberships.joinPod')
    .controller('JoinPodModalCtrl', JoinPodModalCtrl);

  function JoinPodModalCtrl(Restangular, Notification,
                       FormAlerts, $uibModalInstance, myProfile, userProfile) {
    var vm = this;
    vm.isLoading = false;

    vm.rewardCodes = {};
    vm.selectedRewardCode = '';
    vm.selectedRewardCodeLabel = 'Reward Code';
    vm.alerts = new FormAlerts();
    vm.user = myProfile;
    vm.targetUser = userProfile;
    vm.isRewardsFormValid = false;

    vm.data = {
      firstName: vm.targetUser ? vm.targetUser.firstName : null,
      email: vm.targetUser ? vm.targetUser.email : null,
      creditCard: {},
      phoneMobile: vm.targetUser ? vm.targetUser.phoneMobile : null
    };

    vm.cancel = function () {
      $uibModalInstance.dismiss();
    };

    vm.join = function () {
      vm.isLoading = true;
      vm.alerts.reset();
      Restangular.one('Memberships').post('index', vm.data)
        .then(function (response) {
          $uibModalInstance.close(response.receiveLeads);
          Notification({message: 'Success'}, 'confirmation');
        }, function () {
          vm.isLoading = false;
          vm.alerts.add('danger', 'Unable to send');
        });
    };

    vm.onRewardSelectionChange = function (data, isFormValid) {
      _.extend(vm.data, data);
      vm.isRewardsFormValid = isFormValid;
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name myMemberships.joinPod.controller:JoinPodCtrl
   *
   * @description
   *
   */
  JoinPodCtrl.$inject = ["$state", "MyMemberships", "userProfile"];
  angular
    .module('myMemberships.joinPod')
    .controller('JoinPodCtrl', JoinPodCtrl);

  function JoinPodCtrl($state, MyMemberships, userProfile) {
    var vm = this;
    vm.openAskForLeads = function () {
      var modalInstance = MyMemberships.openAskForLeads(userProfile, null);
      modalInstance.then(function () {
        $state.go('myMemberships');
      }, function () {
        $state.go('myMemberships');
      });
    };
    vm.openAskForLeads();
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name myMemberships.import
   * @description
   *
   */
  angular
    .module('myMemberships.import', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider", "configOptions"];
  angular
    .module('myMemberships.import')
    .config(config);

  function config($stateProvider, configOptions) {
    $stateProvider
      .state('myMemberships.import', angular.extend({}, configOptions.defaultStateParams, {
        url: '/import',
        templateUrl: 'my-memberships/my-memberships.tpl.html',
        controller: 'MyMembershipsCtrl',
        controllerAs: 'myMemberships',
        params: {
          leadToOpen: null
        },
        onEnter: ['RouterHelper', function (RouterHelper) {
          RouterHelper.activateTab('myMemberships', 'import');
        }]
      }));
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name myMemberships.import.controller:ImportCtrl
   *
   * @description
   *
   */
  ImportCtrl.$inject = ["Upload", "Auth", "FormAlerts", "Notification"];
  angular
    .module('myMemberships.import')
    .controller('ImportCtrl', ImportCtrl);

  function ImportCtrl(Upload, Auth, FormAlerts, Notification) {
    var vm = this;
    vm.downloadTemplateUrl = '/Memberships/importTemplate';
    vm.acceptedFiles = 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,*.xls,*.xlsx';
    vm.isUploading = false;
    vm.importProgressPercent = 0;
    vm.alerts = new FormAlerts();
    vm.importFinished = false;
    vm.alertsExpanded = false;

    vm.upload = function (files) {
      if (!vm.isUploading && files && files[0]) {
        vm.isUploading = true;
        vm.importProgressPercent = 0;
        vm.alerts.reset();
        Upload.upload({
          url: Auth.fixApiEndpointUrl('/Memberships/import'),
          data: {file: files[0]},
          method: 'POST'
        })
          .then(function (result) {
            if (result.data.content.status === true) {
              vm.importFinished = true;
              Notification({message: 'Success'}, 'confirmation');
            }
            else {
              angular.forEach(result.data.content.errors, function (item) {
                vm.alerts.add('danger', item.message);
              });
            }
            vm.isUploading = false;
          }, function () {
            vm.alerts.add('danger', 'Unable to import');
            vm.isUploading = false;
          }, function (evt) {
            vm.importProgressPercent = parseInt(100.0 * evt.loaded / evt.total, 10);
          });
      }
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name dashboard.memberships
   * @description
   *
   */
  angular
    .module('dashboard.memberships', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name dashboard.memberships.directive:receivedLeadsList
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="dashboard.memberships">
       <file name="index.html">
        <received-leads-list></received-leads-list>
       </file>
     </example>
   *
   */
  angular
    .module('dashboard.memberships')
    .directive('receivedLeadsList', receivedLeadsList);

  function receivedLeadsList() {
    return {
      restrict: 'EA',
      scope: {
        list: '=list'
      },
      templateUrl: 'dashboard/memberships/received-leads-list-directive.tpl.html',
      replace: false,
      controllerAs: 'receivedLeadsList',
      controller: ["$scope", "$uibModal", "Leads", function ($scope, $uibModal, Leads) {
        var vm = this;
        vm.name = 'receivedLeadsList';
        vm.list = $scope.list;

        vm.openDetail = function ($index) {
          Leads.openDetail(vm.list.records[$index], 'received');
        };
      }]
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name dashboard.memberships.directive:receivedLeadsGraph
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="dashboard.memberships">
       <file name="index.html">
        <received-leads-graph></received-leads-graph>
       </file>
     </example>
   *
   */
  angular
    .module('dashboard.memberships')
    .directive('receivedLeadsGraph', receivedLeadsGraph);

  function receivedLeadsGraph() {
    return {
      restrict: 'EA',
      scope: {
        list: '=list'
      },
      templateUrl: 'dashboard/memberships/received-leads-graph-directive.tpl.html',
      replace: false,
      controllerAs: 'receivedLeadsGraph',
      controller: ["$scope", "$filter", function ($scope, $filter) {
        var vm = this;

        vm.chartData = {
          labels: _.map($scope.list, function (day) {
            return $filter('date')(day.date, 'd/M');
          }),
          series: [_.map($scope.list, function (day) {
            return day.leads;
          })]
        };

        vm.chartOptions = {
          axisX: {
            showGrid: false,
            showLabel: false
          },
          axisY: {
            showGrid: true,
            showLabel: true,
            low: 0,
            onlyInteger: true
          },
          lineSmooth: false,
          scaleMinSpace: 10,
          height: 400
        };

        vm.chartEvents = {
          draw: function (data) {
            var circle;
            if (data.type === 'point') {
              circle = new Chartist.Svg('circle', {
                cx: [data.x],
                cy: [data.y],
                r: [5]
              }, 'ct-circle');
              data.element.replace(circle);
            }
          }
        };

        vm.responsiveOptions = [
          ['screen and (min-width: 768px) and (max-width: 991px)', {
            width: 646
          }],
          ['screen and (min-width: 992px) and (max-width: 1199px)', {
            width: 487
          }],
          ['screen and (min-width: 1200px)', {
            width: 590
          }]
        ];
      }],
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  angular
    .module('dashboard.memberships')
    .config(config);

  function config() {
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name dashboard.memberships.controller:DashboardMembershipsCtrl
   *
   * @description
   *
   */
  DashboardMembershipsCtrl.$inject = ["$scope"];
  angular
    .module('dashboard.memberships')
    .controller('DashboardMembershipsCtrl', DashboardMembershipsCtrl);

  function DashboardMembershipsCtrl($scope) {
    var vm = this;
    vm.dailyStats = $scope.dashboard.leadsDailyStats;
    vm.list = $scope.dashboard.receivedLeads;
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name dashboard.stats
   * @description
   *
   */
  angular
    .module('dashboard.stats', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  angular
    .module('dashboard.stats')
    .config(config);

  function config() {
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name dashboard.stats.controller:StatsCtrl
   *
   * @description
   *
   */
  StatsCtrl.$inject = ["$scope", "$filter", "Charities"];
  angular
    .module('dashboard.stats')
    .controller('StatsCtrl', StatsCtrl);

  function StatsCtrl($scope, $filter, Charities) {
    var vm = this;

    vm.membersCount = $scope.dashboard.membersCount;
    vm.membershipsCount = $scope.dashboard.membershipsCount;
    vm.sentLeadsCount = $scope.dashboard.sentLeadsCount;
    vm.donations = $filter('currency')($scope.dashboard.donations, '$', 0);
    vm.earnings = $filter('currency')($scope.dashboard.earnings.lastMonthEarnings, '$', 0);
    vm.balance = $filter('currency')($scope.dashboard.earnings.actualEarnings, '$', 0);
    vm.isBusinessAccountActive = $scope.dashboard.isBusinessAccountActive;

    vm.donationsValue = $scope.dashboard.donations;
    vm.earningsValue = $scope.dashboard.earnings.lastMonthEarnings;

    vm.getLatestPods = function () {
      return [];
    };

    vm.getMembers = function () {
      return [];
    };

    vm.getEarnings = function () {
      return 0;
    };

    vm.getBalance = function () {
      return 0;
    };

    vm.getDonations = function () {
      return 0;
    };

    vm.openDonate = function () {
      Charities.openDonate();
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name dashboard.pod
   * @description
   *
   */
  angular
    .module('dashboard.pod', [
      'ui.router',
      'angular-chartist'
    ]);
}());

(function () {
  'use strict';

  angular
    .module('dashboard.pod')
    .config(config);

  function config() {
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name dashboard.pod.directive:podMembersList
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="dashboard.pod">
       <file name="index.html">
        <pod-members-list></pod-members-list>
       </file>
     </example>
   *
   */
  angular
    .module('dashboard.pod')
    .directive('podMembersList', podMembersList);

  function podMembersList() {
    return {
      restrict: 'EA',
      scope: {
        list: '=list'
      },
      templateUrl: 'dashboard/pod/pod-members-list-directive.tpl.html',
      replace: false,
      controllerAs: 'podMembersList',
      controller: ["$scope", function ($scope) {
        var vm = this;
        vm.list = $scope.list;
      }]
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name dashboard.pod.directive:podEarningsGraph
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="dashboard.pod">
       <file name="index.html">
        <pod-earnings-graph></pod-earnings-graph>
       </file>
     </example>
   *
   */
  angular
    .module('dashboard.pod')
    .directive('podEarningsGraph', podEarningsGraph);

  function podEarningsGraph() {
    return {
      restrict: 'EA',
      scope: {
        list: '=list'
      },
      templateUrl: 'dashboard/pod/pod-earnings-graph-directive.tpl.html',
      replace: false,
      controllerAs: 'podEarningsGraph',
      controller: ["$scope", "$filter", function ($scope, $filter) {
        var vm = this;

        vm.chartData = {
          labels: _.map($scope.list, function (day) {
            return $filter('date')(day.date, 'd/M');
          }),
          series: [_.map($scope.list, function (day) {
            return day.cumulativeEarnings;
          })]
        };

        vm.chartOptions = {
          axisX: {
            showGrid: false,
            showLabel: false
          },
          axisY: {
            showGrid: true,
            showLabel: true,
            low: 0,
            onlyInteger: true
          },
          lineSmooth: false,
          scaleMinSpace: 10,
          showArea: true,
          height: 400,
          stretch: true
        };

        vm.chartEvents = {
          draw: function (data) {
            var circle;
            if (data.type === 'point') {
              circle = new Chartist.Svg('circle', {
                cx: [data.x],
                cy: [data.y],
                r: [5]
              }, 'ct-circle');
              data.element.replace(circle);
            }
          }
        };

        vm.responsiveOptions = [
          ['screen and (min-width: 768px) and (max-width: 991px)', {
            width: 646
          }],
          ['screen and (min-width: 992px) and (max-width: 1199px)', {
            width: 570
          }],
          ['screen and (min-width: 1200px)', {
            width: 688
          }]
        ];
      }],
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name dashboard.pod.controller:PodCtrl
   *
   * @description
   *
   */
  PodCtrl.$inject = ["$scope"];
  angular
    .module('dashboard.pod')
    .controller('PodCtrl', PodCtrl);

  function PodCtrl($scope) {
    var vm = this;
    vm.dailyStats = $scope.dashboard.earningsDailyStats;
    vm.list = $scope.dashboard.members;
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name components.ui
   * @description
   *
   */
  angular
    .module('components.ui', [
      'components.ui.table',
      'components.ui.tabs'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name components.ui.service:PromptDialog
   *
   * @description
   *
   */
  PromptDialog.$inject = ["$document", "$uibModal"];
  angular
    .module('components.ui')
    .service('PromptDialog', PromptDialog);

  function PromptDialog($document, $uibModal) {
    var self = this;

    /**
     * Opens up a modal dialog
     * @param {string} content Content message
     * @param {string} onConfirmMessage Confirmation message
     * @param {string} confirmButtonLabel Label to display on confirm button
     * @param {string} cancelButtonLabel Label to display on cancel button
     * @returns {*} Modal instance
     */
    self.open = function (content, onConfirmMessage, confirmButtonLabel, cancelButtonLabel) {
      var instance;

      instance = $uibModal.open({
        animation: false,
        appendTo: angular.element($document[0].body),
        templateUrl: 'components/ui/prompt-dialog.tpl.html',
        controller: 'PromptDialogCtrl',
        controllerAs: 'promptDialog',
        size: 'md',
        resolve: {
          content: function () {
            return content;
          },
          onConfirmMessage: function () {
            return onConfirmMessage;
          },
          cancelButtonLabel: function () {
            return cancelButtonLabel;
          },
          confirmButtonLabel: function () {
            return confirmButtonLabel;
          }
        }
      });

      return instance.result;
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name components.ui.controller:PromptDialogCtrl
   *
   * @description
   *
   */
  PromptDialogCtrl.$inject = ["$timeout", "$uibModalInstance", "content", "onConfirmMessage", "cancelButtonLabel", "confirmButtonLabel"];
  angular
    .module('components.ui')
    .controller('PromptDialogCtrl', PromptDialogCtrl);

  function PromptDialogCtrl($timeout, $uibModalInstance, content, onConfirmMessage, cancelButtonLabel, confirmButtonLabel) {
    var vm = this;

    vm.content = content;
    vm.onConfirmMessage = onConfirmMessage;
    vm.isConfirmed = false;
    vm.cancelButtonLabel = cancelButtonLabel ? cancelButtonLabel : 'No';
    vm.confirmButtonLabel = confirmButtonLabel ? confirmButtonLabel : 'Yes';

    vm.confirm = function () {
      vm.isConfirmed = true;
      if (vm.onConfirmMessage) {
        $timeout(function () {
          $uibModalInstance.close();
        }, 3000);
      }
      else {
        $uibModalInstance.close();
      }
    };

    vm.cancel = function () {
      $uibModalInstance.dismiss();
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name components.ui.table.directive:creditCard
   * @restrict E
   * @element
   */
  angular
    .module('components.ui')
    .directive('creditCard', creditCard);

  function creditCard() {
    return {
      restrict: 'E',
      scope: {
        user: '=',
        ccData: '=',
        ccShowForm: '&',
        creditCard: '='
      },

      // creditCard - is the existing credit card info
      // ccData - is the object into which the new credit card info will be stored (two-way)
      // ccShowForm - whether to show the new ccForm or not
      // user - userProfile from which the data will be source. might be duplicated as creditCard is directly belonging to him already.

      replace: true,
      transclude: false,
      templateUrl: 'components/ui/credit-card-directive.tpl.html',
      controllerAs: 'cc',
      controller: ['$scope', 'CreditCardUtil', function ($scope, CreditCardUtil) {
        var vm = this;
        vm.months = CreditCardUtil.getExpiryMonths();
        vm.years = CreditCardUtil.getExpiryYears();
        vm.cards = CreditCardUtil.getAcceptedCards();

        // Scope variables in the controller
        vm.scope = $scope;

        if (vm.scope.ccData) {
          vm.scope.ccData = {
            method: angular.isObject(vm.scope.creditCard) && angular.isDefined(vm.scope.creditCard.number) ? 'old' : 'new'
          };
        }

        //
        vm.showCCForm = function (form) {
          var showForm = false;
          if (angular.isFunction(vm.scope.ccShowForm) && angular.isDefined(vm.scope.ccShowForm())) {
            showForm = vm.newCard || vm.scope.ccShowForm();
          }
          else if (vm.scope.creditCard && vm.scope.creditCard.number && !vm.newCard) {
            showForm = false;
          }
          else if (vm.scope.creditCard && vm.scope.creditCard.number && vm.newCard) {
            showForm = true;
          }
          else if (!vm.scope.creditCard || !vm.scope.creditCard.number) {
            showForm = true;
          }
          if (!showForm && form) {
            vm.clearForm(form);
          }
          return showForm;
        };

        //
        vm.closeCCForm = function (form) {
          vm.scope.ccData = {};
          vm.newCard = false;
          if (form) {
            vm.clearForm(form);
          }
          vm.scope.ccData.method = 'old';
        };

        //
        vm.clearForm = function (form) {
          delete form.$error.ccExp;
          delete form.$error.ccNumber;
          delete form.$error.ccNumberType;
          delete form.$error.ccExpMonth;
          delete form.$error.ccExpYear;

          if (form.$$parentForm) {
            delete form.$$parentForm.$error.ccExp;
            delete form.$$parentForm.$error.ccNumber;
            delete form.$$parentForm.$error.ccNumberType;
            delete form.$$parentForm.$error.ccExpMonth;
            delete form.$$parentForm.$error.ccExpYear;
          }
        };
      }]
    };
  }
}());

(function () {
  'use strict';

  contentSlider.$inject = ["$rootScope", "$window", "$timeout"];
  angular
    .module('components.ui')
    .directive('contentSlider', contentSlider);

  function contentSlider($rootScope, $window, $timeout) {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint no-unused-vars: [2, {"args": "none", "varsIgnorePattern": "Handler" }]*/

        var contents,
            container,
            headers,
            buttons,
            el,
            parseContents,
            computeSize,
            width,
            currentStep = 0,
            currentContent,
            stepToClickHandler,
            manageHeightAndVisibility,
            updateHeader,
            eventHandler;

        stepToClickHandler = function (btn, stepId) {
          angular.element(btn).click(stepId, scope.stepTo);
        };

        updateHeader = function () {
          angular.forEach(headers, function (header) {
            var headerStep;
            header = angular.element(header);
            headerStep = header.attr('data-step-id');

            if (angular.isUndefined(headerStep)) {
              return;
            }
            headerStep = Number(headerStep);
            if (headerStep <= currentStep) {
              header.addClass('active');
            }
            else {
              header.removeClass('active');
            }
          });
        };

        manageHeightAndVisibility = function (isResize) {
          var lastContent = currentContent;

          angular.forEach(contents, function (content) {
            var targetStep;
            content = angular.element(content);
            targetStep = angular.element(content).attr('data-step-id');
            if (angular.isUndefined(targetStep)) {
              return;
            }
            targetStep = Number(targetStep);

            if (targetStep === currentStep) {
              computeSize(content);
              currentContent = content;

              if (lastContent && !isResize) {
                // The currentContent becomes previous content. Hide it.
                lastContent.css({
                  visibility: 'hidden',
                  height: '0px',
                  opacity: 0
                });
              }
            }
          });
        };

        computeSize = function (content) {
          var contentHeight;
          // Fix the height so it doesn't 'jump' when setting the child content to 'auto'
          container.css({
            height: container[0].clientHeight + 'px'
          });

          // Set the target content to be visible, non-transparent and reset its height
          // Try to calculate the height. Temporarily set it to auto, calculate content and apply.
          content.css('height', 'auto');
          contentHeight = content[0].clientHeight;

          content.css({
            visibility: 'visible',
            opacity: 1,
            height: contentHeight + 'px'
          });
          container.css({
            height: contentHeight + 'px'
          });

          // That will last 0.2s and apply auto height (due to the dynamic content that might be there. You don't
          // want fixed height then
          $timeout(function () {
            content.css('height', 'auto');
            container.css('height', 'auto');
          }, 200);
        };

        parseContents = function (isResize, stepTo) {
          el = element;

          // These are the containers
          contents = angular.element('.step-content', el);
          container = angular.element('.stepped-container', el);
          buttons = angular.element('button[data-step-to], a[data-step-to]', el);
          headers = angular.element('.step[data-step-id]');

          if (contents.length === 0 || container.length === 0) {
            // Not much to do
            return;
          }

          // First, make sure we stretch the container to some width wide enough so the scrollbar doesn't appear as that would
          // wrongly calculate the width with the scrollbar included to our contents.
          container.width('10000px');

          width = el.width();

          // Calculate the width of the individual contents
          contents.css({
            width: width + 'px'
          });

          // Calculate the width of the wrapper
          container.css({
            width: width * contents.length + 'px',
            overflow: 'hidden',
            position: 'relative',
            transform: 'translateX(0px)',
            transition: 'all 0.2s ease-in-out',
            whiteSpace: 'nowrap'
          });

          if (!isResize) {
            // We must set the visibility: hidden so the focus on elements is disabled!
            angular.forEach(contents, function (cnt, idx) {
              if (idx !== 0) {
                angular.element(cnt).css({
                  visibility: 'hidden'
                });
              }
            });

            // Bind the step to to our function
            angular.forEach(buttons, function (btn) {
              var stepId = angular.element(btn).attr('data-step-to');
              if (angular.isUndefined(stepId)) {
                return;
              }
              stepToClickHandler(btn, Number(stepId));
            });

            // Set the first content to
            currentContent = angular.element(contents[0]);
            currentContent.css({
              height: 'auto',
              visibility: 'visible'
            });
            container.css('height', 'auto');
            updateHeader();
          }
          else {
            scope.stepTo({
              data: angular.isDefined(stepTo) ? stepTo : currentStep,
              isResize: true
            });
          }
        };

        scope.stepTo = function (evt) {
          var stepId = evt.data,
              shiftBy = -1 * stepId * width;

          if (stepId === currentStep && !evt.isResize) {
            return;
          }

          container.css('transform', 'translateX(' + shiftBy + 'px)');
          currentStep = stepId;
          manageHeightAndVisibility(evt.isResize);
          updateHeader();
        };

        angular.element($window).on('resize', function () {
          parseContents(true);
        });

        eventHandler = $rootScope.$on('event:slider-do-layout', function (evtName, data) {
          $timeout(function () {
            if (data && data.stepTo >= 0) {
              parseContents(true, data.stepTo);
            }
            else {
              parseContents(true, currentStep ? currentStep : 0);
            }
          }, data && data.delay ? data.delay : 100);
        });
        scope.$on('$destroy', eventHandler);

        $timeout(function () {
          parseContents();
        }, 100);
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name components.ui.directive:appsStoreLinks
   * @restrict EA
   * @element
   *
   */
  appsStoreLinks.$inject = ["$window", "$document", "$rootScope"];
  angular
    .module('components.ui')
    .directive('appsStoreLinks', appsStoreLinks);

  function appsStoreLinks($window, $document, $rootScope) {
    return {
      restrict: 'EA',
      scope: {},
      replace: true,
      templateUrl: 'components/ui/apps-store-links-directive.tpl.html',
      link: function (scope) {
        var watchHandle;
        scope.isAvailable = true;
        scope.isAuthenticated = $rootScope.isAuthenticated;

        // Check if the navigator has the "gonative" userAgent to determine whether it's the "app" or the "web"
        if ($window.navigator.userAgent.indexOf('gonative') > -1) {
          scope.isAvailable = false;
        }
        else if (!scope.isAuthenticated) {
          // Add the css class to adjust the app content a little
          angular.element($document[0].body).addClass('has-appsdownload-links');
        }
        watchHandle = $rootScope.$watch('isAuthenticated', function () {
          scope.isAuthenticated = $rootScope.isAuthenticated;
          if (scope.isAvailable) {
            if (scope.isAuthenticated) {
              angular.element($document[0].body).removeClass('has-appsdownload-links');
            }
            else {
              angular.element($document[0].body).addClass('has-appsdownload-links');
            }
          }
        });
        $rootScope.$on('$destroy', watchHandle);
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name components.ui.directive:actionToolbar
   * @restrict A
   * @element
   *
   * @description
   *
   */
  actionToolbar.$inject = ["$window", "$rootScope", "$document", "$timeout", "Raven"];
  angular
    .module('components.ui')
    .directive('actionToolbar', actionToolbar);

  function actionToolbar($window, $rootScope, $document, $timeout, Raven) {
    var toolbarEl,
        primaryContainer,
        secondaryContainer,
        angularRootScope,
        angularTimeout,
        scrollingElement,
        isPrimaryVisible = false,
        isSecondaryVisible = false,
        checkScrollFn,
        setVisibilityFn,
        checkVisibilityFn,
        checkVisibilityValsFn,
        lastScrollTop = 0,
        stateChanging = false,
        timeoutPromise,
        instances = {
          primary: {},
          secondary: {}
        };

    function getScrollingElement() {
      var d = $document[0];
      return d.documentElement.scrollHeight > d.body.scrollHeight && d.compatMode.indexOf('CSS1') === 0 ? d.documentElement : d.body;
    }

    //
    checkScrollFn = function () {
      var scrollTop,
          diff;

      // Get the document's scrolling element. Sometimes it's HTML and sometime body.
      scrollingElement = scrollingElement || $document[0].scrollingElement || getScrollingElement();

      if (!scrollingElement) {
        // Something is not right.
        Raven.captureMessage('actionToolbar: unable to get the scrolling element.', {
          level: 'warning'
        });
        return;
      }

      scrollTop = scrollingElement.scrollTop;
      diff = Math.abs(lastScrollTop - scrollTop);

      if (scrollTop < 0 || diff < 20) {
        return;
      }

      if (lastScrollTop > scrollTop) {
        setVisibilityFn(true, null, true);
      }
      else {
        setVisibilityFn(false, null, true);
      }

      lastScrollTop = scrollTop;
    };

    //
    checkVisibilityValsFn = function () {
      // If the state is changing then just forget it
      if (stateChanging) {
        return;
      }

      // And finally set the action toolbar's visibility if any of those are visible (primary / secondary)
      if (isPrimaryVisible || isSecondaryVisible) {
        setVisibilityFn(true, null, true);
      }
      else {
        setVisibilityFn(false, null, true);
      }
    };

    //
    setVisibilityFn = function (visible, container, broadcast) {
      var el = container || toolbarEl;
      if (visible) {
        el.addClass('visible');
        if (broadcast) {
          angularTimeout(function () {
            angularRootScope.$broadcast('event:action-toolbar-visible', el[0].clientHeight);
          }, 200);
        }
      }
      else {
        el.removeClass('visible');
        if (broadcast) {
          angularTimeout(function () {
            angularRootScope.$broadcast('event:action-toolbar-hidden');
          }, 200);
        }
      }
    };

    // Check if there is any elements at all.
    checkVisibilityFn = function (isSecondary) {
      var container = isSecondary ? secondaryContainer : primaryContainer,
          transcludedContainer,
          isVisible = false;

      // Check if the container has any nodes.
      // The structure of these has changed since implementing the scroll-check directive. we need to travarse few more nodes.
      transcludedContainer = angular.element('.transcluded-content', container[0]);
      if (container[0] && transcludedContainer[1] && transcludedContainer[1].childElementCount > 0) {
        isVisible = true;
      }

      if (isSecondary && (!isSecondaryVisible && isVisible || isSecondaryVisible && !isVisible)) {
        isSecondaryVisible = isVisible;
        setVisibilityFn(isVisible, container, false);
        checkVisibilityValsFn();
      }
      else if (!isSecondary && (!isPrimaryVisible && isVisible || isPrimaryVisible && !isVisible)) {
        isPrimaryVisible = isVisible;
        setVisibilityFn(isVisible, container, false);
        checkVisibilityValsFn();
      }
    };

    return {
      restrict: 'E',
      scope: true,
      transclude: true,
      replace: true,
      templateUrl: 'components/ui/action-toolbar-directive.tpl.html',
      link: function (scope, element, attrs) {
        var windowScrollHandle,
            stateChangingHandle,
            stateChangedHandle,
            watchHandle,
            transcludedContainer,
            isSecondary = attrs.atTarget && attrs.atTarget === 'secondary',
            id = (new Date()).getTime();

        scope.id = id;

        // Main Element. First instance ever.
        if (!toolbarEl) {
          toolbarEl = angular.element('<div id="action-toolbar" class="action-toolbar-main"></div>');
          $document[0].body.appendChild(toolbarEl[0]);
          // Append to toolbar element
          toolbarEl.append(element);

          // Get the primary and secondary container
          primaryContainer = angular.element('.primary-container', toolbarEl);
          secondaryContainer = angular.element('.secondary-container', toolbarEl);

          // Some references for angular functions
          angularRootScope = $rootScope;
          angularTimeout = $timeout;
        }

        if (!isPrimaryVisible && !isSecondaryVisible) {
          // Subscribe to the scroll event. just once is enough as this widget will never be actually destroyed
          windowScrollHandle = angular.element($window.document).on('scroll', checkScrollFn);

          // Let's try this
          watchHandle = $rootScope.$watch(function () {
            if (timeoutPromise) {
              $window.clearTimeout(timeoutPromise);
            }
            timeoutPromise = $window.setTimeout(function () {
              checkVisibilityFn(true);
              checkVisibilityFn(false);
            }, 150);
          });

          // Subsribe to our state changing events
          stateChangingHandle = $rootScope.$on('event:app-state-changing', function () {
            stateChanging = true;
            setVisibilityFn(false);
          });
          stateChangedHandle = $rootScope.$on('event:app-state-changed', function () {
            stateChanging = false;
            checkVisibilityFn(isSecondary);
          });
        }

        // Get the element references.
        transcludedContainer = angular.element('#at-transcluded', element);

        // Check the attrs for the target and extract and reposition the data and update the instances content
        if (isSecondary) {
          secondaryContainer[0].innerHTML = '';
          secondaryContainer.append(transcludedContainer);
          instances.secondary[id] = transcludedContainer;
        }
        else {
          primaryContainer[0].innerHTML = '';
          primaryContainer.append(transcludedContainer);
          instances.primary[id] = transcludedContainer;
        }
        checkVisibilityFn(isSecondary);

        scope.$on('$destroy', function () {
          // Ok we have to recognize what type of target has just been destroyed. (primary, secondary..?)
          if (isSecondary) {
            instances.secondary[id].remove();
          }
          else {
            instances.primary[id].remove();
          }
          checkVisibilityFn(isSecondary);

          if (!isPrimaryVisible && !isSecondaryVisible) {
            if (angular.isFunction(watchHandle)) {
              watchHandle();
            }
            if (angular.isFunction(windowScrollHandle)) {
              windowScrollHandle();
            }
            if (angular.isFunction(stateChangingHandle)) {
              stateChangingHandle();
            }
            if (angular.isFunction(stateChangedHandle)) {
              stateChangedHandle();
            }
          }
        });
      }
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name account.profile
   * @description
   *
   */
  angular
    .module('account.profile', [
      'ui.router',
      'ngFileUpload',
      'uiCropper',
      'ngjsColorPicker',
      'colorpicker.module'
    ]);
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider", "configOptions"];
  angular
    .module('account.profile')
    .config(config);

  function config($stateProvider, configOptions) {
    $stateProvider
      .state('account.profile', angular.extend({}, configOptions.defaultStateParams, {
        url: '/profile',
        templateUrl: 'account/account.tpl.html',
        controller: 'AccountCtrl',
        controllerAs: 'account',
        onEnter: ['RouterHelper', function (RouterHelper) {
          RouterHelper.activateTab('account', 'profile');
        }]
      }));
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name account.profile.controller:ProfileCtrl
   *
   * @description
   *
   */
  ProfileCtrl.$inject = ["$document", "$rootScope", "$scope", "$timeout", "$state", "$uibModal", "Notification", "configOptions", "Restangular", "Location", "Session", "FormAlerts", "Industries"];
  angular
    .module('account.profile')
    .controller('ProfileCtrl', ProfileCtrl);

  function ProfileCtrl($document, $rootScope, $scope, $timeout, $state, $uibModal, Notification, configOptions, Restangular, Location, Session, FormAlerts, Industries) {
    var vm = this,
        configDefaults;
    vm.alerts = new FormAlerts();
    vm.isBusinessAccount = false;
    vm.isLoading = true;
    vm.isLoadingBackgroundImg = false;
    vm.isSavingBackgound = false;
    vm.photoFile = null;
    vm.backgroundFile = null;
    vm.imageEditMode = false;
    vm.isDragging = false;
    vm.backgroundStyleBkp = null;
    vm.resetRequested = false;

    // options for color picker
    vm.customOptions = {
      size: 28,
      roundCorners: true
    };
    vm.customColors = [null, '#000000', '#ffffff', '#ffc0cb', '#008080', '#ffe4e1', '#ff0000', '#ffd700', '#d3ffce', '#00ffff', '#ff7373', '#40d0e0', '#e6e6fa'];
    vm.customHeadlineColors = ['#3b374b', '#ffffff'];

    configDefaults = {
      selectedColor: '#fffffa',
      headlineColor: '#3b374a',
      keepPattern: true,
      backgroundFile: null
    };

    vm.defaults = _.clone(configDefaults);

    // Background Edits
    vm.edits = {
      patternEdited: false,
      colorEdited: false,
      headlineEdited: false,
      imageEdited: false
    };

    vm.data = {
      address: {},
      addressPostal: {}
    };
    Industries.isReady.then(function () {
      vm.industries = Industries.getConsolidatedList();
    });
    vm.getCountries = Location.getCountries;
    vm.getStates = function () {
      return Location.getStates(vm.data.addressPostal.idCountryPostal);
    };

    Session.getUserProfile()
      .then(function (userProfile) {
        vm.userProfile = userProfile;
        vm.data.firstName = vm.userProfile.firstName;
        vm.data.lastName = vm.userProfile.lastName;
        vm.data.email = vm.userProfile.email;
        vm.data.phoneMobile = vm.userProfile.phoneMobile;
        vm.data.businessName = vm.userProfile.businessName;
        vm.data.description = vm.userProfile.description;
        vm.data.website = vm.userProfile.website;
        vm.data.location = vm.userProfile.location;
        vm.data.address.streetAddress = vm.userProfile.streetAddress;
        vm.data.address.suburb = vm.userProfile.suburb;
        vm.data.address.state = vm.userProfile.state;
        vm.data.address.postCode = vm.userProfile.postCode;
        vm.data.address.country = vm.userProfile.country;
        vm.data.addressPostal = vm.userProfile.addressPostal;
        if (angular.isObject(vm.userProfile.addressPostal) && angular.isDefined(vm.userProfile.addressPostal.idCountryPostal) && !!vm.userProfile.addressPostal.idCountryPostal) {
          vm.data.addressPostal.idCountryPostal = vm.userProfile.addressPostal.idCountryPostal.toString();
        }
        if (angular.isObject(vm.userProfile.addressPostal) && angular.isDefined(vm.userProfile.addressPostal.idStatePostal) && !!vm.userProfile.addressPostal.idStatePostal) {
          vm.data.addressPostal.idStatePostal = vm.userProfile.addressPostal.idStatePostal.toString();
        }

        if (vm.userProfile && vm.userProfile.isBusinessAccountActive) {
          vm.isBusinessAccount = true;
        }

        vm.selectedClassification = {};

        if (angular.isObject(vm.userProfile.industry)) {
          vm.data.idClassification = vm.userProfile.industry.id.toString();
          vm.selectedClassification = {id: vm.userProfile.industry.id, name: vm.userProfile.industry.name};
        }
        if (angular.isObject(vm.userProfile.subIndustry)) {
          vm.data.idClassification = vm.userProfile.subIndustry.id.toString();
          vm.selectedClassification = {id: vm.userProfile.subIndustry.id, name: vm.userProfile.industry.name + ' - ' + vm.userProfile.subIndustry.name};
        }
        else {
          vm.data.idClassification = null;
        }

        // We need to hold the background style object as the backup.
        vm.backgroundStyleBkp = _.clone(vm.getCoverStyle(vm.userProfile));
        vm.clearData();

        vm.isLoading = false;
      });

    vm.save = function () {
      vm.alerts.reset();
      vm.isLoading = true;
      Session.updateUserProfile(vm.data)
        .then(function (profile) {
          vm.userProfile = profile;
          $state.reload();
          Notification({message: 'Your profile has been successfully updated'}, 'confirmation');
        }, function () {
          vm.isLoading = false;
          vm.alerts.add('danger', 'Unable to save');
        });
    };

    vm.hasPhoto = function () {
      return !!vm.userProfile.imageToken;
    };

    vm.removePhoto = function () {
      vm.isLoading = true;
      Session.removePhoto()
        .then(function (profile) {
          vm.userProfile = profile;
          $state.reload();
          Notification({message: 'Your photo has been removed'}, 'confirmation');
        }, function () {
          vm.isLoading = false;
        });
    };

    vm.uploadPhoto = function () {
      var modalInstance;
      if (vm.photoFile !== null) {
        modalInstance = $uibModal.open({
          animation: false,
          appendTo: angular.element($document[0].getElementById('profile-ctrl-container')),
          templateUrl: 'account/profile/photo-upload.tpl.html',
          controller: 'PhotoUploadCtrl',
          controllerAs: 'photoUpload',
          size: 'lg',
          resolve: {
            photoFile: vm.photoFile
          }
        });

        modalInstance.result.then(function (result) {
          if (angular.isDefined(result.userProfile)) {
            Notification({message: 'Your photo has been successfully updated'}, 'confirmation');
            vm.userProfile = result.userProfile;
          }
        });
      }
    };

    vm.placeChangedCallback = function (address) {
      vm.data.address = address;
    };

    vm.onCountryChange = function () {
      vm.data.addressPostal.idStatePostal = '';
    };

    vm.onClassificationSelect = function (classification) {
      if (angular.isObject(classification) && angular.isObject(classification.originalObject)) {
        vm.data.idClassification = classification.originalObject.id;
      }
    };

    vm.getAddress = function () {
      var addressParts = [];
      if (vm.userProfile.suburb) {
        addressParts.push(vm.userProfile.suburb);
      }
      if (vm.userProfile.state) {
        addressParts.push(vm.userProfile.state);
      }
      if (vm.userProfile.country) {
        addressParts.push(vm.userProfile.country);
      }

      vm.addressLink = vm.userProfile.addressPostal && vm.userProfile.addressPostal.addressPostal ? vm.userProfile.addressPostal.addressPostal + ', ' + addressParts.join(', ') : addressParts.join(', ');
      return addressParts.join(', ');
    };

    vm.formatWebsiteUrl = function () {
      var url = '';
      if (angular.isDefined(vm.userProfile) && angular.isString(vm.userProfile.website)) {
        url = vm.userProfile.website;
        if (url !== '') {
          if ((url.slice(0, 4)).toLowerCase() !== 'http') {
            url = 'http://' + vm.userProfile.website;
          }
        }
      }

      return url;
    };

    vm.deactivateAccount = function () {
      // Let's open the modal.
      $uibModal.open({
        animation: false,
        templateUrl: 'account/profile/deactivate-modal.tpl.html',
        controller: 'DeactivateModalCtrl',
        controllerAs: 'ctrl',
        size: 'md',
        resolve: {
          userProfile: vm.userProfile
        }
      });
    };

    /*
    Background Editing
    */

    vm.resetToDefault = function () {
      vm.resetRequested = true;
      vm.changingColor = false;
      vm.bkgData.backgroundFile = null;
      vm.backgroundFile = null;
    };

    vm.uploadBackgroundPhoto = function () {
      if (vm.backgroundFile) {
        vm.imageUploadMode = true;
        vm.edits.imageEdited = true;
        // We need to clear the color
        if (vm.bkgData.selectedColor === vm.defaults.selectedColor) {
          vm.edits.colorEdited = true;
        }
        vm.bkgData.selectedColor = null;
      }
      else {
        vm.imageUploadMode = false;
      }
    };

    vm.removeBkgPhoto = function () {
      if (vm.bkgData.backgroundFile === vm.defaults.backgroundFile) {
        vm.edits.imageEdited = true;
      }

      vm.backgroundFile = null;
      vm.bkgData.backgroundFile = null;
      vm.imageUploadMode = false;
    };

    vm.getProfileStyle = function () {
      var obj = _.clone(vm.backgroundStyleBkp) || {
        'background-color': 'none',
        'background-blend-mode': 'inherit'
      };

      if (vm.resetRequested) {
        return {};
      }

      if (vm.imageEditMode) {
        obj = {
          'background-color': vm.bkgData.selectedColor
        };

        if (vm.bkgData.selectedColor !== vm.defaults.selectedColor) {
          vm.edits.colorEdited = true;
        }
      }

      if (!vm.imageEditMode || !vm.edits.patternEdited && !vm.edits.colorEdited && !vm.edits.imageEdited) {
        return vm.backgroundStyleBkp;
      }

      if (vm.bkgData.keepPattern || vm.edits.colorEdited && vm.bkgData.selectedColor !== vm.defaults.selectedColor) {
        if (vm.bkgData.keepPattern) {
          obj.backgroundImage = 'url("/images/user-profile-pattern.png")';
          obj.backgroundBlendMode = 'luminosity';
          obj.backgroundPosition = 'center center';
        }
        else {
          obj.backgroundImage = 'none';
        }
      }
      else {
        obj.backgroundImage = 'none';
      }

      if (vm.bkgData.backgroundFile) {
        obj.backgroundImage = 'url("' + vm.bkgData.backgroundFile + '")';
        obj.backgroundBlendMode = 'luminosity';
        obj.backgroundPosition = 'center center';
      }

      return obj;
    };

    vm.getHeadlineStyle = function () {
      var obj = {
        color: vm.bkgData.headlineColor
      };

      if (vm.resetRequested) {
        return {};
      }

      if (vm.bkgData.headlineColor !== vm.defaults.headlineColor) {
        vm.edits.headlineEdited = true;
        return obj;
      }
      return obj;
    };

    vm.clearColourSettings = function () {
      // vm.bkgData.selectedColor = vm.defaults.selectedColor;
      // vm.bkgData.headlineColor = vm.defaults.headlineColor;
      if (vm.bkgData.selectedColor === vm.defaults.selectedColor) {
        vm.edits.colorEdited = true;
      }

      if (vm.bkgData.headlineColor === vm.defaults.headlineColor) {
        vm.edits.headlineColorEdited = true;
      }

      vm.bkgData.selectedColor = null;
      vm.bkgData.headlineColor = null;
    };

    vm.clearData = function () {
      vm.bkgData = {
        selectedColor: vm.defaults.selectedColor,
        keepPattern: vm.defaults.keepPattern,
        headlineColor: vm.defaults.headlineColor,
        backgroundFile: vm.defaults.backgroundFile
      };

      vm.edits = {
        patternEdited: false,
        colorEdited: false,
        headlineColorEdited: false,
        imageEdited: false
      };

      vm.backgroundFile = null;
      vm.imageUploadMode = false;
      vm.changingColor = false;
      vm.resetRequested = false;
    };

    vm.close = function () {
      vm.clearData();
      vm.imageEditMode = false;
      vm.imageUploadMode = false;
      vm.backgroundFile = null;
    };

    vm.hasEdits = function () {
      var hasEdits = false;
      angular.forEach(Object.keys(vm.edits), function (key) {
        if (!hasEdits) {
          hasEdits = vm.edits[key];
        }
      });
      return hasEdits;
    };

    /*
    This will revert any changes and remove any records for the user in the backend so it will display the default image only
     */
    vm.resetBackgroundData = function () {
      vm.isSavingBackgound = true;
      Restangular.one('UserConfiguration').customDELETE('cover').then(function () {
        Session.updateLocalUserProfile({
          profileConfiguration: {
            coverBackground: null
          }
        }, true);
        Notification({message: 'Success'}, 'success');
        vm.userProfile.profileConfiguration.coverBackground = null;
        // Store the backgup
        vm.backgroundStyleBkp = _.clone(vm.getCoverStyle(vm.userProfile));
        // Close and clear data.
        vm.close();
      }, function () {
        Notification({message: 'Failed to reset the profile'}, 'error');
      }).finally(function () {
        vm.isSavingBackgound = false;
      });
    };

    vm.saveBackgroundEdits = function () {
      var data;
      vm.isSavingBackgound = true;

      // Get the data
      data = {
        config: {
          keepPattern: vm.bkgData.keepPattern,
          backgroundColor: vm.bkgData.selectedColor !== configDefaults.selectedColor ? vm.bkgData.selectedColor : null,
          textColor: vm.bkgData.headlineColor !== configDefaults.headlineColor ? vm.bkgData.headlineColor : null
        }
      };

      if (vm.bkgData.backgroundFile && vm.edits.imageEdited) {
        data.image = vm.bkgData.backgroundFile;
      }
      else if (!vm.bkgData.backgroundFile && !vm.bkgData.keepPattern) {
        data.image = null;
      }
      else if (!vm.bkgData.backgroundFile && vm.edits.imageEdited) {
        data.image = null;
      }

      Restangular.one('UserConfiguration').customPUT(data, 'cover')
        .then(function (result) {
          // Update the global profile object
          Session.updateLocalUserProfile({
            profileConfiguration: {
              coverBackground: {
                imageToken: result.plain().imageToken,
                config: result.plain().config
              }
            }
          }, true);
          Notification({message: 'Success'}, 'success');
          vm.userProfile.profileConfiguration.coverBackground.imageToken = result.plain().imageToken;
          vm.userProfile.profileConfiguration.coverBackground.config = result.plain().config;
          // Store the backgup
          vm.backgroundStyleBkp = _.clone(vm.getCoverStyle(vm.userProfile));
          // Close and clear data.
          vm.close();
        }, function () {
          Notification({message: 'Failed to update the profile'}, 'error');
        }).finally(function () {
          vm.isSavingBackgound = false;
        });
    };

    vm.getCoverStyle = function (profile) {
      var coverCss = {};
      // Reset vm defaults
      vm.defaults = {
        selectedColor: null,
        headlineColor: null,
        keepPattern: null,
        backgroundFile: null
      };
      // Set the user profile style from style config
      if (profile.profileConfiguration && profile.profileConfiguration.coverBackground) {
        // Start with the pattern as that has lower priority than the background file.
        if (profile.profileConfiguration.coverBackground.config && profile.profileConfiguration.coverBackground.config.keepPattern) {
          coverCss.backgroundImage = 'url("/images/user-profile-pattern.png")';
          vm.defaults.keepPattern = true;
        }
        else {
          coverCss.backgroundImage = 'none';
          vm.defaults.keepPattern = false;
        }

        // Check if the user opted for his own background image or wants to use provided pattern or plain color. Color can be combined with both
        // so it's in separate 'if' statement.
        if (profile.profileConfiguration.coverBackground.imageToken) {
          coverCss.backgroundImage = 'url("' + configOptions.baseUrl + '/UserConfiguration/coverPicture/' + profile.profileConfiguration.coverBackground.imageToken + '")';
          coverCss.backgroundPosition = 'center top';
          coverCss.backgroundSize = '1140px 215px';
          vm.defaults.backgroundFile = configOptions.baseUrl + '/UserConfiguration/coverPicture/' + profile.profileConfiguration.coverBackground.imageToken;
        }

        if (profile.profileConfiguration.coverBackground.config) {
          if (profile.profileConfiguration.coverBackground.config.backgroundColor) {
            coverCss.backgroundColor = profile.profileConfiguration.coverBackground.config.backgroundColor;
            coverCss.backgroundBlendMode = 'luminosity';
            vm.defaults.selectedColor = profile.profileConfiguration.coverBackground.config.backgroundColor;
          }
          if (profile.profileConfiguration.coverBackground.config.textColor) {
            vm.defaults.headlineColor = profile.profileConfiguration.coverBackground.config.textColor;
          }
        }
      }
      else {
        vm.defaults = _.clone(configDefaults);
      }
      return coverCss;
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name account.profile.controller:PhotoUploadCtrl
   *
   * @description
   *
   */
  PhotoUploadCtrl.$inject = ["$uibModalInstance", "Session", "photoFile"];
  angular
    .module('account.profile')
    .controller('PhotoUploadCtrl', PhotoUploadCtrl);

  function PhotoUploadCtrl($uibModalInstance, Session, photoFile) {
    var vm = this;
    vm.photoFile = photoFile || '';
    vm.croppedDataUrl = '';
    vm.isLoading = false;

    vm.ok = function () {
      vm.isLoading = true;
      Session.updatePhoto(vm.croppedDataUrl)
        .then(function (userProfile) {
          $uibModalInstance.close({userProfile: userProfile});
        }, function () {
          vm.isLoading = true;
        });
    };

    vm.cancel = function () {
      $uibModalInstance.dismiss();
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name account.preferences
   * @description
   *
   */
  angular
    .module('account.preferences', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name account.profile.controller:DeactivateModalCtrl
   *
   * @description
   *
   */
  DeactivateModalCtrl.$inject = ["$rootScope", "$state", "$timeout", "$uibModalInstance", "Restangular", "Notification", "Auth", "userProfile", "Session"];
  angular
    .module('account.preferences')
    .controller('DeactivateModalCtrl', DeactivateModalCtrl);

  function DeactivateModalCtrl($rootScope, $state, $timeout, $uibModalInstance, Restangular, Notification, Auth, userProfile, Session) {
    var vm = this;
    vm.isDeactivating = false;
    vm.isBusinessAccount = false;
    vm.timeOut = Auth.localSignoutTimeout + 10;
    vm.user = angular.copy(userProfile);

    if (vm.user && vm.user.isBusinessAccountActive) {
      vm.isBusinessAccount = true;
    }

    vm.data = {
      wholeAccount: !vm.isBusinessAccount,
      message: ''
    };

    vm.deactivate = function () {
      vm.isDeactivating = true;

      if (vm.data.wholeAccount) {
        Restangular.one('Users').customPUT({message: vm.data.feedback}, 'deactivate').then(function () {
          Notification({message: 'Deactivation was successful'}, 'confirmation');
          signOutAndRedirect('accountSignInUp.accountSignIn');
        }).catch(function () {
          Notification({message: 'Unable to deactivate your account, please try again or contact us.'}, 'error');
          $uibModalInstance.close();
        });
      }
      else {
        // Use the business deactivation api
        Restangular.one('BusinessAccount').customPUT({message: vm.data.feedback}, 'deactivate').then(function (result) {
          var profile = result.profile;
          profile.isBusinessAccountActive = result.isBusinessAccountActive;
          Session.create(profile);
          $rootScope.$broadcast('event:user-updateProfile', {profile: profile});
          Notification({message: 'Deactivation was successful'}, 'confirmation');
          $state.go('dashboard');
          $uibModalInstance.close();
        }).catch(function () {
          Notification({message: 'Unable to deactivate your business account account, please try again or contact us.'}, 'error');
          $uibModalInstance.close();
        });
      }
    };

    function signOutAndRedirect(redirectTo) {
      Auth.signOut(false);

      // We have to do this in the timeout as the AUTH service uses timeout as well due to some rendering issues.
      $timeout(function () {
        $state.go(redirectTo);
        $uibModalInstance.close();
      }, vm.timeOut);
    }

    vm.close = function () {
      $uibModalInstance.close();
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name account.preferences.controller:PreferencesCtrl
   *
   * @description
   *
   */
  PreferencesCtrl.$inject = ["$uibModalInstance", "Session", "Notification", "Restangular", "FormAlerts", "userProfile"];
  angular
    .module('account.preferences')
    .controller('PreferencesCtrl', PreferencesCtrl);

  function PreferencesCtrl($uibModalInstance, Session, Notification, Restangular, FormAlerts, userProfile) {
    var vm = this;
    vm.alerts = new FormAlerts();
    vm.isLoading = true;
    vm.isSaving = false;
    vm.userProfile = userProfile;
    vm.data = {
      mailingLists: {},
      marketplace: {}
    };

    vm.updatePreferences = function () {
      if (!vm.userProfile) {
        return;
      }
      if (vm.userProfile.preferences && vm.userProfile.preferences.mailingLists) {
        vm.data.mailingLists = vm.userProfile.preferences.mailingLists;
      }

      if (vm.userProfile.preferences && vm.userProfile.preferences.marketplace) {
        vm.data.marketplace = vm.userProfile.preferences.marketplace;
      }
      vm.dataBkp = angular.copy(vm.data);
    };

    vm.changeSettings = function (key, value) {
      var lastProperty = null;
      key = key.split('.');
      if (key.length === 1) {
        vm.data[key[0]] = value;
      }
      else {
        lastProperty = vm.data;
        angular.forEach(key, function (keyName, index, array) {
          if (index === array.length - 1) {
            // This is the last key in the list.
            lastProperty[keyName] = value;
          }
          else if (_.has(lastProperty, keyName)) {
            // Key already exists in the data
            lastProperty = lastProperty[keyName];
          }
          else {
            // There is no such key, create one.
            lastProperty = lastProperty[keyName] = {};
          }
        });
      }
      vm.hasChanges = !_.isEqual(vm.data, vm.dataBkp);
    };

    vm.cancel = function () {
      $uibModalInstance.dismiss();
    };

    vm.saveChanges = function () {
      vm.isSaving = true;
      vm.alerts.reset();
      Restangular.one('Preferences').customPUT(vm.data, 'index')
        .then(function (preferences) {
          if (preferences) {
            vm.userProfile.preferences = preferences.plain();
            Session.updateLocalUserProfile(vm.userProfile, false);
          }
          vm.isSaving = false;
          Notification({message: 'Success'}, 'confirmation');
          $uibModalInstance.close();
        }, function () {
          vm.isSaving = false;
          vm.alerts.add('danger', 'Unable to save the changes.');
        });
    };

    // init
    if (!vm.userProfile) {
      Session.getUserProfile().then(function (up) {
        vm.userProfile = up;
        vm.updatePreferences(vm.userProfile);
      });
    }
    else {
      vm.updatePreferences(vm.userProfile);
    }
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name account.preferences.controller:PreferencesCtrl
   *
   * @description
   *
   */
  MailingListUnsubscribeCtrl.$inject = ["$scope", "$document", "AccountService", "Restangular", "$stateParams"];
  angular
    .module('account.preferences')
    .controller('MailingListUnsubscribeCtrl', MailingListUnsubscribeCtrl);

  function MailingListUnsubscribeCtrl($scope, $document, AccountService, Restangular, $stateParams) {
    var vm = this;
    vm.isSaving = false;
    vm.isError = false;
    vm.isContactOpen = false;
    vm.data = {
      userId: $stateParams.userId,
      subscriptionId: $stateParams.subscriptionId
    };

    vm.saveChanges = function () {
      vm.isSaving = true;

      Restangular.one('Preferences').one('mailingListSubscription').remove(vm.data)
        .then(function () {
          vm.isSaving = false;
        }, function () {
          vm.isSaving = false;
          vm.isError = true;
        });
    };

    vm.openFeedback = function () {
      AccountService.openFeedback(null, true);
    };

    // init
    vm.saveChanges();
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name account.password
   * @description
   *
   */
  angular
    .module('account.password', [
      'vcRecaptcha',
      'validation.match'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name account.password.controller:ResetCtrl
   *
   * @description
   *
   */
  ResetCtrl.$inject = ["$stateParams", "Restangular", "FormAlerts"];
  angular
    .module('account.password')
    .controller('ResetCtrl', ResetCtrl);

  function ResetCtrl($stateParams, Restangular, FormAlerts) {
    var vm = this;
    vm.data = {};
    vm.alerts = new FormAlerts();
    vm.showMessage = false;
    vm.isLoading = false;

    vm.reset = function () {
      vm.isLoading = true;
      vm.alerts.reset();
      Restangular.one('Users').customPUT({
        token: $stateParams.token,
        password: vm.data.password
      }, 'lostPassword')
        .then(function () {
          vm.showMessage = true;
        }, function () {
          vm.isLoading = false;
          vm.alerts.add('danger', 'Unable to reset');
        });
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name account.password.controller:RecoverCtrl
   *
   * @description
   *
   */
  RecoverCtrl.$inject = ["Restangular", "vcRecaptchaService", "FormAlerts", "configOptions"];
  angular
    .module('account.password')
    .controller('RecoverCtrl', RecoverCtrl);

  function RecoverCtrl(Restangular, vcRecaptchaService, FormAlerts, configOptions) {
    var vm = this;
    vm.data = {};
    vm.alerts = new FormAlerts();
    vm.reCaptchaSiteKey = configOptions.reCaptchaSiteKey;
    vm.showMessage = false;
    vm.isLoading = false;

    vm.recover = function () {
      var reCaptchaResponse;
      vm.alerts.reset();
      try {
        reCaptchaResponse = vcRecaptchaService.getResponse();
      }
      catch (err) {
        vcRecaptchaService.reload();
        vm.alerts.add('danger', 'Unable to verify that you are not a robot. Please try again');
        return;
      }

      vm.isLoading = true;
      Restangular.one('Users').post('lostPassword', {
        email: vm.data.email,
        reCaptchaResponse: reCaptchaResponse
      })
        .then(function () {
          vm.showMessage = true;
        }, function () {
          vm.isLoading = false;
          vcRecaptchaService.reload();
          vm.alerts.add('danger', 'Unable to recover');
        });
    };
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('account.password')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('accountPassword', {
        abstract: true,
        url: '/account/password',
        template: '<ui-view/>',
        data: {
          classNames: 'no-help'
        }
      })
      .state('accountPassword.recover', {
        url: '/recover',
        templateUrl: 'account/password/recover.tpl.html',
        controller: 'RecoverCtrl',
        controllerAs: 'recoverCtrl'
      })
      .state('accountPassword.reset', {
        url: '/reset/{token}',
        templateUrl: 'account/password/reset.tpl.html',
        controller: 'ResetCtrl',
        controllerAs: 'resetCtrl'
      });
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name account.feedback
   * @description
   *
   */
  angular
    .module('account.feedback', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name account.feedback.controller:FeedbackCtrl
   *
   * @description
   *
   */
  FeedbackCtrl.$inject = ["$window", "$uibModalInstance", "Notification", "Restangular", "Session", "FormAlerts", "userProfile", "helpRequired", "configOptions"];
  angular
    .module('account.feedback')
    .controller('FeedbackCtrl', FeedbackCtrl);

  function FeedbackCtrl($window, $uibModalInstance, Notification, Restangular, Session, FormAlerts, userProfile, helpRequired, configOptions) {
    var vm = this;
    vm.alerts = new FormAlerts();
    vm.isSending = false;
    vm.userProfile = userProfile;
    vm.isHelpRequired = helpRequired;
    vm.data = {
      appVersion: configOptions.appVersion,
      isMobileApp: $window.navigator.userAgent.indexOf('gonative') > -1 ? true : false,
      navigatorInfo: $window.navigator.userAgent,
      url: $window.document.location.href,
      feedback: null,
      name: null,
      email: null
    };

    vm.updateUserData = function () {
      // vm.userProfile should be available here at the time this is triggered.
      vm.data.name = vm.userProfile.fullName;
      vm.data.email = vm.userProfile.email;
    };

    vm.cancel = function () {
      $uibModalInstance.dismiss();
    };

    vm.send = function () {
      vm.isSending = true;
      vm.alerts.reset();
      Restangular.one('Feedback').post('index', vm.data)
        .then(function () {
          vm.isSaving = false;
          Notification({message: 'Success'}, 'confirmation');
          $uibModalInstance.close();
        }, function () {
          vm.isSaving = false;
          vm.alerts.add('danger', 'Unable to save the changes.');
        });
    };

    // User Profile can be provided as the uibModal (or route) resolve. If it's missing, we can always try to get the user from the session.
    if (angular.isUndefined(vm.userProfile) || vm.userProfile === null) {
      Session.getUserProfile().then(function (up) {
        vm.userProfile = up;
        if (vm.userProfile) {
          vm.updateUserData();
        }
      });
    }
    else {
      vm.updateUserData();
    }

    // Connect to the event of once the modal instance has rendered and apply focus to the textarea
    $uibModalInstance.rendered.then(function () {
      angular.element('#details')[0].focus();
    });
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name account.earnings
   * @description
   *
   */
  angular
    .module('account.earnings', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name account.earnings.controller:WithdrawCtrl
   *
   * @description
   *
   */
  WithdrawCtrl.$inject = ["$state", "Restangular", "Notification", "FormAlerts", "earnings", "userProfile", "Charities"];
  angular
    .module('account.earnings')
    .controller('WithdrawCtrl', WithdrawCtrl);

  function WithdrawCtrl($state, Restangular, Notification, FormAlerts, earnings, userProfile, Charities) {
    var vm = this;
    vm.isLoading = false;
    vm.showTotal = false;
    vm.calculating = false;
    vm.calculationRequired = false;
    vm.minLimit = earnings.minimumWithdrawLimit;
    vm.balance = earnings.actualEarnings;
    vm.processingFee = 0;
    vm.remainingBalance = 0;
    vm.receivedAmount = 0;
    vm.alerts = new FormAlerts();
    vm.data = {
      amount: null,
      accountHolder: userProfile.bankAccountName,
      accountBsb: userProfile.bankCodeDomestic,
      accountNumber: userProfile.bankAccountNumberDomestic
    };

    vm.calculate = function () {
      vm.showTotal = true;
    };

    vm.cancel = function () {
      $state.go('account.earnings');
    };

    vm.onAmountChange = function () {
      vm.showTotal = false;
      vm.calculationRequired = true;
    };

    vm.calculate = function () {
      if (vm.data.amount > 0 && vm.data.amount >= earnings.minimumWithdrawLimit && vm.data.amount <= earnings.actualEarnings) {
        vm.calculating = true;
        Restangular.one('Withdrawals').one('calculateWithdrawalFees').get({
          amount: vm.data.amount
        })
        .then(function (result) {
          vm.processingFee = result.fees;
          vm.receivedAmount = result.amountLessFees;
          vm.remainingBalance = result.remainingBalance;
          vm.calculating = false;
          vm.calculationRequired = false;
          vm.showTotal = true;
        });
      }
    };

    vm.withdraw = function () {
      vm.alerts.reset();
      vm.isLoading = true;
      Restangular.one('Withdrawals').post('index', vm.data)
        .then(function () {
          userProfile.bankAccountName = vm.data.accountHolder;
          userProfile.bankCodeDomestic = vm.data.accountBsb;
          userProfile.bankAccountNumberDomestic = vm.data.accountNumber;
          $state.go('account.earnings');
          Notification({message: 'Success'}, 'confirmation');
        }, function () {
          vm.isLoading = false;
          vm.alerts.add('danger', 'Unable to withdraw');
        });
    };

    vm.donate = function () {
      Charities.openDonate();
    };
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider", "configOptions"];
  angular
    .module('account.earnings')
    .config(config);

  function config($stateProvider, configOptions) {
    $stateProvider
      .state('account.earnings', angular.extend({}, configOptions.defaultStateParams, {
        url: '/earnings',
        templateUrl: 'account/account.tpl.html',
        controller: 'AccountCtrl',
        controllerAs: 'account',
        onEnter: ['RouterHelper', function (RouterHelper) {
          RouterHelper.activateTab('account', 'earnings');
        }]
      }))
      .state('accountEarningsWithdraw', angular.extend({}, configOptions.defaultStateParams, {
        url: '/account/earnings/withdraw',
        templateUrl: 'account/earnings/withdraw.tpl.html',
        controller: 'WithdrawCtrl',
        controllerAs: 'withdraw',
        resolve: {
          earnings: ['Restangular', function (Restangular) {
            return Restangular.one('Earnings').one('summaries').get()
              .then(function (result) {
                return result.plain();
              });
          }],
          userProfile: ['Session', function (Session) {
            return Session.getUserProfile();
          }]
        }
      }));
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name account.earnings.controller:EarningsCtrl
   *
   * @description
   *
   */
  EarningsCtrl.$inject = ["$state", "Restangular", "DataTable", "Leads"];
  angular
    .module('account.earnings')
    .controller('EarningsCtrl', EarningsCtrl);

  function EarningsCtrl($state, Restangular, DataTable, Leads) {
    var vm = this;
    vm.summaries = {};
    vm.ranges = {
      all: 'Show all',
      lastMonth: 'Last month',
      lastYear: 'Last year'
    };
    vm.selectedRange = 'all';
    vm.selectedRangeLabel = 'Show all';
    vm.list = DataTable.create('Earnings', 'index');
    vm.withdrawalsList = DataTable.create('Withdrawals', 'index');
    vm.isLoadingSummaries = true;

    vm.tabManager = null;
    vm.tabsConfig = [
      {
        label: 'Earnings',
        value: 'earnings'
      },
      {
        label: 'Withdrawals',
        value: 'withdrawals'
      }
    ];

    Restangular.one('Earnings').one('summaries').get()
      .then(function (result) {
        vm.summaries = result.plain();
        vm.isLoadingSummaries = false;
      }, function () {
        vm.isLoadingSummaries = false;
      });

    vm.selectRange = function (range) {
      vm.selectedRange = range;
      vm.selectedRangeLabel = vm.ranges[range];
      vm.list.reload({
        dateRange: range
      });
    };

    vm.viewLastMonthEarnings = function () {
      vm.tabManager.openTab('earnings');
      vm.selectRange('lastMonth');
    };

    vm.viewAllEarnings = function () {
      vm.tabManager.openTab('earnings');
      vm.selectRange('all');
    };

    vm.withdraw = function () {
      $state.go('accountEarningsWithdraw');
    };

    vm.openLead = function ($index) {
      var earning = vm.list.getRecords()[$index];
      if (!isNaN(earning.idLead) && parseInt(earning.idLead, 10) > 0) {
        Leads.openDetailById(earning.idLead, 'sent');
      }
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name account.billing
   * @description
   *
   */
  angular
    .module('account.billing', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name account.billing.controller:CreditCardCtrl
   *
   * @description
   *
   */
  CreditCardCtrl.$inject = ["Restangular", "$uibModalInstance", "CreditCardUtil", "Session", "operation"];
  angular
    .module('account.billing')
    .controller('CreditCardCtrl', CreditCardCtrl);

  function CreditCardCtrl(Restangular, $uibModalInstance, CreditCardUtil, Session, operation) {
    var
      vm = this;
    vm.isLoading = false;
    vm.months = CreditCardUtil.getExpiryMonths();
    vm.years = CreditCardUtil.getExpiryYears();
    vm.cards = CreditCardUtil.getAcceptedCards();
    vm.data = {};

    vm.isAdd = function () {
      return operation === 'add';
    };

    vm.ok = function () {
      vm.isLoading = true;
      Restangular.one('Billing').customPUT(vm.data, 'creditCard')
        .then(function (result) {
          Session.updateLocalUserProfile({isCCSet: true});
          $uibModalInstance.close({creditCard: result.plain()});
        }, function () {
          vm.isLoading = false;
        });
    };

    vm.cancel = function () {
      $uibModalInstance.dismiss();
    };
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider", "configOptions"];
  angular
    .module('account.billing')
    .config(config);

  function config($stateProvider, configOptions) {
    $stateProvider
      .state('account.billing', angular.extend({}, configOptions.defaultStateParams, {
        url: '/billing',
        templateUrl: 'account/account.tpl.html',
        controller: 'AccountCtrl',
        controllerAs: 'account',
        onEnter: ['RouterHelper', function (RouterHelper) {
          RouterHelper.activateTab('account', 'billing');
        }]
      }));
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name account.billing.controller:BillingCtrl
   *
   * @description
   *
   */
  BillingCtrl.$inject = ["$document", "Restangular", "$uibModal", "Notification", "Session", "DataTable"];
  angular
    .module('account.billing')
    .controller('BillingCtrl', BillingCtrl);

  function BillingCtrl($document, Restangular, $uibModal, Notification, Session, DataTable) {
    var vm = this;
    vm.initialisingCard = true;
    vm.cardLoading = false;
    vm.invoicesExist = true;
    vm.creditCard = null;
    vm.invoices = DataTable.create('Invoices', 'index', {}, {
      itemCallback: function (item) {
        item.downloadUrl = '/Invoices/download?id=' + item.idInvoice;
        return item;
      }
    });

    Restangular.one('Billing').one('creditCard').get()
      .then(function (creditCard) {
        if (angular.isObject(creditCard) && angular.isDefined(creditCard.number)) {
          vm.creditCard = creditCard.plain();
        }
        vm.initialisingCard = false;
      }, function () {
        vm.initialisingCard = false;
      });

    vm.manageCreditCard = function (operation) {
      var modalInstance = $uibModal.open({
        animation: false,
        appendTo: angular.element($document[0].getElementById('billing-ctrl-container')),
        templateUrl: 'account/billing/credit-card.tpl.html',
        controller: 'CreditCardCtrl',
        controllerAs: 'creditCard',
        size: 'lg',
        windowClass: 'modal-credit-card',
        resolve: {
          operation: function () {
            return operation;
          }
        }
      });

      modalInstance.result.then(function (result) {
        if (angular.isObject(result) && angular.isObject(result.creditCard) && angular.isDefined(result.creditCard.number)) {
          vm.creditCard = result.creditCard;
          Session.updateLocalUserProfile({isCCSet: true});
          Notification({message: 'Your credit card details have been successfully updated'}, 'confirmation');
        }
      });
    };

    vm.removeCreditCard = function () {
      vm.cardLoading = true;
      Restangular.one('Billing').one('creditCard').remove()
        .then(function () {
          vm.creditCard = null;
          vm.cardLoading = false;
          Session.updateLocalUserProfile({isCCSet: false});
          Notification({message: 'Your credit card details have been removed'}, 'confirmation');
        }, function () {
          vm.cardLoading = false;
        });
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name user
   * @description
   *
   */
  angular
    .module('user', [
      'ui.router',
      'user.leads',
      'user.agreements',
      'user.connections',
      'user.marketplace',
      'user.messages'
    ]);
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider", "configOptions"];
  angular
    .module('user')
    .config(config);

  function config($stateProvider, configOptions) {
    $stateProvider
      .state('user', {
        url: '/user/{id}',
        templateUrl: 'user/user.tpl.html',
        controller: 'UserCtrl',
        controllerAs: 'user',
        authenticate: true,
        params: {
          isInvite: false
        },
        resolve: {
          userProfile: ['Restangular', '$stateParams', 'Auth', function (Restangular, $stateParams, Auth) {
            return Auth.isAuthenticated().then(function (isAuthenticated) {
              if (isAuthenticated) {
                return Restangular.one('Users').one('profile').get({id: $stateParams.id})
                  .then(function (profile) {
                    return profile.plain();
                  });
              }
              return null;
            }, function () {
              return null;
            });
          }],
          agreementsDetails: ['Restangular', '$stateParams', 'Auth', function (Restangular, $stateParams, Auth) {
            return Auth.isAuthenticated().then(function (isAuthenticated) {
              if (isAuthenticated) {
                return Restangular.one('Contracts').one('index').get({idUser: $stateParams.id})
                  .then(function (agreements) {
                    return agreements.plain();
                  });
              }
              return null;
            }, function () {
              return null;
            });
          }],
          myProfile: ['Session', function (Session) {
            return Session.getUserProfile();
          }]
        },
        onEnter: ['$state', '$stateParams', 'userProfile', 'myProfile', function ($state, $stateParams, userProfile, myProfile) {
          if (userProfile && myProfile && userProfile.id === myProfile.id) {
            $state.go('dashboard');
          }
          else if ($stateParams.isInvite) {
            if (userProfile.marketplace && angular.isArray(userProfile.marketplace) && userProfile.marketplace.length > 0) {
              $state.go('user.marketplace', {id: userProfile.id});
            }
            else {
              $state.go('user.agreements', {id: userProfile.id});
            }
          }
        }]
      })
      .state('user.about', angular.extend({}, configOptions.defaultStateParams, {
        url: '/about',
        templateUrl: 'user/user.tpl.html',
        controller: 'UserCtrl',
        controllerAs: 'user',
        onEnter: ['$state', 'RouterHelper', 'userProfile', function ($state, RouterHelper, userProfile) {
          if (!userProfile.description) {
            $state.go('user.agreements', {id: userProfile.id});
          }
          else {
            RouterHelper.activateTab('user', 'about');
          }
        }]
      }))
      .state('userJoin', {
        url: '/user/{id}/join',
        authenticate: true,
        onEnter: ['$state', '$stateParams', 'MyPod', 'Notification', function ($state, $stateParams, MyPod, Notification) {
          MyPod.quickJoin($stateParams.id).then(function () {
            Notification({message: 'Success'}, 'confirmation');
            $state.go('user.agreements', {id: $stateParams.id});
          }, function (err) {
            Notification({message: 'The registration was unsuccessful.'}, 'error');
            $state.go('dashboard', {id: $stateParams.id});
            throw new Error(err);
          });
        }]
      })
      .state('userInvitation', angular.extend({}, configOptions.defaultStateParams, {
        url: '/invitation/{token}',
        resolve: {
          invitation: ['Restangular', '$state', '$stateParams', 'Auth', '$q', function (Restangular, $state, $stateParams, Auth, $q) {
            var deferred = $q.defer();
            Restangular.one('Invitations').one('detail').get({token: $stateParams.token})
              .then(function (invitation) {
                Auth.isAuthenticated()
                  .then(function (isAuthenticated) {
                    if (isAuthenticated) {
                      deferred.resolve(invitation.plain());
                    }
                    else if (invitation.isRegistered) {
                      $state.go('accountSignInUp.accountSignIn', {invitationToken: $stateParams.token});
                    }
                    else {
                      $state.go('accountSignInUp.accountSignUp', {invitationToken: $stateParams.token});
                    }
                  }, function () {
                    if (invitation.isRegistered) {
                      $state.go('accountSignInUp.accountSignIn', {invitationToken: $stateParams.token});
                    }
                    else {
                      $state.go('accountSignInUp.accountSignUp', {invitationToken: $stateParams.token});
                    }
                  });
              }, function () {
                Auth.signOut();
                $state.go('accountSignInUp.accountSignIn', {invitationToken: $stateParams.token});
              });

            return deferred.promise;
          }]
        },
        onEnter: ['$state', 'invitation', function ($state, invitation) {
          if (angular.isObject(invitation) && angular.isDefined(invitation.idUserSender)) {
            $state.go('user.agreements', {id: invitation.idUserSender});
          }
          else {
            $state.go('dashboard');
          }
        }]
      }));
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name user.controller:UserCtrl
   *
   * @description
   *
   */
  UserCtrl.$inject = ["$window", "$rootScope", "$scope", "$stateParams", "configOptions", "Restangular", "userProfile", "agreementsDetails", "myProfile", "DataTable", "Leads", "MyPod", "Charities"];
  angular
    .module('user')
    .controller('UserCtrl', UserCtrl);

  function UserCtrl($window, $rootScope, $scope, $stateParams, configOptions, Restangular, userProfile, agreementsDetails, myProfile, DataTable, Leads, MyPod, Charities) {
    var vm = this,
        tabs = ['sentLeads', 'receivedLeads', 'agreements', 'connections', 'marketplace', 'messages'];

    vm.isMobile = $window.innerWidth < 768;
    vm.detailsVisible = false;
    vm.userProfile = userProfile;
    vm.myProfile = myProfile;
    vm.agreementsDetails = agreementsDetails;
    vm.eventHandler = null;

    // Set the tabs. The About tab's visibility is dynamic and depends on its content
    if (vm.userProfile.description) {
      tabs.unshift('about');
    }

    vm.isBusinessAccountActive = function () {
      return angular.isDefined(myProfile.isBusinessAccountActive) && myProfile.isBusinessAccountActive === true;
    };

    // Check if this user is the business entity (is registered as the business in MLP)
    // FYI: connectionsList == directoryList, we are keeping the same name in the code (connections) as it was originally planned, but all the UI will display "Directory" instead
    if (vm.userProfile.isBusinessAccountActive === true) {
      vm.connectionsList = DataTable.create('Members', 'index', {}, {
        basicParams: {
          idUser: vm.userProfile.id
        }
      });
    }

    vm.tabManager = null;
    vm.tabsConfig = [
      {
        label: 'About',
        value: 'about',
        url: 'user.about',
        condition: function () {
          return vm.userProfile.description;
        }
      },
      {
        label: 'Sent Leads',
        value: 'sentLeads',
        url: 'user.sentLeads',
        condition: function () {
          return vm.userProfile.isBusinessAccountActive;
        }
      },
      {
        label: 'Received Leads',
        value: 'receivedLeads',
        url: 'user.receivedLeads',
        condition: function () {
          return vm.isBusinessAccountActive();
        }
      },
      {
        label: 'Agreements',
        value: 'agreements',
        url: 'user.agreements',
        condition: function () {
          return true;
        }
      },
      {
        label: 'Messages',
        value: 'messages',
        url: 'user.messages',
        condition: function () {
          return vm.userProfile.acceptedReceiveLeadsContractExists || vm.userProfile.acceptedSendLeadsContractExists;
        }
      },
      {
        label: vm.userProfile.firstName + '\'s Directory',
        value: 'connections',
        url: 'user.connections',
        condition: function () {
          return vm.userProfile.isBusinessAccountActive;
        },
        template: '<span>(<span ng-show="!tab.data.connectionsList.isInitialised() && tab.data.connectionsList.isLoading()"><i class="fa fa-1x fa-circle-o-notch fa-spin-2x"></i></span>{{tab.data.connectionsList.totalRecords()}})</span>',
        data: {
          userProfile: vm.userProfile,
          connectionsList: vm.connectionsList
        }
      },
      {
        label: 'Marketplace',
        value: 'marketplace',
        url: 'user.marketplace',
        condition: function () {
          return vm.userProfile.marketplace && vm.userProfile.marketplace.length;
        },
        template: '<span>({{tab.data.marketplace.length}})</span>',
        data: {
          userProfile: vm.userProfile,
          marketplace: vm.userProfile.marketplace
        }
      }
    ];

    vm.toggleDetails = function () {
      vm.detailsVisible = !vm.detailsVisible;
    };

    vm.moreDetailsExist = function () {
      var nonEmptyDetails = 0;
      if (vm.getAddress()) {
        nonEmptyDetails++;
      }
      if (vm.userProfile.phoneMobile) {
        nonEmptyDetails++;
      }
      if (vm.userProfile.email) {
        nonEmptyDetails++;
      }
      if (vm.userProfile.website) {
        nonEmptyDetails++;
      }

      return nonEmptyDetails > 1;
    };

    vm.getAddress = function () {
      var addressParts = [];
      if (vm.userProfile.suburb) {
        addressParts.push(vm.userProfile.suburb);
      }
      if (vm.userProfile.state) {
        addressParts.push(vm.userProfile.state);
      }
      if (vm.userProfile.country) {
        addressParts.push(vm.userProfile.country);
      }

      vm.addressLink = vm.userProfile.addressPostal && vm.userProfile.addressPostal.addressPostal ? vm.userProfile.addressPostal.addressPostal + ', ' + addressParts.join(', ') : addressParts.join(', ');
      return addressParts.join(', ');
    };

    vm.formatWebsiteUrl = function () {
      var url = '';
      if (angular.isDefined(vm.userProfile) && angular.isString(vm.userProfile.website)) {
        url = vm.userProfile.website;
        if (url !== '') {
          if ((url.slice(0, 4)).toLowerCase() !== 'http') {
            url = 'http://' + vm.userProfile.website;
          }
        }
      }

      return url;
    };

    vm.sendLead = function () {
      Leads.openSendLead(vm.userProfile, false);
    };

    vm.registerLead = function () {
      Leads.openSendLead(vm.userProfile, true);
    };

    vm.sendInvite = function () {
      var uibModal = MyPod.openSendInvite(vm.myProfile, vm.userProfile);
      uibModal.then(function (sendLeads) {
        vm.agreementsDetails.sendLeads = sendLeads;
      });
    };

    vm.donate = function () {
      if (vm.userProfile.isCharityAccountActive) {
        Charities.openDonate(vm.userProfile);
      }
    };

    vm.reloadProfile = function () {
      Restangular.one('Users').one('profile').get({id: $stateParams.id})
        .then(function (profile) {
          vm.userProfile = profile.plain();
        });
    };

    vm.getCoverStyle = function () {
      var coverCss = {};
      // Set the user profile style from style config
      if (vm.userProfile.profileConfiguration && vm.userProfile.profileConfiguration.coverBackground) {
        // Check if the user opted for his own background image or wants to use provided pattern or plain color. Color can be combined with both
        // so it's in separate 'if' statement.
        if (vm.userProfile.profileConfiguration.coverBackground.imageToken) {
          coverCss.backgroundImage = 'url("' + configOptions.baseUrl + '/UserConfiguration/coverPicture/' + vm.userProfile.profileConfiguration.coverBackground.imageToken + '")';
          coverCss.backgroundPosition = 'center top';
          coverCss.backgroundSize = '1140px 215px';
        }
        else if (vm.userProfile.profileConfiguration.coverBackground.config && vm.userProfile.profileConfiguration.coverBackground.config.keepPattern) {
          coverCss.backgroundImage = 'url("/images/user-profile-pattern.png")';
        }
        else {
          coverCss.backgroundImage = 'none';
        }

        if (vm.userProfile.profileConfiguration.coverBackground.config) {
          if (vm.userProfile.profileConfiguration.coverBackground.config.backgroundColor) {
            coverCss.backgroundColor = vm.userProfile.profileConfiguration.coverBackground.config.backgroundColor;
            coverCss.backgroundBlendMode = 'luminosity';
          }
        }
        return coverCss;
      }
    };

    vm.getHeadlineStyle = function () {
      var textCss = {};
      // Set the user profile style from style config
      if (vm.userProfile.profileConfiguration && vm.userProfile.profileConfiguration.coverBackground && vm.userProfile.profileConfiguration.coverBackground.config) {
        textCss.color = vm.userProfile.profileConfiguration.coverBackground.config.textColor || 'inherit';
      }
      return textCss;
    };

    vm.isCustomBackground = function () {
      return vm.userProfile.profileConfiguration && vm.userProfile.profileConfiguration.coverBackground && vm.userProfile.profileConfiguration.coverBackground.imageToken ||
        vm.userProfile.profileConfiguration && vm.userProfile.profileConfiguration.coverBackground && vm.userProfile.profileConfiguration.coverBackground.config && !vm.userProfile.profileConfiguration.coverBackground.config.keepPattern;
    };

    vm.eventHandler = $rootScope.$on('event:user-agreements-updated', function () {
      vm.reloadProfile();
    });
    vm.stateHandler = $rootScope.$on('close-modal-instance', function () {
      vm.detailsVisible = false;
    });
    $rootScope.$on('$destroy', vm.eventHandler);
    $rootScope.$on('$destroy', vm.stateHandler);

    angular.element($window).on('resize', function () {
      vm.isMobile = $window.innerWidth < 768;
      $scope.$apply();
    });
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name user.directive:userAvatar
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="user">
       <file name="index.html">
        <user-avatar></user-avatar>
       </file>
     </example>
   *
   */
  angular
    .module('user')
    .directive('userAvatar', userAvatar);

  function userAvatar() {
    return {
      restrict: 'EA',
      scope: {
        user: '=user',
        size: '@',
        isLink: '@',
        fixedSize: '@'
      },
      templateUrl: 'user/user-avatar-directive.tpl.html',
      replace: true,
      controllerAs: 'userAvatar',
      controller: ['$scope', 'configOptions', function ($scope, configOptions) {
        var vm = this;
        if ($scope.isLink !== 'true') {
          vm.isLink = false;
        }
        else {
          vm.isLink = true;
        }
        if ($scope.size !== 'small' && $scope.size !== 'large') {
          $scope.size = 'small';
        }

        vm.isSmall = function () {
          return $scope.size === 'small';
        };

        if ($scope.user) {
          vm.idUser = $scope.user.id;
          vm.initials = $scope.user.firstName.trim().charAt(0);
        }
        else {
          return;
        }

        vm.isImage = function () {
          return !!$scope.user.imageToken;
        };

        vm.isFixedSize = function () {
          return $scope.fixedSize === 'true';
        };

        vm.getStyle = function () {
          var size = vm.isSmall() ? 44 : 120,
              avatarUrl;
          if ($scope.user.imageToken) {
            avatarUrl = configOptions.baseUrl + '/Users/photo/' + $scope.user.imageToken + '?w=' + size + '&h=' + size;
            return {
              'background-image': 'url(' + avatarUrl + ')'
            };
          }

          return {
            'background-color': $scope.user.color
          };
        };
      }]
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name user.controller:AboutCtrl
   *
   * @description
   *
   */
  AboutCtrl.$inject = ["$scope"];
  angular
    .module('user')
    .controller('AboutCtrl', AboutCtrl);

  function AboutCtrl($scope) {
    var vm = this;
    vm.userProfile = $scope.user.userProfile;
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name mlp-notifications
   * @description
   *
   */
  angular
    .module('mlp-notifications', []);
}());

// jscs:disable requireCamelCaseOrUpperCaseIdentifiers
/* eslint-disable camelcase */
(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name mlpNotifications.provider:OneSignal
   *
   * @description
   *
   */
  angular
    .module('mlp-notifications')
    .provider('OneSignal', OneSignalProvider);

  function OneSignalProvider() {
    var appId,
        safariWebId,
        isLoaded = false,
        OneSignal,
        userIdPromise,
        userId,
        isApp;

    return {
      init: function (applicationId, webId) {
        appId = applicationId;
        safariWebId = webId;
      },
      $get: ['$window', '$document', '$http', '$q', 'Restangular', 'Auth', function ($window, $document, $http, $q, Restangular, Auth) {
        var oneSignalApi = $q.defer(),
            scriptElement;

        function init() {
          OneSignal = $window.OneSignal;
          if (!OneSignal) {
            OneSignal = $window.OneSignal = [];
          }

          // Initialise
          OneSignal.push(['init', {
            appId: appId,
            safari_web_id: safariWebId,
            autoRegister: true,
            notificationClickHandlerMatch: 'origin',
            notifyButton: {
              enable: false
            }
          }]);

          // Occurs when the user's subscription changes to a new value.
          OneSignal.push(function () {
            OneSignal.on('subscriptionChange', function (isSubscribed) {
              if (isSubscribed) {
                OneSignal.getUserId().then(function (playerId) {
                  userId = playerId;
                  if (userIdPromise) {
                    userIdPromise.resolve(userId);
                  }
                  Auth.isAuthenticated().then(function (isAuthenticated) {
                    if (isAuthenticated) {
                      Restangular.one('Users').customPOST(null, 'pushNotifications', {
                        oneSignalPlayerId: playerId
                      });
                    }
                  });
                });
              }
              else {
                userId = null;
              }
            });
          });

          // Get UserId
          OneSignal.push(function () {
            OneSignal.getUserId().then(function (osUserId) {
              userId = osUserId;
              if (userIdPromise) {
                userIdPromise.resolve(userId);
              }
            });
          });

          // Resolve the api (everything is ready and initialized)
          oneSignalApi.resolve();
        }

        isApp = $window.navigator.userAgent.indexOf('gonative') > -1;
        userIdPromise = $q.defer();

        if (isApp) {
          $window.gonative_onesignal_info = function (info) {
            userIdPromise.resolve(info.oneSignalUserId);
            userId = info.oneSignalUserId;
          };
          oneSignalApi.resolve();
          isLoaded = true;
        }
        else if (!isLoaded) {
          scriptElement = $document[0].createElement('script');
          scriptElement.setAttribute('async', 'async');
          scriptElement.onload = init;
          $document[0].body.appendChild(scriptElement);
          scriptElement.src = 'https://cdn.onesignal.com/sdks/OneSignalSDK.js';
          isLoaded = true;
        }
        else {
          oneSignalApi.resolve();
        }

        oneSignalApi.isLoaded = function () {
          return isLoaded;
        };

        oneSignalApi.getUserId = function () {
          return userIdPromise.promise;
        };

        return oneSignalApi;
      }]
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name mlpNotifications.directive:notificationSwipeItem
   * @restrict E
   * @element
   */
  notificationSwipeItem.$inject = ["$swipe"];
  angular
    .module('mlp-notifications')
    .directive('notificationSwipeItem', notificationSwipeItem);

  function notificationSwipeItem($swipe) {
    return {
      restrict: 'AE',
      scope: {
        onSwipeDone: '&',
        onSwiping: '&',
        onSwipeCancel: '&',
        onClick: '&'
      },
      link: function (scope, el, attrs) {
        var id = Number(attrs.notificationId),
            startX = 0,
            dx = 0,
            isMoving;

        function reset() {
          // Reset;
          startX = 0;
          dx = 0;
          isMoving = false;
        }

        function onStart(coords, event) {
          startX = coords.x;

          el.css({
            transition: 'none'
          });
          console.log('onStart:', id, isMoving, event.type);
          if (isMoving) {
            onCancel(event);
          }
          else {
            isMoving = true;
          }
        }

        function onMove(coords) {
          dx = coords.x - startX;
          isMoving = true;
          // alter the position now.
          el.css({
            transform: 'translateX(' + dx + 'px)'
          });
          scope.onSwiping({deltaX: dx});
        }

        function onEnd(coords, event) {
          var absDx = Math.abs(coords.x - startX),
              preventMouseUp = false;

          if (!isMoving) {
            event.stopImmediatePropagation();
            event.stopPropagation();
            event.preventDefault();
            return;
          }

          if (absDx >= 0 && absDx < 5) {
            // This is considered as accidental swipe. don't prevent the mouseup. Return the element slowly back
            el.css({
              transform: 'translateX(0px)',
              transition: 'transform 0.2s ease-in-out'
            });
            el.slideUp(200, function () {
              scope.onClick({id: id});
            });
          }
          else if (absDx > 0 && absDx < 50) {
            // This is considered as unfinished swipe. prevent the mouseup but don't clear the notification
            preventMouseUp = true;
            el.css({
              transform: 'translateX(0px)',
              transition: 'transform 0.2s ease-in-out'
            });
            scope.onSwipeCancel({id: id});
          }
          else {
            // This is finished swipe and the notification should be cleared. Prevent mouseup
            preventMouseUp = true;
            el.css({
              transform: 'translateX(' + (coords.x - startX < 0 ? '-1000' : '1000') + 'px)',
              transition: 'transform 0.3s ease-in-out'
            });
            el.slideUp(200);
            scope.onSwipeDone({id: id});
          }
          if (preventMouseUp) {
            event.stopImmediatePropagation();
            event.stopPropagation();
            event.preventDefault();
          }

          reset();
        }

        function onCancel(event) {
          // This is considered as unfinished swipe. prevent the mouseup but don't clear the notification
          event.stopImmediatePropagation();
          event.stopPropagation();
          event.preventDefault();

          el.css({
            transform: 'translateX(0px)',
            transition: 'transform 0.2s ease-in-out'
          });
          scope.onSwipeCancel({id: id});
          reset();
        }

        $swipe.bind(el, {
          start: onStart,
          move: onMove,
          end: onEnd,
          cancel: onCancel
        }, ['mouse', 'touch']);
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name mlpNotifications.constant:notificationConst
   *
   * @description
   *
   */
  angular
    .module('mlp-notifications')
    .constant('notificationConst', {
      categoryMessages: 'messages',
      categoryLeads: 'leads',
      typeDirectMessage: 'direct message',
      typeLeadMessage: 'lead message',
      typeNewLead: 'new lead'
    });
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name mlpNotifications.directive:notificationCenter
   * @restrict E
   * @element
   */
  notificationCenter.$inject = ["$rootScope", "$timeout", "$sce", "$state", "Cache", "Restangular", "notificationConst", "Leads"];
  angular
    .module('mlp-notifications')
    .directive('notificationCenter', notificationCenter);

  function notificationCenter($rootScope, $timeout, $sce, $state, Cache, Restangular, notificationConst, Leads) {
    return {
      restrict: 'E',
      scope: {
        user: '=',
        isLink: '=?',
        descriptionKey: '=?',
        stopPropagation: '=?',
        hasDetails: '=?',
        nameOnly: '=?',
        addClass: '='
      },
      replace: true,
      transclude: false,
      templateUrl: 'notifications/notification-center-directive.tpl.html',
      controllerAs: 'nc',
      controller: ['$scope', function ($scope) {
        var watchHandeNewNotification,
            watchHandleClearNotification,
            scope = this,
            cache = Cache.create('notification-centre-help');

        scope.notificationsBkp = [];
        scope.notifications = [];
        scope.selectedCategories = ['all'];
        scope.filterActive = false;
        scope.displayHelp = false;

        scope.filterCategories = [
          {name: 'All', value: 'all'},
          {name: 'Leads', value: 'leads', iconClass: 'fa-paper-plane'},
          {name: 'Messages', value: 'messages', iconClass: 'fa-envelope'}
        ];

        // Check if the help was dismissed
        if (!cache.get('helpDismissed')) {
          scope.displayHelp = true;
        }

        // to be replaced with data from api
        scope.notificationsData = [];

        function generateNotifications(data) {
          var foundCategories = [],
              now = (new Date()).getTime(),
              hasMore = false;

          // Alter data
          _.each(data, function (item) {
            // First. Insert the icon-class of the category.
            if (item && item.category && _.find(scope.filterCategories, {value: item.category})) {
              // Icon class
              item.iconClass = _.find(scope.filterCategories, {value: item.category}).iconClass;
            }
            else {
              item.iconClass = 'fa-stop';
            }

            // Check if the category is lead. Then change the value to lead url
            if (item && item.category && item.category === 'leads') {
              // item.value = $sce.trustAsHtml('<a>View Lead</a>');
              item.description = 'sent you new lead';
            }

            if (item && item.category && item.category === 'messages') {
              hasMore = item && item.count && item.count > 1;
              item.description = hasMore ? 'sent you new ' : 'sent you a new ';
              if (item.data && item.data.leadId) {
                item.description += 'lead ';
              }
              item.description += hasMore ? ' messages [' + item.count + ']' : 'message';
            }

            // Format time
            item.timeFormatted = scope.getFormattedTime(now - (new Date(item.time)).getTime());

            // Add the category from the item
            foundCategories.push(item.category);
          });

          // Clear all categories first
          _.each(scope.filterCategories, function (category) {
            category.hasResults = false;
          });

          // Now check the categories
          if (scope.filterCategories && scope.filterCategories.length > 0) {
            // Make the values unique
            foundCategories = _.uniq(foundCategories);
            // Now loop through each category and apply the badge
            angular.forEach(foundCategories, function (category) {
              _.find(scope.filterCategories, {value: category}).hasResults = true;
            });
          }

          scope.notificationsBkp = _.clone(data);
          filterNotifications();
          scope.notify();

          // Sometimes we have problem with refreshing the DOM.
          $timeout(function () {
            $scope.$apply();
          }, 100);
        }

        function filterNotifications(category) {
          var fresults = [];

          if (!category && scope.selectedCategories.lastIndexOf('all') > -1 || category === 'all') {
            scope.notifications = _.clone(scope.notificationsBkp);
            return;
          }

          if (!category) {
            return;
          }

          angular.forEach(scope.notificationsBkp, function (n) {
            if (_.contains(scope.selectedCategories, n.category)) {
              fresults.push(n);
            }
          });
          scope.notifications = fresults;
        }

        /**
         * Mark all notifications as read using API
         */
        scope.markRead = function () {
          scope.notifications = [];
          scope.notificationsBkp = [];
          scope.selectedCategories = ['all'];
          scope.filterActive = false;
          scope.notify();
        };

        scope.notify = function () {
          $rootScope.$broadcast('event:notifications-status', {
            hasNotifications: scope.notifications.length > 0,
            count: scope.notifications.length > 10 ? '+' : scope.notifications.length
          });
        };

        /**
         * Clear all notifications
         */
        scope.clearNotifications = function () {
          return;
        };

        scope.openNotification = function (item) {
          // Based on the category do what is required
          if (!item) {
            return;
          }

          // This is the new lead notification. Only received leads will be visible in NC
          if (item.category && item.category === notificationConst.categoryLeads) {
            Leads.openDetail(item.data.leadId, 'received');
          }

          // This is the message within the lead as it contains the lead id in data property
          if (item.category && item.category === notificationConst.categoryMessages && item.data && item.data.leadId) {
            Leads.openDetail(item.data.leadId, 'received', null, 'messages');
          }

          // This is the direct message. Open the user profile at the messages tab
          if (item.category && item.category === notificationConst.categoryMessages && item.userId && !item.data) {
            $state.go('user.messages', {id: item.userId}, {reload: true});
          }

          scope.clearNotification(item);
          generateNotifications(scope.notificationsData);
        };

        scope.openNotificationById = function (id) {
          var item = _.find(scope.notifications, {id: id});
          if (item) {
            scope.openNotification(item);
          }
        };

        scope.clearNotification = function (item) {
          var category,
              userId,
              type,
              data,
              filtered;

          // Clear the items from the same user within the same category ...
          if (!item || !item.category || !item.userId || !item.type) {
            return;
          }
          category = item.category;
          userId = item.userId;
          type = item.type;
          data = item.data;

          if (data) {
            filtered = _.where(scope.notificationsData, {
              category: category,
              userId: userId,
              type: type,
              data: data
            });
          }
          else {
            filtered = _.where(scope.notificationsData, {
              category: category,
              userId: userId,
              type: type
            });
          }

          // Use API to remove the notification

          scope.notificationsData = _.difference(scope.notificationsData, filtered);
        };

        scope.clearNotificationById = function (id) {
          var item = _.find(scope.notifications, {id: id});
          if (item) {
            $timeout(function () {
              scope.clearNotification(item);
              generateNotifications(scope.notificationsData);
            }, 150);
          }
        };

        scope.isSelected = function (category) {
          return _.contains(scope.selectedCategories, category);
        };

        scope.changeCategory = function (category, evt, isSingleFilter) {
          if (!category) {
            return;
          }
          scope.notifications = [];

          if (category === 'all') {
            scope.selectedCategories = ['all'];
            scope.filterActive = false;
          }
          else if (isSingleFilter && category) {
            scope.selectedCategories = [category];
            scope.filterActive = true;
          }
          filterNotifications(category);
        };

        scope.getFormattedTime = function (timegap) {
          var interval = Math.abs(timegap) / 1000,
              granularity = 1,
              zeroInterval = 'now',
              suffix = '',
              short = false,
              units = null,
              output = '';

          units = [
            {seconds: 31536000, singular: 'year', plural: 'years', short: 'y'},
            {seconds: 2592000, singular: 'month', plural: 'months', short: 'm'},
            {seconds: 604800, singular: 'week', plural: 'weeks', short: 'w'},
            {seconds: 86400, singular: 'day', plural: 'days', short: 'd'},
            {seconds: 3600, singular: 'hour', plural: 'hours', short: 'h'},
            {seconds: 60, singular: 'min', plural: 'min', short: 'm'}
          ];

          angular.forEach(units, function (unit) {
            var count = null;
            if (granularity === 0) {
              return;
            }
            if (interval >= unit.seconds) {
              count = Math.floor(interval / unit.seconds);
              output += (output ? ' ' : '') + count;

              if (short) {
                output += unit.short;
              }
              else {
                output += ' ' + (count > 1 ? unit.plural : unit.singular);
              }

              interval %= unit.seconds;
              granularity--;
            }
          });

          return output ? output + (suffix === '' ? '' : ' ' + suffix) : zeroInterval;
        };

        scope.onSwiping = function (deltaX) {
          scope.isSwipeRight = deltaX > 1;
          $scope.$apply();
        };

        scope.dismissHelp = function () {
          cache.put('helpDismissed', 1);
          scope.displayHelp = false;
        };

        // Subscribe to the event of the new notification event that this directive will be notified about.
        watchHandeNewNotification = $rootScope.$on('event:notifications-raised', function (event, data) {
          if (data.notifications) {
            scope.notifications = data.notifications;
          }
        });

        // Subscribe to the events raised by different routes that will effectively get this directive to check and clear
        // any notifications that might be related to those routes (/messages, /lead/{}/messages etc)
        watchHandleClearNotification = $rootScope.$on('event:notifications-check', function (event, data) {
          scope.clearNotification(data);
          generateNotifications(scope.notificationsData);
        });

        $rootScope.$on('$destroy', function () {
          watchHandeNewNotification();
          watchHandleClearNotification();
        });

        // INIT
        generateNotifications(scope.notificationsData);
      }]
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name urlShorteners
   * @description
   *
   */
  angular
    .module('urlShorteners', [
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name urlShorteners.provider:BitlyUrlShortener
   *
   * @description
   *
   */
  angular
    .module('urlShorteners')
    .provider('BitlyUrlShortener', BitlyUrlShortener);

  function BitlyUrlShortener() {
    var config = {
      accessToken: null
    };

    return {
      setAccessToken: function (accessToken) {
        config.accessToken = accessToken;
      },
      $get: ['$http', '$httpParamSerializer', 'configOptions', '$q', function ($http, $httpParamSerializer, configOptions, $q) {
        var cache = {};
        return {
          shorten: function (longUrl) {
            var deferred = $q.defer();
            /* eslint camelcase: [2, {properties: "never"}]*/
            if (angular.isDefined(cache[longUrl])) {
              deferred.resolve(cache[longUrl]);
            }
            else {
              $http({
                method: 'POST',
                headers: {
                  "Authorization": "Bearer " + configOptions.bitlyGenericAccessToken,
                  "Content-Type": "application/json"
                },
                url: 'https://api-ssl.bitly.com/v4/shorten',
                data:{
                  long_url: longUrl
                }
              })
                .then(function (result) {
                  cache[longUrl] = result.data.link;
                  deferred.resolve(result.data.link);
                });
            }

            return deferred.promise;
          }
        };
      }]
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name toolbar
   * @description
   *
   */
  angular
    .module('toolbar', [
      'ui.router',
      'mlp-notifications'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name toolbar.controller:ToolbarCtrl
   *
   * @description
   *
   */
  ToolbarCtrl.$inject = ["$rootScope", "$scope", "$window", "$state", "$document", "$timeout", "Marketplace", "AccountService", "BusinessAccount", "Auth", "Session", "authEvents"];
  angular
    .module('toolbar')
    .controller('ToolbarCtrl', ToolbarCtrl);

  function ToolbarCtrl($rootScope, $scope, $window, $state, $document, $timeout, Marketplace, AccountService, BusinessAccount, Auth, Session, authEvents) {
    var vm = this,
        loginSuccessHandler,
        updateProfileHandler,
        notificationHandler,
        watchHandle;

    // Default state for the navbar-collapse
    vm.isCollapsed = true;
    vm.hasNotifications = false;
    vm.notificationsCount = ' ';

    notificationHandler = $rootScope.$on('event:notifications-status', function (event, data) {
      vm.hasNotifications = data.hasNotifications;
      if (vm.hasNotifications) {
        vm.notificationsCount = data.count;
      }
      else {
        vm.notificationsCount = ' ';
        if (vm.isMobile) {
          vm.isNCopen = false;
        }
      }
    });

    function updateUserData() {
      Session.getUserProfile()
        .then(function (profile) {
          if (profile) {
            vm.userProfile = profile;
            vm.trialData = BusinessAccount.getTrialData(vm.userProfile);
          }
        });
    }

    function checkForMobile() {
      // This is a simple workaround to find out if we are in the the mobile devices (tablet/phone) according the bootstrap's breakpoints without having to hardcode the breakpoint values,
      // simply use the toggle button (the one with the .navbar-toggle) object and check if it's visible.
      vm.isMobile = angular.element('.navbar-toggle:visible').length === 1;
      vm.isDesktop = !vm.isMobile;
    }

    updateUserData();

    vm.isLogin = function () {
      return $state.is('accountSignInUp.accountSignIn');
    };

    vm.isSignUp = function () {
      return $state.is('accountSignInUp.accountSignUp');
    };

    vm.showLoginLink = function () {
      return $state.is('accountSignInUp.accountSignUp') || $state.includes('accountPassword');
    };

    vm.isAuthenticated = function () {
      return $rootScope.isAuthenticated;
    };

    vm.toggleMenu = function () {
      vm.isCollapsed = !vm.isCollapsed;
    };

    vm.closeMenu = function () {
      vm.isCollapsed = true;
    };

    vm.signOut = function () {
      vm.closeMenu();
      Auth.signOut();
      $state.params = angular.extend($state.params, {logout: true});
      $state.go('accountSignInUp.accountSignIn');
    };

    vm.isBusinessAccountActive = function () {
      return angular.isObject(vm.userProfile) && angular.isDefined(vm.userProfile.isBusinessAccountActive) && vm.userProfile.isBusinessAccountActive === true;
    };

    vm.getUserProfile = function () {
      return vm.userProfile;
    };

    vm.openFeedback = function (helpNeeded) {
      AccountService.openFeedback(vm.userProfile, helpNeeded);
      vm.closeMenu();
    };

    vm.openPreferences = function () {
      AccountService.openPreferences(vm.userProfile);
      vm.closeMenu();
    };

    vm.openMarketplace = function () {
      Marketplace.open();
      vm.closeMenu();
    };

    vm.toggleNotificationCenter = function (isOpen) {
      vm.isNCopen = isOpen;
    };

    vm.toggleProfile = function (isOpen) {
      vm.isProfileOpen = isOpen;
    };

    /* eslint no-unused-vars: [2, {"args": "none", "varsIgnorePattern": "Handler" }]*/
    loginSuccessHandler = $rootScope.$on(authEvents.loginSuccess, function () {
      checkForMobile();
      updateUserData();
    });
    updateProfileHandler = $rootScope.$on('event:user-updateProfile', function (event, data) {
      vm.userProfile = data.profile;
    });

    // We have to run the mobile-check after user logs is, as if he is a visitor, we are hiding the toggle button
    watchHandle = $rootScope.$watch('isAuthenticated', function () {
      if ($rootScope.isAuthenticated) {
        vm.isDesktop = false;
        vm.isMobile = false;
        $timeout(function () {
          checkForMobile();
        }, 50);
      }
      else {
        vm.userProfile = undefined;
      }
    });
    $rootScope.$on('$destroy', function () {
      watchHandle();
      notificationHandler();
    });

    // Subscribe to the element resize
    angular.element($window).on('resize', function () {
      checkForMobile();
      vm.closeMenu();
      $scope.$apply();
    });

    // Do the check for the initial state.
    $document.ready(checkForMobile);
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name dashboard.directive:businessSuggestion
   * @restrict EA
   * @element
   *
   * @description
   */
  angular
    .module('toolbar')
    .directive('businessSuggestion', businessSuggestion);

  function businessSuggestion() {
    return {
      restrict: 'EA',
      scope: {
        user: '=user'
      },
      replace: true,
      templateUrl: 'toolbar/business-suggestion-directive.tpl.html',
      controllerAs: 'suggestion',
      controller: ['$rootScope', '$scope', 'configOptions', 'BusinessAccount', '$document', 'Notification', 'Session', function ($rootScope, $scope, configOptions, BusinessAccount, $document, Notification, Session) {
        var vm = this,
            count = 0,
            trialCount = 0,
            body = angular.element($document[0].body),
            userProfileDeferred = Session.getUserProfile(),
            logoutSuccessHandler;

        // Default state of the visibility of this directive's content
        vm.isClosed = true;

        logoutSuccessHandler = $rootScope.$on('event:auth-logoutSuccess', function () {
          body.removeClass('has-suggestion');
        });

        // Remove the handle once it's all destroyed
        $rootScope.$on('$destroy', logoutSuccessHandler);

        // Get the user data from session.
        function updateUserData() {
          userProfileDeferred.then(function (profile) {
            if (profile) {
              vm.userProfile = profile;
              vm.trialData = BusinessAccount.getTrialData(vm.userProfile);
              // Trigger the check count as there might be some trial data available
              checkCount();
              // If there is a warning, we will show the directive no matter what.
              if (vm.trialData.isInTrial && vm.trialData.trialWarning) {
                vm.setIsClosed(false);
              }
            }
          });
        }

        /**
        * @ngdoc function
        * @name closeSuggestion
        * @methodOf registerSuggestion.controller:suggestion
        * @description Closes the suggestion. At the same time it increases the count in the local storage or sets the new timestamp (if user never signs out)
        */
        vm.closeSuggestion = function () {
          vm.setIsClosed(true);
          BusinessAccount.updateSuggestionCount(count);
          if (vm.trialData && vm.trialData.isEligibleForTrial) {
            BusinessAccount.updateTrialSuggestionCount(trialCount);
          }
        };

        /**
        * @ngdoc function
        * @name checkCount
        * @methodOf registerSuggestion.controller:suggestion
        * @description Checks the number of how many times have we bothered the user with the suggestions already. If we have bothered him => than the number in the settings, then keep it closed. If it's less, open the suggestion.
        * @private
        */
        function checkCount() {
          var closeIt = false;

          // Get the cached count value for business suggestion and trialSuggestions.
          // Trial suggestions are overriding the business ones.
          count = BusinessAccount.getSuggestionsCount();
          trialCount = BusinessAccount.getTrialSuggestionsCount();
          closeIt = count < configOptions.maxBusinessSuggestions ? false : true;
          if (vm.trialData && vm.trialData.isEligibleForTrial) {
            closeIt = trialCount < configOptions.maxBusinessSuggestions ? false : true;
          }
          vm.setIsClosed(closeIt);
        }

        /**
        * Redirects to Business registration
        */
        vm.registerBusiness = function () {
          // Open the popup dialog
          vm.isActivating = true;
          BusinessAccount.initiateActivation(body[0], vm.trialData.isEligibleForTrial)
            .then(function () {
              Notification({message: 'Your business account has been successfully activated.'}, 'confirmation');
              vm.isActivating = false;
              vm.setIsClosed(true);
            }, function () {
              vm.isActivating = false;
              vm.setIsClosed(true);
            });
        };

        /**
        * Sets the isClosed model variable to the incoming parameter
        * @param {Boolean} isClosed Whether the suggestion is to be closed now.
        */
        vm.setIsClosed = function (isClosed) {
          if (vm.isClosed !== isClosed) {
            // Do the CSS change on the body so the content knows it needs to shift a little
            if (isClosed) {
              body.removeClass('has-suggestion');
            }
            else {
              body.addClass('has-suggestion');
            }
          }
          vm.isClosed = isClosed;
        };

        //
        updateUserData();

        // Check the count from cache. This will also reset the visibility status from visible if this has been shown before.
        checkCount();
      }],
      link: function (scope, element, attrs, ctrl) {
        ctrl.element = element;
      }
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name socialShare
   *
   * @description
   *
   */
  run.$inject = ["SocialShare"];
  angular
    .module('socialShare', [
      'socialShare.providers',
      'urlShorteners'
    ]);

  angular
    .module('socialShare')
    .config(config)
    .run(run);

  function config() {
  }

  function run(SocialShare) {
    SocialShare.init();
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name socialShare.provider:SocialShare
   *
   * @description SocialShare provider, generic functionality to share content via social service
   *
   */
  angular
    .module('socialShare')
    .provider('SocialShare', SocialShare);

  function SocialShare() {
    var socialServices = {
          facebook: 'FacebookSocialShare',
          linkedin: 'LinkedinSocialShare',
          twitter: 'TwitterSocialShare',
          google: 'GooglePlusSocialShare'
        },
        config = {};

    /**
     * Get instance name for social service
     * @param {string} name Social service identifier
     * @returns {string} Instance name
     */
    function getInstanceNameForSocialService(name) {
      if (angular.isDefined(socialServices[name])) {
        return socialServices[name];
      }

      throw new Error('Unknown provider');
    }

    return {
      /**
       * Set configuration
       * @param {string} socialService Social service identifier
       * @param {string} paramName Name of configuration option
       * @param {*} value Value of configuration option
       */
      set: function (socialService, paramName, value) {
        if (angular.isUndefined(config[socialService])) {
          config[socialService] = {};
        }

        config[socialService][paramName] = value;
      },
      $get: ['$q', '$rootScope', '$injector',
        function ($q, $rootScope, $injector) {
          /* jshint unused:false */
          /* eslint no-unused-vars: [2, {"args": "none", "varsIgnorePattern": "Handler" }]*/
          var $socialShare = $q.defer(),
              socialInitHandler;

          /**
           * Initialisation - iterates over all supported providers and initialises them
           */
          $socialShare.init = function () {
            var promises = [];
            angular.forEach(socialServices, function (instanceName, name) {
              var instance = $injector.get(instanceName);
              promises.push(instanceName.promise);
              instance.init(config[name] || {});
            });

            $q.all(promises).then(function () {
              $rootScope.$broadcast('socialShare-init');
            });
          };

          socialInitHandler = $rootScope.$on('socialShare-init', function () {
            $socialShare.resolve();
          });

          /**
           * Share a content
           * @param {string} socialService Social service identifier
           * @param {object} data Data to share
           */
          $socialShare.share = function (socialService, data) {
            $injector.get(getInstanceNameForSocialService(socialService)).share(data);
          };

          return $socialShare;
        }]
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name socialShare.directive:socialShare
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="socialShare">
   <file name="index.html">
   <social-share></social-share>
   </file>
   </example>
   *
   */
  angular
    .module('socialShare')
    .directive('socialShare', socialShare);

  function socialShare() {
    return {
      restrict: 'EA',
      scope: {
        url: '=',
        shortUrl: '=',
        title: '=',
        name: '=',
        description: '=',
        source: '=',
        picture: '=',
        hashTags: '=',
        buttonId: '='
      },
      templateUrl: 'social-share/social-share-directive.tpl.html',
      replace: false,
      controllerAs: 'socialShare',
      controller: ['$scope', 'SocialShare', '$location', 'BitlyUrlShortener', 'configOptions',
        function ($scope, SocialShare, $location, BitlyUrlShortener, configOptions) {
          var vm = this;
          if ($scope.buttonId) {
            vm.id = $scope.buttonId;
          }
          else {
            vm.id = '';
          }
          vm.share = function (socialService) {
            var url = $scope.url || $location.absUrl();
            if ($scope.shortUrl) {
              SocialShare.share(socialService, {
                url: $scope.shortUrl,
                name: $scope.name || configOptions.defaultShareName,
                title: $scope.title,
                description: $scope.description || configOptions.defaultShareMessage,
                source: $scope.source || 'MyLeadPod',
                picture: $scope.picture || configOptions.appUrl + '/images/touch-icon-192x192.png',
                hashTags: $scope.hashTags
              });
            }
            else {
              BitlyUrlShortener.shorten(url).then(function (shortUrl) {
                SocialShare.share(socialService, {
                  url: shortUrl,
                  name: $scope.name || configOptions.defaultShareName,
                  title: $scope.title,
                  description: $scope.description || configOptions.defaultShareMessage,
                  source: $scope.source || 'MyLeadPod',
                  picture: $scope.picture || configOptions.appUrl + '/images/touch-icon-192x192.png',
                  hashTags: $scope.hashTags
                });
              });
            }
          };
        }]
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name socialNetwork
   * @description
   *
   */
  config.$inject = ["configOptions"];
  angular
    .module('socialNetwork', [
      'ui.router'
    ]);

  angular
    .module('socialNetwork')
    .config(config);

  function config(configOptions) {
    /* eslint camelcase: [2, {properties: "never"}]*/
    hello.init({
      facebook: configOptions.facebookAppId,
      google: configOptions.googlePlusClientId,
      linkedin: {
        id: configOptions.linkedInClientId,
        scope: {
          basic: 'r_liteprofile',
          email: 'r_emailaddress'
        }
      }
    }, {
      oauth_proxy: configOptions.linkedInProxy
    });
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name socialNetwork.constant:socialNetworkProvider
   *
   * @description
   *
   */
  angular
    .module('socialNetwork')
    .constant('socialNetworkProvider', {
      google: 'google',
      facebook: 'facebook',
      linkedIn: 'linkedin'
    });
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name socialNetwork.service:SocialAccount
   *
   * @description
   *
   */
  SocialAccount.$inject = ["$q", "$state", "configOptions", "socialNetworkProvider", "Auth"];
  angular
    .module('socialNetwork')
    .service('SocialAccount', SocialAccount);

  function SocialAccount($q, $state, configOptions, socialNetworkProvider, Auth) {
    var self = this;

    /**
     * Signs user in
     *
     * @param {string} provider Social network name
     * @param {Object} options  Additional options
     * @param {Object} params Additional parameters
     * @returns {Deferred} Deferred promise
     */
    function signIn(provider, options, params) {
      var deferred = $q.defer();
      /* eslint camelcase: [2, {properties: "never"}]*/
      if (!angular.isObject(options)) {
        options = {};
      }

      options.redirect_uri = $state.href($state.current, $state.params, {absolute: true});
      hello.login(provider, options, function () {
        var result = hello(provider).getAuthResponse();
        if (angular.isObject(result) && angular.isDefined(result.access_token)) {
          Auth.socialSignIn(provider, result.access_token, params)
            .then(function (signInResult) {
              deferred.resolve(signInResult);
            }, function (reason) {
              deferred.reject(reason);
            });
        }
        else {
          deferred.reject('An error occurred');
        }
      });

      return deferred.promise;
    }

    /**
     * Google Sign In
     *
     * @param {Object} params Additional parameters
     *
     * @returns {Deferred} Deferred promise
     */
    function googleSignIn(params) {
      return signIn(socialNetworkProvider.google, {
        scope: configOptions.googlePlusScope
      }, params);
    }

    /**
     * Facebook Sign In
     *
     * @param {Object} params Additional parameters
     *
     * @returns {Deferred} Deferred promise
     */
    function facebookSignIn(params) {
      return signIn(socialNetworkProvider.facebook, {
        scope: configOptions.facebookScope
      }, params);
    }

    /**
     * LinkedIn Sign In
     *
     * @param {Object} params Additional parameters
     *
     * @returns {Deferred} Deferred promise
     */
    function linkedInSignIn(params) {
      return signIn(socialNetworkProvider.linkedIn, {
        scope: configOptions.linkedInScope
      }, params);
    }

    /**
     * Signs user in
     *
     * @param {string} provider Social network name
     * @param {Object} params Additional parameters
     *
     * @returns {Deferred} Deferred promise
     */
    self.signIn = function (provider, params) {
      switch (provider) {
        case socialNetworkProvider.google:
          return googleSignIn(params);
        case socialNetworkProvider.facebook:
          return facebookSignIn(params);
        case socialNetworkProvider.linkedIn:
          return linkedInSignIn(params);
        default:
          throw new Error('Unknown social network provider');
      }
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name components.ui.search
   * @description
   *
   */
  angular
    .module('mlp-search', []);
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name mlp-search.directive:searchBar
   * @restrict EA
   * @element
   *
   * @description This is the directory search bar that searches across the user's content
   *
   */
  angular
    .module('mlp-search')
    .directive('searchBar', searchBar);

  function searchBar() {
    return {
      restrict: 'E',
      scope: true,
      templateUrl: 'search/search-bar-directive.tpl.html',
      replace: true,
      controllerAs: 'searchbar',
      controller: ['$scope', '$document', '$state', 'Session', 'Leads', function ($scope, $document, $state, Session, Leads) {
        var vm = this,
            userProfileDeferred = Session.getUserProfile();

        function handleLeads(item) {
          Leads.openDetail(item.id, item.leadtype);
        }

        //
        vm.searchCategories = [
          {name: 'All', value: 'all'},
          {name: 'My Pod', value: 'myPod', stateTarget: 'user', iconClass: 'fa-user'},
          {name: 'Directory', value: 'businessUsers', stateTarget: 'user.about', iconClass: 'fa-briefcase'},
          {name: 'Leads', value: 'leads', callbackHandler: handleLeads, iconClass: 'fa-paper-plane'},
          {name: 'Charities', value: 'charities', stateTarget: 'user', iconClass: 'fa-heart-o'}
        ];

        // Get the user data from session.
        function updateUserData() {
          userProfileDeferred.then(function (profile) {
            if (profile) {
              vm.userProfile = profile;
              if (vm.userProfile.isBusinessAccountActive) {
                vm.searchCategories.push({name: 'My Connections', value: 'myConnections', stateTarget: 'user', iconClass: 'fa-user-o'});
              }
            }
          });
        }
        updateUserData();

        // For the autocomplete (angucomplete)
        vm.searchbarId = 'directory-searchbar';
        // vm.searchUrl = 'https://admin-pilot.myleadpod.com/dataapi/myleadpod/v1/Members/index?limit=10&offset=0&status=active&search=';
        vm.searchUrl = 'https://admin-pilot.myleadpod.com/dataapi/myleadpod/v1/Search/index?keyword=';
        vm.searchService = 'Search';
        vm.searchServiceEndPoint = 'index';

        vm.onSearchItemSelected = function (item) {
          var stateTarget,
              callbackHandler;

          // Callback on when the user selected the search item.
          // Pull the stateTarget from category
          if (!item) {
            return;
          }

          stateTarget = _.find(vm.searchCategories, {value: item.originalObject.category}).stateTarget;
          callbackHandler = _.find(vm.searchCategories, {value: item.originalObject.category}).callbackHandler;
          if (stateTarget && item.originalObject.id) {
            $state.go(stateTarget, {id: item.originalObject.id});
          }
          else if (callbackHandler) {
            callbackHandler(item.originalObject);
          }
        };

        /**
         * Format the reponse coming from the server, channeled via search-autocomplete-directive
         *
         * @param {object} response Raw response object coming from Search REST Endpoint
         *
         * @returns {object} Formatted result item
         */
        vm.searchResponseFormatter = function (response) {
          var r,
              records;

          if (angular.isUndefined(response) || !response) {
            return null;
          }

          r = response.records;
          records = _.map(r, function (item) {
            var category = _.find(vm.searchCategories, {value: item.datasource}),
                returnObject = {},
                categoryDescriptionClass,
                value = item.fullname;

            categoryDescriptionClass = category.iconClass || 'fa-user';
            if (item.leadtype) {
              categoryDescriptionClass += item.leadtype === 'received' ? ' fa-paper-plane-o' : '';
              value += ' | ' + item.leadfullname;
            }
            else if (item.companyname && item.companyname !== item.fullname) {
              value += ' | ' + item.companyname;
            }

            returnObject = {
              value: value,
              id: item.id,
              category: item.datasource,
              categoryDescription: '<i class="fa ' + categoryDescriptionClass + '"></i>',
              timeDescription: item.datecreatedformatted ? item.datecreatedformatted : '',
              leadtype: item.leadtype
            };

            return returnObject;
          });

          return records;
        };

        vm.focusIn = function () {
          $document.find('.navbar-header').addClass('search-focused');
        };
        vm.focusOut = function () {
          $document.find('.navbar-header').removeClass('search-focused');
        };
      }],

      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name mlp-search.directive:searchAutocomplete
   * @restrict EA
   * @element
   *
   * @description This is the directory search bar that searches across the user's content
   *
   */
  searchAutocomplete.$inject = ["$q", "$parse", "$http", "$sce", "$timeout", "$templateCache", "$interpolate", "$window", "$document", "Restangular"];
  angular
    .module('mlp-search')
    .directive('searchAutocomplete', searchAutocomplete);

  function searchAutocomplete($q, $parse, $http, $sce, $timeout, $templateCache, $interpolate, $window, $document, Restangular) {
    // keyboard events
    var KEY_DW = 40,
        KEY_RT = 39,
        KEY_UP = 38,
        KEY_LF = 37,
        KEY_ES = 27,
        KEY_EN = 13,
        KEY_TAB = 9,

        MIN_LENGTH = 3,
        MAX_LENGTH = 524288,
        PAUSE = 300,
        BLUR_TIMEOUT = 200,

        REQUIRED_CLASS = 'autocomplete-required',
        TEXT_SEARCHING = 'Searching...',
        TEXT_NORESULTS = 'No results found';

    function link(scope, elem, attrs, ctrl) {
      var inputField = elem.find('input'),
          minlength = MIN_LENGTH,
          searchTimer = null,
          hideTimer,
          requiredClassName = REQUIRED_CLASS,
          responseFormatter,
          validState = null,
          httpCanceller = null,
          httpCallInProgress = false,
          dd = elem[0].querySelector('.search-autocomplete-dropdown'),
          isScrollOn = false,
          mousedownOn = null,
          unbindInitialValue,
          displaySearching,
          displayNoResults,
          lastSearchStr,
          bodyClickHandler,
          onBodyClickListener;

      // For the filter
      scope.showFilterDropdown = false;
      scope.selectedCategories = ['all'];
      scope.filterActive = false;
      scope.searchFocused = false;
      scope.resultsBackup = null;
      scope.showResultsFilter = false;
      scope.hasCategoryFilter = typeof scope.hasCategoryFilter === 'undefined';

      // For the history dropdown
      scope.showHistoryDropdown = false;
      scope.historyItems = [];
      scope.currentHistoryIndex = -1;

      // Check if the dropdown is positioned relatively
      if (scope.isDropdownRelative) {
        angular.element(dd).css('position', 'relative');
      }

      // Setup the selected categories based on search categories
      if (scope.searchCategories && scope.searchCategories.length === 1) {
        scope.selectedCategories = [scope.searchCategories[0].value];
      }

      bodyClickHandler = function (evt) {
        scope.checkFocus(evt, true);
      };

      elem.on('mousedown', function (event) {
        if (event.target.id) {
          mousedownOn = event.target.id;
          if (mousedownOn === scope.id + '_dropdown') {
            $document[0].body.addEventListener('click', clickoutHandlerForDropdown);
          }
        }
        else {
          mousedownOn = event.target.className;
        }
      });

      scope.currentIndex = scope.focusFirst ? 0 : null;
      scope.searching = false;
      unbindInitialValue = scope.$watch('initialValue', function (newval) {
        if (newval) {
          // remove scope listener
          unbindInitialValue();
          // change input
          handleInputChange(newval, true);
        }
      });

      scope.$watch('fieldRequired', function (newval, oldval) {
        if (newval !== oldval) {
          if (!newval) {
            ctrl[scope.inputName].$setValidity(requiredClassName, true);
          }
          else if (!validState || scope.currentIndex === -1) {
            handleRequired(false);
          }
          else {
            handleRequired(true);
          }
        }
      });

      scope.$on('search-autocomplete:clearInput', function (event, elementId) {
        if (!elementId || elementId === scope.id) {
          scope.searchStr = null;
          callOrAssign();
          handleRequired(false);
          clearResults();
        }
      });

      scope.$on('search-autocomplete:changeInput', function (event, elementId, newval) {
        if (!!elementId && elementId === scope.id) {
          handleInputChange(newval);
        }
      });

      function handleInputChange(newval, initial) {
        if (newval) {
          if (angular.isObject(newval)) {
            scope.searchStr = extractTitle(newval);
            callOrAssign({originalObject: newval});
          }
          else if (angular.isString(newval) && newval.length > 0) {
            scope.searchStr = newval;
          }
          else if (console && console.error) {
            console.error('Tried to set ' + (!initial ? 'initial' : '') + ' value of search-autocomplete to', newval, 'which is an invalid value');
          }

          handleRequired(true);
        }
      }

      // #194 dropdown list not consistent in collapsing (bug).
      function clickoutHandlerForDropdown(event) {
        mousedownOn = null;
        scope.hideResults(event, false);
        $document[0].body.removeEventListener('click', clickoutHandlerForDropdown);
      }

      // for IE8 quirkiness about event.which
      function ie8EventNormalizer(event) {
        return event.which ? event.which : event.keyCode;
      }

      function callOrAssign(value) {
        if (value && scope.blurOnSelect) {
          inputField.blur();
          $timeout(function () {
            scope.searchFocused = false;
          }, 100);
        }

        if (angular.isFunction(scope.selectedObject)) {
          scope.selectedObject(value, scope.selectedObjectData);
        }
        else {
          scope.selectedObject = value;
        }

        if (value) {
          handleRequired(true);
        }
        else {
          handleRequired(false);
        }
      }

      function callFunctionOrIdentity(fn) {
        return function (data) {
          return scope[fn] ? scope[fn](data) : data;
        };
      }

      function setInputString(str) {
        callOrAssign({originalObject: str});

        if (scope.clearSelected) {
          scope.searchStr = null;
        }
        clearResults();
      }

      function extractTitle(data) {
        // split title fields and run extractValue for each and join with ' '
        return scope.titleField.split(',')
          .map(function (field) {
            return extractValue(data, field);
          })
          .join(' ');
      }

      function extractValue(obj, key) {
        var keys, result, i;
        if (key) {
          keys = key.split('.');
          result = obj;
          for (i = 0; i < keys.length; i++) {
            result = result[keys[i]];
          }
        }
        else {
          result = obj;
        }
        return result;
      }

      function findMatchString(target, str) {
        var result, matches, re;
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
        // Escape user input to be treated as a literal string within a regular expression
        re = new RegExp(str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i');
        if (!target) {
          return null;
        }
        if (!target.match || !target.replace) {
          target = target.toString();
        }
        matches = target.match(re);
        if (matches) {
          result = target.replace(re, '<span class="' + scope.matchClass + '">' + matches[0] + '</span>');
        }
        else {
          result = target;
        }
        return $sce.trustAsHtml(result);
      }

      function handleRequired(valid) {
        scope.notEmpty = valid;
        validState = scope.searchStr;
        if (scope.fieldRequired && ctrl && scope.inputName) {
          ctrl[scope.inputName].$setValidity(requiredClassName, valid);
        }
      }

      /* eslint complexity: [2, 25]*/
      function keyupHandler(event) {
        var which = ie8EventNormalizer(event);
        if (which === KEY_LF || which === KEY_RT) {
          // do nothing
          return;
        }

        if (which === KEY_UP || which === KEY_EN) {
          event.preventDefault();
        }
        else if (which === KEY_DW) {
          event.preventDefault();

          if (scope.searchStr && scope.searchStr === lastSearchStr && scope.results.length > 0) {
            // So there is a result object, the search string is the same as per the last search string and user pressed DWN button. ->
            // open the dropdown with results then without invoking a new search
            scope.searching = false;
            scope.showDropdown = true;
            scope.showFilterDropdown = false;
            scope.showHistoryDropdown = false;
            scope.$apply();
          }
          else if (!scope.showDropdown && scope.searchStr && scope.searchStr.length >= minlength) {
            initResults();
            scope.searching = true;
            resetCategories();
            searchTimerComplete(scope.searchStr);
          }
          else if (scope.historyItems && scope.hasHistory) {
            if (!scope.showHistoryDropdown) {
              scope.currentHistoryIndex = -1;
            }
            scope.showHistoryDropdown = true;
            scope.$apply();
          }
        }
        else if (which === KEY_ES) {
          if (scope.results && scope.showDropdown) {
            scope.showDropdown = false;
          }
          else if (scope.results && !scope.showDropdown) {
            clearResults();
            scope.searchStr = null;
          }
          scope.showHistoryDropdown = false;
          scope.$apply(function () {
            inputField.val(scope.searchStr);
          });
        }
        else {
          if (minlength === 0 && !scope.searchStr) {
            return;
          }

          lastSearchStr = scope.searchStr;
          if (scope.searchStr && scope.searchStr.length >= minlength) {
            initResults();

            if (searchTimer) {
              $timeout.cancel(searchTimer);
            }

            scope.searching = true;
            resetCategories();
            searchTimer = $timeout(function () {
              searchTimerComplete(scope.searchStr);
            }, scope.pause);
          }
          else if (!scope.searchStr || scope.searchStr === '' || scope.searchStr.length < minlength) {
            scope.showDropdown = false;
            if (searchTimer) {
              $timeout.cancel(searchTimer);
            }
            scope.searching = false;
          }

          if (validState && validState !== scope.searchStr && !scope.clearSelected) {
            scope.$apply(function () {
              callOrAssign();
            });
          }
        }
      }

      function keydownHandler(event) {
        if (scope.results && scope.results.length > 0) {
          handleKeyDown(event, scope.results, scope.currentIndex, scope.showDropdown, false);
        }
        else if (scope.historyItems && scope.historyItems.length > 0) {
          handleKeyDown(event, scope.historyItems, scope.currentHistoryIndex, scope.showHistoryDropdown, true);
        }
      }

      function handleKeyDown(event, results, currentIndex, showDropdown, isHistory) {
        var which = ie8EventNormalizer(event),
            row = null,
            rowTop = null;

        // Straightaway is this is a history, and it's not TAB, KEYDOWN, KEYUP or Enter then just hide it.
        if (isHistory && which !== KEY_EN && which !== KEY_DW && which !== KEY_UP && which !== KEY_TAB) {
          scope.showHistoryDropdown = false;
          return;
        }

        if (which === KEY_EN && results) {
          if (currentIndex >= 0 && currentIndex < results.length) {
            event.preventDefault();
            if (isHistory) {
              scope.selectHistoryItem(results[currentIndex]);
            }
            else {
              scope.selectResult(results[currentIndex]);
            }
          }
          else {
            handleOverrideSuggestions(event);
          }
          scope.$apply();
        }
        else if (which === KEY_DW && results && currentIndex + 1 < results.length && showDropdown) {
          event.preventDefault();
          scope.$apply(function () {
            currentIndex += 1;
            updateCurrentIndexByOne(false, isHistory);
            updateInputField(results, currentIndex);
          });

          if (isScrollOn) {
            row = dropdownRow(currentIndex);
            if (dropdownHeight() < row.getBoundingClientRect().bottom) {
              dropdownScrollTopTo(dropdownRowOffsetHeight(row));
            }
          }
        }
        else if (which === KEY_UP && results) {
          event.preventDefault();
          if (currentIndex >= 1) {
            scope.$apply(function () {
              currentIndex -= 1;
              updateCurrentIndexByOne(true, isHistory);
              updateInputField(results, currentIndex);
            });

            if (isScrollOn) {
              rowTop = dropdownRowTop(isHistory ? scope.currentIndex : scope.currentHistoryIndex);
              if (rowTop < 0) {
                dropdownScrollTopTo(rowTop - 1);
              }
            }
          }
          else if (currentIndex === 0) {
            scope.$apply(function () {
              currentIndex -= 1;
              updateCurrentIndexByOne(true, isHistory);
              inputField.val(scope.searchStr);
            });
          }
        }
        else if (which === KEY_TAB) {
          if (results && results.length > 0 && showDropdown) {
            if (currentIndex === -1 && scope.overrideSuggestions) {
              // intentionally not sending event so that it does not
              // prevent default tab behavior
              handleOverrideSuggestions();
            }
            else {
              if (currentIndex === -1) {
                currentIndex = 0;
                updateCurrentIndexByOne(0, isHistory);
              }
              if (isHistory) {
                scope.selectHistoryItem(results[currentIndex]);
              }
              else {
                scope.selectResult(results[currentIndex]);
              }

              scope.$apply();
            }
          }
          else if (scope.searchStr && scope.searchStr.length > 0) {
            // no results
            // intentionally not sending event so that it does not
            // prevent default tab behavior
            handleOverrideSuggestions();
          }
        }
        else if (which === KEY_ES) {
          // This is very specific to IE10/11 #272
          // without this, IE clears the input text
          event.preventDefault();
        }
      }

      function updateCurrentIndexByOne(negative, isHistory) {
        if (isHistory) {
          if (negative === 0) {
            scope.currentHistoryIndex = 0;
          }
          else if (negative === true) {
            scope.currentHistoryIndex -= 1;
          }
          else {
            scope.currentHistoryIndex += 1;
          }
          return;
        }

        if (negative === 0) {
          scope.currentIndex = 0;
        }
        else if (negative === true) {
          scope.currentIndex -= 1;
        }
        else {
          scope.currentIndex += 1;
        }
      }

      function handleOverrideSuggestions(event) {
        if (scope.overrideSuggestions &&
            !(scope.selectedObject && scope.selectedObject.originalObject === scope.searchStr)) {
          if (event) {
            event.preventDefault();
          }

          // cancel search timer
          $timeout.cancel(searchTimer);
          // cancel http request
          cancelHttpRequest();

          setInputString(scope.searchStr);
        }
      }

      function dropdownRowOffsetHeight(row) {
        var css = $window.getComputedStyle(row);
        return row.offsetHeight +
          parseInt(css.marginTop, 10) + parseInt(css.marginBottom, 10);
      }

      function dropdownHeight() {
        return dd.getBoundingClientRect().top +
          parseInt($window.getComputedStyle(dd).maxHeight, 10);
      }

      function dropdownRow(currentIndex) {
        return elem[0].querySelectorAll('.search-autocomplete-row')[currentIndex];
      }

      function dropdownRowTop(currentIndex) {
        return dropdownRow(currentIndex).getBoundingClientRect().top -
          (dd.getBoundingClientRect().top +
           parseInt($window.getComputedStyle(dd).paddingTop, 10));
      }

      function dropdownScrollTopTo(offset) {
        dd.scrollTop += offset;
      }

      function updateInputField(results, currentIndex) {
        var current = null;
        if (!results && !currentIndex) {
          results = scope.results;
          currentIndex = scope.currentIndex;
        }
        current = results[currentIndex];
        if (scope.matchClass) {
          inputField.val(extractTitle(current.originalObject));
        }
        else {
          inputField.val(current.title);
        }
      }

      function httpSuccessCallbackGen(str) {
        return function (responseData, status, headers, config) {
          // normalize return obejct from promise
          if (!status && !headers && !config && responseData.data) {
            responseData = responseData.data;
          }
          scope.searching = false;
          processResults(
            extractValue(responseFormatter(responseData), scope.remoteUrlDataField),
            str);
        };
      }

      function httpErrorCallback(errorRes, status, headers, config) {
        scope.searching = httpCallInProgress;

        // normalize return obejct from promise
        if (!status && !headers && !config) {
          status = errorRes.status;
        }

        // cancelled/aborted
        if (status === 0 || status === -1) {
          return;
        }
        if (scope.remoteUrlErrorCallback) {
          scope.remoteUrlErrorCallback(errorRes, status, headers, config);
        }
        else if (console && console.error) {
          console.error('http error');
        }
      }

      function cancelHttpRequest() {
        if (httpCanceller) {
          httpCanceller.resolve();
        }
      }

      function getRemoteResults(str) {
        var params = {},
            url = scope.remoteUrl + encodeURIComponent(str);

        if (scope.remoteUrl) {
          if (scope.remoteUrlRequestFormatter) {
            params = {
              params: scope.remoteUrlRequestFormatter(str)
            };
            url = scope.remoteUrl;
          }

          if (!scope.remoteUrlRequestWithCredentials) {
            params.withCredentials = true;
          }
          cancelHttpRequest();
          httpCanceller = $q.defer();
          params.timeout = httpCanceller.promise;
          httpCallInProgress = true;
          $http.get(url, params)
            .then(httpSuccessCallbackGen(str))
            .catch(httpErrorCallback)
            .finally(function () {
              httpCallInProgress = false;
            });
        }
        else if (scope.searchService && scope.searchServiceEndPoint) {
          Restangular.all(scope.searchService).get(scope.searchServiceEndPoint, {
            keyword: str,
            categories: scope.selectedCategories && scope.selectedCategories.length > 0 ? scope.selectedCategories.toString() : 'all'
          }).then(httpSuccessCallbackGen(str), httpErrorCallback).finally(function () {
            httpCallInProgress = false;
          });
        }
      }

      function getRemoteResultsWithCustomHandler(str) {
        cancelHttpRequest();

        httpCanceller = $q.defer();

        scope.remoteApiHandler(str, httpCanceller.promise)
          .then(httpSuccessCallbackGen(str))
          .catch(httpErrorCallback);

        /* IE8 compatible
        scope.remoteApiHandler(str, httpCanceller.promise)
          ['then'](httpSuccessCallbackGen(str))
          ['catch'](httpErrorCallback);
        */
      }

      function clearResults(hideOnly) {
        scope.showDropdown = false;
        if (!hideOnly) {
          lastSearchStr = null;
          scope.results = [];
          scope.showResultsFilter = false;
          if (dd) {
            dd.scrollTop = 0;
          }
        }
      }

      function initResults() {
        scope.showDropdown = displaySearching;
        scope.currentIndex = scope.focusFirst ? 0 : -1;
        /* scope.results = [];*/
      }

      function getLocalResults(str) {
        var i, match, s, value,
            searchFields = scope.searchFields.split(','),
            matches = [];

        if (typeof scope.parseInput() !== 'undefined') {
          str = scope.parseInput()(str);
        }
        for (i = 0; i < scope.localData.length; i++) {
          match = false;

          for (s = 0; s < searchFields.length; s++) {
            value = extractValue(scope.localData[i], searchFields[s]) || '';
            match = match || value.toString().toLowerCase().indexOf(str.toString().toLowerCase()) >= 0;
          }

          if (match) {
            matches[matches.length] = scope.localData[i];
          }
        }
        return matches;
      }

      function checkExactMatch(result, obj, str) {
        var key;

        if (!str) {
          return false;
        }
        for (key in obj) {
          if (obj[key].toLowerCase() === str.toLowerCase()) {
            scope.selectResult(result);
            return true;
          }
        }
        return false;
      }

      function searchTimerComplete(str) {
        // Begin the search
        if (!str || str.length < minlength) {
          return;
        }
        if (scope.localData) {
          scope.$apply(function () {
            var matches;
            if (typeof scope.localSearch() !== 'undefined') {
              matches = scope.localSearch()(str, scope.localData);
            }
            else {
              matches = getLocalResults(str);
            }
            scope.searching = false;
            processResults(matches, str);
          });
        }
        else if (scope.remoteApiHandler) {
          getRemoteResultsWithCustomHandler(str);
        }
        else {
          getRemoteResults(str);
        }
      }

      function resetCategories() {
        // Reset all search categories focusFirst
        if (!scope.searchCategories) {
          return;
        }
        angular.forEach(scope.searchCategories, function (sc) {
          sc.hasResults = false;
        });
        $timeout(function () {
          scope.$apply();
        });
      }

      function processResults(responseData, str, isHistory) {
        var i,
            item,
            description,
            description2,
            image, text,
            formattedText,
            formattedDesc,
            foundCategories = [];

        if (responseData && responseData.length > 0) {
          scope.results = [];

          for (i = 0; i < responseData.length; i++) {
            if (scope.titleField && scope.titleField !== '') {
              text = formattedText = extractTitle(responseData[i]);
            }

            description = '';
            if (scope.descriptionField) {
              description = formattedDesc = extractValue(responseData[i], scope.descriptionField);
            }

            if (scope.descriptionField2) {
              description2 = extractValue(responseData[i], scope.descriptionField2);
            }

            image = '';
            if (scope.imageField) {
              image = extractValue(responseData[i], scope.imageField);
            }

            if (scope.matchClass) {
              formattedText = findMatchString(text, str);
              // formattedDesc = findMatchString(description, str);
            }

            // Now check the category so we can update the badge on the category filter.
            if (scope.categoryField) {
              foundCategories.push(responseData[i][scope.categoryField]);
            }

            item = {
              title: formattedText,
              description: formattedDesc,
              description2: description2,
              image: image,
              originalObject: responseData[i]
            };

            if (!isHistory) {
              scope.results[scope.results.length] = item;
            }
            else {
              scope.historyItems[scope.historyItems.length] = item;
            }
          }

          // Now check the categories
          if (!isHistory && scope.searchCategories && scope.searchCategories.length > 0) {
            // Make the values unique
            foundCategories = _.uniq(foundCategories);
            // Now loop through each category and apply the badge
            angular.forEach(foundCategories, function (category) {
              _.find(scope.searchCategories, {value: category}).hasResults = true;
            });
          }
        }
        else {
          scope.results = [];
        }

        // Update the showResultsFilter value
        if (!isHistory) {
          scope.showResultsFilter = scope.searchCategories && scope.results;

          if (scope.autoMatch && scope.results.length === 1 &&
            checkExactMatch(scope.results[0],
              {title: text, desc: description || ''}, scope.searchStr)) {
            scope.showDropdown = false;
          }
          else if (scope.results.length === 0 && !displayNoResults) {
            scope.showDropdown = false;
          }
          else {
            scope.showDropdown = true;
            scope.showFilterDropdown = false;
            scope.showHistoryDropdown = false;
          }

          // Make the backup
          scope.resultsBackup = {
            categories: _.clone(scope.selectedCategories),
            results: _.clone(scope.results)
          };
        }
      }

      function showAll() {
        if (scope.localData) {
          scope.searching = false;
          processResults(scope.localData, '');
        }
        else if (scope.remoteApiHandler) {
          scope.searching = true;
          resetCategories();
          getRemoteResultsWithCustomHandler('');
        }
        else {
          scope.searching = true;
          resetCategories();
          getRemoteResults('');
        }
      }

      scope.onFocusHandler = function () {
        if (scope.focusIn) {
          scope.focusIn();
        }
        scope.showFilterDropdown = false;
        if (minlength === 0 && (!scope.searchStr || scope.searchStr.length === 0)) {
          scope.currentIndex = scope.focusFirst ? 0 : scope.currentIndex;
          scope.showDropdown = true;
          scope.showFilterDropdown = false;
          showAll();
        }
        if (scope.searchStr && scope.searchStr === lastSearchStr && scope.results.length > 0) {
          // So there is a result object, the search string is the same as per the last search string and user pressed DWN button. ->
          // open the dropdown with results then without invoking a new search
          scope.searching = false;
          scope.showDropdown = true;
        }
        else if (scope.hasHistory && scope.historyItems) {
          scope.showHistoryDropdown = true;
          scope.currentHistoryIndex = -1;
        }
      };

      scope.hideResults = function (event, hideOnly) {
        var element = angular.element(event.relatedTarget);
        if (mousedownOn &&
            (mousedownOn === scope.id + '_dropdown' ||
             mousedownOn.indexOf('search-autocomplete') >= 0)) {
          mousedownOn = null;
        }
        else if (!element.closest('.search-autocomplete-holder')) {
          // If the element is the child of the autocomplete holder, don't lose the focus
          hideTimer = $timeout(function () {
            clearResults(hideOnly);
            scope.$apply(function () {
              if (scope.searchStr && scope.searchStr.length > 0) {
                inputField.val(scope.searchStr);
              }
            });
          }, BLUR_TIMEOUT);
          cancelHttpRequest();

          if (scope.focusOut) {
            scope.focusOut();
          }

          if (scope.overrideSuggestions) {
            if (scope.searchStr && scope.searchStr.length > 0 && scope.currentIndex === -1) {
              handleOverrideSuggestions();
            }
          }
        }
      };

      scope.resetHideResults = function () {
        if (hideTimer) {
          $timeout.cancel(hideTimer);
        }
      };

      scope.hoverRow = function (index, isHistory) {
        if (isHistory) {
          scope.currentHistoryIndex = index;
        }
        else {
          scope.currentIndex = index;
        }
      };

      scope.removeHover = function (isHistory) {
        if (isHistory) {
          scope.currentHistoryIndex = -1;
        }
        else {
          scope.currentIndex = -1;
        }
      };

      scope.selectResult = function (result) {
        // Restore original values
        if (scope.matchClass) {
          result.title = extractTitle(result.originalObject);
          result.description = extractValue(result.originalObject, scope.descriptionField);
        }

        if (scope.clearSelected) {
          scope.searchStr = null;
        }
        else if (scope.updateInputOnSelect) {
          updateInputField();
        }
        else {
          inputField.val(scope.searchStr);
        }
        callOrAssign(result);
        clearResults(!scope.clearSelected);
        saveHistoryItem(result);
      };

      scope.selectHistoryItem = function (item) {
        scope.searchStr = null;
        scope.showHistoryDropdown = false;
        // Restore original values
        if (scope.matchClass) {
          item.title = extractTitle(item.originalObject);
          item.description = extractValue(item.originalObject, scope.descriptionField);
        }
        callOrAssign(item);
      };

      scope.inputChangeHandler = function (str) {
        if (str.length < minlength) {
          cancelHttpRequest();
          clearResults();
        }
        else if (str.length === 0 && minlength === 0) {
          showAll();
        }

        if (scope.inputChanged) {
          str = scope.inputChanged(str);
        }
        return str;
      };

      //
      // Filter
      //
      scope.changeCategory = function (category, evt, isSingleFilter) {
        if (!category || scope.searching) {
          return;
        }

        if (evt) {
          evt.stopImmediatePropagation();
          evt.preventDefault();
        }

        if (category === 'all') {
          scope.selectedCategories = ['all'];
          scope.filterActive = false;
          scope.checkLastSearchedCategories(category);
          return;
        }

        if (isSingleFilter && category) {
          scope.selectedCategories = [category];
          scope.filterActive = true;
          scope.checkLastSearchedCategories(category);
          return;
        }

        if (_.contains(scope.selectedCategories, category)) {
          scope.selectedCategories = _.without(scope.selectedCategories, category);
        }
        else {
          if (_.contains(scope.selectedCategories, 'all')) {
            scope.selectedCategories = [];
          }
          scope.selectedCategories.push(category);
          scope.filterActive = true;
        }

        if (scope.selectedCategories.length === 0) {
          scope.selectedCategories.push('all');
          scope.filterActive = false;
        }

        scope.checkLastSearchedCategories(category);

        // if the evt is null, it's comming from the main filter content, so if there are any results. just pop them out.
        // but do this in a little timeout to give it some time to render.
        if (!evt && scope.results && scope.results.length > 0) {
          $timeout(function () {
            scope.showDropdown = true;
            scope.showFilterDropdown = false;
          }, 100);
        }
      };

      scope.checkLastSearchedCategories = function (category) {
        // Check what was searched last time. If the incoming (clicked) category is not in selectedCategories, then re-run the search.

        // Check if there is anything in the input box (will indicate whether the search was already ran or not)
        if (!scope.searchStr || scope.searchStr.length < minlength || !scope.resultsBackup) {
          return;
        }

        if (scope.resultsBackup.categories[0] === 'all' && scope.selectedCategories.lastIndexOf('all') === -1) {
          // This means that the last search was done using the 'all' categories keyword. Any incoming category was already included in the search. Just alter the results
          scope.filterResults(category);
        }
        else if (scope.resultsBackup.categories[0] === 'all' && scope.selectedCategories.lastIndexOf('all') > -1) {
          // Show all of them again
          scope.results = _.clone(scope.resultsBackup.results);
        }
        else if (_.contains(scope.resultsBackup.categories, category)) {
          // Read from backup
          scope.filterResults(category);
        }
        else {
          // Do the new search!
          scope.searching = true;
          resetCategories();
          searchTimerComplete(scope.searchStr);
        }
      };

      scope.filterResults = function () {
        var fresults = [];
        angular.forEach(scope.resultsBackup.results, function (result) {
          if (_.contains(scope.selectedCategories, result.originalObject.category)) {
            fresults.push(result);
          }
        });
        scope.results = fresults;
      };

      scope.openFilter = function () {
        scope.showHistoryDropdown = false;
        scope.showFilterDropdown = !scope.showFilterDropdown;
        if (scope.showFilterDropdown) {
          scope.showDropdown = false;
        }
        if (scope.focusIn) {
          scope.focusIn();
        }
      };

      scope.clearInput = function () {
        scope.searchStr = null;
        clearResults();
        inputField.focus();
      };

      scope.backBtnClicked = function () {
        scope.searchStr = null;
        cancelHttpRequest();
        clearResults();
        if (scope.focusOut) {
          scope.focusOut();
        }
        $timeout(function () {
          scope.searchFocused = false;
        }, 100);
      };

      scope.checkInput = function () {
        return scope.searchStr && scope.searchStr.length > 0 && !scope.searching;
      };

      scope.checkSearch = function () {
        return !scope.searchStr || scope.searchStr.length === 0;
      };

      scope.isSelected = function (category) {
        return _.contains(scope.selectedCategories, category);
      };

      scope.checkFocus = function (evt, isBodyClick) {
        var el;

        if (isBodyClick) {
          el = $document[0].getElementById(scope.controlId);
          scope.searchFocused = _.contains(evt.path, el);
          if (!scope.searchFocused) {
            // Remove the listener.
            scope.showFilterDropdown = false;
            scope.showDropdown = false;
            scope.showHistoryDropdown = false;
            angular.element($document[0].body).off('click', bodyClickHandler);
            onBodyClickListener = null;
            if (scope.focusOut) {
              scope.focusOut();
            }
            scope.$apply();
          }
        }
        else {
          scope.searchFocused = evt.currentTarget.id === scope.controlId;
          if (scope.searchFocused && !onBodyClickListener) {
            // Create listener
            onBodyClickListener = angular.element($document[0].body).on('click', bodyClickHandler);
          }
          evt.stopImmediatePropagation();
        }
      };

      function checkHistoryItems() {
        // Entry point for the history items. Check with server for this particular input.
        scope.loadingHistory = true;
        Restangular.all(scope.searchService).get('history', {
          searchId: scope.controlId
        }).then(function (results) {
          if (results && results.plain().records) {
            processResults(results.plain().records, '', true);
          }
        }).finally(function () {
          scope.loadingHistory = false;
        });
      }

      function saveHistoryItem(result) {
        // Entry point for saving the selected item into history.
        // We shall provide the input name so we can get the different history items for different instances of this directive
        if (scope.hasHistory && result && result.originalObject) {
          Restangular.one(scope.searchService).customPOST(result.originalObject, 'history?searchId=' + scope.controlId)
            .then(function (results) {
              if (results && results.records) {
                scope.historyItems = [];
                processResults(results.records, '', true);
              }
            });
        }
      }

      // check required
      if (scope.fieldRequiredClass && scope.fieldRequiredClass !== '') {
        requiredClassName = scope.fieldRequiredClass;
      }

      // check min length
      if (scope.minlength && scope.minlength !== '') {
        minlength = parseInt(scope.minlength, 10);
      }

      // check pause time
      if (!scope.pause) {
        scope.pause = PAUSE;
      }

      // check clearSelected
      if (!scope.clearSelected) {
        scope.clearSelected = false;
      }

      // check override suggestions
      if (!scope.overrideSuggestions) {
        scope.overrideSuggestions = false;
      }

      // check required field
      if (scope.fieldRequired && ctrl) {
        // check initial value, if given, set validitity to true
        if (scope.initialValue) {
          handleRequired(true);
        }
        else {
          handleRequired(false);
        }
      }

      scope.inputType = attrs.type ? attrs.type : 'text';

      // set strings for "Searching..." and "No results"
      scope.textSearching = attrs.textSearching ? attrs.textSearching : TEXT_SEARCHING;
      scope.textNoResults = attrs.textNoResults ? attrs.textNoResults : TEXT_NORESULTS;
      displaySearching = scope.textSearching === 'false' ? false : true;
      displayNoResults = scope.textNoResults === 'false' ? false : true;

      // set max length (default to maxlength deault from html
      scope.maxlength = attrs.maxlength ? attrs.maxlength : MAX_LENGTH;

      // register events
      inputField.on('keydown', keydownHandler);
      inputField.on('keyup compositionend', keyupHandler);

      // set response formatter
      responseFormatter = callFunctionOrIdentity('remoteUrlResponseFormatter');

      // set isScrollOn
      $timeout(function () {
        var css = $window.getComputedStyle(dd);
        isScrollOn = css.maxHeight && css.overflowY === 'auto';
      });

      // Process history items. Shall be removed for PILOT and PROD!
      checkHistoryItems();
    }

    return {
      restrict: 'EA',
      require: '^?form',
      replace: true,
      transclude: true,
      scope: {
        selectedObject: '=',
        selectedObjectData: '=',
        disableInput: '=',
        initialValue: '=',
        localData: '=',
        localSearch: '&',
        remoteUrlRequestFormatter: '=',
        remoteUrlRequestWithCredentials: '@',
        remoteUrlResponseFormatter: '=',
        remoteUrlErrorCallback: '=',
        remoteApiHandler: '=',
        controlId: '@',
        type: '@',
        placeholder: '@',
        textSearching: '@',
        textNoResults: '@',
        remoteUrl: '@',
        remoteUrlDataField: '@',
        titleField: '@',
        subtitleField: '@',
        descriptionField: '@',
        descriptionField2: '@',
        categoryField: '@',
        imageField: '@',
        inputClass: '@',
        pause: '@',
        searchFields: '@',
        minlength: '@',
        matchClass: '@',
        clearSelected: '=',
        overrideSuggestions: '@',
        fieldRequired: '=',
        fieldRequiredClass: '@',
        inputChanged: '=',
        autoMatch: '@',
        focusOut: '&',
        focusIn: '&',
        fieldTabindex: '@',
        inputName: '@',
        focusFirst: '@',
        parseInput: '&',
        searchCategories: '=',
        blurOnSelect: '=',
        searchServiceEndPoint: '@',
        searchService: '@',
        resultTemplateUrl: '@',
        hasBackButton: '=',
        updateInputOnSelect: '=',
        isDropdownRelative: '=',
        hasCategoryFilter: '=?',
        hasHistory: '=?'
      },
      templateUrl: 'search/search-autocomplete-directive.tpl.html',
      /* templateUrl: function (element, attrs) {
        return attrs.templateUrl || TEMPLATE_URL;
      }, */
      compile: function (tElement) {
        var startSym = $interpolate.startSymbol(),
            endSym = $interpolate.endSymbol(),
            interpolatedHtml = null;

        if (!(startSym === '{{' && endSym === '}}')) {
          interpolatedHtml = tElement.html()
            .replace(/\{\{/g, startSym)
            .replace(/\}\}/g, endSym);
          tElement.html(interpolatedHtml);
        }
        return link;
      }
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name lead
   * @description
   *
   */
  angular
    .module('lead', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name myPod.service:MyPod
   *
   * @description
   *
   */
  MyPod.$inject = ["Restangular", "$document", "$uibModal"];
  angular
    .module('lead')
    .service('MyPod', MyPod);

  function MyPod(Restangular, $document, $uibModal) {
    var self = this;

    /**
     * Joins the currently logged in user to the other user's pod and his default reward program.
     *
     * @param {string} userId        Id of the user that current user will join (will become member of his pod)
     * @return {Deferred} Deferred promise
     */
    self.quickJoin = function (userId) {
      return Restangular.one('Members').post('joinRewardProgram', {idUser: userId});
    };

    /**
     * Opens the modal dialog and sends invite to the business so the logged-in user can start sending the leads.
     * @param {string} myProfile    Profile of the logged in user
     * @param {string} userProfile  Profile of the targeted user (business)
     * @return {Deferred} Deferred promise
     */
    self.openSendInvite = function (myProfile, userProfile) {
      var slModalInstance = $uibModal.open({
        animation: false,
        appendTo: angular.element($document[0].body),
        templateUrl: 'my-pod/send-invite/send-invite-modal.tpl.html',
        controller: 'SendInviteModalCtrl',
        controllerAs: 'sendInvite',
        size: 'md',
        resolve: {
          myProfile: function () {
            return myProfile;
          },
          userProfile: function () {
            return userProfile;
          }
        }
      });

      return slModalInstance.result;
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name myPod
   * @description
   *
   */
  angular
    .module('myPod', [
      'ui.router',
      'myPod.members',
      'myPod.sentLeads',
      'myPod.sendInvite'
    ]);
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('myPod')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('myPod', {
        url: '/my-pod',
        templateUrl: 'my-pod/my-pod.tpl.html',
        controller: 'MyPodCtrl',
        controllerAs: 'myPod',
        authenticate: true
      });
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name myPod.controller:MyPodCtrl
   *
   * @description
   *
   */
  MyPodCtrl.$inject = ["Session", "MyPod"];
  angular
    .module('myPod')
    .controller('MyPodCtrl', MyPodCtrl);

  function MyPodCtrl(Session, MyPod) {
    var vm = this;

    vm.tabManager = null;
    vm.tabsConfig = [
      {
        label: 'People in my Pod',
        value: 'members',
        url: 'myPod.members'
      },
      {
        label: 'Sent Leads',
        value: 'sentLeads',
        url: 'myPod.sentLeads'
      }
    ];

    Session.getUserProfile().then(function (userProfile) {
      vm.userProfile = userProfile;
    });

    vm.sendInvite = function () {
      MyPod.openSendInvite(vm.userProfile);
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name myMemberships
   * @description
   *
   */
  angular
    .module('myMemberships', [
      'ui.router',
      'myMemberships.memberships',
      'myMemberships.receivedLeads',
      'myMemberships.joinPod',
      'myMemberships.sendReward',
      'myMemberships.rewardProgram',
      'myMemberships.import'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name myMemberships.directive:rewardSelector
   * @restrict EA
   * @element
   */
  angular
    .module('myMemberships')
    .directive('rewardSelector', rewardSelector);

  function rewardSelector() {
    return {
      restrict: 'EA',
      scope: {
        user: '=user',
        change: '&onChange',
        hasMonthlySubscription: '@',
        hasCode: '@',
        hasDefaultProgram: '@',
        hasPayPerLead: '@',
        hasCreditCard: '@',
        hasCancelAgreement: '@',
        clearOnBroadcast: '@',
        default: '@'
      },
      templateUrl: 'my-memberships/reward-selector-directive.tpl.html',
      replace: true,
      transclude: true,
      controllerAs: 'rewards',
      controller: ['$scope', '$filter', '$timeout', '$document', function ($scope, $filter, $timeout, $document) {
        var vm = this;

        vm.hasUser = angular.isDefined($scope.user) ? $scope.user : false;
        vm.hasDefaultProgram = angular.isDefined($scope.hasDefaultProgram) ? $scope.hasDefaultProgram === 'true' : true;
        vm.hasCode = angular.isDefined($scope.hasCode) ? $scope.hasCode === 'true' : true;
        vm.hasPayPerLead = angular.isDefined($scope.hasPayPerLead) ? $scope.hasPayPerLead === 'true' : true;
        vm.hasMonthlySubscription = angular.isDefined($scope.hasMonthlySubscription) ? $scope.hasMonthlySubscription === 'true' : true;
        vm.hasCC = angular.isDefined($scope.hasCreditCard) ? $scope.hasCreditCard === 'true' : true;
        vm.hasCancelAgreement = angular.isDefined($scope.hasCancelAgreement) ? $scope.hasCancelAgreement === 'true' : false;
        vm.clearOnBroadcast = angular.isDefined($scope.clearOnBroadcast) ? $scope.clearOnBroadcast : null;

        // Important. If the selector has default program, but no user is specified (it's most likely someone else's program other than the logged-in user)
        // then lock the default program (don't list the values!)
        if (vm.hasDefaultProgram && !vm.hasUser) {
          vm.hasLockedDefaultProgram = true;
        }

        if (vm.clearOnBroadcast) {
          $scope.$on(vm.clearOnBroadcast, function () {
            // Clear the form
            vm.data = {
              contractType: $scope.default || (vm.hasDefaultProgram ? 'Rewards Program' : null),
              leadPrice: null,
              successfulLeadPrice: null,
              subscriptionPrice: null,
              creditCard: {},
              rewardCode: null
            };
          });
        }

        vm.user = $scope.user;

        vm.isFormValid = false;
        vm.data = {
          contractType: $scope.default || (vm.hasDefaultProgram ? 'Rewards Program' : null),
          leadPrice: null,
          successfulLeadPrice: null,
          subscriptionPrice: null,
          creditCard: {},
          rewardCode: null
        };
        vm.rewardsProgramSetup = [];
        vm.rewardCodes = {};
        vm.selectedRewardCode = '';
        vm.selectedRewardCodeLabel = 'Reward Code';
        vm.isRewardsProgramDetailVisible = false;

        if (vm.hasCC) {
          vm.showCCForm = !vm.user.isCCSet;
        }

        // Setup the Reward selectors id, value, desc
        vm.selectors = {
          default: {
            id: 'rewardsProgram',
            value: 'Rewards Program',
            desc: 'Rewards Program'
          },
          code: {
            id: 'rewardCodeSelector',
            value: 'Reward Code',
            desc: 'Reward Code'
          },
          payPerLead: {
            id: 'payPerLead',
            value: 'Pay Per Lead',
            desc: 'Pay Per Lead'
          },
          subscription: {
            id: 'monthlySubscription',
            value: 'Subscription',
            desc: 'Monthly Subscription'
          },
          cancel: {
            id: 'cancelAgreement',
            value: 'Cancel',
            desc: 'Cancel Agreement'
          }
        };

        // Subscribe to the local update
        $scope.$on('event:user-updateLocalProfile', function () {
          if ($scope.user.rewardCodes) {
            vm.renderRewardCodes($scope.user.rewardCodes);
          }
          if (vm.hasDefaultProgram && !vm.hasLockedDefaultProgram && $scope.user.defaultRewardProgram) {
            vm.renderRewardProgramOptions($scope.user.defaultRewardProgram);
          }
        });

        // Render the reward codes.
        vm.renderRewardCodes = function (rewardCodes) {
          angular.forEach(rewardCodes, function (item) {
            var options = [];
            angular.forEach($filter('orderBy')(item.pricingOptions, 'successfulLeadPrice'), function (option) {
              options.push(option.name + ': ' + $filter('currency')(option.successfulLeadPrice, '$', 0));
            });
            vm.rewardCodes[item.rewardCode] = item.rewardCode + ' - ' + options.join(', ');
          });
        };
        if (vm.hasCode && $scope.user.rewardCodes) {
          vm.renderRewardCodes($scope.user.rewardCodes);
        }

        // Render reward program option
        vm.renderRewardProgramOptions = function (options) {
          if (options && options.length > 0) {
            angular.forEach(options, function (option) {
              vm.rewardsProgramSetup.push(option);
            });
          }
          else {
            vm.isRewardsProgramDetailVisible = true;
          }
        };
        if (vm.hasDefaultProgram && !vm.hasLockedDefaultProgram && $scope.user.defaultRewardProgram) {
          vm.renderRewardProgramOptions($scope.user.defaultRewardProgram);
        }

        vm.onContractTypeChange = function (value, form, evt) {
          var elementToFocus;
          if (vm.data.contractType === value) {
            return;
          }

          vm.data.contractType = value;
          if (vm.data.contractType === 'Pay Per Lead' || vm.data.contractType === 'Reward Code' || vm.data.contractType === 'Rewards Program') {
            delete form.$error.ccExp;
            delete form.$error.ccNumber;
            delete form.$error.ccNumberType;
            delete form.$error.ccExpMonth;
            delete form.$error.ccExpYear;
          }

          switch (vm.data.contractType) {
            case 'Pay Per Lead':
              elementToFocus = 'successfulLeadPrice';
              break;
            case 'Subscription':
              elementToFocus = 'subscriptionPrice';
              break;
            case 'Reward Code':
              elementToFocus = 'rewardCode';
              break;
            default:
              elementToFocus = null;
              break;
          }

          if (elementToFocus !== null) {
            $timeout(function () {
              angular.element($document[0].getElementById(elementToFocus))[0].focus();
            }, 200);
          }

          if (evt) {
            evt.preventDefault();
            evt.stopPropagation();
          }

          vm.onValueChanged();
        };

        vm.toggleRewardsProgramDetailVisibility = function () {
          vm.isRewardsProgramDetailVisible = !vm.isRewardsProgramDetailVisible;
        };

        vm.isValid = function () {
          var isValid = true;

          if (!vm.data.contractType) {
            isValid = false;
            return isValid;
          }

          if (vm.data.contractType === 'Rewards Program' && vm.hasUser && (!vm.rewardsProgramSetup || vm.rewardsProgramSetup.length === 0) ||
              vm.data.contractType === 'Reward Code' && !vm.data.rewardCode ||
              vm.data.contractType === 'Pay Per Lead' && !vm.data.successfulLeadPrice) {
            isValid = false;
          }
          // Do the CC separately
          if (vm.data.contractType === 'Subscription') {
            if (!vm.data.subscriptionPrice) {
              isValid = false;
            }
            else if (vm.showCCForm && (!vm.data.creditCard.holderName || !vm.data.creditCard.number || !vm.data.creditCard.expiryMonth || !vm.data.creditCard.expiryYear)) {
              isValid = false;
            }
          }
          return isValid;
        };

        vm.checkValidity = function () {
          // This is being run by the watcher in the dom (ngClass). It's here to check on the initial state of the form and will notify change callback only if the validity changes.
          var isValid = vm.isValid();
          if (vm.isFormValid !== isValid) {
            $scope.change({formData: vm.data, isFormValid: isValid});
            vm.isFormValid = isValid;
          }
        };

        vm.onValueChanged = function () {
          // This is being triggered by every change of any of the inputs. Will trigger the change callback each time.
          var isValid = vm.isValid();
          $scope.change({formData: vm.data, isFormValid: isValid});
        };
      }]
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name myMemberships.service:MyMemberships
   *
   * @description
   *
   */
  MyMemberships.$inject = ["$document", "$uibModal"];
  angular
    .module('myMemberships')
    .service('MyMemberships', MyMemberships);

  function MyMemberships($document, $uibModal) {
    var self = this;

    self.openAskForLeads = function (myProfile, userProfile) {
      var slModalInstance = $uibModal.open({
        animation: false,
        appendTo: angular.element($document[0].body),
        templateUrl: 'my-memberships/join-pod/join-pod-modal.tpl.html',
        controller: 'JoinPodModalCtrl',
        controllerAs: 'joinPod',
        size: 'md',
        resolve: {
          myProfile: function () {
            return myProfile;
          },
          userProfile: function () {
            return userProfile;
          }
        }
      });

      return slModalInstance.result;
    };
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('myMemberships')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('myMemberships', {
        url: '/my-memberships',
        templateUrl: 'my-memberships/my-memberships.tpl.html',
        controller: 'MyMembershipsCtrl',
        controllerAs: 'myMemberships',
        authenticate: true,
        resolve: {
          userProfile: ['Session', function (Session) {
            return Session.getUserProfile();
          }]
        }
      });
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name myMemberships.controller:MyMembershipsCtrl
   *
   * @description
   *
   */
  MyMembershipsCtrl.$inject = ["Notification", "userProfile", "BusinessAccount", "Auth", "MyMemberships"];
  angular
    .module('myMemberships')
    .controller('MyMembershipsCtrl', MyMembershipsCtrl);

  function MyMembershipsCtrl(Notification, userProfile, BusinessAccount, Auth, MyMemberships) {
    var vm = this;
    vm.isActivating = false;

    vm.tabManager = null;
    vm.tabsConfig = [
      {
        label: 'Connections',
        value: 'memberships',
        url: 'myMemberships.memberships'
      },
      {
        label: 'Received Leads',
        value: 'receivedLeads',
        url: 'myMemberships.receivedLeads'
      },
      {
        label: 'Reward Codes',
        value: 'rewardPrograms',
        url: 'myMemberships.rewardPrograms'
      },
      {
        label: 'Import',
        value: 'import',
        url: 'myMemberships.import',
        condition: function () {
          return vm.isImportAllowed();
        }
      }
    ];

    // Get the trial subscription data (if any)
    vm.trialData = BusinessAccount.getTrialData(userProfile);

    vm.isBusinessAccountActive = function () {
      return angular.isObject(userProfile) && angular.isDefined(userProfile.isBusinessAccountActive) && userProfile.isBusinessAccountActive === true;
    };

    vm.isImportAllowed = function () {
      return Auth.hasUserPermission(userProfile, 'importMemberships');
    };

    vm.activateBusinessAccount = function () {
      vm.isActivating = true;
      BusinessAccount.initiateActivation('my-memberships-ctrl-container', vm.trialData.isEligibleForTrial)
        .then(function (newUserProfile) {
          Notification({message: 'Your business account has been successfully activated.'}, 'confirmation');
          vm.isActivating = false;
          userProfile = newUserProfile;
        }, function () {
          vm.isActivating = false;
        });
    };

    vm.openAskForLeads = function () {
      var modalInstance = MyMemberships.openAskForLeads(userProfile, null);
      modalInstance.then(function (receiveLeads) {
        vm.receiveLeads = receiveLeads;
      });
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name mlpMarketplace
   * @description
   *
   */
  angular
    .module('mlpMarketplace', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('mlpMarketplace')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('marketplace', {
        url: '/marketplace/:id',
        templateUrl: 'mlp-marketplace/marketplace.tpl.html',
        controller: 'MarketplaceCtrl',
        controllerAs: 'marketplace',
        authenticate: true,
        params: {
          id: {
            value: null,
            squash: true
          }
        },
        resolve: {
          userProfile: ['Session', function (Session) {
            return Session.getUserProfile();
          }],
          marketplaceItem: ['$stateParams', 'Restangular', 'Auth', function ($stateParams, Restangular, Auth) {
            return Auth.isAuthenticated().then(function (isAuthenticated) {
              if (isAuthenticated) {
                // If no ID is provided, we will show all marketplace addons
                if (!$stateParams.id) {
                  return null;
                }
                // If ID is provided, then we will try to load the marketplace item. If not found, display a message
                return Restangular.one('Marketplace').one('index').get({id: $stateParams.id})
                  .then(function (addon) {
                    return addon.plain();
                  }, function () {
                    return false;
                  });
              }
              return null;
            }, function () {
              return null;
            });
          }]
        }
      });
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name mlpMarketplace.service:Marketplace
   * @requires $document
   * @requires $uibModal
   * @requires Restangular
   * @requires Notification
   * @requires Session
   * @description
   *
   */
  Marketplace.$inject = ["$rootScope", "$document", "$state", "$uibModal", "Restangular", "Notification", "Session"];
  angular
    .module('mlpMarketplace')
    .service('Marketplace', Marketplace);

  function Marketplace($rootScope, $document, $state, $uibModal, Restangular, Notification, Session) {
    var self = this;

    /**
     * Opens the modal dialog for the preferences settings
     *
     * @param {object} userProfile object User profile for which the preferences will be loaded
     */
    self.open = function () {
      $state.go('marketplace');
    };

    self.openInfo = function (item) {
      return $uibModal.open({
        animation: false,
        templateUrl: 'mlp-marketplace/marketplace-info-modal.tpl.html',
        controller: 'MarketplaceInfoCtrl',
        controllerAs: 'marketplaceInfo',
        size: 'lg',
        resolve: {
          marketplaceItem: function () {
            return item;
          }
        },
        appendTo: angular.element($document[0].body)
      }).result;
    };

    self.deactivate = function (item) {
      return Restangular.one('Marketplace').customDELETE('deactivate', {
        id: item.id
      });
    };

    /**
     * @ngdoc function
     * @name initiateActivation
     * @methodOf mlpMarketplace.service:Marketplace
     * @param {Object} item Marketplace item
     *
     * @returns {Promise} Modal dialog result
     */
    self.initiateActivation = function (item) {
      var modalInstance = $uibModal.open({
        animation: false,
        appendTo: angular.element($document[0].body),
        templateUrl: 'mlp-marketplace/marketplace-activation-modal.tpl.html',
        controller: 'MarketplaceActivationCtrl',
        controllerAs: 'marketplaceActivation',
        size: 'lg',
        backdrop: 'static',
        resolve: {
          marketplaceItem: function () {
            return item;
          }
        }
      });

      return modalInstance.result;
    };

    /**
     * @ngdoc function
     * @name activate
     * @methodOf mlpMarketplace.service:Marketplace
     * @param {Object} item Marketplace item
     * @param {Object} creditCard CC data
     * @returns {Promise} Restangular promise
     */
    self.activate = function (item, creditCard) {
      return Restangular.one('Marketplace').customPOST({
        id: item.id,
        creditCard: creditCard
      }, 'activate')
        .then(function (result) {
          var profile = Session.updateLocalUserProfile({
            marketplace: result.plain().marketplace
          }, true);
          Notification({message: 'Success'}, 'success');
          $rootScope.$broadcast('event:user-updateProfile', {profile: profile});
        });
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name mlpMarketplace.directive:MarketplaceListings
   * @requires $timeout
   * @requires $uibModalInstance
   * @requires userProfile
   * @requires components.ui.PromptDialog
   * @requires components.FormAlerts
   * @requires mlpMarketplace.Marketplace
   * @description
   *
   */
  angular
    .module('mlpMarketplace')
    .directive('marketplaceListings', marketplaceListings);

  function marketplaceListings() {
    return {
      restrict: 'E',
      scope: {
        user: '=',
        items: '=',
        hasUserAvatar: '='
      },

      replace: true,
      transclude: false,
      templateUrl: 'mlp-marketplace/marketplace-listings-directive.tpl.html',
      controllerAs: 'marketplace',
      controller: ['$scope', '$timeout', 'PromptDialog', 'FormAlerts', 'Marketplace', function ($scope, $timeout, PromptDialog, FormAlerts, Marketplace) {
        var vm = this;
        vm.userProfile = $scope.user;
        vm.hasUserAvatar = $scope.hasUserAvatar || false;

        vm.items = $scope.items || vm.userProfile.marketplace;
        vm.isLoading = [];
        vm.isProcessing = false;
        vm.alerts = new FormAlerts();

        vm.switchSubscription = function ($index) {
          if (vm.isLoading[$index]) {
            return;
          }
          if (vm.items[$index].isSubscribed) {
            vm.deactivate($index);
          }
          else {
            vm.activate($index);
          }
        };

        vm.activate = function ($index) {
          var item = vm.items[$index];
          if (!item.isSubscribed) {
            vm.isLoading[$index] = true;
            vm.isProcessing = true;
            Marketplace.initiateActivation(vm.items[$index])
              .then(function (result) {
                if (result.active) {
                  item.isSubscribed = true;
                }
                vm.isLoading[$index] = false;
                vm.isProcessing = false;
              }, function () {
                vm.isLoading[$index] = false;
                vm.isProcessing = false;
              });
          }
        };

        vm.deactivate = function ($index) {
          var item = vm.items[$index];
          if (item.isSubscribed) {
            vm.alerts.reset();
            PromptDialog.open('By clicking Deactivate, you will immediately cancel your subscription', null, 'Deactivate', 'Cancel')
              .then(function () {
                vm.isLoading[$index] = true;
                vm.isProcessing = true;
                Marketplace.deactivate(item)
                  .then(function () {
                    item.isSubscribed = false;
                    vm.isLoading[$index] = false;
                    vm.isProcessing = false;
                  }, function () {
                    vm.alerts.add('danger', 'Unable to deactivate subscription.');
                    vm.isLoading[$index] = false;
                    vm.isProcessing = false;
                  });
              });
          }
        };

        vm.openInfo = function ($index) {
          Marketplace.openInfo(vm.items[$index]);
        };
      }]
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name mlpMarketplace.controller:MarketplaceInfoCtrl
   *
   * @description
   *
   */
  MarketplaceInfoCtrl.$inject = ["$scope", "$sce", "$uibModalInstance", "marketplaceItem", "Restangular", "PromptDialog", "Marketplace", "Notification"];
  angular
    .module('mlpMarketplace')
    .controller('MarketplaceInfoCtrl', MarketplaceInfoCtrl);

  function MarketplaceInfoCtrl($scope, $sce, $uibModalInstance, marketplaceItem, Restangular, PromptDialog, Marketplace, Notification) {
    var vm = this;
    vm.item = marketplaceItem;
    vm.isLoading = false;
    vm.isUpdating = false;

    if (!marketplaceItem.info) {
      vm.isLoading = true;

      Restangular.one('Marketplace').one('index').get({id: marketplaceItem.id})
        .then(function (result) {
          marketplaceItem.info = $sce.trustAsHtml(result.info);
          vm.isLoading = false;
        }, function () {
          vm.isLoading = false;
        });
    }

    // Listen to the broadcast of 'close-modal-instance'
    $scope.$on('close-modal-instance', function () {
      $uibModalInstance.dismiss();
    });

    vm.close = function () {
      $uibModalInstance.dismiss();
    };

    vm.switchSubscription = function () {
      if (vm.isUpdating) {
        return;
      }
      if (vm.item.isSubscribed) {
        vm.deactivate();
      }
      else {
        vm.activate();
      }
    };

    vm.activate = function () {
      var item = vm.item;
      if (!item.isSubscribed) {
        vm.isUpdating = true;
        Marketplace.initiateActivation(item)
          .then(function (result) {
            if (result.active) {
              item.isSubscribed = true;
            }
            vm.isUpdating = false;
          }, function () {
            vm.isUpdating = false;
          });
      }
    };

    vm.deactivate = function () {
      var item = vm.item;
      if (item.isSubscribed) {
        PromptDialog.open('By clicking Deactivate, you will immediately cancel your subscription', null, 'Deactivate', 'Cancel')
          .then(function () {
            vm.isUpdating = true;
            vm.isProcessing = true;
            Marketplace.deactivate(item)
              .then(function () {
                Notification({message: 'Success'}, 'confirmation');
                item.isSubscribed = false;
                vm.isUpdating = false;
              }, function () {
                Notification({message: 'Unable to unsubscribe.'}, 'warning');
                vm.isUpdating = false;
              });
          });
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name mlpMarketplace.controller:MarketplaceCtrl
   * @requires $timeout
   * @requires $uibModalInstance
   * @requires userProfile
   * @requires components.ui.PromptDialog
   * @requires components.FormAlerts
   * @requires mlpMarketplace.Marketplace
   * @description
   *
   */
  MarketplaceCtrl.$inject = ["Marketplace", "userProfile", "marketplaceItem", "Notification"];
  angular
    .module('mlpMarketplace')
    .controller('MarketplaceCtrl', MarketplaceCtrl);

  function MarketplaceCtrl(Marketplace, userProfile, marketplaceItem, Notification) {
    var vm = this,
        items = userProfile.marketplace,
        mlpFilter;

    vm.isGrid = true;
    vm.viewMLP = true;
    vm.userProfile = userProfile;

    // MLP Items have priority
    mlpFilter = _.filter(items, function (item) {
      return angular.isObject(item.owner) && ((/cs(\+([\s\S]*?))?@myleadpod\.com/).test(item.owner.email) || item.owner.email === 'sales@myleadpod.com');
    });
    vm.mlpItems = _.sortBy(mlpFilter, 'label');

    // Other Marketplace items
    vm.items = _.filter(items, function (item) {
      return !angular.isObject(item.owner) || !(/cs(\+([\s\S]*?))?@myleadpod\.com/).test(item.owner.email) && item.owner.email !== 'sales@myleadpod.com';
    });

    // Check if we are opening the whole marketplace or details of the addon directly
    if (angular.isObject(marketplaceItem)) {
      // Seems like we have the addon object. open the details
      Marketplace.openInfo(marketplaceItem);
    }
    else if (marketplaceItem === false) {
      // Display notification that the marketplace addon doesn't exist
      Notification({message: 'The requested Marketplace Item is invalid or it doesn\'t exist.', duration: 5000}, 'warning');
    }
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name mlpMarketplace.controller:MarketplaceInfoCtrl
   * @requires $uibModelInstance
   * @requires FormAlerts
   * @requires marketplaceItem
   * @requires Restangular
   * @requires mlpMarketplace.Marketplace
   * @description
   *
   */
  MarketplaceActivationCtrl.$inject = ["$timeout", "$uibModalInstance", "FormAlerts", "marketplaceItem", "Restangular", "Marketplace"];
  angular
    .module('mlpMarketplace')
    .controller('MarketplaceActivationCtrl', MarketplaceActivationCtrl);

  function MarketplaceActivationCtrl($timeout, $uibModalInstance, FormAlerts, marketplaceItem, Restangular, Marketplace) {
    var vm = this;
    vm.alerts = new FormAlerts();
    vm.item = marketplaceItem;
    vm.isPaymentRequired = !vm.item.isFree;
    vm.isLoading = false;
    vm.isCCLoading = false;
    vm.creditCard = null;
    vm.data = {
      creditCard: {}
    };

    vm.paymentSummary = {
      price: vm.item.priceMonthly,
      tax: vm.item.priceMonthlyInclGst - vm.item.priceMonthly,
      total: vm.item.priceMonthlyInclGst
    };

    if (vm.isPaymentRequired) {
      vm.isCCLoading = true;
      Restangular.one('Billing').one('creditCard').get()
        .then(function (cc) {
          cc = cc.plain();
          vm.data.creditCard.method = angular.isObject(cc) && angular.isDefined(cc.number) ? 'old' : 'new';
          vm.creditCard = cc;
          $timeout(function () {
            vm.isCCLoading = false;
          }, 300);
        }, function () {
          vm.isCCLoading = false;
        });
    }

    vm.cancel = function () {
      $uibModalInstance.dismiss();
    };

    vm.activate = function () {
      vm.isLoading = true;
      vm.alerts.reset();
      Marketplace.activate(vm.item, vm.data.creditCard)
        .then(function () {
          vm.isLoading = false;
          $uibModalInstance.close({active: true});
        }, function () {
          vm.alerts.add('danger', 'Unable to activate subscription.');
          vm.isLoading = false;
        });
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name mlp-messaging
   * @description
   *
   */
  angular
    .module('mlp-messaging', []);
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name lead.service:Leads
   *
   * @description
   *
   */
  Messaging.$inject = ["$rootScope", "$document", "$state", "$q", "Notification", "$uibModal", "Restangular"];
  angular
    .module('mlp-messaging')
    .service('Messaging', Messaging);

  function Messaging($rootScope, $document, $state, $q, Notification, $uibModal, Restangular) {
    var self = this;

    self.getMessages = function (params) {
      return Restangular.one('Messages').one('index').get(params)
        .then(function (result) {
          return result.plain();
        }, function (error) {
          return {
            error: error
          };
        });
    };

    self.postMessage = function (idUser, params) {
      return Restangular.one('Messages').post('index', params, {idUser: idUser})
        .then(function (result) {
          return result.plain();
        }, function (error) {
          return {
            error: error
          };
        });
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name mlpMessaging.directive:messagingFormDirective
   * @restrict EA
   * @element
   *
   */
  messagingForm.$inject = ["$timeout"];
  angular
    .module('mlp-messaging')
    .directive('messagingForm', messagingForm);

  function messagingForm($timeout) {
    return {
      restrict: 'EA',
      scope: {
        msgId: '=?',
        msgRequestParams: '=?',
        msgRecipient: '=?',
        msgGrouping: '=?',
        msgWatchParams: '=?',
        msgLoadCallback: '='
      },
      replace: true,
      templateUrl: 'messaging/messaging-form-directive.tpl.html',
      controllerAs: 'msg',
      link: {
        post: function (scope, elem, attrs, ctrl) {
          // Check the scroll of the element containing the message so we could pull more.
          $timeout(function () {
            var messagesContainer = elem.find('.messages-container.has-data')[0];
            messagesContainer.onscroll = function () {
              if (messagesContainer.scrollTop === 0 && !ctrl.loading && ctrl.lastDataCount >= ctrl.limit) {
                // get new data
                ctrl.loadMore();
              }
            };
          }, 10);
        }
      },
      controller: ['$scope', '$document', '$interval', 'Session', 'Messaging', 'configOptions', function ($scope, $document, $interval, Session, Messaging) {
        var vm = this,
            messageIntervalUpdate = null;

        vm.sender = null;
        vm.recipient = $scope.msgRecipient;
        vm.grouping = $scope.msgGrouping;
        vm.watchParams = $scope.msgWatchParams;
        vm.onLoadCallback = $scope.msgLoadCallback;
        vm.id = $scope.msgId ? $scope.msgId : 'msg';

        vm.messageStr = null;
        vm.messages = [];
        vm.loading = false;
        vm.hasError = false;
        vm.requestParams = $scope.msgRequestParams ? angular.copy($scope.msgRequestParams) : {};
        vm.postParams = {
          message: null
        };
        vm.notification = null;
        vm.lastDataCount = 0;
        vm.limit = 20;
        vm.offset = 0;
        vm.offsetIncrement = 20;
        vm.appendMessages = false;
        vm.savingMessages = {};

        if (!vm.recipient) {
          vm.hasError = true;
          vm.notification = 'Unknown recipient';
          return;
        }

        // Check the scroll of the element containing the message so we could pull more.
        // console.log(vm.element);

        // Mixin the value from requestParam into the postParam so we have the correct object to send.
        if (vm.requestParams) {
          angular.forEach(vm.requestParams, function (value, key) {
            vm.postParams[key] = value;
          });
        }

        vm.loadMessages = function (append) {
          // For UI
          vm.loading = true;
          vm.hasError = false;
          vm.appendMessages = append || false;

          // Update the request param object
          vm.requestParams.limit = vm.limit;
          vm.requestParams.offset = vm.offset;

          // Get the messages
          Messaging.getMessages(vm.requestParams).then(vm.formatMessages);
        };

        vm.formatMessages = function (data) {
          $timeout(function () {
            vm.loading = false;
            vm.appendMessages = false;
            if (vm.onLoadCallback) {
              vm.onLoadCallback.apply();
            }
          }, 100);

          if (data && data.error) {
            vm.hasError = true;
            return;
          }
          if (data && data.records && data.records.length > 0) {
            // Try to reformat the messages
            angular.forEach(data.records, function (record) {
              record.message = record.message.replace(/(\r)?\n/g, '<br/>');
            });

            vm.lastDataCount = data.records.length;
            if (vm.appendMessages) {
              vm.messages = vm.messages.concat(data.records);
            }
            else {
              vm.messages = data.records;
            }
          }
          else if (data && data.records && data.records.length === 0) {
            vm.messages = [];
            vm.lastDataCount = 0;
          }
          else {
            vm.lastDataCount = 0;
          }
        };

        vm.addMessage = function (message, isTemp) {
          vm.saving = false;
          if (message.error) {
            vm.notification = 'Unable to send the message. Try again.';
            return;
          }
          if (isTemp) {
            vm.savingMessages[message.dateCreated] = message;
          }
          // Sending the message was successful. Clear the textbox
          vm.messageStr = null;
          message.created = vm.getFormattedTime((new Date()).getTime() - (new Date(message.dateCreated)).getTime());
          message.message = message.message.replace(/(\r)?\n/g, '<br/>');
          vm.messages.unshift(message);
        };

        vm.messageSaved = function (message, timeStamp) {
          delete vm.savingMessages[timeStamp];
        };

        vm.reload = function () {
          vm.offset = 0;
          vm.loadMessages();
        };

        vm.loadMore = function () {
          vm.offset += vm.offsetIncrement;
          vm.loadMessages(true);
        };

        vm.send = function () {
          var timeStamp = (new Date()).getTime();
          if (vm.messageStr) {
            vm.saving = true;
            vm.notification = null;
            vm.postParams.message = vm.messageStr;
            vm.addMessage({
              message: vm.messageStr,
              dateCreated: timeStamp
            }, true);
            Messaging.postMessage(vm.recipient.idUser, vm.postParams).then(function (message) {
              vm.messageSaved(message, timeStamp);
            });
          }
        };

        vm.updateMessageTimes = function () {
          var now = (new Date()).getTime();
          angular.forEach(vm.messages, function (message) {
            message.created = vm.getFormattedTime(now - (new Date(message.dateCreated)).getTime());
          });
        };

        vm.getFormattedTime = function (timegap) {
          var interval = Math.abs(timegap) / 1000,
              granularity = 1,
              zeroInterval = 'now',
              suffix = '',
              short = false,
              units = null,
              output = '';

          units = [
            {seconds: 31536000, singular: 'year', plural: 'years', short: 'y'},
            {seconds: 2592000, singular: 'month', plural: 'months', short: 'm'},
            {seconds: 604800, singular: 'week', plural: 'weeks', short: 'w'},
            {seconds: 86400, singular: 'day', plural: 'days', short: 'd'},
            {seconds: 3600, singular: 'hour', plural: 'hours', short: 'h'},
            {seconds: 60, singular: 'min', plural: 'min', short: 'm'}
          ];

          angular.forEach(units, function (unit) {
            var count = null;
            if (granularity === 0) {
              return;
            }
            if (interval >= unit.seconds) {
              count = Math.floor(interval / unit.seconds);
              output += (output ? ' ' : '') + count;

              if (short) {
                output += unit.short;
              }
              else {
                output += ' ' + (count > 1 ? unit.plural : unit.singular);
              }

              interval %= unit.seconds;
              granularity--;
            }
          });

          return output ? output + (suffix === '' ? '' : ' ' + suffix) : zeroInterval;
        };

        vm.itemRender = function (item, index) {
          var currentGroup,
              lastGroup,
              nextGroup;

          if (!vm.grouping || !item) {
            return;
          }

          currentGroup = item.groupId;
          lastGroup = vm.messages[index - 1] ? vm.messages[index - 1] : null;
          nextGroup = vm.messages[index + 1] ? vm.messages[index + 1] : null;

          item.opensGroup = currentGroup !== lastGroup;
          item.closesGroup = currentGroup !== nextGroup;
          item.noGroup = currentGroup === null;
        };

        Session.getUserProfile().then(function (user) {
          vm.user = user;
          vm.requestParams.idUser = vm.recipient.idUser;
          if (angular.isDefined(vm.recipient)) {
            vm.loadMessages();
          }
        });

        // we are now all set. Now watch for the msgParameter change so we can update the content.
        if (vm.watchParams) {
          $scope.$watch('msgRequestParams', function (oldVal, newVal) {
            if (oldVal !== newVal) {
              vm.requestParams = $scope.msgRequestParams ? angular.copy($scope.msgRequestParams) : {};
              vm.requestParams.idUser = vm.recipient.idUser;
              if (vm.requestParams) {
                angular.forEach(vm.requestParams, function (value, key) {
                  vm.postParams[key] = value;
                });
              }
              vm.loadMessages();
            }
            else if (vm.onLoadCallback) {
              vm.onLoadCallback.apply();
            }
          });
        }

        // Setup time formatting interval
        messageIntervalUpdate = $interval(vm.updateMessageTimes, 60000);
        // Clear the message interval upon destroy
        $scope.$on('$destroy', function () {
          $interval.cancel(messageIntervalUpdate);
        });
      }]
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name mlp-charity
   * @description
   *
   */
  angular
    .module('mlp-charity', [
      'ng-drag-scroll'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name charity.controller:DonateCtrl
   *
   * @description
   *
   */
  DonateCtrl.$inject = ["$rootScope", "$timeout", "$q", "$uibModalInstance", "Notification", "Restangular", "userProfile", "charityProfile", "donationsHistory"];
  angular
    .module('mlp-charity')
    .controller('DonateCtrl', DonateCtrl);

  function DonateCtrl($rootScope, $timeout, $q, $uibModalInstance, Notification, Restangular, userProfile, charityProfile, donationsHistory) {
    var vm = this;

    vm.isLoading = false;
    vm.isCCLoading = true;
    vm.isLoadingCharity = false;
    vm.isCalculating = false;
    vm.hasError = false;
    vm.userProfile = userProfile;
    vm.charityProfile = charityProfile;
    vm.creditCard = null;
    vm.isSearchVisible = !charityProfile;
    vm.canceler = null;

    vm.lastCharities = donationsHistory;

    // For Search Autocomplete
    vm.searchCategories = [
      {name: 'Charities', value: 'charities', stateTarget: 'user', iconClass: 'fa-heart-o'}
    ];

    // For posting the data
    vm.data = {
      paymentDetails: {
        method: null
      },
      idUserRecipient: charityProfile ? charityProfile.idUser : 0,
      amount: null
    };

    // For payment summary
    vm.summary = {
      fee: null,
      tax: null,
      total: null
    };

    // Load the Credit Card data
    Restangular.one('Billing').one('creditCard').get()
      .then(function (cc) {
        cc = cc.plain();
        vm.data.paymentDetails.method = angular.isObject(cc) && angular.isDefined(cc.number) ? 'old' : 'new';
        vm.creditCard = cc;
        $timeout(function () {
          vm.isCCLoading = false;
        }, 300);
      }, function () {
        vm.isCCLoading = false;
      });

    /**
     * Dismisses the modal dialog
     */
    vm.cancel = function () {
      $uibModalInstance.dismiss();
    };

    /**
     * Sets the charityProfile and populates the title and image in the chosen charity container
     * @param {Object} item Item to pre-fill with
     */
    vm.onSearchItemSelected = function (item) {
      // Callback on when the user selected the search item.
      if (!item || !item.originalObject || angular.isString(item.originalObject)) {
        return;
      }
      vm.isLoadingCharity = true;
      Restangular.one('Users').one('profile').get({id: item.originalObject.id})
        .then(function (profile) {
          vm.isLoadingCharity = false;
          profile = profile.plain();
          // Pre-fill the data now.
          vm.charityProfile = profile;
          vm.isSearchVisible = false;
        });
    };

    /**
     * Formats the search response
     *
     * @param {Object} response Response object
     * @returns {Object} Formatted response
     */
    vm.searchResponseFormatter = function (response) {
      var r,
          records;

      if (angular.isUndefined(response) || !response) {
        return null;
      }

      r = response.records;
      records = _.map(r, function (item) {
        var category = _.find(vm.searchCategories, {value: item.datasource}),
            returnObject = null,
            categoryDescriptionClass,
            value = item.fullname;

        categoryDescriptionClass = category.iconClass || 'fa-user';
        if (item.leadtype) {
          categoryDescriptionClass += item.leadtype === 'received' ? ' rotate-90' : '';
          value += ' | ' + item.leadfullname;
        }
        else if (item.companyname && item.companyname !== item.fullname) {
          value += ' | ' + item.companyname;
        }

        returnObject = {
          value: value,
          id: item.id,
          category: item.datasource,
          categoryDescription: '<i class="fa ' + categoryDescriptionClass + '"></i>',
          timeDescription: item.datecreatedformatted ? item.datecreatedformatted : '',
          leadtype: item.leadtype
        };

        return returnObject;
      });

      return records;
    };

    /**
     * Make the donation payment
     */
    vm.donate = function () {
      // Make sure the user recipient is correct
      vm.data.idUserRecipient = vm.charityProfile.idUser;
      vm.isLoading = true;

      // Do the request
      Restangular.one('Donations').post('donate', vm.data)
        .then(function () {
          $uibModalInstance.close();
          vm.isLoading = false;
          Notification({message: 'Success'}, 'confirmation');
        }, function (responseData) {
          vm.isLoading = false;
          Notification({message: 'Donation Payment Failed'}, 'error');
          if (responseData.data.errorCode === 701 || responseData.data.errorCode === 702 || responseData.data.errorCode === 703) {
            console.error('Unable to send: ' + responseData.data.errorMessage);
          }
          else {
            console.error('danger', 'Unable to send, try again later please');
          }
        });
    };

    /**
     * Sets the incoming profile as the destination / target charity profile
     * @param {object} profile Charity profile receiving the donation
     */
    vm.selectCharity = function (profile) {
      if (vm.charityProfile === profile) {
        return;
      }
      vm.charityProfile = profile;
      vm.isSearchVisible = false;
    };

    /**
     * Sets the timer for the search to avoid the unnecessary requests. Once given a go it sends the request to server.
     * @param {boolean} noTimer Indicates whether the trigger of the search should wait or fire immediately.
     */
    vm.calculate = function (noTimer) {
      if (!vm.data.amount) {
        return;
      }

      if (noTimer === true) {
        vm.sendCalculate(vm.data.amount);
        return;
      }

      if (vm.searchTimer) {
        $timeout.cancel(vm.searchTimer);
      }

      vm.isCalculating = true;
      vm.searchTimer = $timeout(function () {
        vm.sendCalculate(vm.data.amount);
      }, 500);
    };

    /**
     * Send the request to get the payment summary
     * @param {int} value Value to get the summary calculated for
     */
    vm.sendCalculate = function (value) {
      if (vm.isCalculating && vm.canceler) {
        vm.canceler.resolve();
      }

      vm.hasError = false;
      vm.isCalculating = true;

      vm.canceler = $q.defer();
      vm.canceler.value = value;
      Restangular.one('Donations').withHttpConfig({timeout: vm.canceler.promise}).one('calculateDonationTotal').get({
        amount: vm.data.amount
      }).then(function (result) {
        var data = result.plain();

        if (vm.data.amount !== value) {
          // This will prevent the undesired effect of quickly changing the text in the summary
          // since the canceller doesn't work
          return;
        }
        vm.isCalculating = false;
        try {
          vm.summary.total = Number(data.total);
          vm.summary.fee = Number(data.fees);
          vm.summary.tax = Number(data.tax);
          vm.hasError = false;
        }
        catch (err) {
          vm.hasError = true;
        }
      }, function () {
        vm.isCalculating = false;
        vm.hasError = true;
      });
    };

    /**
     * Immediately sends the summary request for the provided value
     * @param {int} value Value to calculate the summary for.
     */
    vm.calculateWith = function (value) {
      if (value && angular.isNumber(value)) {
        vm.data.amount = value;
        vm.calculate(true);
      }
    };

    /**
     * On ngKeyPress event handler for the donation amount input.
     * @param {object} $event ngKeyPress event
     */
    vm.onKeyPress = function ($event) {
      if ($event.key === 'Enter') {
        vm.calculate(true);
      }
    };

    vm.openSelector = function () {
      vm.isSearchVisible = true;
    };

    vm.checkScroll = function () {
      $timeout(function () {
        $rootScope.$broadcast('event:check-scroll');
      }, 100);
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name mlp-charity.service:Charities
   *
   * @description
   *
   */
  Charities.$inject = ["$rootScope", "$document", "$state", "$q", "Notification", "$uibModal"];
  angular
    .module('mlp-charity')
    .service('Charities', Charities);

  function Charities($rootScope, $document, $state, $q, Notification, $uibModal) {
    var self = this;

    /**
     * Opens the Modal dialog for donation to charity
     * @param {int} charityId Can be a charity Id (number) or directly a userProfile
     * @returns {*} Modal instance result
     */
    self.openDonate = function (charityId) {
      var modalInstance,
          params = {
            animation: false,
            templateUrl: 'mlp-charity/donate-modal.tpl.html',
            controller: 'DonateCtrl',
            controllerAs: 'donation',
            size: 'lg',
            resolve: {
              userProfile: ['Session', function (Session) {
                return Session.getUserProfile();
              }],
              charityProfile: ['Restangular', function (Restangular) {
                if (angular.isObject(charityId) && charityId.isCharityAccountActive) {
                  return charityId;
                }

                if (angular.isNumber(charityId)) {
                  return Restangular.one('Users').one('profile').get({id: charityId})
                    .then(function (profile) {
                      return profile;
                    });
                }

                return null;
              }],
              donationsHistory: ['Restangular', function (Restangular) {
                return Restangular.one('Donations').getList('lastRecipients')
                  .then(function (lastRecipients) {
                    return lastRecipients.plain();
                  });
              }]
            }
          };

      params.appendTo = angular.element($document[0].body);
      modalInstance = $uibModal.open(params);
      return modalInstance.result;
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name lead.controller:SendLeadCtrl
   *
   * @description
   *
   */
  SendLeadCtrl.$inject = ["$rootScope", "$scope", "$document", "$timeout", "$state", "$stateParams", "Session", "Restangular", "Notification", "userProfile", "isRegister", "onCancelCallback", "onSentCallback"];
  angular
    .module('lead')
    .controller('SendLeadCtrl', SendLeadCtrl);

  function SendLeadCtrl($rootScope, $scope, $document, $timeout, $state, $stateParams, Session, Restangular, Notification,
                        userProfile, isRegister, onCancelCallback, onSentCallback) {
    var vm = this;

    vm.isLoading = false;
    vm.validateRecipient = false;
    vm.searchName = '';
    vm.idIndustry = '';
    vm.selectedUsers = [];
    vm.isRegister = isRegister;
    vm.detailsVisible = false;
    vm.controlName = vm.isRegister ? 'register-lead' : 'send-lead';
    if (!vm.isRegister) {
      vm.isRewardsFormValid = true;
    }

    vm.emptyResults = false;
    vm.showPrompt = false;
    vm.searchCategories = [
      {name: 'All', value: 'all'},
      {name: 'My Pod', value: 'myPod', iconClass: 'fa-user'},
      {name: 'Directory', value: 'businessUsers', iconClass: 'fa-briefcase'},
      {name: 'Charities', value: 'charities', stateTarget: 'user', iconClass: 'fa-heart-o'},
      {name: 'Leads', value: 'leads', iconClass: 'fa-paper-plane'}
    ];
    vm.userSearchCategories = [];

    if (isRegister) {
      vm.userSearchCategories = [
        {name: 'My Connections', value: 'myConnections', iconClass: 'fa-user-o'}
      ];
    }
    else {
      vm.userSearchCategories = [
        {name: 'My Pod', value: 'myPod', iconClass: 'fa-user'}
      ];
    }

    vm.selectors = {
      payPerLead: {
        id: 'payPerLead',
        value: 'Pay Per Lead',
        desc: 'Pay Per Lead'
      },
      subscription: {
        id: 'monthlySubscription',
        value: 'Subscription',
        desc: 'Monthly Subscription'
      }
    };

    Session.getUserProfile().then(function (profile) {
      if (profile) {
        vm.user = profile;
        if (vm.user.isBusinessAccountActive) {
          vm.searchCategories.push({name: 'My Connections', value: 'myConnections', iconClass: 'fa-user-o'});
        }
      }
    });

    vm.data = {
      invitation: {}
    };
    vm.invitations = [];

    // If the userProfile is not undefined, this is coming from the send-lead-modal where the user to which the lead is about to be sent is already known.
    if (angular.isDefined(userProfile)) {
      vm.selectedUser = userProfile;
    }
    else if (angular.isDefined($stateParams.user) && angular.isObject($stateParams.user)) {
      vm.selectedUsers.push($stateParams.user);
    }

    if (angular.isDefined($stateParams.lead) && angular.isObject($stateParams.lead)) {
      vm.data.firstName = $stateParams.lead.firstName;
      vm.data.lastName = $stateParams.lead.lastName;
      vm.data.email = $stateParams.lead.email;
      vm.data.phone = $stateParams.lead.phone;
      vm.data.details = $stateParams.lead.details;
    }

    vm.isValidName = function () {
      return !!vm.data.firstName || !!vm.data.lastName;
    };

    vm.isValidContact = function () {
      return !!vm.data.email || !!vm.data.phone;
    };

    vm.removeUser = function ($index) {
      vm.selectedUsers.splice($index, 1);
    };

    vm.isEmptyNewRecipient = function () {
      var isEmpty = true;

      if (angular.isDefined(vm.data.invitation)) {
        if (angular.isDefined(vm.data.invitation.firstName) && !!vm.data.invitation.firstName) {
          isEmpty = false;
        }
        if (angular.isDefined(vm.data.invitation.email) && !!vm.data.invitation.email) {
          isEmpty = false;
        }
        if (angular.isDefined(vm.data.invitation.contractType) && !!vm.data.invitation.contractType) {
          isEmpty = false;
        }
        if (vm.data.invitation.contractType === 'Pay Per Lead') {
          if (angular.isDefined(vm.data.invitation.successfulLeadPrice) && !isNaN(parseFloat(vm.data.invitation.successfulLeadPrice)) && parseFloat(vm.data.invitation.successfulLeadPrice) >= 0) {
            isEmpty = false;
          }
        }
        if (vm.data.invitation.contractType === 'Subscription') {
          if (angular.isDefined(vm.data.invitation.subscriptionPrice) && !isNaN(parseFloat(vm.data.invitation.subscriptionPrice)) && parseFloat(vm.data.invitation.subscriptionPrice) >= 0) {
            isEmpty = false;
          }
        }
      }

      return isEmpty;
    };

    vm.isValidNewRecipient = function () {
      var isValid = true;

      if (!vm.isEmptyNewRecipient()) {
        if (angular.isUndefined(vm.data.invitation.firstName) || !vm.data.invitation.firstName) {
          isValid = false;
        }
        if (angular.isUndefined(vm.data.invitation.email) || !vm.data.invitation.email) {
          isValid = false;
        }
        if (vm.isRegister && (angular.isUndefined(vm.data.invitation.contractType) || !vm.data.invitation.contractType)) {
          isValid = false;
        }

        if (vm.data.invitation.contractType === 'Pay Per Lead') {
          if (angular.isUndefined(vm.data.invitation.successfulLeadPrice) || isNaN(parseFloat(vm.data.invitation.successfulLeadPrice)) || parseFloat(vm.data.invitation.successfulLeadPrice) < 0) {
            isValid = false;
          }
        }
        if (vm.data.invitation.contractType === 'Subscription') {
          if (angular.isUndefined(vm.data.invitation.subscriptionPrice) || isNaN(parseFloat(vm.data.invitation.subscriptionPrice)) || parseFloat(vm.data.invitation.subscriptionPrice) < 0) {
            isValid = false;
          }
        }
      }

      return isValid;
    };

    vm.isValidRecipient = function () {
      return !vm.isEmptyNewRecipient() && vm.isValidNewRecipient() || (vm.selectedUser || vm.selectedUsers.length > 0);
    };

    vm.send = function () {
      var data,
          recipientUserIds = [];
      vm.validateRecipient = true;

      if (vm.selectedUser) {
        vm.selectedUsers = [vm.selectedUser];
      }

      // Extract the userids from the selected users
      angular.forEach(vm.selectedUsers, function (user) {
        recipientUserIds.push(user.id);
      });

      // Get the new invitees array into the 'invitation' parameter
      if (!vm.invitations || vm.invitations.length === 0) {
        delete vm.data.invitation;
      }
      else {
        // Bastardise the original 'invitation' parameter to include the array of new invitations.
        vm.data.invitation = _.clone(vm.invitations);
      }

      // Finally get the post data object
      data = angular.extend({}, vm.data, {recipientUserIds: recipientUserIds});

      vm.isLoading = true;
      Restangular.one('Leads').post('index', data)
        .then(function (result) {
          var failedUsers = [],
              failedUsersIds = [];
          vm.data.invitation = {};
          if (!result.success) {
            angular.forEach(result.failedUsers, function (user) {
              failedUsersIds.push(user.id);
              Notification({message: 'Unable to send lead to ' + user.fullName}, 'error');
            });
            failedUsers = _.remove(vm.selectedUsers, function (u) {
              return failedUsersIds.indexOf(u.id) > -1;
            });
            vm.selectedUsers = failedUsers;
            vm.isLoading = false;
          }
          else {
            $rootScope.$broadcast('event:lead-sent');
            if (vm.selectedUser && onSentCallback) {
              onSentCallback.apply();
            }
            else {
              $state.go('myPod.sentLeads');
            }
            Notification({message: 'Success'}, 'success');
          }
        }, function () {
          vm.isLoading = false;
          Notification({message: 'Unable to send'}, 'error');
        });
    };

    vm.register = function () {
      var data;
      vm.validateRecipient = true;
      if (vm.isValidRecipient()) {
        if (vm.selectedUser) {
          vm.selectedUsers = [vm.selectedUser];
        }
        data = angular.extend({}, vm.data, {
          senderUserId: vm.selectedUsers.length === 1 ? vm.selectedUsers[0].id : 0
        });
        if (vm.isEmptyNewRecipient()) {
          delete vm.data.invitation;
        }
        vm.isLoading = true;
        Restangular.one('Leads').post('register', data)
          .then(function (result) {
            vm.data.invitation = {};
            if (!result.success) {
              Notification({message: 'Unable to register the lead for ' + vm.selectedUsers[0].fullName}, 'error');
              vm.isLoading = false;
            }
            else {
              if (vm.selectedUser && onSentCallback) {
                onSentCallback.apply();
              }
              else if (vm.isRegister) {
                $state.go('myMemberships.receivedLeads');
              }
              else {
                $state.go('myPod.sentLeads');
              }

              Notification({message: 'Success'}, 'confirmation');
            }
          }, function () {
            vm.isLoading = false;
            Notification({message: 'Unable to register the lead'}, 'error');
          });
      }
    };

    vm.cancel = function () {
      // MGA - if there is a single user provided (very likely "Send Lead" clicked directly from the user's profile), then on cancel don't go anywhere (we should do the update though)
      if (vm.selectedUser && onCancelCallback) {
        onCancelCallback.apply();
      }
      else if (vm.isRegister) {
        $state.go('myMemberships.receivedLeads');
      }
      else {
        $state.go('myPod.sentLeads');
      }
    };

    vm.onContractTypeChange = function () {
      if (vm.data.invitation.contractType === 'Pay Per Lead') {
        $timeout(function () {
          angular.element($document[0].getElementById('inviteSuccessfulLeadPrice'))[0].focus();
        }, 200);
      }
      else if (vm.data.invitation.contractType === 'Subscription') {
        $timeout(function () {
          angular.element($document[0].getElementById('inviteSubscriptionPrice'))[0].focus();
        }, 200);
      }
    };

    vm.changeContractType = function (value, evt) {
      vm.data.invitation.contractType = value;
      vm.onContractTypeChange(evt);
      evt.stopPropagation();
      evt.preventDefault();
    };

    vm.toggleMoreDetailsVisibility = function () {
      vm.detailsVisible = !vm.detailsVisible;
    };

    vm.onRewardSelectionChange = function (data, isFormValid) {
      _.extend(vm.data.invitation, data);
      vm.isRewardsFormValid = isFormValid;
    };

    /**
     * Adds the to-be-invited recipient to the recipients list. Uses the current form values that are used to create an object and clears the form in the end.
     * @param {Object} form New user form that will have to have the errors cleared
     */
    vm.addNewRecipient = function (form) {
      if (!_.find(vm.invitations, {email: vm.data.invitation.email})) {
        vm.invitations.push(_.clone(vm.data.invitation));
        vm.data.invitation = {};
        if (form) {
          form.$setPristine();
          form.$setUntouched();
        }
        // We need to broadcast the clear to the reward selector as well...
        $scope.$broadcast('clearRewardSelector');
      }
    };

    /**
     * Removes the recipient from the list of new invitees that will recieve the lead.
     * @param {int} $index List index.
     */
    vm.removeNewRecipient = function ($index) {
      vm.invitations.splice($index, 1);
    };

    /**
     * Pre-fills the lead detail using search
     * @param {Object} item Item to pre-fill with
     */
    vm.onSearchItemSelected = function (item) {
      // Callback on when the user selected the search item.
      // Pull the stateTarget from category
      if (!item || !item.originalObject || angular.isString(item.originalObject)) {
        return;
      }
      vm.searchingUser = true;
      Restangular.one('Users').one('profile').get({id: item.originalObject.id})
        .then(function (profile) {
          vm.searchingUser = false;
          profile = profile.plain();
          // Pre-fill the data now.
          vm.data.firstName = profile.firstName;
          vm.data.lastName = profile.lastName;
          vm.data.email = profile.email;
          vm.data.phone = profile.phoneMobile;
          angular.element('#details').focus();
        });
    };

    /**
     * OnSelect event of the recipient search autocomplete control
     * @param {Object} item Item to pre-fill with
     */
    vm.onRecipientItemSelected = function (item) {
      if (!item || !item.originalObject || angular.isString(item.originalObject)) {
        return;
      }
      vm.searchingRecipient = true;
      Restangular.one('Users').one('profile').get({id: item.originalObject.id})
        .then(function (profile) {
          vm.searchingRecipient = false;
          profile = profile.plain();
          // Add to selected users
          // If this isRegister and there is already someone selected, we have to clear it.
          if (isRegister && vm.selectedUsers.length > 0) {
            vm.selectedUsers = [];
          }
          if (!_.find(vm.selectedUsers, {id: profile.id})) {
            vm.selectedUsers.push(profile);
          }
        });
    };

    /**
     * Formats the search response
     *
     * @param {Object} response Response object
     * @returns {Object} Formatted response
     */
    vm.searchResponseFormatter = function (response) {
      var r,
          records;

      if (angular.isUndefined(response) || !response) {
        return null;
      }
      vm.emptyResults = false;
      r = response.records;
      if (r.length === 0) {
        vm.emptyResults = true;
        if (vm.activeSearch === 'clients') {
          $timeout(function () {
            vm.emptyResults = false;
          }, 5000);
        }
      }

      records = _.map(r, function (item) {
        var category = _.find(vm.searchCategories, {value: item.datasource}),
            returnObject,
            categoryDescriptionClass,
            value = item.fullname;

        categoryDescriptionClass = category.iconClass || 'fa-user';
        if (item.companyname && item.companyname !== item.fullname) {
          value += ' | ' + item.companyname;
        }

        returnObject = {
          value: value,
          id: item.id,
          category: item.datasource,
          categoryDescription: '<i class="fa ' + categoryDescriptionClass + '"></i>',
          timeDescription: item.datecreatedformatted ? item.datecreatedformatted : '',
          leadtype: item.leadtype
        };

        return returnObject;
      });

      return records;
    };

    vm.showInviteNew = function () {
      if (isRegister) {
        vm.selectedUsers = [];
      }
      vm.showInviteForm = true;
      vm.activeSearch = 'recipients';
      vm.emptyResults = true;
    };

    vm.showSearchForm = function () {
      vm.showInviteForm = false;
      vm.emptyResults = false;
      if (isRegister) {
        vm.data.invitation = {};
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name lead.service:Leads
   *
   * @description
   *
   */
  Leads.$inject = ["$rootScope", "$document", "$state", "$q", "Notification", "$uibModal", "Restangular"];
  angular
    .module('lead')
    .service('Leads', Leads);

  function Leads($rootScope, $document, $state, $q, Notification, $uibModal, Restangular) {
    var self = this;

    self.closeLead = function (lead, status) {
      var modalInstance,
          params = {
            animation: false,
            templateUrl: 'lead/close-lead.tpl.html',
            controller: 'CloseLeadCtrl',
            controllerAs: 'closeLead',
            size: 'md',
            resolve: {
              lead: function () {
                return lead;
              },
              status: function () {
                return status;
              },
              contract: function () {
                return Restangular.one('Leads').one('receiveLeadsContract').get({idUserSender: lead.sender.id})
                  .then(function (contract) {
                    return contract.plain();
                  }, function () {
                    return null;
                  });
              },
              userProfile: ['Session', function (Session) {
                return Session.getUserProfile();
              }],
              leadPriceBreakdown: function () {
                if (status === 'successful') {
                  return Restangular.one('Leads').one('calculateRewardTotal').get({
                    idUserSender: lead.sender.id
                  })
                    .then(function (result) {
                      return result.plain();
                    }, function () {
                      return null;
                    });
                }
                return null;
              }
            }
          };
      if (lead.acceptedReceiveLeadsContractExists !== true) {
        $state.go('user.agreements', {
          id: lead.sender.id,
          '#': 'receive-leads-agreements-container'
        });
        Notification({message: 'Lead can be accessed with a valid agreement.'}, 'confirmation');
        return null;
      }

      params.appendTo = angular.element($document[0].body);
      modalInstance = $uibModal.open(params);
      return modalInstance.result;
    };

    self.openDetail = function (lead, type, parentElementId, tabToOpen) {
      var modalInstance,
          params = {
            animation: true,
            templateUrl: 'lead/lead-detail.tpl.html',
            controller: 'LeadDetailCtrl',
            controllerAs: 'leadDetail',
            size: 'lg',
            resolve: {
              lead: function () {
                if (lead && angular.isDefined(lead.id)) {
                  // The incoming lead is an object.
                  return lead;
                }
                else if (angular.isDefined(lead) && angular.isNumber(lead) && angular.isDefined(type)) {
                  // This is the id. Get the lead from the server.
                  return Restangular.one('Leads').one('index').get({id: lead}).then(function (result) {
                    return result.plain();
                  });
                }
              },
              leadType: function () {
                return type;
              },
              userProfile: ['Session', function (Session) {
                return Session.getUserProfile();
              }],
              tabToOpen: function () {
                return tabToOpen;
              }
            }
          };

      if (lead && lead.sender && type === 'received' && lead.acceptedReceiveLeadsContractExists !== true && !lead.isCoOwner) {
        $state.go('user.agreements', {
          id: lead.sender.id,
          '#': 'receive-leads-agreements-container'
        });

        Notification({message: 'Lead can be accessed with a valid agreement.'}, 'confirmation');
      }
      else {
        params.appendTo = angular.element($document[0].body);
        modalInstance = $uibModal.open(params);
        return modalInstance.result;
      }
      return modalInstance;
    };

    self.openDetailById = function (id, type) {
      Restangular.one('Leads').one('index').get({id: id})
        .then(function (lead) {
          self.openDetail(lead.plain(), type);
        });
    };

    self.resend = function (id) {
      return Restangular.one('Leads').post('resend', {id: id});
    };

    self.acknowledge = function (id) {
      return Restangular.one('Leads').post('acknowledge', {id: id});
    };

    self.update = function (id, data) {
      var deferred = $q.defer();
      Restangular.one('Leads').customPUT(data, 'index?id=' + id)
        .then(function (result) {
          deferred.resolve(result.plain());
        }, function (error) {
          deferred.reject(error.data.errorCode);
        });

      return deferred.promise;
    };

    self.openSendLead = function (userProfile, isRegister, onCancelCallback, onSentCallback) {
      var slModalInstance,
          cancelCallback,
          sentCallback;

      cancelCallback = function () {
        if (slModalInstance) {
          slModalInstance.close();
        }
        if (onCancelCallback && angular.isFunction(onCancelCallback)) {
          onCancelCallback.apply();
        }
      };

      sentCallback = function () {
        if (slModalInstance) {
          slModalInstance.close();
        }
        if (onSentCallback && angular.isFunction(onSentCallback)) {
          onSentCallback.apply();
        }
        $rootScope.$broadcast('sentLeads-success');
      };

      slModalInstance = $uibModal.open({
        animation: false,
        appendTo: angular.element($document[0].body),
        templateUrl: 'lead/send-lead-modal.tpl.html',
        controller: 'SendLeadCtrl',
        controllerAs: 'sendLead',
        size: 'lg',
        resolve: {
          userProfile: function () {
            return userProfile;
          },
          isRegister: function () {
            return isRegister;
          },
          membersSubIndustries: null,
          onCancelCallback: function () {
            return cancelCallback;
          },
          onSentCallback: function () {
            return sentCallback;
          }
        }
      });

      return slModalInstance;
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name lead.controller:LeadViewCtrl
   *
   * @description This is just a dummy view whoe only purpose is to provide background for the modal dialog for the incoming lead
   *
   */
  LeadViewCtrl.$inject = ["$state", "$stateParams", "Session", "Leads", "leadToOpen"];
  angular
    .module('lead')
    .controller('LeadViewCtrl', LeadViewCtrl);

  function LeadViewCtrl($state, $stateParams, Session, Leads, leadToOpen) {
    var vm = this,
        modalInstance;

    vm.lead = leadToOpen;
    vm.leadType = null;
    vm.tabToOpen = $stateParams.tabName;

    Session.getUserProfile().then(function (user) {
      vm.user = user;
      if (vm.lead.sender.fullName === vm.user.fullName) {
        vm.leadType = 'sent';
      }
      else {
        vm.leadType = 'received';
      }

      // We have a lead. Now open the modal dialog.
      // Once the modal is open, then handle the close and re-direct to the relevant section (my-pod, myBusiness) depending on the lead type
      if (angular.isDefined(vm.lead)) {
        modalInstance = Leads.openDetail(vm.lead, vm.leadType, null, vm.tabToOpen);
        if (angular.isDefined(modalInstance)) {
          modalInstance.then(function () {
            // We don't care much about the result. Just redirect to where it's required to go.
            if (vm.leadType === 'sent') {
              $state.go('myPod.sentLeads');
            }
            else {
              $state.go('myMemberships.receivedLeads');
            }
          });
        }
      }
    });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider", "configOptions"];
  angular
    .module('lead')
    .config(config);

  function config($stateProvider, configOptions) {
    $stateProvider
      .state('leadSend', {
        url: '/lead/send',
        templateUrl: 'lead/send-lead.tpl.html',
        controller: 'SendLeadCtrl',
        controllerAs: 'sendLead',
        authenticate: true,
        params: {
          user: null,
          lead: null
        },
        resolve: {
          // This the flag that will control whether the templates (and controller) will act as "sending the leads" or "registering the lead"
          isRegister: function () {
            return false;
          },
          // This is required for if the template is being used in the popup. The leads content is being requested via uiRouter, then provide the user details in the "user" param
          // If the isRegister == true, then the userProfile is the user in behalf who we are registering the lead. Otherwise it's the business user that the lead will be sent to.
          userProfile: function () {
            return null;
          },
          onCancelCallback: function () {
            return null;
          },
          onSentCallback: function () {
            return null;
          }
        }
      })
      .state('leadRegister', {
        url: '/lead/register',
        templateUrl: 'lead/send-lead.tpl.html',
        controller: 'SendLeadCtrl',
        controllerAs: 'sendLead',
        authenticate: true,
        params: {
          user: null,
          lead: null
        },
        resolve: {
          isRegister: function () {
            return true;
          },
          // This is required for if the template is being used in the popup. The leads content is being requested via uiRouter, then provide the user details in the "user" param
          userProfile: function () {
            return null;
          },
          onCancelCallback: function () {
            return null;
          },
          onSentCallback: function () {
            return null;
          }
        }
      })
      .state('leadDetail', angular.extend({}, configOptions.defaultStateParams, {
        url: '/lead/{id}/{tabName}',
        templateUrl: 'lead/lead-view.tpl.html',
        controller: 'LeadViewCtrl',
        controllerAs: 'leadView',
        authenticate: true,
        resolve: {
          leadToOpen: ['$stateParams', 'Restangular', 'Auth', function ($stateParams, Restangular, Auth) {
            return Auth.isAuthenticated().then(function (isAuthenticated) {
              if (isAuthenticated) {
                return Restangular.one('Leads').one('index').get({id: $stateParams.id})
                  .then(function (lead) {
                    return lead.plain();
                  }, function () {
                    return null;
                  });
              }
              return null;
            }, function () {
              return null;
            });
          }]
        }
      }));
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name lead.controller:LeadDetailCtrl
   *
   * @description
   *
   */
  LeadDetailCtrl.$inject = ["$rootScope", "$scope", "$uibModalInstance", "$state", "lead", "leadType", "userProfile", "tabToOpen", "Leads", "Notification", "FormAlerts", "RewardCodes", "Restangular", "notificationConst"];
  angular
    .module('lead')
    .controller('LeadDetailCtrl', LeadDetailCtrl);

  function LeadDetailCtrl($rootScope, $scope, $uibModalInstance, $state, lead, leadType, userProfile, tabToOpen,
                          Leads, Notification, FormAlerts, RewardCodes, Restangular, notificationConst) {
    var vm = this;
    vm.lead = lead;
    vm.leadType = leadType;
    vm.tabName = 'details';
    vm.isSending = false;
    vm.isAcknowledging = false;
    vm.alerts = new FormAlerts();
    vm.closeData = {};
    vm.isSavingUserShare = false;
    vm.userShareEmailChecked = false;
    vm.userShareEmailValid = false;
    vm.userShareEmailValidating = false;
    vm.userShareEmail = null;
    vm.userShareSectionVisible = false;
    vm.userShareEmailHelp = 'Type in the email address of another MyLeadPod user you want to share the lead with.';
    if (angular.isDefined(lead.coOwner) && angular.isObject(lead.coOwner)) {
      vm.userShareEmail = lead.coOwner.email;
      vm.userShareEmailValid = true;
      vm.userShareEmailChecked = true;
    }
    vm.searchCategories = [{
      name: 'Directory',
      value: 'businessUsers',
      stateTarget: 'user',
      iconClass: 'fa-briefcase'
    }];

    // Check tab name
    if (angular.isDefined(tabToOpen)) {
      vm.tabName = tabToOpen.lastIndexOf('detail') > -1 ? 'details' : tabToOpen;
    }

    vm.tabManager = null;
    vm.tabsConfig = [
      {
        label: 'Details',
        value: 'details'
      },
      {
        label: 'Messages',
        value: 'messages'
      }
    ];

    // Listen to the broadcast of 'close-modal-instance'
    $scope.$on('close-modal-instance', function () {
      $uibModalInstance.dismiss();
    });

    vm.close = function () {
      $uibModalInstance.close(vm.closeData);
    };

    vm.getUser = function () {
      if (leadType === 'sent') {
        return lead.recipient;
      }

      return lead.sender;
    };

    vm.showResendButton = function () {
      return leadType === 'sent' && lead.sender.id === userProfile.id && (lead.status === 'open' || lead.status === 'new');
    };

    vm.resend = function () {
      vm.isSending = true;
      vm.alerts.reset();
      Leads.resend(lead.id)
        .then(function () {
          vm.isSending = false;
          Notification({message: 'Success'}, 'confirmation');
        }, function () {
          vm.isSending = false;
          vm.alerts.add('danger', 'An error occurred, try again later please');
        });
    };

    vm.showAcknowledgeButton = function () {
      return leadType === 'received' && lead && lead.recipient && lead.recipient.id === userProfile.id && lead.status === 'new';
    };

    vm.acknowledge = function () {
      vm.isAcknowledging = true;
      vm.alerts.reset();
      Leads.acknowledge(lead.id)
        .then(function (result) {
          vm.lead = result;
          vm.isAcknowledging = false;
          lead = result.plain();
          vm.closeData.lead = lead;
          Notification({message: 'Success'}, 'confirmation');
        }, function () {
          vm.isAcknowledging = false;
          vm.alerts.add('danger', 'An error occurred, try again later please');
        });
    };

    vm.saveUserShare = function () {
      vm.isSavingUserShare = true;
      vm.editUserShareError = false;
      Leads.update(lead.id, {
        userShareEmail: vm.userShareEmail
      })
        .then(function (result) {
          vm.lead = result;
          lead = result;
          vm.closeData.lead = lead;
          vm.editUserShareVisible = false;
          vm.isSavingUserShare = false;
          vm.shareWithProfile = null;
        }, function () {
          vm.isSavingUserShare = false;
          vm.editUserShareError = 'An error occurred';
        });
    };

    vm.removeSharing = function () {
      vm.isRemovingShare = true;
      vm.userShareEmail = null;
      Leads.update(lead.id, {
        userShareEmail: vm.userShareEmail
      })
        .then(function (result) {
          vm.lead = result;
          lead = result;
          vm.closeData.lead = lead;
          vm.editUserShareVisible = false;
          vm.isSavingUserShare = false;
          vm.isRemovingShare = false;
        }, function () {
          vm.isRemovingShare = false;
          vm.isSavingUserShare = false;
          vm.editUserShareError = 'An error occurred';
        });
    };

    vm.onUserShareEmailChange = function () {
      vm.userShareEmailChecked = false;
      vm.userShareEmailValid = false;
      vm.userShareEmailValidating = false;
    };

    vm.validateUserShareEmail = function () {
      if (vm.userShareEmailValidating) {
        // This user email check fn can be triggered by blur and by the 'check' click. To avoid double requests return from here.
        return;
      }
      if (vm.userShareEmail && vm.userShareEmail.length >= 0) {
        vm.userShareEmailValidating = true;
        RewardCodes.isUserShareEmailValid(vm.userShareEmail)
          .then(function (isValid) {
            vm.userShareEmailValid = isValid;
            vm.userShareEmailValidating = false;
            vm.userShareEmailChecked = true;
          });
      }
    };

    vm.isUserShareEmailValid = function () {
      if (vm.userShareEmail !== null && vm.userShareEmail.length === 0) {
        return true;
      }

      return vm.userShareEmailValid;
    };

    vm.forward = function () {
      $uibModalInstance.dismiss();
      $state.go('leadSend', {
        lead: lead
      });
    };

    vm.closeLead = function (status) {
      Leads.closeLead(lead, status)
        .then(function () {
          lead.status = status;
          vm.lead.status = status;
        });
    };

    vm.getUserDetailClass = function () {
      if (vm.tabManager) {
        return vm.tabManager.isOpen('messages') ? 'table-user-detail-opaque' : '';
      }
      return '';
    };

    vm.searchResponseFormatter = function (response) {
      var r,
          records;

      vm.userInvitedProfile = null;

      if (angular.isUndefined(response) || !response) {
        return null;
      }
      vm.emptyResults = false;
      r = response.records;
      if (r.length === 0) {
        vm.emptyResults = true;
      }

      records = _.map(r, function (item) {
        var category = _.find(vm.searchCategories, {value: item.datasource}),
            returnObject = {},
            categoryDescriptionClass,
            value = item.fullname;

        categoryDescriptionClass = category.iconClass || 'fa-user';
        if (item.companyname && item.companyname !== item.fullname) {
          value += ' | ' + item.companyname;
        }

        returnObject = {
          value: value,
          id: item.id,
          category: item.datasource,
          categoryDescription: '<i class="fa ' + categoryDescriptionClass + '"></i>',
          timeDescription: item.datecreatedformatted ? item.datecreatedformatted : '',
          leadtype: item.leadtype
        };

        return returnObject;
      });

      return records;
    };

    vm.onSearchItemSelected = function (item) {
      // Callback on when the user selected the search item.
      // Pull the stateTarget from category
      if (!item) {
        return;
      }
      vm.displayContactDetails = true;
      vm.isLoadingUserData = true;
      Restangular.one('Users').one('profile').get({id: item.originalObject.id})
        .then(function (profile) {
          profile = profile.plain();
          vm.userShareEmail = profile.email;
          vm.shareWithProfile = profile;
          vm.isLoadingUserData = false;
        });
    };

    // Broadcast to NotificationCentre that it can clear notifications related to this lead.
    // Broadcast to the NotificationCentre to check any notifications related to messages for this user.
    vm.onTabChange = function (activeTab) {
      var obj;
      if (activeTab === 'details') {
        obj = {
          userId: vm.getUser().idUser,
          category: notificationConst.categoryLeads,
          type: notificationConst.typeNewLead,
          data: {
            leadId: lead.id
          }
        };
      }
      else {
        obj = {
          userId: vm.getUser().idUser,
          category: notificationConst.categoryMessages,
          type: notificationConst.typeLeadMessage,
          data: {
            leadId: lead.id
          }
        };
      }
      $rootScope.$broadcast('event:notifications-check', obj);
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name lead.controller:CloseLeadCtrl
   *
   * @description
   *
   */
  CloseLeadCtrl.$inject = ["$rootScope", "Restangular", "$uibModalInstance", "Notification", "FormAlerts", "lead", "status", "contract", "userProfile", "leadPriceBreakdown"];
  angular
    .module('lead')
    .controller('CloseLeadCtrl', CloseLeadCtrl);

  function CloseLeadCtrl($rootScope, Restangular, $uibModalInstance, Notification, FormAlerts,
                         lead, status, contract, userProfile, leadPriceBreakdown) {
    var vm = this;
    vm.lead = lead;
    vm.status = status;
    vm.contract = contract;
    vm.isLoading = false;
    vm.calculating = false;
    vm.calculationRequired = false;
    vm.selectedPricingOption = null;
    vm.user = userProfile;
    if (angular.isObject(leadPriceBreakdown)) {
      vm.processingFee = leadPriceBreakdown.fees;
      vm.tax = leadPriceBreakdown.tax;
      vm.total = leadPriceBreakdown.total;
    }
    else {
      vm.processingFee = 0;
      vm.tax = 0;
      vm.total = 0;
    }

    vm.showTotal = false;
    vm.data = {
      status: status,
      id: lead.id,
      creditCard: {},
      feedback: status === 'successful' ? 'Thank you for your lead!' : 'Thanks for your lead, it was not successful this time'
    };
    vm.alerts = new FormAlerts();

    if (contract && contract.pricingOptions) {
      // Sort the pricing options
      contract.pricingOptions = _.sortBy(contract.pricingOptions, 'successfulLeadPrice');
      if (contract.pricingOptions.length === 1) {
        vm.selectedPricingOption = contract.pricingOptions[0];
        vm.data.idPricingOption = contract.pricingOptions[0].id;
      }
    }

    Restangular.one('Billing').one('creditCard').get()
      .then(function (creditCard) {
        if (angular.isObject(creditCard) && angular.isDefined(creditCard.number)) {
          vm.creditCard = creditCard.plain();
        }
        vm.initialisingCard = false;
      }, function () {
        vm.initialisingCard = false;
      });

    vm.isSuccessful = function () {
      return status === 'successful';
    };

    vm.chargeForSuccessfulLead = function () {
      return angular.isObject(contract) && vm.isSuccessful() && (contract.contractType === 'Pay Per Lead' && contract.successfulLeadPrice > 0 ||
          contract.contractType === 'Rewards Program' || contract.contractType === 'Custom Rewards'
        );
    };

    vm.enableBonusReward = function () {
      return vm.isSuccessful() && angular.isObject(contract);
    };

    vm.showPriceBreakdown = function () {
      if (vm.isSuccessful()) {
        if (vm.chargeForSuccessfulLead()) {
          if (contract.contractType === 'Rewards Program' || contract.contractType === 'Custom Rewards') {
            return angular.isObject(vm.selectedPricingOption);
          }

          return true;
        }

        return vm.chargeForSuccessfulLead() ? true : vm.showTotal;
      }

      return false;
    };

    vm.cancel = function () {
      $uibModalInstance.dismiss();
    };

    vm.close = function () {
      vm.alerts.reset();
      vm.isLoading = true;
      Restangular.one('Leads').post('close', vm.data)
        .then(function () {
          $uibModalInstance.close();
          $rootScope.$broadcast('event:lead-closed');
          Notification({message: 'Success'}, 'confirmation');
        }, function (responseData) {
          vm.isLoading = false;
          if (responseData.data.errorCode === 701 || responseData.data.errorCode === 702 || responseData.data.errorCode === 703) {
            vm.alerts.add('danger', 'Unable to close the lead: ' + responseData.data.errorMessage);
          }
          else {
            vm.alerts.add('danger', 'Unable to close the lead, try again later please');
          }
        });
    };

    vm.calculateTotal = function () {
      if (vm.calculationRequired) {
        vm.calculating = true;
        Restangular.one('Leads').one('calculateRewardTotal').get({
          reward: vm.data.bonusReward,
          idUserSender: lead.sender.id,
          idPricingOption: vm.data.idPricingOption
        })
          .then(function (result) {
            vm.processingFee = result.fees;
            vm.total = result.total;
            vm.tax = result.tax;
            vm.calculationRequired = false;
            vm.calculating = false;
            vm.showTotal = true;
          });
      }
    };

    vm.showCCForm = function () {
      if (!userProfile.isCCSet) {
        return angular.isObject(contract) && vm.isSuccessful() && (contract.contractType === 'Pay Per Lead' && contract.successfulLeadPrice > 0 ||
          contract.contractType === 'Rewards Program' || contract.contractType === 'Custom Rewards') || parseFloat(vm.data.bonusReward) > 0;
      }

      return false;
    };

    vm.onBonusRewardChange = function (form) {
      vm.showTotal = false;
      vm.calculationRequired = true;
      if (!vm.showCCForm()) {
        delete form.$error.ccExp;
        delete form.$error.ccNumber;
        delete form.$error.ccNumberType;
        delete form.$error.ccExpMonth;
        delete form.$error.ccExpYear;
      }
    };

    vm.onPricingOptionChange = function (form, $index) {
      vm.data.idPricingOption = contract.pricingOptions[$index].id;
      vm.selectedPricingOption = contract.pricingOptions[$index];
      vm.onBonusRewardChange(form);
      // vm.calculateTotal();
    };

    vm.getSuccessfulLeadPrice = function () {
      switch (contract.contractType) {
        case 'Pay Per Lead':
          return vm.contract.pricingOptions[0].successfulLeadPrice;
        case 'Rewards Program':
        case 'Custom Rewards':
          return vm.selectedPricingOption.successfulLeadPrice;
        default:
          return 0;
      }
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name dashboard
   * @description
   *
   */
  angular
    .module('dashboard', [
      'ui.router',
      'dashboard.pod',
      'dashboard.memberships',
      'dashboard.stats'
    ]);
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('dashboard')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('dashboard', {
        url: '/?imTopic',
        templateUrl: 'dashboard/dashboard.tpl.html',
        controller: 'DashboardCtrl',
        controllerAs: 'dashboard',
        authenticate: true,
        resolve: {
          userProfile: ['Session', function (Session) {
            return Session.getUserProfile();
          }],
          dashboardData: ['Restangular', 'Auth', function (Restangular, Auth) {
            return Auth.isAuthenticated().then(function (isAuthenticated) {
              if (isAuthenticated) {
                return Restangular.one('Dashboard').one('index').get()
                  .then(function (result) {
                    return result.plain();
                  });
              }

              return null;
            }, function () {
              return null;
            });
          }]
        }
      });
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name dashboard.controller:DashboardCtrl
   *
   * @description
   *
   */
  DashboardCtrl.$inject = ["userProfile", "dashboardData"];
  angular
    .module('dashboard')
    .controller('DashboardCtrl', DashboardCtrl);

  function DashboardCtrl(userProfile, dashboardData) {
    var vm = this;
    vm.earnings = dashboardData.earnings;
    vm.donations = dashboardData.donations;
    vm.membersCount = dashboardData.membersCount;
    vm.membershipsCount = dashboardData.membershipsCount;
    vm.leadsDailyStats = dashboardData.leadsDailyStats;
    vm.earningsDailyStats = dashboardData.earningsDailyStats;
    vm.sentLeadsCount = dashboardData.sentLeadsCount;
    vm.members = dashboardData.members;
    vm.receivedLeads = dashboardData.receivedLeads;
    vm.userProfile = userProfile;

    vm.isBusinessAccountActive = function () {
      return angular.isObject(userProfile) && angular.isDefined(userProfile.isBusinessAccountActive) && userProfile.isBusinessAccountActive === true;
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name configuration
   * @description
   *
   */
  angular
    .module('configuration', [
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name configuration.constant:configOptions
   *
   * @description
   *
   */
  angular
    .module('configuration')
    .constant('configOptions', {
      appVersion: '1.48.31',
      appUrl: 'https://app.myleadpod.com',
      baseUrl: 'https://admin.myleadpod.com/dataapi/myleadpod/v1',
      facebookAppId: '1035645753160080',
      facebookScope: 'email,public_profile',
      linkedInClientId: '75ys9solm6v8dj',
      linkedInProxy: 'https://admin.myleadpod.com/dataapi/myleadpod/v1/Users/linkedinOAuthProxy?X-Ff3-Api-Key=zRsMeAwjthhgoLoC3EHkUKmiFFnHkRtXF6oTCMHcoocqfVMDGP',
      googlePlusClientId: '1004778931844-jkjmvpe9b0b3vc21ok9m4kgn6j04if9g.apps.googleusercontent.com',
      googleMapApiKey: 'AIzaSyCpM94BmNGcG9P13TnstvbeCTA5zwiMj5M',
      googleMapLibraries: 'places',
      passwordPattern: '^.*(?=.{6,}).*$',
      passwordMessage: 'Invalid format of password, it must be at least 6 characters long',
      apiKey: 'zRsMeAwjthhgoLoC3EHkUKmiFFnHkRtXF6oTCMHcoocqfVMDGP',
      oneSignalAppId: '6aad642a-9027-48f5-b2d2-19ab65c3d53d',
      oneSignalSafariWebId: 'web.onesignal.auto.5ccade99-0f35-4775-9ae0-5e2c3bfd110b',
      linkedInScope: 'r_liteprofile,r_emailaddress',
      googlePlusScope: 'https://www.googleapis.com/auth/userinfo.email,' +
      'https://www.googleapis.com/auth/userinfo.profile',
      maxFileUploadSize: 10485760,
      maxFileUploadMessage: 'Max allowed size of file is 10M',
      reCaptchaSiteKey: '6LdZhRYTAAAAAMwGIVoEZzkuJ4bi1HH7bADw7I_n',
      defaultStateParams: {
        params: {nextSteps: []},
        authenticate: true
      },
      bitlyGenericAccessToken: '13b7cba3c05015f378f7cc2a084856063f7519c5',
      defaultShareName: 'Send leads. Be rewarded.',
      defaultShareMessage: 'MyLeadPod is a professional reward platform for individuals and businesses sending and receiving leads.',
      defaultEmailMessage: 'We are using MyLeadPod to reward successful leads provided by business associates, ' +
                           'friends and family. Click the link below to take the simple steps (a minute or two) to ' +
                           'join our cash rewards program.\r\r\n Visit \r\n {{link}} \r\r\n' +
                           'If you run a business yourself, check out MyLeadPod as your very own simple-to-use cash ' +
                           'reward program to incentivise word-of-mouth referrals. \r\r\n We look forward to rewarding you in cash!',
      maxBusinessSuggestions: 1,
      reportRegistration: function () {
        gtag('event', 'conversion', {'send_to': 'AW-830376296/JR2hCNe53o8BEOiS-osD'});fbq('track', 'PageView');
      }
    });
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name components
   * @description
   *
   */
  angular
    .module('components', [
      'components.ui'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name components.factory:Tabs
   *
   * @description
   *
   */
  angular
    .module('components')
    .factory('Tabs', Tabs);

  function Tabs() {
    var TabsBase = {};

    function TabsManager(id, tabNames, activeTab) {
      var self = this;
      self.activeTab = activeTab;
      self.tabNames = tabNames;
      self.id = id;

      self.openTab = function (tabName) {
        if (self.activeTab !== tabName) {
          self.activeTab = tabName;
        }
      };

      self.isOpen = function (tabName) {
        return self.activeTab === tabName;
      };
    }

    TabsBase.create = function (id, tabNames, activeTab, $scope) {
      if (!angular.isArray(tabNames) || tabNames.length < 1) {
        throw new Error('List of names must not be empty');
      }

      if (angular.isUndefined(activeTab) && angular.isDefined($scope.tab)) {
        activeTab = $scope.tab;
      }

      return new TabsManager(id, tabNames, activeTab);
    };

    return TabsBase;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name components.directive:staticInclude
   * @restrict EA
   * @element
   *
   */
  staticInclude.$inject = ["$templateRequest", "$compile"];
  angular
    .module('components')
    .directive('staticInclude', staticInclude);

  function staticInclude($templateRequest, $compile) {
    return {
      restrict: 'A',
      transclude: true,
      replace: true,
      scope: false,
      link: function ($scope, element, attrs) {
        var templatePath = attrs.staticInclude;

        $templateRequest(templatePath)
          .then(function (response) {
            var contents = element.html(response).contents();
            $compile(contents)($scope.$new(false, $scope.$parent));
          });
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name components.directive:stateClass
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="components">
       <file name="index.html">
        <state-class></state-class>
       </file>
     </example>
   *
   */
  stateClass.$inject = ["$rootScope"];
  angular
    .module('components')
    .directive('stateClass', stateClass);

  function stateClass($rootScope) {
    return {
      restrict: 'A',
      scope: {},
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint no-unused-vars: [2, {"args": "none", "varsIgnorePattern": "Handler" }]*/
        var stateChangeStartHandler = $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
          var fromClassNames = angular.isDefined(fromState.data) && angular.isDefined(fromState.data.classNames) ? fromState.data.classNames : null,
              toClassNames = angular.isDefined(toState.data) && angular.isDefined(toState.data.classNames) ? toState.data.classNames : null;

          // don't do anything if they are the same
          if (fromClassNames !== toClassNames) {
            if (fromClassNames) {
              element.removeClass(fromClassNames);
            }

            if (toClassNames) {
              element.addClass(toClassNames);
            }
          }
        });
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name components.directive:statBlock
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="components">
       <file name="index.html">
        <stat-block></stat-block>
       </file>
     </example>
   *
   */
  angular
    .module('components')
    .directive('statBlock', statBlock);

  function statBlock() {
    return {
      restrict: 'EA',
      scope: {
        stat: '@',
        subtext: '@?',
        buttonText: '@?',
        buttonLink: '@?'
      },
      templateUrl: 'components/stat-block-directive.tpl.html',
      replace: false,
      controllerAs: 'statBlock',
      controller: function () {
        var vm = this;
        vm.stat = 0;
        vm.subtext = '-';
        vm.buttonText = 'View All';
        vm.buttonLink = '#';
      },
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
        ctrl.stat = attrs.stat;
        ctrl.subtext = attrs.subtext;
        ctrl.buttonText = attrs.buttonText;
        ctrl.buttonLink = attrs.buttonLink;
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name components.directive:stPaginationScroll
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="components">
       <file name="index.html">
        <st-pagination-scroll></st-pagination-scroll>
       </file>
     </example>
   *
   */
  stPaginationScroll.$inject = ["$timeout"];
  angular
    .module('components')
    .directive('stPaginationScroll', stPaginationScroll);

  function stPaginationScroll($timeout) {
    return {
      require: 'stTable',
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */

        var itemByPage = 10,
            pagination = ctrl.tableState().pagination,
            lengthThreshold = 50,
            timeThreshold = 400,
            promise = null,
            lastRemaining = 9999,
            container;

        if (element.closest('.fixed-header').length > 0) {
          container = element.find('> tbody');
        }
        else {
          container = element.parent();
        }

        function handler() {
          ctrl.slice(pagination.start + itemByPage, itemByPage);
        }

        container.bind('scroll', function () {
          var remaining = container[0].scrollHeight - (container[0].clientHeight + container[0].scrollTop);
          // if we have reached the threshold and we scroll down
          if (remaining < lengthThreshold && remaining - lastRemaining < 0) {
            // if there is already a timer running which has no expired yet we have to cancel it and restart the timer
            if (promise !== null) {
              $timeout.cancel(promise);
            }
            promise = $timeout(function () {
              handler();
              promise = null;
            }, timeThreshold);
          }
          lastRemaining = remaining;
        });
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name components.directive:scrollNavigator
   * @restrict A
   * @element
   *
   * @description Directive that checks if the element is within the viewport and if not, it displays the message to scroll
   *
   */
  scrollNavigator.$inject = ["$window", "$rootScope", "$document", "$timeout", "$uibPosition"];
  angular
    .module('components')
    .directive('scrollNavigator', scrollNavigator);

  function scrollNavigator($window, $rootScope, $document, $timeout, $uibPosition) {
    return {
      restrict: 'AE',
      scope: {
        autoRun: '=',
        onClickCallback: '=',
        scrollTargetElementId: '=',
        scrollTargetChangeDelay: '='
      },
      link: function (scope, element, attrs) {
        var targetElement,
            checkScroll,
            navigatorElement,
            watchHandle,
            watchToolbarHandleVisible,
            watchToolbarHandleHidden,
            windowScrollHandle,
            windowResizeHandle,
            windowFn,
            timeoutPromise,
            navigatorOffsetX,
            navigatorOffsetY,
            actionToolbarOffset = 0,
            scrollingElement;

        function getScrollingElement() {
          var d = $document[0];
          return d.documentElement.scrollHeight > d.body.scrollHeight && d.compatMode.indexOf('CSS1') === 0 ? d.documentElement : d.body;
        }

        // Function
        checkScroll = function () {
          var offset;
          targetElement = $document[0].getElementById(scope.scrollTargetElementId);
          if (!targetElement) {
            return false;
          }
          offset = $uibPosition.viewportOffset(targetElement, true);

          if (offset.bottom < actionToolbarOffset) {
            // Means the target element is completely or partially out of view. Display the message.
            if (offset.bottom < targetElement.clientHeight * -1 + actionToolbarOffset) {
              // It's completely out
              navigatorElement.css({
                top: $window.innerHeight - navigatorElement.outerHeight() + navigatorOffsetY - actionToolbarOffset + 'px',
                left: offset.left + navigatorOffsetX + 'px',
                position: 'fixed'
              });
            }
            /*
            else {
              // Partially invisible. As the user might have been scrolling further from completely invisible to partially invisible (here)
              // the calculation and transition of the element are causing undesireable 'jump' effect. Let's check that
              navigatorElement.css({
                top: $window.innerHeight + (offset.top - $window.innerHeight) - navigatorElement.outerHeight() + $window.scrollY + navigatorOffsetY - actionToolbarOffset + 'px',
                left: offset.left + navigatorOffsetX + 'px',
                position: 'absolute'
              });
            }
            */
            navigatorElement.addClass('visible');
            scope.$apply();
          }
          else if (offset.bottom > 0) {
            navigatorElement.removeClass('visible');
            scope.$apply();
          }
        };

        // Get target element
        if (angular.isDefined(scope.scrollTargetElementId)) {
          // Create the navigator element
          navigatorElement = angular.element('<div class="scroll-navigator no-select" id="' + scope.scrollTargetElementId + '-scroll-navigator"></div>');
          navigatorElement[0].innerHTML = '<span class="indicator"><i class="fa fa-arrow-down"></i></span><span>' + attrs.scrollMessage + '</span>';
          $document[0].body.appendChild(navigatorElement[0]);

          //
          navigatorOffsetX = attrs.scrollOffsetX ? parseInt(attrs.scrollOffsetX, 10) : 0;
          navigatorOffsetY = attrs.scrollOffsetY ? parseInt(attrs.scrollOffsetY, 10) : 0;

          //
          if (scope.onClickCallback) {
            navigatorElement.addClass('cursor-pointer');
            navigatorElement.on('click', function () {
              // Assuming the click will scroll the element in...
              // navigatorElement.removeClass('visible');
              // scope.$apply();
              // Call the callback function
              scope.onClickCallback(scope.scrollTargetElementId);
            });
          }

          //
          windowFn = function () {
            if (timeoutPromise) {
              $timeout.cancel(timeoutPromise);
            }
            scrollingElement = scrollingElement || getScrollingElement();
            if (scrollingElement.scrollTop < 0) {
              // This applies especially to iOS safar's elastic scroll that can go negative. don't do anything then.
              return;
            }
            timeoutPromise = $timeout(function () {
              checkScroll();
            }, 50);
          };

          windowScrollHandle = angular.element($window.document).on('scroll', windowFn);
          windowResizeHandle = angular.element($window.document).on('resize', windowFn);

          if (scope.autoRun) {
            checkScroll();
          }
        }

        //
        scope.$watch('scrollTargetElementId', function (oldVal, newVal) {
          if (oldVal !== newVal) {
            $timeout(function () {
              checkScroll();
            }, scope.scrollTargetChangeDelay ? scope.scrollTargetChangeDelay : 10);
          }
        });

        // Subscribe to the command of checking the scroll from parent (?). We shall be passing around the id of the element but no time for that
        watchHandle = $rootScope.$on('event:check-scroll-navigator', function (evt, id, timeout) {
          if (scope.scrollTargetElementId === id || id === 'all') {
            // Do this in the timeout as there might be multiple triggers for checkScroll and we don't know when and if to run scope.apply,
            // hence timeout to try to avoid the diggest errors,
            $timeout(function () {
              checkScroll();
            }, timeout ? timeout : 10);
          }
          else {
            navigatorElement.removeClass('visible');
          }
        });
        watchToolbarHandleVisible = $rootScope.$on('event:action-toolbar-visible', function (evt, height) {
          actionToolbarOffset = height;
          $timeout(function () {
            checkScroll();
          });
        });
        watchToolbarHandleHidden = $rootScope.$on('event:action-toolbar-hidden', function () {
          actionToolbarOffset = 0;
          $timeout(function () {
            checkScroll();
          });
        });
        scope.$on('$destroy', watchHandle);
        scope.$on('$destroy', watchToolbarHandleVisible);
        scope.$on('$destroy', watchToolbarHandleHidden);
        scope.$on('$destroy', function () {
          windowScrollHandle.off('scroll', windowFn);
          windowResizeHandle.off('resize', windowFn);
          navigatorElement.remove();
        });
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name components.directive:scrollCheck
   * @restrict A
   * @element
   *
   * @description Directive that checks the scroll and provides the css classes based on the location of the scroll
   *
   */
  scrollCheck.$inject = ["$rootScope", "$document", "$timeout"];
  angular
    .module('components')
    .directive('scrollCheck', scrollCheck);

  function scrollCheck($rootScope, $document, $timeout) {
    return {
      restrict: 'A',
      scope: true,
      link: function (scope, element, attrs) {
        var targetElement,
            checkScroll,
            hasScrollbar,
            watchHandle,
            init;

        scope.altElementBreakpoint = attrs.altElementBreakpoint ? parseInt(attrs.altElementBreakpoint, 10) : 1024;
        if (!angular.isNumber(scope.altElementBreakpoint)) {
          scope.altElementBreakpoint = 1024;
        }

        // Function
        checkScroll = function () {
          if (!targetElement || !targetElement[0]) {
            hasScrollbar = false;
            return false;
          }
          hasScrollbar = targetElement[0].scrollWidth > targetElement[0].clientWidth;

          // Now apply classes
          if (hasScrollbar) {
            element.removeClass('scroll-start');
            element.removeClass('scroll-between');
            element.removeClass('scroll-end');
            // Check the scrollbar position
            if (targetElement[0].scrollLeft === 0) {
              element.addClass('scroll-start');
            }
            else if (targetElement[0].scrollWidth - targetElement[0].scrollLeft === targetElement[0].clientWidth) {
              element.addClass('scroll-end');
            }
            else {
              element.addClass('scroll-between');
            }
          }
        };

        init = function () {
          targetElement = angular.element($document[0].getElementById(attrs.targetElementId));
          // Bind to the on scroll event
          targetElement.bind('scroll', function () {
            checkScroll();
          });
          checkScroll();
        };

        // Get target element
        if (angular.isDefined(attrs.targetElementId)) {
          if (attrs.withDelay) {
            $timeout(function () {
              init();
            }, Number(attrs.withDelay));
          }
          else {
            init();
          }
        }

        // Subscribe to the command of checking the scroll from parent (?). We shall be passing around the id of the element but no time for that
        watchHandle = $rootScope.$on('event:check-scroll', function () {
          checkScroll();
        });
        scope.$on('$destroy', watchHandle);
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name components.service:RouterHelper
   *
   * @description
   *
   */
  RouterHelper.$inject = ["$rootScope", "$q", "$timeout", "QueueManager", "QueueItem"];
  angular
    .module('components')
    .service('RouterHelper', RouterHelper);

  function RouterHelper($rootScope, $q, $timeout, QueueManager, QueueItem) {
    var self = this;

    self.activateTab = function (id, tabName, params) {
      QueueManager.add(new QueueItem(function (ready) {
        $q.when(ready).then(function () {
          $timeout(function () {
            self.lastEvent = {
              id: 'event:' + id + '-open-tab-' + tabName,
              params: params
            };

            $rootScope.$broadcast('event:' + id + '-open-tab-' + tabName, params);
          });
        });

        return $q.when(true);
      }));
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name components.factory:RecordsSet
   *
   * @description
   *
   */
  RecordsSet.$inject = ["$q", "$httpParamSerializer", "Restangular"];
  angular
    .module('components')
    .factory('RecordsSet', RecordsSet);

  function RecordsSet($q, $httpParamSerializer, Restangular) {
    var RecordsSetBase = {},
        defaultQueryParams = {
          limit: 10
        };

    /**
     * Records set manager
     *
     * @callback itemCallback
     *
     * @param {string} entity Name of the entity
     * @param {string} endPoint Endpoint to be called, default index
     * @param {Object} queryParams Additional parameters
     * @param {Object} options Additional params to configure record set
     * @constructor
     */
    function RecordsSetManager(entity, endPoint, queryParams, options) {
      var self = this,
          loadingEnabled = true,
          totalLoaded = 0,
          initialise,
          deferredInit = $q.defer();

      options = angular.isObject(options) ? options : {};
      self.records = [];
      self.isLoading = false;
      self.isInitialised = false;
      self.numberOfSets = 0;
      self.recordsExist = null;
      self.totalRecords = null;

      if (angular.isObject(queryParams)) {
        queryParams = angular.extend({}, defaultQueryParams, queryParams);
      }
      else {
        queryParams = angular.extend({}, defaultQueryParams);
      }

      if (angular.isDefined(options.basicParams) && angular.isObject(options.basicParams)) {
        queryParams = angular.extend(queryParams, options.basicParams);
      }

      if (angular.isUndefined(endPoint) || endPoint.trim() === '') {
        endPoint = 'index';
      }

      /**
       * Load records, support for loading additional records
       * @param {Boolean} isReload Forces reload if set to true
       *
       * @returns {Deferred} Deferred promise
       */
      self.load = function (isReload) {
        var deferred = $q.defer();
        if (loadingEnabled) {
          self.isLoading = true;
          Restangular.one(entity).getList(endPoint + '?' + $httpParamSerializer(angular.extend(queryParams, {
            offset: totalLoaded
          }))).then(function (result) {
            angular.forEach(result, function (value) {
              var item = value.plain();
              if (angular.isDefined(options.itemCallback) && angular.isFunction(options.itemCallback)) {
                item = options.itemCallback(item);
              }
              self.records.push(item);
            });
            totalLoaded += result.meta.selectedRecords;
            if (result.meta.totalRecords <= totalLoaded) {
              loadingEnabled = false;
            }

            // MGA: there was a reason MSU was setting the totalRecords to 0 and later on keep comparing against null...
            // Let's still support it, but if it's the reload, then just force the update.
            if (!isReload) {
              if (self.totalRecords === null) {
                self.totalRecords = result.meta.totalRecords;
              }
              if (self.recordsExist === null) {
                self.recordsExist = self.records.length > 0;
              }
            }
            else {
              self.totalRecords = result.meta.totalRecords;
              // self.recordsExist = self.records.length > 0;
            }

            self.isLoading = false;
            self.isInitialised = true;

            if (angular.isDefined(queryParams.limit) && parseInt(queryParams.limit, 10) > 0) {
              self.numberOfSets = Math.ceil(result.meta.totalRecords / queryParams.limit);
            }
            deferred.resolve(self.records);
          }, function (result) {
            deferred.reject(result.data.errorMessage);
          });
        }
        else {
          deferred.resolve([]);
        }

        return deferred.promise;
      };

      initialise = function () {
        var deferred = $q.defer(),
            keys,
            extraParams,
            performInitQuery = false;

        if (!self.isInitialised) {
          if (angular.isDefined(options.basicParams) && angular.isObject(options.basicParams)) {
            keys = _.keys(options.basicParams);
            keys.push('limit');
            keys.push('offset');
            extraParams = _.omit(queryParams, keys);
            performInitQuery = _.keys(extraParams).length > 0;
          }

          if (performInitQuery) {
            self.isLoading = true;
            Restangular.one(entity).getList(endPoint + '?' + $httpParamSerializer(options.basicParams))
              .then(function (result) {
                self.recordsExist = result.meta.totalRecords > 0;
                self.totalRecords = result.meta.totalRecords;
                if (self.recordsExist) {
                  self.load()
                    .then(function () {
                      self.isInitialised = true;
                      deferred.resolve(true);
                    });
                }
                else {
                  self.isInitialised = true;
                  self.isLoading = false;
                  deferred.resolve(true);
                }
              });
          }
          else {
            self.load()
              .then(function () {
                self.isInitialised = true;
                deferred.resolve(true);
              });
          }
        }
        else {
          return deferred.resolve(true);
        }

        return deferred.promise;
      };

      /**
       *
       * Creates a new record
       *
       * @param {Object} data Data to be sent to server
       * @param {Object} queryParamsOverride Additional query params, if not provided then params used to initialise loader will be used
       * @param {string} appendTo One of 'beginning' or 'end', indicates the position of new record within existing records, if not provided then the record won't be inserted into the list
       * @returns {Deferred} Deferred promise
       */
      self.post = function (data, queryParamsOverride, appendTo) {
        var deferred = $q.defer(),
            qParams = queryParams;
        if (angular.isDefined(queryParamsOverride) && angular.isObject(queryParamsOverride)) {
          qParams = queryParamsOverride;
        }

        Restangular.one(entity).customPOST(data, endPoint, qParams)
          .then(function (result) {
            if (appendTo === 'beginning') {
              self.records.unshift(result.plain());
              totalLoaded += 1;
            }
            else if (appendTo === 'end') {
              self.records.push(result.plain());
              totalLoaded += 1;
            }
            deferred.resolve(result.plain());
          }, function (result) {
            deferred.reject(result.data.errorMessage);
          });

        return deferred.promise;
      };

      /**
       * Reload with new parameters
       * @param {Object} queryParamsOverride Overriding params
       * @param {Boolean} initialReset If true resets also flag indicating existence of records
       * @returns {Deferred} Deferred promise
       */
      self.reload = function (queryParamsOverride, initialReset) {
        self.reset(queryParamsOverride, initialReset);

        return self.load(true);
      };

      /**
       *
       * @param {string} key Key to sort by
       * @param {string} direction Direction to sort in
       * @returns {Deferred} Deferred promise
       */
      self.sort = function (key, direction) {
        self.records = [];
        totalLoaded = 0;
        loadingEnabled = true;
        queryParams = angular.extend(queryParams, {orderby: key + ' ' + direction});

        return self.load();
      };

      /**
       * Checks whether there is more records to load
       *
       * @returns {boolean} True or False whether loading is enabled
       */
      self.hasMore = function () {
        return loadingEnabled;
      };

      /**
       * Resets the loader, removes already loaded records from the list, resets counter
       *
       * @param {object} queryParamsOverride New params for the loader, overrides initial params from loader initialisation
       * @param {Boolean} initialReset If true resets also flag indicating existence of records
       */
      self.reset = function (queryParamsOverride, initialReset) {
        self.records = [];
        totalLoaded = 0;
        loadingEnabled = true;
        if (angular.isUndefined(initialReset) || initialReset !== true) {
          initialReset = false;
        }

        if (initialReset === true) {
          self.recordsExist = null;
        }
        if (angular.isObject(queryParamsOverride)) {
          queryParams = angular.extend({}, defaultQueryParams, queryParamsOverride);
        }
        else {
          queryParams = angular.extend({}, defaultQueryParams);
        }

        if (angular.isDefined(options.basicParams) && angular.isObject(options.basicParams)) {
          queryParams = angular.extend(queryParams, options.basicParams);
        }
      };

      self.isReady = deferredInit.promise;

      initialise().then(function () {
        deferredInit.resolve();
      }, function () {
        deferredInit.reject();
      });
    }

    /**
     * Returns instance of records set manager
     *
     * @param {string} entity Name of the entity
     * @param {string} endPoint Endpoint to be called, default index
     * @param {Object} params Additional parameters
     * @param {Object} options Additional params to configure record set
     * @returns {RecordsSetManager} Instance of records set manager
     */
    RecordsSetBase.createLoader = function (entity, endPoint, params, options) {
      return new RecordsSetManager(entity, endPoint, params, options);
    };

    return RecordsSetBase;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name components.factory:QueueManager
   *
   * @description
   *
   */
  QueueManager.$inject = ["$timeout", "QueueItem"];
  angular
    .module('components')
    .factory('QueueManager', QueueManager);

  function QueueManager($timeout, QueueItem) {
    var QueueManagerBase = {};
    /**
     * List of items that needs to be executed
     * @type {QueueItem[]|Array}
     */
    QueueManagerBase.queue = [];

    /**
     * Add new item to the list
     * @param {QueueItem} item Queue Item
     */
    QueueManagerBase.add = function (item) {
      if (!(item instanceof QueueItem)) {
        throw new Error('Only instance of QueueItem is allowed');
      }

      this.queue.push(item);
    };

    /**
     * Resets queue
     */
    QueueManagerBase.reset = function () {
      this.queue = [];
    };

    /**
     * Start processing of items in queue
     */
    QueueManagerBase.start = function () {
      var that = this;

      if (this.queue.length === 0) {
        return;
      }

      function queueLoop(previousResult) {
        /**
         * Item to process
         * @type {QueueItem}
         */
        var item;
        if (that.queue.length === 0) {
          return;
        }

        item = that.queue.shift();
        item.callback(previousResult).then(function (result) {
          var delay = item.options.delay || 0;
          /**
           * Process next item with delay, pass result from current item
           */
          $timeout(function () {
            queueLoop(result);
          }, delay);
        });
      }

      queueLoop();
    };

    return QueueManagerBase;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name components.factory:QueueItem
   *
   * @description
   *
   */
  angular
    .module('components')
    .factory('QueueItem', QueueItem);

  function QueueItem() {
    /**
     * Creates new instance of QueueItem
     *
     * @param {Function} callback Callback function
     * @param {Object} options Options
     * @constructor
     */
    function QueueItemBase(callback, options) {
      if (!angular.isFunction(callback)) {
        throw new Error('Callback has to be a function');
      }

      this.callback = callback;
      this.options = options || {};
    }

    return QueueItemBase;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name components.directive:nodeRelocator
   * @restrict A
   * @element
   *
   * @description Directive that will handle the relocation of the element into the alternative node if the breakpoint is achieved (in direction from greater to small)
   *
   */
  nodeRelocate.$inject = ["$window", "$document"];
  angular
    .module('components')
    .directive('nodeRelocate', nodeRelocate);

  function nodeRelocate($window, $document) {
    return {
      restrict: 'A',
      scope: true,
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */

        var moveElement,
            isMobile;

        scope.altElementBreakpoint = attrs.altElementBreakpoint ? parseInt(attrs.altElementBreakpoint, 10) : 1024;
        if (!angular.isNumber(scope.altElementBreakpoint)) {
          scope.altElementBreakpoint = 1024;
        }

        moveElement = function (el) {
          // We are moving the target element into 'el' which is either the srcElement or the altElement
          if (!el || !el.append || !scope.srcElement) {
            console.log('The core elements are missing');
            return;
          }
          // Invalidate the new container's display property
          el.css({display: null});
          // Append the target element into the new container
          el.append(element);
          // Make sure the element we are moving the target from is empty
          if (el[0] === scope.srcElement[0]) {
            scope.altElement[0].innerHTML = '';
          }
          else {
            scope.srcElement[0].innerHTML = '';
          }
        };

        // Get the alternative elementi
        if (angular.isDefined(attrs.altElementId)) {
          // Try to find it. Unfortunately, the angular.element($document).find doesn't work
          scope.altElement = angular.element($document[0].getElementById(attrs.altElementId));
          scope.srcElement = element.parent();

          if (scope.altElement) {
            scope.altElement.addClass('alt-node');
            // Ok now we will have to listen to the change events and then move this directive's element into the alt-element
            // Check if it's mobile already. If it is, then move it straightaway
            isMobile = $window.innerWidth < scope.altElementBreakpoint;
            if (isMobile) {
              moveElement(scope.altElement);
              scope.altElement.addClass('node-relocated');
            }
            else {
              scope.altElement.removeClass('node-relocated');
            }

            angular.element($window).on('resize', function () {
              isMobile = $window.innerWidth < scope.altElementBreakpoint;
              if (scope.isMobile !== isMobile && isMobile) {
                moveElement(scope.altElement);
                scope.altElement.addClass('node-relocated');
              }
              else if (scope.isMobile !== isMobile && !isMobile) {
                moveElement(scope.srcElement);
                scope.altElement.removeClass('node-relocated');
              }
              scope.isMobile = isMobile;
            });

            scope.isMobile = isMobile;
          }
        }
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name components.filter:nl2br
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('components')
    .filter('nl2br', nl2br);

  function nl2br() {
    return function (input) {
      if (input) {
        return (input + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + '<br/>' + '$2');
      }

      return '';
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name components.service:Location
   *
   * @description
   *
   */
  Location.$inject = ["Restangular", "Cache"];
  angular
    .module('components')
    .service('Location', Location);

  function Location(Restangular, Cache) {
    var self = this,
        countries = [],
        states = [];

    self.cache = Cache.create('location');

    if (!self.cache.get('countries')) {
      Restangular.one('Countries').getList('index?limit=-1')
        .then(function (result) {
          angular.forEach(result, function (country) {
            countries.push(country.plain());
          });
          self.cache.put('countries', countries);
        });
    }
    else {
      countries = self.cache.get('countries');
    }

    if (!self.cache.get('states')) {
      Restangular.one('States').getList('index?limit=-1')
        .then(function (result) {
          angular.forEach(result, function (state) {
            states.push(state.plain());
          });
          self.cache.put('states', states);
        });
    }
    else {
      states = self.cache.get('states');
    }

    self.getCountries = function () {
      return countries;
    };

    self.getStates = function (idCountry) {
      idCountry = parseInt(idCountry, 10);
      if (idCountry > 0) {
        return _.filter(states, function (item) {
          return item.idCountry === idCountry;
        });
      }

      return states;
    };

    self.getCountryId = function (countryCode) {
      try {
        return _.find(countries, {abbrevation: countryCode}).idCountry;
      }
      catch (ex) {
        return null;
      }
    };

    self.getCountryNameById = function (countryId) {
      try {
        return _.find(countries, {idCountry: Number(countryId)}).name;
      }
      catch (ex) {
        return null;
      }
    };

    self.getStateId = function (countryId, stateCode) {
      var sts;
      try {
        sts = self.getStates(countryId);
        return _.find(sts, {abbrevation: stateCode}).idState;
      }
      catch (ex) {
        return null;
      }
    };

    self.getStateNameById = function (countryId, stateId, shortName) {
      var sts;
      try {
        sts = self .getStates(countryId);
        if (!shortName) {
          return _.find(sts, {idState: Number(stateId)}).name;
        }
        return _.find(sts, {idState: Number(stateId)}).abbrevation;
      }
      catch (ex) {
        return null;
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name components.directive:locationSearch
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="components">
       <file name="index.html">
        <location-search></location-search>
       </file>
     </example>
   *
   */
  locationSearch.$inject = ["$q", "GoogleMap"];
  angular
    .module('components')
    .directive('locationSearch', locationSearch);

  function locationSearch($q, GoogleMap) {
    return {
      restrict: 'A',
      scope: {
        inputId: '=',
        placeChangedCallback: '=',
        fullAddress: '='
      },
      replace: false,
      link: function (scope, element) {
        var autocomplete,
            placeSelected;

        // This is the place_changed handler which is the google event for the autocomplete input
        placeSelected = function () {
          var place = autocomplete.getPlace(),
              address;

          address = GoogleMap.convertAddress(place.address_components);
          address.location = GoogleMap.getFormattedAddress(address, scope.fullAddress);
          scope.placeChangedCallback(address);
        };

        GoogleMap.promise.then(function () {
          // Prevent "Enter" click to submit the form
          google.maps.event.addDomListener(element[0], 'keydown', function (event) {
            if (event.keyCode === 13) {
              event.preventDefault();
            }
          });

          autocomplete = new google.maps.places.Autocomplete(element[0], {
            types: ['geocode']
          });
          autocomplete.addListener('place_changed', placeSelected);
        });
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name components.service:Industries
   *
   * @description
   *
   */
  Industries.$inject = ["$q", "Restangular", "Cache"];
  angular
    .module('components')
    .service('Industries', Industries);

  function Industries($q, Restangular, Cache) {
    var self = this,
        industries = {},
        subIndustries = [],
        deferred = $q.defer(),
        cache = Cache.create('industries', {
          maxAge: 1800000
        });

    self.isReady = deferred.promise;

    self.init = function () {
      if (!cache.get('industries') || !cache.get('subIndustries')) {
        Restangular.one('Industries').getList('index?limit=-1&includeAll=true')
          .then(function (result) {
            // Reset subIndustries as it might enter here couple of times within the lifecycle of the app and for example
            // login / logout always triggers the init (as the cache of industries is cleared) and hence adding more
            // and more subindustries
            subIndustries = [];

            angular.forEach(result, function (industry) {
              industry = industry.plain();
              if (!isNaN(industry.idIndustryParent) && industry.idIndustryParent > 0) {
                subIndustries.push(industry);
              }
              else {
                industry.options = [];
                industries[industry.id] = industry;
              }
            });

            angular.forEach(subIndustries, function (subIndustry) {
              if (angular.isDefined(industries[subIndustry.idIndustryParent])) {
                industries[subIndustry.idIndustryParent].options.push(subIndustry);
              }
            });
            cache.put('industries', industries);
            cache.put('subIndustries', subIndustries);
            deferred.resolve();
          });
      }
      else {
        industries = cache.get('industries');
        subIndustries = cache.get('subIndustries');
        deferred.resolve();
      }
    };

    self.init();

    self.getMainList = function () {
      return industries;
    };

    self.getSubList = function (idParent) {
      idParent = parseInt(idParent, 10);
      if (idParent > 0) {
        return _.filter(subIndustries, function (item) {
          return item.idIndustryParent === idParent;
        });
      }

      return subIndustries;
    };

    self.getFilteredList = function (subIndustriesFilter) {
      var filteredList = {};
      if (angular.isArray(subIndustriesFilter) && subIndustriesFilter.length > 0) {
        angular.forEach(subIndustriesFilter, function (item) {
          if (angular.isUndefined(filteredList[item.idIndustryParent])) {
            filteredList[item.idIndustryParent] = angular.extend({}, industries[item.idIndustryParent], {options: []});
          }
          filteredList[item.idIndustryParent].options.push(item);
        });
      }

      return _.values(filteredList);
    };

    self.getConsolidatedList = function () {
      var list = [];
      angular.forEach(industries, function (industry) {
        if (industry.options.length > 0) {
          angular.forEach(industry.options, function (subIndustry) {
            list.push({
              id: subIndustry.id,
              name: industry.name + ' - ' + subIndustry.name
            });
          });
        }
        else {
          list.push({
            id: industry.id,
            name: industry.name
          });
        }
      });

      return list;
    };

    self.getConsolidatedFilteredList = function (subIndustriesFilter) {
      var filteredList = {};

      if (angular.isArray(subIndustriesFilter) && subIndustriesFilter.length > 0) {
        filteredList = _.filter(self.getConsolidatedList(), function (item) {
          return _.findIndex(subIndustriesFilter, {id: item.id}) > -1;
        });
      }

      return filteredList;
    };

    self.reload = function () {
      cache.removeAll();
      self.init();
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name components.provider:GoogleMap
   *
   * @description
   *
   */
  angular
    .module('components')
    .provider('GoogleMap', GoogleMap);

  function GoogleMap() {
    var apiKey,
        libraries,
        isLoaded = false;

    return {
      init: function (key, libs) {
        apiKey = key;
        libraries = libs;
      },
      $get: ['$window', '$document', '$http', '$q', function ($window, $document, $http, $q) {
        var googleMapApi = $q.defer(),
            scriptElement;
        if (!isLoaded) {
          $window.googleMapInitCallback = function () {
            $window.googleMapInitCallback = null;
            isLoaded = true;
            googleMapApi.resolve();
          };

          scriptElement = $document[0].createElement('script');
          scriptElement.src = 'https://maps.googleapis.com/maps/api/js?callback=googleMapInitCallback&key=' + apiKey + '&libraries=' + libraries;
          $document[0].body.appendChild(scriptElement);
        }
        else {
          googleMapApi.resolve();
        }

        /**
         * Special location formatting for automatically detected location
         * @param {Object} address Structured address
         * @param {Boolean} preciseAddress Structured address
         * @returns {string} Formatted address
         */
        googleMapApi.getFormattedAddress = function (address, preciseAddress) {
          var parts = [],
              subParts = [];

          if (preciseAddress && address.streetNumber) {
            parts.push(address.streetNumber);
          }

          if (address.streetAddress) {
            parts.push(address.streetAddress);
          }

          if (address.suburb) {
            subParts.push(address.suburb);
          }

          if (address.state) {
            subParts.push(address.state);
          }

          if (address.postCode) {
            subParts.push(address.postCode);
          }

          if (subParts.length > 0) {
            parts.push(subParts.join(' '));
          }

          if (address.country) {
            parts.push(address.country);
          }

          return parts.join(', ');
        };

        googleMapApi.convertAddress = function (inputAddress) {
          /* eslint camelcase: [2, {properties: "never"}]*/
          var address = {},
              i,
              val,
              addressType,
              mappings,
              typeMappings,
              loopFunc;

          mappings = [
            {
              typeKey: 'street_number',
              targetKey: 'streetNumber',
              sourceKey: 'short_name'
            },
            {
              typeKey: 'route',
              targetKey: 'streetAddress',
              sourceKey: 'long_name'
            },
            {
              typeKey: 'locality',
              targetKey: 'suburb',
              sourceKey: 'long_name'
            },
            {
              typeKey: 'administrative_area_level_1',
              targetKey: 'state',
              sourceKey: 'short_name'
            },
            {
              typeKey: 'country',
              targetKey: 'country',
              sourceKey: 'long_name'
            },
            {
              typeKey: 'country',
              targetKey: 'country_id',
              sourceKey: 'short_name'
            },
            {
              typeKey: 'postal_code',
              targetKey: 'postCode',
              sourceKey: 'short_name'
            }];

          loopFunc = function (mapping) {
            val = inputAddress[i][mapping.sourceKey];
            address[mapping.targetKey] = val;
          };

          for (i = 0; i < inputAddress.length; i++) {
            addressType = inputAddress[i].types[0];
            typeMappings = _.where(mappings, {typeKey: addressType});
            angular.forEach(typeMappings, loopFunc);
          }

          return address;
        };

        googleMapApi.getCurrentLocation = function (preciseAddress) {
          var deferred = $q.defer();
          if ('geolocation' in $window.navigator) {
            $window.navigator.geolocation.getCurrentPosition(function (position) {
              var geocoder = new google.maps.Geocoder(),
                  gcRequest = {
                    location: new google.maps.LatLng(position.coords.latitude, position.coords.longitude)
                  };
              geocoder.geocode(
                gcRequest,
                function (gcResult, gcStatus) {
                  var address;
                  if (gcStatus === google.maps.GeocoderStatus.OK) {
                    address = googleMapApi.convertAddress(gcResult[0].address_components);
                    address.location = googleMapApi.getFormattedAddress(address, preciseAddress);
                    if (gcResult[0].geometry) {
                      address.geometry = {
                        lat: gcResult[0].geometry.location.lat(),
                        lng: gcResult[0].geometry.location.lng()
                      };
                    }
                    deferred.resolve(address);
                  }
                }
              );
            }, function () {
              deferred.reject();
            });
          }
          else {
            deferred.reject();
          }

          return deferred.promise;
        };

        googleMapApi.getStaticMapAsBase64 = function (address, options) {
          // Keep in mind that STATIC MAPS API will be constrain to only 640px if the key is not the business google maps license.
          // Check here https://developers.google.com/maps/documentation/static-maps/usage-limits

          var fileReader,
              deferred = $q.defer();

          if (!angular.isObject(options)) {
            deferred.reject();
          }

          try {
            $http({
              method: 'GET',
              url: 'https://maps.googleapis.com/maps/api/staticmap?format=png32&center=' + address + '&zoom=15&size=' + options.w + 'x' + options.h + '&maptype=roadmap',
              responseType: 'blob'
            }).then(function (response) {
              fileReader = new FileReader();
              fileReader.onload = function () {
                deferred.resolve(fileReader.result);
              };
              fileReader.readAsDataURL(response.data);
            });
          }
          catch (err) {
            deferred.reject();
          }

          return deferred.promise;
        };

        return googleMapApi;
      }]
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name components.factory:FormAlerts
   *
   * @description
   *
   */
  angular
    .module('components')
    .factory('FormAlerts', FormAlerts);

  function FormAlerts() {
    function FormAlertsBase() {
      this.alerts = [];
      this.someMethod = function () {
        return 'FormAlerts';
      };
      this.getList = function () {
        return this.alerts;
      };

      this.add = function (type, message) {
        this.alerts.push({type: type, message: message});
      };

      this.remove = function (index) {
        this.alerts.splice(index, 1);
      };

      this.reset = function () {
        this.alerts = [];
      };
    }

    return FormAlertsBase;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name components.directive:fileDownload
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="components">
       <file name="index.html">
        <file-download></file-download>
       </file>
     </example>
   *
   */
  fileDownload.$inject = ["$window", "$compile", "Auth"];
  angular
    .module('components')
    .directive('fileDownload', fileDownload);

  function fileDownload($window, $compile, Auth) {
    return {
      restrict: 'EA',
      scope: {
        url: '=',
        label: '='
      },
      replace: true,
      link: function (scope, element, attrs) {
        var template = '<a ng-click="download()" class="{{class}}" title="{{title}}">{{label}}</a>',
            e;
        scope.class = attrs.class;
        scope.title = attrs.title || 'Click to download';
        scope.download = function () {
          $window.open(Auth.fixApiEndpointUrl(scope.url));
        };

        e = $compile(template)(scope);
        element.replaceWith(e);
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name components.factory:DataTable
   *
   * @description
   *
   */
  DataTable.$inject = ["$q", "RecordsSet"];
  angular
    .module('components')
    .factory('DataTable', DataTable);

  function DataTable($q, RecordsSet) {
    var DataTableBase = {};

    /**
     *
     * @param {string} entity Name of the entity
     * @param {string} endPoint Endpoint to be called, default index
     * @param {Object} params Additional parameters
     * @param {Object} options Additional params to configure record set
     * @constructor
     */
    function DataTableManager(entity, endPoint, params, options) {
      var self = this,
          dataLoader = RecordsSet.createLoader(entity, endPoint, params, options),
          lastTableState = {},
          loadingMoreRecords = false,
          deferredInit = $q.defer();

      self.isReady = deferredInit.promise;

      // Wait for the data loader to initialise. Then the promise will be fulfilled and the self.isReady by be set.
      dataLoader.isReady.then(function () {
        deferredInit.resolve();
      }, function () {
        deferredInit.reject();
      });

      /**
       * Loads the data
       * @param {Object} tableState Table state from the Smart Table
       */
      self.load = function (tableState) {
        // var key, direction;
        if (!self.isLoading()) {
          loadingMoreRecords = true;
          dataLoader.load()
            .then(function () {
              tableState.pagination.numberOfPages = dataLoader.numberOfSets;
              loadingMoreRecords = false;
            }, function () {
              loadingMoreRecords = false;
            });

          // if (angular.isObject(tableState.sort) && angular.isDefined(tableState.sort.predicate)) {
          //   if (angular.isUndefined(lastTableState.sort) || !angular.equals(tableState.sort, lastTableState.sort)) {
          //     key = tableState.sort.predicate;
          //     direction = tableState.sort.reverse ? 'DESC' : 'ASC';
          //     dataLoader.sort(key, direction)
          //       .then(function () {
          //         tableState.pagination.numberOfPages = dataLoader.numberOfSets;
          //       });
          //   }
          // }
          // else {
          //   dataLoader.load()
          //     .then(function () {
          //       tableState.pagination.numberOfPages = dataLoader.numberOfSets;
          //     });
          // }

          lastTableState = tableState;
        }
      };

      /**
       * Reloads the records with new parameters
       * @param {Object} reloadParams New parameters
       * @param {Boolean} initialReset If true resets also flag indicating existence of records
       *
       * @returns {Object} Result
       */
      self.reload = function (reloadParams, initialReset) {
        if (!self.isLoading()) {
          if (angular.isObject(lastTableState) && angular.isObject(lastTableState.sort) && angular.isDefined(lastTableState.sort.predicate)) {
            reloadParams.orderby = lastTableState.sort.predicate + ' ' + (lastTableState.sort.reverse ? 'DESC' : 'ASC');
          }
          return dataLoader.reload(reloadParams, initialReset)
            .then(function (result) {
              if (angular.isObject(lastTableState) && angular.isObject(lastTableState.pagination)) {
                lastTableState.pagination.numberOfPages = dataLoader.numberOfSets;

                return $q.when(result);
              }
            });
        }

        return $q.when(false);
      };

      /**
       * Returns the records
       * @returns {Array} Records
       */
      self.getRecords = function () {
        return dataLoader.records;
      };

      /**
       * Checks if the data table is loading the data
       * @returns {boolean} Flag if records are loading
       */
      self.isLoading = function () {
        return dataLoader.isLoading;
      };

      /**
       * Checks if loading more records - pagination
       *
       * @returns {boolean} Flag if loading more records
       */
      self.isLoadingMoreRecords = function () {
        return loadingMoreRecords;
      };

      /**
       * Checks if the data table is initialised
       * @returns {boolean} Flag if records are initialised
       */
      self.isInitialised = function () {
        return dataLoader.isInitialised;
      };

      /**
       * Checks if records exist
       *
       * @returns {boolean} Flag if records exist
       */
      self.recordsExist = function () {
        return dataLoader.recordsExist;
      };

      /**
       * Returns number of total records
       * @returns {integer} Number of total records
       */
      self.totalRecords = function () {
        return dataLoader.totalRecords;
      };
    }

    /**
     * Returns instance of data table manager
     *
     * @param {string} entity Name of the entity
     * @param {string} endPoint Endpoint to be called, default index
     * @param {Object} params Additional parameters
     * @param {Object} options Additional params to configure record set
     * @returns {DataTableManager} Instance of data table manager
     */
    DataTableBase.create = function (entity, endPoint, params, options) {
      return new DataTableManager(entity, endPoint, params, options);
    };

    return DataTableBase;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name components.service:CreditCardUtil
   *
   * @description
   *
   */
  angular
    .module('components')
    .service('CreditCardUtil', CreditCardUtil);

  function CreditCardUtil() {
    var self = this,
        acceptedCreditCards = ['Visa', 'MasterCard', 'American Express'],
        expiryMonths = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        currentYear = new Date().getFullYear(),
        expiryYears = _.range(currentYear, currentYear + 20, 1);

    /**
     * Returns list of accepted credit cards
     *
     * @returns {string[]} Array of accepted credit cards
     */
    self.getAcceptedCards = function () {
      return acceptedCreditCards;
    };

    /**
     * Returns expiry months
     *
     * @returns {string[]} Array of months
     */
    self.getExpiryMonths = function () {
      return expiryMonths;
    };

    /**
     * Returns expiry years
     *
     * @returns {integer[]} Array of valid years
     */
    self.getExpiryYears = function () {
      return expiryYears;
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc factory
   * @name components.factory:ComponentsUtil
   *
   * @description
   *
   */
  angular
    .module('components')
    .factory('ComponentsUtil', ComponentsUtilFactory);

  function ComponentsUtilFactory() {
    return {

      /**
       * Returns the updated tableSubContentVisible object used across the MLP for tables in xs (sm) devices
       * @param {Object} subContent Subcontent
       * @param {int} idx Index
       * @return {Object} Updated content object.
       */
      toggleSubContent: function (subContent, idx) {
        if (!subContent) {
          subContent = {};
          subContent[idx] = true;
        }
        else if (_.contains(_.keys(subContent), idx.toString())) {
          // Check if the incoming idx is already added, Just toggle it.
          subContent[idx] = !subContent[idx];
        }
        else {
          // Delete everything and set as new
          subContent = {};
          subContent[idx] = true;
        }
        return subContent;
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name components.filter:address
   *
   * @description
   *
   * @param {Object} input The address object
   * @returns {String} Formatted Address
   *
   */
  angular
    .module('components')
    .filter('address', addressFilter);

  function addressFilter() {
    return function (input, locale) {
      var address = input,
          formats = {
            au: [['numberPostal', 'addressPostal'], ['suburbPostal', 'statePostalShort', 'postCodePostal'], 'countryPostal'],
            us: [['numberPostal', 'addressPostal'], 'suburbPostal', 'statePostalShort', 'postCodePostal', 'countryPostal']
          },
          format,
          output,
          innerOutput;

      format = formats[locale];
      if (!format) {
        format = formats.au;
      }

      if (address) {
        angular.forEach(format, function (key) {
          if (angular.isArray(key)) {
            // Keys in array are not separated by divider
            angular.forEach(key, function (k) {
              innerOutput = (innerOutput ? innerOutput + ' ' : '') + (address[k] ? address[k] : '');
            });
            if (innerOutput) {
              output = (output ? output + ', ' : '') + innerOutput;
            }
            innerOutput = '';
          }
          else if (address[key]) {
            output = (output ? output + ', ' : '') + address[key];
          }
        });
        return output;
      }

      return '';
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name cache
   * @description
   *
   */
  config.$inject = ["CacheFactoryProvider"];
  angular
    .module('cache', [
      'angular-cache'
    ]);

  angular
    .module('cache')
    .config(config);

  function config(CacheFactoryProvider) {
    angular.extend(CacheFactoryProvider.defaults, {
      storagePrefix: 'myleadpod.',
      maxAge: 864000000
    });
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name cache.service:Cache
   *
   * @description
   *
   */
  Cache.$inject = ["CacheFactory"];
  angular
    .module('cache')
    .service('Cache', Cache);

  function Cache(CacheFactory) {
    var self = this;

    self.create = function (name, options) {
      var cacheInstance = CacheFactory.get(name);
      if (!cacheInstance) {
        cacheInstance = CacheFactory.createCache(name, angular.extend({
          deleteOnExpire: 'aggressive',
          storageMode: 'localStorage'
        }, options));
      }

      return cacheInstance;
    };

    self.get = CacheFactory.get;
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name api
   * @description
   *
   */
  angular
    .module('api', [
      'cache'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name api.service:Session
   *
   * @description
   *
   */
  Session.$inject = ["$rootScope", "$q", "Restangular"];
  angular
    .module('api')
    .service('Session', Session);

  function Session($rootScope, $q, Restangular) {
    var self = this;

    self.data = {
      profile: null
    };
    self.loaded = false;
    self.loading = false;
    self.deferred = null;

    /**
     * Returns user profile
     *
     * @returns {Object} User profile
     */
    self.getUserProfile = function () {
      return self.getSession()
        .then(function (session) {
          return session.data.profile;
        });
    };

    /**
     * Updates user's profile
     *
     * @param {Object} profileData New profile data
     * @returns {Deferred} Deferred promise
     */
    self.updateUserProfile = function (profileData) {
      var deferred = $q.defer();
      Restangular.one('Users').customPUT(profileData, 'profile')
        .then(function (profile) {
          self.create(profile.plain());
          $rootScope.$broadcast('event:user-updateProfile', {profile: self.data.profile});
          deferred.resolve(self.data.profile);
        }, function () {
          deferred.reject();
        });

      return deferred.promise;
    };

    /**
     * Update local user's profile with new params
     *
     * @param {Object} params Update parameters
     * @param {boolean} broadcast Whether this new get requests should broadcast the event of the local user profile update
     * @returns {Object} User's profile
     */
    self.updateLocalUserProfile = function (params, broadcast) {
      if (angular.isObject(params)) {
        self.data.profile = angular.extend(self.data.profile, params);
        if (broadcast) {
          $rootScope.$broadcast('event:user-updateLocalProfile', params);
        }
      }

      return self.data.profile;
    };

    /**
     * Populates session from the provided data
     *
     * @param {Object} profile User's profile
     */
    self.create = function (profile) {
      self.destroy();
      if (angular.isObject(profile) && angular.isDefined(profile.idUser)) {
        self.data.profile = profile;
        self.data.profile.fullName = profile.firstName + ' ' + profile.lastName;
      }
      self.loaded = true;
      self.loading = false;
      if (self.deferred) {
        self.deferred.resolve(self);
      }
    };

    /**
     * Destroys the session
     */
    self.destroy = function () {
      self.data = {
        profile: null
      };
    };

    /**
     * Loads the Session
     *
     * @returns {Session} Current Session
     */
    self.loadSession = function () {
      if (!self.isLoading()) {
        self.loading = true;
        self.deferred = $q.defer();
        return Restangular.one('Users').one('check').get()
          .then(function (result) {
            if (result.status === true && angular.isObject(result.profile)) {
              return result.profile;
            }

            return null;
          })
          .then(function (userProfile) {
            self.create(userProfile);

            return self;
          });
      }

      return self.deferred.promise;
    };

    /**
     * Returns the Session
     *
     * @returns {Session} Current Session
     */
    self.getSession = function () {
      if (!self.isLoaded()) {
        return self.loadSession();
      }

      return $q.when(self);
    };

    /**
     * Check if the profile is valid
     *
     * @param {Object} profile Profile data to validate
     * @returns {boolean} Flag if profile is valid
     */
    self.isValidProfile = function (profile) {
      return angular.isObject(profile) && !!profile.idUser;
    };

    /**
     * Returns whether the user is signed in
     *
     * @returns {boolean} True or False
     */
    self.isSignedIn = function () {
      return self.getSession()
        .then(function (session) {
          return self.isValidProfile(session.data.profile);
        });
    };

    /**
     * Returns whether the session is already loaded
     *
     * @returns {boolean} True or False
     */
    self.isLoaded = function () {
      return !!self.loaded;
    };

    /**
     * Returns whether the session is currently loading
     *
     * @returns {boolean} True or False
     */
    self.isLoading = function () {
      return !!self.loading;
    };

    /**
     * Removes user's photo
     * @returns {Deferred} Deferred promise
     */
    self.removePhoto = function () {
      var deferred = $q.defer();
      Restangular.one('Users').one('photo').remove()
        .then(function () {
          self.data.profile.imageToken = null;
          $rootScope.$broadcast('event:user-updateProfile', {profile: self.data.profile});
          deferred.resolve(self.data.profile);
        }, function () {
          deferred.reject();
        });

      return deferred.promise;
    };

    /**
     * Updates user's photo
     * @param {string} dataUri Uri of photo
     * @returns {Deferred} Deferred promise
     */
    self.updatePhoto = function (dataUri) {
      var deferred = $q.defer();
      Restangular.one('Users').customPUT({
        dataUri: dataUri
      }, 'photoBase64')
        .then(function (result) {
          self.data.profile.imageToken = result.imageToken;
          $rootScope.$broadcast('event:user-updateProfile', {profile: self.data.profile});
          deferred.resolve(self.data.profile);
        }, function () {
          deferred.reject();
        });

      return deferred.promise;
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name api.factory:Ff3ApiInterceptor
   *
   * @description
   *
   */
  Ff3ApiInterceptor.$inject = ["$injector", "$q", "$rootScope"];
  moduleConfig.$inject = ["$httpProvider"];
  angular
    .module('api')
    .factory('Ff3ApiInterceptor', Ff3ApiInterceptor)
    .config(moduleConfig);

  function Ff3ApiInterceptor($injector, $q, $rootScope) {
    var Ff3ApiInterceptorBase = {};
    Ff3ApiInterceptorBase.request = function (config) {
      var
          apiParams = $injector.get('Auth').getSecurityParams(),
          baseUrl = $injector.get('configOptions').baseUrl;

      if (config.url.indexOf(baseUrl) === -1) {
        return config;
      }

      if (config.method === 'GET') {
        // We will be appending all the required parameters as query params
        if (!config.params) {
          config.params = apiParams;
        }
        else {
          angular.forEach(apiParams, function (value, key) {
            config.params[key] = value;
          });
        }
      }
      else {
        config.headers.Accept = 'application/json';
        angular.forEach(apiParams, function (value, key) {
          config.headers[key] = value;
        });
      }

      return config;
    };

    Ff3ApiInterceptorBase.responseError = function (rejection) {
      if (rejection.status === 403 || rejection.status === 401) {
        $rootScope.$broadcast('event:auth-loginRequired', rejection);
      }
      // otherwise, default behaviour
      return $q.reject(rejection);
    };

    return Ff3ApiInterceptorBase;
  }

  function moduleConfig($httpProvider) {
    $httpProvider.interceptors.push('Ff3ApiInterceptor');
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name api.service:Auth
   *
   * @description
   *
   */
  Auth.$inject = ["$rootScope", "$q", "$timeout", "$httpParamSerializer", "$injector", "Restangular", "configOptions", "Cache", "Session"];
  angular
    .module('api')
    .service('Auth', Auth);

  function Auth($rootScope, $q, $timeout, $httpParamSerializer, $injector, Restangular, configOptions, Cache, Session) {
    var self = this,
        cache = Cache.create('auth');

    self.localSignoutTimeout = 200;

    /**
     * Returns the login token
     *
     * @returns {string} Login token
     */
    function getLoginToken() {
      return cache.get('loginToken');
    }

    /**
     * Saves the login token
     *
     * @param {string} loginToken Login token
     */
    self.saveLoginToken = function (loginToken) {
      cache.put('loginToken', loginToken);
    };

    /**
     * Deletes the login token
     */
    function deleteLoginToken() {
      cache.remove('loginToken');
    }

    function localSignOut() {
      deleteLoginToken();
      Session.destroy();
      cache.removeAll();
      $rootScope.$broadcast('event:auth-logoutSuccess');
    }

    /**
     * Signs user in
     *
     * @param {string} endPoint API end point
     * @param {Object} data Sign In data
     * @returns {Deferred} Resolved value will be either Session in case of success or reason in case of failure
     */
    function signIn(endPoint, data) {
      var deferred = $q.defer();
      $injector.get('OneSignal').getUserId().then(function (playerId) {
        data.oneSignalPlayerId = playerId;
      });
      Restangular.one('Users').post(endPoint, data)
        .then(function (result) {
          self.saveLoginToken(result.authToken);
          Session.create(result.profile);
          $rootScope.$broadcast('event:auth-loginConfirmed');
          deferred.resolve(Session);
        }, function (result) {
          deferred.reject({
            errorMessage: result.data.errorMessage,
            errorCode: result.data.errorCode
          });
        });

      return deferred.promise;
    }

    /**
     * Returns whether the user is authenticated
     *
     * @returns {boolean} True or False
     */
    self.isAuthenticated = function () {
      return Session.isSignedIn();
    };

    /**
     * Signs user out
     *
     * @param {boolean} serverLogout If false server side sign out will not be performed as well, default true
     *
     * @returns {void}
     */
    self.signOut = function (serverLogout) {
      var data = {};
      if (angular.isUndefined(serverLogout)) {
        serverLogout = true;
      }
      if (serverLogout) {
        $injector.get('OneSignal').getUserId().then(function (playerId) {
          data.oneSignalPlayerId = playerId;
        });
        Restangular.one('Users').post('signOut', data);
      }
      $timeout(localSignOut, self.localSignoutTimeout);
    };

    /**
     * Signs user in using username and password
     *
     * @param {string} username Username
     * @param {string} password Password
     * @returns {Deferred} Resolved value will be either Session in case of success or reason in case of failure
     */
    self.passwordSignIn = function (username, password) {
      return signIn('signIn', {
        username: username,
        password: password
      });
    };

    /**
     * Signs user in through social services (OAuth2)
     *
     * @param {string} provider Social service provider
     * @param {string} accessToken OAuth2 Access Token
     * @param {Object} params Additional parameters
     * @returns {Deferred} Resolved value will be either Session in case of success or reason in case of failure
     */
    self.socialSignIn = function (provider, accessToken, params) {
      var deferred = $q.defer();
      if (!angular.isObject(params)) {
        params = {};
      }
      Restangular.one('Users').post('socialNetworkSignIn', angular.extend({}, {
        oauth2: {
          accessToken: accessToken,
          provider: provider
        }
      }, params))
        .then(function (result) {
          if (angular.isDefined(result.activationToken)) {
            deferred.resolve({
              activationToken: result.activationToken
            });
          }
          else {
            self.saveLoginToken(result.authToken);
            Session.create(result.profile);
            $rootScope.$broadcast('event:auth-loginConfirmed');
            deferred.resolve(Session);
          }
        }, function (result) {
          deferred.reject(result.data.errorMessage);
        });

      return deferred.promise;
    };

    /**
     * Activates user account
     *
     * @param {string} token Activation token
     * @param {Object} userProfile User profile data
     * @returns {Deferred} Deferred promise
     */
    self.activateAccount = function (token, userProfile) {
      return signIn('activate', {
        token: token,
        account: userProfile
      });
    };

    /**
     * Returns security params necessary to access an API endpoint via URL
     * @returns {{X-Jwt-Assertion: string, X-Ff3-Api-Key: string}} Object with security params
     */
    self.getSecurityParams = function () {
      var params = {
            'X-Ff3-Api-Key': configOptions.apiKey
          },
          token = getLoginToken();
      if (angular.isDefined(token) && token !== null && token !== '') {
        params['X-Jwt-Assertion'] = token;
      }

      return params;
    };

    /**
     * Fixes API endpoint URL
     * @param {string} apiEndpoint API Endpoint
     * @return {string} Full API endpoint including base url and auth params
     */
    self.fixApiEndpointUrl = function (apiEndpoint) {
      var tempUrl = configOptions.baseUrl,
          paramsStr = $httpParamSerializer(self.getSecurityParams());

      tempUrl += apiEndpoint;
      if (paramsStr !== '') {
        if (tempUrl.indexOf('?') !== -1) {
          tempUrl += '&' + paramsStr;
        }
        else {
          tempUrl += '?' + paramsStr;
        }
      }

      return tempUrl;
    };

    /**
     * Check if user has permission
     *
     * @param {Object} userProfile User's profile
     * @param {string} permission  Name of permission to check
     * @return {boolean} Flag indicating if user has the permission or not
     */
    self.hasUserPermission = function (userProfile, permission) {
      if (angular.isObject(userProfile) && angular.isString(permission)) {
        if (angular.isObject(userProfile.permissions) && angular.isDefined(userProfile.permissions[permission])) {
          return userProfile.permissions[permission] === true;
        }
      }
      return false;
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name api.constant:authEvents
   *
   * @description
   *
   */
  angular
    .module('api')
    .constant('authEvents', {
      loginSuccess: 'event:auth-loginConfirmed',
      loginFailed: 'event:auth-loginFailed',
      loginCancelled: 'event:auth-loginCancelled',
      logoutSuccess: 'event:auth-logoutSuccess',
      sessionTimeout: 'event:auth-sessionTimeout',
      sessionLoaded: 'event:auth-sessionLoaded',
      notAuthenticated: 'event:auth-loginRequired',
      notAuthorised: 'event:auth-notAuthorised'
    });
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name auth
   * @description
   *
   */
  angular
    .module('account', [
      'ui.router',
      'account.password',
      'account.profile',
      'account.billing',
      'account.earnings',
      'account.feedback',
      'account.preferences'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name account.controller:SignUpCtrl
   *
   * @description
   *
   */
  SignUpCtrl.$inject = ["$state", "$stateParams", "Restangular", "FormAlerts", "SocialAccount", "invitation", "rewardCode"];
  angular
    .module('account')
    .controller('SignUpCtrl', SignUpCtrl);

  function SignUpCtrl($state, $stateParams, Restangular, FormAlerts, SocialAccount, invitation, rewardCode) {
    var vm = this;
    vm.data = {};
    vm.alerts = new FormAlerts();
    vm.showMessage = false;
    vm.isLoading = false;
    vm.invitationSender = null;
    vm.invitationType = null;

    // For trial content to display.
    vm.trialEligible = false;
    vm.trialDuration = null;

    // Reward Code
    vm.rewardCode = null;
    vm.rewardCodeChecked = false;
    vm.isRewardCodeValid = false;

    // If it's coming as BOOL false val, then we tried to retrieve it in reward-program-routes but was not found
    if (rewardCode === false) {
      vm.rewardCodeChecked = true;
    }

    if (angular.isObject(invitation) && angular.isDefined(invitation.email)) {
      vm.invitationSender = invitation.userSender;
      vm.invitationType = invitation.invitationType;
      vm.data.email = invitation.email;
    }
    else if (angular.isObject(rewardCode) && angular.isDefined(rewardCode.owner)) {
      vm.invitationSender = rewardCode.owner;
      vm.invitationType = 'Reward Code';
      vm.rewardCode = $stateParams.rewardCode;
      if (rewardCode.rewardCode) {
        vm.trialEligible = rewardCode.rewardCode.isEligibleForBusinessAccountTrial;
        vm.trialDuration = rewardCode.rewardCode.businessAccountTrialDuration;
      }
    }

    vm.passwordSignUp = function () {
      vm.alerts.reset();
      vm.isLoading = true;
      Restangular.one('Users').post('signUp', {
        email: vm.data.email,
        password: vm.data.password,
        invitationToken: $stateParams.invitationToken,
        rewardCode: $stateParams.rewardCode || vm.rewardCode
      })
        .then(function (result) {
          if (angular.isDefined(result.activationToken)) {
            $state.go('accountActivate', {
              token: result.activationToken,
              invitationToken: $stateParams.invitationToken
            });
          }
          else {
            vm.showMessage = true;
          }
        }, function (error) {
          vm.isLoading = false;
          if (error.data && error.data.errorCode === 107) {
            vm.alerts.add('danger', 'This email is already registered. You can <a href="/account/signin">login here</a>.');
          }
          else {
            vm.alerts.add('danger', 'Unable to sign up');
          }
          console.log(error);
        });
    };

    vm.socialSignIn = function (provider) {
      vm.isLoading = true;
      vm.alerts.reset();
      SocialAccount.signIn(provider, {
        invitationToken: $stateParams.invitationToken,
        rewardCode: $stateParams.rewardCode
      })
        .then(function (result) {
          if (angular.isDefined(result.activationToken)) {
            $state.go('accountActivate', {
              token: result.activationToken,
              invitationToken: $stateParams.invitationToken
            });
          }
          else if (angular.isDefined($stateParams.invitationToken) && angular.isString($stateParams.invitationToken)) {
            $state.go('userInvitation', {token: $stateParams.invitationToken});
          }
          else {
            $state.go('dashboard');
          }
        }, function () {
          vm.isLoading = false;
          vm.alerts.add('danger', 'Unable to sign up');
        });
    };

    vm.checkRewardCode = function () {
      if (!vm.rewardCode) {
        vm.isRewardCodeValid = false;
        vm.rewardCodeChecked = false;
        return;
      }
      if (vm.rewardCode === vm.lastChecked) {
        return;
      }
      vm.rewardCardOwner = null;
      vm.lastChecked = vm.rewardCode;
      vm.rewardCode = vm.rewardCode.toUpperCase().replace(/\s+/g, '-');
      vm.isCheckingCode = true;
      Restangular.one('RewardCodes').one('detail').get({rewardCode: vm.rewardCode})
        .then(function (result) {
          vm.rewardCardOwner = result.owner;
          vm.rewardCodeChecked = true;
          vm.isCheckingCode = false;
          vm.isRewardCodeValid = true;
        }, function () {
          vm.isRewardCodeValid = false;
          vm.isCheckingCode = false;
          vm.rewardCodeChecked = true;
        });
    };

    vm.onRewardCodeEnter = function ($event) {
      if ($event.key === 'Enter') {
        vm.lastChecked = null;
        vm.checkRewardCode();
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name account.controller:SignInCtrl
   *
   * @description
   *
   */
  SignInCtrl.$inject = ["$state", "$stateParams", "FormAlerts", "Auth", "SocialAccount"];
  angular
    .module('account')
    .controller('SignInCtrl', SignInCtrl);

  function SignInCtrl($state, $stateParams, FormAlerts, Auth, SocialAccount) {
    var vm = this,
        afterSignInCallback;
    vm.data = {};
    vm.alerts = new FormAlerts();
    vm.isLoading = false;

    afterSignInCallback = function () {
      if (angular.isDefined($stateParams.invitationToken) && angular.isString($stateParams.invitationToken)) {
        $state.go('userInvitation', {token: $stateParams.invitationToken});
      }
      else if (angular.isDefined($stateParams.rewardCode) && angular.isString($stateParams.rewardCode)) {
        $state.go('myMembershipsRewardProgramCode', {code: $stateParams.rewardCode});
      }
      else if (angular.isDefined($stateParams.nextState) && angular.isObject($stateParams.nextState) && angular.isDefined($stateParams.nextState.name)) {
        $state.go($stateParams.nextState.name, $stateParams.nextState.params || {});
      }
      else {
        $state.go('dashboard');
      }
    };

    vm.passwordSignIn = function () {
      vm.isLoading = true;
      vm.alerts.reset();
      Auth.passwordSignIn(vm.data.username, vm.data.password)
        .then(afterSignInCallback, function () {
          vm.isLoading = false;
          vm.alerts.add('danger', 'Unable to login');
        });
    };

    vm.socialSignIn = function (provider) {
      vm.isLoading = true;
      vm.alerts.reset();
      SocialAccount.signIn(provider)
        .then(function (result) {
          if (angular.isDefined(result.activationToken)) {
            $state.go('accountActivate', {
              token: result.activationToken,
              nextState: $stateParams.nextState,
              invitationToken: $stateParams.invitationToken
            });
          }
          else {
            afterSignInCallback();
          }
        }, function () {
          vm.isLoading = false;
          vm.alerts.add('danger', 'Unable to login');
        });
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name account.service:BusinessAccount
   *
   * @description
   *
   */
  BusinessAccount.$inject = ["$rootScope", "$document", "$q", "$uibModal", "Restangular", "Auth", "Session", "Cache", "authEvents"];
  angular
    .module('account')
    .service('BusinessAccount', BusinessAccount);

  function BusinessAccount($rootScope, $document, $q, $uibModal, Restangular, Auth, Session, Cache, authEvents) {
    var self = this,
        userProfile = Session.getUserProfile(),
        loginWatchHandle = null;

    // Get the cache that keeps the businessSuggestion records at the init of this service.
    self.suggestionCache = Cache.create('businessSuggestion');

    // Subscribe to the update of the user profile.
    loginWatchHandle = $rootScope.$on(authEvents.loginSuccess, function () {
      userProfile = Session.getUserProfile();
    });

    // Remove the handle once it's all destroyed
    $rootScope.$on('$destroy', loginWatchHandle);

    function openBusinessAccountPopup(domNode, isTrial) {
      var modalInstance = $uibModal.open({
        animation: false,
        appendTo: domNode,
        templateUrl: isTrial ? 'account/business-account-trial.tpl.html' : 'account/business-account.tpl.html',
        controller: 'BusinessAccountCtrl',
        controllerAs: 'businessAccount',
        size: 'md',
        windowClass: 'modal-business-account',
        backdrop: 'static',
        resolve: {
          userProfile: function () {
            return Session.getUserProfile();
          },
          pricingDetails: function () {
            return Restangular.one('BusinessAccount').one('pricingDetails').get();
          },
          isTrial: function () {
            return isTrial;
          }
        }
      });

      return modalInstance;
    }

    function getDomNode(n) {
      var dn = null;
      if (angular.isUndefined(n)) {
        console.error('The target domNode is not specified.');
        return null;
      }

      if (angular.isString(n)) {
        dn = angular.element($document[0].getElementById(n));
      }
      else if (n.nodeName) {
        dn = angular.element(n);
      }
      else {
        console.error('Unsupported type for the target domNode. Please provide the ID or the HTMLElement reference');
        return null;
      }
      return dn;
    }

    /**
     * Activates user's business account
     *
     * @param {Object} data        New profile data
     * @param {Bool}   skipPayment Flag indicating if payment can be skipped
     * @return {Deferred} Deferred promise
     */
    self.activate = function (data, skipPayment) {
      var deferred = $q.defer(),
          action = 'activate';

      if (!angular.isObject(data)) {
        data = {};
      }

      if (angular.isUndefined(skipPayment) && skipPayment !== true && skipPayment !== false) {
        skipPayment = false;
      }

      if (skipPayment === true) {
        action = 'activateWithoutPayment';
      }

      Restangular.one('BusinessAccount').customPUT(data, action)
        .then(function (result) {
          var profile = result.profile;
          profile.isBusinessAccountActive = result.isBusinessAccountActive;
          Session.create(profile);
          $rootScope.$broadcast('event:user-updateProfile', {profile: profile});
          deferred.resolve(profile);
        }, function (responseData) {
          deferred.reject({
            errorCode: responseData.data.errorCode,
            errorMessage: responseData.data.errorMessage
          });
        });

      return deferred.promise;
    };

    self.activateTrial = function (data) {
      var deferred = $q.defer(),
          action = 'activateTrial';

      if (!angular.isObject(data)) {
        data = {};
      }

      Restangular.one('BusinessAccount').customPUT(data, action)
        .then(function (result) {
          var profile = result.profile;
          profile.isBusinessAccountActive = result.isBusinessAccountActive;
          Session.create(profile);
          $rootScope.$broadcast('event:user-updateProfile', {profile: profile});
          deferred.resolve(profile);
        }, function (responseData) {
          deferred.reject({
            errorCode: responseData.data.errorCode,
            errorMessage: responseData.data.errorMessage
          });
        });

      return deferred.promise;
    };

    /**
     * Initiate activation
     *
     * @param {string | HTMLElement} domNode Id of the dom node or the HTML Element directly into which the popup will be populated
     * @param {bool} isTrial If the template to be loaded should be the one for the TRIAL activation (evaluation subscription)
     * @return {IPromise<T>} Deferred promise
     */
    self.initiateActivation = function (domNode, isTrial) {
      var deferred = $q.defer(),
          dn = getDomNode(domNode);

      if (!dn) {
        throw new Error('The target domNode for the popup is not specified or is unrecognized.');
      }

      userProfile.then(function (profile) {
        if (Auth.hasUserPermission(profile, 'activateBusinessAccountWithoutPayment')) {
          self.activate({}, true)
            .then(function (result) {
              deferred.resolve(result);
            }, function () {
              deferred.reject();
            });
        }
        else {
          openBusinessAccountPopup(dn, isTrial).result.then(function (result) {
            if (result) {
              deferred.resolve(result);
            }
            else {
              deferred.reject();
            }
          }, function () {
            deferred.reject();
          });
        }
      });

      return deferred.promise;
    };

    /**
     * Returns the suggestions count
     * @returns {number} Suggestions count
     */
    self.getSuggestionsCount = function () {
      var count = 0;
      if (angular.isDefined(self.suggestionCache) && self.suggestionCache.get('count')) {
        count = self.suggestionCache.get('count');
      }
      return count;
    };

    /**
     * Returns trial suggestions count
     * @returns {number} Trial suggestions count
     */
    self.getTrialSuggestionsCount = function () {
      var count = 0;
      if (angular.isDefined(self.suggestionCache) && self.suggestionCache.get('trialCount')) {
        count = self.suggestionCache.get('trialCount');
      }
      return count;
    };

    /**
     * Updates the suggestion count in the cache.
     * @param {number} count Initial count
     */
    self.updateSuggestionCount = function (count) {
      if (angular.isDefined(self.suggestionCache)) {
        self.suggestionCache.put('count', count + 1);
        self.suggestionCache.put('trialCount', count + 1);
      }
    };

    /**
     * Updates trial suggestions count
     * @param {number} count Initial count
     */
    self.updateTrialSuggestionCount = function (count) {
      if (angular.isDefined(self.suggestionCache)) {
        self.suggestionCache.put('trialCount', count + 1);
      }
    };

    /**
     * Returns the trial data
     * @param {Object} profile User's profile
     * @returns {Object} Trial data
     */
    self.getTrialData = function (profile) {
      var data;

      if (!profile) {
        return {};
      }

      data = {
        isEligibleForTrial: profile.businessAccount.isEligibleForTrial,
        isInTrial: profile.businessAccount.isInTrial,
        expiresIn: profile.businessAccount.trialExpiresInDays,
        trialWarning: profile.businessAccount.trialExpiresInDays <= 7,
        trialExpiresIn: null,
        trialExpiryDate: new Date(profile.businessAccount.trialExpiryDate)
      };

      if (data.isInTrial) {
        if (data.expiresIn === 0) {
          data.trialExpiresIn = ' expires today';
        }
        else if (data.expiresIn === 1) {
          data.trialExpiresIn = ' will expire in 1 day';
        }
        else {
          data.trialExpiresIn = ' will expire in ' + data.expiresIn + ' days';
        }
      }

      return data;
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name account.controller:BusinessAccountCtrl
   *
   * @description
   *
   */
  BusinessAccountCtrl.$inject = ["$window", "$scope", "$timeout", "$uibModalInstance", "$state", "PromptDialog", "BusinessAccount", "FormAlerts", "userProfile", "pricingDetails", "Industries", "RewardCodes", "Location", "GoogleMap", "Restangular", "addressFilter", "isTrial"];
  angular
    .module('account')
    .controller('BusinessAccountCtrl', BusinessAccountCtrl);

  function BusinessAccountCtrl($window, $scope, $timeout, $uibModalInstance, $state, PromptDialog, BusinessAccount, FormAlerts, userProfile,
                               pricingDetails, Industries, RewardCodes, Location, GoogleMap, Restangular, addressFilter, isTrial) {
    var vm = this;
    vm.alerts = new FormAlerts();
    vm.isLoading = false;
    vm.hasRewardCode = false;
    vm.rewardCodeChecked = false;
    vm.rewardCodeValid = false;
    vm.rewardCodeValidating = false;
    vm.rewardCodeHelp1 = 'Create your first Reward Code to offer to your referral network (one word, alpha numeric). With this step your default Reward Program will be created and used for this Reward Code';
    vm.rewardCodeHelp2 = 'Examples: MYCOMPANYNAME123, CODE123, SUMMERPROMOTION, ...\n\nPlease choose between 5 to 20 characters.';
    vm.getCountries = Location.getCountries;
    vm.pricingDetails = pricingDetails.pricingOptions;
    vm.getStates = function () {
      return Location.getStates(vm.data.addressPostal.idCountryPostal);
    };
    vm.showRewardCard = true;
    vm.data = {
      businessName: userProfile.businessName,
      idClassification: null,
      businessMarketingPayment: vm.pricingDetails && vm.pricingDetails[0] ? vm.pricingDetails[0].id : -1,
      creditCard: {
        method: null
      },
      address: null,
      addressPostal: {
        address: null,
        numberPostal: null,
        addressPostal: userProfile.addressPostal.addressPostal,
        suburbPostal: userProfile.addressPostal.suburbPostal,
        postCodePostal: userProfile.addressPostal.postCodePostal,
        idStatePostal: angular.isDefined(userProfile.addressPostal.idStatePostal) && userProfile.addressPostal.idStatePostal !== null ? userProfile.addressPostal.idStatePostal.toString() : '',
        idCountryPostal: angular.isDefined(userProfile.addressPostal.idCountryPostal) && userProfile.addressPostal.idCountryPostal !== null ? userProfile.addressPostal.idCountryPostal.toString() : '',
        statePostal: userProfile.addressPostal.idCountryPostal && userProfile.addressPostal.idStatePostal ? Location.getStateNameById(userProfile.addressPostal.idCountryPostal, userProfile.addressPostal.idStatePostal) : '',
        statePostalShort: userProfile.addressPostal.idCountryPostal && userProfile.addressPostal.idStatePostal ? Location.getStateNameById(userProfile.addressPostal.idCountryPostal, userProfile.addressPostal.idStatePostal, true) : '',
        countryPostal: userProfile.addressPostal.idCountryPostal ? Location.getCountryNameById(userProfile.addressPostal.idCountryPostal) : ''
      }
    };

    vm.paymentSummary = {
      price: null,
      tax: null,
      total: null
    };

    Restangular.one('Billing').one('creditCard').get()
      .then(function (cc) {
        cc = cc.plain();
        vm.data.creditCard.method = angular.isObject(cc) && angular.isDefined(cc.number) ? 'old' : 'new';
        vm.creditCard = cc;
        return cc;
      }, function () {
        return null;
      });

    vm.formatAddress = function () {
      vm.data.addressPostal.address = addressFilter(vm.data.addressPostal);
    };

    // Get the industry
    if (angular.isObject(userProfile.subIndustry)) {
      vm.data.idClassification = userProfile.subIndustry.id.toString();
      vm.selectedClassification = {id: userProfile.subIndustry.id, name: userProfile.industry.name + ' - ' + userProfile.subIndustry.name};
    }
    else if (angular.isObject(userProfile.industry)) {
      vm.data.idClassification = userProfile.industry.id.toString();
      vm.selectedClassification = {id: userProfile.industry.id, name: userProfile.industry.name};
    }

    // Check if the user already has a reward code so we can skip the reward code step
    if (userProfile && userProfile.rewardCodes && _.where(userProfile.rewardCodes, {isArchived: false}).length > 0) {
      vm.hasRewardCode = true;
    }

    // Get the trial data for the user. We only need the expiry date
    if (userProfile) {
      vm.trialData = BusinessAccount.getTrialData(userProfile);
    }

    Industries.isReady.then(function () {
      vm.industries = Industries.getConsolidatedList();
    });

    vm.onClassificationSelect = function (classification) {
      if (angular.isObject(classification) && angular.isObject(classification.originalObject)) {
        vm.data.idClassification = classification.originalObject.id;
      }
    };

    vm.onRewardCodeChange = function () {
      vm.rewardCodeChecked = false;
    };

    vm.onCountryChange = function (form) {
      vm.data.addressPostal.idStatePostal = '';
      if (form && form.idStatePostal) {
        delete form.idStatePostal.$touched;
        delete form.idStatePostal.$error.required;
      }
    };

    vm.validateRewardCode = function () {
      var code;
      if (vm.data.rewardCode && vm.data.rewardCode.length >= 5 && vm.data.rewardCode.length <= 20) {
        code = vm.data.rewardCode.toUpperCase().replace(/\s+/g, '-');
        vm.data.rewardCode = code.replace(/[-]+/g, '-');
        vm.onDataChange('code');
        vm.rewardCodeValidating = true;
        RewardCodes.isRewardCodeUnique(vm.data.rewardCode)
          .then(function (isUnique) {
            vm.rewardCodeValid = isUnique;
            vm.rewardCodeValidating = false;
            vm.rewardCodeChecked = true;
          });
      }
    };

    vm.isLeadPriceValid = function () {
      return !isNaN(vm.data.successfulLeadPrice) && vm.data.successfulLeadPrice >= 0;
    };

    vm.cancel = function () {
      PromptDialog.open('Are you sure you\'d like to cancel the activation?<br/><span class="prompt-subtext">You can access this activation window again from Profile Menu -> Business Account</span>', null, 'Cancel Activation', 'Go Back')
        .then(function () {
          $uibModalInstance.dismiss();
        });
    };

    vm.onDataChange = function (type) {
      var el,
          value;
      try {
        if (type === 'name') {
          el = angular.element('#RewardCard-front')[0].contentDocument.getElementById('RewardCard-CompanyName');
          value = vm.data.businessName;
          if (value.length > 20) {
            value = value.substr(0, 20) + '..';
          }
        }
        if (type === 'code') {
          el = angular.element('#RewardCard-back')[0].contentDocument.getElementById('RewardCard-Code');
          value = vm.data.rewardCode || '';
          if (value.length > 15) {
            el.style.fontSize = '18px';
          }
          else {
            el.style.fontSize = '20px';
          }
        }
        if (type === 'value') {
          el = angular.element('#RewardCard-front')[0].contentDocument.getElementById('RewardCard-RewardValue').firstElementChild;
          value = vm.data.successfulLeadPrice || 0;
          if (Number(value) > 999) {
            el.style.fontSize = '24px';
          }
          else {
            el.style.fontSize = '36px';
          }
        }

        if (el) {
          el.innerHTML = value;
        }
        vm.showRewardCard = true;
      }
      catch (ex) {
        console.log(ex);
        vm.showRewardCard = false;
      }
    };

    vm.isAddressIncomplete = function () {
      return !vm.data.addressPostal.addressPostal ||
      !vm.data.addressPostal.suburbPostal ||
      !vm.data.addressPostal.postCodePostal ||
      !vm.data.addressPostal.idStatePostal ||
      !vm.data.addressPostal.idCountryPostal;
    };

    vm.getUserLocationAddress = function () {
      // From geolocation
      vm.isGeolocating = true;
      GoogleMap.promise.then(function () {
        GoogleMap.getCurrentLocation(true).then(function (result) {
          // result.location
          vm.data.addressPostal.address = result.location;
          vm.data.addressPostal.numberPostal = result.streetNumber;
          vm.data.addressPostal.addressPostal = result.streetAddress;
          vm.data.addressPostal.suburbPostal = result.suburb;
          vm.data.addressPostal.postCodePostal = result.postCode;
          vm.data.addressPostal.statePostalShort = result.state;
          vm.data.addressPostal.countryPostal = result.country;
          vm.updateStateCountryIds(result);
          vm.formatAddress();
          vm.isGeolocating = false;
          // vm.getLocationScreenshot();
        }, vm.geolocationFailCallback);
      }, vm.geolocationFailCallback);
    };

    vm.geolocationFailCallback = function () {
      // There is some issue with the geolocation. disable the button.
      vm.isGeolocationDisabled = true;
      vm.isGeolocating = false;
      $timeout(function () {
        $scope.$apply();
      }, 0);
    };

    vm.placeChangedCallback = function (data) {
      // From google maps geocoding (autocomplete)
      vm.data.addressPostal.address = data.location;
      vm.data.addressPostal.numberPostal = data.streetNumber;
      vm.data.addressPostal.addressPostal = data.streetAddress;
      vm.data.addressPostal.suburbPostal = data.suburb;
      vm.data.addressPostal.postCodePostal = data.postCode;
      vm.data.addressPostal.statePostalShort = data.state;
      vm.data.addressPostal.countryPostal = data.country;
      vm.updateStateCountryIds(data);
      vm.formatAddress();
      $scope.$apply();
      // vm.getLocationScreenshot();
    };

    vm.updateStateCountryIds = function (result) {
      vm.data.addressPostal.idCountryPostal = Location.getCountryId(result.country_id);
      vm.data.addressPostal.idStatePostal = Location.getStateId(vm.data.addressPostal.idCountryPostal, result.state);
      vm.data.addressPostal.idCountryPostal = vm.data.addressPostal.idCountryPostal ? vm.data.addressPostal.idCountryPostal.toString() : null;
      vm.data.addressPostal.idStatePostal = vm.data.addressPostal.idStatePostal ? vm.data.addressPostal.idStatePostal.toString() : null;
    };

    vm.getLocationScreenshot = function () {
      var el;
      vm.isRenderingLocation = true;
      try {
        el = angular.element('.location-screenshot');
        console.log(el.height(), el.width());
        GoogleMap.getStaticMapAsBase64(vm.data.addressPostal.address, {
          w: el.width(),
          h: el.height()
        }).then(function (base64) {
          angular.element('img', el)[0].src = base64;
          vm.isRenderingLocation = false;
        });
      }
      catch (ex) {
        vm.isRenderingLocation = false;
      }
    };

    vm.updateSummary = function () {
      // Get the active pricing option
      var p = _.find(vm.pricingDetails, {id: vm.data.businessMarketingPayment});
      if (p) {
        vm.paymentSummary.price = p.price;
        vm.paymentSummary.total = p.priceInclGst;
        vm.paymentSummary.tax = vm.paymentSummary.total - vm.paymentSummary.price;
      }
    };

    vm.save = function () {
      vm.alerts.reset();
      vm.isLoading = true;

      if (isTrial) {
        BusinessAccount.activateTrial(vm.data).then(function (profile) {
          $uibModalInstance.close(profile);
          $state.go('myMembershipsRewardProgramsSetup', {openInvite: true});
        }, function (error) {
          vm.isLoading = false;
          if (error.errorCode === 701 || error.errorCode === 702 || error.errorCode === 703) {
            vm.alerts.add('danger', 'Unable to activate the trial: ' + error.errorMessage);
          }
          else {
            vm.alerts.add('danger', 'Unable to activate the trial, try again later please');
          }
        });
      }
      else {
        BusinessAccount.activate(vm.data).then(function (profile) {
          $uibModalInstance.close(profile);
          // Navigate to Rewards Program Page
          $state.go('myMembershipsRewardProgramsSetup', {openInvite: true});
        }, function (error) {
          vm.isLoading = false;
          if (error.errorCode === 701 || error.errorCode === 702 || error.errorCode === 703) {
            vm.alerts.add('danger', 'Unable to activate: ' + error.errorMessage);
          }
          else {
            vm.alerts.add('danger', 'Unable to activate, try again later please');
          }
        });
      }
    };

    vm.formatAddress();
    vm.isMobile = $window.innerWidth < 768;
    angular.element($window).on('resize', function () {
      vm.isMobile = $window.innerWidth < 768;
      $scope.$apply();
    });
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name account.controller:ActivateUpdatePasswordCtrl
   *
   * @description
   *
   */
  ActivateUpdatePasswordCtrl.$inject = ["$rootScope", "$state", "Restangular", "Auth", "Session", "FormAlerts", "userProfile", "OneSignal", "configOptions"];
  angular
    .module('account')
    .controller('ActivateUpdatePasswordCtrl', ActivateUpdatePasswordCtrl);

  function ActivateUpdatePasswordCtrl($rootScope, $state, Restangular, Auth, Session, FormAlerts, userProfile, OneSignal, configOptions) {
    var vm = this,
        oneSignalPlayerId;

    OneSignal.getUserId().then(function (playerId) {
      oneSignalPlayerId = playerId;
    });
    vm.alerts = new FormAlerts();
    vm.isLoading = false;
    vm.userProfile = userProfile;
    vm.data = {
      password: null,
      confirmPassword: null
    };

    vm.activate = function () {
      vm.alerts.reset();
      vm.isLoading = true;
      Restangular.one('Users').customPUT({
        newPassword: vm.data.password,
        oneSignalPlayerId: oneSignalPlayerId
      }, 'password')
        .then(function (result) {
          configOptions.reportRegistration();
          result = result.plain();
          vm.isLoading = false;
          Auth.saveLoginToken(result.authToken);
          Session.create(result.profile);
          $rootScope.$broadcast('event:user-updateProfile', {profile: result.profile});
          $rootScope.$broadcast('event:auth-loginConfirmed');
          $state.go('dashboard');
        }, function () {
          vm.alerts.add('danger', 'Unable to save');
          vm.isLoading = false;
        });
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name account.controller:ActivateCtrl
   *
   * @description
   *
   */
  ActivateCtrl.$inject = ["$document", "$state", "$stateParams", "FormAlerts", "Auth", "BusinessAccount", "userRegistration", "GoogleMap", "configOptions"];
  angular
    .module('account')
    .controller('ActivateCtrl', ActivateCtrl);

  function ActivateCtrl($document, $state, $stateParams, FormAlerts, Auth, BusinessAccount, userRegistration, GoogleMap, configOptions) {
    var vm = this;

    vm.data = userRegistration;
    vm.alerts = new FormAlerts();
    vm.isLoading = false;
    vm.data.newsletter = true;
    vm.data.rewardCode = userRegistration.rewardCode;
    vm.rewardCodeReadOnly = !!userRegistration.rewardCode;
    vm.rewardCodeLabel = userRegistration.rewardCode;
    vm.emailVisible = !userRegistration.email;
    vm.passwordVisible = !userRegistration.isPasswordSet;

    // Help texts
    vm.rewardCodeHelp = 'If you have a Reward Code from an existing MyLeadPod business, enter it below to directly connect with this business after activation. (Optional)';

    vm.data.address = {
      streetAddress: userRegistration.streetAddress,
      suburb: userRegistration.suburb,
      state: userRegistration.state,
      postCode: userRegistration.postCode,
      country: userRegistration.country
    };

    vm.activate = function () {
      vm.isLoading = true;
      vm.alerts.reset();
      Auth.activateAccount($stateParams.token, {
        firstName: vm.data.firstName,
        lastName: vm.data.lastName,
        businessName: vm.data.businessName,
        address: vm.data.address,
        phoneMobile: vm.data.phoneMobile,
        newsletter: vm.data.newsletter,
        rewardCode: vm.data.rewardCode,
        email: vm.data.email,
        password: vm.data.password
      })
        .then(function (newSession) {
          var userProfile = newSession.data.profile;

          configOptions.reportRegistration();

          if (angular.isDefined($stateParams.invitationToken) && angular.isString($stateParams.invitationToken)) {
            $state.go('userInvitation', {
              token: $stateParams.invitationToken
            });
          }
          else if (angular.isDefined(userRegistration.idUserRewardCodeOwner) && userRegistration.idUserRewardCodeOwner !== null && angular.isDefined(userRegistration.redirectToUserProfile) && userRegistration.redirectToUserProfile === true) {
            $state.go('user', {id: userRegistration.idUserRewardCodeOwner, isInvite: true});
          }
          else {
            $state.go('dashboard');

            if (angular.isObject(userProfile.businessAccount) && userProfile.businessAccount.isEligibleForTrial === true) {
              BusinessAccount.initiateActivation(angular.element($document[0].body)[0], true);
            }
          }
        }, function (error) {
          vm.isLoading = false;
          if (angular.isObject(error) && error.errorCode === 602) {
            vm.alerts.add('danger', 'Invalid Reward Code');
          }
          else {
            vm.alerts.add('danger', 'Unable to activate');
          }
        });
    };

    vm.placeChangedCallback = function (address) {
      vm.data.address = address;
    };

    if (!vm.data.address.streetAddress) {
      GoogleMap.promise.then(function () {
        GoogleMap.getCurrentLocation().then(function (result) {
          vm.data.location = result.location;
          vm.data.address = result;
        });
      });
    }
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name account.service:AccountService
   *
   * @description
   *
   */
  AccountService.$inject = ["$uibModal", "$document", "Session"];
  angular
    .module('account')
    .service('AccountService', AccountService);

  function AccountService($uibModal, $document, Session) {
    var scope = this;

    /**
     * Opens the modal dialog for the preferences settings
     *
     * @param {object} userProfile object User profile for which the preferences will be loaded
     * @returns {object} UibModalInstance result
     */
    scope.openPreferences = function (userProfile) {
      var modalInstance,
          params = {
            animation: false,
            templateUrl: 'account/preferences/preferences-modal.tpl.html',
            controller: 'PreferencesCtrl',
            controllerAs: 'preferences',
            size: 'lg',
            resolve: {
              userProfile: function () {
                return userProfile;
              }
            },
            appendTo: angular.element($document[0].body)
          };

      modalInstance = $uibModal.open(params);
      return modalInstance.result;
    };

    scope.openFeedback = function (userProfile, helpRequired) {
      var modalInstance,
          params = {
            animation: false,
            templateUrl: 'account/feedback/feedback-modal.tpl.html',
            controller: 'FeedbackCtrl',
            controllerAs: 'feedback',
            size: 'lg',
            resolve: {
              userProfile: function () {
                if (userProfile) {
                  return userProfile;
                }
                return Session.getUserProfile();
              },
              helpRequired: function () {
                return helpRequired;
              }
            },
            appendTo: angular.element($document[0].body)
          };

      modalInstance = $uibModal.open(params);
      return modalInstance.result;
    };
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('account')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('account', {
        url: '/account',
        templateUrl: 'account/account.tpl.html',
        controller: 'AccountCtrl',
        controllerAs: 'account'
      })
      .state('accountSignInUp', {
        abstract: true,
        template: '<ui-view/>',
        params: {
          invitationToken: null,
          rewardCode: null
        },
        resolve: {
          rewardCode: ['Restangular', '$stateParams', function (Restangular, $stateParams) {
            if ($stateParams.rewardCode && angular.isString($stateParams.rewardCode) && $stateParams.rewardCode.length > 0) {
              return Restangular.one('RewardCodes').one('detail').get({rewardCode: $stateParams.rewardCode})
                .then(function (rewardCode) {
                  return rewardCode.plain();
                }, function () {
                  return false;
                });
            }
            return null;
          }]
        },
        data: {
          classNames: 'no-help'
        }
      })
      .state('accountSignInUp.accountSignIn', {
        url: '/account/signin',
        templateUrl: 'account/sign-in.tpl.html',
        controller: 'SignInCtrl',
        controllerAs: 'signIn',
        params: {
          nextState: {}
        },
        data: {
          classNames: 'no-help requires-login'
        }
      })
      .state('accountSignInUp.accountSignUp', {
        url: '/account/signup',
        templateUrl: 'account/sign-up.tpl.html',
        controller: 'SignUpCtrl',
        controllerAs: 'signUp',
        resolve: {
          invitation: ['Restangular', '$stateParams', function (Restangular, $stateParams) {
            if ($stateParams.invitationToken) {
              return Restangular.one('Invitations').one('detail').get({token: $stateParams.invitationToken})
                .then(function (invitation) {
                  return invitation.plain();
                }, function () {
                  return null;
                });
            }

            return null;
          }]
        },
        data: {
          classNames: 'no-help requires-signup'
        }
      })
      .state('accountActivate', {
        url: '/account/activate/{token}',
        templateUrl: 'account/activate.tpl.html',
        controller: 'ActivateCtrl',
        controllerAs: 'activate',
        params: {
          invitationToken: null
        },
        resolve: {
          userRegistration: ['$stateParams', '$state', 'Restangular', function ($stateParams, $state, Restangular) {
            return Restangular.one('Users').one('activate').get({token: $stateParams.token})
              .then(function (result) {
                return result.plain();
              }, function () {
                $state.go('accountSignInUp.accountSignIn');
              });
          }]
        }
      })
      .state('accountVerifyRegistration', {
        url: '/account/verify/{token}',
        templateUrl: 'account/activate-update-password.tpl.html',
        controller: 'ActivateUpdatePasswordCtrl',
        controllerAs: 'activate',
        params: {
          invitationToken: null
        },
        resolve: {
          userProfile: ['$stateParams', '$state', '$q', 'Auth', 'Session', 'Restangular', function ($stateParams, $state, $q, Auth, Session, Restangular) {
            var userProfileDeferred = $q.defer();
            Auth.signOut(false);
            Restangular.one('Users').one('verify').get({token: $stateParams.token})
              .then(function (result) {
                var userProfile = result.profile,
                    token = result.authToken;

                // If this is not a valid token, then just go to sign in.
                if (!token || !userProfile) {
                  $state.go('accountSignInUp.accountSignIn');
                  return;
                }

                // Save it into the cache
                Auth.saveLoginToken(result.authToken);

                // Create the session
                Session.create(userProfile);

                // If this is the first time and user needs to provide the password, go ahead
                if (!userProfile.isPasswordSet) {
                  userProfileDeferred.resolve(userProfile);
                }
                else {
                  // Forward him to sign in. That should in turn go to dashboard?
                  $state.go('accountSignInUp.accountSignIn');
                }
              }, function () {
                $state.params = angular.extend($state.params, {logout: true});
                $state.go('accountSignInUp.accountSignIn');
              });

            return userProfileDeferred.promise;
          }]
        }
      })
      .state('accountUnsubscribe', {
        url: '/account/mailing-list/unsubscribe/{userId}/{subscriptionId}',
        templateUrl: 'account/preferences/mailing-list-unsubscribe.tpl.html',
        controller: 'MailingListUnsubscribeCtrl',
        controllerAs: 'preferences',
        data: {
          classNames: 'mailing-list-unsubscribe'
        }
      });
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name account.controller:AccountCtrl
   *
   * @description
   *
   */
  AccountCtrl.$inject = ["Session"];
  angular
    .module('account')
    .controller('AccountCtrl', AccountCtrl);

  function AccountCtrl(Session) {
    var vm = this;

    Session.getUserProfile()
      .then(function (profile) {
        if (profile) {
          vm.userProfile = profile;
        }
      });

    vm.tabManager = null;
    vm.tabsConfig = [
      {
        label: 'Profile',
        value: 'profile',
        url: 'account.profile'
      },
      {
        label: 'Billing',
        value: 'billing',
        url: 'account.billing',
        condition: function () {
          return vm.userProfile.isBusinessAccountActive;
        }
      },
      {
        label: 'Earnings',
        value: 'earnings',
        url: 'account.earnings'
      }
    ];
  }
}());

(function () {
  'use strict';

  angular
    .module('mlp-animations', ['ngAnimate'])
    .animation('.slide', slide)
    .animation('.slide-fast', slideFast)
    .animation('.slide-if', slideIf)
    .animation('.slide-if-reversed', slideIfReversed)
    .animation('.slide-if-flex', slideIfFlex)
    .animation('.slide-left', slideLeft)
    .animation('.slide-right', slideRight)
    .animation('.opacity-if', opacityIf)
    .animation('.opacity-slow-if', opacitySlowIf);

  function slide() {
    return {
      addClass: function (element, className, done) {
        element.css('display', 'none');
        angular.element(element).slideDown('fast', done);
      },
      removeClass: function (element, className, done) {
        angular.element(element).slideUp('fast', done);
      }
    };
  }

  function slideFast() {
    return {
      addClass: function (element, className, done) {
        element.css('display', 'none');
        angular.element(element).slideDown(100, done);
      },
      removeClass: function (element, className, done) {
        angular.element(element).slideUp(100, done);
      }
    };
  }

  function slideIf() {
    // Remember to include the slide-if class in the app.scss with the "display: none" otherwise the slideDown won't be smooth
    // slideUp would not be affected as the element would be already visible.

    return {
      enter: function (element, done) {
        angular.element(element).slideDown('fast', done);
      },
      move: function (element, done) {
        angular.element(element).slideDown('fast', done);
      },
      leave: function (element, done) {
        angular.element(element).slideUp('fast', done);
      }
    };
  }

  function slideIfReversed() {
    return {
      enter: function (element, done) {
        angular.element(element).slideUp('fast', done);
      },
      move: function (element, done) {
        angular.element(element).slideUp('fast', done);
      },
      leave: function (element, done) {
        angular.element(element).slideDown('fast', done);
      }
    };
  }

  function slideIfFlex() {
    // Remember to include the slide-if class in the app.scss with the "display: none" otherwise the slideDown won't be smooth
    // slideUp would not be affected as the element would be already visible.

    return {
      enter: function (element, done) {
        angular.element(element).css('display', 'flex');
        angular.element(element).slideDown('fast', done);
      },
      move: function (element, done) {
        angular.element(element).slideDown('fast', done);
      },
      leave: function (element, done) {
        angular.element(element).slideUp('fast', done);
      }
    };
  }

  function slideLeft() {
    return {
      addClass: function (element, className, done) {
        var el = angular.element(element);
        el.css({
          display: 'block',
          transform: 'translate(-' + (el.offset().left + el.outerWidth()) + 'px, 0px)'
        });
        done();
      }
    };
  }

  function slideRight() {
    return {
      addClass: function (element, className, done) {
        angular.element(element).css({
          display: 'block',
          transform: 'translate(0px, 0px)'
        });
        done();
      }
    };
  }

  function opacityIf() {
    // Remember to include the slide-if class in the app.scss with the "display: none" otherwise the slideDown won't be smooth
    // slideUp would not be affected as the element would be already visible.

    return {
      enter: function (element, done) {
        angular.element(element).fadeIn(100, function (args) {
          element.css('opacity', 1);
          done(args);
        });
      },
      leave: function (element, done) {
        angular.element(element).fadeOut(100, function (args) {
          element.css('opacity', 0);
          done(args);
        });
      }
    };
  }

  function opacitySlowIf() {
    // Remember to include the slide-if class in the app.scss with the "display: none" otherwise the slideDown won't be smooth
    // slideUp would not be affected as the element would be already visible.

    return {
      enter: function (element, done) {
        element.css('display', 'none');
        angular.element(element).fadeIn(500, done);
      },
      leave: function (element, done) {
        angular.element(element).fadeOut(500, done);
      }
    };
  }
}());

var $windowInstance = angular.injector(['ng']).get('$window');

Raven.addPlugin(Raven.Plugins.Angular);

$windowInstance.deferredBootstrapper.bootstrap({
  element: $windowInstance.document,
  module: 'myLeadPod',
  bootstrapConfig: {
    strictDi: !false
  },
  injectorModules: ['restangular', 'api', 'configuration'],
  resolve: {
    PreloadedUserProfile: ['$q', 'Restangular', 'configOptions', 'Session', 'Auth',
      function ($q, Restangular, configOptions, Session, Auth) {
        'use strict';
        var deferred;
        Restangular.setBaseUrl(configOptions.baseUrl);
        Restangular.addResponseInterceptor(function (data, operation) {
          var extractedData;
          if (operation === 'getList') {
            extractedData = data.content.records;
            extractedData.meta = data.content.recordsInfo;
          }
          else {
            extractedData = angular.extend({}, data.content);
          }
          extractedData.contentEtag = data.contentEtag;
          extractedData.responseStatus = {
            code: data.content.statusCode,
            text: data.content.statusText
          };

          return extractedData;
        });
        Restangular.setDefaultHeaders({Accept: 'application/json'});
        deferred = $q.defer();
        Session.getUserProfile().then(function (userProfile) {
          if (angular.isObject(userProfile) && !!userProfile.idUser) {
            deferred.resolve(userProfile);
          }
          else {
            Auth.signOut(false);
            deferred.resolve(null);
          }
        }, function () {
          Auth.signOut(false);
          deferred.resolve(null);
        });

        return deferred.promise;
      }]
  }
});

(function () {
  'use strict';

  angular
    .module('myLeadPod')
    .config(config);

  function config() {

  }
}());
